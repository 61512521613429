import React, {Component} from 'react';
import {Header} from './Header';
import {Footer} from './Footer';
import {Link} from "react-router-dom";
import {faqPageText as faqPageText_en} from "../libs/en/texts-faqs-page-lib";
import {faqPageText as faqPageText_de} from "../libs/de/texts-faqs-page-lib";
import {faqPageText as faqPageText_fr} from "../libs/fr/texts-faqs-page-lib";
import {faqPageText as faqPageText_fr_fr} from "../libs/fr-fr/texts-faqs-page-lib";
import {faqPageText as faqPageText_nl} from "../libs/nl/texts-faqs-page-lib";
import {faqPageText as faqPageText_it} from "../libs/it/texts-faqs-page-lib";
import {faqPageText as faqPageText_cz} from "../libs/cz/texts-faqs-page-lib";
import {faqPageText as faqPageText_fi} from "../libs/fi/texts-faqs-page-lib";
import {faqPageText as faqPageText_pl} from "../libs/pl/texts-faqs-page-lib";
import {faqPageText as faqPageText_se} from "../libs/se/texts-faqs-page-lib";
import {faqPageText as faqPageText_no} from "../libs/no/texts-faqs-page-lib";
import {faqPageText as faqPageText_dk} from "../libs/dk/texts-faqs-page-lib";
import {faqPageText as faqPageText_es} from "../libs/es/texts-faqs-page-lib";
import {headerText as headerText_en} from "../libs/en/texts-header-lib";
import {headerText as headerText_de} from "../libs/de/texts-header-lib";
import {headerText as headerText_fr} from "../libs/fr/texts-header-lib";
import {headerText as headerText_nl} from "../libs/nl/texts-header-lib";
import {headerText as headerText_it} from "../libs/it/texts-header-lib";
import {headerText as headerText_cz} from "../libs/cz/texts-header-lib";
import {headerText as headerText_fi} from "../libs/fi/texts-header-lib";
import {headerText as headerText_pl} from "../libs/pl/texts-header-lib";
import {headerText as headerText_se} from "../libs/se/texts-header-lib";
import {headerText as headerText_no} from "../libs/no/texts-header-lib";
import {headerText as headerText_dk} from "../libs/dk/texts-header-lib";
import {headerText as headerText_es} from "../libs/es/texts-header-lib";
import parse from "html-react-parser";
import {Helmet} from 'react-helmet';

class Accordion extends Component {
    constructor() {
        super();
        this._handleClick = this._handleClick.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        window.addEventListener('resize', this.resize);
        this._handleClick();

    }

    _handleClick() {
        let acc = this._acc.getElementsByClassName('accordian-title');
        for (let i = 0; i < acc.length; i++) {
            let a = acc[i];
            a.onclick = () => a.classList.toggle("active");
        }
    }

    render() {
        return (
            <div
                ref={a => this._acc = a}
                onClick={this._handleClick}>
                {this.props.children}
            </div>
        )
    }
}

export class Faq extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block1: true,
            block2: false,
            block3: false,
            language: this.props.language,
            faqPageText: faqPageText_en,
            headerText: headerText_en,
        };
        if (this.state.language === "DE") {
            this.state.faqPageText = faqPageText_de;
            this.state.headerText = headerText_de;
        } else if (this.state.language === "FR") {
            this.state.faqPageText = faqPageText_fr;
            this.state.headerText = headerText_fr;
        } else if (this.state.language === "FR-FR") {
            this.state.faqPageText = faqPageText_fr_fr;
            this.state.headerText = headerText_fr;
        } else if (this.state.language === "NL") {
            this.state.faqPageText = faqPageText_nl;
            this.state.headerText = headerText_nl;
        } else if (this.state.language === "IT") {
            this.state.faqPageText = faqPageText_it;
            this.state.headerText = headerText_it;
        } else if (this.state.language === "CZ") {
            this.state.faqPageText = faqPageText_cz;
            this.state.headerText = headerText_cz;
        } else if (this.state.language === "FI") {
            this.state.faqPageText = faqPageText_fi;
            this.state.headerText = headerText_fi;
        } else if (this.state.language === "PL") {
            this.state.faqPageText = faqPageText_pl;
            this.state.headerText = headerText_pl;
        } else if (this.state.language === "SE") {
            this.state.faqPageText = faqPageText_se;
            this.state.headerText = headerText_se;
        } else if (this.state.language === "NO") {
            this.state.faqPageText = faqPageText_no;
            this.state.headerText = headerText_no;
        } else if (this.state.language === "DK") {
            this.state.faqPageText = faqPageText_dk;
            this.state.headerText = headerText_dk;
        } else if (this.state.language === "ES") {
            this.state.faqPageText = faqPageText_es;
            this.state.headerText = headerText_es;
        }
    }

    setLanguageVars() {
        if (this.state.language !== this.props.language) {
            if (this.props.language === "EN") {
                this.setState({
                    language: this.props.language,
                    faqPageText: faqPageText_en,
                    headerText: headerText_en,
                });
            } else if (this.props.language === "DE") {
                this.setState({
                    language: this.props.language,
                    faqPageText: faqPageText_de,
                    headerText: headerText_de,
                });
            } else if ((this.props.language === "FR") || (this.props.language === "FR-FR")) {
                this.setState({
                    language: this.props.language,
                    faqPageText: faqPageText_fr,
                    headerText: headerText_fr,
                });
            } else if (this.props.language === "NL") {
                this.setState({
                    language: this.props.language,
                    faqPageText: faqPageText_nl,
                    headerText: headerText_nl,
                });
            } else if (this.props.language === "IT") {
                this.setState({
                    language: this.props.language,
                    faqPageText: faqPageText_it,
                    headerText: headerText_it,
                });
            } else if (this.props.language === "CZ") {
                this.setState({
                    language: this.props.language,
                    faqPageText: faqPageText_cz,
                    headerText: headerText_cz,
                });
            } else if (this.props.language === "FI") {
                this.setState({
                    language: this.props.language,
                    faqPageText: faqPageText_fi,
                    headerText: headerText_fi,
                });
            } else if (this.props.language === "PL") {
                this.setState({
                    language: this.props.language,
                    faqPageText: faqPageText_pl,
                    headerText: headerText_pl,
                });
            } else if (this.props.language === "SE") {
                this.setState({
                    language: this.props.language,
                    faqPageText: faqPageText_se,
                    headerText: headerText_se,
                });
            } else if (this.props.language === "NO") {
                this.setState({
                    language: this.props.language,
                    faqPageText: faqPageText_no,
                    headerText: headerText_no,
                });
            } else if (this.props.language === "DK") {
                this.setState({
                    language: this.props.language,
                    faqPageText: faqPageText_dk,
                    headerText: headerText_dk,
                });
            } else if (this.props.language === "ES") {
                this.setState({
                    language: this.props.language,
                    faqPageText: faqPageText_es,
                    headerText: headerText_es,
                });
            }
        }
    }

    componentDidUpdate() {
        this.setLanguageVars();
    }

    toggle = (index) => () => {
        this.setState({[`block${index}`]: !this.state[`block${index}`]});
    };

    toggleExpand = (expand = false) => () => {
        this.setState({
            block1: expand,
            block2: expand,
            block3: expand,
        });
    };


    render() {
        return (
            <div>
                <Helmet>
                    <title>{parse(this.state.faqPageText.title)}</title>
                    <meta name="title" content={parse(this.state.faqPageText.titleMeta)}/>
                    <meta name="description"
                          content={parse(this.state.faqPageText.descriptionMeta)}/>
                    <meta name="keywords" content={this.state.faqPageText.keywords}/>
                    <meta property="og:locale" content={this.state.faqPageText.localeMeta}/>
                </Helmet>
                <Header props={this.props}/>
                <section className="inner-banner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-8 col-xl-9">
                                <h1>{parse(this.state.faqPageText.haveQuestion)}</h1>
                                <p>{parse(this.state.faqPageText.hereAnswers)}</p>
                                {/*<p><br/></p>*/}
                                {/*{(this.state.language === "EN") &&*/}
                                {/*<NavLink to={"/info"} className="btn">*/}
                                {/*    Send me info</NavLink>}*/}
                            </div>
                        </div>
                    </div>
                </section>
                <section className="faq-main">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <Accordion>
                                    <div className="accordian-col">
                                        <div className="accordian-title">
                                            <h4>{parse(this.state.faqPageText.whatIsCamAPS)}</h4></div>
                                        <div className="accordian-content">
                                            <p>{parse(this.state.faqPageText.whatIsCamAPSAnswer)}</p>
                                        </div>
                                    </div>

                                    {(this.state.language !== "FR-FR") &&
                                        <div className="accordian-col">
                                            <div className="accordian-title">
                                                <h4>{parse(this.state.faqPageText.isApproved)}</h4></div>
                                            <div className="accordian-content">
                                                <p>{parse(this.state.faqPageText.isApprovedAnswer)}</p>
                                            </div>
                                        </div>
                                    }

                                    {(this.state.language !== "FR-FR") &&
                                        <div className="accordian-col">
                                            <div className="accordian-title">
                                                <h4>{parse(this.state.faqPageText.isAvailable)}</h4>
                                            </div>
                                            <div className="accordian-content">
                                                <p>{parse(this.state.faqPageText.isAvailableAnswer1)} <Link
                                                    to="/orders">{parse(this.state.faqPageText.isAvailableAnswer2)}
                                                </Link> {parse(this.state.faqPageText.isAvailableAnswer3)}</p>
                                            </div>
                                        </div>
                                    }

                                    {/*{(this.state.language !== "FR-FR") &&*/}
                                    {/*    <div className="accordian-col">*/}
                                    {/*        <div className="accordian-title">*/}
                                    {/*            <h4>{parse(this.state.faqPageText.cost)}</h4>*/}
                                    {/*        </div>*/}
                                    {/*        <div className="accordian-content">*/}
                                    {/*            <p>{parse(this.state.faqPageText.costAnswer1)} <Link*/}
                                    {/*                to="/orders">{parse(this.state.headerText.tabOrders)}*/}
                                    {/*            </Link> {parse(this.state.faqPageText.costAnswer2)}</p>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*}*/}

                                    {(this.state.language !== "FR-FR") &&
                                        <div className="accordian-col">
                                            <div className="accordian-title">
                                                <h4>{parse(this.state.faqPageText.compatibleCgm)}</h4></div>
                                            <div className="accordian-content">
                                                <p>{parse(this.state.faqPageText.compatibleCgmAnswer)} <a
                                                    href="https://www.dexcom.com"
                                                    title="Dexcom" target="_blank" rel="noopener noreferrer">Dexcom G6 </a>
                                                    &nbsp;
                                                    {parse(this.state.faqPageText.compatibleCgmAnswer1)} <a
                                                        href="https://www.diabetescare.abbott/products.html"
                                                        title="FreeStyle Libre 3" target="_blank" rel="noopener noreferrer">
                                                        FreeStyle Libre 3 </a>
                                                    {parse(this.state.faqPageText.compatibleCgmAnswer2)}
                                                </p>
                                            </div>
                                        </div>
                                    }

                                    {(this.state.language === "FR-FR") &&
                                        <div className="accordian-col">
                                            <div className="accordian-title">
                                                <h4>{parse(this.state.faqPageText.compatibleCgm)}</h4></div>
                                            <div className="accordian-content">
                                                <p>{parse(this.state.faqPageText.compatibleCgmAnswer)} <a
                                                    href="https://www.dexcom.com"
                                                    title="Dexcom" target="_blank" rel="noopener noreferrer">Dexcom G6 </a>
                                                    {parse(this.state.faqPageText.compatibleCgmAnswer2)}
                                                </p>
                                            </div>
                                        </div>
                                    }

                                    <div className="accordian-col">
                                        <div className="accordian-title">
                                            <h4>{parse(this.state.faqPageText.whatPumps)}</h4></div>
                                        <div className="accordian-content">
                                            <p>{parse(this.state.faqPageText.whatPumpsAnswer1)}
                                                &nbsp;
                                                {parse(this.state.faqPageText.whatPumpsAnswer2)}
                                                {/*<a href={this.state.faqPageText.whatPumpsAnswerHtml}*/}
                                                {/*   title="Dana" target="_blank"*/}
                                                {/*   rel="noopener noreferrer"> {parse(this.state.faqPageText.whatPumpsAnswer2)}*/}
                                                {/*</a>*/}
                                                &nbsp;
                                                {parse(this.state.faqPageText.whatPumpsAnswer3)}</p>
                                        </div>
                                    </div>

                                    {(this.state.language !== "FR-FR") &&
                                        <div className="accordian-col">
                                            <div className="accordian-title">
                                                <h4>{parse(this.state.faqPageText.whatPhone)}</h4></div>
                                            <div className="accordian-content">
                                                <p>
                                                    {parse(this.state.faqPageText.whatPhoneAnswer)}
                                                </p>
                                                {/*<p>*/}
                                                {/*    {parse(this.state.faqPageText.whatPhoneAnswer1)}*/}
                                                {/*    &nbsp;*/}
                                                {/*    <a href={parse(this.state.faqPageText.whatPhoneAnswerDexcomHtml)}*/}
                                                {/*       title={parse(this.state.faqPageText.whatPhoneAnswer2)}*/}
                                                {/*       target="_blank"*/}
                                                {/*       rel="noopener noreferrer">*/}
                                                {/*        {parse(this.state.faqPageText.whatPhoneAnswer2)}</a>*/}
                                                {/*    &nbsp;*/}
                                                {/*    {parse(this.state.faqPageText.whatPhoneAnswer3)}*/}
                                                {/*    &nbsp;*/}
                                                {/*    {parse(this.state.faqPageText.whatPhoneAnswerDexcom)}*/}
                                                {/*    &nbsp;*/}
                                                {/*</p>*/}
                                                <p>
                                                    {parse(this.state.faqPageText.whatPhoneAnswer1)}
                                                    &nbsp;
                                                    <a href={parse(this.state.faqPageText.whatPhoneAnswerLibreHtml)}
                                                       title={parse(this.state.faqPageText.whatPhoneAnswer2)}
                                                       target="_blank"
                                                       rel="noopener noreferrer">
                                                        {parse(this.state.faqPageText.whatPhoneAnswer2)}</a>
                                                    &nbsp;
                                                    {parse(this.state.faqPageText.whatPhoneAnswer3)}
                                                    &nbsp;
                                                    {parse(this.state.faqPageText.whatPhoneAnswerLibre3)}
                                                    &nbsp;
                                                </p>
                                            </div>
                                        </div>
                                    }

                                    {(this.state.language === "FR-FR") &&
                                        <div className="accordian-col">
                                            <div className="accordian-title">
                                                <h4>{parse(this.state.faqPageText.whatPhone)}</h4></div>
                                            <div className="accordian-content">
                                                <p>
                                                    {parse(this.state.faqPageText.whatPhoneAnswer)}
                                                </p>
                                                <p>
                                                    {parse(this.state.faqPageText.whatPhoneAnswer1)}
                                                    &nbsp;
                                                    <a href={parse(this.state.faqPageText.whatPhoneAnswerDexcomHtml)}
                                                       title={parse(this.state.faqPageText.whatPhoneAnswer2)}
                                                       target="_blank"
                                                       rel="noopener noreferrer">
                                                        {parse(this.state.faqPageText.whatPhoneAnswer2)}</a>
                                                    &nbsp;
                                                    {parse(this.state.faqPageText.whatPhoneAnswer3)}
                                                    &nbsp;
                                                    {parse(this.state.faqPageText.whatPhoneAnswerDexcom)}
                                                    &nbsp;
                                                </p>
                                            </div>
                                        </div>
                                    }

                                    <div className="accordian-col">
                                        <div className="accordian-title">
                                            <h4>{parse(this.state.faqPageText.whereUpload)}</h4></div>
                                        <div className="accordian-content">
                                            <p>{parse(this.state.faqPageText.whereUploadAnswer)} <a
                                                href="https://glookoxt.com"
                                                title="Glooko XT" target="_blank"
                                                rel="noopener noreferrer">Glooko XT</a>.
                                                {/*Other cloud portals such as Clarity may become available in the future.*/}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="accordian-col">
                                        <div className="accordian-title">
                                            <h4>{parse(this.state.faqPageText.whatLanguage)}</h4></div>
                                        <div className="accordian-content">
                                            <p>{parse(this.state.faqPageText.whatLanguageAnswer)}</p>
                                        </div>
                                    </div>

                                    {(this.state.language !== "FR-FR") &&
                                        <div className="accordian-col">
                                            <div className="accordian-title">
                                                <h4>{parse(this.state.faqPageText.whereResults)}</h4></div>
                                            <div className="accordian-content">
                                                <p>{parse(this.state.faqPageText.whereResultsAnswer1)} <a
                                                    href="https://s3-eu-west-1.amazonaws.com/camdiab.trial.data/CamAPS+FX+related+publications.zip"
                                                    title="Publications" target="_blank"
                                                    rel="noopener noreferrer">{parse(this.state.faqPageText.whereResultsAnswer2)}
                                                </a> {parse(this.state.faqPageText.whereResultsAnswer3)} <a
                                                    href="https://www.thelancet.com/journals/lancet/article/PIIS0140-6736(18)31947-0/fulltext"
                                                    title="Lancet 2018" target="_blank" rel="noopener noreferrer">The
                                                    Lancet</a> {parse(this.state.faqPageText.whereResultsAnswer4)} <a
                                                    href="https://www.nejm.org/doi/10.1056/NEJMoa1509351"
                                                    title="NEJM 2015" target="_blank" rel="noopener noreferrer">The New
                                                    England Journal of Medicine</a>.</p>
                                            </div>
                                        </div>}
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer props={this.props}/>
            </div>
        );
    }
}

