const tab = '    ';
/**
 * Texts for Order page and modal popups
 */
export const orderPageText = {
    title: 'Ordine - CamDiab',
    titleMeta: 'Ottieni CamAPS FX - CamDiab',
    descriptionMeta: 'Spendi solo 85 € al mese per avere CamAPS FX. Fai una prova gratuita di un mese. Sono necessari il microinfusore Dana compatibile e il sensore della glicemia Dexcom G6',
    localeMeta: 'it',

    keywords:
        'camaps cost, camaps fx cost, camaps order, camaps fx order, camaps fx free trial',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    // discount code processing messages
    pleaseWait: 'Attendere prego',
    successResult: 'Riuscito',
    errorResult: 'Errore di elaborazione',
    discountApplied: 'Il codice di sconto è stato applicato.',
    okButtonText: 'OK',

    // Discount line
    discountHeading: 'Sconto',
    discountOver: ' su', // This reads: Discount: 30% over 3 months
    discountOneMonth: 'mese',
    discountTwoAndMoreMonths: 'mesi',

    // interest in CamAPS FX related messages
    processingEnquiry: 'La tua richiesta è in corso di elaborazione',
    thankYouForSubmission: 'Grazie per averci fornito i tuoi dati',
    processingError: 'Errore di elaborazione',
    responseAck: 'Prenderemo in esame la tua richiesta e ti contatteremo non appena CamAPS FX sarà disponibile nel tuo Paese',

    stage1: 'Fase 1',
    subscriptionOption: 'Opzione di abbonamento',
    stage2: 'Fase 2',
    equipment: 'Dotazione',
    stage3: 'Fase 3',
    personalInfo: 'Informazioni personali',
    stage4: 'Fase 4',
    orderConfirmation: 'Conferma d’ordine',

    currencySubscription: '€',
    vatExempt: '(esente dk IVA)',

    costMonthly: 80,
    costSixMonthly: 450,
    costAnnual: 840,

    costMonthlyVAT: 96,
    costSixMonthlyVAT: 540,
    costAnnualVAT: 1008,

    subscriptionMonthly: 'Abbonamento mensile',
    subscriptionSixMonthly: 'Abbonamento semestrale',
    subscriptionAnnual: 'Abbonamento annuale',

    interested: 'Sei interessato ad acquistare CamAPS FX?',
    interestedPara: 'Utilizza il codice di sconto <b>CamAPS30</b> per una prova di 30 giorni completamente gratuita senza vincoli.',

    subscriptionProcess: 'Procedura per abbonarsi',
    choosePackage: 'Scegli la formula di abbonamento',
    answerQuestions: 'Rispondi a qualche domanda su te stesso',
    receiveTraining: 'Ottieni il corso di addestramento online certificato',

    // below are texts for API
    botCheckFailed: 'Controllo bot non riuscito. Riprova tra breve oppure contattaci all’indirizzo support@camdiab.com',
    wrongDiscountCode: 'Codice di sconto errato oppure codice di sconto scaduto',
    pumpAlreadyExists: 'Impossibile completare l’ordine. Il microinfusore con numero di serie %s è già in uso. Controlla il numero di serie del tuo microinfusore oppure contattaci all’indirizzo sales@camdiab.com.',
    costTextWithDiscount: '%s%s per il periodo di prova (codice di sconto %s), successivamente %s%s per i periodi seguenti',
    emailOrderSubjectLine: 'Il tuo ordine CamAPS FX',
    emailTrainingSubjectLine: 'Addestramento CamAPS FX',
    emailInterestedSubjectLine: 'Richiesta CamAPS FX (%s)',

    emailVATexemptYes: 'Sì',
    emailVATexemptNo: 'No',

    emailOrderBody: "Caro %s,<p>Siamo lieti di confermarti l’ordine dell’app CamAPS FX. Riepiloghiamo di seguito i dettagli del tuo ordine:<p>" +
        tab + "Nome: %s %s<br>" +
        tab + "E-mail di contatto: %s<br>" +
        tab + "Numero di serie del microinfusore: %s<br>" +
        tab + "Periodo di abbonamento: %s<br>" +
        tab + "%s<br>" +
        tab + "Esente dk IVA: %s<br>" +
        tab + "Centro clinico di addestramento: %s<br><p><strong>Fasi successive</strong><br>Puoi scegliere di partecipare al <a href=\"https://www.camdiabtraining.com/account-sign-in.html\">corso di addestramento online all’app CamAPS FX</a> oppure affidarti al tuo educatore sul diabete che ti istruirà personalmente su come utilizzare l’app. Puoi scaricare l’app CamAPS FX dk Amazon Appstore, ma non potrai utilizzarla finché non hai completato l’addestramento e il tuo educatore sul diabete non ha sbloccato la tua app fornendoti il codice attestante l’addestramento certificato all’CamAPS FX. Dopo l’addestramento, potrai cominciare ad utilizzare immediatamente l’app CamAPS FX.<p><strong>Pagamento</strong><br>Dopo che l’app è stata sbloccata e puoi cominciare a utilizzarla, ti invieremo una fattura per posta elettronica. Preferiamo che i pagamenti siano effettuati con rimessa diretta. È necessario effettuare puntualmente i pagamenti per continuare a utilizzare l’app CamAPS FX. Ciò non pregiudica i tuoi diritti legali. Puoi annullare il tuo ordine in qualsiasi momento prima dell’addestramento oppure entro 14 giorni dall’addestramento per ottenere un rimborso totale dei pagamenti effettuati.<p><strong>Periodo di abbonamento</strong><br>Il periodo di abbonamento decorre dalla data dell’avvenuto addestramento. Non saranno accettati pagamenti prima dell’avvenuto addestramento. Dopo il primo periodo di abbonamento, i pagamenti per ogni successivo periodo di abbonamento saranno dovuti a meno non si annulli l’ordine per iscritto scrivendo a %s.<p>Grazie per la preferenza accordataci. Speriamo che l’uso dell’app CamAPS FX possa esserti di aiuto.<p>Team CamDiab<br>%s",

    emailPotentialUser: "Caro %s %s,<p>Ci risulta che sei interessato ad utilizzare l’app CamAPS FX. Tuttavia, hai segnalato di non poter contare su un addestramento all’app CamAPS FX presso il tuo centro diabetologico. Possiamo organizzare per te un corso di addestramento a pagamento. Riepiloghiamo di seguito i tuoi dati:<p>" +
        tab + "Nome: %s %s<br>" +
        tab + "E-mail di contatto: %s<br><p><strong>Fasi successive</strong><br>Trasmetteremo la tua richiesta al/ai nostri istruttore/i che potrà/potranno aiutarti. In questo caso, sarai contattato separatamente tramite posta elettronica. Dopo aver concordato con l’istruttore il programma di addestramento, dovrai inoltrare l’ordine CamAPS FX tramite <a href=\"https://camdiab.com/\">camdiab.com</a>.<p><strong>Pagamento dell’addestramento</strong><br>Dovrai concordare con l’istruttore CamAPS FX il costo dell’addestramento e pagarlo separatamente. CamDiab non fornisce questi servizi. <p><strong>Istruttori CamAPS</strong><br>Tutti i nostri istruttori CamAPS FX sono in possesso di un adeguato addestramento e ne conosciamo il valore.<p>Grazie per l’interesse verso la nostra app CamAPS FX.<p>Team CamDiab<br>%s",

    emailInterestedUser: "Caro %s %s,<p>Grazie per l’interesse ad utilizzare la nostra app CamAPS FX. Abbiamo preso nota del tuo interesse e ti contatteremo nel caso in cui CamAPS FX fosse resa disponibile in %s. I tuoi dati:<p>" +
        tab + "Nome: %s %s<br>" +
        tab + "E-mail di contatto: %s<br>" +
        tab + "Paese: %s<br><p>Grazie per l’interesse ad utilizzare l’app CamAPS FX.<p>Team CamDiab<br>%s",
    emailOrderBody_1: 'Grazie per esserti registrato per utilizzare l’app CamAPS FX',
    emailOrderBody_2: 'Caro %s',
    emailOrderBody_3: 'Siamo lieti di confermarti l’ordine dell’app CamAPS FX. Riepiloghiamo di seguito i dettagli del tuo ordine:',
    emailOrderBody_4: 'Nome:',
    emailOrderBody_5: 'E-mail di contatto:',
    emailOrderBody_6: 'Numero di serie del microinfusore:',
    emailOrderBody_7: 'Periodo di abbonamento:',
    emailOrderBody_8: 'Costo:',
    emailOrderBody_9: 'Esente dk IVA:',
    emailOrderBody_10: 'Centro clinico di addestramento:',
    emailOrderBody_11: 'Fasi successive',
    emailOrderBody_12: 'Ti sarà inviata una fattura <b>dopo</b> che hai cominciato a utilizzare l’app CamAPS FX.',
    emailOrderBody_13: 'Ti consigliamo di partecipare al corso di addestramento online all’app CamAPS FX.',
    emailOrderBody_14: 'In caso contrario affidati al tuo educatore sul diabete che ti istruirà personalmente su come utilizzare l’app.',
    emailOrderBody_15: 'Puoi scaricare l’app CamAPS FX dk Amazon Appstore, ma non potrai utilizzarla finché non hai completato l’addestramento e il tuo educatore sul diabete o tu stesso non avete sbloccato la tua app per mezzo del codice attestante l’addestramento certificato all’app CamAPS FX. ',
    emailOrderBody_16: 'Dopo che l’app è stata sbloccata, puoi cominciare a utilizzarla immediatamente.',
    emailOrderBody_17: 'Pagamento',
    emailOrderBody_18: 'Dopo l’addestramento, ti invieremo una fattura tramite posta elettronica. È necessario effettuare puntualmente i pagamenti per continuare a utilizzare l’app CamAPS FX. Ciò non pregiudica i tuoi diritti legali. Puoi annullare il tuo ordine in qualsiasi momento prima dell’addestramento oppure entro 30 giorni dall’addestramento per ottenere un rimborso totale dei pagamenti effettuati.',
    emailOrderBody_19: 'Periodo di abbonamento',
    emailOrderBody_20: 'Il periodo di abbonamento decorre dalla data del tuo addestramento. Non saranno accettati pagamenti prima dell’avvenuto addestramento. Dopo il primo periodo di abbonamento, i pagamenti per ogni successivo periodo di abbonamento saranno dovuti a meno che tu non annulli l’ordine per iscritto scrivendo a sales@camdiab.com.',
    emailOrderBody_21: 'Grazie per esserti abbonato per utilizzare l’app CamAPS FX',
    emailOrderBody_22: 'Per assistenza tecnica, visita il nostro sito web per ulteriori informazioni.',
    emailOrderBody_23: '© 2022 CamDiab Ltd. Tutti i diritti riservati.',

    // Texts for step one form
    s1MonthlyHeading: 'Abbonamento mensile',
    s1MonthlyValue: '£80*',
    s1Select: 'Seleziona',
    s1HalfYearlyHeading: 'Abbonamento semestrale',
    s1HalfYearlyText: '£450*',
    s1HalfYearlyTextSaving: '(risparmio di £30)',
    s1YearlyHeading: 'Abbonamento annuale',
    s1YearlyText: '£840*',
    s1YearlyTextSaving: '(risparmio di £120)',
    s1VATtext: ' *Più IVA eccetto in caso di esenzione. Da pagarsi integralmente ad avvenuto addestramento / avvenuta attivazione dell’app.',

    // Texts for step two form
    previous: 'Indietro',
    next: 'Avanti',
    s2Yes: 'Sì',
    s2No: 'No',
    s2HaveDanaPump: 'Possiedi un microinfusore mylife YpsoPump, Dana Diabecare RS o DANA-i?',
    s2HaveCgm: 'Possiedi un sistema CGM Dexcom G6?',
    s2Phone1: 'Possiedi uno',
    s2PhoneHtml: 'https://www.dexcom.com/dexcom-international-compatibility',
    s2Phone2: 'smartphone Android compatibile',
    s2Phone3: '?',
    s2NoPump: 'Per poter utilizzare l’app CamAPS FX è necessario utilizzare il microinfusore mylife YpsoPump, Dana Diabecare RS o DANA-i. Contatta il tuo team diabetologico locale per informarti sulle opzioni disponibili oppure il tuo distributore Dana locale.',
    s2NoCgm: 'Per poter utilizzare l’app CamAPS FX è necessario utilizzare il sistema CGM Dexcom G6. Consulta il tuo team diabetologico locale per informarti sul sistema CGM Dexcom G6 oppure contatta Dexcom.',
    s2NoPhone: 'Senza l’uso di uno smartphone Android compatibile, non possiamo garantire una connessione sicura al tuo sensore Dexcom G6. Valuta l’acquisto di uno smartphone Android compatibile.',

    // Texts for step three form
    s3InfoSource: [
        'Centro clinico',
        'Motore di ricerca',
        'Social media',
        'Un post di un amico sui social media',
        'JDRF',
        'Diabetes UK',
        'Radio',
        'TV',
        'Stampa',
        'Passa parola',
        'Altro'],

    // s3TeritoryQuestion: 'Are you based in the UK, the EU, or New Zealand?',
    s3TeritoryQuestion: 'Ti trovi nel Regno Unito o nella UE?',
    s3NotAvailable: 'Purtroppo, l’app CamAPS FX è disponibile solo in paesi selezionati. Ti invitiamo a inserire i tuoi dati se desideri essere contattato dk parte nostra quando il sistema sarà disponibile anche nel tuo paese.',
    s3SelectCountry: 'Seleziona il paese',
    s3TC1: 'Inviando la tua richiesta accetti i nostri',
    s3TC2: 'Termini e condizioni',
    s3TC3: 'e',
    s3TC4: 'Privacy',
    s3TC5: 'e ci autorizzi di contattarti.',
    s3Training1: 'Devi essere addestrato dk un istruttore certificato e/o eseguire un',
    s3Training2: 'addestramento online',
    s3Training3: 'prima di poter utilizzare l’app CamAPS FX.',

    s3FirstName: 'Nome',
    s3LastName: 'Cognome',
    s3Email: 'Indirizzo e-mail',
    s3EmailConfirm: 'Conferma indirizzo e-mail',
    s3AddressLine1: 'Riga uno indirizzo',
    s3AddressLine2: 'Riga due indirizzo',
    s3AddressLine3: 'Riga tre indirizzo',
    s3PostCode: 'Codice postale',
    s3SubmitCountry: 'Invia',
    s3DisabilityHeading: 'Dichiaro di avere la seguente disabilità o malattia cronica:',
    s3Disability: 'Disabilità o malattia cronica',
    s3signature: 'Firma (nome in stampatello)',

    s3TrainingCdep1: 'Seleziona l’addestramento online CamAPS per continuare:',
    s3TrainingCdep2: 'Seleziona addestramento',
    s3TrainingCdep3: 'Addestramento online CamAPS',

    s3contactHeading: 'Inserisci i tuoi dati di contatto',
    s3whereHeard: 'Dove sei venuto a conoscenza dell’app CamAPS FX?',
    s3whereHeardSelect: 'Seleziona una delle seguenti opzioni',
    s3pumpSerialNumber: 'Numero di serie del microinfusore Dana:',
    s3pumpSerialNumberConfirm: 'Conferma il numero di serie del microinfusore Dana:',
    s3pumpSerialNumberMessage: 'Importante:',
    s3pumpSerialNumberMessage1: 'Il numero di serie del microinfusore è necessario affinché l’app CamAPS FX possa collegarsi al tuo microinfusore. Un numero di serie errato può causare il mancato collegamento tra l’app CamAPS FX e il tuo microinfusore. Il numero di serie si trova a lato del microinfusore. Un numero di serie campione è THF00041FB.',
    s3vatExempt: 'Sei esente dk IVA?',
    s3vatHelp1: 'Per ulteriori informazioni puoi consultare i fogli informativi su',
    s3vatHelpHtml: 'https://www.gov.uk/government/organisations/hm-revenue-customs/contact/vat-reliefs-for-disabled-and-older-people',
    s3vatHelpLine: 'sportello Consulenza Esenzione IVA',
    s3vatHelp2: 'sito web GOV.UK',
    s3vatHelp3: 'o telefonando alla linea dedicata Sgravi IVA per Disabili al numero 0300 123 1073.',
    s3vatHelp4: 'Il personale del dipartimento HMRC non può fornire consulenza in merito allo stato di malattia cronica o di disabilità di un individuo.',
    s3vatHelp5: 'Importante:',
    s3vatHelp6: 'Se stai ordinando l’app CamAPS FX per conto di altri, ti invitiamo a compilare il modulo di esenzione IVA con i dati del soggetto interessato.',
    s3PhoneWarning: 'Senza l’uso di uno smartphone Android compatibile, non possiamo garantire una connessione sicura al tuo sensore Dexcom G6. Valuta l’acquisto di uno smartphone Android compatibile',

    s3NameMissing: 'Il nome è obbligatorio',
    s3SurnameRequired: 'Il cognome è obbligatorio',
    s3EmailRequired: 'L’e-mail è obbligatoria',
    s3CountryRequired: 'Il paese è obbligatorio',
    s3EmailConfimrRequired: 'La conferma dell’e-mail è obbligatoria',
    s3EmailMatchRequired: 'L’e-mail deve corrispondere',
    s3ClinicRequired: 'Il centro clinico di addestramento è obbligatorio',
    s3DiseaseStateRequired: 'È necessario indicare la natura delle esigenze dell’utilizzatore',
    s3OnlyTextValueProvided: 'Contiene solo un valore testuale',
    s3OnlyNumberValueProvided: 'Contiene solo un numero',
    s3SerialNumbersMatch: 'Il numero di serie deve corrispondere',
    s3vatStatusRequired: 'Lo stato IVA deve essere specificato',
    s3AddressLine1Required: 'La riga uno indirizzo è obbligatoria',
    s3AddressLine2Required: 'La riga due indirizzo è obbligatoria',
    s3PostCodeRequired: 'Il codice postale è obbligatorio',
    s3SickessRequired: 'L’indicazione di disabilità o malattia cronica è necessaria',
    s3SignatureRequired: 'La firma è obbligatoria',

    // Texts for step four form
    s4NextSteps:'Fasi successive',
    s4DownloadApp:'Puoi scaricare l’app CamAPS FX dk',
    s4DownloadGuideHtml:'https://s3-eu-west-1.amazonaws.com/camdiab.user.manual/Downloading+CamAPS+FX.pdf',
    s4DownloadAppGuide:'Guida all’installazione',
    s4Appstore:'Amazon Appstore',
    s4DownloadApp1:'ma non potrai utilizzarla finché non avrai superato',
    s4TrainingTextLink:'l’addestramento',
    s4TrainingHtml:'https://camdiab.com/it/training',
    s4TrainingTitle:'Addestramento',

    s4Payment:'I pagamenti saranno riscossi al momento del collegamento tra l’app CamAPS FX e il microinfusore.',

    s4TermsAccept:'Accetto',
    s4TermsTermsHtml:'https://camdiab.com/it/terms',
    s4TermsTermsText:'Termini e condizioni',
    s4TermsPrivacyHtml:'https://camdiab.com/it/privacy',
    s4TermsPrivacyText:'Informativa sulla Privacy',

    s4DiscountCodeText:'Codice di sconto (facoltativo)',
    s4DiscountApplyCode:'Applica codice',

    s4Summary:'Riepilogo dell’ordine',
    s4Name:'Nome:',
    s4Email:'E-mail:',
    s4PumpSN:'NS microinfusore:',
    s4Training:'Addestramento:',
    s4Total:'Totale',

    s4CompleteButtonText:'Completa',

    // duration or subscription period in an email
    emailOneMonth: '1 mese',
    emailSixMonths: '6 mesi',
    emailTwelveMonths: 'annuale',

    // Order complete
    oderProcessedHeading: 'Il tuo ordine è in corso di elaborazione',
    oderProcessedSucess: 'Grazie, il tuo ordine è andato a buon fine',
    oderProcessedError: 'Errore di elaborazione',
    oderProcessedEmailToBeSent: 'Riceverai a breve un’e-mail di conferma',
};
