import React, {useState} from 'react';
import parse from "html-react-parser";

const StepOneForm = props => {
    const [selected, setSelected] = useState("false");
    return (
        <section className="subscription-section">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="subscription-col">
                            <h4>{parse(props.orderPageText.s1MonthlyHeading)}</h4>
                            <h2>
                                {parse(props.orderPageText.s1MonthlyValue)}
                            </h2>
                            <p>
                                &nbsp;
                            </p>
                            <div className="subscription-button">
                                <input type="radio" name="sub_btn" id="sub_btn1" onChange={(e) => {
                                    setSelected(e.target.value);
                                    props.setSubscriptionPeriod('monthly');
                                }} value={"true"}/>
                                <label htmlFor="sub_btn1" className="btn">
                                    {parse(props.orderPageText.s1Select)}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="subscription-col">
                            <h4>{parse(props.orderPageText.s1HalfYearlyHeading)}</h4>
                            <h2>
                                {parse(props.orderPageText.s1HalfYearlyText)}
                            </h2>
                            <p>
                                {parse(props.orderPageText.s1HalfYearlyTextSaving)}
                            </p>
                            <div className="subscription-button">
                                <input type="radio" name="sub_btn" id="sub_btn2" onChange={(e) => {
                                    setSelected(e.target.value);
                                    props.setSubscriptionPeriod('sixMonthly');
                                }} value={"true"}/>
                                <label htmlFor="sub_btn2" className="btn">
                                    {parse(props.orderPageText.s1Select)}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="subscription-col">
                            <h4>{parse(props.orderPageText.s1YearlyHeading)}</h4>
                            <h2>
                                {parse(props.orderPageText.s1YearlyText)}
                            </h2>
                            <p>
                                {parse(props.orderPageText.s1YearlyTextSaving)}
                            </p>
                            <div className="subscription-button">
                                <input type="radio" name="sub_btn" id="sub_btn3" onChange={(e) => {
                                    setSelected(e.target.value);
                                    props.setSubscriptionPeriod('annual');
                                }} value={"true"}/>
                                <label htmlFor="sub_btn3" className="btn">
                                    {parse(props.orderPageText.s1Select)}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-12">
                        <div className="next-step">
                            <button className={`btn arrow-btn ${selected === 'false' ? 'disable-btn' : ''}`}
                                    onClick={props.handleNext}>
                                {parse(props.orderPageText.next)}
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    {parse(props.orderPageText.s1VATtext)}
                </div>
            </div>
        </section>
    )
};

export default StepOneForm;