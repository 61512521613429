import React, {Component} from 'react';
import {Header} from '../Components/Header';
import {Footer} from '../Components/Footer';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

export class TermsNO extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Vilkår og betingelser - CamAPS FX</title>
                    <meta name="title" content='Vilkår og betingelser'/>
                    <meta name="description"
                          content='Her kan du finne CamDiab vilkår og betingelser'/>
                    <meta property="og:locale" content='nb'/>
                    <meta name="keywords" content='vilkår og betingelser'/>
                </Helmet>
                <Header props={this.props}/>
                <section className="inner-banner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-10">
                                <h1>Vilkår og betingelser</h1>
                                <h3>Versjon datert 24. juni 2024</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <h2>
                                    1. OMFANG
                                </h2>
                                <h3>
                                    1.1. Produktene våre
                                </h3>
                                <p>
                                    1.1.1. Vi
                                </p>
                                <p>
                                    CamDiab Ltd <strong>
                                    <br/>
                                    Registrert kontoradresse<br/>
                                    20-22 Wenlock Road<br/>
                                    London, N1 7GU, Storbritannia
                                    <br/>
                                </strong>
                                    support@camdiab.com
                                    <br/>
                                    bedriftsnummer 11659211 England og Wales
                                    <br/>
                                    <br/>
                                </p>
                                <p>
                                    (“<strong>CamDiab</strong>”), utvikler og drifter mobilapplikasjoner (apper) for closed loop-insulintilførsel og tilknyttet datainfrastruktur for personer med diabetes samt helsepersonell. CamDiab er et digitalt helseforetak og ønsker å tilby personer med diabetes bedre glukosekontroll og redusere byrden knyttet til å kontrollere diabetes.
                                </p>
                                <p>
                                    1.1.2 Produktene våre veileder og støtter closed loop-insulintilførsel etter beste viten. Produktene og tjenestene våre kan ikke erstatte konsultasjon med og diagnostisering av helsepersonell. Hvis du har spørsmål om sykdommen og behandlingen, anbefaler vi at du kontakter helsepersonell. Dataene som gis, skal kun analyseres av deg og helsepersonellet som behandler deg. Ikke noe innhold – selv om det gis av CamDiab selv, våre samarbeidspartnere, distributører, leverandører eller brukere – skal forstås som at det supplerer eller erstatter informasjon fra helsepersonell eller farmasøyt.
                                </p>
                                <p>
                                    1.1.3 CamDiab kan tilby en rekke produkter (apper) og tjenester samt tillate datadeling for å hjelpe til å kontrollere diabetesen din. Alle gjeldende og framtidige apper og tjenester (inkludert nye versjoner, oppdateringer og andre forbedringer) som CamDiab tilveiebringer på noen som helst måte, omtales framover felles som “<strong>Produkter</strong>” eller hver for seg som et “<strong>Produkt</strong>”. Hver person som skaffer seg og bruker ett eller flere av produktene våre, omtales felles som “<strong>Brukere</strong>” eller individuelt som “<strong>Bruker</strong>”. Hvis disse vilkårene henviser til fysiske personer og bruker hankjønn, så gjelder det for alle personer.
                                </p>
                                <p>
                                    1.1.4 Følgende betegnelser gjelder for individuelle produkter i forbindelse med disse vilkårene:
                                </p>
                                <p>
                                    <strong>CamAPS FX </strong> er en frittstående mobilapplikasjon (app) for smarttelefonen din med en hybridløsning for closed loop-insulintilførsel for personer med diabetes type 1. Appen knyttes sammen med en glukosesensor og insulinpumpe levert av en tredjepart. Du kan velge å dele data innhentet av appen med CamDiab eller diabetesportal(er) for datavisning fra en tredjepart. Begrensninger kan gjelde hvilken smarttelefonmodell som kan brukes basert på pumpen og glukosesensoren fra en tredjepart.
                                </p>
                                <p>
                                    <strong>CamAPS HX </strong> er en tilsvarende app som CamAPS FX, men muliggjør fullstendig closed loop for diabetes som ikke er type 1.
                                </p>
                                <p>
                                    1.1.5 Håndbøkene våre (tilgjengelige via appene våre eller nettstedet vårt) og de relevante spesielle forholdsreglene understreker eventuelle særegenheter for de forskjellige produktene.
                                </p>
                                <h3>
                                    1.2. Omfanget av vilkårene
                                </h3>
                                <p>
                                    1.2.1 Vi utelukkende tilbyr produktene våre på grunnlag av den gjeldende versjonen av disse generelle vilkårene. Dette gjelder globalt. CamDiab avslår uttrykkelig motstridende forretningsvilkår eller andre bruksvilkår.
                                </p>
                                <p>
                                    1.2.2 Vær oppmerksom på at før du bruker produktene for første gang, må du gi ditt samtykke til alle erklæringene og avtalene det henvises til og godta dem under all videre bruk. Registreringen og bruken av produktene våre er forbudt uten at du har godtatt vilkårene våre.
                                </p>
                                <p>
                                    1.2.3. Vi forbeholder uttrykkelig retten til å foreta fremtidige endringer og reelle begrunnede modifikasjoner av vilkårene våre. Det kan være nødvendig å gjøre endringer for å oppfylle lovpålagte krav, korrespondere med tekniske og økonomiske krav eller imøtekomme interessene til brukerne våre. Slike endringer kan skje når som helst og blir offentliggjort på en passende måte. Hvis det ikke gjøres noen innvendinger innen en måned, er videre bruk av produktene våre underlagt de aktuelle nye vilkårene.
                                </p>
                                <h3>
                                    1.3. Tredjepartsleverandører
                                </h3>
                                <p>
                                    1.3.1 Du finner produktene våre i appbutikken(e). Generelt sett gjelder forretningsvilkårene til den relevante tredjepartsleverandøren (som vi ikke har noen kontroll over). Disse er ikke en del av våre vilkår eller vårt kontraktsmessige forhold med deg som brukeren av produktene våre. Dette gjelder også forretningsvilkårene for mobilenheten din eller tjenestetilbyderen.
                                </p>
                                <p>
                                    1.3.2 Vi jobber med forskjellige partnere for å sikre optimal bruk av produktene våre. Dette inkluderer samarbeidspartnere som leverer visse produkter til brukerne våre direkte (f.eks. insulinpumpe, vevsglukosemonitor). I disse tilfellene gjør vi oppmerksom på at tredjepartsleverandørens gjeldende forretningsvilkår, da du må godta dem før du kan begynne å bruke produktene våre.
                                </p>
                                <h2>
                                    2. PERSONVERN
                                </h2>
                                <p>
                                    <strong> Vi er klar over det store ansvaret som følger med at du bruker produktene våre. Du gir oss ikke bare generelle personopplysninger, men også helseinformasjon. I </strong>
                                    <Link to="/nb/privacy">
                                        personvernerklæringen</Link> <strong>vår informerer vi deg om hvordan personopplysningene dine brukes og blir beskyttet i forbindelse med bruken av produktene våre, og om at du må gi samtykke til det. </strong>
                                </p>
                                <p>
                                    <strong> HVIS DU IKKE GIR SAMTYKKE TIL NØDVENDIG DATABEHANDLING, ER DET VERKEN LOVLIG ELLER PRAKTISK MULIG Å BRUKE PRODUKTENE VÅRE. </strong>
                                </p>
                                <p>
                                    <strong>Vår </strong>
                                    <Link to="/nb/privacy"> personvernerklæring</Link> <strong>er ikke endel av disse vilkårene, men brukes kun til å gi deg informasjon i samsvar med Personvernforordningen (GDPR), som er grunnlaget du gir samtykke til den nødvendige databehandlingen på. </strong>
                                </p>
                                <h2>
                                    3. INNGÅELSE AV AVTALE OG REGISTRERING
                                </h2>
                                <h3>
                                    3.1. Kjøp og betaling av produktene våre
                                </h3>
                                <p>
                                    3.1.1 Produktene våre kan kjøpes på forskjellige måter:
                                </p>
                                <h3>
                                    Apper
                                </h3>
                                <p>
                                    <strong>Appbutikker</strong> (for eksempel Google Play og Amazon Appstore) tilbyr produktene våre. Vær oppmerksom på at egne forretningsvilkår gjelder i app-butikken for å laste ned (ikke bruke) produktene våre, og disse kan kreve at du har en brukerkonto.
                                </p>
                                <p>
                                    <strong>Nettstedet vårt</strong> kan gi direkte tilgang til produktene våre og til å bestille dem.
                                </p>
                                <p>
                                    <strong>Kampanjekoder</strong> for produktene våre kan være utdelt og løses inn på nettstedet vårt.
                                </p>
                                <p>
                                    3.1.2 Du kan bestille betalingsproduktene våre på nettstedet vårt eller gjennom distributørene våre. De betales via en betalingstjenestetilbyder, forhandleren vår eller andre angitte betalingsmåter. Forhandleren fungerer enten som agent eller betalingstjenestetilbyder for CamDiab eller som direkte selger. Hvis du opplever problemer med nedlasting eller betaling kan du alltid kontakte <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <p>
                                    3.1.3 Bruksgebyrer eller kjøpspriser (inkludert lovpålagt merverdiavgift) må betales – avhengig av tilbud og utvalg – én gang eller flere ganger (f.eks. en gang i måneden, hvert halvår eller en gang i året). De aktuelle gebyrene er oppgitt på nettstedet vårt etter forhandler eller vist i appen vår eller i app-butikker, og skal forhåndsbetales. Hvis betalingen ikke behandles direkte via app-butikken, kan betalingstilbyderen eller forhandleren sende deg fakturaer og betalingspåminnelser på e-post. Hvi du skylder betaling, betaler for sent eller betalingen er ufullstendig, har vi rett til å sperre tilgangen din til produktene våre. Forpliktelsen din til å betale bruksgebyr berøres ikke av dette.
                                </p>
                                <p>
                                    3.1.4. CamDiab kan også gi spesialtilbud eller gratistilbud, som er underlagt ytterligere vilkår eller begrensninger. I den forbindelse henviser vi uttrykkelig til eventuelle avvik fra disse vilkårene, men ellers gjelder disse vilkår fullt ut. Spesialtilbud eller gratistilbud kan ikke overføres til andre brukere.
                                </p>
                                <h3>
                                    3.2. Registrering og aktivering
                                </h3>
                                <p>
                                    3.2.1 Du må registrere deg hos CamDiab i appene våre for å kunne bruke produktene våre. Så fort du har aktivert produktet vårt for første gang, må du registrere deg med en e-postadresse og et passord (CamDiab ID). Hver bruker kan bare opprette én CamDiab ID, og hver CamDiab ID skal kun brukes av én bruker.
                                </p>
                                <p>
                                    3.2.2 Ved å registrere seg godtar brukeren sitt bindende tilbud om å inngå en avtale med CamDiab om bruk av produktene våre på grunnlag av den gjeldende versjonen av disse vilkårene. Vi godtar uttrykkelig dette tilbudet, senest ved aktivering av CamDiab ID-en din.
                                </p>
                                <p>
                                    3.2.3 Aktiveringen bekreftes når innloggingsinformasjonen din godtas, og du har fått passende opplæring. Vi forbeholder oss retten til å avvise enkelte brukere eller deres tilbud om å inngå en avtale uten å kunne begrunne det. I disse tilfellene vil allerede gjennomførte betalinger bli refundert, og brukerdataene blir straks slettet.
                                </p>
                                <p>
                                    3.2.4 Etter registrering bekrefter brukeren at vedkommende har fylt seksten år og at gjeldende lov tillater at han eller hun inngår avtaler. Bare personer som er myndige, kan registrere seg. Mindreårige kan kun gjøre dette med uttrykkelig tillatelse fra en foresatt. Med registreringen erklærer den foresatte at vedkommende har rett til å avgi juridisk bindende erklæringer på den mindreåriges vegne.
                                </p>
                                <p>
                                    3.2.5 Etter registreringen bekrefter brukeren at vedkommende anerkjenner og uten begrensninger godtar innholdet i disse vilkårene, og at vedkommendes opplysninger er sanne, korrekte, oppdaterte og fullstendige. Vi forbeholder oss retten til enhver tid å kunne kontakte brukerne og kontrollere registrerings- og bruksinformasjonen.
                                </p>
                                <p>
                                    3.2.6 Hvis brukeren oppgir falske, feil, gamle eller ufullstendige opplysninger, eller vi har grunn til å tro at informasjonen er falsk, feil, gammel eller ufullstendig, har CamDiab rett til uten forhåndsvarsel og med omgående virkning å sperre den aktuelle CamDiab ID-en og forby bruk av produktene våre uten at vi er forpliktet til å tilbakebetale brukerens utgifter i den forbindelse.
                                </p>
                                <p>
                                    3.2.7 Brukeren skal beskytte registreringsinformasjonen sin mot uautorisert tilgang fra tredjeparter, misbruk eller bruk med tanke på svindel. Hvis det er den minste mistanke om at CamDiab ID-en har vært utsatt for en slik risiko, skal det straks meldes til <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>. Vi har rett til å sperre en brukers CamDiab ID hvis den brukes på ulovlig eller måte eller brukes til svindel.
                                </p>
                                <h2>
                                    4. ANGRERETT OG ANNULLERING
                                </h2>
                                <h3>
                                    4.1 Informasjon om angreretten
                                </h3>
                                <p>
                                    4.1.1 Hvis du ikke bruker produktene våre til kommersielle eller yrkesrelaterte formål, er du forbruker og har dermed rett til annullere en avtale du har inngått med CamDiab på følgende betingelser:
                                </p>
                                <p>
                                    <strong>ANGRERETT</strong>
                                </p>
                                <p>
                                    <strong><u>Angrerett</u></strong>
                                </p>
                                <p>
                                    <strong>Du har rett til å annullere avtalen innen fjorten dager uten å oppgi noen grunn.</strong>
                                </p>
                                <p>
                                    <strong>Angreretten gjelder i fjorten dager fra datoen avtalen inngås (aktivering av digitalt innhold).</strong>
                                </p>
                                <p>
                                    <strong>Hvis du bruker angreretten din, må du informere oss om avgjørelsen din om å annullere avtalen, med en tydelig erklæring til forhandleren eller CamDiab ved å sende en e-post til</strong><strong> <a href="mailto:support@camdiab.com">support@camdiab.com</a></strong><strong> (f.eks. via e-post eller post).</strong>
                                </p>
                                <p>
                                    <strong>Det holder at du sender meldingen om at du benytter deg av angreretten før angrefristen utløper.</strong>
                                </p>
                                <p>
                                    <strong><u>Konsekvenser av annullering</u></strong>
                                </p>
                                <p>
                                    <strong>Hvis du benytter deg av angreretten for denne avtalen, må forhandleren eller vi tilbakebetale alle betalinger vi har mottatt fra deg straks og senest fjorten dager etter at vi har mottatt meldingen din om at du ønsker å heve avtalen. Når det er mulig bruker vi samme betalingsmetode for denne tilbakebetalingen som den du brukte til den opprinnelige transaksjonen, med mindre vi uttrykkelig har avtalt noe annet. Ikke under noen omstendigheter tilkommer det noen gebyrer for tilbakebetalingen.</strong>
                                </p>
                                <h2>
                                    5. BRUK AV PRODUKTENE VÅRE
                                </h2>
                                <h3>
                                    5.1. Bruksperiode og oppsigelse
                                </h3>
                                <p>
                                    5.1.1 Retten din til å bruke produktene våre begynner når du registrerer deg og CamDiab aktiverer produktene.
                                </p>
                                <p>
                                    5.1.2 Betalingsproduktene våre kan kjøpes i form av månedlige, halvårlige eller årlige abonnementer, og avhengig av det aktuelle tilbudet, kan de også være tilgjengelige i kortere eller lengere bruksperioder (heretter kalt “<strong>abonnementsperioden</strong>”).
                                </p>
                                <p>
                                    5.1.3 Både brukeren og CamDiab har til enhver tid rett til å si opp avtalen med en måneds oppsigelsestid, som trer i kraft i slutten av inneværende måned. Du kan gjøre dette ved å kontakte distributøren eller ved å gi oss beskjed skriftlig på <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>. Vær oppmerksom på at gebyrer som allerede er betalt for en ubrukt abonnementsperiode, kun tilbakebetales ved en ordinær oppsigelse fra CamDiabs side eller ved automatisk avslutning (del 5.1.5 nedenfor).
                                </p>
                                <p>
                                    5.1.4 Hvis det ikke er noen oppsigelse, eller oppsigelsen kommer for sent, forlenges abonnementsperioden automatisk med en ny periode tilsvarende den tidligere valgte abonnementsperioden. Den relevante standardprisen på forlengelsestidspunktet (ikke prisene på spesialtilbud eller gratistilbud) gjelder.
                                </p>
                                <p>
                                    5.1.5 I tilfelle av brukerens død eller tilbaketrekking av samtykke til databehandling, opphører bruksforholdet automatisk med umiddelbar virkning. CamDiab kan ikke behandle helseopplysningene dine uten ditt samtykke.
                                </p>
                                <p>
                                    5.1.6 Hvis du har spørsmål om bruksperioden din eller dens opphør, kan du alltid kontakte <a href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h3>
                                    5.2. Utestenging
                                </h3>
                                <p>
                                    5.2.1 Vi forbeholder oss retten til når som helst å utestenge brukere hvis det (etter vårt skjønn) foreligger en tungtveiende grunn, eller å permanent utestenge dem fra å bruke produktene våre. Hvis en slik avtale avsluttes uten varslingsperiode, er tilbakebetaling av bruksgebyret utelatt.
                                </p>
                                <p>
                                    5.2.2 Det foreligger en vesentlig grunn spesielt ved alvorlige overtredelser av bestemmelsene i disse vilkårene fra brukerens side, f.eks. overtredelse av del 3.1 (Betaling) etter en skåneperiode på to uker, del 3.2 (Registreringsdata), del 5.3 (Lisens) eller del 6 (“Adferden din”).
                                </p>
                                <h3>
                                    5.3. Lisens og overdragelse av rettigheter
                                </h3>
                                <p>
                                    5.3.1 Når du registrerer deg og aktiverer produktet, får du en ikke-eksklusiv, ikke-overdragelig, men geografisk begrenset, rett til å lagre og bruke et eksemplar av appen vår til dine egne formål innenfor rammene av disse vilkårene. For betalingsprodukter er denne lisensen begrenset til abonnementsperioden din i henhold til avtalen.
                                </p>
                                <p>
                                    5.3.2 Programvaren, koden, metodene og systemene samt innholdet i produktene våre er beskyttet av opphavsretts- og konkurranselovgivning og kan utelukkende brukes av CamDiab. Produktene våre eller deler av dem skal ikke kopieres, modifiseres, gjengis, gjenutgis, postes, overføres, selges, legges ut for salg, videreselges eller brukes på annen måte uten forutgående skriftlig tillatelse fra oss. Brukere har ikke lov til å bruke CamDiabs varemerker, logoer, andre kommersielle åndsverk eller varemerkerettigheter. Med mindre annet er bestemt i disse vilkårene tilhører alle bruks- og utnyttelsesrettigheter utelukkende CamDiab, og det er ingen lisensiering av noe slag for produktene våre.
                                </p>
                                <p>
                                    5.3.3 Hvis det nødvendig for den tekniske leveringen av produktene våre, gir hver bruker CamDiab en ikke-eksklusiv, gjenkallelig, men kostnadsfri, overdragelig utnyttelses- og bruksrett, ubegrenset med tanke på tid og sted, til det innholdet de genererer, overfører, oppbevarer eller offentliggjør i produktene våre. Bruk eller utnyttelse er imidlertid utelukket hvis det har en uforholdsmessig negativ innvirkning på brukerens legitime interesser (f.eks. personvernsrettigheter). Ved bruk utenfor produktene våre vil vi, hvis det er relevant, oppgi at innholdet kommer fra brukeren. CamDiab påtar seg ikke noe eierskap til opprettet innhold og påtar seg ikke noen tilsynsrolle for innhold opprettet av brukere.
                                </p>
                                <h3>
                                    5.4. Tilgjengelighet av produktene våre
                                </h3>
                                <p>
                                    5.4.1 Vi leverer produktene våre i hvert tilfelle i overensstemmelse med eksisterende tekniske, økonomiske, driftsmessige og organisatoriske muligheter. CamDiab kan ikke utelukke avbrudd, forstyrrelser, forsinkelser, slettinger, feilaktige overføringer eller mislykket lagring i forbindelse med bruken av produktene våre eller kommunikasjonen med brukerne. Vi tilbyr produktene våre delvis i samarbeid med tredjepartsforhandlere og -leverandører og er derfor også avhengige av tekniske tjenester fra tredjepart. Derfor kan ikke CamDiab påta seg noe ansvar for, garantere eller påta seg noen skyld eller forpliktelse for å sørge for at produktene våre alltid er tilgjengelige på nett hele tiden uten avbrudd.
                                </p>
                                <p>
                                    5.4.2 Dette gjelder også begrensninger i bruken av produktene våre på grunn av force majeure, streik, lockout og direktiver fra myndigheter samt tekniske endringer eller vedlikeholdsarbeid på CamDiabs systemer. I tilfelle av avbrudd eller feil i produktene våre, kan du alltid kontakte<a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h2>
                                    6. ADFERDEN DIN
                                </h2>
                                <h3>
                                    6.1. Generell informasjon
                                </h3>
                                <p>
                                    6.1.1 Hver bruker har rett til og forpliktet til å bruke produktene våre for egen regning og risiko via passende teknisk utstyr. Du kan til enhver tid få opplyst hvilke enheter og operativsystemer som er kompatible med produktene, ved å henvende deg til <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <p>
                                    6.1.2 Du er også forpliktet til å utelukkende bruke produktene våre i overensstemmelse med disse vilkårene og gjeldende lovverk og å unngå all urettmessig bruk. Informer oss umiddelbart dersom du oppdager noen brudd på forpliktelsene som er angitt i denne delen, ved å henvende deg til <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h3>
                                    6.2. Misbruk
                                </h3>
                                <p>
                                    6.2.1 Alle brukere er forpliktet til å oppgi riktige registreringsdata, å holde dem oppdatert og fullstendige og ikke videresende dem til tredjepart. Personopplysninger må behandles fortrolig, administreres med forsiktighet og sikkerhetskopieres på eget ansvar. CamDiab påtar seg ikke noe ansvar for, og garanterer ikke for data som mistes eller skades hvis det ikke er vår skyld.
                                </p>
                                <p>
                                    6.2.2 Innhold som bilder, tekst, videoer eller andre avbildninger kan bare lagres, offentliggjøres, overføres eller distribueres i forbindelse med produktene våre hvis brukeren har rett til å overføre eller bruke dem. I alle tilfeller er det alltid forbudt å bruke rasistisk, støtende diskriminerende, ærekrenkende, seksuelt, pornografisk, voldelig og annet ulovlig innhold av alle slag.
                                </p>
                                <p>
                                    6.2.3 Det er dessuten forbudt å dekryptere, rekonstruere, dekompilere eller demontere produktene våre eller å bruke produktene våre på jailbreakede smarttelefoner. Alle brukere er forpliktet til å ikke utføre forstyrrende tiltak av teknisk eller elektronisk art i produktene våre (spesielt forsøk på hacking, forsøk på å bruke makt, introduksjon av virus, ormer, trojanske hester eller ondsinnet programvare) eller noen form for forsøk på forstyrrelse som kan påvirke programvaren eller maskinvaren i CamDiabs produkter og systemer. Enhver manipulasjon av produktene våre kan resultere i at produktene ikke fungerer som tiltenkt.
                                </p>
                                <h2>
                                    7. VÅRT ANSVAR
                                </h2>
                                <h3>
                                    7.1. Garanti og ansvar
                                </h3>
                                <p>
                                    7.1.1 Med mindre annet er bestemt i disse vilkårene tar CamDiab ansvar for og gir garantier i henhold til gjeldende lover.
                                </p>
                                <p>
                                    7.1.2 Når det gjelder brukere som ikke er forbrukere, utelukkes ansvar for økonomiske tap, følgeskader, tap av fortjeneste og skadeerstatning som følge av krav fra tredjepart. Hvis ansvaret vårt er begrenset eller utelukket i disse vilkårene, gjelder dette også CamDiabs stedfortredere og tilknyttede selskaper.
                                </p>
                                <p>
                                    7.1.3 Utover produktansvarslovgivningens omfang er CamDiabs ansvar begrenset til forsett og grov uaktsomhet – med unntak av personskade. Ansvaret for lett uaktsomhet utelukkes når det foreligger omstendigheter som gjør dette objektivt kan forsvares.
                                </p>
                                <p>
                                    7.1.4 CamDiab gir ingen garantier for at produktene våre er fullt ut tilgjengelige uten avbrudd og uten feil, og at den nødvendige programvaren og maskinvaren fungerer uten feil. Vi kan heller ikke utelukke muligheten for at data kan spores, registreres eller forfalskes av tredjeparter under dataoverførsel via tredjepartssystemer, spesielt over internett og andre telekommunikasjonsnettverk.
                                </p>
                                <p>
                                    7.1.5 CamDiab gir ingen garanti for nedlastet innhold eller materiale som brukerne har fått som følge av bruk av produktene våre. Brukeren har eneansvar for alle skader som kan oppstå på vedkommendes IT-systemer eller -enheter, eller for tap av data som følge av nedlasting av materiell i forbindelse med produktene våre.
                                </p>
                                <p>
                                    7.1.6 Videre tar CamDiab ikke noe ansvar for innhold fra tredjepart som eksterne linker, bannere, annen informasjon eller reklametilbud fra tredjeparter som kan finnes som en del av produktene våre. Hvis vi gir tilgang til tilbud fra tredjeparter via varsler eller linker, er CamDiab ikke ansvarlig for informasjonen disse inneholder. Etterfølgende juridiske transaksjoner med tredjeparter fører utelukkende til avtaleforhold mellom brukeren og den aktuelle tredjeparten. Vi gir ingen garantier og tar ikke noe ansvar for tjenester fra tredjeparter.
                                </p>
                                <h3>
                                    7.2. Ansvarsforhold og godtgjørelse
                                </h3>
                                <p>
                                    7.2.1 Bruken av produktene våre kan ikke erstatte en konsultasjon med helsepersonell eller annen medisinsk rådgivning og skjer utelukkende på brukerens egen risiko. Dette gjelder all bruk av de dataene som leveres som en del av produktene våre, deriblant blod- og sensorglukosemålinger, beregninger og eventuelle anbefalinger. Brukeren er uttrykkelig inneforstått med at slike data kan være mangelfulle, og CamDiab påtar seg ikke noe ansvar for at de er korrekte.
                                </p>
                                <p>
                                    7.2.2 I den grad det kreves i gjeldende lovgivning om medisinsk utstyr som styrer bruken av produktene, må produktene kun drives eller brukes i henhold til det formålet, de spesifikasjonene og de bruksområdene som er oppgitt i tilbudet og bruksvilkårene.
                                </p>
                                <p>
                                    7.2.3 Brukeren holder CamDiab skadeløs for alle krav fra tredjeparter mot CamDiab som følge av brukerens urettmessige krenkelse av deres rettigheter med hensyn til bruk av produktene våre. Dette påvirker ikke øvrige krav om skadeerstatning mot brukeren fra CamDiabs side.
                                </p>
                                <p>
                                    7.2.4 Brukeren påtar seg det fulle ansvar for alle skader og tvister i og utenfor domstolsystemet som følger av konflikter med andre brukere. Brukeren er uttrykkelig inneforstått med at CamDiab ikke under noen omstendigheter er ansvarlig for andre brukeres handlinger eller forsømmelse eller eventuelle skader som følger av dette.
                                </p>
                                <p>
                                    7.2.5 Hvis det skulle reises et krav mot CamDiab fra tredjeparter, er brukeren forpliktet til straks å oppgi alle opplysninger vedkommende har til rådighet, sannferdig og fullstendig som påkrevd for å gjennomgå, forsvare og forebygge krav fra tredjeparter. Brukeren bærer omkostningene til ethvert nødvendig juridisk forsvar av CamDiab, herunder alle saksomkostninger og andre juridiske omkostninger med det lovbestemte beløpet.
                                </p>
                                <h2>
                                    8. ØVRIGE BESTEMMELSER
                                </h2>
                                <h3>
                                    8.1. Lovvalg
                                </h3>
                                <p>
                                    Disse vilkårene og hele det juridiske forholdet mellom CamDiab og brukeren er helt og holdent underlagt engelsk lovgivning med unntak av referansebestemmelsene og FNs konvensjon om internasjonale kjøp. Som forbruker er brukeren beskyttet av de obligatoriske bestemmelsene i det landet brukeren befinner seg i eller normalt er bosatt.
                                </p>
                                <h3>
                                    8.2. Alminnelig verneting
                                </h3>
                                <p>
                                    Oppfyllelsesstedet og eneste verneting for alle tvister i forbindelse med disse vilkårene, avtales som CamDiabs hovedkontor i Cambridge i Storbritannia. Obligatoriske lovmessige konflikter i favør av forbrukeren påvirkes ikke.
                                </p>
                                <h3>
                                    8.3. Annet
                                </h3>
                                <p>
                                    8.3.1 Hvis underretting eller skriftlig form kreves i disse vilkårene (herunder juridisk viktige erklæringer), er overføring via e-post til den adressen som ble angitt under registreringen (CamDiab ID), tilstrekkelig.
                                </p>
                                <p>
                                    8.3.2 Brukere har ikke lov til å overføre CamDiab ID-en eller rettighetene og forpliktelsene sine ifølge disse vilkårene i forbindelse med bruk av produktene våre, til noen tredjepart. CamDiab kan overføre rettighetene og forpliktelsene sine fra disse vilkårene til et annet selskap eller tredjepart hvis en slik overføring ikke har noen vesentlig innvirkning på brukerens rettigheter i henhold til disse vilkårene.
                                </p>
                                <p>
                                    8.3.3 Hvis enkelte bestemmelser i disse vilkårene er eller blir ugyldige, forblir de øvrige bestemmelsene i vilkårene, som ikke er berørt av ugyldigheten, gyldige og gjeldende.
                                </p>
                                <p>
                                    8.3.4 Disse vilkårene finnes på flere språk. Versjonen på det språket i det landet der forbrukeren oppholder seg eller normalt er bosatt, er avgjørende og definitiv. Hvis og i det omfang det ikke er noe landsspesifikt språk tilgjengelig, gjelder den engelske versjonen.
                                </p>

                            </div>
                        </div>
                    </div>
                </section>
                <Footer props={this.props}/>
            </div>
        );
    }
}

