import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import twitter_logo from '../assets/images/twitter-logo.png';
import {footerText as footerText_en} from "../libs/en/texts-footer-lib";
import {footerText as footerText_de} from "../libs/de/texts-footer-lib";
import {footerText as footerText_fr} from "../libs/fr/texts-footer-lib";
import {footerText as footerText_nl} from "../libs/nl/texts-footer-lib";
import {footerText as footerText_it} from "../libs/it/texts-footer-lib";
import {footerText as footerText_cz} from "../libs/cz/texts-footer-lib";
import {footerText as footerText_fi} from "../libs/fi/texts-footer-lib";
import {footerText as footerText_pl} from "../libs/pl/texts-footer-lib";
import {footerText as footerText_se} from "../libs/se/texts-footer-lib";
import {footerText as footerText_no} from "../libs/no/texts-footer-lib";
import {footerText as footerText_dk} from "../libs/dk/texts-footer-lib";
import {footerText as footerText_es} from "../libs/es/texts-footer-lib";
import parse from "html-react-parser";

export class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            language: this.props.props.language,
            footerText: footerText_en,
            prefix: ''
        };
        if (this.state.language === "DE") {
            this.state.footerText = footerText_de;
            this.state.prefix = '/de';
        } else if (this.state.language === "FR") {
            this.state.footerText = footerText_fr;
            this.state.prefix = '/fr-ch';
        } else if (this.state.language === "FR-FR") {
            this.state.footerText = footerText_fr;
            this.state.prefix = '/fr';
        } else if (this.state.language === "NL") {
            this.state.footerText = footerText_nl;
            this.state.prefix = '/nl';
        } else if (this.state.language === "IT") {
            this.state.footerText = footerText_it;
            this.state.prefix = '/it';
        }else if (this.state.language === "CZ") {
            this.state.footerText = footerText_cz;
            this.state.prefix = '/cz';
        }else if (this.state.language === "FI") {
            this.state.footerText = footerText_fi;
            this.state.prefix = '/fi';
        }else if (this.state.language === "PL") {
            this.state.footerText = footerText_pl;
            this.state.prefix = '/pl';
        }else if (this.state.language === "SE") {
            this.state.footerText = footerText_se;
            this.state.prefix = '/se';
        }else if (this.state.language === "NO") {
            this.state.footerText = footerText_no;
            this.state.prefix = '/nb';
        }else if (this.state.language === "DK") {
            this.state.footerText = footerText_dk;
            this.state.prefix = '/dk';
        }else if (this.state.language === "ES") {
            this.state.footerText = footerText_es;
            this.state.prefix = '/es';
        }
    }

    setLanguageVars() {
        if (this.state.language !== this.props.props.language) {
            if (this.props.props.language === "EN") {
                this.setState({
                    language: this.props.props.language,
                    footerText: footerText_en,
                    prefix: ''
                });
            }
            if (this.props.props.language === "DE") {
                this.setState({
                    language: this.props.props.language,
                    footerText: footerText_de,
                    prefix: '/de'
                });
            } else if (this.props.props.language === "FR") {
                this.setState({
                    language: this.props.props.language,
                    footerText: footerText_fr,
                    prefix: '/fr-ch'
                });
            } else if (this.props.props.language === "FR-FR") {
                this.setState({
                    language: this.props.props.language,
                    footerText: footerText_fr,
                    prefix: '/fr'
                });
            } else if (this.props.props.language === "NL") {
                this.setState({
                    language: this.props.props.language,
                    footerText: footerText_nl,
                    prefix: '/nl'
                });
            } else if (this.props.props.language === "IT") {
                this.setState({
                    language: this.props.props.language,
                    footerText: footerText_it,
                    prefix: '/it'
                });
            } else if (this.props.props.language === "CZ") {
                this.setState({
                    language: this.props.props.language,
                    footerText: footerText_cz,
                    prefix: '/cz'
                });
            }else if (this.props.props.language === "FI") {
                this.setState({
                    language: this.props.props.language,
                    footerText: footerText_fi,
                    prefix: '/fi'
                });
            }else if (this.props.props.language === "PL") {
                this.setState({
                    language: this.props.props.language,
                    footerText: footerText_pl,
                    prefix: '/pl'
                });
            }else if (this.props.props.language === "SE") {
                this.setState({
                    language: this.props.props.language,
                    footerText: footerText_se,
                    prefix: '/se'
                });
            }else if (this.props.props.language === "NO") {
                this.setState({
                    language: this.props.props.language,
                    footerText: footerText_no,
                    prefix: '/nb'
                });
            }else if (this.props.props.language === "DK") {
                this.setState({
                    language: this.props.props.language,
                    footerText: footerText_dk,
                    prefix: '/dk'
                });
            }else if (this.props.props.language === "ES") {
                this.setState({
                    language: this.props.props.language,
                    footerText: footerText_es,
                    prefix: '/es'
                });
            }
        }
    }

    componentDidUpdate() {
        this.setLanguageVars();
    }

    render() {
        return (
            <div>
                <footer className="footer-main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <div className="footer-top">
                                    <div className="footer-col">
                                        <ul>
                                            <li><Link to={this.state.prefix + "/faq"}>
                                                {parse(this.state.footerText.txtFAQs)}</Link></li>
                                            <li><Link to={this.state.prefix + "/support"}>
                                                {parse(this.state.footerText.txtSupport)}</Link></li>
                                            <li><Link to={this.state.prefix + "/history"}>
                                                {parse(this.state.footerText.txtHistory)}</Link></li>
                                        </ul>
                                    </div>
                                    <div className="footer-col">
                                        <ul>
                                            {/*<li><a*/}
                                            {/*    href="https://s3-eu-west-1.amazonaws.com/camdiab.user.manual/user_manual_fx_mmoll_commercial.pdf">User*/}
                                            {/*    manual</a></li>*/}
                                            {/*<li> <a href="/#">Installation guide</a></li>*/}
                                            <li><a
                                                href={parse(this.state.footerText.txtTrainingHtml)}>
                                                {parse(this.state.footerText.txtTraining)}</a></li>
                                            <li><a
                                                href="https://camaps-fx.com">
                                                {parse(this.state.footerText.txtClinic)}</a></li>
                                            {/*<li> <a href="/#">Installation guide</a></li>*/}
                                        </ul>
                                    </div>
                                    <div className="footer-col">
                                        <ul>
                                            <li><Link to={this.state.prefix + "/terms"}>
                                                {parse(this.state.footerText.terms)}</Link></li>
                                            <li><Link to={this.state.prefix + "/privacy"}>
                                                {parse(this.state.footerText.privacy)}</Link></li>
                                            <li><a
                                                href={parse(this.state.footerText.statusPageFooterLink)}
                                                target="_blank" rel="noopener noreferrer">
                                                {parse(this.state.footerText.statusPageFooterText)}</a></li>
                                        </ul>
                                    </div>
                                    <div className="footer-col">
                                        <a href="https://twitter.com/CamAPS_FX">
                                            <img src={twitter_logo} alt="logo"/>
                                        </a>
                                    </div>
                                </div>
                                <div className="footer-bottom">
                                    <p>{parse(this.state.footerText.txtFooterBottom)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-copyright">
                        <p><span>&copy;</span> {new Date().getFullYear()} CamDiab Ltd. All rights reserved.</p>
                    </div>
                </footer>
            </div>
        );
    }
}

