/**
 * Texts for Reviews page
 */
export const reviewsPage = {
    title: 'Beoordelingen - CamAPS FX',
    titleMeta: 'Lees de beoordelingen van CamAPS FX-gebruikers',
    descriptionMeta: 'CamAPS FX brengt voor veel gebruikers grote verandering, door de ongeëvenaarde controle van de glucosespiegel en de vermindering van de diabetesziektelast, zowel voor de gebruikers zelf als hun gezinnen.',
    keywords:
        'camaps reviews, camaps fx reviews, ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'nl_NL',

    shortReview1:
        '<h2>Wij willen u graag laten weten dat het leven van onze dochter en van ons zo enorm is verbeterd sinds zij het CamAPS FX closed-loop-systeem heeft. </h2><p><span>Ouders van een tienermeisje</span></p>',

    shortReview2:
        '<p> Het is onmogelijk om te overschatten hoeveel het ons leven heeft veranderd. Als bij toverslag waren bijna alle problemen verdwenen, zoals de uitputting door verstoorde nachten en het continu checken van de glucosewaarden overdag. Het is echt niet overdreven om te zeggen dat CamAPS FX ons ons leven heeft teruggegeven. We moeten nog altijd koolhydraten tellen, er moet 10 minuten voor het eten nog altijd een bolus worden toegediend en ja ook de canules van de pomp moeten regelmatig worden vervangen, maar dat was het dan ook. CamAPS FX geeft ons de vrijheid om diabetes het grootste deel van de dag te vergeten.</p> <p> <span>David, vader van een meisje van 9</span> </p>',
    shortReview3:
        '<h2>Dank u. Afgelopen nacht heb ik voor het eerst sinds lange tijd weer eens echt goed kunnen slapen.</h2><p><span>Volwassen gebruiker van 60+</span></p>',

    shortReview4:
        '<p> Wij merkten echt effect op het leven van ons kind: het lopen en spreken verbeterden significant. Het was een enorm verschil met de afgelopen paar maanden. Mijn zoon is veel zelfverzekerder, minder boos en over het algemeen gelukkiger.</p> <p> <span>Moeder van een peuter</span> </p>',
};
