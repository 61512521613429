/**
 * Texts for Reviews page
 */
export const reviewsPage = {
    title: 'Kokemuksia − CamAPS FX',
    titleMeta: 'Lue CamAPS FX -sovelluksen käyttäjien kokemuksia',
    descriptionMeta: 'CamAPS FX on mullistava sovellus monille käyttäjille, sillä se tarjoaa ennennäkemättömän glukoosinseurannan ja vähentää diabeteksesta johtuvaa taakkaa käyttäjille ja heidän perheilleen.',
    keywords:
        'camaps reviews, camaps fx reviews, ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'fi_fi',

    shortReview1:
        '<h2>Haluamme vain kertoa, kuinka paljon tyttäremme ja meidän elämänlaatumme on parantunut sen jälkeen, kun hän aloitti CamAPS FX suljetun järjestelmän käytön. </h2><p><span>Teini-ikäisen tytön vanhemmat</span></p>',

    shortReview2:
        '<p> Emme pysty sanoin kuvailemaan, kuinka paljon se on muuttanut elämäämme. Lähes kaikki ongelmat ovat taianomaisesti kadonneet, kuten esim. katkonaisista yöunista johtuva uupumus tai jatkuva verensokerin mittaus päivisin. Emme liioittele sanoessamme, että CamAPS FX on tuonut meille meidän elämämme takaisin. Kyllä, meidän pitää edelleen laskea hiilihydraatteja, ja kyllä, annostelemme boluksen 10 minuuttia ennen ateriaa, ja kyllä, meidän pitää vaihtaa insuliinipumpun kanyyli, mutta siinä kaikki. CamAPS FX -sovellus antaa meille vapauden unohtaa diabeteksen suurimmaksi osasta päivästä.</p> <p> <span>David, 9-vuotiaan tytön isä</span> </p>',
    shortReview3:
        '<h2>Kiitos. Viime yönä nukuin paremmin kuin aikoihin.</h2><p><span>Yli 60-vuotias käyttäjä</span></p>',

    shortReview4:
        '<p> Huomasimme myös vaikutuksen lapsemme elämään: hänen kävelynsä ja puhumisensa on kehittynyt huomattavasti. Ero muutamaan viimeiseen kuukauteen verrattuna on todella suuri. Poikani on paljon itsevarmempi, vähemmän turhautunut ja yleisesti ottaen onnellisempi.</p> <p> <span>Taaperoikäisen äiti</span> </p>',


};
