/**
 * Texts for History page
 */
export const historyPageText = {
    title: 'Il viaggio CamDiab',
    titleMeta: 'Ricerca clinica che ha portato a CamAPS FX',
    descriptionMeta: 'Cronologia degli eventi che hanno permesso al team dedicato a CamAPS FX di aiutare le persone con diabete grazie all’algoritmo Cambridge.',
    localeMeta: 'it',

    keywords:
        'camaps storia, camaps fx storia, ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    journeyHeading: 'Il nostro viaggio CamDiab',
    timelineHeading: 'Cronologia degli eventi che hanno permesso al team dedicato a CamAPS FX di aiutare le persone con diabete grazie all’algoritmo Cambridge.',
    year2006_2010dates: '2006–2010',
    year2006_2010heading: 'Primi studi in clinica',
    year2006_2010text: 'Avvio del progetto Pancreas Artificiale a Cambridge con studi sui bambini con diabete di tipo 1. Collegato al progetto Artificial Pancreas Consortium della JDRF. Algoritmo Cambridge testato in condizioni di stress a seguito di attività fisica e consumo di alcol in soggetti adulti con diabete di tipo 1. Algoritmo Cambridge valutato in donne in gravidanza con diabete di tipo 1.',
    year2011_2014dates: '2011–2014',
    year2011_2014heading: 'Studi di fattibilità solo notturni e in condizioni di free living',
    year2011_2014text: 'Uso notturno dell’algoritmo Cambridge valutato in pazienti pediatrici, adulti e donne in gravidanza con diabete di tipo 1. Algoritmo Cambridge utilizzato sia di giorno che di notte per sette giorni dk pazienti adulti con diabete di tipo 1.',
    year2015_2016dates: '2015–2016',
    year2015_2016heading: 'Brevi studi sia diurni che notturni in condizioni di free living',
    year2015_2016text: 'Algoritmo Cambridge utilizzato per quattro settimane dk pazienti adulti con diabete di tipo 1 ben controllato. Algoritmo Cambridge utilizzato per tre settimane dk adolescenti con diabete di tipo 1.',
    year2017_2018dates: '2017–2018',
    year2017_2018heading: 'Studi diurni e notturni di durata superiore e studi in bambini piccoli',
    year2017_2018text: 'Algoritmo Cambridge utilizzato per tre mesi dk pazienti pediatrici e adulti con diabete di tipo 1. Algoritmo Cambridge utilizzato per 2 anni dk bambini e adolescenti dall’esordio del diabete. Algoritmo Cambridge utilizzato dk bambini con diabete di tipo 1 nella fascia di età dk uno a sette anni.',
    year2019_2019dates: '2019',
    year2019_2019heading: 'Passaggio a CamAPS FX ',
    year2019_2019text: 'App CamAPS FX utilizzata dk pazienti anziani con diabete di tipo 1. App CamAPS FX utilizzata dk donne in gravidanza con diabete di tipo 1 per tutto il periodo della gravidanza. App CamAPS FX utilizzata dk bambini con diabete di tipo 1 nella fascia d’età dk uno a sette anni.',
    year2020_2020dates: 'Dal 2020 in poi',
    year2020_2020heading: 'Approvazione regolatoria, commercializzazione, studi clinici',
    year2020_2020text: 'App CamAPS FX certificata CE per l’uso nella UE e nel Regno Unito. La ricerca continua per valutare i benefici e per incentivare il rimborso.',
};
