/**
 * Texts for Training page
 */
export const trainingPageText = {
    title: 'Koulutus − CamAPS FX',
    titleMeta: 'Tarjoamme maksutonta verkkokoulutusta CamAPS FX -käyttäjille',
    descriptionMeta: 'Cambridge Diabetes Education Programme tarjoaa maksutonta ja sertifioitua verkkokoulutusta tyypin 1 diabetesta sairastaville henkilöille, vanhemmille ja huoltajille, koulujen henkilökunnalle ja terveydenhoidon ammattilaisille.',
    keywords:
        'camaps koulutus, camaps fx koulutus,  ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'fi_fi',

    trainingHeader: 'Sertifioitu koulutus',
    trainingHeader_2: 'Käyttäjille, terveydenhoidon ammattilaisille ja koulujen henkilökunnalle',
    trainingHeaderText: 'MAKSUTON CamAPS FX -verkkokoulutus − Cambridge Diabetes Education Programme (CDEP).',
    websiteLink: 'https://www.camdiabtraining.com/account-sign-in.html',
    websiteLinkHeading: 'CDEP-koulutus',
    getTrained: 'Hanki koulutus',
};
