import React from 'react';

const errorMessage = props => {
  const text  = props.children;
  return(
    <p className="text-danger" dangerouslySetInnerHTML={{__html: text}}></p>)
};


/**
 * common input field component
 * @param props { field, form: { touched, errors }, ...props }
 */
const renderInput = (props) => {
	const field = props.field;
	return (
    <div>
      <label>{props.label}</label>
      <input
        type={props.type}
        label={props.label}
        name={field.name}
        value={field.value}
        className={props.className}
        onChange={field.onChange}
        onBlur={field.onBlur}
        autoComplete={props.autoComplete}
        multiline={props.multiline ? props.multiline : false}
        disabled={props.disabled ? props.disabled : false}
        rows={props.multiline ? props.rows : '1'}
      />
    </div>
	);
};

const RenderRadio = props => {
  const field = props.field;
  return (
    <div>
      <label>{field.label}</label>
      <div className="">
         <label className="" >Yes</label>
         <input type="radio" name={field.name} value={true} className=""
        
            onChange={(e) => props.setFieldValue(field.name, e.target.value)} />
      <label className="" >No</label>
      <input type="radio" name={field.name} value={false}
     
       onChange={(e) => props.setFieldValue(field.name, e.target.value)}  className=""/>
      </div>
    </div>
  )
};

/**
 * common field error message component
 * @param props
 */
const fieldErrorMessage = (props) => (
	<span className='Error'>
		{props.children}
	</span>
);

const renderSelect = props => {
  return (
    <select class="custom-select" size="3" onChange={(e) => {
      console.log(e)
    }}>
        <option selected>Open this select menu</option>
        {
          props.options.map((value, index) => {
            return <option key={index} value={value.value}>{value.name}</option>
          })
        }
    </select>
  )
};

export {
  errorMessage,
  renderSelect,
  fieldErrorMessage,
  renderInput,
  RenderRadio
}