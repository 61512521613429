/**
 * Texts for header
 */
export const headerText = {
    cookiesIntroText: 'We use cookies, just to track visits to our website, we store no personal details.',
    cookiesAccept: 'Accept cookies',
    cookiesDeny: 'Decline cookies',
    tabFAQs: 'FAQs',
    tabSupport: 'Support',
    tabHistory: 'History',
    tabReviews: 'Reviews',
    tabTraining: 'Training',
    // tabOrders: 'Orders',
    tabOrders: '',
    tabNotifications: 'Notifications',
};
