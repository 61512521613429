/**
 * Texts for Reviews page
 */
export const reviewsPage = {
    title: 'Anmeldelser – CamAPS FX',
    titleMeta: 'Læs anmeldelser fra CamAPS FX-brugere',
    descriptionMeta: 'CamAPS FX er livsændrende for mange brugere, fordi det giver hidtil uset glukosekontrol og reducerer belastningen ved at have diabetes for brugerne og deres familier.',
    keywords:
        'camaps reviews, camaps fx reviews, ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'da_dk',

    shortReview1:
        '<h2>Vi ville bare fortælle, hvor meget bedre vores datters og vores liv er blevet, efter hun fik det lukkede CamAPS FX-system. </h2><p><span>Forældre til en teenagepige</span></p>',

    shortReview2:
        '<p> Man kan ikke overdrive, hvor meget det har ændret vores liv. Som ved et trylleslag er næsten alle problemerne væk, dvs. udmattelse på grund af afbrudt nattesøvn og konstante kontroller i løbet af dagen. Det er slet ikke for meget at sige, at CamAPS FX har givet os vores liv tilbage. Ja, vi skal stadig tælle kulhydrater, ja, vi giver bolus 10 min. før måltider, og ja, vi skal skifte pumpekanyle, men det er også det. CamAPS FX giver os frihed til at glemme alt om diabetes det meste af dagen.</p> <p> <span>David, far til pige på 9 år</span> </p>',
    shortReview3:
        '<h2>Tak. I nat har jeg fået den bedste nats søvn i umindelige tider.</h2><p><span>60+ årig voksen bruger</span></p>',

    shortReview4:
        '<p> Vi har mærket en virkning på vores barns liv: Han er blevet væsentligt bedre til at gå og tale. Det har gjort en enorm forskel de sidste måneder. Min søn har haft meget mere selvtillid, har været mindre vred og generelt gladere.</p> <p> <span>Mor til en lille dreng</span> </p>',


};
