/**
 * Texts for FAQs page
 */
export const faqPageText = {
    title: 'FAQ - CamAPS FX',
    titleMeta: 'Har du en fråga om CamAPS FX?',
    descriptionMeta: 'Här hittar du svaren på några vanliga frågor om kompatibla enheter, regulatoriskt godkännande, tillgänglighet och klinisk forskning.',
    keywords:
        'camaps faqs, camaps fx faqs, ', 
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'sv_se',

    haveQuestion: 'Har du en fråga om CamAPS FX?',
    hereAnswers: 'Här hittar du svaren på några vanliga frågor.',
    whatIsCamAPS: 'Vad är CamAPS FX?',
    whatIsCamAPSAnswer: 'CamAPS FX är en sofistikerad och användarvänlig Android-app som hjälper till att hantera glukosvärden hos personer med diabetes typ 1 från ett års ålder, genom en avancerad, adaptiv hybridmetod med closed-loop.',
    isApproved: 'Är CamAPS FX godkänd för användning?',
    isApprovedAnswer: 'Appen har genomgått omfattande tester i kliniska prövningar och är godkänd för användning i bland annat Storbritannien och EU.',
    isAvailable: 'Är CamAPS FX tillgänglig där jag bor?',
    isAvailableAnswer1: 'CamAPS FX är tillgänglig på utvalda diabetesmottagningar och för personer som finansierar den själva och genomgår en webbaserad utbildning. Fråga på din mottagning eller gå till',
    isAvailableAnswer2: 'Beställningar',
    isAvailableAnswer3: 'om du vill beställa appen.',
    cost: 'Hur mycket kostar CamAPS FX?',
    costAnswer1: 'Du hittar information om priser under',
    costAnswer2: 'på webbplatsen.',
    compatibleCgm: 'Vilka kontinuerliga glukosmätare är kompatibla med CamAPS FX?',
    compatibleCgmAnswer: 'CamAPS FX är kompatibel med',
    compatibleCgmAnswer1: 'och',
    compatibleCgmAnswer2: '. Klicka på länkarna för att se kompatibla smartphones och operativsystem.',
    whatPumps: 'Vilka insulinpumpar är kompatibla med CamAPS FX?',
    whatPumpsAnswer1: 'CamAPS FX är kompatibel med',
    whatPumpsAnswer2: 'mylife YpsoPump-, Dana Diabecare RS- och DANA-i-',
    whatPumpsAnswer3: 'insulinpumpar.',
    whatPumpsAnswerHtml: 'https://advancedtherapeutics.org.uk/shop/dana-diabecare-insulin-pump/dana-rs-insulin-pump-2018',
    whatPhone: 'Vilka smarttelefoner är kompatibla med CamAPS FX?',
    whatPhoneAnswer: 'CamAPS FX körs på en Android-telefon och är kompatibel med Android-telefonmodeller som stöds av din CGM-tillverkare.',

    whatPhoneAnswer1: '',
    whatPhoneAnswer2: 'Klicka',
    whatPhoneAnswer3: 'på den här länken för en lista över enheter och operativsystem som är kompatibla med',
    whatPhoneAnswerDexcom: 'Dexcom G6-sensorn och Dexcom G6-appen.',
    whatPhoneAnswerLibre3: 'FreeStyle Libre 3-sensorn och FreeStyle Libre 3-appen.',
    whatPhoneAnswerDexcomHtml:'https://www.dexcom.com/compatibility',
    whatPhoneAnswerLibreHtml:'https://www.diabetescare.abbott/support/manuals.html',

    whereUpload: 'Var kan jag överföra mina data till och från CamAPS FX?',
    whereUploadAnswer: 'I nuläget kan data från CamAPS FX överföras till',
    whatLanguage: 'Vilka språk är tillgängliga i CamAPS FX?',
    whatLanguageAnswer: 'CamAPS FX finns på engelska, tjeckiska, danska, tyska, spanska, franska, italienska, holländska, polska, finska och svenska.',
    whereResults: 'Var hittar jag resultaten av era kliniska prövningar?',
    whereResultsAnswer1: 'Publikationer som rör användningen av Cambridges kontrollalgoritm finns att hämta',
    whereResultsAnswer2: 'här.',
    whereResultsAnswer3: 'De viktigaste publikationerna har gjorts i',
    whereResultsAnswer4: 'och',
};
