const tab = '    ';
/**
 * Texts for Order page and modal popups
 */
export const orderPageText = {
    title: 'Bestilling – CamDiab',
    titleMeta: 'Få CamAPS FX – CamDiab',
    descriptionMeta: 'Betal så lite som £70 i måneden for CamAPS FX. Få en gratis prøveperiode på én måned. Krever kompatibel Dana insulinpumpe og Dexcom G6 glukosesensor',
    localeMeta: 'nb_no',

    keywords:
        'camaps cost, camaps fx cost, camaps order, camaps fx order, camaps fx free trial',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    // discount code processing messages
    pleaseWait: 'Vent litt',
    successResult: 'Gjennomført',
    errorResult: 'Feil under behandling',
    discountApplied: 'Rabattkoden har blitt inkludert.',
    okButtonText: 'OK',

    // Discount line
    discountHeading: 'Rabatt',
    discountOver: ' over', // This reads: Discount: 30% over 3 months
    discountOneMonth: 'måned',
    discountTwoAndMoreMonths: 'måneder',

    // interest in CamAPS FX related messages
    processingEnquiry: 'Forespørselen din blir behandlet',
    thankYouForSubmission: 'Takk for at du sendte inn informasjonen din',
    processingError: 'Feil under behandling',
    responseAck: 'Vi skal vurdere forespørselen din og ta kontakt hvis CamAPS FX blir tilgjengelig i landet du er bosatt i',

    stage1: 'Trinn 1',
    subscriptionOption: 'Valg av abonnement',
    stage2: 'Trinn 2',
    equipment: 'Utstyr',
    stage3: 'Trinn 3',
    personalInfo: 'Personopplysninger',
    stage4: 'Trinn 4',
    orderConfirmation: 'Bestillingsbekreftelse',

    currencySubscription: '£',
    vatExempt: '(Fritatt fra mva.)',

    costMonthly: 80,
    costSixMonthly: 450,
    costAnnual: 840,

    costMonthlyVAT: 96,
    costSixMonthlyVAT: 540,
    costAnnualVAT: 1008,

    subscriptionMonthly: 'Månedlig abonnement',
    subscriptionSixMonthly: 'Halvårlig abonnement',
    subscriptionAnnual: 'Årlig abonnement',

    interested: 'Er du interessert i å kjøpe CamAPS FX?',
    interestedPara: 'Bruk rabattkoden <b>CamAPS30</b> for en gratis 30-dagers prøveperiode uten forpliktelser.',

    subscriptionProcess: 'Abonnementsprosessen vår',
    choosePackage: 'Velg abonnement',
    answerQuestions: 'Svar på noen spørsmål om deg selv',
    receiveTraining: 'Få sertifisert nettbasert opplæring',

    // below are texts for API
    botCheckFailed: 'Bot-kontroll mislyktes. Prøv igjen snart eller kontakt oss via support@camdiab.com',
    wrongDiscountCode: 'Feil rabattkode eller rabattkoden har utløpt',
    pumpAlreadyExists: 'Kan ikke fullføre bestillingen. Pumpen med serienummer %s er allerede i bruk. Kontroller pumpens serienummer eller kontakt oss via sales@camdiab.com.',
    costTextWithDiscount: '%s%s for den første perioden (rabattkode %s) og deretter %s%s for de etterfølgende periodene',
    emailOrderSubjectLine: 'CamAPS FX bestillingen din',
    emailTrainingSubjectLine: 'Opplæring i CamAPS FX',
    emailInterestedSubjectLine: 'CamAPS FX forespørsel (%s)',

    emailVATexemptYes: 'Ja',
    emailVATexemptNo: 'Nei',

    emailOrderBody: "Til %s<p>Vi bekrefter bestillingen din av CamAPS FX appen. Bestillingsinformasjonen er følgende:<p>" +
        tab + "Navn: %s %s<br>" +
        tab + "E-postadresse for kontakt: %s<br>" +
        tab + "Pumpens serienummer: %s<br>" +
        tab + "Abonnementsperiode: %s<br>" +
        tab + "%s<br>" +
        tab + "Fritatt fra mva.: %s<br>" +
        tab + "Klinikk der opplæring ble gjennomført: %s<br><p><strong>Neste trinn</strong><br>Du kan enten utføre <a href=\"https://camdiabtraining.com/\">CamAPS FX opplæringen på nettet</a> eller så kan diabetesveilederen din vise deg hvordan appen brukes, ansikt til ansikt. Du kan laste ned CamAPS FX appen fra Amazon Appstore, men du vil ikke kunne bruke den før du har fått opplæring og diabetesveilederen din låser opp appen ved å oppgi en sertifisert CamAPS opplæringskode. Etter at du har fått opplæring, kan du bruke CamAPS FX appen umiddelbart.<p><strong>Betaling</strong><br>Når appen er låst opp og du kan begynne å bruke den, sender vi deg en faktura på e-post. Vi foretrekker betalinger med avtalegiro. Du må betale i riktig tid for å kunne fortsette å bruke CamAPS FX appen. Dette påvirker ikke de lovpålagte rettighetene dine. Du kan avbestille når som helst før opplæring eller innen 14 dager etter opplæring for å få tilbake hele beløpet du har betalt.<p><strong>Abonnementsperiode</strong><br>Abonnementsperioden starter fra datoen du får opplæring. Vær oppmerksom på at ingen betalinger utføres før du har fått opplæring. Etter den første abonnementsperioden blir du nødt til å betale for hver etterfølgende abonnementsperiode med mindre du sender en skriftlig avbestilling til %s.<p>Takk for handelen. Vi håper du har nytte av å bruke CamAPS FX appen.<p>CamDiab teamet<br>%s",

    emailPotentialUser: "Til %s %s<p>Vi forstår at du er interessert i å bruke CamAPS FX appen. Du har imidlertid oppgitt at du ikke kan få sikker opplæring i CamAPS FX appen på klinikken der diabetesen din blir behandlet. Vi kan muligens arrangere opplæring for deg for din regning. Informasjonen du har oppgitt, er:<p>" +
        tab + "Navn: %s %s<br>" +
        tab + "E-postadresse for kontakt: %s<br><p><strong>Neste trinn</strong><br>Vi sender forespørselen din til instruktørene i CamAPS FX som vi samarbeider med, og som kanskje kan hjelpe deg. Hvis det er tilfelle, får du en egen e-post om det. Når du har avtalt opplæringen med instruktøren din, må du sende inn CamAPS FX bestillingen via <a href=\"https://camdiab.com/\">camdiab.com</a>.<p><strong>Betaling for opplæring</strong><br>Du må avtale prisen for opplæringen med CamAPS FX instruktøren og betale denne prisen for seg. Disse tjenestene leveres ikke av CamDiab. <p><strong>CamAPS-instruktører</strong><br>Vi verdsetter CamAPS FX instruktørene våre som har gjennomført passende opplæring.<p>Takk for interessen du viser for CamAPS FX appen.<p>CamDiab teamet<br>%s",

    emailInterestedUser: "Til %s %s<p>Takk for interessen du viser for å bruke CamAPS FX appen. Vi har registrert interessen din og tar kontakt hvis CamAPS FX blir tilgjengelig i %s. Kontaktinformasjonen din:<p>" +
        tab + "Navn: %s %s<br>" +
        tab + "E-postadresse for kontakt: %s<br>" +
        tab + "Land: %s<br><p>Takk for interessen du viser for å bruke CamAPS FX appen.<p>CamDiab teamet<br>%s",
    emailOrderBody_1: 'Takk for at du har registrert deg for å bruke CamAPS FX appen',
    emailOrderBody_2: 'Til %s',
    emailOrderBody_3: 'Vi bekrefter bestillingen din av CamAPS FX appen. Bestillingsinformasjonen er følgende:',
    emailOrderBody_4: 'Navn:',
    emailOrderBody_5: 'E-postadresse for kontakt:',
    emailOrderBody_6: 'Pumpens serienummer:',
    emailOrderBody_7: 'Abonnementsperiode',
    emailOrderBody_8: 'Pris:',
    emailOrderBody_9: 'Fritatt fra mva.:',
    emailOrderBody_10: 'Klinikk der opplæring ble gjennomført:',
    emailOrderBody_11: 'Neste trinn',
    emailOrderBody_12: 'Du får tilsendt en faktura <b>etter</b> at du har begynt å bruke CamAPS FX appen.',
    emailOrderBody_13: 'Vi anbefaler at du tar CamAPS FX opplæringen på nettet.',
    emailOrderBody_14: 'Ellers kan diabetesveilederen din vise deg hvordan appen brukes, ansikt til ansikt.',
    emailOrderBody_15: 'Du kan laste ned CamAPS FX appen fra Amazon Appstore, men du vil ikke kunne bruke den før du har fått opplæring og diabetesveilederen din eller du låser opp appen ved å oppgi en sertifisert CamAPS opplæringskode. ',
    emailOrderBody_16: 'Når appen er låst opp kan du bruke den umiddelbart.',
    emailOrderBody_17: 'Betaling',
    emailOrderBody_18: 'Etter opplæringen sender vi deg en faktura på e-post. Du må betale i riktig tid for å kunne fortsette å bruke CamAPS FX appen. Dette påvirker ikke de lovpålagte rettighetene dine. Du kan avbestille når som helst før opplæring eller innen 30 dager etter opplæring for å få tilbake hele beløpet du har betalt.',
    emailOrderBody_19: 'Abonnementsperiode',
    emailOrderBody_20: 'Abonnementsperioden starter fra og med datoen du får opplæring. Vær oppmerksom på at ingen betalinger utføres før du har fått opplæring. Etter den første abonnementsperioden må du betale for hver etterfølgende abonnementsperiode med mindre du sender en skriftlig avbestilling til sales@camdiab.com.',
    emailOrderBody_21: 'Takk for at du abonnerer på CamAPS FX appen',
    emailOrderBody_22: 'Hvis du trenger teknisk hjelp, kan du gå til nettstedet vårt for mer informasjon.',
    emailOrderBody_23: '© 2023 CamDiab Ltd. Med enerett.',

    // Texts for step one form
    s1MonthlyHeading: 'Månedlig abonnement',
    s1MonthlyValue: '£80*',
    s1Select: 'Velg',
    s1HalfYearlyHeading: 'Halvårlig abonnement',
    s1HalfYearlyText: '£450*',
    s1HalfYearlyTextSaving: '(du sparer £30)',
    s1YearlyHeading: 'Årlig abonnement',
    s1YearlyText: '£840*',
    s1YearlyTextSaving: '(du sparer £120)',
    s1VATtext: ' *Pluss mva. med mindre du er fritatt fra mva. Full betaling etter opplæring / aktivering av appen.',

    // Texts for step two form
    previous: 'Forrige',
    next: 'Neste',
    s2Yes: 'Ja',
    s2No: 'Nei',
    s2HaveDanaPump: 'Har du en mylife YpsoPump, DANA Diabecare RS eller DANA-i pumpe?',
    s2HaveCgm: 'Har du en Dexcom G6 vevsglukosemåler?',
    s2Phone1: 'Har u en kompatibel',
    s2PhoneHtml: 'https://www.dexcom.com/dexcom-international-compatibility',
    s2Phone2: 'Android-telefon',
    s2Phone3: '?',
    s2NoPump: 'For å kunne bruke CamAPS FX appen må du bruke mylife YpsoPump, DANA Diabecare RS eller DANA-i insulinpumpen. Ta kontakt med diabetesteamet ditt og drøft alternativene dine, eller ta kontakt med din lokale Dana distributør.',
    s2NoCgm: 'For å kunne bruke CamAPS FX appen må du bruke Dexcom G6 vevsglukosemåleren. Snakk med diabetesteamet ditt om Dexcom G6 vevsglukosemåleren eller kontakt Dexcom.',
    s2NoPhone: 'Hvis ikke du bruker en kompatibel Android-telefon, kan vi ikke garantere en stabil forbindelse med Dexcom G6 sensoren din. Vurder å kjøpe en kompatibel Android-telefon.',

    // Texts for step three form
    s3InfoSource: [
        'Klinikk',
        'Søkemotor',
        'Sosiale medier',
        'Et innlegg fra en venn på sosiale medier',
        'JDRF',
        'Diabetes UK',
        'Radio',
        'TV',
        'Magasin',
        'Muntlig',
        'Annet'],

    // s3TeritoryQuestion: 'Are you based in the UK, the EU, or New Zealand?',
    // s3TeritoryQuestion: 'Are you based in the UK or the EU?',
    s3TeritoryQuestion: 'Bor du i Storbritannia?',
    s3NotAvailable: 'CamAPS FX appen er dessverre bare tilgjengelig i utvalgte land. Fyll ut informasjonen din hvis du vil at vi skal kontakte deg når systemet blir tilgjengelig i landet du er bosatt i.',
    s3SelectCountry: 'Velg land',
    s3TC1: 'Ved å sende inn forespørselen din godtar du',
    s3TC2: 'vilkårene',
    s3TC3: 'og',
    s3TC4: 'Personvern',
    s3TC5: 'gir oss tillatelse til å kontakte deg.',
    s3Training1: 'Du må få opplæring av en sertifisert instruktør og/eller ta',
    s3Training2: 'nettbasert opplæring',
    s3Training3: 'før du kan begynne å bruke CamAPS FX appen.',

    s3FirstName: 'Fornavn',
    s3LastName: 'Etternavn',
    s3Email: 'E-postadresse',
    s3EmailConfirm: 'Bekreft e-postadressen',
    s3AddressLine1: 'Adresselinje 1',
    s3AddressLine2: 'Adresselinje 2',
    s3AddressLine3: 'Adresselinje 3',
    s3PostCode: 'Postnummer',
    s3SubmitCountry: 'Send inn',
    s3DisabilityHeading: 'Jeg erklærer at jeg har følgende uførhet eller kroniske sykdom:',
    s3Disability: 'Uførhet eller kronisk sykdom',
    s3signature: 'Underskrift (skriv inn navnet ditt)',

    s3TrainingCdep1: 'Velg CamAPS nettbasert opplæring for å fortsette:',
    s3TrainingCdep2: 'Velg opplæring',
    s3TrainingCdep3: 'CamAPS nettbasert opplæring',

    s3contactHeading: 'Skriv inn kontaktinformasjonen din',
    s3whereHeard: 'Hvor hørte du om CamAPS FX appen?',
    s3whereHeardSelect: 'Velg en av de følgende',
    s3pumpSerialNumber: 'Serienummeret til Dana pumpen din:',
    s3pumpSerialNumberConfirm: 'Bekreft serienummeret til Dana pumpen:',
    s3pumpSerialNumberMessage: 'Viktig:',
    s3pumpSerialNumberMessage1: 'Du må angi pumpens serienummer for å gjøre det mulig for CamAPS FX appen å knytte seg til pumpen din. Hvis du oppgir feil serienummer, kan kanskje ikke CamAPS FX appen knytte seg til pumpen din. Du finner serienummeret på siden av pumpen. Eksempel på serienummer: THF00041FB.',
    s3vatExempt: 'Er du fritatt fra mva.?',
    s3vatHelp1: 'Du finner mer informasjon fra hjelpearkene på',
    s3vatHelpHtml: 'https://www.gov.uk/government/organisations/hm-revenue-customs/contact/vat-reliefs-for-disabled-and-older-people',
    s3vatHelpLine: 'Råd ved mva.-fritak',
    s3vatHelp2: 'nettstedet GOV.UK',
    s3vatHelp3: 'eller ved å ringe hjelpetelefonen for mva.-fritak for uføre på 0300 123 1073.',
    s3vatHelp4: 'HMRC-personell kan ikke si noe om en person er kronisk syk eller ufør eller ikke.',
    s3vatHelp5: 'Viktig:',
    s3vatHelp6: 'Hvis du bestiller CamAPS FX appen på vegne av noen andre, må du fylle ut skjemaet om mva.-fritak med informasjonen til vedkommende.',
    s3PhoneWarning: 'Hvis ikke du bruker en kompatibel Android-telefon, kan vi ikke garantere en stabil forbindelse med Dexcom G6 sensoren din. Vurder å kjøpe en kompatibel Android-telefon',

    s3NameMissing: 'Fornavn er obligatorisk',
    s3SurnameRequired: 'Etternavn er obligatorisk',
    s3EmailRequired: 'E-postadresse er obligatorisk',
    s3CountryRequired: 'Land er obligatorisk',
    s3EmailConfimrRequired: 'Bekreftelse av e-postadresse er obligatorisk',
    s3EmailMatchRequired: 'E-postadressene må være like',
    s3ClinicRequired: 'Klinikk der opplæring ble gjennomført, er obligatorisk',
    s3DiseaseStateRequired: 'Type bruker må oppgis',
    s3OnlyTextValueProvided: 'Inneholder bare tekst',
    s3OnlyNumberValueProvided: 'Inneholder bare tall',
    s3SerialNumbersMatch: 'Serienumre må være like',
    s3vatStatusRequired: 'Mva.-status må oppgis',
    s3AddressLine1Required: 'Adresselinje 1 er obligatorisk',
    s3AddressLine2Required: 'Adresselinje 2 er obligatorisk',
    s3PostCodeRequired: 'Postnummer er obligatorisk',
    s3SickessRequired: 'Uførhet eller kronisk sykdom er obligatorisk',
    s3SignatureRequired: 'Underskrift er obligatorisk',

    // Texts for step four form
    s4NextSteps:'Neste trinn',
    s4DownloadApp:'Du kan laste ned CamAPS FX appen fra',
    s4DownloadGuideHtml:'https://s3-eu-west-1.amazonaws.com/camdiab.user.manual/Downloading+CamAPS+FX.pdf',
    s4DownloadAppGuide:'Installasjonsveiledning',
    s4Appstore:'Amazon Appstore,',
    s4DownloadApp1:'men du vil ikke kunne bruke den før du har fått',
    s4TrainingTextLink:'opplæring',
    s4TrainingHtml:'https://camdiab.com/training',
    s4TrainingTitle:'Opplæring',

    s4Payment:'Betaling belastes når CamAPS FX appen knyttes til pumpen din.',

    s4TermsAccept:'Jeg godtar',
    s4TermsTermsHtml:'https://camdiab.com/terms',
    s4TermsTermsText:'Vilkår',
    s4TermsPrivacyHtml:'https://camdiab.com/privacy',
    s4TermsPrivacyText:'Personvernerklæring',

    s4DiscountCodeText:'Rabattkode (valgfritt)',
    s4DiscountApplyCode:'Bruk kode',

    s4Summary:'Bestillingssammendrag',
    s4Name:'Navn:',
    s4Email:'E-post:',
    s4PumpSN:'Pumpens serienummer:',
    s4Training:'Opplæring:',
    s4Total:'Totalt',

    s4CompleteButtonText:'Fullfør',

    // duration or subscription period in an email
    emailOneMonth: '1 måned',
    emailSixMonths: '6 måneder',
    emailTwelveMonths: 'Årlig',

    // Order complete
    oderProcessedHeading: 'Bestillingen din blir behandlet',
    oderProcessedSucess: 'Bestillingen var vellykket. Takk!',
    oderProcessedError: 'Feil under behandling',
    oderProcessedEmailToBeSent: 'Du mottar snart en bekreftelse på e-post',
};
