/**
 * Texts for Training page
 */
export const trainingPageText = {
    title: 'Opplæring – CamAPS FX',
    titleMeta: 'Vi tilbyr gratis nettbasert opplæring for CamAPS FX brukere',
    descriptionMeta: 'Cambridge Diabetes Education Programme tilbyr gratis nettbasert sertifisert opplæring for personer med diabetes type 1, foresatte, skolepersonell og helsepersonell.',
    keywords:
        'camaps training, camaps fx training,  ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'nb_no',

    trainingHeader: 'Sertifisert opplæring',
    trainingHeader_2: 'For brukere, helsepersonell og skolepersonell',
    trainingHeaderText: 'GRATIS nettbasert opplæring i CamAPS FX levert av Cambridge Diabetes Education Programme (CDEP).',
    websiteLink: 'https://camdiabtraining.com',
    websiteLinkHeading: 'CDEP-opplæring',
    getTrained: 'Få opplæring',
};
