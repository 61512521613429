/**
 * Texts for Reviews page
 */
export const reviewsPage = {
    title: 'Recensioner – CamAPS FX',
    titleMeta: 'Läs recensioner från CamAPS FX-användare',
    descriptionMeta: 'CamAPS FX förändrar livet för många användare genom att erbjuda glukoskontroll på en nivå som de aldrig tidigare upplevt och gör det därmed lättare att leva med diabetes, både för dem själva och deras anhöriga.',
    keywords:
        'camps recensioner, camps fx recensioner, ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'sv_se',

    shortReview1:
        '<h2>Vi vill bara berätta hur mycket bättre tillvaron har blivit både för oss och vår dotter sedan hon fick closed-loop-systemet CamAPS FX. </h2><p><span>Föräldrar till en tonårsflicka</span></p>',

    shortReview2:
        '<p> Det går inte att överskatta hur mycket det har påverkat våra liv. Som genom ett trollslag har nästan alla problem försvunnit, som tröttheten efter oroliga nätter och ständiga kontroller under dagen. Det är ingen överdrift att säga att CamAPS FX har gett oss våra liv tillbaka. Visst, vi får fortfarande lov att räkna kolhydrater, ge bolusdos tio minuter före måltiderna och byta kanyl på pumpen, men det är allt. CamAPS FX ger oss friheten att slippa tänka på diabetes större delen av dagen.</p> <p> <span>David, pappa till en 9-årig flicka</span> </p>',
    shortReview3:
        '<h2>Tack! I natt sov jag bättre än jag har gjort på länge.</h2><p><span>Vuxen användare 60+</span></p>',

    shortReview4:
        '<p> Vi har märkt en övergripande inverkan på vår sons liv, med rejäla framsteg i utvecklingen av gång- och talförmåga. Det har gjort en enorm skillnad under de senaste månaderna. Min son har blivit mycket mer trygg i sig själv, mindre arg och generellt gladare.</p> <p> <span>Mamma till en pojke i småbarnsåldern</span> </p>',
};
