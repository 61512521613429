 /**
 * Texts for Notifications page
 */
export const notificationsPageText = {
    title: 'Nedávná oznámení v aplikaci',
    titleMeta: 'Zobrazují se poslední oznámení v aplikaci CamAPS FX',
    descriptionMeta: 'Zde je text nedávných oznámení v aplikaci týkajících se aktualizací aplikací, bezpečnostních upozornění a aktualizací uživatelských příruček',
    keywords: 'oznámení' ,
    localeMeta: 'cs_cz',

    heading: 'Nedávná oznámení v aplikaci',
    subheading: '',

    header_27:'',
    body_27:'',

	header_26:'Aktualizace uživatelské příručky 11/05/2024',
    body_26:'K dispozici je aktualizovaná verze uživatelské příručky.',
	
	header_25:'',
    body_25:'',
	
	header_24:'',
    body_24:'',
	
	header_23:'Nová aktualizace 24/04/2024',
    body_23:'Vážený uživateli mylife CamAPS FX, děkujeme vám za vaši trpělivost. Nová verze aplikace 1.4(180) je dokončena a bude zpřístupněna během následujících 24 hodin. Chcete-li zvolit preferovaný čas aktualizace, vypněte automatickou aktualizaci v obchodě Google Play. V případě jakýchkoli dotazů se obraťte na zákaznický servis společnosti Ypsomed.  Omlouváme se za případné nepříjemnosti, které Vám mohly být tímto způsobeny. Váš tým CamDiab',
	
	header_22:'Aktualizace uživatelské příručky 13/04/2024',
    body_22:'K dispozici je aktualizovaná verze uživatelské příručky.',
	
	header_21:'',
    body_21:'',
	
	header_20:'Nová aktualizace 25/03/2024',
    body_20:'Vážený uživateli mylife CamAPS FX,  víme, že u některých z Vás nadále přetrvávají problémy s poslední aktualizací. V současné době rozšiřujeme testování opravy na širší skupinu uživatelů. Jakmile bude opravná verze k dispozici v obchodu Google Play, budeme Vás ihned informovat. Děkujeme vám za trpělivost, Váš tým CamDiab.',
	
	header_19:'Nová aktualizace 21/03/2024',
    body_19:'Někteří uživatelé se stále potýkají s problémy se současnou verzí mylife CamAPS FX, a proto finalizujeme vydání opravy chyb a testování ve verzi 1.4(180). O plánovaném vydání aktualizace budete brzy informováni. Omlouváme se za případné nepříjemnosti, které Vám mohli být tímto způsobeny.',
	
	header_18:'Nová aktualizace 17/03/2024',
    body_18:'Omlouváme se za trvající problémy, které někteří uživatelé mohou zažívat s nejnovější verzí. Nová aktualizace (verze 1.4(179)) bude k dispozici v pondělí. Pokud si přejete vybrat preferovaný čas pro aktualizaci, vypněte automatické aktualizace v Google Play. Pokud máte ztrátu dat v aplikaci Glooko, prosím znovu propojte svůj účet Glooko v nabídce ‘Sdílet’',
	
	header_17:'Nová aktualizace 11/03/2024',
    body_17:'Omlouváme se za pokračující problémy, které někteří uživatelé zažívají s nejnovější verzí aplikace. Nová aktualizace, která odstraňuje tyto problémy (verze 1.4(178)), bude k dispozici již zítra.Chcete-li si vybrat preferovaný čas aktualizace, zakažte automatické aktualizace v Obchodě Google Play.',
	
	header_16:'Aktualizace uživatelské příručky 04/03/2024',
    body_16:'K dispozici je aktualizovaná verze uživatelské příručky.',
	
	header_15:'Aktualizace uživatelské příručky 04/01/2024',
    body_15:'K dispozici je aktualizovaná verze uživatelské příručky.',
	
	header_14:'Android OS 14. 22/11/2023',
    body_14:'Připomínka: Aby bylo zajištěno nepřetržité používání aplikace mylife CamAPS FX, zkontrolujte, prosím, webové stránky výrobce senzoru, zda schválil kompatibilitu před aktualizací na Android OS 14. Upozorňujeme, že v nastavení telefonu mohou být zapnuté automatické aktualizace.',
	
	header_13:'BEZPEČNOSTNÍ UPOZORNĚNÍ V TERÉNU 14/11/2023',
    body_13:'BEZPEČNOSTNÍ UPOZORNĚNÍ V TERÉNU.',
    body_13_link_text:'Přečtěte si a potvrďte čtení důležitého upozornění',
    body_13_end: '. Přejděte do Menu aplikace -> Bezpečnostní upozornění.',
    body_13_link:'https://s3.eu-west-1.amazonaws.com/camaps.safety.notices/Field+Safety+Notice+ET5604+Mylife+Users+final+(2)+-+CZ.pdf',

	header_12:'Aktualizace uživatelské příručky 10/11/2023',
    body_12:'K dispozici je aktualizovaná verze uživatelské příručky.',
	
	header_11:'Android OS 14. 12/09/2023',
    body_11:'Aby bylo zajištěno nepřetržité používání aplikace mylife CamAPS FX, zkontrolujte, prosím, webové stránky výrobce senzoru, zda schválil kompatibilitu před aktualizací na Android OS 14. Upozorňujeme, že v nastavení telefonu mohou být zapnuté automatické aktualizace.Před aktualizací na Android OS 14 se ujistěte, že používáte aplikaci mylife CamAPS FX verze 1.4(173).',
	
	header_10:'BEZPEČNOSTNÍ UPOZORNĚNÍ V TERÉNU 15/08/2023',
    body_10:'BEZPEČNOSTNÍ UPOZORNĚNÍ V TERÉNU. Přečtěte si a potvrďte čtení důležitého upozornění. Přejděte do Menu aplikace -> Bezpečnostní upozornění.',
	
	header_9:'Aktualizace uživatelské příručky 24/07/2023',
    body_9:'K dispozici je aktualizovaná verze uživatelské příručky.',
	
	header_8:'Kompatibilita Glooko 21/07/2023',
    body_8:'Jak bylo oznámeno dříve letos, diasend bude ukončen 28. července. Pokud chcete i nadále sdílet data se svým diabetologickým týmem, aktualizujte své diasend konto na Glooko nebo si vytvořte nové konto Glooko. Aktualizujte na nejnovější verzi mylife CamAPS FX, zadejte své nové jméno a heslo Glooko v menu sdílení a restartujte smartphone. Diasend bude i nadále dostupný pro prohlížení historických dat po zbytek roku.',
	
	header_7:'Samsung A-Series 17/07/2023',
    body_7:'Uživatelé modelů Samsung A-Series (např. A53, A33) po poslední aktualizaci Samsung One UI 5.1 možná zaznamenali ztrátu signálu u svého CGM senzoru. Samsung problém identifikoval a řešení je ve vývoji. Samsung plánuje 24. července (v závislosti na regionu) vydat aktualizaci One UI, která problém se ztrátou signálu vyřeší. Doporučuje se nainstalovat aktualizaci, jakmile bude k dispozici. Pokud jste ještě neaktualizovali na One UI 5.1, doporučujeme počkat na další aktualizaci Samsung One UI.',

    header_6:'Kompatibilita Glooko 02/06/2023',
    body_6:'mylife CamAPS FX je nyní kompatibilní s Glooko. Prosím, aktualizujte svou aplikaci mylife CamAPS FX. Pokud sdílíte data s klinikou, budete upozorněni prostřednictvím vašeho stávajícího účtu diasend, když klinika přejde na Glooko. Jakmile je to hotovo, zadejte své nové uživatelské jméno a heslo pro Glooko do nabídky sdílení a restartujte svůj smartphone. Prosím, aktualizujte na Glooko před 28. červencem 2023. Diasend bude dostupný pro zobrazení historických dat po zbytek roku.',

    header_5:'',
    body_5:'',

    header_4:'Aktualizace uživatelské příručky 27/05/2023',
    body_4:'K dispozici je aktualizovaná verze uživatelské příručky.',

    header_3:'SMS upozornění (Pouze Ypsopump)  11/05/2023',
    body_3:'S připravovanou aktualizací 1.4 (171) v polovině května budete moci aktivovat až 5 sledovatelů SMS a současně používat režim společníka (Companion). Pro použití režimu společníka musí mít telefon uživatele internetové připojení pro sdílení dat. Uživatelé musí mít SIM kartu, aby mohli odesílat SMS z jejich telefonu. Pokud používáte jednu z těchto možností, doporučujeme vám zkontrolovat, zda váš mobilní tarif zahrnuje pokrytí SMS a/nebo dat v závislosti na tom, kterou možnost používáte.',

    header_2:'Aktualizace kybernetické bezpečnosti (Pouze Ypsopump) 24/04/2023',
    body_2:'Bezpečnost aplikace mylife CamAPS FX má zásadní význam. Od poloviny května bude v obchodě Google Play probíhat vynucená aktualizace kybernetické bezpečnosti. Po této aktualizaci může malá část telefonů selhat při kontrole integrity kybernetické bezpečnosti. Telefon můžete předem otestovat v nabídce nápovědy a provést kontrolu. Pokud telefon touto kontrolou neprojde, zkuste aplikaci odinstalovat a znovu nainstalovat.',

    header_1:'',
    body_1:'',
};
