import React, {Component} from 'react';
import {Header} from '../Components/Header';
import {Footer} from '../Components/Footer';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

export class TermsSE extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Allmänna villkor - CamAPS FX</title>
                    <meta name="title" content='Allmänna villkor'/>
                    <meta name="description"
                          content='Här hittar du CamDiab Allmänna villkor'/>
                    <meta property="og:locale" content='sv_SE'/>
                    <meta name="keywords" content='Allmänna villkor'/>
                </Helmet>
                <Header props={this.props}/>
                <section className="inner-banner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-10">
                                <h1>Allmänna villkor</h1>
                                <h3>Version daterad 24 juni 2024</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <h2>
                                    1. OMFATTNING
                                </h2>
                                <h3>
                                    1.1. Våra produkter
                                </h3>
                                <p>
                                    1.1.1. Vi,
                                </p>
                                <p>
                                    CamDiab Ltd <strong>
                                    <br/>
                                    Registrerad kontorsadress<br/>
                                    20-22 Wenlock Road<br/>
                                    London, N1 7GU, Storbritannien
                                    <br/>
                                </strong>
                                    support@camdiab.com
                                    <br/>
                                    organisationsnummer 11659211 i England och Wales
                                    <br/>
                                    <br/>
                                </p>
                                <p>
                                    (”<strong>CamDiab</strong>”), utvecklar och driver mobilapplikationer (appar) för insulindosering med closed-loop och kopplad datainfrastruktur för personer med diabetes samt hälso- och sjukvårdspersonal. CamDiab är ett e-hälsoföretag som vill erbjuda personer med diabetes bättre glukoskontroll och minska bördan av egenvård vid diabetes.
                                </p>
                                <p>
                                    1.1.2 Våra produkter vägleder och stöder insulindosering med closed-loop enligt vår bedömning och den kunskap vi besitter. Våra produkter kan inte ersätta rådgivning och diagnos som ges av hälso- och sjukvårdspersonal. Om du har frågor om sjukdomen eller behandlingen rekommenderar vi att du tar kontakt med vården. De data som tillhandahålls får endast analyseras av dig själv och hälso- och sjukvårdspersonalen. Inget innehåll – vare sig det tillhandahålls av CamDiab, våra samarbetspartners, distributörer, entreprenörer eller användare – ska betraktas som komplement till eller ersättning för information från hälso- och sjukvårdspersonal eller apotekspersonal.
                                </p>
                                <p>
                                    1.1.3 CamDiab kan erbjuda ett utbud av produkter (appar) och tjänster samt tillåta delning av data för att hjälpa dig att hantera din diabetes. Alla befintliga och framtida appar och tjänster (inklusive nya versioner, uppdateringar och andra förbättringar) som CamDiab tillhandahåller på något sätt benämns hädanefter gemensamt som ”<strong>produkter</strong>” eller var för sig som en ”<strong>produkt</strong>”. Varje person som skaffar och använder en eller flera av våra produkter benämns gemensamt och var för sig som ”<strong>användare</strong>”.
                                </p>
                                <p>
                                    1.1.4 I samband med dessa villkor gäller följande definitioner av termer för enskilda produkter:
                                </p>
                                <p>
                                    <strong>CamAPS FX </strong> är en fristående mobilapplikation (app) till din smarttelefon som erbjuder ett hybridsystem för insulindosering med closed-loop för personer med diabetes typ 1. Appen kopplas till en glukossensor från tredje part och en insulinpump från tredje part. Du kan välja att dela data som samlas in av appen med CamDiab eller med en eller flera diabetesportal(er) från tredje part för att visa dina data. Det kan finnas begränsningar för vilka modeller av Android-smarttelefoner som kan användas beroende på pumpen och glukossensorn från tredje part.
                                </p>
                                <p>
                                    <strong>CamAPS HX </strong> är en liknande app som CamAPS FX men erbjuder helt sluten slinga vid icke-typ 1-diabetes.
                                </p>
                                <p>
                                    1.1.5 Eventuella särskilda omständigheter lyfts fram i vår(a) bruksanvisning(ar), som finns tillgängliga i våra appar och via vår webbplats, samt i de särskilda bestämmelserna för respektive produkt.
                                </p>
                                <h3>
                                    1.2. Villkorens omfattning
                                </h3>
                                <p>
                                    1.2.1 Vi erbjuder våra produkter exklusivt enligt den gällande versionen av dessa allmänna villkor, som gäller i hela världen. CamDiab tar uttryckligen avstånd från eventuella motstridiga affärs- eller användningsvillkor.
                                </p>
                                <p>
                                    1.2.2 Observera att du måste ge ditt samtycke till alla förklaringar och avtal som nämns innan du använder våra produkter för första gången, och acceptera dem vid eventuell fortsatt användning. Det är inte tillåtet att registrera sig i eller använda våra produkter utan att godkänna våra villkor.
                                </p>
                                <p>
                                    1.2.3 Vi förbehåller oss den uttryckliga rätten att göra framtida ändringar och rimliga anpassningar av våra villkor. Sådana förändringar kan bli nödvändiga för att uppfylla lagstadgade krav, bemöta tekniska eller ekonomiska krav eller tillgodose användarnas behov. Förändringar kan komma att göras när som helst och kommer att publiceras på lämpligt sätt. Om inga invändningar lämnas inom en månad omfattas den fortsatta användningen av våra produkter av respektive nya villkor.
                                </p>
                                <h3>
                                    1.3. Tredjepartsleverantörer
                                </h3>
                                <p>
                                    1.3.1 Du hittar våra produkter i de vanliga appbutikerna. I allmänhet är det den relevanta tredjepartsleverantörens affärsvillkor som gäller där, och dessa har vi ingen möjlighet att påverka. De villkoren ingår inte i våra villkor eller i vårt avtalsförhållande till dig som användare av våra produkter. Detsamma gäller affärsvillkoren från din mobiltelefontillverkare och mobiloperatör.
                                </p>
                                <p>
                                    1.3.2 Vi samarbetar med flera olika partners för att säkerställa att våra produkter kan användas på ett optimalt sätt. Det rör sig bland annat om samarbetspartners som direkt förser våra användare med vissa produkter (t.ex. insulinpump och kontinuerlig glukosmätare). I dessa fall framhåller vi de tillämpliga affärsvillkoren från denna tredjepartsleverantör eftersom du måste acceptera dessa villkor innan du kan börja använda våra produkter.
                                </p>
                                <h2>
                                    2. DATASKYDD
                                </h2>
                                <p>
                                    <strong> Vi är medvetna om det stora ansvar som faller på oss när du använder våra produkter. Du förser oss inte bara med personuppgifter utan också med uppgifter om din hälsa. I vår</strong>
                                    <Link to="/se/privacy">
                                        integritetspolicy</Link> <strong>kan du läsa mer om hur vi använder och skyddar dina uppgifter när du använder våra produkter, och varför vi behöver ditt samtycke till det. </strong>
                                </p>
                                <p>
                                    <strong>UTAN DITT SAMTYCKE TILL NÖDVÄNDIG DATABEHANDLING ÄR DET INTE JURIDISKT ELLER PRAKTISKT MÖJLIGT FÖR DIG ATT ANVÄNDA PRODUKTERNA.</strong>
                                </p>
                                <p>
                                    <strong>Vår</strong>
                                    <Link to="/se/privacy"> integritetspolicy</Link> <strong>är inte en del av dessa villkor utan används bara för att ge dig information enligt GDPR som grund för ditt samtycke till nödvändig databehandling.</strong>
                                </p>
                                <h2>
                                    3. SLUTFÖRA AVTAL OCH REGISTRERING
                                </h2>
                                <h3>
                                    3.1. Köp och betalning av produkterna
                                </h3>
                                <p>
                                    3.1.1 Du kan köpa våra produkter på olika sätt:
                                </p>
                                <h3>
                                    Appar
                                </h3>
                                <p>
                                    <strong>Appbutikerna</strong> (t.ex. Google Play och Amazon Appstore) har våra produkter. Observera att appbutikens egna affärsvillkor gäller för att ladda ner (inte använda) våra produkter, och det kan krävas ett användarkonto för detta.
                                </p>
                                <p>
                                    <strong>Vår webbplats</strong> kan erbjuda direkt tillgång till våra produkter samt möjlighet att beställa produkterna.
                                </p>
                                <p>
                                    <strong>Rabattkoder</strong> för våra produkter kan finnas i omlopp och kan lösas in på webbplatsen.
                                </p>
                                <p>
                                    3.1.2 Du kan beställa våra avgiftsbelagda produkter på webbplatsen eller via våra distributörer. Betalningen sker via en betaltjänstleverantör, våra distributörer eller andra betalningsalternativ som anges där. Distributören fungerar antingen som agent och betaltjänstleverantör åt CamDiab, eller som direkt säljare. Om du har problem med att hämta eller betala för produkten är du alltid välkommen att kontakta <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <p>
                                    3.1.3 Prenumerationsavgifter eller inköpspris (inklusive lagstadgad moms) ska betalas antingen en gång eller upprepade gånger beroende på vilket alternativ som valts (t.ex. månadsvis, halvårsvis eller årsvis). Aktuella avgifter finns tillgängliga på vår webbplats, fås från distributören eller visas i vår app eller i appbutiken och ska betalas i förskott. Om betalningen inte genomförs direkt via appbutiken kan betaltjänstleverantören eller distributören skicka fakturor och betalningspåminnelser via e-post. Vid eventuell klandervärd, försenad eller ofullständig betalning har vi rätt att blockera din åtkomst till våra produkter. Detta påverkar inte din skyldighet att betala avgiften för användningen.
                                </p>
                                <p>
                                    3.1.4 CamDiab kan även komma med special- och gratiserbjudanden som omfattas av ytterligare villkor och begränsningar. I det sammanhanget nämner vi uttryckligen eventuella avvikelser från dessa villkor, och i övrigt gäller dessa villkor i sin helhet. Special- eller gratiserbjudanden kan inte överlåtas till andra användare.
                                </p>
                                <h3>
                                    3.2. Registrering och aktivering
                                </h3>
                                <p>
                                    3.2.1 För att kunna använda våra produkter måste du registrera dig hos CamDiab i våra appar. Så snart du har aktiverat produkten för första gången måste du registrera dig med en e-postadress och ett lösenord (CamDiab-ID). Varje användare får bara skapa ett CamDiab-ID, och varje CamDiab-ID får endast användas av en användare.
                                </p>
                                <p>
                                    3.2.2 Genom registreringen samtycker användaren till sitt bindande erbjudande att ingå ett avtal med CamDiab för användning av våra produkter på basis av den tillämpliga versionen av dessa villkor. Vi accepterar uttryckligen erbjudandet senast då vi aktiverar ditt CamDiab-ID.
                                </p>
                                <p>
                                    3.2.3 Aktiveringen bekräftas när dina inloggningsuppgifter är godkända och du har genomgått lämplig utbildning. Vi förbehåller oss rätten att avvisa enskilda användare eller erbjudanden om att ingå avtal utan att uppge något skäl. Om detta inträffar återbetalas eventuella betalningar som redan gjorts och alla användaruppgifter raderas omedelbart.
                                </p>
                                <p>
                                    3.2.4 Vid registreringen intygar du som användare att du är minst sexton år gammal och har rätt att ingå avtal enligt tillämplig lagstiftning. Endast personer med rättskapacitet får registrera sig. Minderåriga får endast registrera sig med uttryckligt tillstånd från en förälder/vårdnadshavare. I och med registreringen intygar föräldern/vårdnadshavaren sin rätt att göra juridiskt bindande åtaganden i den minderårigas namn.
                                </p>
                                <p>
                                    3.2.5 Vid registreringen intygar användaren sin kännedom om och sitt förbehållslösa godkännande av innehållet i dessa villkor samt att användarens uppgifter är sanningsenliga, korrekta, aktuella och fullständiga. Vi förbehåller oss rätten att när som helst kontakta användare för att verifiera registreringsuppgifter och information om användning.
                                </p>
                                <p>
                                    3.2.6 Om en användare uppger falska, felaktiga, inaktuella eller ofullständiga uppgifter, eller om vi har rimliga skäl att anta att uppgifterna är falska, felaktiga, inaktuella eller ofullständiga, har CamDiab rätt att med omedelbar verkan och utan förvarning blockera användarens CamDiab-ID och förbjuda vederbörande att använda våra produkter, utan skyldighet att återbetala de kostnader användaren haft.
                                </p>
                                <p>
                                    3.2.7 Användaren måste skydda sina registreringsuppgifter från åtkomst av obehörig tredje part, missbruk och användning med bedrägligt uppsåt. Om det finns minsta misstanke om att ett CamDiab-ID har utsatts för en sådan risk måste detta rapporteras utan dröjsmål till <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>. Vi har alltid rätt att blockera ett CamDiab-ID om det används för felaktiga eller bedrägliga syften.
                                </p>
                                <h2>
                                    4. ÅNGERRÄTT OCH ANNULLERING
                                </h2>
                                <h3>
                                    4.1. Information om ångerrätten
                                </h3>
                                <p>
                                    4.1.1 Om du inte använder våra produkter för kommersiella eller yrkesrelaterade ändamål har du som konsument rätt att annullera det avtal som ingåtts med CamDiab med följande villkor:
                                </p>
                                <p>
                                    <strong>ÅNGERÄTT</strong>
                                </p>
                                <p>
                                    <strong><u>Ångerrätt och annullering</u></strong>
                                </p>
                                <p>
                                    <strong>Du har rätt att annullera avtalet inom fjorton dagar utan att uppge något skäl.</strong>
                                </p>
                                <p>
                                    <strong>Ångerrättsperioden är fjorton dagar från det datum då avtalet ingicks (aktivering av digitalt innehåll).</strong>
                                </p>
                                <p>
                                    <strong>Om du vill utnyttja din ångerrätt ska du informera oss om ditt beslut att annullera avtalet genom att tydligt anmäla detta till distributören eller till CamDiab (e-post </strong> <strong><a href="mailto:support@camdiab.com">support@camdiab.com</a> </strong><strong>) t.ex. via e-post eller vanlig post.</strong>
                                </p>
                                <p>
                                    <strong>För att ångerrättsperioden ska vara giltig måste du informera oss om ditt beslut att utnyttja ångerrätten före utgången av ångerrättsperioden.</strong>
                                </p>
                                <p>
                                    <strong><u>Konsekvenser av annullering</u></strong>
                                </p>
                                <p>
                                    <strong>Om du väljer att annullera avtalet är distributören eller vi skyldiga att återbetala alla betalningar som vi har fått från dig, utan dröjsmål och senast inom fjorton dagar från det datum då vi mottog ditt meddelande om annullering av avtalet. Om möjligt använder vi samma betalningsmetod för återbetalningen som du använde för den ursprungliga transaktionen, såvida vi inte uttryckligen avtalar något annat. Inga kostnader för återbetalning tillkommer under några omständigheter.</strong>
                                </p>
                                <h2>
                                    5. ANVÄNDNING AV PRODUKTERNA
                                </h2>
                                <h3>
                                    5.1. Användningsperiod och uppsägning
                                </h3>
                                <p>
                                    5.1.1 Din rätt att använda våra produkter startar när du registrerar dig och CamDiab aktiverar produkterna.
                                </p>
                                <p>
                                    5.1.2 Våra avgiftsbelagda produkter kan köpas i form av månads-, halvårs- eller årsprenumerationer. Beroende på aktuella erbjudanden kan de även finnas tillgängliga att köpa för en kortare eller längre period (benämns härefter ”<strong>prenumerationsperiod</strong>”).
                                </p>
                                <p>
                                    5.1.3 Såväl användaren som CamDiab har rätt att när som helst säga upp avtalet med en månads uppsägningstid, som träder i kraft i slutet av innevarande månad. Du kan göra detta genom att kontakta distributören eller genom skriftligt meddelande till oss på <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>. Observera att avgifter som redan betalats för en prenumerationsperiod som inte utnyttjas bara återbetalas vid eventuell uppsägning från CamDiabs sida eller vid automatiskt avslut (se avsnitt 5.1.5 nedan).
                                </p>
                                <p>
                                    5.1.4 Om ingen uppsägning görs eller om uppsägningen görs för sent förlängs prenumerationsperioden automatiskt med en ny period motsvarande den prenumerationsperiod som tidigare valts. Aktuellt standardpris vid tidpunkten för förlängningen tillämpas (inte priserna i eventuella special- eller gratiserbjudanden).
                                </p>
                                <p>
                                    5.1.5 I händelse av att användaren avlider eller återkallar sitt samtycke till behandling av personuppgifter avslutas användarrelationen automatiskt med omedelbar verkan. CamDiab har inte rätt att behandla dina hälsodata utan ditt samtycke.
                                </p>
                                <p>
                                    5.1.6 Om du har frågor om din prenumerationsperiod eller dess slut är du alltid välkommen att kontakta <a href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h3>
                                    5.2. Avstängning och blockering
                                </h3>
                                <p>
                                    5.2.1 Vi förbehåller oss rätten att när som helst, av vägande skäl (enligt vår bedömning) stänga av en användare tillfälligt eller permanent förhindra användaren att använda våra produkter. I händelse av att avtalet avslutas på detta sätt utan uppsägningstid är återbetalningen av prenumerationsavgifterna undantagen.
                                </p>
                                <p>
                                    5.2.2 Vägande skäl föreligger i synnerhet vid allvarliga överträdelser från användarens sida i förhållande till bestämmelserna i dessa villkor, däribland överträdelse av avsnitt 3.1 (Betalning) efter en frist på två veckor, avsnitt 3.2 (Registreringsdata), avsnitt 5.3 (Licens) eller avsnitt 6 (”Ditt beteende”).
                                </p>
                                <h3>
                                    5.3. Licens och överlåtelse av rättigheter
                                </h3>
                                <p>
                                    5.3.1 I och med registrering och aktivering erhåller du den icke-exklusiva och icke-överlåtbara men geografiskt obegränsade rätten att spara och använda ett exemplar av vår app för dina egna syften inom ramen för dessa villkor. För avgiftsbelagda produkter är denna licens begränsad till varaktigheten för din avtalsenliga prenumerationsperiod.
                                </p>
                                <p>
                                    5.3.2 Programvara, kod, metoder och system samt innehåll i våra produkter skyddas av upphovsrätts- och konkurrenslagstiftningen och får endast användas av CamDiab. Våra produkter eller delar av dessa får inte kopieras, ändras, reproduceras, publiceras på nytt, postas, överföras, säljas, bjudas ut till försäljning, säljas vidare eller användas på något annat sätt utan vårt föregående skriftliga medgivande. Användarna får inte använda varumärken, logotyper eller andra kommersiella immateriella rättigheter eller varumärkesrättigheter som tillhör CamDiab. Såvida ingenting annat anges i dessa villkor tillhör alla användnings- och exploateringsrättigheter exklusivt CamDiab, och det sker ingen licensiering av något slag för våra produkter.
                                </p>
                                <p>
                                    5.3.3 Om så krävs för att tekniskt tillhandahålla våra produkter ger varje användare CamDiab en icke-exklusiv, återkallelig men kostnadsfri och överlåtbar rätt till användning och exploatering, obegränsad vad gäller tid och plats, för det innehåll som användaren skapar, överför, lagrar eller publicerar i våra produkter. Användning eller exploatering undantas dock om detta på ett oproportionerligt sätt påverkar användarens legitima intressen negativt (t.ex. dataskyddsrättigheter). Vid eventuell användning utanför våra produkter kommer vi, om så är lämpligt, att ange att innehållet kommer från användaren. CamDiab göra inga anspråk på ägarskap till det innehåll som skapas av användarna och åtar sig inte heller någon tillsynsroll för detta innehåll.
                                </p>
                                <h3>
                                    5.4. Tillgång till våra produkter
                                </h3>
                                <p>
                                    5.4.1 Vi tillhandahåller våra produkter i varje enskilt fall i enlighet med befintliga tekniska, ekonomiska, driftsmässiga och organisatoriska möjligheter. CamDiab kan inte utesluta att det kan förekomma avbrott, störningar, förseningar, radering, felaktig överföring eller misslyckad lagring vid användning av våra produkter eller kommunikation med användare. Vi erbjuder delvis våra produkter i samarbete med en eller flera tredjepartsdistributör(er) och -leverantörer och är därför beroende av tekniska tjänster från tredje part. Därför kan CamDiab inte ta något ansvar för, garantera eller påta sig någon skyldighet eller förpliktelse att se till att våra produkter alltid är tillgängliga online utan avbrott.
                                </p>
                                <p>
                                    5.4.2 Detsamma gäller begränsad användning av våra produkter till följd av force majeure, strejk, lockout eller direktiv från myndigheter samt tekniska ändringar eller underhållsarbete på CamDiabs system. Vid eventuella störningar eller fel i våra produkter är du alltid välkommen att kontakta <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h2>
                                    6. DITT BETEENDE
                                </h2>
                                <h3>
                                    6.1. Allmän information
                                </h3>
                                <p>
                                    6.1.1 Varje användare är berättigad och skyldig att använda våra produkter på egen risk och till egen kostnad med hjälp av lämplig teknisk utrustning. Du kan alltid vända dig till <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a> för att få veta vilka enheter och operativsystem som är kompatibla.
                                </p>
                                <p>
                                    6.1.2 Du är också skyldig att använda våra produkter uteslutande i enlighet med dessa villkor och gällande lagstiftning samt att undvika otillåten användning. Om du upptäcker några brott mot de skyldigheter som anges i detta avsnitt ber vi dig att genast informera oss på <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h3>
                                    6.2. Missbruk
                                </h3>
                                <p>
                                    6.2.1 Varje användare är skyldig att uppge sanningsenliga registreringsdata, hålla dessa uppdaterade och fullständiga samt undvika att lämna ut dem till tredje part. Personuppgifter måste behandlas konfidentiellt, hanteras med försiktighet och säkerhetskopieras på eget ansvar. CamDiab tar inget ansvar och lämnar inga garantier för data eller innehåll som gått förlorat eller skadats om detta inte är vårt fel.
                                </p>
                                <p>
                                    6.2.2 Innehåll som foton, bilder, texter, videor eller andra avbildningar får bara lagras, publiceras, överföras eller spridas i samband med våra produkter om användaren har rätt att överföra eller använda dessa. All användning av rasistiskt, stötande, diskriminerande, nedsättande, sexuellt, pornografiskt, våldsamt eller olagligt innehåll av alla slag är alltid förbjudet.
                                </p>
                                <p>
                                    6.2.3 Vidare är det förbjudet att dekryptera, rekonstruera, dekompilera eller demontera våra produkter eller använda våra produkter på rootade smarttelefoner. Varje användare är skyldig att avstå från att utföra några störande åtgärder på teknisk eller elektronisk väg i våra produkter (i synnerhet hackningsförsök, uttömmande attacker och införande av virus, maskar, trojaner eller annan skadlig programvara) och att inte heller göra några försök till störningar som kan påverka programvara eller maskinvara i CamDiabs produkter eller system. All manipulering av våra produkter kan leda till att produkterna inte fungerar i enlighet med avsedd användning.
                                </p>
                                <h2>
                                    7. VÅRT ANSVAR
                                </h2>
                                <h3>
                                    7.1. Garanti och ansvar
                                </h3>
                                <p>
                                    7.1.1 Såvida ingenting annat anges i dessa villkor tar CamDiab ansvar och lämnar garantier i enlighet med gällande lagstiftning.
                                </p>
                                <p>
                                    7.1.2 Gällande användare som inte är konsumenter är allt ansvar för ekonomiska förluster, följdskador, uteblivna vinster samt skador till följd av anspråk från tredje part undantagna. Om vårt ansvar är begränsat eller undantaget enligt dessa villkor gäller detta även ställföreträdande agenter och dotterbolag till CamDiab.
                                </p>
                                <p>
                                    7.1.3 Utöver tillämpningsområdet för gällande lagstiftning om produktansvar är CamDiabs ansvar begränsat till uppsåtligt agerande och grov oaktsamhet – med undantag för personskador. Ansvaret för ringa oaktsamhet undantas när det föreligger omständigheter som gör detta objektivt försvarbart.
                                </p>
                                <p>
                                    7.1.4 CamDiab ger inga garantier för att våra produkter alltid är fullt tillgängliga utan avbrott eller fel eller att den programvara och maskinvara som krävs fungerar felfritt. Vi kan inte heller utesluta möjligheten att data kan spåras, spelas in eller förfalskas av tredje part vid dataöverföring via tredjepartssystem, i synnerhet internet och andra telekommunikationsnätverk.
                                </p>
                                <p>
                                    7.1.5 CamDiab lämnar inga garantier för hämtat innehåll eller material som användarna har mottagit vid användning av våra produkter. Användaren är själv fullt ansvarig för eventuella skador som kan uppstå i vederbörandes IT-system eller enheter, samt för förlust av data till följd av hämtning av material som är förknippat med våra produkter.
                                </p>
                                <p>
                                    7.1.6 Vidare har CamDiab inget ansvar för innehåll från tredje part som externa länkar, banners, annan information eller reklamerbjudanden från tredje part som kan finnas som en del av våra produkter. Om vi ger åtkomst till erbjudanden från tredje part via notiser eller länkar har CamDiab inget ansvar för den information som dessa innehåller. Lagliga transaktioner med en tredje part till följd av sådana erbjudanden leder uteslutande till ett avtalsförhållande mellan användaren och respektive tredje part. Vi ger inga garantier och tar inget ansvar för tjänster från tredje part.
                                </p>
                                <h3>
                                    7.2. Ansvarsfriskrivning och gottgörelse
                                </h3>
                                <p>
                                    7.2.1 Användningen av våra produkter ersätter inte konsultation med hälso- och sjukvårdspersonal eller annan medicinsk rådgivning och sker uteslutande på användarens egen risk. Detta omfattar all användning av de data som tillhandahålls som en del av våra produkter, däribland blod- och sensorglukosvärden, beräkningar och eventuella rekommendationer. Användaren är uttryckligen införstådd med att sådana data kan vara bristfälliga, och CamDiab tar inget ansvar för att dessa är korrekta.
                                </p>
                                <p>
                                    7.2.2 I den utsträckning som krävs enligt lagstiftningen om medicintekniska produkter, som styr användningen av produkterna, får produkterna endast drivas eller användas enligt de ändamål, specifikationer och tillämpningsområden som anges i villkoren för erbjudande och användning.
                                </p>
                                <p>
                                    7.2.3 Användaren ska gottgöra CamDiab för alla anspråk från tredje part gentemot CamDiab till följd av orättfärdigt intrång i vederbörandes rättigheter från användarens sida i samband med användning av våra produkter. Detta påverkar inte övriga skadeståndsanspråk gentemot användaren från CamDiabs sida.
                                </p>
                                <p>
                                    7.2.4 Användaren påtar sig fullt ansvar för alla eventuella skador och tvister i eller utanför domstol till följd av konflikt med andra användare. Användaren är uttryckligen införstådd med att CamDiab inte under några omständigheter tar ansvar för handlingar eller försummelse från andra användares sida eller eventuella skador till följd av detta.
                                </p>
                                <p>
                                    7.2.5 I händelse av att en tredje part kommer med anspråk gentemot CamDiab är användaren skyldig att omedelbart tillhandahålla den information som vederbörande besitter, sanningsenligt och fullständigt, när detta behövs för att granska, försvara sig mot eller förhindra anspråk från tredje part. Användaren ansvarar för alla kostnader för nödvändigt rättsligt försvar för CamDiab, inklusive alla rättegångskostnader inom ramen för det lagstadgade beloppet.
                                </p>
                                <h2>
                                    8. ÖVRIGA BESTÄMMELSER
                                </h2>
                                <h3>
                                    8.1. Lagvalsregler
                                </h3>
                                <p>
                                    Dessa villkor och hela det rättsliga förhållandet mellan CamDiab och användaren lyder helt och hållet under engelsk lag, med undantag för dess referensbestämmelser och lagen om internationella köp (UN Sales Convention). I egenskap av konsument är dock användaren skyddad av de obligatoriska bestämmelserna i det land där användaren befinner sig eller vanligtvis är bosatt.
                                </p>
                                <h3>
                                    8.2. Laga domstol
                                </h3>
                                <p>
                                    Uppfyllelseorten och den enda behöriga domstolen för alla tvister som kan uppstå till följd av dessa villkor är avtalad som CamDiabs huvudkontor i Cambridge, Storbritannien. Oundvikliga lagkonflikter till förmån för konsumenterna påverkas inte.
                                </p>
                                <h3>
                                    8.3. Övrigt
                                </h3>
                                <p>
                                    8.3.1 Om underrättelse eller skriftlig form krävs enligt dessa villkor (däribland för rättsligt viktiga kungöranden) är överföring via e-post till den adress som angavs vid registreringen (CamDiab-ID) tillräckligt.
                                </p>
                                <p>
                                    8.3.2 Användarna får inte överföra sitt CamDiab-ID eller sina rättigheter och skyldigheter enligt dessa villkor i samband med användningen av våra produkter till någon tredje part. CamDiab får överföra sina rättigheter och skyldigheter enligt dessa villkor till ett annat företag eller tredje part om överföringen inte har några betydande effekter på användarens rättigheter enligt dessa villkor.
                                </p>
                                <p>
                                    8.3.3 Om enskilda bestämmelser i dessa villkor är eller blir ogiltiga ska de övriga bestämmelserna i dessa villkor, som inte påverkas av ogiltigheten, förbli giltiga och tillämpliga.
                                </p>
                                <p>
                                    8.3.4 Dessa villkor tillhandahålls på flera olika språk. Versionen på det officiella språket i det land där konsumenten befinner sig eller vanligtvis är bosatt är avgörande och definitiv. Om eller i den utsträckning ingen landsspecifik språkversion finns tillgänglig är den engelska versionen definitiv.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer props={this.props}/>
            </div>
        );
    }
}

