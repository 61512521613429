import React from "react";
// import "react-router-dom";
import NotFound from "./Components/NotFound";
import {Home} from './Components/Home';
import {Faq} from './Components/Faq';
import {Notifications} from './Components/Notifications';
import {History} from './Components/History';
import {Support} from './Components/Support';
import {Privacy} from './Components/Privacy';
import {PrivacyDE} from './Components/Privacy-DE';
import {PrivacyFR} from './Components/Privacy-FR';
import {PrivacyNL} from './Components/Privacy-NL';
import {PrivacyIT} from './Components/Privacy-IT';
import {PrivacyCZ} from './Components/Privacy-CZ';
import {PrivacyFI} from './Components/Privacy-FI';
import {PrivacyPL} from './Components/Privacy-PL';
import {PrivacySE} from './Components/Privacy-SE';
import {PrivacyNO} from './Components/Privacy-NO';
import {PrivacyDK} from './Components/Privacy-DK';
import {PrivacyES} from './Components/Privacy-ES';

import {Terms} from './Components/Terms';
import {TermsDE} from './Components/Terms-DE';
import {TermsFR} from './Components/Terms-FR';
import {TermsNL} from './Components/Terms-NL';
import {TermsIT} from './Components/Terms-IT';
import {TermsCZ} from './Components/Terms-CZ';
import {TermsFI} from './Components/Terms-FI';
import {TermsPL} from './Components/Terms-PL';
import {TermsSE} from './Components/Terms-SE';
import {TermsNO} from './Components/Terms-NO';
import {TermsDK} from './Components/Terms-DK';
import {TermsES} from './Components/Terms-ES';

// import AppliedRoute from './Components-shared/AppliedRoute';
import UnauthenticatedRoute from './Components-shared/UnauthenticatedRoute';
import {Training} from './Components/Training';
import {Orders} from './Components/Orders/Orders';
// import Route from 'react-router-dom/es/Route';
import { Route, Switch } from 'react-router-dom';
// import Switch from 'react-router-dom/es/Switch';
import Reviews from './Components/Reviews';
import ArticleSam from './Components/ArticleSam';
import ArticleRoger from './Components/ArticleRoger';
import {PrivacyFRFR} from "./Components/Privacy-FR-FR";
import {TermsFRFR} from "./Components/Terms-FR-FR";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({childProps}) => (
    <Switch>
        {/*<AppliedRoute path="/" exact component={Home} props={childProps}/>*/}
        {/*<AppliedRoute path="/de" exact component={Home} props={childProps}/>*/}
        <UnauthenticatedRoute path="/home" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/de/home" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/fr-ch/home" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/fr/home" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/nl/home" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/it/home" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/cz/home" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/fi/home" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/pl/home" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/se/home" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/nb/home" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/dk/home" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/es/home" component={Home} props={childProps}/>

        <UnauthenticatedRoute path="/orders" component={Orders} props={childProps}/>
        <UnauthenticatedRoute path="/de/orders" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/fr-ch/orders" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/fr/orders" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/nl/orders" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/it/orders" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/cz/orders" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/fi/orders" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/pl/orders" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/se/orders" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/nb/orders" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/dk/orders" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/es/orders" component={Home} props={childProps}/>

        <UnauthenticatedRoute path="/faq" component={Faq} props={childProps}/>
        <UnauthenticatedRoute path="/de/faq" component={Faq} props={childProps}/>
        <UnauthenticatedRoute path="/fr-ch/faq" component={Faq} props={childProps}/>
        <UnauthenticatedRoute path="/fr/faq" component={Faq} props={childProps}/>
        <UnauthenticatedRoute path="/nl/faq" component={Faq} props={childProps}/>
        <UnauthenticatedRoute path="/it/faq" component={Faq} props={childProps}/>
        <UnauthenticatedRoute path="/cz/faq" component={Faq} props={childProps}/>
        <UnauthenticatedRoute path="/fi/faq" component={Faq} props={childProps}/>
        <UnauthenticatedRoute path="/pl/faq" component={Faq} props={childProps}/>
        <UnauthenticatedRoute path="/se/faq" component={Faq} props={childProps}/>
        <UnauthenticatedRoute path="/nb/faq" component={Faq} props={childProps}/>
        <UnauthenticatedRoute path="/dk/faq" component={Faq} props={childProps}/>
        <UnauthenticatedRoute path="/es/faq" component={Faq} props={childProps}/>

        <UnauthenticatedRoute path="/notifications" component={Notifications} props={childProps}/>
        <UnauthenticatedRoute path="/de/notifications" component={Notifications} props={childProps}/>
        <UnauthenticatedRoute path="/fr-ch/notifications" component={Notifications} props={childProps}/>
        <UnauthenticatedRoute path="/fr/notifications" component={Notifications} props={childProps}/>
        <UnauthenticatedRoute path="/nl/notifications" component={Notifications} props={childProps}/>
        <UnauthenticatedRoute path="/it/notifications" component={Notifications} props={childProps}/>
        <UnauthenticatedRoute path="/cz/notifications" component={Notifications} props={childProps}/>
        <UnauthenticatedRoute path="/fi/notifications" component={Notifications} props={childProps}/>
        <UnauthenticatedRoute path="/pl/notifications" component={Notifications} props={childProps}/>
        <UnauthenticatedRoute path="/se/notifications" component={Notifications} props={childProps}/>
        <UnauthenticatedRoute path="/nb/notifications" component={Notifications} props={childProps}/>
        <UnauthenticatedRoute path="/dk/notifications" component={Notifications} props={childProps}/>
        <UnauthenticatedRoute path="/es/notifications" component={Notifications} props={childProps}/>

        <UnauthenticatedRoute path="/history" component={History} props={childProps}/>
        <UnauthenticatedRoute path="/de/history" component={History} props={childProps}/>
        <UnauthenticatedRoute path="/fr-ch/history" component={History} props={childProps}/>
        <UnauthenticatedRoute path="/fr/history" component={History} props={childProps}/>
        <UnauthenticatedRoute path="/nl/history" component={History} props={childProps}/>
        <UnauthenticatedRoute path="/it/history" component={History} props={childProps}/>
        <UnauthenticatedRoute path="/cz/history" component={History} props={childProps}/>
        <UnauthenticatedRoute path="/fi/history" component={History} props={childProps}/>
        <UnauthenticatedRoute path="/pl/history" component={History} props={childProps}/>
        <UnauthenticatedRoute path="/se/history" component={History} props={childProps}/>
        <UnauthenticatedRoute path="/nb/history" component={History} props={childProps}/>
        <UnauthenticatedRoute path="/dk/history" component={History} props={childProps}/>
        <UnauthenticatedRoute path="/es/history" component={History} props={childProps}/>

        {/*<UnauthenticatedRoute path="/reviews/:id" component={ArticleSam} props={childProps} exact/>*/}
        {/*<UnauthenticatedRoute path="/reviews/sam-and-sofia-stevie" component={ArticleSam} props={childProps}/>*/}
        {/*<UnauthenticatedRoute path="/reviews/roger" component={ArticleRoger} props={childProps}/>*/}
        <UnauthenticatedRoute path="/reviews/sam-and-sofia-stevie" component={ArticleSam} props={childProps}/>
        <UnauthenticatedRoute path="/de/reviews/sam-and-sofia-stevie" component={ArticleSam} props={childProps}/>
        <UnauthenticatedRoute path="/fr-ch/reviews/sam-and-sofia-stevie" component={ArticleSam} props={childProps}/>
        <UnauthenticatedRoute path="/fr/reviews/sam-and-sofia-stevie" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/nl/reviews/sam-and-sofia-stevie" component={ArticleSam} props={childProps}/>
        <UnauthenticatedRoute path="/it/reviews/sam-and-sofia-stevie" component={ArticleSam} props={childProps}/>
        <UnauthenticatedRoute path="/cz/reviews/sam-and-sofia-stevie" component={ArticleSam} props={childProps}/>
        <UnauthenticatedRoute path="/fi/reviews/sam-and-sofia-stevie" component={ArticleSam} props={childProps}/>
        <UnauthenticatedRoute path="/pl/reviews/sam-and-sofia-stevie" component={ArticleSam} props={childProps}/>
        <UnauthenticatedRoute path="/se/reviews/sam-and-sofia-stevie" component={ArticleSam} props={childProps}/>
        <UnauthenticatedRoute path="/nb/reviews/sam-and-sofia-stevie" component={ArticleSam} props={childProps}/>
        <UnauthenticatedRoute path="/dk/reviews/sam-and-sofia-stevie" component={ArticleSam} props={childProps}/>
        <UnauthenticatedRoute path="/es/reviews/sam-and-sofia-stevie" component={ArticleSam} props={childProps}/>

        <UnauthenticatedRoute path="/reviews/roger" component={ArticleRoger} props={childProps}/>
        <UnauthenticatedRoute path="/de/reviews/roger" component={ArticleRoger} props={childProps}/>
        <UnauthenticatedRoute path="/fr-ch/reviews/roger" component={ArticleRoger} props={childProps}/>
        <UnauthenticatedRoute path="/fr/reviews/roger" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/nl/reviews/roger" component={ArticleRoger} props={childProps}/>
        <UnauthenticatedRoute path="/it/reviews/roger" component={ArticleRoger} props={childProps}/>
        <UnauthenticatedRoute path="/cz/reviews/roger" component={ArticleRoger} props={childProps}/>
        <UnauthenticatedRoute path="/fi/reviews/roger" component={ArticleRoger} props={childProps}/>
        <UnauthenticatedRoute path="/pl/reviews/roger" component={ArticleRoger} props={childProps}/>
        <UnauthenticatedRoute path="/se/reviews/roger" component={ArticleRoger} props={childProps}/>
        <UnauthenticatedRoute path="/nb/reviews/roger" component={ArticleRoger} props={childProps}/>
        <UnauthenticatedRoute path="/dk/reviews/roger" component={ArticleRoger} props={childProps}/>
        <UnauthenticatedRoute path="/es/reviews/roger" component={ArticleRoger} props={childProps}/>

        <UnauthenticatedRoute path="/reviews" component={Reviews} props={childProps}/>
        <UnauthenticatedRoute path="/de/reviews" component={Reviews} props={childProps}/>
        <UnauthenticatedRoute path="/fr-ch/reviews" component={Reviews} props={childProps}/>
        <UnauthenticatedRoute path="/fr/reviews" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/nl/reviews" component={Reviews} props={childProps}/>
        <UnauthenticatedRoute path="/it/reviews" component={Reviews} props={childProps}/>
        <UnauthenticatedRoute path="/cz/reviews" component={Reviews} props={childProps}/>
        <UnauthenticatedRoute path="/fi/reviews" component={Reviews} props={childProps}/>
        <UnauthenticatedRoute path="/pl/reviews" component={Reviews} props={childProps}/>
        <UnauthenticatedRoute path="/se/reviews" component={Reviews} props={childProps}/>
        <UnauthenticatedRoute path="/nb/reviews" component={Reviews} props={childProps}/>
        <UnauthenticatedRoute path="/dk/reviews" component={Reviews} props={childProps}/>
        <UnauthenticatedRoute path="/es/reviews" component={Reviews} props={childProps}/>

        <UnauthenticatedRoute path="/support" component={Support} props={childProps}/>
        <UnauthenticatedRoute path="/de/support" component={Support} props={childProps}/>
        <UnauthenticatedRoute path="/fr-ch/support" component={Support} props={childProps}/>
        <UnauthenticatedRoute path="/fr/support" component={Support} props={childProps}/>
        <UnauthenticatedRoute path="/nl/support" component={Support} props={childProps}/>
        <UnauthenticatedRoute path="/it/support" component={Support} props={childProps}/>
        <UnauthenticatedRoute path="/cz/support" component={Support} props={childProps}/>
        <UnauthenticatedRoute path="/fi/support" component={Support} props={childProps}/>
        <UnauthenticatedRoute path="/pl/support" component={Support} props={childProps}/>
        <UnauthenticatedRoute path="/se/support" component={Support} props={childProps}/>
        <UnauthenticatedRoute path="/nb/support" component={Support} props={childProps}/>
        <UnauthenticatedRoute path="/dk/support" component={Support} props={childProps}/>
        <UnauthenticatedRoute path="/es/support" component={Support} props={childProps}/>

        {/*<Route path='/reviews/:id' component={ArticleSam} exact />*/}
        <UnauthenticatedRoute path="/training" component={Training} props={childProps}/>
        <UnauthenticatedRoute path="/de/training" component={Training} props={childProps}/>
        <UnauthenticatedRoute path="/fr-ch/training" component={Training} props={childProps}/>
        <UnauthenticatedRoute path="/fr/training" component={Training} props={childProps}/>
        <UnauthenticatedRoute path="/nl/training" component={Training} props={childProps}/>
        <UnauthenticatedRoute path="/it/training" component={Training} props={childProps}/>
        <UnauthenticatedRoute path="/cz/training" component={Training} props={childProps}/>
        <UnauthenticatedRoute path="/fi/training" component={Training} props={childProps}/>
        <UnauthenticatedRoute path="/pl/training" component={Training} props={childProps}/>
        <UnauthenticatedRoute path="/se/training" component={Training} props={childProps}/>
        <UnauthenticatedRoute path="/nb/training" component={Training} props={childProps}/>
        <UnauthenticatedRoute path="/dk/training" component={Training} props={childProps}/>
        <UnauthenticatedRoute path="/es/training" component={Training} props={childProps}/>

        <UnauthenticatedRoute path="/privacy" component={Privacy} props={childProps}/>
        <UnauthenticatedRoute path="/de/privacy" component={PrivacyDE} props={childProps}/>
        <UnauthenticatedRoute path="/fr-ch/privacy" component={PrivacyFR} props={childProps}/>
        <UnauthenticatedRoute path="/fr/privacy" component={PrivacyFRFR} props={childProps}/>
        <UnauthenticatedRoute path="/nl/privacy" component={PrivacyNL} props={childProps}/>
        <UnauthenticatedRoute path="/it/privacy" component={PrivacyIT} props={childProps}/>
        <UnauthenticatedRoute path="/cz/privacy" component={PrivacyCZ} props={childProps}/>
        <UnauthenticatedRoute path="/fi/privacy" component={PrivacyFI} props={childProps}/>
        <UnauthenticatedRoute path="/pl/privacy" component={PrivacyPL} props={childProps}/>
        <UnauthenticatedRoute path="/se/privacy" component={PrivacySE} props={childProps}/>
        <UnauthenticatedRoute path="/nb/privacy" component={PrivacyNO} props={childProps}/>
        <UnauthenticatedRoute path="/dk/privacy" component={PrivacyDK} props={childProps}/>
        <UnauthenticatedRoute path="/es/privacy" component={PrivacyES} props={childProps}/>

        <UnauthenticatedRoute path="/terms" component={Terms} props={childProps}/>
        <UnauthenticatedRoute path="/de/terms" component={TermsDE} props={childProps}/>
        <UnauthenticatedRoute path="/fr-ch/terms" component={TermsFR} props={childProps}/>
        <UnauthenticatedRoute path="/fr/terms" component={TermsFRFR} props={childProps}/>
        <UnauthenticatedRoute path="/nl/terms" component={TermsNL} props={childProps}/>
        <UnauthenticatedRoute path="/it/terms" component={TermsIT} props={childProps}/>
        <UnauthenticatedRoute path="/cz/terms" component={TermsCZ} props={childProps}/>
        <UnauthenticatedRoute path="/fi/terms" component={TermsFI} props={childProps}/>
        <UnauthenticatedRoute path="/pl/terms" component={TermsPL} props={childProps}/>
        <UnauthenticatedRoute path="/se/terms" component={TermsSE} props={childProps}/>
        <UnauthenticatedRoute path="/nb/terms" component={TermsNO} props={childProps}/>
        <UnauthenticatedRoute path="/dk/terms" component={TermsDK} props={childProps}/>
        <UnauthenticatedRoute path="/es/terms" component={TermsES} props={childProps}/>

        <UnauthenticatedRoute path="/de" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/fr-ch" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/fr" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/nl" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/it" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/cz" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/fi" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/pl" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/se" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/nb" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/dk" component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/es" component={Home} props={childProps}/>

        <UnauthenticatedRoute path="/" component={Home} props={childProps}/>

        {/*<UnauthenticatedRoute path="/ger-home" component={GermanHome} props={childProps}/>*/}
        {/*<UnauthenticatedRoute path="/ger-faq" component={German_Faq} props={childProps}/>*/}
        {/*<UnauthenticatedRoute path="/ger-history" component={German_History} props={childProps}/>*/}
        {/*<UnauthenticatedRoute path="/ger-support" component={German_Support} props={childProps}/>*/}
        {/*<UnauthenticatedRoute path="/ger-terms" component={GerTerms} props={childProps}/>*/}
        {/*<UnauthenticatedRoute path="/ger-privacy" component={GerPrivacy} props={childProps}/>*/}
        { /* Finally, catch all unmatched routes */}
        <Route component={NotFound} props={childProps}/>
    </Switch>
)