/**
 * Texts for History page
 */
export const historyPageText = {
    title: 'Reis van CamDiab',
    titleMeta: 'Klinisch onderzoek op weg naar CamAPS FX',
    descriptionMeta: 'Een tijdslijn van hoe het team achter CamAPS FX mensen met diabetes ging helpen met ons Cambridge-algoritme.',
    localeMeta: 'nl_NL',

    keywords:
        'camaps history, camaps fx history, ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    journeyHeading: 'Onze reis bij CamDiab',
    timelineHeading: 'Een tijdslijn van hoe het team achter CamAPS FX mensen met diabetes ging helpen met ons Cambridge-algoritme.',
    year2006_2010dates: '2006–2010',
    year2006_2010heading: 'Vroege klinische onderzoeken',
    year2006_2010text: 'Begin van het project ‘Artificial Pancreas’ (kunstmatige alvleesklier) in Cambridge, met onderzoek onder kinderen met diabetes type 1. Toegetreden tot JDRF Artificial Pancreas Consortium. Belastingstest van het Cambridge-algoritme na lichaamsbeweging en alcoholgebruik onder volwassenen met diabetes type 1. Cambridge-algoritme beoordeeld bij gebruik onder zwangere vrouwen met diabetes type 1.',
    year2011_2014dates: '2011–2014',
    year2011_2014heading: 'Haalbaarheidsonderzoeken voor gebruik uitsluitend ’s nachts en voor volledige vrijheid van leven',
    year2011_2014text: 'Cambridge-algoritme beoordeeld bij gebruik ’s nachts, onder kinderen, volwassenen en zwangere vrouwen met diabetes type 1. Cambridge-algoritme beoordeeld bij gebruik ’s nachts en overdag gedurende zeven dagen, onder volwassenen met diabetes type 1.',
    year2015_2016dates: '2015–2016',
    year2015_2016heading: 'Korte onderzoeken ’s nachts en overdag, voor volledige vrijheid van leven',
    year2015_2016text: 'Cambridge-algoritme gebruikt gedurende vier weken, onder goed ingestelde volwassenen met diabetes type 1. Cambridge-algoritme gebruikt gedurende drie weken, onder jongeren met diabetes type 1.',
    year2017_2018dates: '2017–2018',
    year2017_2018heading: 'Langere dag-en-nachtonderzoeken en onderzoeken onder jonge kinderen',
    year2017_2018text: 'Cambridge-algoritme gebruikt gedurende drie maanden, onder kinderen en volwassenen met diabetes type 1. Cambridge-algoritme gebruikt gedurende 2 jaar, onder kinderen en jongeren, vanaf begin van diabetes. Cambridge-algoritme gebruikt onder jonge kinderen van een tot zeven jaar oud met diabetes type 1.',
    year2019_2019dates: '2019',
    year2019_2019heading: 'Overgang naar CamAPS FX ',
    year2019_2019text: 'CamAPS FX-app gebruikt onder oudere volwassenen met diabetes type 1. CamAPS FX-app gebruikt tijdens de zwangerschap, onder zwangere vrouwen met diabetes type 1. CamAPS FX-app gebruikt onder kinderen van een tot zeven jaar oud met diabetes type 1.',
    year2020_2020dates: '2020 en verder',
    year2020_2020heading: 'Wettelijke goedkeuring, commerciële ontwikkeling, klinische onderzoeken',
    year2020_2020text: 'CamAPS FX-app krijgt CE-markering voor gebruik in de EU en het Verenigd Koninkrijk. Onderzoek naar de voordelen en ter ondersteuning van vergoeding wordt voortgezet.',
};
