/**
 * Texts for History page
 */
export const historyPageText = {
    title: 'Cesta společnosti CamDiab',
    titleMeta: 'Klinický výzkum na cestě ke CamAPS FX',
    descriptionMeta: 'Časová osa znázorňující, jak se tým vývojářů aplikace CamAPS FX dostal k tomu, že pomocí našeho cambridgeského algoritmu pomáhá lidem s diabetem',
    localeMeta: 'cs_cz',

    keywords:
        'camaps historie, ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    journeyHeading: 'Cesta společnosti CamDiab',
    timelineHeading: 'Časová osa znázorňující, jak se tým vývojářů aplikace CamAPS FX dostal k tomu, že pomocí našeho cambridgeského algoritmu pomáhá lidem s diabetem',
    year2006_2010dates: '2006–2010',
    year2006_2010heading: 'Rané studie na klinice',
    year2006_2010text: 'Zahájení projektu umělé slinivky v Cambridgi se studiemi u dětí s diabetem 1. typu Vstup do konsorcia JDRF Artificial Pancreas Consortium Stresový test cambridgeského algoritmu po cvičení a konzumaci alkoholu u dospělých s diabetem 1. typu Hodnocení cambridgeského algoritmu u těhotných žen s diabetem 1. typu',
    year2011_2014dates: '2011–2014',
    year2011_2014heading: 'Studie použitelnosti pouze v noci a v běžných podmínkách',
    year2011_2014text: 'Hodnocení cambridgeského algoritmu používaného v noci u dětí, dospělých a těhotných žen s diabetem 1. typu Cambridgeský algoritmus používaný 24 hodin denně po dobu 7 dnů u dospělých s diabetem 1. typu',
    year2015_2016dates: '2015–2016',
    year2015_2016heading: 'Krátké studie používání 24 hodin denně v běžných podmínkách',
    year2015_2016text: 'Cambridgeský algoritmus používaný po dobu 4 týdnů u kompenzovaných dospělých s diabetem 1. typu Cambridgeský algoritmus používaný po dobu 3 týdnů u dospívajících s diabetem 1. typu',
    year2017_2018dates: '2017–2018',
    year2017_2018heading: 'Dlouhodobější studie používání 24 hodin denně a u malých dětí',
    year2017_2018text: 'Cambridgeský algoritmus používaný po dobu 3 měsíců u dětí a dospělých s diabetem 1. typu Cambridgeský algoritmus používaný po dobu 2 let u dětí a dospívajících od manifestace diabetu Cambridgeský algoritmus používaný u dětí ve věku jednoho roku až sedmi let s diabetem 1. typu',
    year2019_2019dates: '2019',
    year2019_2019heading: 'Přechod na CamAPS FX ',
    year2019_2019text: 'Používání aplikace CamAPS FX staršími dospělými s diabetem 1. typu Používání aplikace CamAPS FX po celou dobu těhotenství těhotnými ženami s diabetem 1. typu Používání aplikace CamAPS FX dětmi ve věku od jednoho roku do sedmi let s diabetem 1. typu',
    year2020_2020dates: '2020 a dále',
    year2020_2020heading: 'Schválení kontrolními úřady, vývoj komerčních verzí, klinické studie',
    year2020_2020text: 'Získání označení CE pro používání aplikace CamAPS FX v EU a ve Spojeném království Probíhá další výzkum k vyhodnocení přínosů a získání podkladů pro hrazení ze zdravotního pojištění',
};
