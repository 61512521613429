/**
 * Texts for History page
 */
export const historyPageText = {
    title: 'CamDiab-matka',
    titleMeta: 'Kliininen tutkimus matkalla CamAPS FX -sovellukseen',
    descriptionMeta: 'Aikajana matkasta, jonka CamAPS FX -sovelluksen takana oleva tiimi tuli auttamaan diabetesta sairastavia Cambridge-algoritmillamme.',
    localeMeta: 'fi_fi',

    keywords:
        'camps historia, camaps fx historia, ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    journeyHeading: 'CamDiab-matkamme',
    timelineHeading: 'Aikajana matkasta, jonka CamAPS FX -sovelluksen takana oleva tiimi tuli auttamaan diabetesta sairastavia Cambridge-algoritmillamme.',
    year2006_2010dates: '2006–2010',
    year2006_2010heading: 'Varhaiset kliiniset tutkimukset',
    year2006_2010text: 'Keinohaimaprojektin käynnistäminen Cambridgessa tehdyillä tutkimuksilla tyypin 1 diabetesta sairastavilla lapsilla. Liittyminen JDRF:n keinohaimakonsortioon. Cambridge-algoritmin stressitesti liikunnan ja alkoholin kulutuksen jälkeen tyypin 1 diabetesta sairastavilla aikuisilla. Cambridge-algoritmin arviointi raskaana olevilla, tyypin 1 diabetesta sairastavilla naisilla.',
    year2011_2014dates: '2011–2014',
    year2011_2014heading: 'Toteutettavuustutkimukset ainoastaan öisin ja vapaasti arkeaan viettävillä ("free-living") henkilöillä',
    year2011_2014text: 'Cambridge-algoritmin yökäytön arviointi tyypin 1 diabetesta sairastavilla lapsilla, aikuisilla ja raskaana olevilla naisilla. Cambridge-algoritmin käyttö vuorokauden ympäri seitsemän vuorokauden ajan tyypin 1 diabetesta sairastavilla aikuisilla.',
    year2015_2016dates: '2015–2016',
    year2015_2016heading: 'Lyhytaikaiset tutkimukset käytöstä vuorokauden ympäri vapaasti arkeaan viettävillä ("free-living") henkilöillä.',
    year2015_2016text: 'Cambridge-algoritmin käyttö neljän viikon ajan tyypin 1 diabetesta sairastavilla aikuisilla, joilla diabetes on hoitotasapainossa. Cambridge-algoritmin käyttö kolmen viikon ajan tyypin 1 diabetesta sairastavilla nuorilla.',
    year2017_2018dates: '2017–2018',
    year2017_2018heading: 'Pidempiaikaiset tutkimukset käytöstä vuorokauden ympäri ja nuorilla lapsilla.',
    year2017_2018text: 'Cambridge-algoritmin käyttö kolmen kuukauden ajan tyypin 1 diabetesta sairastavilla lapsilla ja aikuisilla. Cambridge-algoritmin käyttö kahden vuoden ajan lapsilla ja nuorilla diabeteksen puhkeamisesta alkaen. Cambridge-algoritmin käyttö tyypin 1 diabetesta sairastavilla 1−7-vuotiailla lapsilla.',
    year2019_2019dates: '2019',
    year2019_2019heading: 'Siirtyminen CamAPS FX -sovellukseen. ',
    year2019_2019text: 'CamAPS FX -sovelluksen käyttö tyypin 1 diabetesta sairastavilla, vanhemmilla aikuisilla. CamAPS FX -sovelluksen käyttö tyypin 1 diabetesta sairastavilla, raskaana olevilla naisilla koko raskauden ajan. CamAPS FX -sovelluksen käyttö tyypin 1 diabetesta sairastavilla 1−7-vuotiailla lapsilla.',
    year2020_2020dates: '2020 alkaen',
    year2020_2020heading: 'Sääntelyllinen hyväksyntä, markkinoille tuonti, kliinisiä tutkimuksia',
    year2020_2020text: 'CamAPS FX -sovellukselle myönnetään CE-merkki, jonka ansiosta sitä voidaan käyttää EU-maissa ja Isossa-Britanniassa. Tutkimustyö jatkuu hyötyjen arvioimiseksi ja kulukorvausten tukemiseksi.',
};
