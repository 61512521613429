/**
 * Texts for footer
 */
export const footerText = {
    txtFAQs: 'Vanlige spørsmål',
    txtSupport: 'Brukerstøtte',
    txtHistory: 'Historie',
    txtTrainingHtml: 'https://camdiabtraining.com',
    txtTraining: 'Nettbasert opplæring',
    txtClinic: 'CamAPS FX klinikk',
    terms: 'Vilkår',
    privacy: 'Personvernerklæring',
    txtFooterBottom: 'CamAPS er et registrert varemerke som tilhører CamDiab Ltd. Andre varemerker tilhører de respektive eierne. Verken CamDiabs varemerke, handelsnavn eller utstyr på dette nettstedet kan brukes på noen måte uten forutgående skriftlig tillatelse fra CamDiab, bortsett fra for å identifisere virksomhetens produkt eller tjenester. Produktbildene er kun ment som illustrasjoner.',
    rightsReserved: 'CamDiab Ltd. Med enerett.',

    statusPageFooterText: 'Statusside',
    statusPageFooterLink: 'https://camdiabltd.statuspage.io/',
};
