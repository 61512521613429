/**
 * Texts for Training page
 */
export const trainingPageText = {
    title: 'Oplæring – CamAPS FX',
    titleMeta: 'Vi sørger for gratis onlineoplæring til CamAPS FX-brugere',
    descriptionMeta: 'Cambridge Diabetes Education Programme udbyder gratis certificeret onlineoplæring til mennesker med type 1-diabetes, forældre og værger, skolepersonale og behandlere.',
    keywords:
        'camps træning, camps fx træning, ' ,
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'da_dk',

    trainingHeader: 'Certificeret oplæring',
    trainingHeader_2: 'For brugere, behandlere og skolepersonale',
    trainingHeaderText: 'GRATIS CamAPS FX-onlineoplæring leveret af Cambridge Diabetes Education Programme (CDEP).',
    websiteLink: 'https://www.camdiabtraining.com/account-sign-in.html',
    websiteLinkHeading: 'CDEP-oplæring',
    getTrained: 'Bliv oplært',
};
