import React, {Component} from 'react';
import {Header} from './Header';
import {Footer} from './Footer';
import {Helmet} from "react-helmet";

export class PrivacyIT extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div>
                <Helmet>
                    <title>Privacy - CamAPS FX</title>
                    <meta name="title" content='Informativa sulla Privacy'/>
                    <meta name="description"
                          content="Qui puoi trovare l'informativa sulla privacy di CamDiab"/>
                    <meta property="og:locale" content='it'/>
                    <meta name="keywords" content='politica sulla riservatezza'/>
                </Helmet>
                <Header props={this.props}/>
                <section className="inner-banner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-10">
                                <h1>Informativa sulla Privacy</h1>
                                <h3>Versione datata 24 giugno 2024</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <h2>
                                    1. INTRODUZIONE
                                </h2>
                                <h3>
                                    1.1. Entità responsabile
                                </h3>
                                <p>
                                    CamDiab Limited, sede legale registrata 20-22 Wenlock Road, London, N1 7GU, Regno Unito, costituita e registrata in Inghilterra e in Galles con numero 11659211 (“CamDiab”), è l’entità responsabile notificata ai sensi delle normative in materia di protezione dei dati. CamDiab disciplina la finalità e i mezzi del trattamento dei dati personali dei propri utilizzatori (“<strong>Dati dell’Utilizzatore</strong>”) e risponde pertanto per la sicurezza di questi dati e per il rispetto delle leggi vigenti.
                                </p>
                                <p>
                                    1.1.2 In quanto entità responsabile, noi siamo tenuti, ad esempio, al rispetto dei requisiti di informazione che intendiamo soddisfare in associazione con la presente Informativa sulla Privacy.
                                </p>
                                <h3>
                                    1.2. Struttura e concetto di consenso
                                </h3>
                                <p>
                                    1.2.1 La presente Informativa sulla Privacy La informa sulle finalità e sulla portata del trattamento dei Dati dell’Utilizzatore, nonché sui trasferimenti dei dati e sui Suoi diritti estesi. La nostra offerta è indirizzata esclusivamente a persone con diabete, quindi l’utilizzo da parte Sua fornisce già specifiche informazioni sul Suo stato di salute. Trattiamo quindi i Dati dell’Utilizzatore come dati sanitari esclusivamente previo Suo consenso. Precisiamo quanto segue:
                                </p>
                                <p>
                                    1.2.1.1 L’espressione “Trattamento Necessario” descrive come trattiamo i Dati dell’Utilizzatore richiesti per adempiere al contratto. Senza questo consenso, l’utilizzo dei nostri prodotti non è consentito da un punto di vista di fatto e di diritto, perché i nostri servizi dipendono da questo trattamento dei dati.
                                </p>
                                <p>
                                    1.2.1.2 L’espressione “Trattamento ai fini di un Miglioramento del Prodotto” spiega come, fornendoci il Suo consenso, Lei può aiutare noi e altri utilizzatori permettendoci di utilizzare i Suoi dati, in particolare per migliorare algoritmi per la gestione della terapia, migliorare il prodotto, ecc., senza doverla contattare.
                                </p>
                                <p>
                                    1.2.1.3 L’espressione “Trattamento per Finalità di Marketing” descrive come, previo Suo consenso, La contattiamo per finalità di marketing, ad es. tramite posta elettronica, notifiche, ecc.
                                </p>
                                <p>
                                    1.2.1.4 Sotto “Informazioni Generali” abbiamo raccolto le informazioni riguardanti tutti i suddetti consensi per evitare ripetizioni.
                                </p>
                                <p>
                                    Le rispettive categorie sono descritte più dettagliatamente qui di seguito. Lei deve rilasciare il corrispondente consenso al momento della registrazione oppure dell’ordine del Suo prodotto.
                                </p>
                                <p>
                                    1.2.2 In alcuni casi, il trattamento può avvenire indipendentemente dal consenso, quando ciò si basa su principi di legge (ad es. ai sensi delle normative sui dispositivi medici).
                                </p>
                                <h2>
                                    2. TRATTAMENTO NECESSARIO
                                </h2>
                                <p>
                                    Se rilascia il Suo consenso, trattiamo i Dati dell’Utilizzatore elencati di seguito allo scopo di fornire i nostri servizi. Se non acconsente a questo Trattamento Necessario, non potrà utilizzare i servizi di CamDiab. Può rilasciare i corrispondenti consensi durante il processo di registrazione oppure di ordine del Suo prodotto.
                                </p>
                                <h3>
                                    2.1. Dati dell’Utilizzatore non necessari
                                </h3>
                                <p>
                                    2.1.1 Al fine di proteggere i Dati dell’Utilizzatore, i nostri servizi possono essere utilizzati esclusivamente in associazione ad un account utente. Per creare un account utente necessitiamo e trattiamo i seguenti Dati dell’Utilizzatore:
                                </p>
                                <ul type="disc">
                                    <li>
                                        Indirizzo e-mail
                                    </li>
                                    <li>
                                        Password (salviamo unicamente una somma di controllo)
                                    </li>
                                    <li>
                                        Nome
                                    </li>
                                    <li>
                                        Dichiarazione di esenzione IVA (nome, indirizzo, data; per soggetti autopaganti)
                                    </li>
                                    <li>
                                        Anno di nascita
                                    </li>
                                    <li>
                                        Data di registrazione
                                    </li>
                                    <li>
                                        Stato del consenso
                                    </li>
                                    <li>
                                        ID del dispositivo, fabbricante, tipo di dispositivo, versione del sistema operativo, versione dell’app
                                    </li>
                                    <li>
                                        Lingua, paese, fuso orario.
                                    </li>
                                </ul>
                                <p>
                                    2.1.2 Altre informazioni raccolte al di fuori della registrazione dell’utilizzatore includono:
                                </p>
                                <p>
                                    <strong>Dati master di natura medica </strong>
                                    <br/>
                                    Peso, intervallo glicemico target, impostazioni della basale del microinfusore, fattori di correzione, rapporto insulina/carboidrati, durata di azione dell’insulina, valore target della glicemia, impostazioni di allarme, incremento del bolo, unità dei carboidrati, stato di gravidanza, data prevista del parto.
                                </p>
                                <p>
                                    <strong>Dati di utilizzo e di collegamento</strong>
                                    <br/>
                                    Numero di serie del microinfusore e del trasmettitore, registro delle azioni del microinfusore, del sensore e delle azioni nel circuito chiuso/a circuito aperto, registro eventi, informazioni dei follower inserite nell’app (numero di telefono, credenziali degli account per i portali di gestione dei dati del diabete), richieste di assistenza, dati degli istruttori, ID clinica per il diabete.
                                </p>
                                <p>
                                    <strong>Dati di natura medica</strong>
                                    <br/>
                                    Dati inseriti nell’app, ad es. data/ora/fuso orario/luogo, assunzione di alimenti/pasto, misurazioni della glicemia del sensore e della glicemia, velocità di variazione della glicemia del sensore, dose di insulina totale giornaliera, erogazione di insulina (bolo e basale), velocità basale temporanea, stato a circuito chiuso, priming del microinfusore e della cannula, avvisi, eventi “Boost“ e “Ease-off“, impostazioni dell’app quali opzioni del display, statistiche riepilogative dei dati della glicemia e dell’insulina.
                                </p>
                                <p>
                                    2.1.3 Se lo desidera, può inserire un indirizzo e-mail creato appositamente per noi, ma funzionante, per poterle inviare avvisi importanti.
                                </p>
                                Trattiamo esclusivamente i Dati dell’Utilizzatore che Lei fornisce attivamente e volontariamente a CamDiab o che sono ottenuti dal sensore della glicemia e dal microinfusore collegati volontariamente da parte Sua. L’inserimento dei Dati dell’Utilizzatore necessari rappresenta tuttavia un prerequisito per l’utilizzo completo dei nostri prodotti.
                                <h3>
                                    2.2. Finalità necessarie
                                </h3>
                                <p>
                                    2.2.1 Tutte le finalità necessarie del nostro trattamento dei dati sono associate alla fornitura dei nostri servizi:
                                </p>
                                <p>
                                    La <strong>richiesta del cliente </strong> comporta la registrazione di dati di contatto.
                                </p>
                                <p>
                                    L’<strong>ordine </strong> delle nostre app da parte di soggetti autopaganti comporta la registrazione di dati di contatto e dati tecnici, ad es. l’ID del dispositivo.
                                </p>
                                <p>
                                    L’<strong>installazione </strong> delle nostre app comporta la registrazione di dati tecnici e dati relativi al dispositivo.
                                </p>
                                <p>
                                    La <strong>registrazione </strong> comporta la creazione di un Suo account CamDiab basato su indirizzo e-mail e password.
                                </p>
                                <p>
                                    La <strong>fornitura dei nostri servizi</strong> prevede che Lei inserisca o fornisca volontariamente e attivamente certi dati; ogni funzione descrive la finalità per cui i dati sono necessari.
                                </p>
                                <p>
                                    CamDiab o il distributore comunica con Lei all’interno delle nostre app o tramite altri servizi di messaggistica elettronica (ad es. posta elettronica, messenger, telefono) qualora ciò sia necessario per fornire supporto o risolvere problemi legati ai nostri prodotti. Questo è il modo in cui gestiamo eventuali commenti e domande sottoposti da parte Sua tramite vari canali di comunicazione durante l’uso dei prodotti CamDiab. L’esempio più importante è il nostro servizio di assistenza clienti, accessibile all’indirizzo <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>. La invitiamo quindi a riflettere su quali informazioni e dati desidera fornirci nella Sua comunicazione attiva con noi - questo è unicamente a Sua discrezione. Da parte nostra, comunicare con gli utilizzatori può essere necessario tramite posta elettronica o mezzi simili. Questo è il modo per informarla su aggiornamenti riguardanti i nostri prodotti e fornirle avvisi importanti sulla sicurezza, nonché servizi di assistenza associati al Suo utilizzo. Queste comunicazioni di supporto, che sono parte fondamentale dei nostri prodotti, vengono inviate agli utilizzatori.
                                </p>
                                <p>
                                    I <strong>dispositivi per la terapia </strong> (cioè sensore della glicemia e microinfusore) vengono connessi in pairing con il Suo smart device per consentire il trasferimento dei dati alle nostre app.
                                </p>
                                <p>
                                    I <strong>portali di gestione dei dati del diabete e i sistemi di condivisione dei dati</strong>, come quelli di mylife Cloud/Glooko, consentono lo scambio dei dati con le nostre app. Tuttavia, la sincronizzazione dei dati è possibile solo se attivata nelle impostazioni delle nostre app, cioè se si utilizza questa funzione. L’Informativa sulla Privacy dei fabbricanti di questi portali di gestione dei dati del diabete si applica ai dati condivisi con questi collaboratori terzi.
                                </p>

                                2.2.2 L’<strong>utilizzo</strong> delle nostre app richiede che Lei inserisca o fornisca volontariamente e attivamente certi dati. Per risolvere un errore nell’app necessitiamo, ad esempio, di crash report utilizzabili da parte nostra per finalità di risoluzione dei problemi, cioè per stabilire le cause dei problemi. Inoltre, i dati principali del Suo smart device e il Suo comportamento d’uso vengono registrati perché l’adempimento contrattuale da parte nostra prevede soprattutto la personalizzazione dei nostri prodotti, cioè il trattamento di informazioni specifiche dell’utilizzatore, ad es. la Sua posizione (aspetto rilevante anche per la funzione di ricerca). Un’analisi automatizzata del comportamento dell’utilizzatore viene effettuata esclusivamente per la finalità di personalizzare il Suo utilizzo nell’adempimento del contratto e non ha effetti legali per Lei.

                                <h2>
                                    3. TRATTAMENTO AI FINI DEL MIGLIORAMENTO DEI PRODOTTI
                                </h2>
                                <p>
                                    Inoltre, trattiamo i Dati dell’Utilizzatore al di là dell’utilizzo necessario descritto nella sezione 2 per migliorare i nostri prodotti e servizi descritti più dettagliatamente di seguito.
                                </p>
                                <h3>
                                    3.1. Dati supplementari
                                </h3>
                                <p>
                                    In generale, per migliorare i nostri prodotti utilizziamo gli stessi Dati dell’Utilizzatore indicati nella sezione 2. Inoltre, CamDiab può registrare anche i seguenti Dati dell’Utilizzatore:
                                </p>
                                <p>
                                    <strong>Dati di utilizzo</strong>
                                    <br/>
                                    Eventi di attività che ci consentono di capire come Lei utilizza i nostri prodotti. Possiamo vedere come sono utilizzati i nostri prodotti e, ad esempio, capire quali parti del design del menu possono essere ottimizzate.
                                </p>
                                <h3>
                                    3.2. Finalità di miglioramento dei prodotti
                                </h3>
                                <p>
                                    Di fronte a un progresso tecnologico in rapida evoluzione, dobbiamo analizzare, sviluppare, provare e migliorare continuamente i nostri prodotti e le loro interazioni per avere la certezza che i nostri utilizzatori possano approfittare dei nostri contenuti nel modo più efficiente possibile. A tale scopo, possiamo condurre valutazioni di utilizzo, fattibilità, previsione, controllo e sicurezza, e le informazioni acquisite vengono integrate in nuove e migliorate versioni dei nostri prodotti, come le app. Questi miglioramenti sono messi a Sua disposizione anche tramite regolari aggiornamenti.
                                </p>
                                <p>
                                    CamDiab è impegnata nella scienza di tutti gli aspetti del diabete. Pertanto, i Dati utente anonimi possono essere utilizzati anche a scopo di ricerca e statistica (sempre nel rispetto degli standard scientifici etici riconosciuti) e per analisi interne. Ciò può includere la condivisione di dati anonimizzati con collaboratori terzi.
                                </p>
                                <h2>
                                    4. TRATTAMENTO PER FINALITÀ DI MARKETING
                                </h2>
                                <h3>
                                    4.1. Newsletter
                                </h3>
                                <p>
                                    4.1.1 Possiamo inviarle informazioni su prodotti e servizi al di là dell’ambito di validità del contratto (incluse informazioni di partner attentamente selezionati) e inviti a partecipare a sondaggi e ad altre promozioni commerciali e attività di marketing (“<strong>Newsletter</strong>”).
                                </p>
                                <p>
                                    4.1.2 Può scegliere autonomamente di iscriversi alla nostra Newsletter (opt-in). Può revocare il Suo consenso in qualsiasi momento nelle impostazioni dell’account.
                                </p>
                                <h3>
                                    4.2. Altri tipi di attività di marketing
                                </h3>
                                <p>
                                    4.2.1 Altri consensi, ad es. per sondaggi, notifiche o offerte personalizzate, sono ottenuti in base a necessità. Le spieghiamo sempre perché abbiamo bisogno di certi dati e come Lei può revocare il Suo consenso.
                                </p>
                                <p>
                                    4.2.2 Le facciamo notare che possiamo sottoporle certe offerte all’interno dell’app senza trattare i Suoi dati personali. Lei vedrà questi annunci non personalizzati anche se non ha fornito il Suo consenso.
                                </p>
                                <h2>
                                    5. UTILIZZO PER FINALITÀ DI LEGGE
                                </h2>
                                <h3>
                                    5.1. Ricerca scientifica e statistiche
                                </h3>
                                <p>
                                    CamDiab è impegnata nella ricerca scientifica di tutti gli aspetti del diabete. Per questo motivo, possiamo utilizzare anche Dati anonimi dell’Utilizzatore per finalità di ricerca, statistiche (sempre nel rispetto degli standard scientifici etici riconosciuti) e analisi interne. Tutto ciò ha principalmente lo scopo di stabilire e migliorare l’efficacia delle tecniche per controllare e trattare il diabete. La base legale per questo è l’Articolo 9 (2) j) del General Data Protection Regulation (GDPR).
                                </p>
                                <h3>
                                    5.2. Attuazione dei diritti
                                </h3>
                                <p>
                                    L’uso dei dati personali può essere necessario anche per prevenire abusi da parte degli utilizzatori o per far valere, esercitare o difendere rivendicazioni legali. Possiamo essere costretti alla divulgazione dei dati ai sensi di leggi vincolanti, delibere e istruzioni giudiziarie o ufficiali, indagini di natura penale o nell’interesse pubblico. In questi casi, la conservazione e il trattamento dei Suoi dati sono consentiti per legge senza il Suo consenso. La base legale per questo è l’Articolo 9 (2) f) del GDPR.
                                </p>
                                <h3>
                                    5.3. Conformità alle normative sui dispositivi medici
                                </h3>
                                <p>
                                    Infine, in qualità di fabbricante e distributore di un dispositivo medico, siamo tenuti al rispetto di maggiori requisiti per il monitoraggio della funzionalità dei nostri prodotti. Questo sistema di vigilanza necessario per finalità regolatorie può comportare anche il trattamento di dati personali. La base legale per questo è l’Articolo 9 (2) i) del GDPR.
                                </p>
                                <h2>
                                    6. INFORMAZIONI GENERALI
                                </h2>
                                <h3>
                                    6.1. Limitazione della finalità e sicurezza
                                </h3>
                                <p>
                                    6.1.1 CamDiab utilizza i Suoi dati personali esclusivamente per le finalità specificate nella presente Informativa sulla Privacy e per i consensi pertinenti. Garantiamo che ogni trattamento è limitato a quanto necessario rispetto alla relativa finalità.
                                </p>
                                <p>
                                    6.1.2 Ogni trattamento garantisce sempre l’adeguata sicurezza e riservatezza dei Suoi dati personali. Ciò implica la protezione da trattamenti non autorizzati e illegali, dalla perdita e distruzione accidentale dei dati o da danni utilizzando adeguate misure tecniche e organizzative. Utilizziamo severi processi interni, funzioni di sicurezza e metodi di crittografia.
                                </p>
                                <h3>
                                    6.2. Responsabili del Trattamento
                                </h3>
                                <p>
                                    6.2.1 I nostri prodotti sono soggetti a processi complessi che dobbiamo gestire e tenere aggiornati. Per il supporto tecnico possiamo avvalerci di fornitori terzi (“<strong>Responsabili del Trattamento</strong>”) per offrirle un utilizzo ottimale e completo dei nostri prodotti.
                                </p>
                                <p>
                                    6.2.2 CamDiab trasferisce i Dati dell’Utilizzatore ai Responsabili del Trattamento esclusivamente nell’ambito della presente Informativa sulla Privacy e unicamente per adempiere alle finalità specificate nella stessa. I Titolari del Trattamento operano nel rispetto delle nostre specifiche e istruzioni; non sono autorizzati ad utilizzare i dati personali dei nostri utilizzatori per finalità personali o di altra natura.
                                </p>
                                <p>
                                    6.2.3 Ci avvaliamo di Responsabili del Trattamento in grado di offrire sufficienti garanzie che le misure tecniche e organizzative adottate assicurino il trattamento dei dati personali in ottemperanza ai requisiti di legge e alla nostra Informativa sulla Privacy. La protezione dei diritti dei nostri utilizzatori è garantita dalla conclusione di contratti vincolanti che rispettano i severi requisiti del GDPR.
                                </p>
                                <h3>
                                    6.3. Crittografia, pseudonimizzazione e anonimizzazione
                                </h3>
                                <p>
                                    6.3.1 Ogni trasferimento di dati, senza eccezione e per default, è crittografato durante il trasferimento. Utilizzando il protocollo HTTPS (hypertext transfer protocol secure) o simili garantiamo che i Suoi dati non siano intercettati da terzi non autorizzati.
                                </p>
                                <p>
                                    Inoltre, per le finalità di sicurezza e minimizzazione dei dati, utilizziamo anche altri processi per la crittografia e la pseudonimizzazione dei Dati dell’Utilizzatore. Naturalmente ciò dipende dal tipo, dalla portata e dalla finalità del rispettivo trattamento dei dati. Ad esempio, divulghiamo esclusivamente i Dati dell’Utilizzatore di cui un Responsabile del Trattamento necessita per svolgere le proprie attività.
                                </p>
                                <p>
                                    6.3.2 Alla cessazione di un rapporto contrattuale con un Responsabile del Trattamento, quest’ultimo è tenuto, a discrezione di CamDiab, a restituire tutti i Dati dell’Utilizzatore che ci appartengono o a cancellarli qualora non sussistano obblighi di conservazione legali.
                                </p>
                                <p>
                                    6.3.3 I dati che non necessitano di riferimenti personali per il trattamento (ad es. per ricerca e analisi) vengono anonimizzati. Ciò impedisce in tutti i casi che venga stabilito un collegamento con uno specifico utilizzatore.
                                </p>
                                <h3>
                                    6.4. UE e altri paesi
                                </h3>
                                <p>
                                    6.4.1 Cerchiamo di stabilire collaborazioni con partner la cui sede o i cui server sono ubicati nell’Unione Europea (UE) o nello Spazio economico europeo (SEE). La trasmissione dei dati nella UE e nello SEE è irreprensibile perché il GDPR si applica in tutti gli Stati membri.
                                </p>
                                <p>
                                    6.4.2 In casi eccezionali incarichiamo fornitori terzi la cui sede o i cui server sono ubicati al di fuori della UE. Tuttavia, anche in questi casi i Suoi dati personali sono coperti da un elevato livello di protezione in accordo con il GDPR, garantito da una decisione di adeguatezza della UE, che valuta appropriata la protezione dei dati in certi paesi terzi (ad es. Svizzera, Israele e Nuova Zelanda), oppure da certe clausole contrattuali tipo standard approvate dalla UE, su cui si basano i rapporti contrattuali con i responsabili del trattamento dei dati incaricati da parte nostra, o ancora da strumenti legali simili contemplati dal GDPR.
                                </p>
                                <p>
                                    6.4.3 Inoltre, garantiamo che i nostri partner hanno implementato standard di sicurezza supplementari, ad esempio misure di sicurezza individuali e disposizioni di protezione dei dati o meccanismi di certificazione ai sensi del GDPR. Ad esempio, se i fornitori terzi hanno sede negli Stati Uniti, devono essere soggetti allo Scudo UE-USA per la Privacy approvato dalla UE o a simili standard di sicurezza riconosciuti a livello internazionale.
                                </p>
                                <h3>
                                    6.5. Cookie
                                </h3>
                                <p>
                                    CamDiab può salvare i cosiddetti “cookie” per offrirle una gamma completa di funzioni e facilitare l’uso del nostro sito web. I cookie sono piccoli file di testo che vengono salvati sul Suo smart device dal Suo browser. Fatta eccezione per i cookie riguardanti i dati di utilizzo menzionati nella sezione 6.6, i nostri cookie possono essere utilizzati per gestire il sito web. Se non desidera utilizzare i cookie, può impedirne il salvataggio utilizzando le corrispondenti impostazioni nel browser. La maggior parte dei nostri cookie viene cancellata alla fine della Sua visita oppure alla chiusura del browser (cookie di sessione). Qualora ciò non avvenisse, può verificare il periodo di cancellazione nel Suo browser oppure cancellare i cookie manualmente.
                                </p>
                                <h3>
                                    6.6. Dati di utilizzo
                                </h3>
                                <p>
                                    Nell’App utilizziamo Google Firebase Analytics, un servizio di analisi di siti web e di app di Google Inc. (“Google”). Google è certificato ai sensi dello Scudo UE-USA per la Privacy.
                                </p>
                                <h3>
                                    6.7. Conservazione e cancellazione
                                </h3>
                                <p>
                                    6.7.1 I Dati dell’Utilizzatore vengono salvati sul Suo smart device. Questi dati vengono salvati anche su server. Utilizziamo sistemi che rispondono ai requisiti del GDPR.
                                </p>
                                <p>
                                    6.7.2 I Suoi dati vengono salvati su server nell’Unione Europea (UE).
                                </p>
                                <p>
                                    6.7.3 Di regola, CamDiab salva i Suoi dati personali unicamente per la durata del contratto. In casi eccezionali, un periodo di conservazione più lungo può essere necessario per soddisfare obblighi post-contrattuali o per ottemperare agli obblighi di conservazione o di divulgazione di legge o ancora per far valere, esercitare o difendere rivendicazioni legali (periodi di limitazione).
                                </p>
                                <h3>
                                    6.8. Minori
                                </h3>
                                <p>
                                    I minori di età inferiore a sedici anni hanno il permesso di utilizzare i nostri prodotti con il consenso di un genitore/tutore (<a href="/it/terms"> Termini e Condizioni Generali - T&amp;C </a>). Questo requisito si applica anche al trattamento dei loro dati personali, che è legale se e nella misura in cui sia stato ottenuto il consenso a tale riguardo da parte del genitore/tutore. In caso contrario, l’utilizzo dei nostri prodotti è vietato.
                                </p>
                                <h3>
                                    6.9. Responsabile della protezione dei dati
                                </h3>
                                <p>
                                    6.9.1 Il nostro responsabile della protezione dei dati risponde a tutte le domande riguardanti la protezione dei dati all’indirizzo <a href="mailto:dpo@evalian.co.uk">dpo@evalian.co.uk</a>.
                                </p>
                                <p>
                                    6.9.2 Il responsabile della protezione dei dati è ampiamente coinvolto in tutte le questioni inerenti la protezione dei dati personali dei nostri utilizzatori. In qualità di esperto qualificato, sorveglia il nostro trattamento dei dati su base continua per garantire la massima protezione possibile dei Dati dell’Utilizzatore.
                                </p>
                                <h2>
                                    7. I SUOI DIRITTI
                                </h2>
                                <h3>
                                    7.1. Revoca dei consensi
                                </h3>
                                <p>
                                    Se trattiamo i Dati dell’Utilizzatore sulla base del Suo consenso, Lei può revocare il Suo consenso in qualsiasi momento. Tuttavia, ciò non pregiudicherà la legittimità del trattamento dei dati prima della revoca. Continueremo a fornire i nostri servizi qualora questi non dipendano dal consenso revocato.
                                </p>
                                <h3>
                                    7.2. <strong>Informazione, correzione e restrizione</strong>
                                </h3>
                                <p>
                                    7.2.1 Ogni utilizzatore ha il diritto di richiedere informazioni sul trattamento dei propri dati personali. A tale scopo, si invita l’utilizzatore a contattarci in qualsiasi momento all’indirizzo <a
                                    href="mailto:privacy@camdiab.com">privacy@camdiab.com</a>.
                                </p>
                                <p>
                                    7.2.2 Il Suo diritto di accesso riguarda informazioni sulle finalità del trattamento, sulle categorie di dati e di destinatari, sul periodo di conservazione, sull’origine dei Suoi dati e sui Suoi diritti ai sensi dei regolamenti sulla protezione dei dati. Può trovare tutte queste informazioni nella presente Informativa sulla Privacy e saremo lieti di metterle a Sua disposizione in formato elettronico.
                                </p>
                                <p>
                                    7.2.3 Qualora alcuni dei Suoi dati personali fossero errati, può richiederne la rettifica o l’integrazione in qualsiasi momento. Lei ha il diritto di limitare il trattamento dei dati per la durata di una qualsiasi revisione dell’indagine che ha richiesto.
                                </p>
                                <h3>
                                    7.3. Cancellazione (“diritto all’oblio“)
                                </h3>
                                <p>
                                    Ogni utilizzatore ha il diritto di richiedere la cancellazione dei propri dati personali. A tale scopo, si invita l’utilizzatore a contattarci in qualsiasi momento all’indirizzo <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h3>
                                    7.4. Reclami
                                </h3>
                                <p>
                                    7.4.1 Se ritiene che non stiamo proteggendo adeguatamente i Suoi dati, La invitiamo a contattarci in qualsiasi momento all’indirizzo <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a> o a contattare direttamente il nostro responsabile della protezione dei dati all’indirizzo <a
                                    href="mailto:dpo@evalian.co.uk">dpo@evalian.co.uk</a>. Gestiremo opportunamente la Sua richiesta.
                                </p>
                                <p>
                                    7.4.2 Qualsiasi utilizzatore ha il diritto di presentare un reclamo alla competente Autorità di Protezione dei Dati responsabile per CamDiab qualora ritenga che il trattamento dei suoi dati personali non sia conforme ai regolamenti sulla protezione dei dati. Inoltre, l’utilizzatore ha il diritto di presentare reclamo a un’autorità di controllo nello Stato membro della UE in cui è residente, in cui si trova il suo luogo di lavoro oppure in cui si sospetta sia avvenuta la violazione.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer props={this.props}/>
            </div>
        );
    }
}
