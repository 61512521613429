/**
 * Common texts - what is CamAPS FX
 */
export const whatIsCamAPSFX = {

    linkFindOutMore: 'Ontdek meer',
    body:
        '<h2>Wat is CamAPS FX?</h2> <p> CamAPS FX is een Android-app voor het beheren van de glucosewaarden met behulp van een geavanceerd, adaptief, hybride closed-loop systeem bij personen met diabetes type 1. </p>'
};

/**
 * Common texts - what is CamAPS FX
 */
export const userReviewsIntro = {
    linkMoreReviews: 'Meer beoordelingen',
    readMore: 'Lees meer',
    header:
        'Gebruikersbeoordelingen CamAPS FX',
    text:
        'Echte verhalen over echte mensen die de CamAPS FX-app gebruiken'
};