/**
 * Texts for Reviews page
 */
export const reviewsPage = {
    title: 'Opiniones - CamAPS FX',
    titleMeta: 'Leer las opiniones de los usuarios de CamAPS FX',
    descriptionMeta: 'CamAPS FX cambia la vida de muchos usuarios, al brindarles un control de la glucosa sin precedentes, y reduce la carga que supone la diabetes para los usuarios y sus familias.',
    keywords:
        'camaps reviews, camaps fx reviews, ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'es_es',

    shortReview1:
        '<h2>Solo queríamos es que sepan lo mucho que ha mejorado la vida de nuestra hija y la nuestra desde que cuenta con el sistema de Loop cerrado (automático) CamAPS FX. </h2><p><span>Padres de una adolescente</span></p>',

    shortReview2:
        '<p> No hay palabras para explicar lo mucho que nos ha cambiado la vida. Como por arte de magia, casi todos esos problemas desaparecen, es decir, el agotamiento por pasar malas noches, el estar pendientes todo el día. No exageramos para nada al decir que CamAPS FX nos ha devuelto la vida. Sí, todavía tenemos que contar los hidratos de carbono; sí, tenemos que administrar bolos 10 minutos antes de las comidas, y sí, tenemos que cambiar las cánulas de la bomba, pero ya está. CamAPS FX nos dk la libertad de olvidarnos de la diabetes casi toda la jornada.</p> <p> <span>David, padre de una niña de 9 años</span> </p>',
    shortReview3:
        '<h2>Muchas gracias. Anoche dormí como llevaba siglos sin hacerlo.</h2><p><span>Usuario adulto mayor de 60 años</span></p>',

    shortReview4:
        '<p> En general, notamos las repercusiones en la vida de nuestro hijo: mejoró notablemente a la hora de aprender a andar y hablar. Ha supuesto una transformación espectacular en los últimos meses. Mi hijo ha ganado mucho en seguridad, buen humor y, en general, felicidad.</p> <p> <span>Madre de un niño pequeño</span> </p>',


};
