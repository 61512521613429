/**
 * Texts for Reviews page
 */
export const reviewsPage = {
    title: 'Hodnocení – CamAPS FX',
    titleMeta: 'Přečtěte si hodnocení od uživatelů CamAPS FX',
    descriptionMeta: 'CamAPS FX uživatelům často zcela změní život tím, že jim poskytuje bezprecedentní kontrolu hladiny glukózy a snižuje zátěž, kterou jim a jejich rodinám diabetes způsobuje.',
    keywords:
        'camaps recenze, camaps fx recenze, ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'cs_cz',

    shortReview1:
        '<h2>Jenom jsme vám chtěli říct, jak moc se život naší dcery i náš život zlepšil od doby, kdy dostala systém uzavřené smyčky CamAPS FX. </h2><p><span>Rodiče dospívající dcery</span></p>',

    shortReview2:
        '<p> Nedá se ani vylíčit, jak moc nám to změnilo život. To vyčerpání, to neustálé vstávání v průběhu noci a neustálé kontrolování během dne je jako mávnutím kouzelného proutku téměř pryč. Bez přehánění můžu říci, že nám CamAPS FX vrátil život. Jasně že musíme dál počítat sacharidy a 10 minut před jídlem podávat bolus a taky musíme dál měnit kanyly v pumpě. Ale to je všechno. Se systémem CamAPS FX můžeme po většinu dne na diabetes zapomenout.</p> <p> <span>David, otec devítileté dcery</span> </p>',
    shortReview3:
        '<h2>Moc vám děkuju! Minulou noc jsem se konečně vyspal, jak už dlouho ne.</h2><p><span>Dospělý uživatel, přes 60 let</span></p>',

    shortReview4:
        '<p> Zaznamenali jsme celkovou pozitivní změnu v životě našeho dítěte. Výrazně se mu zlepšila chůze i mluvení. Je to za posledních pár měsíců obrovský rozdíl. Můj syn je mnohem sebevědomější, méně se vzteká a je celkově šťastnější.</p> <p> <span>Matka kojence</span> </p>',


};
