import React, {Component} from 'react';
import {Header} from './Header';
import {Footer} from './Footer';
import {Helmet} from "react-helmet";

export class PrivacySE extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Dataskyddspolicy - CamAPS FX</title>
                    <meta name="title" content='Dataskyddspolicy'/>
                    <meta name="description"
                          content='Här kan du hitta CamDiab dataskyddspolicy'/>
                    <meta property="og:locale" content='sv_SE'/>
                    <meta name="keywords" content='Dataskyddspolicy'/>
                </Helmet>
                <Header props={this.props}/>
                <section className="inner-banner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-10">
                                <h1>Dataskyddspolicy</h1>
                                <h3>Version daterad 24 juni 2024</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <h2>
                                    1. INLEDNING
                                </h2>
                                <h3>
                                    1.1. Personuppgiftsansvarig
                                </h3>
                                <p>
                                    CamDiab Limited, registrerad kontorsadress 20-22 Wenlock Road, London, N1 7GU, Storbritannien, aktiebolag registrerat i England och Wales med organisationsnummer 11659211 (”CamDiab”), är personuppgiftsansvarig enligt gällande dataskyddsförordningar. CamDiab beslutar om ändamål och metod för behandlingen av användarnas personuppgifter (”<strong>användardata</strong>”) och ansvarar därmed för att behandlingen är säker och följer tillämpliga lagar.
                                </p>
                                <p>
                                    1.1.2 I egenskap av personuppgiftsansvarig omfattas vi bland annat av informationskrav som vi gärna vill uppfylla med detta integritetsmeddelande.
                                </p>
                                <h3>
                                    1.2. Struktur och samtycke
                                </h3>
                                <p>
                                    1.2.1 Det här integritetsmeddelandet informerar dig om ändamål och omfattning för behandlingen av dina användardata samt dataöverföring och dina omfattande rättigheter. Eftersom vårt erbjudande enbart riktar sig till personer med diabetes innebär din användning normalt att du redan har uppgett information om ditt hälsotillstånd. Därför behandlar vi bara användardata som hälsodata med ditt samtycke. Vi skiljer mellan följande:
                                </p>
                                <p>
                                    1.2.1.1 ”Nödvändig behandling” beskriver behandling av användardata som är nödvändig för att fullgöra avtalet. Utan detta samtycke är det inte möjligt i juridisk och praktisk mening att använda våra produkter eftersom våra tjänster är beroende av denna behandling.
                                </p>
                                <p>
                                    1.2.1.2 ”Behandling för produktförbättringsändamål” innebär att du kan hjälpa oss och andra användare med ditt samtycke genom att ge oss tillstånd att använda dina data, i synnerhet för att förbättra behandlingsalgoritmer, förbättra produkten och så vidare utan att vi behöver kontakta dig.
                                </p>
                                <p>
                                    1.2.1.3 ”Behandling för marknadsföringsändamål” handlar om hur vi kontaktar dig i marknadsföringssyfte, med ditt samtycke, till exempel via mejl, notiser osv.
                                </p>
                                <p>
                                    1.2.1.4 Under ”Allmän Information” har vi sammanställt information som gäller samtliga ovanstående samtyckestyper, för att undvika upprepning.
                                </p>
                                <p>
                                    De aktuella kategorierna beskrivs mer ingående nedan. Du måste ge det samtycke som är aktuellt för dig när du registrerar dig eller beställer produkten.
                                </p>
                                <p>
                                    1.2.2 I vissa fall kan behandling ske oberoende av samtycke på grundval av rättsliga principer (t.ex. bestämmelser gällande medicintekniska produkter).
                                </p>
                                <h2>
                                    2. NÖDVÄNDIG BEHANDLING
                                </h2>
                                <p>
                                    Om du ger ditt samtycke behandlar vi de användardata som anges nedan för att kunna tillhandahålla våra tjänster. Om du inte samtycker till denna nödvändiga behandling kan du inte använda några tjänster från CamDiab. Du kan lämna samtycke under registreringsprocessen eller när du beställer produkten.
                                </p>
                                <h3>
                                    2.1. Nödvändiga användardata
                                </h3>
                                <p>
                                    2.1.1 För att vi ska kunna skydda dina användardata kan våra tjänster bara användas kopplade till ett användarkonto. För att kunna skapa ett användarkonto behöver vi samla in och behandla följande användardata:
                                </p>
                                <ul type="disc">
                                    <li>
                                        E-postadress
                                    </li>
                                    <li>
                                        Lösenord (vi sparar bara totalsumman för kontroll)
                                    </li>
                                    <li>
                                        Namn
                                    </li>
                                    <li>
                                        Deklaration om undantag från moms (namn, adress och datum; gäller självfinansierade användare)
                                    </li>
                                    <li>
                                        Födelseår
                                    </li>
                                    <li>
                                        Registreringsdatum
                                    </li>
                                    <li>
                                        Status för samtycke
                                    </li>
                                    <li>
                                        Enhets-ID, tillverkare, typ av enhet, operativsystemversion, appversion
                                    </li>
                                    <li>
                                        Språk, land, tidszon.
                                    </li>
                                </ul>
                                <p>
                                    2.1.2 Övriga uppgifter som samlas in utanför användarregistreringen är bland andra:
                                </p>
                                <p>
                                    <strong>Medicinska masterdata </strong>
                                    <br/>
                                    Vikt, målintervall för blodglukosvärde, pumpens basalinställningar, korrigeringsfaktorer, kvot för insulin:kolhydrater, varaktighet för insulineffekt, målglukosvärde, inställningar för varningar, bolussteg, kolhydratenhet, graviditetsstatus, beräknat förlossningsdatum.
                                </p>
                                <p>
                                    <strong>Användnings- och kopplingsdata</strong>
                                    <br/>
                                    Serienummer på pump och sändare, aktivitetsspårning på pump, sensor och closed-loop/open-loop, spårning av appens kommandoflöde och händelser, information om följare som anges i appen (telefonnummer, inloggningsuppgifter till konton på diabetesdataportalen), supportfrågor, uppgifter om utbildare, ID för diabetesklinik.
                                </p>
                                <p>
                                    <strong>Medicinska data</strong>
                                    <br/>
                                    Uppgifter som matas in i appen, exempelvis datum/tid/tidszon/plats, intag av mat/måltider, blod- och sensorglukosmätningar, sensorns glukosförändringsvärde, total daglig insulindos, insulindosering (bolus och basal), tillfälligt basalvärde, status för closed-loop, förfyllning av pump och kanyl, varningar, boost- och ease-off-händelser, appinställningar som displayalternativ, statistiska sammanfattningar av glukos- och insulindata.
                                </p>
                                <p>
                                    2.1.3 Om du vill kan du skaffa en särskild e-postadress som du bara använder för oss, men observera att den måste fungera så att vi kan skicka viktiga varningar till dig.
                                </p>
                                Vi behandlar enbart de användardata som du aktivt och frivilligt förser CamDiab med eller som kommer från den glukossensor och insulinpump som du frivilligt ansluter till. Att uppge nödvändiga användardata är dock ett krav för att kunna använda våra produkter fullt ut.
                                <h3>
                                    2.2. Nödvändiga ändamål
                                </h3>
                                <p>
                                    2.2.1 Alla nödvändiga ändamål för vår behandling är kopplade till att tillhandahålla våra tjänster:
                                </p>
                                <p>
                                    <strong>Förfrågan från kund </strong> leder till att kontaktuppgifter registreras.
                                </p>
                                <p>
                                    <strong>Beställning </strong> av våra appar från självfinansierade användare leder till att kontaktuppgifter och tekniska data som enhets-ID registreras.
                                </p>
                                <p>
                                    <strong>Installation </strong> av våra appar leder till registrering av tekniska och enhetsrelaterade data.
                                </p>
                                <p>
                                    <strong>Registrering </strong> leder till att vi skapar ditt CamDiab-konto med din e-postadress och ditt lösenord.
                                </p>
                                <p>
                                    <strong>Tillhandahållande av våra tjänster</strong> förutsätter att du frivilligt och aktivt uppger eller tillhandahåller data, och varje funktion beskriver det ändamål som dina data behövs för.
                                </p>
                                <p>
                                    Kommunikation med dig från CamDiab eller distributören i våra appar eller via andra elektroniska meddelandetjänster (t.ex. e-post, sms-tjänst, telefon) när detta krävs för att ge support eller felsöka våra produkter. På det sättet hanterar vi eventuella frågor eller synpunkter från dig via olika kommunikationskanaler när du använder CamDiab. Det främsta exemplet är vår supporttjänst, som du når via <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>. Tänk därför över vilken information och vilka data du vill uppge i din aktiva kommunikation med oss – det är helt upp till dig. Från vår sida kan det vara nödvändigt att kommunicera med användarna via e-post eller liknande. På det sättet informerar vi dig om uppdateringar av våra produkter och ger viktiga säkerhetsråd samt stöd kring din användning. Denna supportrelaterade kommunikation skickas ut till användarna som en viktig del av våra produkter.
                                </p>
                                <p>
                                    <strong>Behandlingsenheter </strong> (t.ex. glukossensor och insulinpump) parkopplas med din enhet vilket gör att data kan överföras till våra appar.
                                </p>
                                <p>
                                    <strong>Diabetesdataportaler och delning av data</strong>, till exempel från mylife Cloud/Glooko, möjliggör datautbyte med våra appar. Synkronisering sker dock bara om du aktiverar detta i inställningarna till våra appar, det vill säga om du använder funktionen. Integritetsmeddelanden från tillverkarna till diabetesdataportaler gäller för data som delas med sådana tredjepartssamarbetspartners.
                                </p>

                                2.2.2 <strong>Användning</strong> av våra appar förutsätter att du aktivt och frivilligt anger eller tillhandahåller data. För att hantera ett eventuellt fel i appen behöver vi till exempel kraschrapporter för att kunna felsöka och hitta källan till problemet. Dessutom registreras viktiga data om din enhet och din användning, eftersom vårt avtalsmässiga åtagande framför allt innebär att anpassa våra produkter till användarna, det vill säga behandla information om enskilda användare, till exempel utifrån din plats (även relevant för sökfunktionen). En automatiserad analys av användarbeteendet utförs enbart i syfte att anpassa din användning när vi uppfyller vårt åtagande och detta har ingen rättslig effekt på dig.

                                <h2>
                                    3. BEHANDLING FÖR PRODUKTFÖRBÄTTRINGSÄNDAMÅL
                                </h2>
                                <p>
                                    Vi behandlar även dina användardata utöver den nödvändiga behandling som beskrivs i avsnitt 2 ovan med syftet att förbättra våra produkter och tjänster enligt närmare beskrivning nedan.
                                </p>
                                <h3>
                                    3.1. Ytterligare data
                                </h3>
                                <p>
                                    I allmänhet använder vi samma data för att förbättra våra produkter som nämns i avsnitt 2. Utöver detta kan CamDiab också komma att registrera följande data:
                                </p>
                                <p>
                                    <strong>Användardata</strong>
                                    <br/>
                                    Aktivitetshändelser som hjälper oss att förstå hur du använder våra produkter. Det gör att vi kan se hur våra produkter används och hur vi kan förbättra till exempel menyernas utformning.
                                </p>
                                <h3>
                                    3.2. Produktförbättringsändamål
                                </h3>
                                <p>
                                    Till följd av de snabba tekniska framsteg som görs behöver vi kontinuerligt analysera, utveckla, testa och förbättra våra produkter och deras interaktion för att se till att vårt innehåll gynnar användarna så effektivt som möjligt. För detta ändamål genomför vi utvärderingar av användning, genomförbarhet, förutsägbarhet, kontroll och säkerhet. Den kunskap som samlas in överförs till nya och förbättrade versioner av appen. Förbättringarna tillhandahålls också till dig via regelbundna uppdateringar.
                                </p>
                                <p>
                                    CamDiab är engagerad i vetenskapen om alla aspekter av diabetes. Därför kan anonyma användardata också användas för forskning och statistik (alltid i enlighet med erkända etiska vetenskapliga normer) och interna analyser. Detta kan inbegripa delning av anonymiserade data med samarbetspartners från tredje part.
                                </p>
                                <h2>
                                    4. BEHANDLING FÖR MARKNADSFÖRINGSÄNDAMÅL
                                </h2>
                                <h3>
                                    4.1. Nyhetsbrev
                                </h3>
                                <p>
                                    4.1.1 Vi kan komma att skicka information till dig om hälso- och sjukvårdsrelaterade produkter och tjänster utanför avtalets omfattning som vi själva eller noggrant utvalda partners erbjuder, liksom inbjudningar att delta i undersökningar eller andra sälj- och marknadsföringsaktiviteter (”<strong>nyhetsbrev</strong>”).
                                </p>
                                <p>
                                    4.1.2 Du kan välja om du vill prenumerera på vårt nyhetsbrev (ja-krav). Du kan dra tillbaka ditt samtycke när som helst i kontoinställningarna.
                                </p>
                                <h3>
                                    4.2. Andra typer av marknadsföring
                                </h3>
                                <p>
                                    4.2.1 Andra typer av samtycke, t.ex. för undersökningar, notiser eller skräddarsydda erbjudanden, inhämtas vid behov. Vi förklarar alltid varför vi behöver vissa data och hur du kan dra tillbaka ditt samtycke.
                                </p>
                                <p>
                                    4.2.2 Observera att vi kan visa dig erbjudanden i appen utan att behandla dina personuppgifter. Dessa icke-skräddarsydda annonser visas även om du inte har gett ditt samtycke.
                                </p>
                                <h2>
                                    5. ANVÄNDNING FÖR LAGSTADGADE ÄNDAMÅL
                                </h2>
                                <h3>
                                    5.1. Vetenskaplig forskning och statistik
                                </h3>
                                <p>
                                    CamDiab har ett stort engagemang i forskningen kring alla aspekter av diabetes. Därför kan anonyma användardata också komma att användas för forsknings- och statistikrelaterade ändamål (alltid i enlighet med vedertagna etiska standarder för forskning) och för interna analyser. Detta handlar huvudsakligen om att fastställa och förbättra effektiviteten hos olika metoder för att kontrollera och behandla diabetes. Den rättsliga grunden för detta är artikel 9 (2) j) i General Data Protection Regulation (GDPR).
                                </p>
                                <h3>
                                    5.2. Verkställande av rättigheter
                                </h3>
                                <p>
                                    Användning av personuppgifter kan också vara nödvändig för att förhindra missbruk från en användares sida eller för att hävda, genomdriva eller försvara rättsliga anspråk. Vi kan tvingas lämna ut uppgifter på grund av lagstiftning, beslut eller direktiv från domstol eller myndighet, brottsutredning eller i allmänhetens intresse. I sådana situationer är lagring och behandling av dina personuppgifter tillåten enligt lag utan ditt samtycke. Den rättsliga grunden för detta är artikel 9 (2) f) i GDPR.
                                </p>
                                <h3>
                                    5.3. I enlighet med lagstiftning om medicintekniska produkter
                                </h3>
                                <p>
                                    Slutligen omfattas vi som tillverkare eller distributör av en medicinteknisk produkt av skärpta krav på uppföljning av funktionaliteten hos vår produkt. Detta verksamhetssystem är nödvändigt för regulatoriska ändamål och kan också innebära behandling av personuppgifter. Den rättsliga grunden för detta är artikel 9 (2) i) i GDPR.
                                </p>
                                <h2>
                                    6. ALLMÄN INFORMATION
                                </h2>
                                <h3>
                                    6.1. Syftesbegränsning och säkerhet
                                </h3>
                                <p>
                                    6.1.1 CamDiab använder dina personuppgifter enbart för de ändamål som fastställs i det här integritetsmeddelandet och relevanta samtyckesformulär. Vi ser till att behandlingen begränsas till den omfattning som krävs för respektive ändamål.
                                </p>
                                <p>
                                    6.1.2 Varje behandling garanterar alltid tillräcklig säkerhet och sekretess för dina personuppgifter. Detta omfattar skydd mot otillåten och olaglig behandling, oavsiktlig förlust och oavsiktlig förstöring eller skada genom tillämpning av lämpliga tekniska och organisatoriska åtgärder. Vi tillämpar strikta interna rutiner, säkerhetsfunktioner och krypteringsmetoder.
                                </p>
                                <h3>
                                    6.2. Personuppgiftsbiträden
                                </h3>
                                <p>
                                    6.2.1 Våra produkter omfattar komplexa processer som måste skötas och hållas uppdaterade. För vår tekniska support kan vi komma att använda oss av tredjepartsleverantörer (”<strong>personuppgiftsbiträden</strong>”) så att du kan använda produkterna fullt ut på ett optimalt sätt.
                                </p>
                                <p>
                                    6.2.2 CamDiab överför användardata till personuppgiftsbiträden helt inom ramen för detta integritetsmeddelande och enbart för att uppfylla de ändamål som anges här. Personuppgiftsbiträdena arbetar i enlighet med våra specifikationer och instruktioner. De har inte tillåtelse att använda våra användares personuppgifter för egna eller andra ändamål.
                                </p>
                                <p>
                                    6.2.3 Vi anlitar personuppgiftsbiträden som erbjuder tillräckliga garantier för att lämpliga tekniska och organisatoriska åtgärder vidtas på så sätt att behandlingen av personuppgifter följer de lagstadgade kraven och vårt integritetsmeddelande. Vi ser till att våra användares rättigheter är skyddade genom att ingå bindande avtal som uppfyller de strikta kraven i GDPR.
                                </p>
                                <h3>
                                    6.3. Kryptering, pseudonymisering och anonymisering
                                </h3>
                                <p>
                                    6.3.1 Alla data som överförs blir automatiskt och undantagslöst krypterade vid överföringen. Med hjälp av HTTPS (hypertext transfer protocol secure) eller liknande tillser vi att dina data inte kan fångas upp av obehöriga tredjeparter.
                                </p>
                                <p>
                                    Utöver detta tillämpar vi även andra processer för kryptering och pseudonymisering av användardata för dataskydds- och minimeringsändamål, beroende på vilken typ av databehandling som är aktuell och med vilken omfattning och vilket ändamål. Till exempel lämnar vi bara ut användardata som ett visst personuppgiftsbiträde behöver för att utföra sina uppgifter.
                                </p>
                                <p>
                                    6.3.2 När en avtalsrelation med ett personuppgiftsbiträde avslutas måste det personuppgiftsbiträdet efter CamDiabs gottfinnande antingen återlämna alla användardata eller radera dem om det inte finns några lagstadgade krav på lagring.
                                </p>
                                <p>
                                    6.3.3 Data som inte kräver någon personlig referens för behandlingen (t.ex. vid forskning och analys) anonymiseras. Detta gör att det aldrig går att koppla data till en viss användare.
                                </p>
                                <h3>
                                    6.4. EU och andra länder
                                </h3>
                                <p>
                                    6.4.1 Vi strävar efter att välja samarbetspartners som är baserade i eller har sina servrar inom EU eller EES-området. Dataöverföring inom EU och EES-området är oklanderlig eftersom GDPR gäller i samtliga medlemsstater.
                                </p>
                                <p>
                                    6.4.2 I undantagsfall anlitar vi tredjepartsleverantörer som är baserade i eller har sina servrar utanför EU. Även i dessa fall omfattas dock dina personuppgifter av en hög nivå av dataskydd i linje med GDPR – antingen genom ett EU-beslut om adekvat skyddsnivå där dataskyddet i vissa tredjepartsländer (t.ex. Schweiz, Israel och Nya Zeeland) anses tillräckligt, eller genom vissa standardavtalsklausuler som har godkänts av EU och som utgör grunden för våra avtalsrelationer med personuppgiftsbiträdena, eller via jämförbara rättsliga instrument som tillåts enligt GDPR.
                                </p>
                                <p>
                                    6.4.3 Dessutom säkerställer vi att våra partners har ytterligare säkerhetsstandarder på plats, exempelvis enskilda säkerhetsåtgärder och dataskyddsbestämmelser eller -certifieringar enligt GDPR. Om en tredjepartsleverantör till exempel är baserad i USA ska denna följa regelverket Privacy Shield Framework som godkänts av EU, eller andra internationellt erkända säkerhetsstandarder.
                                </p>
                                <h3>
                                    6.5. Cookies
                                </h3>
                                <p>
                                    CamDiab kan använda så kallade cookies för att erbjuda dig ett enhetligt utbud av funktioner och göra det bekvämare att använda vår webbplats. Cookies är små textfiler som webbläsaren lagrar på din enhet. Utöver de cookies för användardata som nämns i avsnitt 6.6 kan vi även använda cookies för att driva webbplatsen. Om du inte vill använda cookies kan du ändra inställningarna i din webbläsare så att den förhindrar lagring av cookies. De flesta av våra cookies raderas antingen när du avslutar besöket eller när du stänger ner webbläsaren (sessionscookies). Om detta inte sker kan du kontrollera raderingsschemat i din webbläsare eller radera cookies manuellt.
                                </p>
                                <h3>
                                    6.6. Användningsdata
                                </h3>
                                <p>
                                    Vi använder Google Firebase Analytics i appen, vilket är en app- och webbanalystjänst från Google Inc. (“Google”). Google är certifierat enligt EU-US Privacy Shield.
                                </p>
                                <h3>
                                    6.7. Lagring och radering
                                </h3>
                                <p>
                                    6.7.1 Dina användardata lagras på din enhet samt på våra servrar. Vi använder enbart system som uppfyller kraven i GDPR.
                                </p>
                                <p>
                                    6.7.2 Dina data lagras på servrar inom EU.
                                </p>
                                <p>
                                    6.7.3 Som regel lagrar CamDiab bara dina personuppgifter så länge avtalet är i kraft. I undantagsfall kan längre lagring krävas för att uppfylla åtaganden efter avtalstidens slut eller för att följa lagstadgade förpliktelser till lagring eller utlämnande av data, eller för att hävda, genomdriva eller försvara rättsliga anspråk (preskriptionstider).
                                </p>
                                <h3>
                                    6.8. Minderåriga
                                </h3>
                                <p>
                                    Minderåriga under sexton år får bara använda våra produkter med en förälders eller vårdnadshavares tillåtelse (se <a href="/se/terms">Allmänna villkor</a>). Detta gäller även behandling av deras personuppgifter, som bara är lagligt om och i den utsträckning som en förälder/vårdnadshavare har gett sin tillåtelse. I annat fall är det inte tillåtet att använda våra produkter.
                                </p>
                                <h3>
                                    6.9. Dataskyddsombudet
                                </h3>
                                <p>
                                    6.9.1 Vår dataskyddsombudet kan svara på alla frågor om dataskydd och nås på <a href="mailto:dpo@evalian.co.uk">dpo@evalian.co.uk</a>.
                                </p>
                                <p>
                                    6.9.2 Dataskyddsombudet är i stor utsträckning involverad i alla frågor som är förknippade med att skydda våra användares personuppgifter. Dataskyddsansvarig är en utbildad expert som övervakar vår personuppgiftsbehandling kontinuerligt för att säkerställa bästa möjliga skydd för dina användardata.
                                </p>
                                <h2>
                                    7. DINA RÄTTIGHETER
                                </h2>
                                <h3>
                                    7.1. Återkalla samtycke
                                </h3>
                                <p>
                                    Om vi behandlar dina användaruppgifter med ditt samtycke som rättslig grund har du rätt att återkalla samtycket när som helst. Detta påverkar dock inte lagligheten för den behandling som skedde baserat på ditt samtycke innan det återkallades. Vi kommer att fortsätta att tillhandahålla våra tjänster om de inte är beroende av det samtycke som har återkallats.
                                </p>
                                <h3>
                                    7.2. <strong>Information, korrigering och begränsning</strong>
                                </h3>
                                <p>
                                    7.2.1 Som användare har du alltid rätt att få information om behandlingen av dina personuppgifter. Om du vill begära information om detta kontaktar du oss på <a
                                    href="mailto:privacy@camdiab.com">privacy@camdiab.com</a>.
                                </p>
                                <p>
                                    7.2.2 Din rätt till information omfattar information om ändamålet med behandlingen, kategorier av data och mottagare, lagringstid, ursprunget till dina data samt dina rättigheter enligt dataskyddslagstiftningen. Du hittar all denna information i det här integritetsmeddelandet och vi skickar den gärna till dig i elektroniskt format.
                                </p>
                                <p>
                                    7.2.3 Om några av dina personuppgifter skulle vara felaktiga kan du när som helst begära att dina uppgifter korrigeras eller kompletteras. Om du har begärt granskning av dina uppgifter har du rätt att begränsa behandlingen medan utredningen pågår.
                                </p>
                                <h3>
                                    7.3. Radering (”rätten att bli bortglömd”)
                                </h3>
                                <p>
                                    Som användare har du rätt att begära att dina personuppgifter raderas. Om du vill begära radering kontaktar du oss på <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h3>
                                    7.4. Klagomål
                                </h3>
                                <p>
                                    7.4.1 Om du upplever att vi inte tillgodoser dina dataskyddsrättigheter på ett bra sätt kan du kontakta oss på <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a> eller vända dig direkt till vår dataskyddsombudet på <a
                                    href="mailto:dpo@evalian.co.uk">dpo@evalian.co.uk</a>. Vi kommer att hantera dina synpunkter på lämpligt sätt.
                                </p>
                                <p>
                                    7.4.2 Som användare har du rätt att inge klagomål till den dataskyddsmyndighet som är ansvarig för CamDiab om du anser att behandlingen av dina personuppgifter inte följer dataskyddslagstiftningen. Dessutom kan du inge klagomål till tillsynsmyndigheten i den EU-medlemsstat där du bor eller har din arbetsplats eller där ett misstänkt intrång har skett.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer props={this.props}/>
            </div>
        );
    }
}

