/**
 * Texts for footer
 */
export const footerText = {
    txtFAQs: 'Usein kysyttyjä kysymyksiä',
    txtSupport: 'Tuki',
    txtHistory: 'Historia',
    txtTrainingHtml: 'https://www.camdiabtraining.com/account-sign-in.html',
    txtTraining: 'Verkkokoulutus',
    txtClinic: 'CamAPS FX Clinic',
    terms: 'Käyttöehdot',
    privacy: 'Tietosuojaehdot',
    txtFooterBottom: 'CamAPS on CamDiab Ltd -yrityksen tavaramerkki. Muut tavaramerkit ja tuotenimet ovat vastaavien omistajien omaisuutta. CamDiab-tuotemerkin tai -tuotenimen tai tämän sivuston ulkoasun käyttäminen ei ole sallittua ilman CamDiab-yhtiön etukäteen antamaa suostumusta, lukuun ottamatta yhtiön tuotteiden tai palveluiden tunnistamiseksi. Tuotekuvat ovat ainoastaan esimerkkejä.',
    rightsReserved: 'CamDiab Ltd. Kaikki oikeudet pidätetään.',

    statusPageFooterText: 'Tilasivu',
    statusPageFooterLink: 'https://camdiabltd.statuspage.io/',
};
