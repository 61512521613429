/**
 * Texts for header
 */
export const headerText = {
    cookiesIntroText: 'Utilizamos cookies solo para rastrear las visitas a nuestro sitio web, no almacenamos datos personales.',
    cookiesAccept: 'Aceptar cookies',
    cookiesDeny: 'Rechazar cookies',
    tabFAQs: 'Preguntas frecuentes',
    tabSupport: 'Asistencia',
    tabHistory: 'Historia',
    tabReviews: 'Opiniones',
    tabTraining: 'Formación',
    // tabOrders: 'Pedidos',
    tabOrders: '',
    tabNotifications: 'Notificaciones',
};
