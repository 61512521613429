const tab = '    ';
/**
 * Texts for Order page and modal popups
 */
export const orderPageText = {
    title: 'Beställa - CamDiab',
    titleMeta: 'Skaffa CamAPS FX - CamDiab',
    descriptionMeta: 'Få CamAPS FX för bara £70 per månad. Prova gratis i en månad. Kräver kompatibel Dana-insulinpump och Dexcom G6-glukossensor',
    localeMeta: 'sv_se',

    keywords:
        'camps kostnad, camps fx kostnad, camps order, camps fx order, camps fx gratis provperiod',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    // discount code processing messages
    pleaseWait: 'Vänta lite',
    successResult: 'Det gick bra',
    errorResult: 'Bearbetningsfel',
    discountApplied: 'Rabattkoden har tillämpats.',
    okButtonText: 'OK',

    // Discount line
    discountHeading: 'Rabatt',
    discountOver: ' under', // This reads: Discount: 30% over 3 months
    discountOneMonth: 'månad',
    discountTwoAndMoreMonths: 'månader',

    // interest in CamAPS FX related messages
    processingEnquiry: 'Din förfrågan bearbetas',
    thankYouForSubmission: 'Tack för att du skickade in dina uppgifter',
    processingError: 'Bearbetningsfel',
    responseAck: 'Vi ska titta på din förfrågan och återkommer till dig om CamAPS FX blir tillgänglig i ditt land',

    stage1: 'Steg 1',
    subscriptionOption: 'Prenumerationsalternativ',
    stage2: 'Steg 2',
    equipment: 'Utrustning',
    stage3: 'Steg 3',
    personalInfo: 'Personuppgifter',
    stage4: 'Steg 4',
    orderConfirmation: 'Beställningsbekräftelse',

    currencySubscription: '£',
    vatExempt: '(Undantag från moms)',

    costMonthly: 80,
    costSixMonthly: 450,
    costAnnual: 840,

    costMonthlyVAT: 96,
    costSixMonthlyVAT: 540,
    costAnnualVAT: 1008,

    subscriptionMonthly: 'Månadsprenumeration',
    subscriptionSixMonthly: 'Halvårsprenumeration',
    subscriptionAnnual: 'Årsprenumeration',

    interested: 'Är du intresserad av att köpa CamAPS FX?',
    interestedPara: 'Använd rabattkoden <b>CamAPS30</b> så får du en helt kostnadsfri provperiod på 30 dagar utan köpkrav.',

    subscriptionProcess: 'Så här går det till att börja prenumerera',
    choosePackage: 'Välj prenumerationspaket',
    answerQuestions: 'Svara på några frågor om dig själv',
    receiveTraining: 'Genomgå en webbaserad utbildning med legitimerad utbildare',

    // below are texts for API
    botCheckFailed: 'Robottestet misslyckades. Försök igen eller kontakta oss på support@camdiab.com',
    wrongDiscountCode: 'Rabattkoden är felaktig eller inte längre giltig.',
    pumpAlreadyExists: 'Det gick inte att slutföra beställningen. En pump med serienummer %s används redan. Kontrollera pumpens serienummer eller kontakta oss på sales@camdiab.com.',
    costTextWithDiscount: '%s%s för den första perioden (rabattkod%s) och därefter %s%s för efterföljande perioder',
    emailOrderSubjectLine: 'Din CamAPS FX-beställning',
    emailTrainingSubjectLine: 'Utbildning i CamAPS FX',
    emailInterestedSubjectLine: 'Förfrågan om CamAPS FX (%s)',

    emailVATexemptYes: 'Ja',
    emailVATexemptNo: 'Nej',

    emailOrderBody: "Bästa %s,<p>Här är din bekräftelse på beställningen av CamAPS FX-appen. Nedan hittar du uppgifter om din beställning:<p>" +
        tab + "Namn: %s %s<br>" +
        tab + "E-postadress: %s<br>" +
        tab + "Pumpserienummer: %s<br>" +
        tab + "Prenumerationsperiod: %s<br>" +
        tab + "%s<br>" +
        tab + "Undantag från moms: %s<br>" +
        tab + "Utbildningsklinik: %s<br><p><strong>Nästa steg</strong><br>Antingen kan du genomgå vår <a href=\"https://www.camdiabtraining.com/account-sign-in.html\">webbaserade utbildning i CamAPS FX</a> eller också kommer din diabetesutbildare personligen att visa dig hur du använder appen. Du kan hämta CamAPS FX-appen från Amazon Appstore men du kommer inte att kunna använda den förrän du har genomgått utbildningen och din diabetesutbildare låser upp appen med sin CamAPS FX-utbildningskod. Efter utbildningen kommer du att kunna börja använda CamAPS FX-appen direkt.<p><strong>Betalning</strong><br>När appen är upplåst och du kan börja använda den kommer du att få en faktura via e-post. Vi föredrar betalning via autogiro. Betalningarna måste göras i tid för att du ska kunna fortsätta att använda CamAPS FX-appen. Detta påverkar inte dina lagstadgade rättigheter. Du kan annullera din beställning när som helst före utbildningen eller inom 14 dagar och få full återbetalning av de betalningar du gjort.<p><strong>Prenumerationsperiod</strong><br>Prenumerationsperioden startar det datum då du genomgår utbildningen. Observera att inga pengar dras från ditt konto innan utbildningen är genomförd. Efter den första prenumerationsperioden krävs betalning för varje efterföljande prenumerationsperiod, såvida du inte annullerar din beställning genom att skriva till %s.<p>Tack för att du valde oss! Vi hoppas att du ska ha nytta av CamAPS FX-appen.<p>CamDiab-teamet<br>%s",

    emailPotentialUser: "Bästa %s %s,<p>Vi har förstått att du är intresserad av att använda CamAPS FX-appen, men du uppgav att du inte har möjlighet att få utbildning i CamAPS FX-appen på din diabetesmottagning. Vi skulle eventuellt kunna ordna utbildning åt dig mot en kostnad. Dina uppgifter är följande:<p>" +
        tab + "Namn: %s %s<br>" +
        tab + "E-postadress: %s<br><p><strong>Nästa steg</strong><br>Vi skickar din förfrågan vidare till de CamAPS FX-utbildare som vi samarbetar med. Förhoppningsvis kan någon av dem hjälpa dig. Utbildaren tar i så fall direktkontakt med dig via e-post. När du har kommit överens med utbildaren om hur utbildningen ska genomföras beställer du CamAPS FX via <a href=\"https://camdiab.com/\">camdiab.com</a>.<p><strong>Betalning för utbildningen</strong><br>Du avtalar kostnaden för utbildningen med CamAPS FX-utbildaren och betalar den separat. Det är inte CamDiab som tillhandahåller den tjänsten. <p><strong>CamAPS-utbildare</strong><br>Vi sätter stort värde på våra CamAPS FX-utbildare som själva genomgår lämplig utbildning.<p>Tack för visat intresse för CamAPS FX-appen.<p>CamDiab-teamet<br>%s",

    emailInterestedUser: "Bästa %s %s,<p>Vad roligt att du är intresserad av CamAPS FX-appen! Vi har noterat ditt intresse och kommer att kontakta dig om CamAPS FX blir tillgänglig i %s. Dina kontaktuppgifter:<p>" +
        tab + "Namn: %s %s<br>" +
        tab + "E-postadress: %s<br>" +
        tab + "Land: %s<br><p>Tack för visat intresse för CamAPS FX-appen.<p>CamDiab-teamet<br>%s",
    emailOrderBody_1: 'Tack för att du registrerar dig som användare av CamAPS FX-appen.',
    emailOrderBody_2: 'Bästa %s,',
    emailOrderBody_3: 'Här är din bekräftelse på beställningen av CamAPS FX-appen. Nedan hittar du uppgifter om din beställning:',
    emailOrderBody_4: 'Namn:',
    emailOrderBody_5: 'E-postadress:',
    emailOrderBody_6: 'Pumpserienummer:',
    emailOrderBody_7: 'Prenumerationsperiod:',
    emailOrderBody_8: 'Kostnad:',
    emailOrderBody_9: 'Undantag från moms:',
    emailOrderBody_10: 'Utbildningsklinik:',
    emailOrderBody_11: 'Nästa steg',
    emailOrderBody_12: 'Du kommer att få en faktura <b>efter</b> att du börjar använda CamAPS FX-appen.',
    emailOrderBody_13: 'Vi rekommenderar att du genomgår vår webbaserade utbildning i CamAPS FX.',
    emailOrderBody_14: 'I annat fall kommer din diabetesutbildare personligen att visa dig hur du använder appen.',
    emailOrderBody_15: 'Du kan hämta CamAPS FX-appen från Amazon Appstore men du kommer inte att kunna använda den förrän du har genomgått utbildningen och du själv eller din diabetesutbildare låser upp appen med en CamAPS FX-utbildningskod. ',
    emailOrderBody_16: 'När appen är upplåst kan du börja använda den direkt.',
    emailOrderBody_17: 'Betalning',
    emailOrderBody_18: 'Du kommer att få en faktura via e-post efter genomförd utbildning. Betalningarna måste göras i tid för att du ska kunna fortsätta att använda CamAPS FX-appen. Detta påverkar inte dina lagstadgade rättigheter. Du kan annullera din beställning när som helst före utbildningen eller inom 30 dagar och få full återbetalning av de betalningar du gjort.',
    emailOrderBody_19: 'Prenumerationsperiod',
    emailOrderBody_20: 'Prenumerationsperioden startar det datum då du genomgår utbildningen. Observera att inga pengar dras från ditt konto innan utbildningen är genomförd. Efter den första prenumerationsperioden krävs betalning för varje efterföljande prenumerationsperiod, såvida du inte annullerar din beställning genom att skriva till sales@camdiab.com.',
    emailOrderBody_21: 'Tack för att du registrerar dig som prenumerant på CamAPS FX-appen.',
    emailOrderBody_22: 'Om du behöver teknisk support hittar du uppgifter om detta på vår webbplats.',
    emailOrderBody_23: '© 2022 CamDiab Ltd. Med ensamrätt.',

    // Texts for step one form
    s1MonthlyHeading: 'Månadsprenumeration',
    s1MonthlyValue: '£80*',
    s1Select: 'Välj',
    s1HalfYearlyHeading: 'Halvårsprenumeration',
    s1HalfYearlyText: '£450*',
    s1HalfYearlyTextSaving: '(du sparar £30)',
    s1YearlyHeading: 'Årsprenumeration',
    s1YearlyText: '£840*',
    s1YearlyTextSaving: '(du sparar £120)',
    s1VATtext: ' *Plus moms om ej undantagen från moms. Hela summan betalas efter genomförd utbildning/aktivering av appen.',

    // Texts for step two form
    previous: 'Föregående',
    next: 'Nästa',
    s2Yes: 'Ja',
    s2No: 'Nej',
    s2HaveDanaPump: 'Har du en pump av modell mylife YpsoPump, Dana Diabecare RS eller DANA-i?',
    s2HaveCgm: 'Har du ett Dexcom G6 CGM-system?',
    s2Phone1: 'Har du en kompatibel',
    s2PhoneHtml: 'https://www.dexcom.com/dexcom-international-compatibility',
    s2Phone2: 'Android-telefon',
    s2Phone3: '?',
    s2NoPump: 'För att kunna använda CamAPS FX-appen måste du ha en insulinpump av modell mylife YpsoPump, Dana Diabecare RS eller DANA-i. Kontakta ditt lokala diabetesteam för att diskutera möjligheterna, eller vänd dig till din Dana-distributör.',
    s2NoCgm: 'För att kunna använda CamAPS FX-appen måste du ha ett Dexcom G6 CGM-system. Prata med ditt lokala diabetesteam om Dexcom G6 CGM-systemet eller kontakta Dexcom.',
    s2NoPhone: 'Om du inte använder en kompatibel Android-telefon kan vi inte garantera en tillförlitlig anslutning till Dexcom G6-sensorn. Överväg att skaffa en kompatibel Android-telefon.',

    // Texts for step three form
    s3InfoSource: [
        'Klinik',
        'Sökmotor',
        'Sociala medier',
        'Ett inlägg från en vän i sociala medier',
        'JDRF',
        'Diabetes UK',
        'Radio',
        'TV',
        'Pressen',
        'Muntligen',
        'Annat sätt'],

    // s3TeritoryQuestion: 'Are you based in the UK, the EU, or New Zealand?',
    s3TeritoryQuestion: 'Bor du i Storbritannien eller EU?',
    s3NotAvailable: 'CamAPS FX-appen är tyvärr bara tillgänglig i de angivna länderna. Fyll gärna i dina uppgifter om du vill att vi kontaktar dig när systemet blir tillgängligt i ditt land.',
    s3SelectCountry: 'Välj land',
    s3TC1: 'Genom att skicka in din förfrågan godkänner du våra',
    s3TC2: 'villkor',
    s3TC3: 'och',
    s3TC4: 'vår dataskyddspolicy',
    s3TC5: 'och ger oss tillstånd att kontakta dig.',
    s3Training1: 'Du måste få utbildning av en legitimerad utbildare och/eller genomgå en',
    s3Training2: 'webbaserad utbildning',
    s3Training3: 'innan du kan börja använda CamAPS FX-appen.',

    s3FirstName: 'Förnamn',
    s3LastName: 'Efternamn',
    s3Email: 'E-post',
    s3EmailConfirm: 'Bekräfta e-post',
    s3AddressLine1: 'Adressrad 1',
    s3AddressLine2: 'Adressrad 2',
    s3AddressLine3: 'Postadress',
    s3PostCode: 'Postnummer',
    s3SubmitCountry: 'Skicka',
    s3DisabilityHeading: 'Jag intygar att jag har följande funktionsnedsättning eller kronisk sjukdom:',
    s3Disability: 'Funktionsnedsättning eller kronisk sjukdom',
    s3signature: 'Signatur (skriv in ditt namn)',

    s3TrainingCdep1: 'Välj webbaserad CamAPS-utbildning för att fortsätta:',
    s3TrainingCdep2: 'Välj utbildning',
    s3TrainingCdep3: 'Webbaserad CamAPS-utbildning',

    s3contactHeading: 'Fyll i dina kontaktuppgifter',
    s3whereHeard: 'Hur hörde du talas om CamAPS FX-appen?',
    s3whereHeardSelect: 'Välj ett av följande alternativ',
    s3pumpSerialNumber: 'Serienummer på din Dana-pump:',
    s3pumpSerialNumberConfirm: 'Bekräfta serienumret på din Dana-pump:',
    s3pumpSerialNumberMessage: 'Viktigt:',
    s3pumpSerialNumberMessage1: 'Pumpens serienummer krävs för att CamAPS FX-appen ska kunna kopplas till pumpen. Om serienumret är felaktigt går det inte att koppla CamAPS FX-appen till pumpen. Du hittar serienumret på baksidan av pumpen. Ett serienummer kan se ut så här: THF00041FB.',
    s3vatExempt: 'Är du undantagen från moms?',
    s3vatHelp1: 'Du hittar mer information i hjälpavsnitten på',
    s3vatHelpHtml: 'https://www.gov.uk/government/organisations/hm-revenue-customs/contact/vat-reliefs-for-disabled-and-older-people',
    s3vatHelpLine: 'Råd om undantag från moms',
    s3vatHelp2: 'webbplatsen GOV.UK',
    s3vatHelp3: 'eller också kan du ringa hjälplinjen VAT Disabled Reliefs på 0300 123 1073.',
    s3vatHelp4: 'Personalen på HMRC kan inte ge råd om huruvida en person anses ha en kronisk sjukdom eller funktionsnedsättning.',
    s3vatHelp5: 'Viktigt:',
    s3vatHelp6: 'Om du beställer CamAPS FX-appen åt någon annan måste du fylla i den personens uppgifter i formuläret om undantag från moms.',
    s3PhoneWarning: 'Om du inte använder en kompatibel Android-telefon kan vi inte garantera en tillförlitlig anslutning till Dexcom G6-sensorn. Överväg att skaffa en kompatibel Android-telefon.',

    s3NameMissing: 'Förnamn är obligatoriskt',
    s3SurnameRequired: 'Efternamn är obligatoriskt',
    s3EmailRequired: 'E-post är obligatoriskt',
    s3CountryRequired: 'Land är obligatoriskt',
    s3EmailConfimrRequired: 'Bekräfta e-post är obligatoriskt',
    s3EmailMatchRequired: 'E-postadresserna måste stämma överens',
    s3ClinicRequired: 'Utbildningsklinik är obligatoriskt',
    s3DiseaseStateRequired: 'Du måste uppge vilken typ av behov användaren har',
    s3OnlyTextValueProvided: 'Innehåller endast textvärde',
    s3OnlyNumberValueProvided: 'Innehåller endast siffror',
    s3SerialNumbersMatch: 'Serienumren måste stämma överens',
    s3vatStatusRequired: 'Momsstatus måste anges',
    s3AddressLine1Required: 'Adressrad 1 är obligatorisk',
    s3AddressLine2Required: 'Adressrad 2 är obligatorisk',
    s3PostCodeRequired: 'Postadress och postnummer är obligatoriskt',
    s3SickessRequired: 'Funktionsnedsättning eller kronisk sjukdom är obligatoriskt',
    s3SignatureRequired: 'Signatur är obligatorisk',

    // Texts for step four form
    s4NextSteps:'Nästa steg',
    s4DownloadApp:'Du kan själv hämta CamAPS FX-appen från',
    s4DownloadGuideHtml:'https://s3-eu-west-1.amazonaws.com/camdiab.user.manual/Downloading+CamAPS+FX.pdf',
    s4DownloadAppGuide:'Installationsguide',
    s4Appstore:'Amazon Appstore,',
    s4DownloadApp1:'men du kommer inte att kunna använda den förrän du har',
    s4TrainingTextLink:'genomgått utbildningen',
    s4TrainingHtml:'https://camdiab.com/training',
    s4TrainingTitle:'Utbildning',

    s4Payment:'Betalningarna startar när du kopplar CamAPS FX-appen till din pump.',

    s4TermsAccept:'Jag godkänner',
    s4TermsTermsHtml:'https://camdiab.com/terms',
    s4TermsTermsText:'Villkor',
    s4TermsPrivacyHtml:'https://camdiab.com/privacy',
    s4TermsPrivacyText:'Dataskyddspolicy',

    s4DiscountCodeText:'Rabattkod (valfritt)',
    s4DiscountApplyCode:'Tillämpa kod',

    s4Summary:'Sammanfattning av beställningen',
    s4Name:'Namn:',
    s4Email:'E-post:',
    s4PumpSN:'Pumpens serienummer:',
    s4Training:'Utbildning:',
    s4Total:'Summa',

    s4CompleteButtonText:'Slutförd',

    // duration or subscription period in an email
    emailOneMonth: '1 månad',
    emailSixMonths: '6 månader',
    emailTwelveMonths: '1 år',

    // Order complete
    oderProcessedHeading: 'Din beställning bearbetas',
    oderProcessedSucess: 'Tack, din beställning är genomförd',
    oderProcessedError: 'Bearbetningsfel',
    oderProcessedEmailToBeSent: 'Du kommer snart att få en bekräftelse via e-post.',
};
