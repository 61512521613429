/**
 * Texts for footer
 */
export const footerText = {
    txtFAQs: 'Preguntas frecuentes',
    txtSupport: 'Asistencia',
    txtHistory: 'Historia',
    txtTrainingHtml: 'https://www.camdiabtraining.com/account-sign-in.html',
    txtTraining: 'Formación en línea',
    txtClinic: 'Consulta CamAPS FX',
    terms: 'Condiciones generales',
    privacy: 'Política de privacidad',
    txtFooterBottom: 'CamAPS es una marca registrada de CamDiab Ltd. Otras marcas comerciales son propiedad de sus respectivos propietarios. Se prohíbe el uso de cualquier marca registrada, nombre comercial e imagen comercial de CamDiab en este sitio sin la autorización previa por escrito de CamDiab, excepto para identificar el producto o los servicios de la empresa. Las imágenes del producto solo son para fines ilustrativos.',
    rightsReserved: 'CamDiab Ltd. Reservados todos los derechos.',

    statusPageFooterText: 'Página de estado',
    statusPageFooterLink: 'https://camdiabltd.statuspage.io/',
};
