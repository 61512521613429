/**
 * Texts for Training page
 */
export const trainingPageText = {
    title: 'Formación - CamAPS FX',
    titleMeta: 'Ofrecemos formación gratuita en línea a los usuarios de CamAPS FX',
    descriptionMeta: 'El Programa de Formación en Diabetes de Cambridge imparte formación certificada gratuita en línea para personas con diabetes de tipo 1, padres y tutores, personal escolar y profesionales sanitarios.',
    keywords:
        '\n' +
        'entrenamiento camaps, entrenamiento camaps fx,  ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'es_es',

    trainingHeader: 'Formación certificada',
    trainingHeader_2: 'Para usuarios, profesionales sanitarios y personal escolar',
    trainingHeaderText: 'Formación GRATUITA en línea en CamAPS FX facilitada por el Programa de Formación en Diabetes de Cambridge (CDEP).',
    websiteLink: 'https://www.camdiabtraining.com/account-sign-in.html',
    websiteLinkHeading: 'Formación del CDEP',
    getTrained: 'Fórmese',
};
