const tab = '    ';
/**
 * Texts for Order page and modal popups
 */
export const orderPageText = {
    title: 'Objednávky – CamDiab',
    titleMeta: 'Získejte CamAPS FX – CamDiab',
    descriptionMeta: 'Plaťte pouhých 70 liber měsíčně za používání aplikace CamAPS FX. Získejte zkušební verzi na měsíc zdarma. Vyžaduje kompatibilní inzulinovou pumpu Dana a kontinuální senzor Dexcom G6',
    localeMeta: 'cs_cz',

    keywords:
        'cena camaps, cena camaps fx, objednávka camaps, objednávka camaps fx, bezplatná zkušební verze camaps fx',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    // discount code processing messages
    pleaseWait: 'Počkejte',
    successResult: 'Zdařilo se',
    errorResult: 'Chyba při zpracování',
    discountApplied: 'Slevový kód byl uplatněn.',
    okButtonText: 'OK',

    // Discount line
    discountHeading: 'Sleva',
    discountOver: ' za', // This reads: Discount: 30% over 3 months
    discountOneMonth: 'měsíc',
    discountTwoAndMoreMonths: 'měsíců',

    // interest in CamAPS FX related messages
    processingEnquiry: 'Vaše poptávka se zpracovává',
    thankYouForSubmission: 'Děkujeme za zaslání vašich údajů',
    processingError: 'Chyba při zpracování',
    responseAck: 'Vaši poptávku zaznamenáme a budeme vás kontaktovat, jakmile bude aplikace CamAPS FX dostupná ve vaší zemi',

    stage1: 'Fáze 1',
    subscriptionOption: 'Možnosti předplatného',
    stage2: 'Fáze 2',
    equipment: 'Vybavení',
    stage3: 'Fáze 3',
    personalInfo: 'Osobní údaje',
    stage4: 'Fáze 4',
    orderConfirmation: 'Potvrzení objednávky',

    currencySubscription: '£',
    vatExempt: '(osvobození od DPH)',

    costMonthly: 80,
    costSixMonthly: 450,
    costAnnual: 840,

    costMonthlyVAT: 96,
    costSixMonthlyVAT: 540,
    costAnnualVAT: 1008,

    subscriptionMonthly: 'Měsíční předplatné',
    subscriptionSixMonthly: 'Pololetní předplatné',
    subscriptionAnnual: 'Roční předplatné',

    interested: 'Uvažujete o zakoupení aplikace CamAPS FX?',
    interestedPara: 'Použijte slevový kód <b>CamAPS30</b> a získejte na 30 dnů zkušební verzi – zdarma a bez jakýchkoli závazků.',

    subscriptionProcess: 'Jak funguje předplatné',
    choosePackage: 'Zvolte si balíček předplatného',
    answerQuestions: 'Odpovězte na několik otázek o vás',
    receiveTraining: 'Absolvujte certifikované online školení',

    // below are texts for API
    botCheckFailed: 'Ověření, zda jste člověk, se nezdařilo. Zkuste to za chvíli znovu nebo nás kontaktujte na support@camdiab.com',
    wrongDiscountCode: 'Nesprávný slevový kód nebo platnost slevového kódu vypršela',
    pumpAlreadyExists: 'Objednávku se nepodařilo dokončit. Pumpa se sériovým číslem %s se už používá. Zkontrolujte sériové číslo pumpy nebo nás kontaktujte na sales@camdiab.com.',
    costTextWithDiscount: '%s%s za první období (slevový kód %s) a poté %s%s za další období',
    emailOrderSubjectLine: 'Vaše objednávka aplikace CamAPS FX',
    emailTrainingSubjectLine: 'Školení k aplikaci CamAPS FX',
    emailInterestedSubjectLine: 'Dotaz k aplikaci CamAPS FX (%s)',

    emailVATexemptYes: 'Ano',
    emailVATexemptNo: 'Ne',

    emailOrderBody: "Dobrý den,<p>s potěšením potvrzujeme Vaši objednávku aplikace CamAPS FX. Toto jsou údaje z Vaší objednávky:<p>" +
        tab + "Jméno: %s %s<br>" +
        tab + "Kontaktní e-mail: %s<br>" +
        tab + "Sériové číslo pumpy: %s<br>" +
        tab + "Období předplatného: %s<br>" +
        tab + "%s<br>" +
        tab + "Osvobození od DPH: %s<br>" +
        tab + "Školicí zdravotnické zařízení: %s<br><p><strong>Další kroky</strong><br>Můžete teď absolvovat <a href=\"https://www.camdiabtraining.com/account-sign-in.html\">online školení k aplikaci CamAPS FX</a>, nebo Vás s používáním aplikace osobně seznámí Vaše diabetologická edukační zdravotní sestra. Aplikaci CamAPS FX si můžete stáhnout z obchodu Amazon, nebudete ji ale moci používat, dokud neabsolvujete školení a Váš školitel Vám aplikaci zadáním svého kódu k certifikovanému školení CamAPS FX neodemkne. Jakmile absolvujete školení, budete moci aplikaci CamAPS FX ihned začít používat.<p><strong>Platba</strong><br>Fakturu Vám pošleme, až bude aplikace odemknutá, takže ji budete moci začít používat. Upřednostňujeme platbu povolením k inkasu. Včasné platby jsou podmínkou pro nepřetržité používání aplikace CamAPS FX. Nejsou tím nijak dotčena vaše zákonná práva. Objednávku můžete kdykoli stornovat ještě před školením nebo do 14 dnů po školení. Bude Vám vrácena zaplacená částka v plné výši.<p><strong>Období předplatného</strong><br>Období předplatného začíná datem školení. Dokud neabsolvujete školení, nebude Vám účtována žádná částka. Po uplynutí prvního období předplatného budete povinni hradit částku za každé další období předplatného, dokud objednávku nezrušíte písemně na %s.<p>Děkujeme Vám a věříme, že Vám aplikace CamAPS FX pomůže.<p>Tým CamDiab<br>%s",

    emailPotentialUser: "Dobrý den,<p>zaznamenali jsme Váš zájem o používání aplikace CamAPS FX. Uvedli jste ale, že na Vaší diabetologické klinice nejste schopni zajistit školení k aplikaci CamAPS FX. Za úhradu Vám můžeme školení zajistit. Údaje z Vaší poptávky:<p>" +
        tab + "Jméno: %s %s<br>" +
        tab + "Kontaktní e-mail: %s<br><p><strong>Další kroky</strong><br>Vaši poptávku předáme spolupracujícím školitelům CamAPS FX, kteří Vám možná budou schopni pomoci. Pokud ano, ozvou se Vám e-mailem přímo. Až se se školitelem dohodnete na podmínkách školení, musíte prostřednictvím webu <a href=\"https://camdiab.com/\">camdiab.com</a> poslat objednávku na aplikaci CamAPS FX.<p><strong>Úhrada za školení</strong><br>Na ceně za školení se dohodnete se školitelem CamAPS FX a uhradíte ji zvlášť. Společnost CamDiab tyto služby neposkytuje. <p><strong>Školitelé CamAPS</strong><br>Vážíme si našich školitelů CamAPS FX, kteří prošli příslušným školením.<p>Děkujeme Vám za Váš zájem o aplikaci CamAPS FX.<p>Tým CamDiab<br>%s",

    emailInterestedUser: "Dobrý den,<p>děkujeme Vám za Váš zájem používat aplikaci CamAPS FX. Váš záznam jsme zaznamenali a ozveme se Vám, jakmile bude aplikace CamAPS FX v %s dostupná. Vaše údaje:<p>" +
        tab + "Jméno: %s %s<br>" +
        tab + "Kontaktní e-mail: %s<br>" +
        tab + "Země: %s<br><p>Děkujeme Vám za Váš zájem používat aplikaci CamAPS FX.<p>Tým CamDiab<br>%s",
    emailOrderBody_1: 'Děkujeme Vám za registraci jako uživatel aplikace CamAPS FX',
    emailOrderBody_2: 'Vážený pane, vážená paní,',
    emailOrderBody_3: 's potěšením potvrzujeme Vaši objednávku aplikace CamAPS FX. Toto jsou údaje z Vaší objednávky:',
    emailOrderBody_4: 'Jméno:',
    emailOrderBody_5: 'Kontaktní e-mail:',
    emailOrderBody_6: 'Sériové číslo pumpy:',
    emailOrderBody_7: 'Období předplatného:',
    emailOrderBody_8: 'Cena:',
    emailOrderBody_9: 'Osvobození od DPH:',
    emailOrderBody_10: 'Školicí zdravotnické zařízení:',
    emailOrderBody_11: 'Další kroky',
    emailOrderBody_12: 'Fakturu Vám pošleme <b>až poté, co</b> začnete aplikaci CamAPS FX používat.',
    emailOrderBody_13: 'Doporučujeme Vám absolvovat online školení k aplikaci CamAPS FX.',
    emailOrderBody_14: 'Případně Vás s používáním aplikace osobně seznámí Vaše diabetologická edukační sestra.',
    emailOrderBody_15: 'Aplikaci CamAPS FX si můžete stáhnout z obchodu Amazon, nebudete ji ale moci používat, dokud neabsolvujete školení a Váš školitel Vám aplikaci zadáním svého kódu k certifikovanému školení CamAPS FX neodemkne. ',
    emailOrderBody_16: 'Po odemknutí budete moci aplikaci ihned začít používat.',
    emailOrderBody_17: 'Platba',
    emailOrderBody_18: 'Po školení Vám e-mailem pošleme fakturu. Včasné platby jsou podmínkou pro nepřetržité používání aplikace CamAPS FX. Nejsou tím nijak dotčena vaše zákonná práva. Objednávku můžete kdykoli stornovat ještě před školením nebo do 30 dnů po školení. Bude Vám vrácena zaplacená částka v plné výši.',
    emailOrderBody_19: 'Období předplatného',
    emailOrderBody_20: 'Období předplatného začíná datem školení. Dokud neabsolvujete školení, nebude Vám účtována žádná částka. Po uplynutí prvního období předplatného jste povinni hradit částku za každé další období předplatného, dokud objednávku nezrušíte písemně na sales@camdiab.com.',
    emailOrderBody_21: 'Děkujeme Vám za předplatné aplikace CamAPS FX',
    emailOrderBody_22: 'Kontakty pro případnou technickou podporu najdete na našem webu.',
    emailOrderBody_23: '© 2021 CamDiab Ltd. Všechna práva vyhrazena.',

    // Texts for step one form
    s1MonthlyHeading: 'Měsíční předplatné',
    s1MonthlyValue: '80 £*',
    s1Select: 'Vybrat',
    s1HalfYearlyHeading: 'Pololetní předplatné',
    s1HalfYearlyText: '450 £*',
    s1HalfYearlyTextSaving: '(úspora 30 £)',
    s1YearlyHeading: 'Roční předplatné',
    s1YearlyText: '840 £*',
    s1YearlyTextSaving: '(úspora 120 £)',
    s1VATtext: ' *Plus VAT, pokud není osvobozeno od DPH. Splatné v plné výši po školení / aktivaci aplikace.',

    // Texts for step two form
    previous: 'Předchozí',
    next: 'Další',
    s2Yes: 'Ano',
    s2No: 'Ne',
    s2HaveDanaPump: 'Máte pumpu mylife YpsoPump, Dana Diabecare RS nebo DANA-i?',
    s2HaveCgm: 'Máte systém Dexcom G6 CGM?',
    s2Phone1: 'Máte kompatibilní',
    s2PhoneHtml: 'https://www.dexcom.com/dexcom-international-compatibility',
    s2Phone2: 'telefon Android',
    s2Phone3: '?',
    s2NoPump: 'Abyste mohli aplikaci CamAPS FX používat, musíte mít inzulinovou pumpu mylife YpsoPump, Dana Diabecare RS nebo DANA-i. Poraďte se se svým lékařem nebo se obraťte na místního distributora společnosti Dana.',
    s2NoCgm: 'Abyste mohli aplikaci CamAPS FX používat, musíte používat systém Dexcom G6 CGM. Poraďte se o systému Dexcom G6 CGM se svým lékařem nebo se obraťte na společnost Dexcom.',
    s2NoPhone: 'Bez použití kompatibilního telefonu se systémem Android nemůžeme zaručit spolehlivé připojení k senzoru Dexcom G6. Zvažte pořízení kompatibilního telefonu se systémem Android.',

    // Texts for step three form
    s3InfoSource: [
        'Klinika',
        'Vyhledávač',
        'Sociální sítě',
        'Příspěvek od přítele ze sociálních sítí',
        'JDRF',
        'Diabetes UK',
        'Rádio',
        'Televize',
        'Tisk',
        'Ústní doporučení',
        'Jinak'],

    // s3TeritoryQuestion: 'Are you based in the UK, the EU, or New Zealand?',
    s3TeritoryQuestion: 'Máte bydliště nebo sídlo ve Spojeném království nebo v EU?',
    s3NotAvailable: 'Aplikace CamAPS FX je bohužel dostupná pouze v určených zemích. Pokud chcete, abychom vás kontaktovali, až bude dostupná ve vaší zemi, vyplňte své údaje.',
    s3SelectCountry: 'Vybrat zemi',
    s3TC1: 'Odesláním žádosti vyjadřujete souhlas s našimi',
    s3TC2: 'podmínkami',
    s3TC3: 'a',
    s3TC4: 'zásadami ochrany osobních údajů',
    s3TC5: 'a souhlasíte s tím, že Vás můžeme kontaktovat.',
    s3Training1: 'Musíte absolvovat školení vedené certifikovaným školitelem nebo',
    s3Training2: 'online školení,',
    s3Training3: 'teprve poté můžete začít aplikaci CamAPS FX používat.',

    s3FirstName: 'Jméno',
    s3LastName: 'Příjmení',
    s3Email: 'E-mailová adresa',
    s3EmailConfirm: 'E-mailová adresa k potvrzení',
    s3AddressLine1: 'První řádek adresy',
    s3AddressLine2: 'Druhý řádek adresy',
    s3AddressLine3: 'Třetí řádek adresy',
    s3PostCode: 'PSČ',
    s3SubmitCountry: 'Odeslat',
    s3DisabilityHeading: 'Prohlašuji, že mám níže uvedené zdravotní postižení nebo chronické onemocnění:',
    s3Disability: 'Zdravotní postižení nebo chronické onemocnění',
    s3signature: 'Podpis (jméno hůlkovým písmem)',

    s3TrainingCdep1: 'Abyste mohli pokračovat, vyberte online školení CamAPS:',
    s3TrainingCdep2: 'Vybrat školení',
    s3TrainingCdep3: 'Online školení CamAPS',

    s3contactHeading: 'Zadejte své kontaktní údaje',
    s3whereHeard: 'Jak jste se o aplikaci CamAPS FX dozvěděli?',
    s3whereHeardSelect: 'Vyberte jednu z možností',
    s3pumpSerialNumber: 'Sériové číslo vaší pumpy Dana:',
    s3pumpSerialNumberConfirm: 'Potvrďte sériové číslo vaší pumpy Dana:',
    s3pumpSerialNumberMessage: 'Důležité:',
    s3pumpSerialNumberMessage1: 'Sériové číslo pumpy je nutné k propojení aplikace CamAPS FX s pumpou. Nesprávné sériové číslo může mít za následek nemožnost propojení aplikace CamAPS FX s pumpou. Sériové číslo najdete na boku pumpy. Příklad sériového čísla: THF00041FB',
    s3vatExempt: 'Vztahuje se na vás osvobození od DPH?',
    s3vatHelp1: 'Podrobné informace najdete v nápovědách na webu',
    s3vatHelpHtml: 'https://www.gov.uk/government/organisations/hm-revenue-customs/contact/vat-reliefs-for-disabled-and-older-people',
    s3vatHelpLine: 'Informace k osvobození od DPH',
    s3vatHelp2: 'web GOV.UK',
    s3vatHelp3: 'nebo zavolejte na linku úlev na DPH pro zdravotně postižené 0300 123 1073.',
    s3vatHelp4: 'Pracovníci HMRC se nemohou vyjadřovat k tomu, zda je někdo chronicky nemocný nebo zdravotně postižený.',
    s3vatHelp5: 'Důležité:',
    s3vatHelp6: 'Pokud aplikaci CamAPS FX neobjednáváte pro sebe, vyplňte do formuláře pro osvobození od DPH údaje osoby, pro niž aplikaci objednáváte.',
    s3PhoneWarning: 'Bez použití kompatibilního telefonu se systémem Android nemůžeme zaručit spolehlivé připojení k senzoru Dexcom G6. Zvažte pořízení kompatibilního telefonu se systémem Android',

    s3NameMissing: 'Křestní jméno je povinné',
    s3SurnameRequired: 'Příjmení je povinné',
    s3EmailRequired: 'E-mail je povinný',
    s3CountryRequired: 'Země je povinná',
    s3EmailConfimrRequired: 'E-mail k potvrzení je povinný',
    s3EmailMatchRequired: 'E-mailové adresy musejí být stejné',
    s3ClinicRequired: 'Školicí zdravotnické zařízení je povinné',
    s3DiseaseStateRequired: 'Musí být uveden typ uživatele',
    s3OnlyTextValueProvided: 'Obsahuje pouze text',
    s3OnlyNumberValueProvided: 'Obsahuje pouze číslice',
    s3SerialNumbersMatch: 'Sériové číslo se musí shodovat',
    s3vatStatusRequired: 'Musí být uveden údaj o plátcovství DPH',
    s3AddressLine1Required: 'První řádek adresy je povinný',
    s3AddressLine2Required: 'Druhý řádek adresy je povinný',
    s3PostCodeRequired: 'PSČ je povinné',
    s3SickessRequired: 'Zdravotní postižení nebo chronické onemocnění jsou povinné',
    s3SignatureRequired: 'Podpis je povinný',

    // Texts for step four form
    s4NextSteps:'Další kroky',
    s4DownloadApp:'Aplikaci CamAPS FX si můžete stáhnout z',
    s4DownloadGuideHtml:'https://s3-eu-west-1.amazonaws.com/camdiab.user.manual/Downloading+CamAPS+FX.pdf',
    s4DownloadAppGuide:'Průvodce instalací',
    s4Appstore:'obchodu Amazon,',
    s4DownloadApp1:'nebudete ji ale moci používat, dokud neabsolvujete',
    s4TrainingTextLink:'školení',
    s4TrainingHtml:'https://camdiab.com/training',
    s4TrainingTitle:'Školení',

    s4Payment:'Platby jsou účtovány až od propojení aplikace CamAPS FX s pumpou.',

    s4TermsAccept:'Souhlasím',
    s4TermsTermsHtml:'https://camdiab.com/terms',
    s4TermsTermsText:'Podmínky',
    s4TermsPrivacyHtml:'https://camdiab.com/privacy',
    s4TermsPrivacyText:'Zásady ochrany osobních údajů',

    s4DiscountCodeText:'Slevový kód (nepovinný)',
    s4DiscountApplyCode:'Uplatnit kód',

    s4Summary:'Shrnutí objednávky',
    s4Name:'Jméno:',
    s4Email:'E-mail:',
    s4PumpSN:'SN pumpy:',
    s4Training:'Školení:',
    s4Total:'Celkem',

    s4CompleteButtonText:'Dokončit',

    // duration or subscription period in an email
    emailOneMonth: '1 měsíc',
    emailSixMonths: '6 měsíců',
    emailTwelveMonths: 'ročně',

    // Order complete
    oderProcessedHeading: 'Vaše objednávka se zpracovává',
    oderProcessedSucess: 'Děkujeme Vám, Vaše objednávka byla přijata',
    oderProcessedError: 'Chyba při zpracování',
    oderProcessedEmailToBeSent: 'Brzy dostanete e-mail s potvrzením',
};
