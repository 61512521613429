/**
 * Texts for Training page
 */
export const trainingPageText = {
    title: 'Addestramento - CamAPS FX',
    titleMeta: 'Forniamo corsi di addestramento online gratuiti per gli utilizzatori dell’app CamAPS FX',
    descriptionMeta: 'Il Cambridge Diabetes Education Programme è un programma educativo sul diabete che offre corsi di addestramento online certificati per persone con diabete di tipo 1, genitori e tutori, personale scolastico e operatori sanitari.',
    keywords:
        'camaps addestramento, camaps fx addestramento,  ',
    // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'it',

    trainingHeader: 'Addestramento certificato',
    trainingHeader_2: 'Per utilizzatori, operatori sanitari e personale scolastico',
    trainingHeaderText: 'Corso di addestramento online GRATUITO all’app CamAPS FX offerto dal Cambridge Diabetes Education Programme (CDEP).',
    websiteLink: 'https://www.camdiabtraining.com/account-sign-in.html',
    websiteLinkHeading: 'Addestramento CDEP',
    getTrained: 'Partecipa all’addestramento',
};
