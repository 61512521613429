/**
 * Texts for header
 */
export const headerText = {
    cookiesIntroText: 'Wij gebruiken cookies voor het volgen van bezoeken aan onze website, wij slaan geen persoonlijke informatie op.',
    cookiesAccept: 'Cookies accepteren',
    cookiesDeny: 'Cookies afwijzen',
    tabFAQs: 'Veelgestelde vragen',
    tabSupport: 'Ondersteuning',
    tabHistory: 'Geschiedenis',
    tabReviews: 'Beoordelingen',
    tabTraining: 'Training',
    // tabOrders: 'Bestellingen',
    tabOrders: '',
    tabNotifications: 'Meldingen',
};
