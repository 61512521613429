import React, {Component} from 'react';
import {Header} from '../Components/Header';
import {Footer} from '../Components/Footer';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

export class TermsCZ extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Smluvní podmínky - CamAPS FX</title>
                    <meta name="title" content='Smluvní podmínky'/>
                    <meta name="description"
                          content='Zde najdete smluvní podmínky CamDiab'/>
                    <meta property="og:locale" content='cs_cz'/>
                    <meta name="keywords" content='Podmínky'/>
                </Helmet>
                <Header props={this.props}/>
                <section className="inner-banner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-10">
                                <h1>Smluvní podmínky</h1>
                                <h3>Verze ze dne 24. června 2024</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <h2>
                                    1. ROZSAH PLATNOSTI
                                </h2>
                                <h3>
                                    1.1 Naše produkty
                                </h3>
                                <p>
                                    1.1.1 Společnost
                                </p>
                                <p>
                                    CamDiab Ltd <strong>
                                    <br/>
                                    Sídlo společnosti<br/>
                                    20-22 Wenlock Road<br/>
                                    London, N1 7GU, Spojené království,
                                    <br/>
                                </strong>
                                    support@camdiab.com
                                    <br/>
                                    číslo společnosti 11659211, registrovaná v Anglii a Walesu
                                    <br/>
                                    <br/>
                                </p>
                                <p>
                                    (dále „<strong>CamDiab</strong>“), vyvíjí a provozuje pro osoby s diabetem a zdravotnické pracovníky mobilní aplikaci (aplikace) pro podávání inzulinu v uzavřené smyčce a propojenou datovou infrastrukturu. CamDiab je společnost zabývající se elektronickým zdravotnictvím a lidem s diabetem chce nabídnout lepší kontrolu glykémie a snížit zátěž spojenou s péčí o diabetes.
                                </p>
                                <p>
                                    1.1.2 Naše produkty řídí a podporují podle našich nejlepších znalostí uzavřenou smyčku podávání inzulinu. Naše produkty a služby ale nemohou nahradit konzultaci a stanovení diagnózy zdravotnickým pracovníkem. V případě dotazů týkajících se nemoci a léčby doporučujeme kontaktovat zdravotnického pracovníka. Poskytnuté údaje smíte vyhodnocovat pouze vy a váš zdravotnický tým. Žádný obsah, ať už poskytnutý samotnou společností CamDiab, našimi spolupracovníky, distributory, smluvními partnery nebo uživateli, nelze chápat jako doplněk k informacím nebo náhradu informací od zdravotnického pracovníka nebo lékárníka.
                                </p>
                                <p>
                                    1.1.3 Společnost CamDiab může nabízet řadu produktů (aplikací) a služeb a umožňovat sdílení dat na podporu léčby diabetu. Všechny současné a budoucí aplikace a služby (včetně nových verzí, aktualizací a dalších vylepšení), které společnost CamDiab jakýmkoli způsobem poskytuje, jsou následně označovány souhrnně jako „<strong>produkty</strong>“, resp. jednotlivě jako „<strong>produkt</strong>“. Každý, která získá a používá jeden nebo více našich produktů, je označován jako „<strong>uživatel</strong>“, resp. souhrnně jako „<strong>uživatelé</strong>“. Je-li v těchto podmínkách odkazováno na fyzické osoby v mužském rodě, platí stejně pro všechny osoby.
                                </p>
                                <p>
                                    1.1.4 V souvislosti s těmito podmínkami platí pro jednotlivé produkty následující definice:
                                </p>
                                <p>
                                    <strong>CamAPS FX</strong> je nezávislá mobilní aplikace pro chytrý telefon, která osobám s diabetem 1. typu nabízí podávání inzulinu v hybridní uzavřené smyčce. Aplikace je propojena s kontinuálním senzorem a inzulinovou pumpou třetí strany. Můžete se rozhodnout sdílet data shromážděná aplikací se společností CamDiab nebo s portálem (portály) třetích stran pro diabetes za účelem prohlížení dat. Podle pumpy a kontinuálního senzoru od externího dodavatele mohou platit určitá omezení, jaký model smartphonu se systémem lze použít.
                                </p>
                                <p>
                                    <strong>CamAPS HX</strong> je podobná aplikace jako CamAPS FX, ale nabízí plně uzavřenou smyčku u diabetu jiného typu.
                                </p>
                                <p>
                                    1.1.5 Případné zvláštnosti našich produktů jsou popsány v našich uživatelských příručkách (dostupných v aplikacích nebo na našem webu) a v příslušných zvláštních opatřeních.
                                </p>
                                <h3>
                                    1.2 Rozsah platnosti našich podmínek
                                </h3>
                                <p>
                                    1.2.1 Naše produkty nabízíme výhradně na základě aktuálního znění těchto všeobecných podmínek (dále „podmínky“), které platí po celém světě. Společnost CamDiab výslovně odmítá jakékoli jiné obchodní podmínky nebo podmínky používání.
                                </p>
                                <p>
                                    1.2.2 Před prvním použitím našich produktů musíte souhlasit se všemi uvedenými prohlášeními a ujednáními a řídit se jimi při dalším používání. Registrace a používání našich produktů bez poskytnutí souhlasu s našimi podmínkami je zakázáno.
                                </p>
                                <p>
                                    1.2.3 Výslovně si vyhrazujeme právo na budoucí změny a věcně odůvodněné úpravy našich podmínek. Změny mohou být nezbytné, abychom mohli splnit zákonné, technické a ekonomické požadavky nebo vyhovět zájmům našich uživatelů. Takové změny jsou možné kdykoli a budou vhodným způsobem zveřejněny. Pokud do jednoho měsíce nevyjádříte nesouhlas, bude se další používání našich produktů řídit příslušnými novými podmínkami.
                                </p>
                                <h3>
                                    1.3 Externí dodavatelé
                                </h3>
                                <p>
                                    1.3.1 Naše produkty můžete najít v obchodě (obchodech) s aplikacemi. Obecně platí podmínky příslušného externího dodavatele, na které nemáme žádný vliv. Nejsou součástí našich podmínek ani našeho smluvního vztahu s vámi jakožto uživatelem našich produktů. To platí i pro podmínky dodavatele mobilního zařízení nebo mobilních služeb.
                                </p>
                                <p>
                                    1.3.2 Spolupracujeme s různými partnery, abychom zajistili optimální používání našich produktů. Jsou to mj. partneři, kteří našim uživatelům přímo dodávají určité produkty (např. inzulinovou pumpu, nebo kontinuální monitor glukózy). V takových případech vždy odkazujeme na platné podmínky externího dodavatele, protože je musíte přijmout, abyste poté mohli začít používat naše produkty.
                                </p>
                                <h2>
                                    2. OCHRANA OSOBNÍCH ÚDAJŮ
                                </h2>
                                <p>
                                    <strong> Jsme si vědomi velké odpovědnosti, která je spojena s používáním našich produktů. Sdělujete nám nejen obecné osobní údaje, ale také údaje o svém zdravotním stavu. V našich </strong>
                                    <Link to="/cz/privacy">
                                        zásadách ochrany osobních údajů</Link><strong> vás informujeme o používání a ochraně vašich údajů v souvislosti s používáním našich produktů a o vašem souhlasu, který je k tomu nutný. </strong>
                                </p>
                                <p>
                                    <strong> BEZ VAŠEHO SOUHLASU SE ZPRACOVÁNÍM OSOBNÍCH ÚDAJŮ NENÍ POUŽÍVÁNÍ PRODUKTŮ PRÁVNĚ ANI FAKTICKY MOŽNÉ. </strong>
                                </p>
                                <p>
                                    <strong>Naše </strong>
                                    <Link to="/cz/privacy"> zásady ochrany osobních údajů</Link><strong> nejsou součástí těchto podmínek. Slouží pouze k tomu, aby vám poskytly informace podle nařízení GDPR, na jehož základě poskytujete souhlas s nezbytným zpracováním údajů. </strong>
                                </p>
                                <h2>
                                    3. UZAVŘENÍ SMLOUVY A REGISTRACE
                                </h2>
                                <h3>
                                    3.1 Nákup a platba za naše produkty
                                </h3>
                                <p>
                                    3.1.1 Naše produkty lze zakoupit různými způsoby:
                                </p>
                                <h3>
                                    Aplikace
                                </h3>
                                <p>
                                    <strong>Naše produkty</strong> nabízejí obchody s aplikacemi (například Google Play a Amazon Appstore). Upozorňujeme, že pro stahování (nikoli používání) našich produktů platí samostatné podmínky obchodu s aplikacemi, které mohou vyžadovat uživatelský účet.
                                </p>
                                <p>
                                    Přímý přístup k našim produktům a jejich objednávání může nabízet také <strong>náš web</strong>.
                                </p>
                                <p>
                                    V oběhu mohou být <strong>slevové poukázky</strong> na naše produkty, které lze uplatnit na našem webu.
                                </p>
                                <p>
                                    3.1.2 Naše zpoplatněné produkty si můžete objednat na našem webu nebo prostřednictvím našich distributorů. Platí se prostřednictvím poskytovatele platebních služeb, našich distributorů nebo jiných stanovených platebních možností. Distributor působí buď jako zprostředkovatel a poskytovatel platebních služeb pro společnost CamDiab, nebo jako přímý prodejce. Pokud se vyskytnou problémy se stahováním nebo s platbou, obraťte se na nás kdykoli e-mailem na <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <p>
                                    3.1.3 Poplatky za užívání nebo kupní ceny (včetně zákonné daně z přidané hodnoty) je třeba podle nabídky a výběru hradit jednorázově, nebo opakovaně (např. měsíčně, pololetně nebo ročně). Aktuální poplatky jsou uvedeny na našem webu, sdělí vám je distributor nebo se zobrazují v naší aplikaci nebo v obchodech s aplikacemi a jsou splatné předem. Pokud platba neprobíhá přímo v obchodě s aplikacemi, může vám poskytovatel platby nebo distributor posílat faktury a upomínky platby e-mailem. V případě zaviněné opožděné nebo neúplné platby jsme oprávněni zablokovat vám přístup k našim produktům. Vaše povinnost nadále platit poplatky za používání tím není dotčena.
                                </p>
                                <p>
                                    3.1.4 Společnost CamDiab může rovněž poskytovat speciální a bezplatné nabídky, které podléhají dalším podmínkám nebo omezením. V takových případech výslovně odkazujeme na případné odchylky od těchto podmínek, jinak nadále platí tyto podmínky v plném rozsahu. Speciální nebo bezplatné nabídky nelze převádět na jiné uživatele.
                                </p>
                                <h3>
                                    3.2 Registrace a aktivace
                                </h3>
                                <p>
                                    3.2.1 K používání našich produktů je nezbytné registrovat se v aplikacích u společnosti CamDiab. Po první aktivaci našeho produktu se musíte registrovat pomocí e-mailové adresy a hesla (CamDiab ID). Uživatel si může vytvořit vždy pouze jedno ID CamDiab a jedno ID CamDiab může používat vždy pouze jeden uživatel.
                                </p>
                                <p>
                                    3.2.2 Registrací uživatel předkládá závaznou nabídku na uzavření smlouvy se společností CamDiab o používání našich produktů na základě platné verze těchto podmínek a souhlasí s ní. Takovou nabídku výslovně přijímáme, a to nejpozději aktivací vašeho ID CamDiab.
                                </p>
                                <p>
                                    3.2.3 Aktivace je potvrzena po doručení vašich přihlašovacích údajů a po absolvování příslušného školení. Vyhrazujeme si právo odmítnout jednotlivé uživatele nebo jejich nabídku na uzavření smlouvy i bez udání důvodu. V takovém případě budou veškeré již provedené platby vráceny a poskytnuté uživatelské údaje okamžitě vymazány.
                                </p>
                                <p>
                                    3.2.4 Uživatel při registraci potvrzuje, že je starší šestnácti let a že podle platných právních předpisů může vstupovat do smluvních závazků. Registrovat se mohou pouze osoby způsobilé k právním úkonům. Nezletilým je to umožněno pouze s výslovným souhlasem rodiče/opatrovníka. Registrací rodič/zákonný zástupce prohlašuje, že je oprávněn jménem nezletilé osoby činit právně závazná prohlášení.
                                </p>
                                <p>
                                    3.2.5 Při registraci uživatel potvrzuje, že bere na vědomí tyto podmínky a bezvýhradně s nimi souhlasí a že údaje, které uvádí, jsou pravdivé, správné, aktuální a úplné. Vyhrazujeme si právo kdykoli kontaktovat uživatele kvůli ověření registračních údajů a informací o používání.
                                </p>
                                <p>
                                    3.2.6 Pokud uživatel uvede nepravdivé, nesprávné, neaktuální nebo neúplné údaje nebo pokud budeme mít oprávněný důvod se domnívat, že údaje jsou nepravdivé, nesprávné, neaktuální nebo neúplné, je společnost CamDiab oprávněna s okamžitou platností a bez předchozího upozornění zablokovat příslušné ID CamDiab a zakázat používání našich produktů, aniž by byla povinna uhradit uživateli vzniklé náklady.
                                </p>
                                <p>
                                    3.2.7 Uživatel musí chránit své registrační údaje před neoprávněným přístupem třetích stran, zneužitím nebo použitím s podvodným úmyslem. Pokud existuje byť jen podezření, že ID CamDiab bylo vystaveno takovému riziku, je třeba to neprodleně oznámit na <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>. Máme právo zablokovat ID CamDiab jakéhokoli uživatele, pokud je používáno k neoprávněným nebo podvodným účelům.
                                </p>
                                <h2>
                                    4. PRÁVO NA ODVOLÁNÍ A ODSTOUPENÍ OD SMLOUVY
                                </h2>
                                <h3>
                                    4.1 Informace k uplatnění práva na odstoupení od smlouvy
                                </h3>
                                <p>
                                    4.1.1 Pokud naše produkty nejsou používány pro komerční nebo profesionální účely, máte jako spotřebitel právo odstoupit od smlouvy uzavřené se společností CamDiab za následujících podmínek:
                                </p>
                                <p>
                                    <strong>PRÁVO NA ODSTOUPENÍ OD SMLOUVY</strong>
                                </p>
                                <p>
                                    <strong><u>Právo na odstoupení od smlouvy</u></strong>
                                </p>
                                <p>
                                    <strong> Máte právo odstoupit od této smlouvy i bez udání důvodu do čtrnácti dnů. </strong>
                                </p>
                                <p>
                                    <strong> Lhůta pro odstoupení od smlouvy je čtrnáct dnů od data uzavření smlouvy (aktivace elektronického obsahu). </strong>
                                </p>
                                <p>
                                    <strong> Chcete-li uplatnit právo na odstoupení od smlouvy, musíte nás o svém rozhodnutí odstoupit od smlouvy informovat prostřednictvím jasného prohlášení adresovaného distributorovi nebo společnosti CamDiab, e-mail: </strong><strong><a href="mailto:support@camdiab.com">support@camdiab.com</a></strong><strong> (např. e-mailem nebo poštou). </strong>
                                </p>
                                <p>
                                    <strong> K dodržení lhůty pro odstoupení od smlouvy stačí, když oznámení o uplatnění práva na odstoupení od smlouvy odešlete před koncem lhůty pro odstoupení od smlouvy. </strong>
                                </p>
                                <p>
                                    <strong><u>Důsledky odstoupení od smlouvy</u></strong>
                                </p>
                                <p>
                                    <strong> Pokud odstoupíte od této smlouvy, distributor nebo případně naše společnost jsou povinni vám neprodleně, nejpozději však do čtrnácti dnů ode dne, kdy obdržíme vaše oznámení o odstoupení od této smlouvy, vrátit všechny platby, které jsme od vás obdrželi. Pokud je to možné, použijeme k jejich vrácení stejný způsob platby, jaký jste použili při původní transakci, ledaže se výslovně dohodneme jinak. S vrácením těchto plateb nejsou spojeny žádné poplatky. </strong>
                                </p>
                                <h2>
                                    5. POUŽÍVÁNÍ NAŠICH PRODUKTŮ
                                </h2>
                                <h3>
                                    5.1 Doba používání a její ukončení
                                </h3>
                                <p>
                                    5.1.1 Vaše právo používat naše produkty začíná registrací a aktivací společností CamDiab.
                                </p>
                                <p>
                                    5.1.2 Naše zpoplatněné produkty lze zakoupit ve formě měsíčního, šestiměsíčního nebo ročního předplatného, podle aktuální nabídky jsou k dispozici i na kratší nebo delší dobu užívání (dále „<strong>předplatné</strong>“).
                                </p>
                                <p>
                                    5.1.3 Uživatel i společnost CamDiab jsou oprávněni smlouvu kdykoli řádně vypovědět s měsíční výpovědní lhůtou, která počíná běžet ke konci měsíce. Můžete tak učinit buď kontaktováním distributora, nebo písemným oznámením na <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>. Již uhrazené poplatky za nevyužité období předplatného se vracejí pouze v případě řádného ukončení ze strany společnosti CamDiab nebo automatického ukončení (bod 5.1.5 níže).
                                </p>
                                <p>
                                    5.1.4 Pokud nedojde k výpovědi nebo je výpověď opožděná, doba předplatného se automaticky prodlouží o další období odpovídající dříve zvolenému období předplatného. Platí příslušná standardní cena v době prodloužení (nikoli ceny speciálních nebo bezplatných nabídek).
                                </p>
                                <p>
                                    5.1.5 V případě úmrtí uživatele nebo odvolání souhlasu se zpracováním osobních údajů uživatelský vztah automaticky s okamžitou platností končí. Společnost CamDiab nesmí zpracovávat vaše zdravotní údaje bez vašeho souhlasu.
                                </p>
                                <p>
                                    5.1.6 Pokud budete mít dotazy týkající se doby používání nebo jejího ukončení, obraťte se kdykoli e-mailem na <a href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h3>
                                    5.2 Zablokování a vyloučení
                                </h3>
                                <p>
                                    5.2.1 Vyhrazujeme si právo kdykoli dočasně zablokovat uživatele z jakéhokoli podstatného důvodu (podle našeho uvážení) nebo ho trvale vyloučit z používání našich produktů. V případě ukončení smlouvy bez výpovědní lhůty je vrácení poplatků za užívání vyloučeno.
                                </p>
                                <p>
                                    5.2.2 Podstatným důvodem je zejména závažné porušení ustanovení těchto podmínek ze strany uživatele, např. porušení bodu 3.1 (Platba) po uplynutí dvoutýdenní odkladné lhůty, bodu 3.2 (Registrační údaje), bodu 5.3 (Licence) nebo bodu 6 (Vaše chování).
                                </p>
                                <h3>
                                    5.3 Licence a udělení práv
                                </h3>
                                <p>
                                    5.3.1 Registrací a aktivací získáváte nevýhradní, nepřenosné, ale geograficky neomezené právo uložit a používat kopii naší aplikace pro vlastní účely v rámci těchto podmínek. U zpoplatněných produktů je tato licence omezena na dobu trvání smluvního předplatného.
                                </p>
                                <p>
                                    5.3.2 Software, kód, metody a systémy a také obsah našich produktů jsou chráněny autorskými právy a právy hospodářské soutěže a smí je používat výhradně společnost CamDiab. Naše produkty ani jejich části nesmějí být kopírovány, upravovány, reprodukovány, znovu publikovány, zveřejňovány, přenášeny, prodávány, nabízeny k prodeji, dále prodávány ani jinak používány bez našeho předchozího písemného souhlasu. Uživatelům není dovoleno používat značky, loga, jiná obchodní majetková práva ani práva k ochranným známkám společnosti CamDiab. Pokud není v těchto podmínek stanoveno jinak, veškerá práva na používání a využívání jsou výhradně vlastnictvím společnosti CamDiab a na naše produkty není poskytována žádná licence.
                                </p>
                                <p>
                                    5.3.3 Pokud je to nutné k technickému poskytování našich produktů, každý uživatel uděluje společnosti CamDiab nevýhradní, odvolatelné, ale volně převoditelné právo na využívání a užívání obsahu, který uživatel vytváří, posílá, ukládá nebo zveřejňuje v rámci našich produktů, neomezené z hlediska času a místa. Použití nebo využití je však vyloučeno, pokud by tím byly nepřiměřeně dotčeny oprávněné zájmy uživatele (např. právo na soukromí). V případě použití mimo naše produkty bude případně uvedeno, že obsah pochází od uživatele. Společnost CamDiab si nečiní nárok na vlastnictví vytvořeného obsahu a nijak nedohlíží na obsah vytvářený uživateli.
                                </p>
                                <h3>
                                    5.4 Dostupnost produktů
                                </h3>
                                <p>
                                    5.4.1 Naše produkty poskytujeme vždy podle existujících technických, ekonomických, provozních a organizačních možností. Společnost CamDiab nemůže vyloučit přerušení, poruchy, zpoždění, vymazání, nesprávné přenosy nebo selhání úložiště v souvislosti s používáním našich produktů nebo s komunikací s uživateli. Naše produkty částečně nabízíme ve spolupráci s distributory a externími dodavateli, a proto jsme také závislí na technickém zajištění služeb třetích stran. Společnost CamDiab proto nenese žádnou odpovědnost, neposkytuje žádnou záruku ani se nezavazuje, že budou naše produkty dostupné online po celou dobu bez výpadků.
                                </p>
                                <p>
                                    5.4.2 To platí i pro omezení používání našich produktů z důvodu vyšší moci, stávek, výluk a úředních nařízení nebo z důvodu technických úprav či údržby systémů CamDiab. V případě poruch nebo selhání našich produktů se můžete kdykoli obrátit na <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h2>
                                    6. VAŠE CHOVÁNÍ
                                </h2>
                                <h3>
                                    6.1 Obecné informace
                                </h3>
                                <p>
                                    6.1.1 Uživatel je oprávněn a povinen používat naše produkty na vlastní nebezpečí a náklady při použití vhodného technického vybavení. O přehled zařízení a operačních systémů kompatibilních s produkty můžete kdykoli požádat e-mailem na <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <p>
                                    6.1.2 Jste také povinni používat naše produkty výhradně v souladu s těmito podmínkami a zákonnými ustanoveními a vyvarovat se jakéhokoli neoprávněného použití. Pokud zjistíte porušení povinností uvedených v tomto oddíle, neprodleně nás informujte e-mailem na <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h3>
                                    6.2 Zneužití
                                </h3>
                                <p>
                                    6.2.1 Uživatel je povinen uvádět své registrační údaje pravdivě, udržovat je aktuální a úplné a nepředávat je třetím osobám. S osobními údaji je třeba zacházet důvěrně, spravovat je pečlivě a zálohovat je na vlastní odpovědnost. Společnost CamDiab nenese odpovědnost a neposkytuje žádné záruky za případnou ztrátu nebo poškození dat nebo obsahu, pokud k němu nedojde její vinou.
                                </p>
                                <p>
                                    6.2.2 Obsah, jako jsou fotografie, obrázky, texty, videa nebo jiná vyobrazení, lze ukládat, zveřejňovat, přenášet nebo šířit v souvislosti s našimi produkty pouze tehdy, pokud má uživatel právo je přenášet nebo používat. V každém případě je vždy zakázáno používat rasistický, urážlivý, diskriminační, hanlivý, sexuální, pornografický, násilný nebo jiný nezákonný obsah všeho druhu.
                                </p>
                                <p>
                                    6.2.3 Dále je zakázáno dešifrovat, zpětně analyzovat, dekompilovat nebo rozebírat naše produkty nebo používat naše produkty v chytrých telefonech s upraveným operačním systémem (root). Uživatel je povinen nepodnikat žádné rušivé zásahy technickými nebo elektronickými prostředky do našich produktů (zejména pokusy o hackerské útoky, útoky hrubou silou, zavádění virů, červů, trojských koní a jiného škodlivého softwaru) ani žádné jiné pokusy o narušení, které by mohly ovlivnit software nebo hardware produktů a systémů společnosti CamDiab. Jakýkoli zásah do našich produktů může mít za následek, že naše produkty nebudou fungovat v souladu se zamýšleným použitím.
                                </p>
                                <h2>
                                    7. NAŠE POVINNOSTI
                                </h2>
                                <h3>
                                    7.1 Záruka a odpovědnost
                                </h3>
                                <p>
                                    7.1.1 Pokud není v těchto podmínkách stanoveno jinak, společnost CamDiab ručí a poskytuje záruky podle zákonných ustanovení.
                                </p>
                                <p>
                                    7.1.2 U uživatelů, kteří nejsou spotřebiteli, je vyloučena odpovědnost za finanční ztráty, následné škody, ušlý zisk a škody vyplývající z nároků třetích stran. Pokud je naše odpovědnost v těchto podmínkách omezena nebo vyloučena, platí to i pro zprostředkovatele a přidružené společnosti CamDiab.
                                </p>
                                <p>
                                    7.1.3 Nad rámec právních předpisů o odpovědnosti za výrobek je odpovědnost společnosti CamDiab s výjimkou újmy na zdraví omezena pouze na úmyslné jednání a hrubou nedbalost. Odpovědnost za běžnou nedbalost je vyloučena, pokud ji lze následně objektivně zdůvodnit.
                                </p>
                                <p>
                                    7.1.4 Společnost CamDiab neposkytuje žádnou záruku, že její produkty budou vždy dostupné bez výpadků a bez chyb nebo že požadovaný software a hardware bude fungovat bez chyb. Nemůžeme také vyloučit, že údaje mohou být sledovány, zaznamenávány nebo falšovány třetími stranami při přenosu dat prostřednictvím systémů třetích stran, zejména internetu a jiných telekomunikačních sítí.
                                </p>
                                <p>
                                    7.1.5 Společnost CamDiab neposkytuje žádnou záruku za stažený obsah nebo materiál, který uživatelé získají v důsledku používání našich produktů. Uživatel nese výhradní odpovědnost za veškeré škody, které by mohly vzniknout v jeho IT systému nebo zařízení, nebo za ztrátu dat v důsledku stahování materiálů souvisejících s našimi produkty.
                                </p>
                                <p>
                                    7.1.6 Společnost CamDiab neodpovídá ani za obsah třetích stran, např. externí odkazy, bannery, jiné informace nebo reklamní nabídky třetích stran, které mohou být umístěny nebo vloženy jako součást našich produktů. Pokud umožníme přístup k nabídkám třetích stran prostřednictvím oznámení nebo odkazů, společnost CamDiab neodpovídá za informace v nich obsažené. Výsledné právní úkony s třetími stranami vedou výhradně ke smluvním vztahům mezi uživatelem a příslušnou třetí stranou. Neposkytujeme žádné záruky a neneseme žádnou odpovědnost za služby třetích stran.
                                </p>
                                <h3>
                                    7.2 Zřeknutí se odpovědnosti a odškodnění
                                </h3>
                                <p>
                                    7.2.1 Používání našich produktů nenahrazuje konzultaci s lékařem ani jiné zdravotnické poradenství a je prováděno výhradně na vlastní riziko uživatele. To se vztahuje na jakékoli použití dat poskytnutých v rámci našich produktů uživatelem, včetně výsledků měření glykémie a měření hladiny glukózy ze senzoru, výpočtů a doporučení. Uživatel výslovně bere na vědomí, že tyto údaje mohou být chybné, a společnost CamDiab nenese žádnou odpovědnost za jejich správnost.
                                </p>
                                <p>
                                    7.2.2 V rozsahu požadovaném platnými právními předpisy o zdravotnických prostředcích, kterými se řídí používání produktů, mohou být produkty provozovány nebo používány výhradně v souladu s účelem, specifikacemi a oblastmi použití stanovenými v nabídce a v podmínkách používání.
                                </p>
                                <p>
                                    7.2.3 Uživatel odškodní společnost CamDiab za všechny nároky třetích stran, které jí vzniknou v důsledku úmyslného porušení jejích práv uživatelem v souvislosti s používáním našich produktů. Případné další nároky společnosti CamDiab na náhradu škody vůči uživateli tím zůstávají nedotčeny.
                                </p>
                                <p>
                                    7.2.4 Uživatel nese plnou odpovědnost za veškeré škody a soudní i mimosoudní spory vzniklé v důsledku sporů s jinými uživateli. Uživatel výslovně bere na vědomí, že společnost CamDiab v žádném případě nenese odpovědnost za jednání nebo opomenutí jiných uživatelů ani za případné škody z toho vyplývající.
                                </p>
                                <p>
                                    7.2.5 V případě uplatnění nároků vůči společnosti CamDiab třetími osobami je uživatel povinen neprodleně poskytnout veškeré informace, které má k dispozici, a to pravdivě a v plném rozsahu nezbytném k posouzení takového nároku, obhajobě proti němu a předcházení dalším nárokům třetích stran. Uživatel nese náklady na případnou právní obranu ze strany společnosti CamDiab, včetně všech soudních výloh a výdajů na právní zastoupení v zákonné výši.
                                </p>
                                <h2>
                                    8. ZÁVĚREČNÁ USTANOVENÍ
                                </h2>
                                <h3>
                                    8.1 Volba právního řádu
                                </h3>
                                <p>
                                    Tyto podmínky a celý právní vztah mezi společností CamDiab a uživatelem podléhá výhradně anglickému právu, s výjimkou jeho kolizních ustanovení a Úmluvy OSN o prodeji. Uživatel jako spotřebitel však požívá ochrany závazných ustanovení v zemi, kde se nachází nebo kde má obvyklé bydliště.
                                </p>
                                <h3>
                                    8.2 Místo soudní příslušnosti
                                </h3>
                                <p>
                                    Místem plnění a jediným místem soudní příslušnosti pro veškeré spory vyplývající z těchto podmínek je sídlo společnosti CamDiab v Cambridge ve Spojeném království. Povinné kolizní normy ve prospěch spotřebitelů tím zůstávají nedotčeny.
                                </p>
                                <h3>
                                    8.3 Různé
                                </h3>
                                <p>
                                    8.3.1 Pokud tyto podmínky vyžadují oznámení nebo písemnou formu (včetně právně důležitých prohlášení), postačí zaslání e-mailem na adresu uvedenou při registraci (CamDiab ID).
                                </p>
                                <p>
                                    8.3.2 Uživatelé nejsou oprávněni převádět své CamDiab ID nebo práva a povinnosti z těchto podmínek v souvislosti s používáním našich produktů na třetí osoby. Společnost CamDiab může převést svá práva a povinnosti z těchto podmínek na jinou společnost nebo třetí stranu, pokud takový převod nemá významný vliv na práva uživatele z těchto podmínek.
                                </p>
                                <p>
                                    8.3.3 Pokud jednotlivá ustanovení těchto podmínek jsou nebo se stanou neúčinnými, ostatní ustanovení těchto podmínek, která nejsou neplatností dotčena, zůstávají nadále platná a použitelná.
                                </p>
                                <p>
                                    8.3.4 Tyto podmínky jsou poskytovány v různých jazycích. Klíčová a rozhodující je verze v jazyce země, v níž se spotřebitel nachází nebo v níž má obvyklé bydliště. Pokud není k dispozici jazyková verze pro danou zemi, je rozhodující anglická verze.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer props={this.props}/>
            </div>
        );
    }
}

