/**
 * Texts for Reviews page
 */
export const reviewsPage = {
    title: 'Bewertungen - CamAPS FX',
    titleMeta: 'Lesen Sie Bewertungen von CamAPS FX-Benutzern',
    descriptionMeta: 'CamAPS FX ist für viele Benutzer lebensverändernd, bietet eine beispiellose ' +
        'Glukosekontrolle und reduziert die Belastung durch Diabetes für Benutzer und ihre Familien.',

    localeMeta: 'de_de',
    keywords:
        'camaps bewertungen, camaps fx bewertungen, ' +
        // 'closed loop, closed loop system, loop deutsch, eine autoimmunerkrankung kommt selten allein, glukose im blut, closed de, open loop closed loop, loop diabetes, was ist ein loop, hba1c zu niedrig, blutglukose, closed loop diabetes, dexcom app, insulin berechnen app, closed loop insulinpumpe, diabetes zimt therapie, glukose zu hoch, stopp diabetes, dexcom g6 closed loop, diabetes kohlenhydrate app, hybrid closed loop diabetes, loop dexcom g6, welche insulinpumpen gibt es, diabetes loop system, kohlenhydrate app android, kur diabetes typ 1, was heißt loop, mutter kind kur für diabetiker,  ' +
        '',
    shortReview1:
        '<h2>Wir wollten Sie nur wissen lassen, wie sehr sich das Leben unserer Tochter und unser Leben verbessert hat, seit sie das CamAPS FX Closed-Loop-System hat. </h2>' +
        '<p>' +
        '    <span>Eltern eines Teenagers</span>' +
        '</p>',

    shortReview2:
        '<p> ' +
        '   Man kann nicht überbewerten, wie sehr es unser Leben verändert hat. Wie Magie, fast alle diese Probleme (i.e. Erschöpfung aufgrund unterbrochenen Nachtschlafes, dauernde Überwachung am Tage) sind verschwunden. Es ist nicht übertrieben zu sagen, dass CamAPS FX uns unser Leben zurückgegeben hat. Ja, wir müssen noch immer Kohlenhydrate zählen; ja, wir geben den Bolus 10 Minuten vor dem Essen; und ja, wir müssen Pumpenkanülen wechseln; aber das ist es auch schon. CamAPS FX gibt uns die Freiheit, den Diabetes die meiste Zeit des Tages zu vergessen.' +
        '</p> ' +
        '    <p> ' +
        '        <span>David, Vater einer neunjährigen Tochter</span> ' +
        '    </p>' +
        '<p> </p> <p> </p>',
    shortReview3:
        '<h2>Danke. Letzte Nacht hatte ich den erholsamsten Schlaf seit Jahren.</h2>' +
        '<p>' +
        '    <span>Erwachsener Anwender über 60 Jahre</span>' +
        '</p>',

    shortReview4:
        '<p> ' +
        'Insgesamt haben wir den Effekt auf das Leben unseres Kindes feststellen können: Er machte signifikante Fortschritte beim Laufen und Sprechen. Es hat eine enorme Veränderung in den letzten Monaten gegeben. Mein Sohn war wesentlich selbstsicherer, weniger zornig und insgesamt glücklicher.' +
        '    </p> ' +
        '    <p> ' +
        '        <span>Mutter eines Kleinkindes</span> ' +
        '    </p>',
};

