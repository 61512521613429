import * as ReactGA from 'react-ga';
// import ReactGA from "react-ga4";

// See https://github.com/react-ga/react-ga/blob/master/demo/app/withTracker.jsx
export function withTracker(page) {
    if (localStorage.getItem('camdiab-cookie-denied')) {
        window['ga-disable-UA-149448704-1'] = true;
        window['ga-disable-G-WBG2BT66CG'] = true;
    }
    ReactGA.pageview(page);
    // ReactGA.send({
    //     hitType: "pageview", page: page,
    //     title: page
    // });
}
