/**
 * Texts for Support page
 */
export const supportPageText = {
    title: 'Support – CamAPS FX',
    titleMeta: 'Vi leverer bred support til brugen af CamAPS FX',
    descriptionMeta: 'Vi leverer støttematerialer, herunder brugervejledninger, retningslinjer til download, webinarer og telefon- og e-mailsupport til kunderne.',
    keywords:
        'camaps support, camaps fx support, ' ,
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'da_dk',

    needHelp: 'Har du brug for hjælp?',
    needHelpResponse: 'Vi håber, at supportmaterialerne her vil hjælpe dig med at få afklaret de problemer, du måtte have. Kontakt os, hvis du ikke kan finde svar på dit problem.',
    clinicalSupportHeader: 'Klinisk support',
    clinicalSupportText: 'Hvis du har spørgsmål til din behandling, bedes du kontakte dit diabetesbehandlerteam.',
    techSupportHeader: 'Teknisk og uddannelsesmæssig support',
    techSupportHeaderText: 'Her kan du finde supportmateriale og kontaktoplysninger, hvis du har brug for at komme i kontakt med vores supportteam.',
    techMaterialHeader: 'Tekniske materialer:',
    userManual: 'CamAPS FX brugervejledning',
    userManualEnglish_mmol_l: 'English',
    userManualCzech_mmol_l: 'Česky',
    userManualDanish_mmol_l: 'Dansk',
    userManualGerman_mg_dl: 'Deutsch',
    userManualGerman_mmol_l: 'Deutsch',
    userManualSpanish_mg_dl: 'Español',
    userManualFrench_mmol_l: 'Français',
    userManualFrench_mg_dl: 'Français',
    userManualItalian_mg_dl: 'Italiano',
    userManualItalian_mmol_l: 'Italiano',
    userManualDutch_mg_dl: 'Nederland',
    userManualDutch_mmol_l: 'Nederland',
    userManualNorsk_mmol_l: 'Norsk',
    userManualPolish_mg_dl: 'Polski',
    userManualFinish_mmol_l: 'Suomi',
    userManualSwedish_mmol_l: 'Svenska',

    previousManualVersions: 'Tidligere versioner',
    installationManualHeader: 'Installation af CamAPS FX (DANA)',
    installationManualEnglish: 'Installationsvejledning',
    installationManualGerman: 'Installationsanleitung',
    installationManualFrench: 'Guide d\'installation',
    educationalMaterial: 'Undervisningsmateriale:',
    onlineTraining: 'Onlineoplæring:',
    onlineTrainingText: 'Leveres af Cambridge Diabetes Education Programme (CDEP)',
    onlineTrainingLink: 'https://www.camdiabtraining.com/account-sign-in.html',
    onlineTrainingText_1: 'GRATIS certificeret onlineoplæring',
    webinarsHeading: 'Webinarer',
    webinar_1: 'Præsentation af CamAPS FX-systemet',
    webinar_2: 'Start CamAPS FX-systemet',
    webinar_3: 'Optimering af CamAPS FX-indstillingerne',
    webinar_4: 'Oplæring til lærere og pædagogisk personale',
    webinar_5: 'Finjustering for at forbedre tid inden for målområde',
    webinar_6: 'Anvendelse af CamAPS FX under graviditet',
    webinar_7: 'Anvendelse af CamAPS FX hos spædbørn, småbørn og mindre børn',
    webinar_8: 'Anvendelse af CamAPS FX hos unge voksne og voksne',
    webinar_9: 'Styring af fysisk aktivitet, måltider og andre livsbegivenheder',
    webinar_10: 'Insulinbehandling i lukket system og livskvalitet',
    webinar_11: 'Behandling i lukket system og langvarig type 1-diabetes',
    webinar_footnote: '',

    contactDetailsHeader: 'Kontaktoplysninger:',
    contactDetailsText: 'Vores supportteam kan kontaktes i arbejdstiden mandag-fredag 10-18.',
    contactDetailsEmailTitle: 'E-mail',
    contactDetailsEmail: 'support@camdiab.com',
    contactDetailsEmailFull: 'mailto:support@camdiab.com',
    contactDetailsPhoneTitle: 'Kontakt os',
    contactDetailsPhone: '+44 20 3695 3780 (kun engelsk)',
    contactDetailsPhoneFull: 'tel.:+442036953780',

    eIfuAccess: 'Trykte kopier af brugervejledningerne kan rekvireres fra kundesupport. Trykte kopier er gratis og vil ankomme om cirka 3 til 7 dage. For de bedste resultater skal du bruge Adobe® Reader® til at se CamDiab-brugervejledninger.',

    statusPageText: 'Status side',
    statusPageLink: 'https://camdiabltd.statuspage.io/',

    installationYpsoPumpHeader: 'Installation af CamAPS FX (YpsoPump)',
    installationYpsoPumpText: 'Google Play',
    installationYpsoPumpLink: 'https://play.google.com/store/search?q=camaps%20fx&c=apps',

    danaPumpSupportText: 'Dana pumpe brugere',
    danaPumpSupportTextUK: 'Dana pump users in the UK',
    danaPumpSupportLinkUK: 'https://advancedtherapeutics.org.uk/contact-us/',
    ypsoPumpSupportText: 'YpsoPump pumpe brugere',
    ypsoPumpSupportLink: 'https://www.mylife-diabetescare.com/da-DK/services/kundeservice-og-kontakt.html',
};
