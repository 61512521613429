/**
 * Texts for Reviews page
 */
export const reviewsPage = {
    title: 'Opinie — aplikacja CamAPS FX',
    titleMeta: 'Przeczytaj opinie użytkowników aplikacji CamAPS FX',
    descriptionMeta: 'Aplikacja CamAPS FX odmienia życie wielu użytkowników, zapewniając niespotykaną dotychczas kontrolę stężenia glukozy we krwi oraz ograniczając problemy użytkowników chorujących na cukrzycę i ich rodzin.',
    keywords:
        'camaps reviews, camaps fx reviews, ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'pl_pl',

    shortReview1:
        '<h2>Chcieliśmy podzielić się z wszystkimi tym, że życie naszej córki, a także nasze znacznie się polepszyło, odkąd zaczęła ona korzystać z systemu pętli zamkniętej CamAPS FX. </h2><p><span>Rodzice nastoletniej dziewczyny</span></p>',

    shortReview2:
        '<p> Stwierdzenie, że nasze życie uległo znacznej poprawie nie będzie przesadą. Aplikacja ma magiczne właściwości, dzięki którym niemalże wszystkie problemy nagle zniknęły, tj. zmęczenie spowodowane przerywaniem snu w nocy oraz ciągłe sprawdzanie, czy wszystko w porządku w ciągu dnia. Nie będzie przesadą, jeśli powiemy, że aplikacja CamAPS FX pozwoliła nam odzyskać swoje życie. Nadal musimy liczyć węglowodany, podawać bolus 10 minut przed posiłkami i zmieniać kaniulę pompy, ale to tylko tyle. Aplikacja CamAPS FX dała nam wolność pozwalającą zapomnieć o cukrzycy przez większość dnia.</p> <p> <span>David, ojciec 9-letniej dziewczynki</span> </p>',
    shortReview3:
        '<h2>Dziękuję. Ostatniej nocy wyspałem się najlepiej od wielu lat.</h2><p><span>Użytkownik w wieku powyżej 60 lat</span></p>',

    shortReview4:
        '<p> Zauważyliśmy wpływ na życie naszego dziecka: nastąpił znaczny postęp w zakresie rozwijania swoich umiejętności chodzenia i mówienia. W porównaniu do ostatnich kilku miesięcy różnica jest ogromna. Mój syn jest znacznie bardziej pewny siebie, mniej rozgniewany i ogólnie szczęśliwszy.</p> <p> <span>Mama niemowlaka</span> </p>',


};
