/**
 * Common texts - what is CamAPS FX
 */
export const whatIsCamAPSFX = {

    linkFindOutMore: 'Lue lisää',
    body:
        '<h2>Mikä on CamAPS FX?</h2> <p> CamAPS FX on Android-käyttöjärjestelmiin tarkoitettu sovellus, jonka avulla tyypin 1 diabetesta sairastavat diabeetikot voivat hallita glukoositasojaan. Sovelluksessa käytetään pitkälle kehitettyä mukautuvaa suljettua hybridijärjestelmää. </p>'
};

/**
 * Common texts - what is CamAPS FX
 */
export const userReviewsIntro = {
    linkMoreReviews: 'Lisää raportteja',
    readMore: 'Lue lisää',
    header:
        'CamAPS FX -käyttäjäraportteja',
    text:
        'Tositarinoita todellisilta CamAPS FX -sovelluksen käyttäjiltä'
};