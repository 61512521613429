import React, {Component} from 'react';
import {Header} from '../Components/Header';
import {Footer} from '../Components/Footer';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

export class TermsFI extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Käyttöehdot - CamAPS FX</title>
                    <meta name="title" content='Käyttöehdot'/>
                    <meta name="description"
                          content='Täältä löydät CamDiab-ehdot'/>
                    <meta property="og:locale" content='fi_fi'/>
                    <meta name="keywords" content='Käyttöehdot'/>
                </Helmet>
                <Header props={this.props}/>
                <section className="inner-banner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-10">
                                <h1>Käyttöehdot</h1>
                                <h3>Versio päivätty 24. kesäkuuta 2024</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12">

                                <h2>
                                    1. SOVELTAMISALA
                                </h2>
                                <h3>
                                    1.1. Tuotteemme
                                </h3>
                                <p>
                                    1.1.1. Me
                                </p>
                                <p>
                                    CamDiab Ltd <strong>
                                    <br/>
                                    Rekisteröity toimiston osoite<br/>
                                    20-22 Wenlock Road<br/>
                                    London, N1 7GU, UK
                                    <br/>
                                </strong>
                                    support@camdiab.com
                                    <br/>
                                    yritystunnus 11659211 Englanti ja Wales
                                    <br/>
                                    <br/>
                                </p>
                                <p>
                                    (“<strong>CamDiab</strong>”), kehitämme ja teemme mobiilisovelluksia (sovelluksia) insuliinin annosteluun suljetussa järjestelmässä ja linkitettyyn datainfrastruktuuriin diabeetikoille ja terveydenhuollon ammattilaisille. CamDiab on digitaalinen terveysalan yritys joka, haluaa tarjota diabeetikoille parempaa glukoosin hallintaa ja helpottaa diabeteksen itsehoidon taakkaa.
                                </p>
                                <p>
                                    1.1.2 Tuotteemme opastavat ja tukevat insuliinin annostelua suljetussa järjestelmässä parhaan tietämykseme mukaan. Tuotteemme ja palvelumme eivät voi korvata terveydenhuoltoalan ammattilaisen konsultaatiota ja diagnoosia. Jos sinulla on kysyttävää sairaudesta tai hoidosta, suosittelemme, että käännyt terveydenhuoltoalan ammattilaisen puoleen. Annettuja tietoja saa analysoida sinun lisäksesi ainoastaan terveydenhuoltoalan ammattilainen. Mitään sisällöstä – oli se sitten CamDiabin, yhteistyökumppaneiden, jakelijoiden, toimittajien tai käyttäjien tarjoamaa – ei pidä pitää lisätietona tai korvaavina tietoina terveydenhuoltoalan ammattilaiselta tai farmaseutilta saatavien tietojen suhteen.
                                </p>
                                <p>
                                    1.1.3 CamDiab voi tarjota useita tuotteita (sovelluksia) ja palveluja sekä mahdollistaa tietojen jakamisen diabeteksen hallinnan tukemiseksi. Kaikkiin nykyisiin ja tuleviin sovelluksiin ja palveluihin (myös uudet versiot, päivitykset ja muut parannukset), joita CamDiab tarjoaa, viitataan yhteisesti “<strong>tuotteina</strong>” tai yksittäin “<strong>tuotteena</strong>”. Kaikkiin käyttäjiin, jotka hankkivat tai käyttävät yhtä tai useampaa tuotteistamme, viitataan yhteisesti “<strong>käyttäjinä</strong>” tai yksittäin “<strong>käyttäjänä</strong>”. Jos nämä käyttöehdot viittaavat luonnollisiin henkilöihin maskuliinimuodossa, ne koskevat kuitenkin yhtäläisesti kaikkia ihmisiä.
                                </p>
                                <p>
                                    1.1.4 Näissä käyttöehdoissa seuraavat termimääritykset koskevat yksittäisiä tuotteita:
                                </p>
                                <p>
                                    <strong>CamAPS FX </strong> on itsenäinen mobiilisovellus (sovellus) älypuhelimeen ja tarjoaa suljetun insuliinin annostelun hybridijärjestelmän tyypin 1 diabetesta sairastaville henkilöille. Sovellus muodostaa yhteyden kolmannen osapuolen glukoosisensoriin ja kolmannen osapuolen insuliinipumppuun. Voit päättää jakaa sovelluksen keräämät tiedot CamDiabin tai kolmannen osapuolen diabetesportaalien kanssa tietojen tarkastelua varten. Kolmannen osapuolen insuliinipumpun ja glukoosisensorin perusteella käytettäviin Android-älypuhelinmalleihin voi olla rajoituksia.
                                </p>
                                <p>
                                    <strong>CamAPS HX</strong> on samanlainen kuin CamAPS FX, mutta tarjoaa täysin suljetun kierron muun kuin tyypin 1 diabeteksessa.
                                </p>
                                <p>
                                    1.1.5 Käyttöohjeemme (saatavilla sovelluksiemme tai verkkosivustomme kautta) ja asiaankuuluvat erityissäännökset korostavat yksittäisten tuotteiden erityispiirteitä.
                                </p>
                                <h3>
                                    1.2. Käyttöehtojen soveltamisala
                                </h3>
                                <p>
                                    1.2.1 Tarjoamme tuotteitamme yksinomaan nykyisten yleisten käyttöehtojen (käyttöehdot) pohjalta, mikä pätee globaalisti. CamDiab vastustaa nimenomaisesti ristiriitaisia liiketoiminta- tai muita käyttöehtoja.
                                </p>
                                <p>
                                    1.2.2 Otathan huomioon, että ennen kuin käytät tuotteitamme ensimmäisen kerran, sinun tulee hyväksyä kaikki viitatut ilmoitukset ja sopimukset sekä hyväksyä ne myös seuraavien käyttökertojen aikana. Tuotteidemme rekisteröinti ja käyttö on kiellettyä ilman käyttöehtojemme hyväksymistä.
                                </p>
                                <p>
                                    1.2.3 Varaamme oikeuden tehdä tulevia muutoksia ja tosiasiallisesti perusteltuja muokkauksia käyttöehtoihimme. Muutokset voivat olla tarpeen lakisääteisten vaatimusten, teknisten ja taloudellisten vaatimusten tai käyttäjien etujen täyttämiseksi. Tällaiset muutokset ovat mahdollisia milloin tahansa, ja ne julkaistaan ​​asianmukaisella tavalla. Jos niitä ei vastusteta kuukauden kuluessa, tuotteidemme jatkuvaan käyttöön sovelletaan asiaankuuluvia uusia käyttöehtoja.
                                </p>
                                <h3>
                                    1.3. Kolmannen osapuolen toimittajat
                                </h3>
                                <p>
                                    1.3.1 Saatat löytää tuotteitamme sovelluskaupasta tai sovelluskaupoista. Yleisesti kyseessä olevan kolmannen osapuolen toimittajan liiketoimintaehdot pätevät niissä (meillä ei ole niihin vaikutusvaltaa). Ne eivät kuulu käyttöehtoihimme tai sopimussuhteemme puitteisiin, jossa olet tuotteidemme käyttäjä. Tämä pätee myös mobiililaitteesi toimittajan tai palveluntarjoajan liiketoimintasääntöihin ja ehtoihin.
                                </p>
                                <p>
                                    1.3.2 Työskentelemme useiden eri kumppanien kanssa varmistaaksemme tuotteidemme optimaalisen käytön. Tämä sisältää yhteistyön niiden kumppanien kanssa, jotka toimittavat käyttäjillemme suoraan tiettyjä tuotteita (esim. insuliinipumppu, jatkuva glukoosimonitori). Näissä tapauksissa korostamme kolmannen osapuolen toimittajan soveltuvia liiketoimintaehtoja, sillä sinun on hyväksyttävä ne, ennen kuin voit käyttää tuotteitamme.
                                </p>
                                <h2>
                                    2. TIETOSUOJA
                                </h2>
                                <p>
                                    <strong> Tiedostamme suuren vastuun siitä, että käytät tuotteitamme. Toimitat meille henkilötietojesi lisäksi tietoja terveydestäsi. Kerromme </strong>
                                    <Link to="/fi/privacy">
                                        tietosuojaselosteessamme</Link> <strong> sinulle tietojesi käytöstä ja suojaamisesta tuotteidemme käytön yhteydessä sekä tähän tarvittavasta suostumuksestasi.</strong>
                                </p>
                                <p>
                                    <strong> TUOTTEIDEN KÄYTTÖ EI OLE MAHDOLLISTA LAILLISESTI EIKÄ MUUTOIN ILMAN TIETOSUOJAA KOSKEVAA SUOSTUMUSTA TARVITTAVAAN TIETOJEN KÄSITTELYYN. </strong>
                                </p>
                                <p>
                                    <strong>Meidän </strong>
                                    <Link to="/fi/privacy"> tietosuojamme</Link> <strong> ei ole osa näitä käyttöehtoja, vaan sitä käytetään antamaan sinulle yleisen tietosuoja-asetuksen mukaisia tietoja, joiden perusteella annat suostumuksen tarvittavaan tietojenkäsittelyyn. </strong>
                                </p>
                                <h2>
                                    3. SOPIMUKSEN TEKO JA REKISTERÖITYMINEN
                                </h2>
                                <h3>
                                    3.1. Tuotteidemme osto ja maksaminen
                                </h3>
                                <p>
                                    3.1.1 Tuotteitamme voi ostaa useilla tavoilla:
                                </p>
                                <h3>
                                    Sovellukset
                                </h3>
                                <p>
                                    <strong>Sovelluskaupat</strong> (esimerkiksi Google Play Store) tarjoavat tuotteitamme. Otathan huomioon, että ladataksesi (ei koske käyttöä) tuotteitamme sovelluskaupan erilliset käyttöehdot pätevät ja voivat vaatia käyttäjätilin.
                                </p>
                                <p>
                                    <strong>Verkkosivustomme</strong> saattaa tarjota suoran pääsyn tuotteisiimme ja mahdollistaa tuotteidemme tilaamisen.
                                </p>
                                <p>
                                    <strong>Kuponkikoodeja</strong> voi olla saatavilla tuotteisiimme, ja ne voi lunastaa verkkosivuillamme.
                                </p>
                                <p>
                                    3.1.2 Voit tilata maksullisia tuotteita verkkosivustollamme tai jälleenmyyjiemme kautta. Ne maksetaan maksupalveluntarjoajan, jakelijoidemme tai muiden esitettyjen maksuvaihtoehtojen kautta. Jakelija toimii joko edustajana ja maksupalvelujentarjoajana CamDiabille tai suoramyyjänä. Jos sinulla on ongelmia lataamisen tai maksamisen kanssa, ota tarvittaessa yhteyttä: <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <p>
                                    3.1.3 Käyttömaksut tai ostohinta (sisältäen lainmukaisen arvonlisäveron) on maksettava – tarjouksesta ja valikoimasta riippuen – kerran tai toistuvasti (esim. kuukausittain, puolivuosittain tai vuosittain). Nykyiset maksut kerrotaan verkkosivuillamme, jakelijamme toimesta tai näytetään sovelluksessamme tai sovelluskaupoissa, ja ne on maksettava etukäteen. Jos maksua ei käsitellä suoraan sovelluskaupassa, maksuntarjoaja tai jakelija voivat lähettää sinulle laskun ja muistutuksia sähköpostitse. Jos maksu on tahallisesti väärä, myöhässä tai epätäydellinen, meillä on oikeus estää pääsysi tuotteisiimme. Tämä ei vaikuta käyttömaksuvelvoitteeseesi.
                                </p>
                                <p>
                                    3.1.4 CamDiab voi tehdä erikois- ja ilmaistarjouksia, sovelletaan lisäehtoja tai -rajoituksia. Tässä suhteessa viittaamme nimenomaisesti mahdollisiin poikkeamiin näistä käyttöehdoista, mutta muutoin näiden käyttöehtojen koko sisältö pysyy voimassa. Erikois- tai ilmaistarjouksia ei voi siirtää muille käyttäjille.
                                </p>
                                <h3>
                                    3.2. Rekisteröityminen ja käyttöönotto
                                </h3>
                                <p>
                                    3.2.1 Tuotteidemme käyttö vaatii, että rekisteröidyt CamDiabiin sovelluksissamme. Otettuasi tuotteemme ensimmäistä kertaa käyttöön, sinun on rekisteröidyttävä käyttämällä sähköpostiosoitetta ja salasanaa (CamDiab-tunnus). Jokainen käyttäjä voi luoda vain yhden CamDiab-tunnuksen, ja jokaista CamDiab-tunnusta voi käyttää vain yksi käyttäjä.
                                </p>
                                <p>
                                    3.2.2 Rekisteröitymällä käyttäjä hyväksyy sitovan tarjouksen tehdä sopimus CamDiabin kanssa tuotteidemme käyttöä koskien perustuen näiden käyttöehtojen soveltuvaan versioon. Hyväksymme sellaisen tarjouksen viimeistään aktivoimalla CamDiab-tunnuksesi.
                                </p>
                                <p>
                                    3.2.3 Aktivointi vahvistetaan, kun kirjautumistietosi hyväksytään ja olet käynyt läpi tarvittavan koulutuksen. Varaamme oikeuden hylätä yksittäisiä käyttäjiä tai heidän tarjouksensa sopimuksesta ilman syyn ilmoittamista. Näissä tapauksissa jo suoritetut maksut palautetaan ja käyttötiedot poistetaan heti.
                                </p>
                                <p>
                                    3.2.4 Kun käyttäjä rekisteröityy, hän vahvistaa, että on vähintään kuusitoistavuotias ja että soveltuvat lait sallivat hänen solmia sopimuksia. Vain henkilöt, joilla on laillinen oikeus siihen, voivat rekisteröityä. Alaikäiset voivat rekisteröityä ainoastaan vanhemman tai huoltajan yksinomaisella suostumuksella. Rekisteröitymisen yhteydessä vanhempi/huoltaja ilmoittaa, että heillä on oikeus tehdä laillisesti sitovia ilmoituksia alaikäisen nimissä.
                                </p>
                                <p>
                                    3.2.5 Rekisteröityessään käyttäjä vahvistaa hyväksyntänsä ja että hyväksyy nämä käyttöehdot rajoituksetta ja heidän tietonsa ovat todenmukaiset, ajantasaiset, oikein ja täydelliset. Varaamme oikeuden ottaa yhteyttä käyttäjiin milloin tahansa vahvistaaksemme rekisteröinti- ja käyttötiedot.
                                </p>
                                <p>
                                    3.2.6 Jos käyttäjä antaa vääriä, virheellisiä, vanhoja tai epätäydellisiä tietoja tai meillä on syytä olettaa, että tiedot ovat vääriä, virheellisiä, vanhoja tai epätäydellisiä, CamDiabilla on oikeus estää kyseinen CamDiab-tunnus heti ilman etukäteisilmoitusta ja estää sen tuotteiden käyttö ilman korvausvelvollisuutta mahdollisista siitä koituneista kuluista.
                                </p>
                                <p>
                                    3.2.7 Käyttäjän on suojattava rekisteröintitietonsa valtuuttamattomalta kolmansien osapuolten käytöltä, väärinkäytöltä tai petolliselta käytöltä. Jos on olemassa edes epäilys siitä, että CamDiab-tunnus on altistunut tällaiselle riskille, siitä tulee ilmoittaa viipymättä osoitteeseen <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>. Meillä on oikeus estää käyttäjän CamDiab-tunnus, jos sitä käytetään luvattomiin tai petollisiin toimiin.
                                </p>
                                <h2>
                                    4. PERUUTTAMISOIKEUDET
                                </h2>
                                <h3>
                                    4.1. Tietoja peruutusoikeuden käyttämisestä
                                </h3>
                                <p>
                                    4.1.1 Jos et käytä tuotteitamme kaupallisiin tai ammatillisiin tarkoituksiin, sinulla on kuluttajana oikeus peruuttaa CamDiabin kanssa tehty sopimus seuraavien ehtojen mukaisesti:
                                </p>
                                <p>
                                    <strong>PERUUTUSOIKEUS</strong>
                                </p>
                                <p>
                                    <strong><u>Peruutusoikeus</u></strong>
                                </p>
                                <p>
                                    <strong> Sinulla on oikeus peruuttaa tämä sopimus neljäntoista päivän sisällä syytä ilmoittamatta.</strong>
                                </p>
                                <p>
                                    <strong> Peruutusaika on neljätoista päivää siitä, kun sopimus tehtiin (digitaalisen sisällön aktivointi). </strong>
                                </p>
                                <p>
                                    <strong> Jos haluat käyttää peruutusoikeuttasi, sinun on kerrottava peruutuspäätöksestä meille selkeällä ilmoituksella joko jakelijalle tai CamDiabille sähköpostitse </strong> <strong> <a href="mailto:support@camdiab.com">support@camdiab.com</a> </strong> <strong> (esim. sähköpostitse tai postitse). </strong>
                                </p>
                                <p>
                                    <strong> Peruutusoikeusajan säilyttämiseksi riittää, että lähetät ilmoituksen peruutusoikeuden käyttämisestä ennen peruutusajan päättymistä. </strong>
                                </p>
                                <p>
                                    <strong><u>Sopimuksen peruuttamisen seuraukset</u></strong>
                                </p>
                                <p>
                                    <strong> Jos peruutat tämän sopimuksen, jakelijan tai meidän on maksettava takaisin kaikki maksut, joita olemme saaneet sinulta ilman viivästystä ja viimeistään neljäntoista päivän sisällä siitä, kun saimme peruutusilmoituksen koskien tätä sopimusta. Käytämme mahdollisuuksien mukaan samaa maksutapaa palautukseen kuin mitä käytit alkuperäisen suorituksen tekemiseen, ellemme ole sopineet jotain muuta. Takaisinmaksusta ei missään olosuhteissa aiheudu kuluja.</strong>
                                </p>
                                <h2>
                                    5. TUOTTEIDEMME KÄYTTÖ
                                </h2>
                                <h3>
                                    5.1. Käytön kesto ja irtisanominen
                                </h3>
                                <p>
                                    5.1.1 Oikeutesi käyttää tuotteitamme alkaa, kun CamDiab suorittaa rekisteröimisen ja aktivoinnin.
                                </p>
                                <p>
                                    5.1.2 Maksulliset tuotteemme voi ostaa vain kuukausitilauksilla tai 6-kuukautis- tai vuositilauksilla, ja riippuen senhetkisestä tarjonnasta ne ovat saatavilla myös lyhyempinä tai pidempinä käyttöjaksoina (tästä eteenpäin “ <strong>tilausjakso</strong>”).
                                </p>
                                <p>
                                    5.1.3 Sekä käyttäjällä että CamDiabilla on oikeus irtisanoa sopimus tavallisesti milloin tahansa kuukauden irtisanomisajalla, ja se tulee voimaan kuukauden lopussa. Voit tehdä sen ottamalla yhteyttä jakelijaasi tai lähettämättä kirjallisen tiedonannon osoitteeseen <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>. Otathan huomioon, että jo maksetut maksut käyttämättömästä tilausjaksosta hyvitetään vain, jos irtisanominen tapahtuu CamDiabin toimesta tai automaattisena lopetuksena (osio 5.1.5 alla).
                                </p>
                                <p>
                                    5.1.4 Jos irtisanomista ei ole tai irtisanominen on myöhässä, tilausjakso jatkuu automaattisesti toisella kaudella, joka vastaa aiemmin valittua tilausjaksoa. Sovelletaan voimassa olevaa vakiohintaa jatkamishetkellä (eivät siis erikois- tai ilmaistarjoushinnat).
                                </p>
                                <p>
                                    5.1.5 Käyttäjän menehtymisen tai tietosuojasuostumuksen peruuntuessa käyttösuhde päättyy automaattisesti välittömästi. CamDiab ei saa käsitellä terveystietojasi ilman suostumustasi.
                                </p>
                                <p>
                                    5.1.6 Jos sinulla on kysyttävää käyttöjaksosta tai sen peruuntumisesta, ota tarvittaessa yhteyttä: <a href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h3>
                                    5.2. Väliaikainen ja pysyvä estäminen
                                </h3>
                                <p>
                                    5.2.1 Varaamme oikeuden estää käyttäjien pääsyn väliaikaisesti mistä tahansa tärkeästä syystä (harkintamme mukaan) tai sulkea heidät pysyvästi käyttämästä tuotteitamme. Jos sopimus päättyy näin ilman ilmoitusjaksoa, käyttömaksuja ei palauteta.
                                </p>
                                <p>
                                    5.2.2 Tärkeä syy on erityisesti sellainen, että käyttäjä on rikkonut vakavasti näiden käyttöehtojen määräyksiä, kuten rikkomukset koskien kohtaa 3.1 (Maksu) kahden maksuvapaan viikon asettamisen jälkeen, kohtaa 3.2 (Rekisteröintitiedot), kohtaa 5.3 (Lisenssi) tai kohtaa 6 (Toimintasi).
                                </p>
                                <h3>
                                    5.3. Lisenssi ja oikeuksien myöntäminen
                                </h3>
                                <p>
                                    5.3.1 Kun rekisteröintisi ja aktivointisi on tehty, saat ei-yksinomaisen, ei-siirrettävän, mutta maantieteellisesti rajoittamattoman oikeuden tallentaa ja käyttää sovelluksemme kopiota omiin tarkoituksiin näiden käyttöehtojen puitteissa. Maksullisia tuotteita koskien tämä lisenssi on rajoitettu sopimuksesi tilausjakson kestoon.
                                </p>
                                <p>
                                    5.3.2 Ohjelmisto, koodi, menetelmät ja järjestelmät sekä tuotteidemme sisältö ovat tekijänoikeus- ja kilpailulailla suojatut, ja CamDiabilla on niihin yksinomainen käyttöoikeus. Tuotteitamme tai niiden osia ei saa kopioida, muokata, toisintaa, julkaista uudelleen, siirtää, myydä, tarjota myyntiin, myydä uudelleen tai käyttää millään tavalla ilman kirjallista suostumustamme. Käyttäjät eivät saa käyttää CamDiabin tuotemerkkejä, logoja, muita kaupallisia omistusoikeuksia eikä tavaramerkkioikeuksia. Ellei näissä käyttöehdoissa toisin määrätä, kaikki käyttö- ja hyödyntämisoikeudet ovat yksinomaan CamDiabin omistuksessa, eikä tuotteillemme ole minkäänlaista lisenssiä.
                                </p>
                                <p>
                                    5.3.3 Jos se on tarpeen tuotteidemme teknisessä tarjonnassa, jokainen käyttäjä myöntää sisältöön, jota he luovat, siirtävät, tallentavat tai julkaisevat tuotteissamme, CamDiabille ei-yksinomaisen, peruutettavissa olevan, mutta ilmaisen, siirrettävän hyödyntämis- ja käyttöoikeuden, joka on ajallisesti ja sijainnin kannalta rajoittamaton. Käyttäminen tai hyödyntäminen suljetaan pois, jos se aiheuttaa suhteettomia haittoja käyttäjän laillisia intressejä koskien (esim. tietosuojaoikeudet). Jos sisältöä käytetään tuotteidemme ulkopuolella, ilmoitamme tarvittaessa, että sisältö tulee käyttäjältä. CamDiab ei väitä omistavansa luotua sisältöä eikä ota valvovaa roolia käyttäjien luoman sisällön suhteen.
                                </p>
                                <h3>
                                    5.4. Tuotteidemme saatavuus
                                </h3>
                                <p>
                                    5.4.1 Toimitamme tuotteemme kussakin tapauksessa olemassa olevien teknisten, taloudellisten, toiminnallisten ja organisatoristen mahdollisuuksien mukaisesti. CamDiab ei voi sulkea pois keskeytyksiä, häiriöitä, viiveitä, poistoja, virheellisiä lähetyksiä tai säilytysongelmia tuotteitamme käytettäessä tai viestinnässä käyttäjien kanssa. Tarjoamme tuotteemme yhteistyössä kolmansien osapuolten jakelijoiden ja toimittajien kanssa ja olemme siksi riippuvaisia kolmansien osapuolten palvelujen teknisestä tarjonnasta. Siksi CamDiab ei hyväksy vastuuta, takuita, sitoumusta tai velvollisuutta tarjota tuotteitamme verkossa aina ilman keskeytyksiä.
                                </p>
                                <p>
                                    5.4.2 Tämä koskee myös tuotteidemme käyttöön liittyviä rajoituksia, jotka riippuvat ylivoimaisista esteistä, lakoista, sähkökatkoista ja virallisista ohjeista tai teknisistä muutoksista tai CamDiab-järjestelmien huoltotöistä. Jos tuotteissamme on häiriöitä tai ongelmia, ota tarvittaessa yhteyttä meihin: <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h2>
                                    6. TOIMINTASI
                                </h2>
                                <h3>
                                    6.1. Yleistä tietoa
                                </h3>
                                <p>
                                    6.1.1 Jokaisella käyttäjällä on oikeus ja velvollisuus käyttää tuotteitamme omalla vastuullaan ja kustannuksellaan käyttäen samalla soveliaita teknisiä laitteita. Tietoja siitä, mitkä laitteet ja käyttöjärjestelmät ovat yhteensopivia tuotteidemme kanssa, voi pyytää koska tahansa osoitteella <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <p>
                                    6.1.2 Sinulla on velvollisuus käyttää tuotteitamme ainoastaan näiden käyttöehtojen ja lakisääteisten määräysten mukaisesti ja välttäen väärinkäyttöä. Jos huomaat tässä osiossa mainittuja velvollisuuksia koskevia rikkomuksia, ilmoita siitä heti osoitteesta <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h3>
                                    6.2. Väärinkäyttö
                                </h3>
                                <p>
                                    6.2.1 Kullakin käyttäjällä on velvollisuus ilmoittaa rekisteröintitietonsa totuudenmukaisesti, pitää ne ajantasalla ja täydellisinä sekä olemaan luovuttamatta niitä kolmansille osapuolille. Henkilökohtaisia tietoja on käsiteltävä luottamuksellisesti, hallinnoitava varoen ja varmuuskopioitava yksilön omalla vastuulla. CamDiab ei hyväksy vastuita tai muita takeita koskien kadonneita tai vahingoittuneita tietoja tai sisältöä, jos tämä ei ole meidän syytämme.
                                </p>
                                <p>
                                    6.2.2 Sisällöt, kuten valokuvat, kuvat, tekstit, videot tai muut kuvaukset, voidaan säilyttää, julkaista, siirtää tai jakaa vain yhteydessä tuotteisiimme, jos käyttäjällä on oikeus siirtää tai käyttää niitä. Kaikissa tapauksissa kaikenlaisen rasistisen, loukkaavan, syrjivän, kunniaa loukkaavan, seksuaalisen, väkivaltaisen tai muutoin laittoman sisällön käyttäminen on aina kielletty.
                                </p>
                                <p>
                                    6.2.3 Lisäksi on kielletty poistamasta salausta, takaisinmallintamasta, purkamasta tai kääntämästä tuotteitamme tai käyttää tuotteitamme rootatuilla laitteilla. Jokainen käyttäjä on velvollinen olemaan tekemättä tuotteisiimme teknisin tai elektronisin keinoin häiriöitä (erityisesti hakkerointiyritykset, väsytyshyökkäykset, virukset, madot, troijalaiset, muut haittaohjelmien käyttöönotot) tai minkäänlaisia häiriöyrityksiä, jotka voivat vaikuttaa CamDiabin tuotteiden ja järjestelmien ohjelmistoon tai laitteistoon. Tuotteidemme peukalointi saattaa johtaa siihen, etteivät ne toimi aiotun käytön mukaisesti.
                                </p>
                                <h2>
                                    7. VELVOLLISUUTEMME
                                </h2>
                                <h3>
                                    7.1. Takuu ja vastuu
                                </h3>
                                <p>
                                    7.1.1 Ellei näissä käyttöehdoissa toisin mainita, CamDiab on vastuussa ja tarjoaa takuun lain määräysten mukaisesti.
                                </p>
                                <p>
                                    7.1.2 Jos käyttäjä ei ole kuluttaja, rajataan pois vastuut taloudellisista menetyksistä, välillisistä vahingoista, menetetyistä tuotoista ja vahingoista, jotka johtuvat kolmansien osapuolten vaatimuksista. Jos vastuumme on rajoitettu tai rajattu pois näissä käyttöehdoissa, se koskee myös CamDiabin välillisiä edustajia ja kumppaneita.
                                </p>
                                <p>
                                    7.1.3 Tuotevastuulainsäädännön soveltamisalan ulkopuolella CamDiabin vastuu rajoittuu tahallisiin tekoihin ja törkeään huolimattomuuteen, lukuun ottamatta henkilövahinkoja. Vastuu vähäisestä huolimattomuudesta suljetaan pois, kun siihen liittyy objektiivisia perusteluja.
                                </p>
                                <p>
                                    7.1.4 CamDiab ei anna mitään takuita siitä, että tuotteemme ovat aina täysin saatavilla ilman keskeytyksiä tai virheitä tai että tarvittavat ohjelmistot ja laitteistot toimivat ilman virheitä. Emme voi sulkea pois sitä mahdollisuutta, että tietoja voidaan seurata, tallentaa tai väärentää kolmansien osapuolten toimesta, kun tietoja siirretään kolmansien osapuolten järjestelmissä, etenkin internetissä ja muissa televiestintäverkoissa.
                                </p>
                                <p>
                                    7.1.5 CamDiab ei hyväksy takeita ladatusta sisällöstä tai materiaalista, jonka käyttäjät ovat saaneet tuotteidemme käytön seurauksena. Käyttäjä on yksin vastuussa kaikista vahingoista, jotka voivat syntyä heidän IT-järjestelmissään, tai tietojen menetyksestä tuotteisiimme liittyvien materiaalien lataamisen takia.
                                </p>
                                <p>
                                    7.1.6 CamDiab ei ole myöskään vastuussa kolmansien osapuolten sisällöstä, kuten sellaisista ulkoisista linkeistä, mainospalkeista, muista tiedoista tai tarjouksista kolmansilta osapuolilta, jotka voidaan sijoittaa osaksi tuotteitamme. Jos sallimme pääsyn kolmansien osapuolten tarjouksiin ilmoitusten tai linkkien kautta, CamDiab ei ole vastuussa niiden sisältämistä tiedoista. Tästä johtuvat oikeudelliset toimet kolmansien osapuolten kanssa johtavat yksinomaan käyttäjän ja asianomaisen kolmannen osapuolen välisiin sopimussuhteisiin. Emme hyväksy takeita tai muita vastuita kolmansien osapuolten palveluista.
                                </p>
                                <h3>
                                    7.2. Vastuuvapauslauseke ja vahingonkorvaus
                                </h3>
                                <p>
                                    7.2.1 Tuotteidemme käyttäminen ei korvaa terveydenhuoltoalan ammattilaisen konsultaatiota tai muita lääketieteellisiä neuvoja, ja käyttö tapahtuu käyttäjän omalla vastuulla. Tämä koskee kaikkea käyttäjän sellaisen tiedon käyttöä, joka annetaan osana tuotteita, mukaan lukien veri- ja sensoriglukoositulokset, laskelmat ja kaikki suositukset. Käyttäjä myöntää nimenomaisesti, että tällaiset tiedot voivat olla viallisia, eikä CamDiab ota vastuuta niiden oikeellisuudesta.
                                </p>
                                <p>
                                    7.2.2 Tuotteiden käyttöä koskevan sovellettavan lääkinnällisten laitteiden lain edellyttämässä laajuudessa tuotteita saa käyttää vain tarjous- ja käyttöehtojen mukaisten käyttötarkoitusten, eritelmien ja sovellusalueiden mukaisesti.
                                </p>
                                <p>
                                    7.2.3 Käyttäjä vapauttaa CamDiabin vastuusta koskien kaikkia kolmannen osapuolen vaatimuksia, jotka nostetaan CamDiabia vastaan sen vuoksi, että käyttäjä on loukannut sen oikeuksia koskien tuotteidemme käyttöä. Kaikki muut CamDiabin käyttäjää kohtaan esittämät vahingonkorvausvaatimukset säilyvät ennallaan.
                                </p>
                                <p>
                                    7.2.4 Käyttäjä hyväksyy täyden vastuun kaikista sellaisista vahingoista ja kiistoista oikeudessa ja sen ulkopuolella, jotka syntyvät kiistoista muiden käyttäjien kanssa. Käyttäjä myöntää nimenomaisesti, että CamDiab ei ole missään olosuhteissa vastuussa toisen käyttäjän toimista tai tekemättä jättämisistä ja niistä mahdollisesti johtuvista vahingoista.
                                </p>
                                <p>
                                    7.2.5 Jos kolmannet osapuolet esittävät vaatimuksia CamDiabia vastaan, käyttäjä on velvollinen toimittamaan viipymättä kaikki hänen käytettävissään olevat tiedot totuudenmukaisesti ja kokonaisuudessaan, kun se on tarpeen kolmansien osapuolten vaatimusten tarkistamiseksi, puolustamiseksi ja estämiseksi. Käyttäjä vastaa mahdollisista CamDiabin tarvittavista oikeudellisista toimenpiteistä aiheutuvista kuluista kustannuksista mukaan lukien lakisääteiset oikeudenkäyntikulut.
                                </p>
                                <h2>
                                    8. LOPUT EHDOT
                                </h2>
                                <h3>
                                    8.1. Lainvalinta
                                </h3>
                                <p>
                                    Nämä käyttöehdot ja koko oikeudellinen suhde CamDiabin ja käyttäjän välillä ovat kokonaan Englannin lain alaisia lukuun ottamatta sen viitemääräyksiä ja YK:n myyntisopimusta. Kuluttajana käyttäjällä on kuitenkin suojanaan pakolliset määräykset maassa, jossa hän on tai jossa hänen asuinpaikkansa on.
                                </p>
                                <h3>
                                    8.2. Lainkäyttövalta
                                </h3>
                                <p>
                                    Kaikkien näistä käyttöehdoista johtuvien riitojen täytäntöönpanopaikka ja ainoa toimivaltainen tuomioistuin on sovittu CamDiabin pääkonttoriksi Cambridgessa, Yhdistyneessä kuningaskunnassa. Pakolliset lakien ristiriidat kuluttajien eduksi eivät muutu.
                                </p>
                                <h3>
                                    8.3. Muut asiat
                                </h3>
                                <p>
                                    8.3.1 Jos näiden käyttöehtojen puitteissa vaaditaan ilmoitusta tai kirjallista muotoa (mukaan lukien tärkeät lainmukaiset ilmoitukset), niiden lähettäminen sähköpostitse rekisterissä (CamDiab-tunnus) olevaan osoitteeseen riittää.
                                </p>
                                <p>
                                    8.3.2 Käyttäjillä ei ole oikeutta siirtää CamDiab-tunnustaan tai oikeuksiaan ja velvollisuuksiaan koskien näitä käyttöehtoja tuotteidemme käytön yhteydessä kolmansille osapuolille. CamDiab voi siirtää näiden käyttöehtojen mukaiset oikeutensa ja velvollisuutensa toiselle yritykselle tai kolmannelle osapuolelle, jos sellaisilla siirroilla ei ole merkittäviä vaikutuksia näiden käyttöehtojen mukaisiin käyttäjän oikeuksiin.
                                </p>
                                <p>
                                    8.3.3 Jos jokin yksittäinen näiden käyttöehtojen ehto on tai siitä tulee tehoton, sillä ei ole vaikutusta käyttöehtojen muiden ehtojen soveltuvuuteen ja voimassaoloon.
                                </p>
                                <p>
                                    8.3.4 Nämä käyttöehdot ovat saatavilla useilla kielillä. Sen maan, jossa kuluttaja sijaitsee tai jossa hänen asuinpaikkansa on, kielinen versio on keskeinen ja lopullinen. Jos ja siltä osin kuin maakohtaista kieliversiota ei ole saatavilla, englanninkielinen versio on lopullinen.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer props={this.props}/>
            </div>
        );
    }
}

