import React, {Component} from 'react';
import {Header} from '../Components/Header';
import {Footer} from '../Components/Footer';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

export class TermsPL extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Regulamin - CamAPS FX</title>
                    <meta name="title" content='Regulamin'/>
                    <meta name="description"
                          content='Tutaj znajdziesz regulamin CamDiab'/>
                    <meta property="og:locale" content='pl_pl'/>
                    <meta name="keywords" content='Regulamin'/>
                </Helmet>
                <Header props={this.props}/>
                <section className="inner-banner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-10">
                                <h1>Regulamin</h1>
                                <h3>Wersja z dnia 24 czerwca 2024</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <h2>
                                    1. ZAKRES
                                </h2>
                                <h3>
                                    1.1. Nasze produkty
                                </h3>
                                <p>
                                    1.1.1. Firma
                                </p>
                                <p>
                                    CamDiab Ltd <strong>
                                    <br/>
                                    Zarejestrowany adres siedziby<br/>
                                    20-22 Wenlock Road<br/>
                                    London, N1 7GU, UK
                                    <br/>
                                </strong>
                                    support@camdiab.com
                                    <br/>
                                    numer spółki 11659211, zarejestrowana w Anglii i Walii
                                    <br/>
                                    <br/>
                                </p>
                                <p>
                                    Spółka („<strong>CamDiab</strong>”), opracowuje i obsługuje aplikacje mobilne (apps) przeznaczone do podawania insuliny w pętli zamkniętej oraz powiązaną infrastrukturę danych dla osób z cukrzycą i pracowników ochrony zdrowia. CamDiab to firma działająca w sektorze zdrowia cyfrowego, która pragnie zaoferować osobom z cukrzycą lepszą kontrolę poziomu glukozy i zmniejszenie obciążenia związanego z samoopieką w cukrzycy.
                                </p>
                                <p>
                                    1.1.2 Zgodnie z naszą najlepszą wiedzą, nasze Produkty zapewniają wskazówki i wsparcie w zakresie podawania insuliny w pętli zamkniętej. Nasze Produkty i usługi nie zastępują konsultacji oraz diagnozy u pracownika ochrony zdrowia. W razie pytań dotyczących choroby i leczenia zalecamy skontaktowanie się z pracownikiem ochrony zdrowia. Podawane dane mogą być analizowane wyłącznie przez użytkownika i pracownika ochrony zdrowia. Żadne treści nie będą uznawane za uzupełniające lub zastępujące informacje od pracownika ochrony zdrowia lub farmaceuty, bez względu na to, czy są podawane przez firmę CamDiab, naszych współpracowników, dystrybutorów, wykonawców czy użytkowników.
                                </p>
                                <p>
                                    1.1.3 Firma CamDiab może oferować wybór Produktów (aplikacji) i usług, a także zezwalać na udostępnianie danych w celu wsparcia leczenia cukrzycy. Wszelkie obecne i przyszłe aplikacje i usługi (w tym nowe wersje, aktualizacja i inne ulepszenia), które firma CamDiab w dowolny sposób dostarcza, nazywane będą razem „<strong>Produktami</strong>” lub osobno „<strong>Produktem</strong>”. Wszystkie osoby pozyskujące przynajmniej jeden nasz Produkt i korzystające z niego określane są razem jako „<strong>Użytkownicy</strong>” albo osobno jako „<strong>Użytkownik</strong>”. Jeśli w niniejszych warunkach stosuje się formę męską w odniesieniu do osób fizycznych, warunki te dotyczą w równym stopniu wszystkich osób.
                                </p>
                                <p>
                                    1.1.4 W powiązaniu z niniejszymi warunkami, poniższe definicje mają zastosowanie do poszczególnych Produktów:
                                </p>
                                <p>
                                    <strong>CamAPS FX </strong> to niezależna aplikacja mobilna na urządzenia, obsługująca podawanie insuliny w hybrydowej pętli zamkniętej u osób z cukrzycą typu 1. Aplikacja łączy się z glukometrem oraz z pompą insulinową innych producentów. Użytkownik może zdecydować, czy udostępniać dane gromadzone przez aplikację firmie CamDiab lub serwisom stron trzecich poświęconym cukrzycy w celu przeglądania danych. W zależności od rodzaju pompy i glukometru innych producentów mogą występować ograniczenia dotyczące modelu smartfona z systemem Android.
                                </p>
                                <p>
                                    <strong>CamAPS HX </strong> to aplikacja podobna do CamAPS FX, ale oferująca w pełni zamkniętą pętlę w przypadku cukrzycy typu 1.
                                </p>
                                <p>
                                    1.1.5 Nasze podręczniki użytkownika (dostępne w aplikacji lub w witrynie internetowej) oraz odpowiednie postanowienia szczególne kładą nacisk na specyficzne cechy poszczególnych Produktów.
                                </p>
                                <h3>
                                    1.2. Zakres warunków
                                </h3>
                                <p>
                                    1.2.1 Firma oferuje Produkty wyłącznie na podstawie bieżącej wersji niniejszych Ogólnych warunków o zasięgu globalnym. Firma CamDiab wyraźnie sprzeciwia się sprzecznym warunkom handlowym albo innym warunkom użytkowania.
                                </p>
                                <p>
                                    1.2.2 Należy pamiętać, że przed pierwszym zastosowaniem Produktów wymagane jest wyrażenie zgody na wszystkie przywołane oświadczenia i umowy oraz zaakceptowanie ich w toku jakiegokolwiek dalszego użytkowania. Bez wyrażenia zgody na nasze warunki rejestracja Produktów i korzystanie z nich są zabronione.
                                </p>
                                <p>
                                    1.2.3 Firma zastrzega sobie prawo do wprowadzania zmian w przyszłości oraz modyfikacji warunków uzasadnionych okolicznościami faktycznymi. Zmiany mogą być wymagane w celu spełnienia wymogów ustawowych, technicznych i ekonomicznych, albo w celu zaspokojenia interesów Użytkowników. Zmiany takie mogą być wprowadzane w dowolnym momencie i zostaną opublikowane w odpowiedni sposób. Jeśli w ciągu jednego miesiąca nie zostanie zgłoszony żaden sprzeciw, dalsze korzystanie z Produktów podlegać będzie odpowiednim nowym warunkom.
                                </p>
                                <h3>
                                    1.3. Dostawcy będący stronami trzecimi
                                </h3>
                                <p>
                                    1.3.1 Nasze Produkty można znaleźć w sklepach z aplikacjami. W takich sklepach zastosowanie mają zwykle warunki handlowe odpowiedniego dostawcy będącego podmiotem zwenętrznym (na który nasza firma nie ma wpływu), przy czym nie stanowią one części naszych warunków ani naszego stosunku umownego z Użytkownikiem Produktów. Dotyczy to także warunków handlowych dostawców urządzeń mobilnych i usługodawców.
                                </p>
                                <p>
                                    1.3.2 Aby zapewnić optymalne wykorzystanie naszych Produktów, współpracujemy z różnymi partnerami. Należą do nich współpracujący partnerzy dostarczający określone Produkty bezpośrednio do Użytkowników (np. pompy insulinowe lub systemy ciągłego monitorowania glukozy). W takich przypadkach kładziemy nacisk na obowiązujące warunki handlowe dostawcy będącego podmiotem zewnętrznym, ponieważ rozpoczęcie korzystania z naszych Produktów wymaga zaakceptowania tych warunków.
                                </p>
                                <h2>
                                    2. OCHRONA DANYCH
                                </h2>
                                <p>
                                    <strong> Zdajemy sobie sprawę z odpowiedzialności, z jaką wiąże się korzystanie z naszych Produktów. Użytkownicy przekazują nam nie tylko swoje ogólne dane osobowe, ale także dane dotyczące stanu zdrowia. W naszej </strong>
                                    <Link to="/pl/privacy">
                                        Polityce prywatności </Link> <strong> informujemy Użytkowników o wykorzystaniu i ochronie ich danych w związku z korzystaniem z naszych Produktów i wymaganej w związku z tym zgodzie. </strong>
                                </p>
                                <p>
                                    <strong> BEZ UDZIELENIA WYNIKAJĄCEJ Z ZASAD OCHRONY PRYWATNOŚCI ZGODY NA NIEZBĘDNE PRZETWARZANIA DANYCH, UŻYTKOWANIE PRODUKTÓW NIE JEST MOŻLIWE POD WZGLĘDEM PRAWNYM ANI FAKTYCZNYM. </strong>
                                </p>
                                <p>
                                    <strong>Nasza </strong>
                                    <Link to="/pl/privacy"> Polityka prywatności</Link> <strong> nie stanowi części niniejszych warunków. Stosowana jest tylko w celu przedstawienia użytkownikowi informacji wymaganych przez RODO, na podstawie których wyrażana jest zgoda na niezbędne przetwarzanie danych. </strong>
                                </p>
                                <h2>
                                    3. ZAWARCIE UMOWY I REJESTRACJA
                                </h2>
                                <h3>
                                    3.1. Zakup produktów i płatność za produkty
                                </h3>
                                <p>
                                    3.1.1 Nasze produkty można zakupić na wiele sposobów:
                                </p>
                                <h3>
                                    Aplikacje
                                </h3>
                                <p>
                                    Nasze produkty są oferowane przez <strong>sklepy z aplikacjami</strong> (na przykład Google Play oraz Amazon Appstore). Należy pamiętać, że pobieranie (ale nie użytkowanie) naszych produktów regulują odrębne warunki handlowe sklepu z aplikacjami, które mogą wymagać założenia konta użytkownika.
                                </p>
                                <p>
                                    Bezpośredni dostęp do naszych produktów i ich zamówienie możliwe jest w <strong>naszej witrynie internetowej</strong>.
                                </p>
                                <p>
                                    Dostępne mogą być <strong>kody promocyjne</strong> na nasze produkty, które można wykorzystać w naszej witrynie internetowej.
                                </p>
                                <p>
                                    3.1.2 Odpłatne Produkty można zamówić w naszej witrynie internetowej lub za pośrednictwem naszych dystrybutorów. Płatność realizowana jest za pośrednictwem dostawcy usług płatniczych, naszych dystrybutorów lub innych przewidzianych sposobów płatności. Dystrybutor występuje jako przedstawiciel i dostawca usług płatniczych wobec firmy CamDiab albo jako sprzedawca bezpośredni. W razie problemów z pobieraniem albo płatnością, można w dowolnej chwili napisać na adres <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <p>
                                    3.1.3 W zależności od oferty i wyboru, opłaty z tytułu użytkowania albo ceny zakupu (w tym ustawowy podatek VAT) płatne są jednorazowo bądź regularnie (np. co miesiąc, co pół roku, co rok). Bieżące opłaty podane są w naszej witrynie internetowej, w aplikacji albo w sklepach z aplikacjami i płatne są z góry. Jeśli płatność nie jest przetwarzana bezpośrednio za pośrednictwem sklepu z aplikacjami, dostawca płatności albo dystrybutor mogą wysyłać faktury albo przypomnienia o płatności w wiadomościach e-mail. W przypadku umyślnego opóźnienia lub dokonania niepełnej płatności przysługuje nam prawo zablokowania dostępu Użytkownika do naszych Produktów. Nie wpływa to na zobowiązanie Użytkownika do uiszczania opłat za użytkowanie.
                                </p>
                                <p>
                                    3.1.4 Firma CamDiab może opracowywać także specjalne i bezpłatne oferty, które podlegają dodatkowym warunkom albo ograniczeniom. W takim przypadku w wyraźny sposób wskazujemy wszelkie różnice względem niniejszych warunków, przy czym pełna treść niniejszych warunków pozostaje w mocy. Oferty specjalne i bezpłatne nie podlegają przeniesieniu na innych Użytkowników.
                                </p>
                                <h3>
                                    3.2. Rejestracja i aktywacja
                                </h3>
                                <p>
                                    3.2.1 Korzystanie z naszych Produktów wymaga rejestracji w aplikacji firmy CamDiab. Po pierwszej aktywacji naszego Produktu wymagana jest rejestracja przy użyciu adresu e-mail i hasła (identyfikator CamDiab ID). Każdy użytkownik może utworzyć tylko jeden identyfikator CamDiab ID, a dany identyfikator CamDiab ID może być używany tylko przez jednego użytkownika.
                                </p>
                                <p>
                                    3.2.2 Rejestrując się, Użytkownik wyraża zgodę na związanie ofertą zawarcia umowy z firmą CamDiab na korzystanie z Produktów na podstawie obowiązującej wersji niniejszych warunków. Firma w sposób wyraźny zaakceptuje taką ofertę, nie później niż w momencie aktywacji identyfikatora CamDiab ID.
                                </p>
                                <p>
                                    3.2.3 Aktywacja jest potwierdzona po zaakceptowaniu danych logowania i przejściu odpowiedniego przeszkolenia. Zastrzegamy sobie prawo do odrzucenia poszczególnych Użytkowników lub ich oferty zawarcia umowy bez podania przyczyny. W takim przypadku wszelkie dokonane już płatności zostaną zwrócone, a udostępnione dane użytkownika niezwłocznie usunięte.
                                </p>
                                <p>
                                    3.2.4 Dokonując rejestracji, Użytkownik potwierdza, że ma ukończone przynajmniej szesnaście lat i zgodnie z obowiązującym prawem ma zdolność do zawierania umów. Rejestracji dokonywać mogą wyłącznie osoby posiadające zdolność do czynności prawnych. Osoby małoletnie mogą rejestrować się wyłącznie po uzyskaniu wyraźnej zgody rodzica/opiekuna. Dokonując rejestracji, rodzic/opiekun oświadcza, że jest upoważniony do składania wiążących prawnie oświadczeń w imieniu osoby małoletniej.
                                </p>
                                <p>
                                    3.2.5 Dokonując rejestracji, użytkownik potwierdza zapoznanie się z treścią i akceptację, bez ograniczeń, treści niniejszych warunków, oraz że podane przez użytkownika dane są prawdziwe, poprawne, aktualne i kompletne. Zastrzegamy sobie prawo do skontaktowania się w dowolnym momencie z użytkownikami w celu weryfikacji danych podanych podczas rejestracji oraz informacji dotyczących użytkowania.
                                </p>
                                <p>
                                    3.2.6 Jeśli użytkownik poda nieprawdziwe, nieprawidłowe, nieaktualne lub niekompletne informacje lub jeśli wystąpią uzasadnione przyczyny, by uważać, że podane informacje są nieprawdziwe, nieprawidłowe, nieaktualne lub niekompletne, firmie CamDiab przysługuje prawo zablokowania danego identyfikatora CamDiab ID ze skutkiem natychmiastowym bez uprzedniego powiadomienia i zakazania użytkowania naszych Produktów bez obowiązku zwrotu kosztów poniesionych przez Użytkownika.
                                </p>
                                <p>
                                    3.2.7 Użytkownik zobowiązany jest zabezpieczyć dane do rejestracji przed nieuprawnionym dostępem osób trzecich, nadużyciem lub użyciem z zamiar popełnienia oszustwa. W przypadku powzięcia podejrzenia, że identyfikator CamDiab ID został na takie ryzyko narażony, należy niezwłocznie zgłosić ten fakt na adres <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>. Mamy prawo zablokować identyfikator CamDiab ID, jeśli używany jest w sposób niezgodny z prawem lub z zamiarem popełnienia oszustwa.
                                </p>
                                <h2>
                                    4. PRAWO DO WYCOFANIA SIĘ I ODSTĄPIENIA
                                </h2>
                                <h3>
                                    4.1. Informacje o skorzystaniu z prawa do odstąpienia
                                </h3>
                                <p>
                                    4.1.1 Jeśli nasze Produkty nie są używane do celów handlowych ani zawodowych, Użytkownik, będąc konsumentem, może odstąpić od umowy zawartej z firmą CamDiab pod następującymi warunkami:
                                </p>
                                <p>
                                    <strong>PRAWO DO ODSTĄPIENIA</strong>
                                </p>
                                <p>
                                    <strong><u>Prawo do odstąpienia</u></strong>
                                </p>
                                <p>
                                    <strong> Użytkownikowi przysługuje prawo odstąpienia od niniejszej umowy w ciągu czternastu dni bez podania przyczyny. </strong>
                                </p>
                                <p>
                                    <strong> Okres odstąpienia wynosi czternaście dni od daty zawarcia umowy (aktywacji treści cyfrowych). </strong>
                                </p>
                                <p>
                                    <strong> Aby skorzystać z prawa do odstąpienia, należy powiadomić o decyzji o odstąpieniu od umowy w formie wyraźnego oświadczenia przesłanego dystrybutorowi lub firmie CamDiab na adres email </strong> <strong> <a href="mailto:support@camdiab.com">support@camdiab.com</a> </strong> <strong> (np. pocztą elektroniczną lub tradycyjną). </strong>
                                </p>
                                <p>
                                    <strong> W celu zachowania okresu odstąpienia wystarczy wysłać powiadomienie o skorzystaniu z prawa do odstąpienia przed jego upływem. </strong>
                                </p>
                                <p>
                                    <strong><u>Skutki odstąpienia</u></strong>
                                </p>
                                <p>
                                    <strong> W przypadku odstąpienia od umowy, dystrybutor lub firma CamDiab musi niezwłocznie zwrócić wszelkie otrzymane od Użytkownika płatności, nie później niż w ciągu czternastu dni od dnia otrzymania powiadomienia o odstąpieniu od umowy. O ile nie ustalono wyraźnie inaczej i w miarę możliwości, wpłacone kwoty zostaną zwrócone za pomocą tej samej metody płatności. W związku ze zwrotem płatności nie będą pobierane żadne opłaty. </strong>
                                </p>
                                <h2>
                                    5. KORZYSTANIE Z PRODUKTÓW
                                </h2>
                                <h3>
                                    5.1. Okres korzystania i rozwiązanie umowy
                                </h3>
                                <p>
                                    5.1.1 Użytkownik nabywa prawo do korzystania z Produktów w chwili rejestracji i aktywacji przez firmę CamDiab.
                                </p>
                                <p>
                                    5.1.2 Odpłatne Produkty można nabywać w formie miesięcznych, półrocznych albo rocznych subskrypcji. W zależności od bieżącej oferty są one również dostępne w krótszych lub dłuższych okresach (zwanych dalej „<strong>okresem subskrypcji</strong>”).
                                </p>
                                <p>
                                    5.1.3 Zarówno Użytkownikowi, jak i firmie CamDiab przysługuje prawo do rozwiązania umowy w trybie zwykłym w dowolnym momencie za jednomiesięcznym wypowiedzeniem, ze skutkiem na koniec miesiąca. Użytkownik może to zrobić, kontaktując się z dystrybutorem lub przesyłając powiadomienie na piśmie na adres <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>. Należy zauważyć, że opłaty wniesione za niewykorzystany okres subskrypcji zwracane są wyłącznie w przypadku rozwiązania w trybie zwykłym przez firmę CamDiab lub automatycznego rozwiązania (pkt 5.1.5 poniżej).
                                </p>
                                <p>
                                    5.1.4 W przypadku braku lub opóźnienia w złożeniu wypowiedzenia, okres subskrypcji ulega automatycznemu przedłużeniu o kolejny okres odpowiadający wcześniej wybranemu okresowi subskrypcji. Zastosowanie ma odpowiednia cena standardowa obowiązująca w momencie przedłużenia (a nie cena w ramach oferty specjalnej lub bezpłatnej).
                                </p>
                                <p>
                                    5.1.5 W przypadku śmierci Użytkownika lub odstąpienia od zgody wynikające z zasad ochrony prywatności, użytkowanie kończy się automatycznie ze skutkiem natychmiastowym. Firmie CamDiab nie wolno przetwarzać danych o stanie zdrowia Użytkownika bez jego zgody.
                                </p>
                                <p>
                                    5.1.6 W razie pytań dotyczących okresu korzystania i jego zakończenia, prosimy o kontakt pod adresem <a href="mailto:support@camdiab.com">support@camdiab.com﻿</a> w dowolnym momencie.
                                </p>
                                <h3>
                                    5.2. Blokada i wyłączenie
                                </h3>
                                <p>
                                    5.2.1 Zastrzegamy sobie prawo do tymczasowej blokady Użytkowników w dowolnym momencie z ważnej przyczyny (według naszego uznania) lub do trwałego wyłączenia możliwości użytkowania Produktów przez Użytkowników. W przypadku takiego rozwiązania umowy bez okresu wypowiedzenia zwrot opłat za korzystanie jest wykluczony.
                                </p>
                                <p>
                                    5.2.2 Ważna przyczyna obejmuje w szczególności przypadki poważnego naruszenia przez Użytkownika niniejszych warunków, tj. naruszenia pkt. 3.1 (Płatności) po wyznaczeniu dwutygodniowego okresu karencji, pkt. 3.2 (Dane do rejestracji) po wyznaczeniu dwutygodniowego okresu karencji, pkt. 5.3 (Licencja) albo pkt. 6 („Zachowanie użytkownika”).
                                </p>
                                <h3>
                                    5.3. Licencja i udzielenie praw
                                </h3>
                                <p>
                                    5.3.1 Dokonując rejestracji i aktywacji, użytkownik nabywa niewyłączne, nieprzenoszalne i nieograniczone pod względem geograficznym prawo do zapisywania i używania kopii aplikacji dla własnych celów w ramach niniejszych warunków. W przypadku Produktów odpłatnych, licencja ograniczona jest na czas umownego okresu subskrypcji.
                                </p>
                                <p>
                                    5.3.2 Oprogramowanie, kod, metody i systemy, a także treść Produktów chronione są prawem autorskim i prawem konkurencji i mogą być używane wyłącznie przez firmę CamDiab. Bez naszej uprzedniej zgody wyrażonej na piśmie, Produktów ani ich części nie wolno kopiować, zmieniać, powielać, ponownie wydawać, przesyłać, przekazywać, sprzedawać, oferować do sprzedaży, sprzedawać ponownie ani wykorzystywać w żaden inny sposób. Użytkownikom nie wolno wykorzystywać marek, logo, innych praw własności handlowej ani praw do znaków towarowych firmy CamDiab. O ile nie określono inaczej w niniejszych warunkach, wszystkie prawa do użytkowania i wykorzystywania stanowią wyłączną własność firmy CamDiab a Produkty nie podlegają licencjonowaniu.
                                </p>
                                <p>
                                    5.3.3. Jeśli wymaga tego techniczny aspekt udostępnienia Produktów, każdy użytkownik udziela firmie CamDiab niewyłącznego, odwołalnego lecz bezpłatnego i przenoszalnego prawa do wykorzystywania i użytkowania, nieograniczonego w czasie i przestrzeni, do treści przez nich generowanych, przekazywanych, przechowywanych lub publikowanych w ramach Produktów. Użytkowanie i wykorzystywanie jest jednak wyłączone, jeśli w sposób niewspółmierny wywiera negatywny wpływ na uzasadnione interesy użytkownika (np. prawa dotyczące prywatności). W przypadku użytkowania poza Produktami, w stosownych przypadkach, wskażemy, że treści pochodzą od Użytkownika. Firma CamDiab nie rości sobie praw własności do treści tworzonych przez Użytkowników i nie będzie pełnić funkcji nadzorczych wobec tych treści.
                                </p>
                                <h3>
                                    5.4. Dostępność naszych Produktów
                                </h3>
                                <p>
                                    5.4.1 W każdym przypadku dostarczane przez nas Produkty są zgodne z istniejącymi możliwościami technicznymi, ekonomicznymi, operacyjnymi i organizacyjnymi. Firma CamDiab nie może wykluczyć żadnych przerw, zakłóceń, opóźnień, usunięć, nieprawidłowych transmisji lub awarii pamięci w związku z użytkowaniem Produktów lub komunikacji z Użytkownikami. Firma częściowo oferuje swoje Produkty we współpracy z dystrybutorami i dostawcami będącymi stronami trzecimi i w związku z tym jest zależna od technicznej realizacji usług stron trzecich. Dlatego też firma CamDiab nie ponosi odpowiedzialności za dostarczanie Produktów online w trybie ciągłym bez zakłóceń, nie udziela gwarancji w tym zakresie ani nie jest to tego zobowiązana.
                                </p>
                                <p>
                                    5.4.2 Dotyczy to także ograniczenia w użytkowaniu Produktów w wyniku siły wyższej, strajków, lokautów i oficjalnych poleceń lub z uwagi na techniczne modyfikacje lub prace konserwacyjne prowadzone na systemach firmy CamDiab. W przypadku zakłóceń lub awarii Produktów prosimy o kontakt pod adresem <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a> w dowolnym momencie.
                                </p>
                                <h2>
                                    6. ZACHOWANIE UŻYTKOWNIKA
                                </h2>
                                <h3>
                                    6.1. Informacje ogólne
                                </h3>
                                <p>
                                    6.1.1 Każdemu Użytkownikowi przysługuje prawo i obowiązek użytkowania Produktów na własne ryzyko i koszt, z wykorzystaniem odpowiednich urządzeń technicznych. Wniosek o podanie informacji o urządzeniach i systemach operacyjnych kompatybilnych z Produktami można uzyskać w dowolnym momencie, pisząc na adres <a
                                    href="mailto:support@camdiab.com">support@camdiab.com﻿</a>.
                                </p>
                                <p>
                                    6.1.2 Użytkownik zobowiązany jest korzystać z Produktów wyłącznie zgodnie z niniejszymi warunkami oraz przepisami prawa i do unikania bezprawnego użytkowania. W razie wykrycia jakichkolwiek przypadków naruszenia obowiązków określonych w niniejszym punkcie, prosimy o niezwłoczne powiadomienie na adres <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h3>
                                    6.2. Nadużycie
                                </h3>
                                <p>
                                    6.2.1 Każdy Użytkownik zobowiązany jest podać dane do rejestracji zgodnie z prawdą, aktualizować je, zapewnić ich kompletność i nie przekazywać ich stronom trzecim. Dane osobowe należy traktować w sposób poufny. Czynności w zakresie zarządzania danymi i tworzenia kopii zapasowych należą do obowiązków Użytkownika. Firma CamDiab nie ponosi odpowiedzialności za dane lub treści utracone lub uszkodzone nie z jej winy i nie udziela gwarancji w tym zakresie.
                                </p>
                                <p>
                                    6.2.2 Treści takie jak zdjęcia, obrazy, tekst, materiały wideo lub inne formy przedstawienia można przechowywać, publikować, przekazywać albo rozpowszechniać w związku z Produktami, jeśli Użytkownik ma prawo do ich przekazywania lub wykorzystywania. W każdym przypadku wykorzystanie treści o charakterze rasistowskim, obraźliwym, dyskryminującym, zniesławiającym, seksualnym, pornograficznym, treści zawierających przemoc lub innych nielegalnych treści jest niedozwolone.
                                </p>
                                <p>
                                    6.2.3 Ponadto niedozwolone jest odszyfrowywanie, odtwarzanie kodu źródłowego, dekompilowanie czy dezasemblacja Produktów albo korzystanie z Produktów na zrootowanych smartfonach. Każdy Użytkownik zobowiązuje się nie podejmować żadnych działań zakłócających przy użyciu środków technicznych albo elektronicznych w Produktach (w szczególności prób hakowania, ataków brute force, wprowadzania wirusów, robaków, koni trojańskich, innego złośliwego oprogramowania) ani żadnego rodzaju prób zakłócenia, które mogłoby mieć wpływ na oprogramowanie lub sprzęt, które stanowią część Produktów i systemów firmy CamDiab. Wszelka ingerencja w Produkty może prowadzić do ich działania niezgodnie z przeznaczeniem.
                                </p>
                                <h2>
                                    7. ODPOWIEDZIALNOŚĆ FIRMY CAMDIAB
                                </h2>
                                <h3>
                                    7.1. Gwarancja i odpowiedzialność
                                </h3>
                                <p>
                                    7.1.1 O ile nie określono inaczej w niniejszych warunkach, firma CamDiab ponosi odpowiedzialność i udziela gwarancji zgodnie z przepisami prawa.
                                </p>
                                <p>
                                    7.1.2 W odniesieniu do użytkowników niebędących konsumentami, odpowiedzialność z tytułu strat finansowych, szkód następczych, utraconego zysku i szkód wynikających z roszczeń stron trzecich jest wyłączona. Ograniczenie lub wyłączenie odpowiedzialności w niniejszych warunkach ma także zastosowanie do wykonawców i spółek powiązanych firmy CamDiab.
                                </p>
                                <p>
                                    7.1.3 Poza zakresem zastosowania przepisów prawa dotyczących odpowiedzialności za produkt, odpowiedzialność firmy CamDiab jest ograniczona do zamierzonych działań i rażącego zaniedbania, z wyjątkiem szkody osobowej. Odpowiedzialność z tytułu nieznacznego zaniedbania jest wyłączona w przypadku przedstawienia ważnych i obiektywnych uzasadnień.
                                </p>
                                <p>
                                    7.1.4 Firma CamDiab nie gwarantuje, że Produkty będą dostępne bez przerwy i bez błędów lub że wymagane oprogramowanie i sprzęt działać będą bez błędów. Nie może także wykluczyć możliwości śledzenia, rejestrowania lub fałszowania danych przez strony trzecie podczas przesyłu danych za pomocą systemów stron trzecich, w szczególności przez internet i inne sieci telekomunikacyjne.
                                </p>
                                <p>
                                    7.1.5 Firma CamDiab nie ponosi żadnej odpowiedzialności za pobrane treści lub materiały otrzymane przez Użytkownika w wyniku korzystania z Produktów. Użytkownik ponosi wyłączną odpowiedzialność za wszelkie szkody powstałe w jego systemie informatycznym lub urządzeniach lub za utratę danych w wyniku pobrania materiałów powiązanych z Produktami.
                                </p>
                                <p>
                                    7.1.6 Ponadto firma CamDiab nie ponosi odpowiedzialności za treści stron trzecich, takie jak łącza zewnętrzne, banery, inne informacje, czy oferty reklamowe stron trzecich, które mogą być zamieszczane w Produktach. W przypadku umożliwienia dostępu do ofert stron trzecich za pomocą powiadomień lub łącz, firma CamDiab nie ponosi odpowiedzialności za zawarte w nich informacje. Wynikające z tego czynności prawne ze stronami trzecimi prowadzą wyłącznie do stosunków umownych pomiędzy użytkownikiem a daną stroną trzecią. Firma nie ponosi żadnej odpowiedzialności za usługi świadczone przez strony trzecie.
                                </p>
                                <h3>
                                    7.2. Zastrzeżenie i zwolnienie z odpowiedzialności
                                </h3>
                                <p>
                                    7.2.1 Korzystanie z Produktów nie zastępuje konsultacji z pracownikiem ochrony zdrowia ani innych porad medycznych i podejmowane jest wyłącznie na własne ryzyko Użytkownika. Dotyczy to wszelkiego wykorzystania danych udostępnionych w ramach Produktów przez Użytkownika, w tym wyników pomiarów glukozy przy pomocy czujnika oraz we krwi przy użyciu glukometru, obliczeń czy zaleceń. Użytkownik w sposób wyraźny przyjmuje do wiadomości, że dane takie mogą być błędne, a firma CamDiab nie ponosi odpowiedzialności za ich poprawność.
                                </p>
                                <p>
                                    7.2.2 W stopniu wymaganym przez obowiązujące przepisy prawa dotyczące wyrobów medycznych regulujące użytkowanie Produktów, Produktów można używać wyłącznie zgodnie z przeznaczeniem, specyfikacją i obszarami zastosowania przedstawionymi w ofercie i warunkach użytkowania.
                                </p>
                                <p>
                                    7.2.3 Użytkownik zwolni firmę CamDiab z odpowiedzialności z tytułu wszelkich roszczeń stron trzecich przeciwko firmie CamDiab w wyniku bezprawnego naruszenia jej praw przez użytkownika w związku z korzystaniem z Produktów. Wszelkie pozostałe roszczenia odszkodowawcze firmy CamDiab wobec Użytkownika pozostają bez zmian.
                                </p>
                                <p>
                                    7.2.4 Użytkownik ponosi pełną odpowiedzialność za szkody oraz spory sądowe i pozasądowe wynikające z konfliktów z innymi Użytkownikami. Użytkownik w sposób wyraźny przyjmuje do wiadomości, że w żadnym wypadku firma CamDiab nie odpowiada za działania lub zaniechania innych Użytkowników i wynikającej z nich szkody.
                                </p>
                                <p>
                                    7.2.5 W przypadku wniesienia roszczeń wobec firmy CamDiab przez strony trzecie, użytkownik zobowiązany jest niezwłocznie przekazać, zgodnie z prawdą i w pełni, wszystkie dostępne informacje wymagane do analizy roszczeń stron trzecich, obrony przed nimi i zapobiegania im. Użytkownik ponosi koszty niezbędnej obrony prawnej firmy CamDiab, w tym wszelkie koszty sądowe i prawne w kwotach ustawowych.
                                </p>
                                <h2>
                                    8. POSTANOWIENIA KOŃCOWE
                                </h2>
                                <h3>
                                    8.1. Wybór prawa
                                </h3>
                                <p>
                                    Niniejsze warunki oraz całość stosunku prawnego pomiędzy firmą CamDiab a Użytkownikiem podlegają wyłącznie prawu angielskiemu, z wyjątkiem przepisów odniesienia i Konwencji Narodów Zjednoczonych o umowach międzynarodowej sprzedaży towarów. Jednak jako konsumentowi, Użytkownikowi przysługuje ochrona na mocy obowiązujących przepisów kraju zamieszkania lub pobytu czasowego.
                                </p>
                                <h3>
                                    8.2. Sąd właściwy
                                </h3>
                                <p>
                                    Wszelkie spory wynikające z niniejszych warunków rozstrzygane będą przez sąd właściwy dla siedziby firmy CamDiab w Cambridge w Wielkiej Brytanii. Obowiązujące konflikty prawne na rzecz konsumentów pozostają bez zmian.
                                </p>
                                <h3>
                                    8.3. Pozostałe postanowienia
                                </h3>
                                <p>
                                    8.3.1 Jeśli zgodnie z niniejszymi warunkami (w tym na potrzeby oświadczeń o mocy prawnej) wymagane jest powiadomienie lub forma pisemna, wystarczające będzie powiadomienie przesłane na adres e-mail podany przy rejestracji (identyfikator CamDiab ID).
                                </p>
                                <p>
                                    8.3.2 Użytkownikom nie przysługuje prawo przeniesienia identyfikatora CamDiab ID ani praw i obowiązków wynikających z niniejszych warunków w związku z użytkowaniem Produktów na strony trzecie. Firma CamDiab może przenieść swoje prawa i obowiązki wynikające z niniejszych warunków na inną spółkę lub stronę trzecią, o ile takie przeniesienie nie będzie miało znaczącego wpływu na prawa użytkownika wynikające z niniejszych warunków.
                                </p>
                                <p>
                                    8.3.3 Nieskuteczność poszczególnych postanowień niniejszych warunków, która nie ma wpływu na pozostałe postanowienia, nie powoduje nieważności pozostałych postanowień.
                                </p>
                                <p>
                                    8.3.4 Niniejsze warunki dostępne są w różnych językach. Wersja językowa obowiązująca w kraju zamieszkania lub czasowego pobytu konsumenta będzie kluczowa i ostateczna. Jeśli oraz w stopniu, w jakim nie jest dostępna wersja językowa właściwa dla danego kraju, wiążąca będzie angielska wersja językowa.
                                </p>

                            </div>
                        </div>
                    </div>
                </section>
                <Footer props={this.props}/>
            </div>
        );
    }
}

