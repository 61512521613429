/**
 * Texts for Sam's review article
 */
export const samReviewText = {
    title: 'Sam and Sofia-Stevie - CamAPS FX',
    titleMeta: 'Read review of CamAPS FX by Sam and Sofia-Stevie',
    descriptionMeta: 'CamAPS FX has been life changing for Sam and Sofia-Stevie allowing ' +
        'Sam to relax for the first time since the diagnosis.',
    keywords:
        'camaps review Sam and Sofia-Stevie, camaps fx review Sam and Sofia-Stevie, ' +
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ' +
        '',
    localeMeta: 'en_gb',

    imageTitle: 'Sam and Sofia-Stevie',
    shortIntroAndBody:
        '<h2>I feel that for the first time since the diagnosis I can relax</h2>' +
        '<p>' +
        '        I have full trust in the CamAPS FX app and I feel like for ' +
        '        the first time since the diagnosis I can relax. Without a doubt the ' +
        '        time Sofia-Stevie spends within her target blood glucose range has ' +
        '        improved. ' +
        '</p>',
    body: 
        '<h2>I feel that for the first time since the diagnosis I can relax</h2> ' +
        '<p> ' +
        '    <strong> ' +
        '        Over the past two years Sam, mother to Sofia-Stevie (aged 5), ' +
        '        has endured the tremendously steep learning curve any parent of a child ' +
        '        with type 1 diabetes has to undergo. A whirlwind of finger prick tests, ' +
        '        injections and sensors later, she has now discovered the CamAPS FX app ' +
        '        and the hybrid closed-loop system and would not be without it. ' +
        '    </strong> ' +
        '</p> ' +
        '<p> ' +
        '    In a hot summer excessive thirst wouldn’t be anything out of the ordinary ' +
        '    for a young child. However, Sam and her mum followed their intuition ' +
        '    that it could be something more and began to research if there was any cause ' +
        '    for concern, which ultimately led to Sofia-Stevie’s diagnosis of ' +
        '    type 1 diabetes. ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '        “The diagnosis changed everything forever, I can remember the first few ' +
        '        weeks like it was yesterday. Almost overnight I felt like I needed ' +
        '        to be an expert in diabetes to best care for my little girl. ' +
        '        She is my absolute priority, so I just did it, and I feel really proud ' +
        '        of myself and my mum for doing it – she’s been on this journey ' +
        '        with us from the beginning.” ' +
        '    </i> ' +
        '</p> ' +
        '<h3> ' +
        '    A steep learning curve ' +
        '</h3> ' +
        '<p> ' +
        '    Like most parents with a newly diagnosed child with type 1 diabetes ' +
        '    Sam quickly became an expert at finger prick tests, basal and bolus ' +
        '    insulin dosing and what to do when her daughter was hypoglycaemic. ' +
        '    Injecting your child would be a challenge for any parent, but particularly ' +
        '    for Sam as she also had to overcome a fear of needles. ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '        “The clinical team at Addenbrooke’s Hospital were amazing at ' +
        '        helping me overcome some big challenges in those early days, ' +
        '        especially Adam Dawes, Clinical Nurse Specialist at the hospital. ' +
        '        I don’t think we’d be where we are today without the support of ' +
        '        Adam and the clinical team.” ' +
        '    </i> ' +
        '</p> ' +
        '<p> ' +
        '    In the first few months of Sofia-Stevie’s diagnosis finger prick tests were ' +
        '    a ' +
        '    regular occurrence to understand blood glucose levels through the day. ' +
        '    Sam also had to set alarms at night so she could check her daughter’s blood ' +
        '    glucose levels. If Sofia-Stevie’s levels were high or low, Sam would have to ' +
        '    administer a corrective dose and then wait until her levels were moving in ' +
        '    the right direction before going back to sleep, an exhausting process ' +
        '    for both every single night. ' +
        '</p> ' +
        '<p> ' +
        '    Eventually Sofia-Stevie received a continuous glucose monitor (CGM) to ' +
        '    support her care. A CGM is a small device with a sensor worn just under ' +
        '    the skin to measure glucose levels continuously. Sam saw the CGM as a step ' +
        '    in the right direction, particularly as the device meant she no longer had ' +
        '    to set alarms at night – the device would alert her if Sofia-Stevie’s ' +
        '    glucose levels were out of range automatically. The CGM also syncs with ' +
        '    Sam’s phone so she can check Sofia-Stevie’s blood glucose levels at ' +
        '    school and it also helps teachers with care through the day. ' +
        '</p> ' +
        '<p> ' +
        '    But in January 2020 Sam was introduced to the CamAPS FX app ' +
        '    and hasn’t looked back since. ' +
        '</p> ' +
        '<h3> ' +
        '    Finding freedom ' +
        '</h3> ' +
        '<p> ' +
        '    The CamAPS FX app was developed to mimic the key functions of a ' +
        '    healthy pancreas, a so-called hybrid closed-loop approach to treatment. ' +
        '    The app communicates autonomously with a patient’s CGM and insulin pump ' +
        '    and uses a complex algorithm to automatically calculate and administer ' +
        '    the optimal dose of insulin every 8-12 minutes. ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '        “I’d never heard of the hybrid closed-loop system, ' +
        '        or the CamAPS FX app before. I didn’t know how it worked but when ' +
        '        Adam suggested it might be a benefit to us we jumped in with both feet. ' +
        '        At that point I’d learnt so much about diabetes, learning about the ' +
        '        hybrid closed-loop felt like a walk in the park.” ' +
        '    </i> ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '        “I have full trust in the CamAPS FX app and I feel like for ' +
        '        the first time since the diagnosis I can relax. Without a doubt the ' +
        '        time Sofia-Stevie spends within her target blood glucose range has ' +
        '        improved. It is so much easier to control her levels now and is a ' +
        '        complete weight off my shoulders. It is also less of a burden for ' +
        '        her teachers and much less worry for me. I can check on her ' +
        '        remotely if I want to but also have the reassurance of receiving ' +
        '        automated text messages if she’s going high or low.” ' +
        '    </i> ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '        “You wouldn’t know that she is any different from any of her ' +
        '        classmates and that is thanks to the CamAPS FX app.” ' +
        '    </i> ' +
        '</p> ' +
        '<p> ' +
        '    Sam isn’t alone in her observations about the change the CamAPS FX app has ' +
        '    had on her and her daughter. Research has shown that the app improves ' +
        '    adolescents’ sleep and provides a sense of waking up feeling normal, ' +
        '    which facilitates improved diabetes control during the day, aiding ' +
        '    concentration and well-being in school. In addition, parents of children ' +
        '    using the app have been shown to feel less anxious about their child’s ' +
        '    diabetes, and as a result better able to give their children greater ' +
        '    freedom, like being able to play with friends, go on school trips and ' +
        '    attend sleepovers. ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '        “It makes so much sense for children of Sofia-Stevie’s age to have ' +
        '        the closed-loop, because their bodies are constantly changing how ' +
        '        they respond to insulin. It is an extremely difficult ask for a ' +
        '        parent to keep on top of that without significant highs and lows, ' +
        '        whereas the app learns and adapts instantly. It is beneficial for ' +
        '        the child’s long-term management of the condition and freedom for ' +
        '        the parents so they can sleep at night.” ' +
        '    </i> ' +
        '</p> ' +
        '<h3> ' +
        '    I would never be without the app ' +
        '</h3> ' +
        '<p> ' +
        '    <i> ' +
        '        “I would never be without the app. It is something looking out for ' +
        '        you so you don’t have to worry. To anyone considering it, just go for ' +
        '        it. ' +
        '        It is a game changer and you won’t look back or want to be without ' +
        '        it once you’ve had it. I completely believe it is so beneficial for ' +
        '        both the parents and the child.” ' +
        '    </i> ' +
        '</p>'

};

/**
 * Texts for Roger's review article
 */
export const rogerReviewText = {
    title: 'Roger - CamAPS FX',
    titleMeta: 'Read review of CamAPS FX by Roger',
    descriptionMeta: 'Roger comments that CamAPS FX can be used by anyone and at any age.',
    keywords:
        'camaps review Roger, camaps fx review Roger, ' +
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ' +
        '',
    localeMeta: 'en_gb',

    imageTitle: 'Roger',
    shortIntroAndBody:
        '<h2>If I can do it anyone can</h2>' +
        '<p>' +
        '    It is amazing how much treatment has changed since I was diagnosed, ' +
        '    I really feel like I’ve come from the desert into the digital world. ' +
        '    To anyone considering the CamAPS FX app my advice is quite simple, go for it. ' +
        '    It really is transformational and if I can do it anyone can.' +
        '</p>',
    body:
        '<h2>If I can do it anyone can</h2> ' +
        '<p> ' +
        '    <strong> ' +
        '        Diagnosed with type 1 diabetes as an adult, retired headteacher Roger, ' +
        '       aged 69, has spent the last 39 years in pursuit of a greater understanding of ' +
        '       his condition. He has experienced first-hand the transformation in diabetes care ' +
        '       during this time, from glass syringes and thick needles sterilised in a saucepan of ' +
        '       boiling water to the CamAPS FX app and the hybrid closed-loop system, ' +
        '       which he now would not be without.   ' +
        '    </strong> ' +
        '</p> ' +
        '<p> ' +
        '    Today type 1 diabetes is often diagnosed in childhood, but for Roger it wasn’t until ' +
        '   he was 30 that his symptoms became apparent. Both Roger’s uncle and mother-in-law had ' +
        '   been diagnosed with type 1 diabetes previously, so he was already familiar with the symptoms. ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '        “I remember vividly I’d just had my breakfast and was out in the garden doing a ' +
        '       few jobs. I came back inside and was extremely thirsty. I downed a pint of ' +
        '       squash in one go and my wife and I looked at each other and at the same ' +
        '       time said ‘diabetes’. My GP took some convincing, but I was referred for tests ' +
        '       and they confirmed I was diabetic.” ' +
        '    </i> ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '        “At the time knowledge and treatment of diabetes was very limited compared to now. ' +
        '           I was simply given some glass syringes, told how to sterilise the needles in ' +
        '           boiling water with a saucepan and given a target amount of insulin. ' +
        '           Fortunately, my mother-in-law lent me her British Diabetic Association handbook and ' +
        '           I can remember sitting in our kitchen with my wife holding open the pages of the ' +
        '           book while I taught myself how to do the injections.” ' +
        '    </i> ' +
        '</p> ' +
        '<h3> ' +
        '    Pursuing better control  ' +
        '</h3> ' +
        '<p> ' +
        '    During these initial years Roger benefitted greatly from the British Diabetic ' +
        '   Association’s (now known as Diabetes UK) resources, for example learning about the need to ' +
        '   administer more insulin when he was ill which he hadn’t previously been aware of. ' +
        '   Roger was managing his condition well but then his glucose levels became more challenging ' +
        '   and so he was referred onto the DAFNE (dose adjustment for normal eating) course to learn ' +
        '   about matching his insulin to his diet and lifestyle. However, Roger was still experiencing ' +
        '   severe hypoglycaemic events.  ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '        “I was looking after my Grandson and playing football with him in the garden. ' +
        '       My son-in-law came to pick him up and suddenly said, ‘Roger you need to go get ' +
        '       yourself a juice.’ Sure enough, I did a finger prick test and was at about 2 mmol/l, ' +
        '       so well below where it should be. I was meant to be looking after my Grandson ' +
        '       the following day but they made other arrangements. They didn’t think I was well ' +
        '       enough to look after him and that devastated me and I knew enough was enough.” ' +
        '    </i> ' +
        '</p> ' +
        '<p> ' +
        '    After sharing this story with Roger’s consultant, he was referred onto a ' +
        '      research project investigating unexplained hypoglycaemic events and was started on ' +
        '       an insulin pump. Immediately the pump helped improve Roger’s glucose levels. ' +
        '</p> ' +
        '<p> ' +
        '    Having benefitted so much from the first research project, when Roger was offered a ' +
        '   chance to take part in another research project he jumped at the chance and started on ' +
        '   the CamAPS FX app in October 2019. ' +
        '</p> ' +
        '<p> ' +
        '    The CamAPS FX app was developed to mimic the key functions of a healthy pancreas, ' +
        '       a so-called hybrid closed-loop approach to treatment. The app communicates autonomously ' +
        '       with a patient’s continuous glucose monitor and insulin pump and uses a complex algorithm ' +
        '       to automatically calculate and administer the optimal dose of insulin every 8-12 minutes.   ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '        “Admittedly I hadn’t heard of the hybrid closed-loop system before, ' +
        '       but when my two children heard I’d be managing my diabetes from a smartphone ' +
        '       they laughed their heads off! Prior to the research the extent of my knowledge of ' +
        '       using a phone was switching it on, making a call and switching it back off, but ' +
        '       actually with some support from the very knowledgeable clinical team at Addenbrooke’s ' +
        '       Hospital I got on really quite well with the smartphone and the CamAPS FX app. ' +
        '       Its ability to just control and treat your diabetes is just tremendous and very liberating.” ' +
        '    </i> ' +
        '</p> ' +
        '<h3> ' +
        '    Complete discretion ' +
        '</h3> ' +
        '<p> ' +
        '    Since starting on the CamAPS FX app Roger has reduced his bolus ' +
        '   insulin and seen a reduction in the amount of insulin required each day. ' +
        '   But Roger has seen other benefits too.  ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '        “Now when we go out for meals all I need do is take out my smartphone, ' +
        '       enter the carbohydrate count and away it goes. I can see my levels instantly ' +
        '       and can do everything I need to do on the phone. Before I had the app I needed to ' +
        '       carry around with me needles and insulin and would go to the toilets to do a finger ' +
        '       prick test and injections as needed. That’s all taken care of now with complete discretion.” ' +
        '    </i> ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '        “Another time we benefitted from the app was at the beginning of the COVID-19 pandemic ' +
        '       when I’d taken a real bad turn. I was thinking the worst, but the app was providing a ' +
        '       continuous reading of my blood glucose levels which was a great reassurance. At one point ' +
        '       I fell asleep and my wife was worried I may be going into a diabetic coma, but she ' +
        '       quietly checked my blood glucose levels on my phone while I slept and could see all was well. ' +
        '       The app provides peace of mind which is a benefit both to mine and my wife’s quality of life.”' +
        '    </i> ' +
        '</p> ' +
        '<p> ' +
        '        The feeling of reassurance and discretion isn’t something that just ' +
        '       Roger and his wife have experienced. Research has shown that the CamAPS ' +
        '       FX app helps people feel less burdened by their diabetes and more able to ' +
        '       lead flexible and spontaneous lives. ' +
        '</p> ' +
        '<p> ' +
        '        Individuals also credited the app for acting as a safety net and having a ' +
        '       level of responsiveness which extended beyond their own capabilities. ' +
        '       Although users are still able to override the app should they wish to ease off or ' +
        '       boost therapy because of physiological demands such as exercise or illness.' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '        “It is amazing how much treatment has changed since I was diagnosed, ' +
        '       I really feel like I’ve come from the desert into the digital world. To anyone ' +
        '       considering the CamAPS FX app my advice is quite simple, go for it. It really is ' +
        '       transformational and if I can do it anyone can.” ' +
        '    </i> ' +
        '</p>'

};