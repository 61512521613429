import React, {Component} from 'react';
import {Header} from '../Components/Header';
import {Footer} from '../Components/Footer';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

export class TermsNL extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Algemene voorwaarden - CamAPS FX</title>
                    <meta name="title" content='Voorwaarden'/>
                    <meta name="description"
                          content='Hier vindt u de algemene voorwaarden van CamDiab'/>
                    <meta property="og:locale" content='nl'/>
                    <meta name="keywords" content='voorwaarden'/>
                </Helmet>
                <Header props={this.props}/>
                <section className="inner-banner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-10">
                                <h1>Algemene voorwaarden</h1>
                                <h3>Versie gedateerd 24 juni 2024</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12">

                                <h2>
                                    1. OMVANG
                                </h2>
                                <h3>
                                    1.1. Onze producten
                                </h3>
                                <p>
                                    1.1.1. Wij
                                </p>
                                <p>
                                    CamDiab Ltd <strong>
                                    <br/>
                                    Geregistreerd kantooradres<br/>
                                    20-22 Wenlock Road<br/>
                                    London, N1 7GU, Verenigd Koninkrijk
                                    <br/>
                                </strong>
                                    support@camdiab.com
                                    <br/>
                                    bedrijfsnummer 11659211 Engeland en Wales
                                    <br/>
                                    <br/>
                                </p>
                                <p>
                                    (‘<strong>CamDiab</strong>’), ontwikkelen en beheren mobiele applicatie(s) (apps) voor closed-loop-insulinetoediening en de daaraan gekoppelde gegevensinfrastructuur, voor personen met diabetes en diabetesbehandelaren. CamDiab is een bedrijf voor digitale gezondheidszorg en wil voor personen met diabetes de glucosewaarden beter beheersbaar maken en de belasting verminderen van de zelfbehandeling bij diabetes.
                                </p>
                                <p>
                                    1.1.2 Onze producten dragen op grond van onze beste kennis en kunde bij aan de begeleiding en ondersteuning van de closed-loop-insulinetoediening. Onze producten en diensten kunnen niet dienen ter vervanging van een consult van en diagnose door een diabetesbehandelaar. Voor vragen over de ziekte en behandeling raden wij aan om contact op te nemen met een diabetesbehandelaar. De beschikbaar gestelde gegevens mogen alleen worden geanalyseerd door u en uw diabetesbehandelaar. Inhoudelijke informatie – of die nu door CamDiab zelf ter beschikking wordt gesteld, door onze samenwerkingspartners, distributeurs of gebruikers – kan niet worden opgevat als aanvulling op of vervanging van de informatie die door diabetesbehandelaren of apothekers wordt gegeven.
                                </p>
                                <p>
                                    1.1.3 CamDiab biedt een reeks producten (apps) en diensten aan voor de omgang met uw diabetes en maakt daarbij het delen van gegevens mogelijk. Alle huidige en toekomstige apps en diensten (waaronder nieuwe versies, updates en andere verbeteringen) die CamDiab aanbiedt, worden hierna samen ‘<strong>producten</strong>’ genoemd, of individueel een ‘<strong>product</strong>’. Iedereen die een of meerdere van onze producten aanschaft en gebruikt, wordt als groep gezamenlijk ‘<strong>gebruikers</strong>’ genoemd of individueel ‘<strong>gebruiker</strong>’. Als deze algemene voorwaarden in de mannelijke vorm verwijzen naar natuurlijke personen, zijn ze in gelijke mate van toepassing op alle personen, ongeacht het geslacht.
                                </p>
                                <p>
                                    1.1.4 Ten aanzien van deze algemene voorwaarden zijn op de afzonderlijke producten de volgende definities van termen van toepassing:
                                </p>
                                <p>
                                    <strong>CamAPS FX</strong> is een onafhankelijke, mobiele applicatie (app) voor uw smartphone, waarmee personen met diabetes type 1 kunnen beschikken over hybride closed-loop-insulinetoediening. De app is gekoppeld aan een glucosesensor van derden en insulinepomp van derden. U kunt besluiten om gegevens die door de app zijn verzameld ter inzage te delen met CamDiab of diabetesportalen van derden. Er kan sprake zijn van beperkingen ten aanzien van het gebruikte model Android-smartphone, afhankelijk van de insulinepomp en glucosesensor van derden.
                                </p>
                                <p>
                                    <strong>CamAPS HX</strong> is een vergelijkbare app als CamAPS FX, maar biedt een volledig gesloten lus bij niet-type 1 diabetes.
                                </p>
                                <p>
                                    1.1.5 In onze gebruikershandleiding(en) (toegankelijk via onze apps of via onze website) en de relevante, speciale bepalingen staan de typische kenmerken genoemd van onze afzonderlijke producten.
                                </p>
                                <h3>
                                    1.2. Omvang van onze algemene voorwaarden
                                </h3>
                                <p>
                                    1.2.1 Wij bieden onze producten uitsluitend aan op basis van de huidige versie van deze algemene voorwaarden (AV), dit geldt voor de hele wereld. CamDiab wijst afwijkende handelsvoorwaarden of andere gebruiksvoorwaarden expliciet af.
                                </p>
                                <p>
                                    1.2.2 Houd er rekening mee dat u voordat u onze producten voor het eerst gebruikt, in moet stemmen met alle genoemde verklaringen en overeenkomsten en deze tijdens het verdere gebruik moet aanvaarden. De aanmelding voor en het gebruik van onze producten is niet toegestaan zonder dat u instemt met onze algemene voorwaarden.
                                </p>
                                <p>
                                    1.2.3 Wij behouden ons expliciet het recht voor om in de toekomst wijzigingen en feitelijk gerechtvaardigde aanpassingen door te voeren in onze algemene voorwaarden. Dergelijke veranderingen kunnen nodig zijn om te voldoen aan vereisten op het gebied van regelgeving, om technische en economische redenen of om aan de belangen van onze gebruikers tegemoet te komen. Dergelijke veranderingen zijn op ieder moment mogelijk en zullen op een juiste manier worden bekendgemaakt. Als hiertegen niet binnen een maand bezwaar wordt gemaakt, gelden voor het verdere gebruik van onze producten de relevante, nieuwe algemene voorwaarden.
                                </p>
                                <h3>
                                    1.3. Derden/leveranciers
                                </h3>
                                <p>
                                    1.3.1 Onze producten zijn terug te vinden in de app-store(s). Over het algemeen zijn daar de handelsvoorwaarden van toepassing van de relevante derde/leverancier (waarop wij geen invloed hebben); die voorwaarden maken geen deel uit van onze algemene voorwaarden, noch van onze contractuele relatie met u als gebruiker van onze producten. Dit geldt ook voor de voorwaarden die gelden voor het gebruik van uw mobiele apparaat of die door de aanbieder zijn vastgelegd.
                                </p>
                                <p>
                                    1.3.2 Ten behoeve van het optimale gebruik van onze producten, werken wij samen met verschillende partners. Het gaat daarbij ook om samenwerkingspartners die onze gebruikers direct voorzien van bepaalde producten (bijv. insulinepomp, systeem voor continue glucosemetingen). In dergelijke gevallen verwijzen wij naar de handelsvoorwaarden van een derde/leverancier, aangezien u die handelsvoorwaarden moet accepteren voor u onze producten kunt gaan gebruiken.
                                </p>
                                <h2>
                                    2. GEGEVENSBESCHERMING
                                </h2>
                                <p>
                                    <strong> Wij zijn ons bewust van de grote verantwoordelijkheid die gepaard gaat met het gebruik van onze producten door u. Wij ontvangen niet alleen algemene persoonsgegevens van u, maar ook gegevens over uw gezondheid. In ons </strong>
                                    <Link to="/nl/privacy">
                                        gegevensbeschermingsbeleid </Link> <strong> informeren wij u over het gebruik van uw gegevens en de bescherming daarvan in combinatie met het gebruiken van onze producten en over uw toestemming die daarvoor nodig is. </strong>
                                </p>
                                <p>
                                    <strong> ZONDER UW TOESTEMMING VOOR ONZE GEGEVENSBESCHERMING TEN AANZIEN VAN DE NOODZAKELIJKE VERWERKING VAN GEGEVENS, IS HET GEBRUIK VAN DE PRODUCTEN IN WETTELIJKE EN FEITELIJKE TERMEN NIET MOGELIJK. </strong>
                                </p>
                                <p>
                                    <strong>Ons </strong>
                                    <Link to="/nl/privacy"> gegevensbeschermingsbeleid </Link> <strong> maakt geen deel uit van deze algemene voorwaarden, maar wordt alleen gebruikt om u te informeren over de regelingen op grond van de AVG, op basis waarvan u instemt met de noodzakelijke gegevensverwerking. </strong>
                                </p>
                                <h2>
                                    3. TOTSTANDKOMING VAN DE OVEREENKOMST EN AANMELDING
                                </h2>
                                <h3>
                                    3.1. Aanschaf en betaling van onze producten
                                </h3>
                                <p>
                                    3.1.1 Onze producten kunnen op verschillende manieren worden aangeschaft:
                                </p>
                                <h3>
                                    Apps
                                </h3>
                                <p>
                                    Onze producten worden aangeboden in <strong>app-stores</strong> (zoals de Google Play en Amazon Appstore). Houd er rekening mee dat voor het downloaden (niet voor het gebruiken) van onze producten de afzonderlijke handelsvoorwaarden van de app-store van toepassing zijn en hiervoor mogelijk een gebruikersaccount moet worden aangemaakt.
                                </p>
                                <p>
                                    <strong>Onze website</strong> biedt directe toegang tot onze producten en de mogelijkheid tot het bestellen van onze producten.
                                </p>
                                <p>
                                    Er zijn mogelijk <strong>vouchercodes</strong> voor onze producten beschikbaar, die op onze website kunnen worden ingewisseld.
                                </p>
                                <p>
                                    3.1.2 U kunt onze betaalde producten bestellen via onze website of via onze distributeurs. Betaling hiervan verloopt via een aanbieder van betaaldiensten, via onze distributeurs of via andere voorgestelde betaalmogelijkheden. De distributeur werkt ofwel als tussenpersoon en aanbieder van betaaldiensten voor CamDiab of als directe verkoper. Als u problemen ondervindt met downloaden en betalen, neem dan alstublieft contact op met <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <p>
                                    3.1.3 Gebruiksvergoedingen of aanschafprijzen (inclusief de wettelijke omzetbelasting) moeten – al naar gelang de aanbieding en uiteindelijke keuzes – eenmalig worden voldaan of herhaaldelijk worden betaald (bijv. een keer per maand, halfjaarlijks of jaarlijks). De huidige vergoedingen staan op onze website, worden door onze distributeur bekendgemaakt of staan weergegeven in onze app of de app-stores en moeten vooraf worden voldaan. Als de betaling niet direct via de app-store wordt verwerkt, kan de aanbieder van betaaldiensten of de distributeur u facturen en betalingsherinneringen sturen per e-mail. In het geval van een verwijtbare, late of onvolledige betaling, zijn wij gerechtigd om uw toegang tot onze producten te blokkeren. Dit heeft echter geen gevolgen voor de betalingsverplichting voor het gebruik ervan.
                                </p>
                                <p>
                                    3.1.4 CamDiab kan ook speciale aanbiedingen en gratis aanbiedingen doen, waarvoor extra voorwaarden en beperkingen gelden. In dat kader wijzen wij expliciet op mogelijke afwijkingen van deze algemene voorwaarden, waarbij de bepalingen daaruit verder alsnog volledig van toepassing blijven. Speciale aanbiedingen of gratis aanbiedingen kunnen niet worden overgedragen op andere gebruikers.
                                </p>
                                <h3>
                                    3.2. Aanmelding en activering
                                </h3>
                                <p>
                                    3.2.1 Voor het gebruik van onze producten moet u zich bij CamDiab aanmelden via onze apps. Zodra u ons product voor de eerste keer heeft geactiveerd, moet u zich aanmelden met behulp van een e-mailadres en wachtwoord (CamDiab-identificatie). Iedere gebruiker kan maar een CamDiab-identificatie aanmaken en iedere CamDiab-identificatie kan maar door één gebruiker worden gebruikt.
                                </p>
                                <p>
                                    3.2.2 Door aanmelding stemt de gebruiker in met de totstandkoming van een overeenkomst met CamDiab voor het gebruik van onze producten, op basis van de toepasselijke versie van deze algemene voorwaarden. Door de uiteindelijke activering van uw CamDiab-identificatie stemmen wij hiermee expliciet in.
                                </p>
                                <p>
                                    3.2.3 De activering wordt bevestigd als uw inloggegevens geaccepteerd zijn en u op de juiste manier bent getraind. Wij behouden ons het recht voor om afzonderlijke gebruikers of hun aanbod voor de totstandkoming van een overeenkomst af te wijzen, zonder daarvoor een reden te geven. In dergelijke gevallen zullen eventueel reeds gedane betalingen worden vergoed en worden de aangeleverde gegevens van de gebruiker direct gewist.
                                </p>
                                <p>
                                    3.2.4 Met de aanmelding bevestigt de gebruiker dat hij minstens 16 jaar oud is en dat hij op grond van de geldende wetgeving toestemming heeft om overeenkomsten te sluiten. Alleen mensen die wettelijk daartoe bevoegd zijn mogen zich aanmelden. Voor minderjarigen geldt dat zij alleen toestemming hebben als een ouder/verzorger hier expliciet mee instemt. Met de aanmelding verklaart de ouder/verzorger het recht te hebben om wettelijk bindende verklaringen te doen namens de minderjarige.
                                </p>
                                <p>
                                    3.2.5 Bij aanmelding bevestigt de gebruiker de inhoud van deze algemene voorwaarden onverkort te erkennen en aanvaarden en dat de opgegeven eigen informatie waar, juist, actueel en volledig is. Wij behouden ons het recht voor om op ieder moment contact op te nemen met gebruikers om hun aanmeldingsgegevens en gebruiksinformatie te verifiëren.
                                </p>
                                <p>
                                    3.2.6 Als de gebruiker foutieve, onjuiste, achterhaalde of onvolledige informatie levert, of als wij voldoende redenen hebben om aan te nemen dat de informatie foutief, onjuist, achterhaald of onvolledig is, heeft CamDiab het recht om de desbetreffende CamDiab-identificatie met onmiddellijke ingang, zonder aankondiging vooraf, te blokkeren en het gebruik van onze producten te verbieden, zonder de gebruiker de gemaakte kosten te hoeven vergoeden.
                                </p>
                                <p>
                                    3.2.7 De gebruiker moet zijn aanmeldingsgegevens beschermen tegen toegang door onbevoegde derden, misbruik of gebruik met frauduleus oogmerk. Bij de minste verdenking dat de CamDiab-identificatie aan een dergelijk risico is blootgesteld, moet dit zonder uitstel worden doorgegeven aan <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>. Wij hebben het recht om de CamDiab-identificatie van iedere gebruiker te blokkeren als die wordt gebruikt met een verkeerd of frauduleus oogmerk.
                                </p>
                                <h2>
                                    4. RECHT VAN INTREKKING EN HERROEPING
                                </h2>
                                <h3>
                                    4.1. Informatie over de uitoefening van het herroepingsrecht
                                </h3>
                                <p>
                                    4.1.1 Als u onze producten niet voor commerciële of beroepsmatige doeleinden gebruikt, heeft u als consument het recht om de overeenkomst met CamDiab onder de volgende voorwaarden te herroepen:
                                </p>
                                <p>
                                    <strong>RECHT VAN HERROEPING</strong>
                                </p>
                                <p>
                                    <strong><u>Recht van herroeping</u></strong>
                                </p>
                                <p>
                                    <strong> U heeft het recht om deze overeenkomst binnen 14 dagen te herroepen, zonder daarvoor een reden te geven. </strong>
                                </p>
                                <p>
                                    <strong> De herroepingsperiode is 14 dagen, gerekend vanaf de datum dat de overeenkomst tot stand is gekomen (activering van de digitale content). </strong>
                                </p>
                                <p>
                                    <strong> Om uw herroepingsrecht uit te oefenen, moet u ons informeren over uw besluit om uw overeenkomst te herroepen, door middel van het sturen van een duidelijke verklaring aan de distributeur of CamDiab, e-mail </strong> <strong> <a href="mailto:support@camdiab.com">support@camdiab.com</a></strong>, <strong> (bijv. per e-mail of per post). </strong>
                                </p>
                                <p>
                                    <strong> Voor het aanhouden van de herroepingsperiode is het voor u voldoende als u de melding van de uitoefening van het herroepingsrecht toestuurt voor het einde van de herroepingsperiode. </strong>
                                </p>
                                <p>
                                    <strong><u>Gevolgen van herroeping</u></strong>
                                </p>
                                <p>
                                    <strong> Als u deze overeenkomst herroept, moeten de distributeur of wij alle betalingen die wij van u hebben ontvangen zonder uitstel terugbetalen, uiterlijk binnen 14 dagen nadat wij de melding over uw herroeping van deze overeenkomst hebben ontvangen. Indien mogelijk gebruiken wij voor die terugbetaling dezelfde betaalmethode als u heeft gebruikt voor de oorspronkelijke transactie, tenzij expliciet anders is overeengekomen; voor deze terugbetaling worden in geen geval kosten in rekening gebracht. </strong>
                                </p>
                                <h2>
                                    5. GEBRUIK VAN ONZE PRODUCTEN
                                </h2>
                                <h3>
                                    5.1. Gebruiksduur en beëindiging van het gebruik
                                </h3>
                                <p>
                                    5.1.1 Uw gebruiksrecht voor onze producten begint na aanmelding en activering bij CamDiab.
                                </p>
                                <p>
                                    5.1.2 Onze betaalde producten kunnen worden aangeschaft in de vorm van een abonnement van een maand, 6 maanden of een jaar en zijn al naar gelang de huidige aanbieding ook verkrijgbaar voor een kortere of langere gebruiksperiode (hier na te noemen ‘<strong>abonnementsperiode</strong>’).
                                </p>
                                <p>
                                    5.1.3 Zowel de gebruiker als CamDiab hebben het recht om de overeenkomst op ieder moment op de gebruikelijke manier op te zeggen met een opzegtermijn van minimaal een maand, ingaand aan het eind van de maand van opzegging. U kunt dit doen door contact op te nemen met uw distributeur of door een schriftelijke mededeling aan ons via <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>. Houd er rekening mee dat kosten die reeds voor een ongebruikte abonnementsperiode zijn betaald alleen worden terugbetaald in het geval van een regelmatige opzegging door CamDiab of bij automatische beëindiging (zie hoofdstuk 5.1.5 hieronder).
                                </p>
                                <p>
                                    5.1.4 Zonder opzegging of bij te late opzegging wordt de abonnementsperiode automatisch verlengd met een periode die overeenkomt met de eerder gekozen abonnementsperiode. In dat geval is de geldende standaardprijs op het moment van verlenging van toepassing (niet de prijzen van speciale aanbiedingen of een gratis abonnement).
                                </p>
                                <p>
                                    5.1.5 In het geval van overlijden van de gebruiker of het herroepen van de toestemming met het gegevensbeschermingsbeleid eindigt de gebruiksrelatie automatisch, met onmiddellijke ingang. Het is CamDiab niet toegestaan uw gezondheidsgegevens te verwerken zonder uw toestemming.
                                </p>
                                <p>
                                    5.1.6 Als u vragen heeft over uw gebruikstermijn of het eind daarvan, kunt u op ieder moment contact opnemen via <a href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h3>
                                    5.2. Blokkering en uitsluiting
                                </h3>
                                <p>
                                    5.2.1 Wij behouden ons het recht voor om gebruikers tijdelijk te blokkeren op ieder moment dat daarvoor (naar onze inschatting) zwaarwegende redenen zijn, of hen permanent uit te sluiten van het gebruik van onze producten. In het geval van beëindiging van de overeenkomst zonder aankondiging vooraf, is terugbetaling van betaalde gebruiksvergoedingen uitgesloten.
                                </p>
                                <p>
                                    5.2.2 Er is in het bijzonder sprake van een zwaarwegende reden bij ernstige schendingen door de gebruiker van de bepalingen in deze algemene voorwaarden, zoals schending van de bepalingen in hoofdstuk 3.1 (Betaling), met een coulanceperiode van twee weken, hoofdstuk 3.2 (Aanmeldingsgegevens), hoofdstuk 5.3 (Gebruiksvergunning) of hoofdstuk 6 (Uw gedrag).
                                </p>
                                <h3>
                                    5.3. Gebruiksvergunning en toekenning van rechten
                                </h3>
                                <p>
                                    5.3.1 Door de aanmelding en activering krijgt u het niet-exclusieve, niet-overdraagbare, maar niet geografisch beperkte recht om een exemplaar van onze app op te slaan en te gebruiken voor uw eigen doeleinden, binnen het kader van de bepalingen in deze algemene voorwaarden. Voor betaalde producten is deze gebruiksvergunning beperkt tot de duur van de contractperiode van uw abonnement.
                                </p>
                                <p>
                                    5.3.2 De software, de broncode, de methodes en systemen en de inhoud van onze producten zijn beschermd door auteursrechten en mededingingsrechten en mogen uitsluitend worden gebruikt door CamDiab. Onze producten of gedeelten daarvan mogen niet worden gekopieerd, aangepast, vermenigvuldigd, opnieuw worden uitgegeven, verspreid door posten, overgedragen, verkocht, ter verkoop worden aangeboden, worden doorverkocht of anderszins worden gebruikt, zonder onze voorafgaande, schriftelijke toestemming. Het is gebruikers niet toegestaan om merken, logo’s of andere commerciële eigendomsrechten of merkrechten van CamDiab te gebruiken. Tenzij in deze algemene voorwaarden anders vermeld staat, zijn de eigendoms- en exploitatierechten exclusief eigendom van CamDiab en worden er voor onze producten op geen enkele wijze licenties verleend.
                                </p>
                                <p>
                                    5.3.3 Als dat nodig is voor de technische beschikbaarheid van onze producten, verleent iedere gebruiker CamDiab een niet-exclusief, herroepbaar, maar gratis en overdraagbaar exploitatie- en gebruiksrecht dat geen beperkingen kent in tijd en plaats en van toepassing is op alle content die door de gebruiker binnen onze producten wordt aangemaakt, overgedragen, opgeslagen of openbaar wordt gemaakt. Het gebruik of de exploitatie daarvan wordt echter uitgesloten als die in te sterke mate ten nadele zijn van de legitieme belangen van de gebruiker (bijv. gegevensbeschermingsrechten). Bij gebruik buiten onze producten, mits van toepassing, wordt door ons vermeld dat de content afkomstig is van de gebruiker. CamDiab zal geen eigendomsrechten doen gelden op aangemaakte content en zal geen controlefunctie innemen ten aanzien van content die door gebruikers is aangemaakt.
                                </p>
                                <h3>
                                    5.4. Beschikbaarheid van onze producten
                                </h3>
                                <p>
                                    5.4.1 Wij stellen onze producten altijd ter beschikking in overeenstemming met de bestaande technische, economische, operationele en organisatorische mogelijkheden. CamDiab kan onderbrekingen, verstoringen, vertragingen, geschrapte passages, onjuiste overdracht of fouten bij het opslaan van informatie in verband met onze producten of in onze communicatie met gebruikers niet uitsluiten. Wij bieden onze producten gedeeltelijk in samenwerking met derden/distributeurs/leveranciers aan en zijn daarom ook afhankelijk van de technische beschikbaarheid van de diensten van derden. Daarom aanvaardt CamDiab geen verantwoordelijkheid, garantie, aansprakelijkheid of verplichtingen voor het continu online, aanbieden van onze producten zonder verstoringen.
                                </p>
                                <p>
                                    5.4.2 Dit heeft ook betrekking op restricties bij het gebruik van onze producten als gevolg van overmacht, stakingen, uitsluitingen en officiële aankondigingen of als gevolg van technische aanpassingen of onderhoudswerkzaamheden aan de systemen van CamDiab. Neem in het geval van storingen of fouten van onze producten alstublieft direct contact op met <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h2>
                                    6. UW GEDRAG
                                </h2>
                                <h3>
                                    6.1. Algemene informatie
                                </h3>
                                <p>
                                    6.1.1 Iedere gebruiker heeft toestemming onze producten voor eigen rekening en risico te gebruiken, mits daarvoor de geschikte technische uitrusting wordt gebruikt. Informatie over de apparatuur en besturingssystemen die compatibel zijn met de producten kunnen op ieder moment worden opgevraagd via <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <p>
                                    6.1.2 U bent bovendien verplicht om onze producten exclusief te gebruiken in overeenstemming met deze algemene voorwaarden en de wettelijke voorschriften en mogelijk verkeerd gebruik ervan te vermijden. Informeer ons alstublieft direct als u schendingen van de verplichtingen uit dit hoofdstuk vaststelt, via <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h3>
                                    6.2. Misbruik
                                </h3>
                                <p>
                                    6.2.1 Iedere gebruiker is verplicht om de eigen aanmeldingsgegevens naar waarheid op te geven, deze informatie actueel en volledig te houden en niet door te geven aan derden. Persoonsgegevens moeten vertrouwelijk worden behandeld, zorgvuldig worden beheerd en op eigen verantwoordelijk worden bewaard. CamDiab aanvaardt geen aansprakelijkheid of andere garanties voor verlies of beschadiging van gegevens of content als wij daaraan geen schuld hebben.
                                </p>
                                <p>
                                    6.2.2 Content zoals foto’s, afbeeldingen, teksten, video’s of andere weergaven mag alleen in combinatie met onze producten worden bewaard, gepubliceerd, overgedragen of verspreid, als de gebruiker het recht heeft om die over te dragen of te gebruiken. In alle gevallen is het gebruik van racistische, aanstootgevende, discriminerende, lasterlijke, seksuele, pornografische, gewelddadige of andere illegale content, van welk soort dan ook, altijd verboden.
                                </p>
                                <p>
                                    6.2.3 Verder is het niet toegestaan om onze producten te decoderen, de opbouw ervan te achterhalen, ze in hun samenstelling te doorbreken of demonteren of onze producten te gebruiken op illegaal geprepareerde (geroote) smartphones. Iedere gebruiker is verplicht om geen verstorende ingrepen aan onze producten te doen door middel van technische of elektronische ingrepen (in het bijzonder door middel van hackpogingen, ‘brute force’-aanvallen, het binnensluizen van virussen, wormen, Trojaanse paarden of andere schadelijke software) of ieder ander type verstoring dat van invloed kan zijn op de software of hardware van de producten en systemen van CamDiab. Alle ongewenste ingrepen aan onze producten kunnen ertoe leiden dat onze producten niet naar behoren werken.
                                </p>
                                <h2>
                                    7. ONZE VERANTWOORDELIJKHEID
                                </h2>
                                <h3>
                                    7.1. Garantie en aansprakelijkheid
                                </h3>
                                <p>
                                    7.1.1 Tenzij in deze algemene voorwaarden anders vermeld staat, is CamDiab aansprakelijk en verleent de wettelijk vastgelegde garantie.
                                </p>
                                <p>
                                    7.1.2 Ten aanzien van gebruikers die geen consumenten zijn, is de aansprakelijkheid voor financiële verliezen, gevolgschade, winstderving en schade door aanspraken van derden uitgesloten. Als onze aansprakelijkheid beperkt is of in deze algemene voorwaarden is uitgesloten, geldt dit tevens voor bedrijven die handelen namens, of gelieerd zijn aan CamDiab.
                                </p>
                                <p>
                                    7.1.3 Afgezien van het gebied waarop de wetgeving voor productaansprakelijkheid van toepassing is, blijft de aansprakelijkheid van CamDiab beperkt tot opzettelijk handelen of grove nalatigheid – uitgezonderd persoonlijk letsel. De aansprakelijkheid voor lichte nalatigheid is uitgesloten als sprake is van zwaarwegende, objectieve redenen.
                                </p>
                                <p>
                                    7.1.4 CamDiab geeft geen garantie voor de volledige beschikbaarheid van onze producten zonder onderbrekingen en zonder fouten, noch voor het foutloos werken van de benodigde software en hardware. Wij kunnen ook niet uitsluiten dat gegevens tijdens de overdracht mogelijk worden achterhaald, vastgelegd of vervalst door derden via systemen van derden, in het bijzonder via het internet of andere telecommunicatienetwerken.
                                </p>
                                <p>
                                    7.1.5 CamDiab verleent geen garantie voor gedownloade content of materialen die gebruikers hebben ontvangen als gevolg van het gebruik van onze producten. De gebruiker is uitsluitend zelf aansprakelijk voor schade die aan hun IT-systeem of apparatuur wordt aangericht of voor het verlies van gegevens als gevolg van het downloaden van materialen die verband houden met onze producten.
                                </p>
                                <p>
                                    7.1.6 Verder is CamDiab niet aansprakelijk voor content van derden, zoals externe links, banners en andere informatie of advertentie-aanbiedingen van derden, die als onderdeel van onze producten kunnen worden geplaatst. Als wij door middel van meldingen of links toegang verlenen voor de aanbiedingen van derden, is CamDiab niet aansprakelijk voor de informatie daarin. Wettelijke transacties die daaruit voortkomen leiden uitsluitend tot een contractuele relatie tussen de gebruiker en de relevante derde. Wij aanvaarden geen garantie of aansprakelijkheid voor de diensten van derden.
                                </p>
                                <h3>
                                    7.2. Vrijwaring en schadeloosstelling
                                </h3>
                                <p>
                                    7.2.1 Het gebruik van onze producten kan niet dienen ter vervanging van een consult bij een diabetesbehandelaar of andere medisch advies en vindt uitsluitend plaats voor risico van de gebruiker. Dit heeft betrekking op iedere vorm van gebruik van de gegevens die als onderdeel van onze producten beschikbaar worden gesteld, inclusief resultaten van bloed- en sensorglucosemetingen, berekeningen en eventuele aanbevelingen. De gebruiker erkent expliciet dat dergelijke gegevens gebreken kunnen vertonen en CamDiab neemt geen verantwoordelijkheid voor de juistheid ervan.
                                </p>
                                <p>
                                    7.2.2 Voor zover dit op grond van de geldende wetgeving voor medische hulpmiddelen die betrekking heeft op de gebruikte producten van toepassing is, mogen de producten uitsluitend worden bediend of gebruikt op grond van het beoogde doel, de geldende specificaties en toepassingsgebieden, zoals is vastgelegd in de bepalingen voor de aanbieding en het gebruik ervan.
                                </p>
                                <p>
                                    7.2.3 De gebruiker stelt CamDiab schadeloos voor alle aanspraken van derden die ten aanzien van CamDiab worden gemaakt als gevolg van de wederrechtelijke schending van geldende rechten door de gebruiker ten aanzien van het gebruik van onze producten. Alle andere schadeclaims door CamDiab tegen de gebruiker blijven daarbij onverminderd van kracht.
                                </p>
                                <p>
                                    7.2.4 De gebruiker aanvaardt volledige aansprakelijkheid voor alle schade en geschillen, binnen en buiten rechte, die voortkomen uit conflicten met andere gebruikers. De gebruiker erkent expliciet dat CamDiab onder geen beding aansprakelijk is voor het handelen of de nalatigheid van andere gebruikers of voor schade die daaruit voortvloeit.
                                </p>
                                <p>
                                    7.2.5 Indien er door derden aanspraken worden gemaakt ten aanzien van CamDiab, is de gebruiker verplicht om direct, oprecht en volledig alle beschikbare informatie te overhandigen, voor zover die nodig is voor het beoordelen, aanvechten en voorkomen van claims van derden. De gebruiker draagt de kosten van het mogelijk noodzakelijke juridisch verweer van CamDiab, inclusief alle griffiekosten en juridische kosten, ter hoogte van de wettelijk geldende bedragen.
                                </p>
                                <h2>
                                    8. SLOTBEPALINGEN
                                </h2>
                                <h3>
                                    8.1. Bevoegde rechtbank
                                </h3>
                                <p>
                                    Op deze algemene voorwaarden en de volledige relatie tussen CamDiab en de gebruiker is uitsluitend Engels recht van toepassing, met uitzondering van de bepalingen waaraan wordt gerefereerd en van het VN-verdrag inzake internationale koopovereenkomsten van roerende zaken. Als consument geniet de gebruiker echter bescherming op grond van verplichte bepalingen in het land waar gebruiker is of doorgaans woonachtig is.
                                </p>
                                <h3>
                                    8.2. Verantwoordelijke rechtbank
                                </h3>
                                <p>
                                    De plaats van voltrekking en enige verantwoordelijke rechtbank voor alle geschillen die voortkomen uit deze algemene voorwaarden is de rechtbank in de vestigingsplaats van het hoofdkantoor van CamDiab in Cambridge, Verenigd Koninkrijk. Dit is niet van invloed op conflicterende wettelijke bepalingen in het voordeel van consumenten.
                                </p>
                                <h3>
                                    8.3. Overige
                                </h3>
                                <p>
                                    8.3.1 Indien op grond van deze algemene voorwaarden een melding of de schriftelijke vorm noodzakelijk is (waaronder voor wettelijk belangrijke verklaringen), is overdracht per e-mail aan het adres dat bij aanmelding is opgegeven (CamDiab-identificatie) voldoende.
                                </p>
                                <p>
                                    8.3.2 Gebruikers zijn niet gerechtigd om hun CamDiab-identificatie of hun rechten en verplichtingen op grond van deze algemene voorwaarden voor het gebruik van onze producten aan derden over te dragen. CamDiab kan de eigen rechten en verplichtingen op grond van deze algemene voorwaarden overdragen op een ander bedrijf of een derde, mits die overdracht geen significante gevolgen heeft voor de rechten van de gebruiker op grond van deze algemene voorwaarden.
                                </p>
                                <p>
                                    8.3.3 Als afzonderlijke bepalingen van deze algemene voorwaarden nietig zijn of worden, dan blijven de andere bepalingen van deze algemene voorwaarden daarbij onverminderd van kracht.
                                </p>
                                <p>
                                    8.3.4 Deze algemene voorwaarden worden ter beschikking gesteld in verschillende talen. De versie in de taal van het land waar de consument gevestigd is of doorgaans woonachtig is, is doorslaggevend en bepalend. Indien er geen landspecifieke taalversie beschikbaar is, geldt de Engelse versie als bepalend.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer props={this.props}/>
            </div>
        );
    }
}

