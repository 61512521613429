/**
 * Texts for header
 */
export const headerText = {
    cookiesIntroText: 'Utilizziamo i cookie semplicemente per tenere traccia delle visite sul nostro sito web. Non salviamo dati personali.',
    cookiesAccept: 'Accetta i cookie',
    cookiesDeny: 'Rifiuta i cookie',
    tabFAQs: 'FAQ',
    tabSupport: 'Assistenza',
    tabHistory: 'Storia',
    tabReviews: 'Testimonianze',
    tabTraining: 'Addestramento',
    // tabOrders: 'Ordini',
    tabOrders: '',
    tabNotifications: 'Notifiche',
};
