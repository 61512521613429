/**
 * Texts for Training page
 */
export const trainingPageText = {
    title: 'Schulung - CamAPS FX',
    titleMeta: 'Wir bieten kostenlose Online-Schulungen für CamAPS FX-Benutzer',
    descriptionMeta: 'Das Diabetes-Ausbildungsprogramm von Cambridge bietet eine kostenlose ' +
        'zertifizierte Online-Schulung für Menschen mit Typ-1-Diabetes, Eltern und Erziehungsberechtigte, Schulpersonal und medizinisches Fachpersonal.',
    keywords:
        'camaps schulung, camaps fx training,  camaps training, camaps fx schulung,  ' +
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ' +
        '',
    localeMeta: 'de_de',

    trainingHeader: 'Zertifiziertes Training',
    trainingHeader_2: 'Für Anwender, medizinisches Fachpersonal und Schulungspersonal',
    trainingHeaderText: 'KOSTENLOSES online CamAPS FX Training des Cambridge Diabetes Education Programme (CDEP).',
    websiteLink: 'https://de.camdiabtraining.com',
    websiteLinkHeading: 'CDEP Training',
    getTrained: 'Zum Training',
};

