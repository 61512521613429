/**
 * Texts for Notifications page
 */
export const notificationsPageText = {
    title: 'De siste i-app-varslerne',
    titleMeta: 'Viser nylige varsler fra CamAPS FX i appen',
    descriptionMeta: 'Her finner du teksten til de siste i-app-varslingene for appoppdateringer, sikkerhetsvarsler og oppdateringer av brukermanualen.',
    keywords: 'Varsler' ,
    localeMeta: 'nb_no',

    heading: 'De siste i-app-varslerne',
    subheading: '',

    header_27:'',
    body_27:'',

    header_26:'',
    body_26:'',

    header_25:'',
    body_25:'',

    header_24:'',
    body_24:'',

    header_23:'',
    body_23:'',

	header_22:'',
    body_22:'',
	
	header_21:'',
    body_21:'',
	
	header_20:'',
    body_20:'',
	
	header_19:'',
    body_19:'',

    header_18:'',
    body_18:'',

    header_17:'',
    body_17:'',

    header_16:'',
    body_16:'',

    header_15:'',
    body_15:'',

    header_14:'',
    body_14:'',

    header_13:'',
    body_13:'',
    body_13_link_text:'',
    body_13_end: '',
    body_13_link:'',

    header_12:'',
    body_12:'',

    header_11:'',
    body_11:'',

    header_10:'',
    body_10:'',

    header_9:'',
    body_9:'',

    header_8:'',
    body_8:'',

    header_7:'',
    body_7:'',

    header_6:'',
    body_6:'',

    header_5:'',
    body_5:'',

    header_4:'',
    body_4:'',

    header_3:'',
    body_3:'',

    header_2:'',
    body_2:'',

    header_1:'',
    body_1:'',
};
