/**
 * Common texts - what is CamAPS FX
 */
export const whatIsCamAPSFX = {

    linkFindOutMore: 'Zjistěte více',
    body:
        '<h2>Co je CamAPS FX?</h2> <p> CamAPS FX je aplikace pro Android k řízení hladiny glukózy u lidí s diabetem 1. typu pomocí pokročilé adaptivní hybridní uzavřené smyčky. </p>'
};

/**
 * Common texts - what is CamAPS FX
 */
export const userReviewsIntro = {
    linkMoreReviews: 'Další hodnocení',
    readMore: 'Přečtěte si více',
    header:
        'Hodnocení od uživatelů CamAPS FX',
    text:
        'Skutečné příběhy skutečných uživatelů aplikace CamAPS FX'
};