/**
 * Texts for Reviews page
 */
export const reviewsPage = {
    title: 'Testimonianze - CamAPS FX',
    titleMeta: 'Leggi le testimonianze degli utilizzatori di CamAPS FX',
    descriptionMeta: 'CamAPS FX cambia la vita di molti utilizzatori perché assicura un controllo della glicemia senza eguali e riduce l’onere della gestione del diabete sia per i pazienti che per le loro famiglie.',
    keywords:
        'camaps testimonianze, camaps fx testimonianze, ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'it',

    shortReview1:
        '<h2>Desideriamo semplicemente farvi sapere quanto è migliorata la vita di nostra figlia e la nostra stessa vita dk quando nostra figlia ha ricevuto il sistema a ciclo chiuso CamAPS FX. </h2><p><span>Genitori di una teenager</span></p>',

    shortReview2:
        '<p> È impossibile esprimere a parole quanto siano cambiate le nostre vite. Come per magia, tutti i problemi svaniscono, ad es. lo sfinimento dovuto alle notti insonni, al controllo costante durante il giorno. Non è affatto esagerato affermare che CamAPS FX ci ha ridato la vita. È vero che dobbiamo continuare a conteggiare i carboidrati, erogare boli di insulina 10 minuti prima dei pasti e cambiare la cannula del microinfusore, ma è tutto qui. CamAPS FX ci dà la libertà di dimenticarci del diabete per la maggior parte del giorno.</p> <p> <span>David, padre di una bambina di 9 anni</span> </p>',
    shortReview3:
        '<h2>Grazie! La scorsa notte ho dormito meravigliosamente bene, come non facevo dk anni.</h2><p><span>Utilizzatore adulto ultra 60enne</span></p>',

    shortReview4:
        '<p> La vita di nostro figlio è stata influenzata sotto tutti i punti di vista: nostro figlio ha evidenziato un significativo miglioramento nello sviluppo della deambulazione e del linguaggio. C’è stata un’enorme differenza rispetto agli ultimi mesi. Mio figlio è diventato più sicuro, meno collerico e generalmente più felice.</p> <p> <span>Madre di un bambino piccolo</span> </p>',


};
