/**
 * Texts for History page
 */
export const historyPageText = {
    title: 'CamDiabs resa',
    titleMeta: 'Klinisk forskning på vägen mot CamAPS FX',
    descriptionMeta: 'En tidslinje över hur teamet bakom CamAPS FX nådde fram till Cambridge-algoritmen som hjälper människor med diabetes.',
    localeMeta: 'sv_se',

    keywords:
        'camaps historia, camaps fx historia, ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    journeyHeading: 'Vår CamDiab-resa',
    timelineHeading: 'En tidslinje över hur teamet bakom CamAPS FX nådde fram till Cambridge-algoritmen som hjälper människor med diabetes.',
    year2006_2010dates: '2006–2010',
    year2006_2010heading: 'Tidiga prövningar på klinik',
    year2006_2010text: 'Ett projekt med konstgjord bukspottkörtel inleds vid Cambridge med prövningar som omfattar barn med diabetes typ 1. Går med i JDRF Artificial Pancreas Consortium. Cambridge-algoritmen stresstestas efter fysisk aktivitet och alkoholkonsumtion hos vuxna med diabetes typ 1. Cambridge-algoritmen utvärderas hos gravida kvinnor med diabetes typ 1.',
    year2011_2014dates: '2011–2014',
    year2011_2014heading: 'Prövningar av enbart nattlig användning och funktionalitet vid en fri livsstil',
    year2011_2014text: 'Nattlig användning av Cambridge-algoritmen utvärderas av barn, vuxna och gravida kvinnor med diabetes typ 1. Cambridge-algoritmen används dygnet runt under sju dagar av vuxna med diabetes typ 1.',
    year2015_2016dates: '2015–2016',
    year2015_2016heading: 'Korta prövningar av användning dygnet runt och funktionalitet vid en fri livsstil',
    year2015_2016text: 'Cambridge-algoritmen används under fyra veckor av vuxna med välkontrollerad diabetes typ 1. Cambridge-algoritmen används under tre veckor av ungdomar med diabetes typ 1.',
    year2017_2018dates: '2017–2018',
    year2017_2018heading: 'Längre prövningar av användning dygnet runt och användning hos barn',
    year2017_2018text: 'Cambridge-algoritmen används dygnet runt under tre månader av barn och vuxna med diabetes typ 1. Cambridge-algoritmen används under två år av barn och ungdomar från debut av diabetes. Cambridge-algoritmen används av barn i åldern ett till sju år med diabetes typ 1.',
    year2019_2019dates: '2019',
    year2019_2019heading: 'Övergång till CamAPS FX ',
    year2019_2019text: 'CamAPS FX används av äldre vuxna med diabetes typ 1. CamAPS FX används av gravida kvinnor med diabetes typ 1 under hela graviditeten. CamAPS FX används av barn i åldern ett till sju år med diabetes typ 1.',
    year2020_2020dates: '2020 och framåt',
    year2020_2020heading: 'Regulatoriskt godkännande, kommersialisering, kliniska prövningar',
    year2020_2020text: 'CamAPS FX-appen CE-märks för användning i EU och Storbritannien. Forskningen fortsätter för att utvärdera fördelarna och ge stöd för kostnadsersättning.',
};
