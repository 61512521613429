/**
 * Texts for footer
 */
export const footerText = {
    txtFAQs: 'Často kladené otázky',
    txtSupport: 'Podpora',
    txtHistory: 'Historie',
    txtTrainingHtml: 'https://www.camdiabtraining.com/account-sign-in.html',
    txtTraining: 'Online školení',
    txtClinic: 'Klinika CamAPS FX',
    terms: 'Podmínky',
    privacy: 'Zásady ochrany osobních údajů',
    txtFooterBottom: 'CamAPS je registrovaná ochranná známka společnosti CamDiab Ltd. Ostatní ochranné známky jsou vlastnictvím příslušných vlastníků. Bez předchozího písemného souhlasu společnosti CamDiab nesmí být na těchto stránkách použita žádná ochranná známka, obchodní název ani charakteristické vizuální prvky společnosti CamDiab, s výjimkou označení produktů nebo služeb společnosti. Obrázky produktů jsou pouze pro ilustraci.',
    rightsReserved: 'CamDiab Ltd. Všechna práva vyhrazena.',

    statusPageFooterText: 'Stavová stránka',
    statusPageFooterLink: 'https://camdiabltd.statuspage.io/',
};
