import React, {Component} from 'react';
import {Header} from '../Components/Header';
import {Footer} from '../Components/Footer';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

export class TermsDE extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Geschäftsbedingungen - CamAPS FX</title>
                    <meta name="title" content='Geschäftsbedingungen'/>
                    <meta name="description"
                          content='Hier finden Sie die Nutzungsbedingungen von CamDiab'/>
                    <meta property="og:locale" content='de'/>
                    <meta name="keywords" content='Geschäftsbedingungen'/>
                </Helmet>
                <Header props={this.props}/>
                <section className="inner-banner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-10">
                                <h1>Allgemeinen Geschäftsbedingungen</h1>
                                <h3>Version vom 24. Juni 2024</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <h2>
                                    1. GELTUNGSBEREICH
                                </h2>
                                <h3>
                                    1.1. Unsere Produkte
                                </h3>
                                <p>
                                    1.1.1. Wir
                                </p>
                                <p>
                                    CamDiab Ltd <strong>
                                    <br/>
                                    Eingetragene Geschäftsadresse<br/>
                                    20-22 Wenlock Road<br/>
                                    London, N1 7GU, Vereinigtes Königreich
                                    <br/>
                                </strong>
                                    support@camdiab.com
                                    <br/>
                                    Unternehmensnummer 11659211 England und Wales
                                    <br/>
                                    <br/>
                                </p>
                                <p>
                                    („<strong>CamDiab</strong>“), entwickeln und betreiben mobile Anwendungen (Apps) für die Closed-Loop-Insulinversorgung und die zugehörige Dateninfrastruktur für Personen mit Diabetes und medizinisches Fachpersonal. CamDiab ist ein digitales Gesundheitsunternehmen, das Personen mit Diabetes eine bessere Blutzuckerkontrolle ermöglichen und das selbstständige Diabetesmanagement erleichtern möchte.
                                </p>
                                <p>
                                    1.1.2 Unsere Produkte steuern und unterstützen die Closed-Loop-Insulinversorgung nach bestem Wissen. Unsere Produkte und Leistungen können das Gespräch mit medizinischem Fachpersonal und die Diagnostik durch medizinisches Fachpersonal jedoch nicht ersetzen. Bei Fragen zur Erkrankung und Therapie empfehlen wir, sich an medizinisches Fachpersonal zu wenden. Die bereitgestellten Daten dürfen nur von Ihnen und dem Sie behandelnden medizinischen Fachpersonal ausgewertet werden. Die Inhalte – unabhängig davon, ob sie von CamDiab selbst oder von unseren Kooperationspartnern, Händlern, Auftragnehmern oder Benutzern bereitgestellt wurden – ersetzen oder ergänzen den Rat von medizinischem Fachpersonal oder Apothekern in keinster Weise.
                                </p>
                                <p>
                                    1.1.3 CamDiab bietet eine Reihe von Produkten (Apps) und Leistungen an und ermöglicht den Austausch von Daten, um Sie beim Diabetesmanagement zu unterstützen. Alle aktuellen und künftigen Apps und Leistungen (einschließlich neuer Versionen, Updates und sonstiger Verbesserungen), die CamDiab in irgendeiner Weise bereitstellt, werden nachfolgend gemeinsam als „<strong>Produkte</strong>“ und einzeln als „<strong>Produkt</strong>“ bezeichnet. Alle Personen, die eines oder mehrere unserer Produkte erwerben oder nutzen, werden gemeinsam und einzeln als „<strong>Benutzer</strong>“ bezeichnet. Wenn in diesen AGB unter Nutzung der männlichen Form Bezug auf natürliche Personen genommen wird, sind damit alle Personen gleichermaßen gemeint.
                                </p>
                                <p>
                                    1.1.4 Im Zusammenhang mit diesen AGB gelten die folgenden Begriffsdefinitionen für einzelne Produkte:
                                </p>
                                <p>
                                    <strong>CamAPS FX</strong> ist eine eigenständige mobile Anwendung (App) für Ihr Smartphone, die Personen mit Typ-1-Diabetes eine Hybrid-Closed-Loop-Insulinversorgung ermöglicht. Die App verbindet sich mit einem Glukosesensor eines Drittanbieters und einer Insulinpumpe eines Drittanbieters. Optional können Sie von der App gesammelte Daten für CamDiab oder Diabetesportale von Drittanbietern freigeben, um die Ansicht der Daten zu ermöglichen. Je nach Drittanbieter-Pumpe und ‑Glukosesensor gelten eventuell Einschränkungen hinsichtlich des Android-Smartphone-Modells, das genutzt werden kann.
                                </p>
                                <p>
                                    <strong>CamAPS HX</strong> ist eine ähnliche App wie CamAPS FX, ermöglibietet jedoch einen vollständig geschlossenen Regelkreis bei Nicht-Typ-1-Diabetes.
                                </p>
                                <p>
                                    1.1.5 In unseren Benutzerhandbüchern (über unsere Apps oder unsere Website abrufbar) und den jeweiligen besonderen Bestimmungen sind Besonderheiten der einzelnen Produkte hervorgehoben.
                                </p>
                                <h3>
                                    1.2. Geltungsbereich unserer AGB
                                </h3>
                                <p>
                                    1.2.1 Wir bieten unsere Produkte ausschließlich auf Grundlage der aktuellen Version dieser Allgemeinen Geschäftsbedingungen (AGB) an. Dies gilt weltweit. CamDiab widerspricht anderslautenden Geschäftsbedingungen oder sonstigen Nutzungsbedingungen ausdrücklich.
                                </p>
                                <p>
                                    1.2.2 Bitte beachten Sie, dass Sie vor der erstmaligen Nutzung unserer Produkte allen genannten Erklärungen und Vereinbarungen zustimmen und diese im Laufe einer weiteren Nutzung akzeptieren müssen. Ohne Zustimmung zu unseren AGB sind die Registrierung und die Nutzung unserer Produkte untersagt.
                                </p>
                                <p>
                                    1.2.3 Wir behalten uns ausdrücklich das Recht vor, in Zukunft Veränderungen und sachlich gerechtfertigte Anpassungen unserer AGB vorzunehmen. Veränderungen können erforderlich sein, um gesetzliche Vorschriften zu erfüllen, technischen und wirtschaftlichen Anforderungen zu entsprechen oder die Interessen unserer Benutzer zu berücksichtigen. Entsprechende Veränderungen sind zu jeder Zeit möglich und werden in angemessener Weise veröffentlicht. Wird ihnen nicht innerhalb eines Monats widersprochen, gelten für die weitere Nutzung unserer Produkte die jeweiligen neuen AGB.
                                </p>
                                <h3>
                                    1.3. Drittanbieter
                                </h3>
                                <p>
                                    1.3.1 Unsere Produkte sind in den App Stores zu finden. Generell gelten dort die Geschäftsbedingungen des jeweiligen Drittanbieters (auf die wir keinen Einfluss haben); diese sind weder Teil unserer AGB noch unseres Vertragsverhältnisses mit Ihnen als dem Benutzer unserer Produkte. Dies gilt auch für die Geschäftsbedingungen des Anbieters Ihres mobilen Geräts oder Ihres Mobilfunkanbieters.
                                </p>
                                <p>
                                    1.3.2 Wir arbeiten mit verschiedenen Partnern zusammen, um eine optimale Nutzung unserer Produkte zu gewährleisten. Dazu zählen Kooperationspartner, die unseren Benutzern direkt bestimmte Produkte bereitstellen (z. B. Insulinpumpe, kontinuierliche Glukosemessung). In diesen Fällen verweisen wir auf die geltenden Geschäftsbedingungen des Drittanbieters, da Sie diesen Geschäftsbedingungen zustimmen müssen, bevor Sie mit der Nutzung unserer Produkte beginnen können.
                                </p>
                                <h2>
                                    2. DATENSCHUTZ
                                </h2>
                                <p>
                                    <strong> Wir sind uns der großen Verantwortung bewusst, die mit Ihrer Nutzung unserer Produkte zusammenhängt. Sie stellen uns nicht nur allgemeine personenbezogene Daten zur Verfügung, sondern auch Daten über Ihre Gesundheit. In unserer </strong>
                                    <Link to="/de/privacy">
                                        Datenschutzerklärung </Link> <strong> informieren wir Sie über die Verarbeitung und den Schutz Ihrer Daten im Zusammenhang mit der Nutzung unserer Produkte sowie über Ihre Einwilligung, die dafür erforderlich ist. </strong>
                                </p>
                                <p>
                                    <strong> OHNE IHRE EINWILLIGUNG IN DIE ERFORDERLICHE DATENVERARBEITUNG IST DIE NUTZUNG DER PRODUKTE RECHTLICH UND SACHLICH NICHT MÖGLICH. </strong>
                                </p>
                                <p>
                                    <strong>Unsere </strong>
                                    <Link to="/de/privacy"> Datenschutzerklärung </Link> <strong> ist nicht Teil dieser AGB, sondern dient allein dazu, Ihnen gemäß DSGVO Informationen zur Verfügung zu stellen, auf deren Grundlage Sie Ihre Einwilligung in die erforderliche Datenverarbeitung erteilen. </strong>
                                </p>
                                <h2>
                                    3. VERTRAGSABSCHLUSS UND REGISTRIERUNG
                                </h2>
                                <h3>
                                    3.1. Kauf und Bezahlung unserer Produkte
                                </h3>
                                <p>
                                    3.1.1 Unsere Produkte können auf verschiedenen Wegen erworben werden:
                                </p>
                                <h3>
                                    Apps
                                </h3>
                                <p>
                                    <strong>App Stores</strong> (wie der Google Play und Amazon Appstore) bieten unsere Produkte an. Bitte beachten Sie, dass für den Download (nicht die Nutzung) unserer Produkte die gesonderten Geschäftsbedingungen des App Stores gelten und dass dieser eventuell ein Benutzerkonto erfordert.
                                </p>
                                <p>
                                    <strong>Unsere Website</strong> bietet eventuell direkten Zugang zu unseren Produkten und ermöglicht die Bestellung unserer Produkte.
                                </p>
                                <p>
                                    <strong>Gutscheincodes</strong> für unsere Produkte können im Umlauf sein und auf unserer Website eingelöst werden.
                                </p>
                                <p>
                                    3.1.2 Sie können unsere kostenpflichtigen Produkte auf unserer Website oder über unsere Vertriebspartner bestellen. Sie werden über einen Zahlungsdienstleister, unsere Händler oder andere vereinbarte Zahlungsmöglichkeiten bezahlt. Der Händler ist entweder als Vertreter und Zahlungsdienstleister für CamDiab oder als direkter Verkäufer tätig. Falls Sie Probleme beim Download oder der Bezahlung haben, können Sie sich jederzeit an <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a> wenden.
                                </p>
                                <p>
                                    3.1.3 Nutzungsgebühren oder Kaufpreise (inklusive gesetzlicher Mehrwertsteuer) müssen – je nach Angebot und Wahl – einmal oder regelmäßig (z. B. monatlich, halbjährlich oder jährlich) bezahlt werden. Die aktuell geltenden Gebühren sind auf unserer Website aufgeführt, werden vom Händler genannt oder in unserer App oder den App Stores angezeigt und sind im Voraus zu entrichten. Wenn die Zahlung nicht direkt über den App Store abgewickelt wird, kann der Zahlungsdienstleister oder Händler Ihnen per E-Mail Rechnungen und Zahlungserinnerungen zusenden. Im Fall eines schuldhaften Zahlungsverzugs oder einer schuldhaften unvollständigen Zahlung sind wir berechtigt, Ihren Zugang zu unseren Produkten zu sperren. Ihre Pflicht zur Zahlung der Nutzungsgebühr bleibt davon unberührt.
                                </p>
                                <p>
                                    3.1.4 CamDiab kann auch Sonderangebote und kostenfreie Angebote unterbreiten, für die zusätzliche Bedingungen oder Beschränkungen gelten. In diesem Fall verweisen wir ausdrücklich auf eventuelle Abweichungen von diesen AGB, die ansonsten vollumfänglich gültig bleiben. Sonderangebote und kostenfreie Angebote sind nicht auf andere Benutzer übertragbar.
                                </p>
                                <h3>
                                    3.2. Registrierung und Aktivierung
                                </h3>
                                <p>
                                    3.2.1 Um unsere Produkte nutzen zu können, ist eine Registrierung bei CamDiab über unsere Apps nötig. Direkt nach der erstmaligen Aktivierung unseres Produkts müssen Sie sich mit einer E-Mail-Adresse und einem Passwort registrieren (CamDiab-ID). Jeder Benutzer kann nur eine CamDiab-ID erstellen und jede CamDiab-ID kann nur von einem Benutzer verwendet werden.
                                </p>
                                <p>
                                    3.2.2 Mit seiner Registrierung unterbreitet der Benutzer CamDiab ein verbindliches Angebot zum Abschluss eines Vertrages über die Nutzung unserer Produkte auf Grundlage der geltenden Version dieser AGB und akzeptiert dieses. Wir nehmen dieses Angebot ausdrücklich an, spätestens durch Aktivierung Ihrer CamDiab-ID.
                                </p>
                                <p>
                                    3.2.3 Die Aktivierung wird bestätigt, sobald Ihre Anmeldedaten akzeptiert wurden und Sie die entsprechende Schulung absolviert haben. Wir behalten uns das Recht vor, einzelne Benutzer oder ihr Angebot zum Abschluss eines Vertrages ohne Angabe von Gründen abzulehnen. In solch einem Fall werden bereits geleistete Zahlungen erstattet und bereitgestellte Benutzerdaten werden umgehend gelöscht.
                                </p>
                                <p>
                                    3.2.4 Mit der Registrierung bestätigt der Benutzer, dass er mindestens sechzehn Jahre alt ist und es ihm nach geltendem Recht gestattet ist, Verträge abzuschließen. Nur geschäftsfähige Personen dürfen sich registrieren. Minderjährige sind nur mit dem ausdrücklichen Einverständnis eines Elternteils/Vormunds zugelassen. Durch die Registrierung erklärt der Elternteil/Vormund, dass er befugt ist, im Namen des Minderjährigen rechtsverbindliche Erklärungen abzugeben.
                                </p>
                                <p>
                                    3.2.5 Mit der Registrierung bestätigt der Benutzer, dass er den Inhalt dieser AGB zur Kenntnis genommen hat und diesem uneingeschränkt zustimmt und dass seine Angaben wahrheitsgemäß, richtig, aktuell und vollständig sind. Wir behalten uns das Recht vor, Benutzer zu einem beliebigen Zeitpunkt zu kontaktieren, um Registrierungsdaten und Nutzungsinformationen zu überprüfen.
                                </p>
                                <p>
                                    3.2.6 Macht der Benutzer falsche, unrichtige, nicht mehr aktuelle oder unvollständige Angaben oder haben wir Grund zu der Annahme, dass Angaben falsch, unrichtig, nicht mehr aktuell oder unvollständig sind, ist CamDiab berechtigt, die entsprechende CamDiab-ID unangekündigt und mit sofortiger Wirkung zu sperren und die Nutzung unserer Produkte zu untersagen, ohne dass sich daraus eine Verpflichtung ergibt, den Benutzer für die entstandenen Kosten zu entschädigen.
                                </p>
                                <p>
                                    3.2.7 Der Benutzer muss seine Registrierungsdaten vor unbefugtem Zugriff Dritter, Missbrauch oder Nutzung in betrügerischer Absicht schützen. Wenn auch nur der Verdacht besteht, dass die CamDiab-ID einem entsprechenden Risiko ausgesetzt war, muss dies unverzüglich an <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a> gemeldet werden. Wir haben das Recht, die CamDiab-ID eines jeden Benutzers zu sperren, falls diese in rechtswidriger oder betrügerischer Absicht verwendet wird.
                                </p>
                                <h2>
                                    4. RÜCKTRITTS- UND WIDERRUFSRECHT
                                </h2>
                                <h3>
                                    4.1. Widerrufsbelehrung
                                </h3>
                                <p>
                                    4.1.1 Wenn Sie unsere Produkte weder zu gewerblichen noch zu beruflichen Zwecken nutzen, haben Sie als Verbraucher das Recht, den mit CamDiab geschlossenen Vertrag unter folgenden Bedingungen zu widerrufen:
                                </p>
                                <p>
                                    <strong>WIDERRUFSRECHT</strong>
                                </p>
                                <p>
                                    <strong><u>Widerrufsrecht</u></strong>
                                </p>
                                <p>
                                    <strong> Sie haben das Recht, diesen Vertrag ohne Angabe von Gründen binnen vierzehn Tagen zu widerrufen. </strong>
                                </p>
                                <p>
                                    <strong> Die Widerrufsfrist beträgt vierzehn Tage ab Datum des Vertragsabschlusses (Aktivierung digitaler Inhalte). </strong>
                                </p>
                                <p>
                                    <strong> Um Ihr Widerrufsrecht auszuüben, müssen Sie den Händler oder CamDiab, E-Mail-Adresse </strong> <strong> <a href="mailto:support@camdiab.com">support@camdiab.com</a> </strong> <strong>, mittels einer eindeutigen Erklärung (z. B. per Post oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. </strong>
                                </p>
                                <p>
                                    <strong> Zur Wahrung der Widerrufsfrist reicht es aus, die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist abzusenden. </strong>
                                </p>
                                <p>
                                    <strong><u>Folgen des Widerrufs</u></strong>
                                </p>
                                <p>
                                    <strong> Wenn Sie diesen Vertrag widerrufen, haben der Händler oder wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrages bei uns eingegangen ist. Für diese Rückzahlung verwenden wir nach Möglichkeit dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet. </strong>
                                </p>
                                <h2>
                                    5. NUTZUNG UNSERER PRODUKTE
                                </h2>
                                <h3>
                                    5.1. Nutzungsdauer und Kündigung
                                </h3>
                                <p>
                                    5.1.1 Ihr Recht zur Nutzung unserer Produkte beginnt mit Ihrer Registrierung und der Aktivierung durch CamDiab.
                                </p>
                                <p>
                                    5.1.2 Unsere kostenpflichtigen Produkte können in Form von monatlichen, halbjährlichen oder jährlichen Abonnements erworben werden; je nach aktuellem Angebot sind sie auch für eine kürzere oder längere Nutzungsdauer (nachfolgend „<strong>Abonnementlaufzeit</strong>“) erhältlich.
                                </p>
                                <p>
                                    5.1.3 Sowohl der Benutzer als auch CamDiab haben jederzeit das Recht, den Vertrag ordentlich unter Wahrung einer Frist von einem Monat zum Monatsende zu kündigen. Sie können dies tun, indem Sie sich an Ihren Händler wenden oder uns Ihren Kündigungswunsch schriftlich unter <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a> übermitteln. Bitte beachten Sie, dass bereits gezahlte Gebühren für eine nicht genutzte Abonnementlaufzeit nur im Fall einer ordentlichen Kündigung durch CamDiab oder einer automatischen Beendigung des Vertrages (Abschnitt 5.1.5 unten) zurückerstattet werden.
                                </p>
                                <p>
                                    5.1.4 Erfolgt keine oder keine fristgerechte Kündigung, verlängert sich die Abonnementlaufzeit automatisch um einen weiteren Zeitraum, der der zuvor gewählten Abonnementlaufzeit entspricht. Dabei gilt der zum Zeitpunkt der Verlängerung geltende Standardpreis (nicht die Preise von Sonderangeboten oder kostenfreien Angeboten).
                                </p>
                                <p>
                                    5.1.5 Im Fall des Todes des Benutzers oder des Widerrufs der Einwilligung zur Datenverarbeitung endet das Nutzungsverhältnis automatisch mit sofortiger Wirkung. CamDiab ist es nicht gestattet, Ihre Gesundheitsdaten ohne Ihre Einwilligung zu verarbeiten.
                                </p>
                                <p>
                                    5.1.6 Sollten Sie Fragen zu Ihrer Abonnementlaufzeit oder deren Beendigung haben, können Sie sich jederzeit an <a href="mailto:support@camdiab.com">support@camdiab.com</a> wenden.
                                </p>
                                <h3>
                                    5.2. Sperrung und Ausschluss
                                </h3>
                                <p>
                                    5.2.1 Wir behalten uns das Recht vor, Benutzer jederzeit aus wichtigem Grund (nach unserem Ermessen) vorübergehend zu sperren oder dauerhaft von der Nutzung unserer Produkte auszuschließen. Im Fall einer solchen fristlosen Vertragsbeendigung ist eine Rückerstattung von Nutzungsgebühren ausgeschlossen.
                                </p>
                                <p>
                                    5.2.2 Ein triftiger Grund ist insbesondere bei schwerwiegenden Verstößen eines Benutzers gegen die Bestimmungen dieser AGB gegeben, z. B. Verstößen gegen Abschnitt 3.1 (Zahlung) nach Einräumung einer Nachfrist von zwei Wochen, Abschnitt 3.2 (Registrierungsdaten), Abschnitt 5.3 (Lizenz) oder Abschnitt 6 („Ihr Verhalten“).
                                </p>
                                <h3>
                                    5.3. Lizenz und Gewährung von Rechten
                                </h3>
                                <p>
                                    5.3.1 Durch Ihre Registrierung und die Aktivierung erhalten Sie das nichtexklusive, nicht übertragbare, aber geografisch uneingeschränkte Recht zur Speicherung und Nutzung einer Kopie unserer App zu Ihren eigenen Zwecken im Rahmen dieser AGB. Bei kostenpflichtigen Produkten ist die Lizenz auf die Dauer Ihrer vertraglichen Abonnementlaufzeit beschränkt.
                                </p>
                                <p>
                                    5.3.2 Die Software, der Quellcode, die Methoden und Systeme sowie die Inhalte unserer Produkte sind durch das Urheber- und Wettbewerbsrecht geschützt und dürfen ausschließlich von CamDiab genutzt werden. Ohne unser vorheriges schriftliches Einverständnis dürfen unsere Produkte oder Teile davon nicht kopiert, verändert, reproduziert, neu veröffentlicht, im Internet veröffentlicht, übertragen, verkauft, zum Verkauf angeboten, weiterverkauft oder in irgendeiner sonstigen Weise verwendet werden. Benutzern ist es nicht gestattet, Marken, Logos, sonstige gewerbliche Eigentumsrechte oder Schutzrechte von CamDiab zu verwenden. Sofern in diesen AGB nicht anders angegeben, sind sämtliche Nutzungs- und Verwertungsrechte das ausschließliche Eigentum von CamDiab und es wird keinerlei Lizenz für unsere Produkte gewährt.
                                </p>
                                <p>
                                    5.3.3 Wenn es zur technischen Bereitstellung unserer Produkte erforderlich ist, gewährt jeder Benutzer CamDiab ein nichtexklusives, widerrufliches, aber kostenfreies, übertragbares sowie zeitlich und räumlich uneingeschränktes Verwertungs- und Nutzungsrecht an den von ihm in unseren Produkten erzeugten, übermittelten, gespeicherten oder veröffentlichten Inhalten. Die Nutzung oder Verwertung ist jedoch ausgeschlossen, wenn sie den berechtigten Interessen (z. B. den Datenschutzrechten) des Benutzers in unverhältnismäßiger Weise entgegensteht. Im Fall einer Nutzung außerhalb unserer Produkte werden wir gegebenenfalls darauf hinweisen, dass der Inhalt vom Benutzer stammt. CamDiab erhebt keine Eigentumsansprüche auf erstellte Inhalte und übernimmt keine überwachende Funktion im Hinblick auf von Benutzern erstellte Inhalte.
                                </p>
                                <h3>
                                    5.4. Verfügbarkeit unserer Produkte
                                </h3>
                                <p>
                                    5.4.1 Wir stellen unsere Produkte jeweils nach den gegebenen technischen, wirtschaftlichen, betrieblichen und organisatorischen Möglichkeiten bereit. CamDiab kann Unterbrechungen, Störungen, Verzögerungen, Löschungen, fehlerhafte Übertragungen oder Speicherfehler im Zusammenhang mit der Nutzung unserer Produkte oder der Kommunikation mit Benutzern nicht ausschließen. Wir bieten unsere Produkte zum Teil in Zusammenarbeit mit Drittanbietern und ‑dienstleistern an und sind somit auch auf die technische Bereitstellung externer Leistungen angewiesen. Deshalb übernimmt CamDiab keine Verantwortung, Garantie, Haftung oder Verpflichtung, unsere Produkte jederzeit und ohne Unterbrechungen online zur Verfügung zu stellen.
                                </p>
                                <p>
                                    5.4.2 Dies gilt auch für Einschränkungen der Nutzung unserer Produkte aufgrund von höherer Gewalt, Streik, Aussperrung und amtlichen Weisungen oder aufgrund technischer Änderungen oder Wartungsarbeiten an den Systemen von CamDiab. Bei Störungen oder Ausfällen unserer Produkte können Sie sich jederzeit an <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a> wenden.
                                </p>
                                <h2>
                                    6. IHR VERHALTEN
                                </h2>
                                <h3>
                                    6.1. Allgemeine Informationen
                                </h3>
                                <p>
                                    6.1.1 Jeder Benutzer ist berechtigt und verpflichtet, unsere Produkte auf eigenes Risiko und eigene Kosten sowie unter Einsatz geeigneter technischer Ausstattung zu nutzen. Welche Geräte und Betriebssysteme mit den Produkten kompatibel sind, kann jederzeit unter <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a> erfragt werden.
                                </p>
                                <p>
                                    6.1.2 Sie sind darüber hinaus verpflichtet, unsere Produkte ausschließlich in Übereinstimmung mit diesen AGB und den gesetzlichen Vorschriften zu nutzen und jegliche unrechtmäßige Nutzung zu vermeiden. Bitte informieren Sie uns unverzüglich unter <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>, falls Sie Verletzungen der in diesem Abschnitt genannten Pflichten feststellen.
                                </p>
                                <h3>
                                    6.2. Missbrauch
                                </h3>
                                <p>
                                    6.2.1 Jeder Benutzer ist verpflichtet, bei der Registrierung wahrheitsgemäße Daten anzugeben, diese aktuell und vollständig zu halten und sie nicht an Dritte weiterzugeben. Es ist in eigener Verantwortung für eine vertrauliche Behandlung und die Sicherung personenbezogener Daten sowie einen sorgfältigen Umgang mit diesen zu sorgen. CamDiab übernimmt keine Haftung oder sonstigen Garantien für abhanden gekommene oder beschädigte Daten oder Inhalte, es sei denn, wir haben dies zu verschulden.
                                </p>
                                <p>
                                    6.2.2 Inhalte wie Fotos, Bilder, Texte, Videos oder sonstige Darstellungen dürfen in Zusammenhang mit unseren Produkten nur gespeichert, veröffentlicht, übertragen oder weitergegeben werden, wenn der Benutzer das Recht zu deren Übertragung oder Nutzung hat. In jedem Fall ist die Verwendung rassistischer, anstößiger, diskriminierender, verleumderischer, sexueller, pornografischer, gewalttätiger oder sonstiger illegaler Inhalte jeglicher Art stets untersagt.
                                </p>
                                <p>
                                    6.2.3 Zudem ist es untersagt, unsere Produkte zu entschlüsseln, durch Reverse Engineering zu rekonstruieren, zu dekompilieren oder zu disassemblieren oder unsere Produkte auf gerooteten Smartphones zu nutzen. Jeder Benutzer ist verpflichtet, störende Eingriffe in unsere Produkte mit technischen oder elektronischen Mitteln (insbesondere Hacking-Versuche, Brute-Force-Angriffe, Einschleusen von Viren, Würmern, Trojanern oder sonstiger Malware) sowie jegliche Art versuchter Störungen, die die Software oder Hardware der Produkte und Systeme von CamDiab beeinträchtigen könnten, zu unterlassen. Jegliche Manipulation unserer Produkte kann dazu führen, dass unsere Produkte nicht mehr bestimmungsgemäß funktionieren.
                                </p>
                                <h2>
                                    7. UNSERE PFLICHTEN
                                </h2>
                                <h3>
                                    7.1. Garantie und Haftung
                                </h3>
                                <p>
                                    7.1.1 Sofern in diesen AGB nicht anders angegeben, übernimmt CamDiab die gesetzlich vorgeschriebene Haftung und Garantie.
                                </p>
                                <p>
                                    7.1.2 In Bezug auf Benutzer, bei denen es sich nicht um Verbraucher handelt, ist die Haftung für finanzielle Schäden, Folgeschäden, entgangenen Gewinn sowie Schäden infolge von Ansprüchen Dritter ausgeschlossen. Wenn unsere Haftung gemäß diesen AGB beschränkt oder ausgeschlossen ist, gilt dies gleichermaßen auch für Erfüllungsgehilfen und verbundene Unternehmen von CamDiab.
                                </p>
                                <p>
                                    7.1.3 Außerhalb des Anwendungsbereichs der Produkthaftungsgesetze ist die Haftung von CamDiab auf Vorsatz und grobe Fahrlässigkeit beschränkt – Personenschäden ausgenommen. Die Haftung für leichte Fahrlässigkeit ist ausgeschlossen, wenn entsprechende objektive Gründe gegeben sind.
                                </p>
                                <p>
                                    7.1.4 CamDiab gewährt keine Garantie, dass unsere Produkte vollumfänglich, ohne Unterbrechung und fehlerfrei zur Verfügung stehen oder dass die erforderliche Software oder Hardware fehlerfrei funktioniert. Ebenso wenig können wir die Möglichkeit ausschließen, dass Daten bei der Datenübermittlung über Drittsysteme, insbesondere das Internet und andere Telekommunikationsnetze, von Dritten nachverfolgt, aufgezeichnet oder verfälscht werden.
                                </p>
                                <p>
                                    7.1.5 CamDiab übernimmt keine Garantie für heruntergeladene Inhalte oder Materialien, die Benutzer infolge der Nutzung unserer Produkte erhalten haben. Der Benutzer haftet allein für jegliche Schäden an seinem IT-System oder seinen Geräten sowie für Datenverluste, die infolge des Herunterladens von Materialien in Zusammenhang mit unseren Produkten entstehen könnten.
                                </p>
                                <p>
                                    7.1.6 Darüber hinaus haftet CamDiab nicht für Inhalte Dritter wie etwa externe Links, Banner, sonstige Informationen oder Werbeangebote Dritter, die im Rahmen unserer Produkte angezeigt werden können. Falls wir den Zugang zu Angeboten Dritter über Benachrichtigungen oder Links ermöglichen, übernimmt CamDiab keine Verantwortung für die darin enthaltenen Informationen. Sich daraus ergebende Rechtsgeschäfte mit Dritten begründen ausschließlich Vertragsverhältnisse zwischen dem Benutzer und dem jeweiligen Dritten. Wir übernehmen keine Garantie oder sonstige Haftung für die Leistungen Dritter.
                                </p>
                                <h3>
                                    7.2. Haftungsausschluss und Entschädigung
                                </h3>
                                <p>
                                    7.2.1 Die Nutzung unserer Produkte ersetzt nicht das Gespräch mit medizinischem Fachpersonal oder eine sonstige medizinische Beratung und erfolgt ausschließlich auf eigenes Risiko des Benutzers. Dies gilt für jegliche Nutzung der Daten, die im Rahmen unserer Produkte vom Benutzer zur Verfügung gestellt werden, darunter Blutzucker- und Sensor-Glukose-Ergebnisse, Berechnungen und jegliche Empfehlungen. Der Benutzer erkennt ausdrücklich an, dass solche Daten fehlerhaft sein können, und CamDiab übernimmt keine Haftung für ihre Korrektheit.
                                </p>
                                <p>
                                    7.2.2 Soweit dies nach geltendem Medizinproduktegesetz, das die Nutzung der Produkte regelt, vorgeschrieben ist, dürfen die Produkte ausschließlich für den Zweck, gemäß den Spezifikationen und für die Anwendungsgebiete betrieben oder genutzt werden, die im Angebot und den Nutzungsbedingungen beschrieben sind.
                                </p>
                                <p>
                                    7.2.3 Der Benutzer hält CamDiab gegenüber sämtlichen Ansprüchen Dritter schadlos, die infolge der unrechtmäßigen Verletzung der Rechte von CamDiab durch den Benutzer im Zusammenhang mit der Nutzung unserer Produkte gegen CamDiab entstehen. Alle sonstigen Schadenersatzansprüche von CamDiab gegen den Benutzer bleiben unberührt.
                                </p>
                                <p>
                                    7.2.4 Der Benutzer übernimmt die vollumfängliche Haftung für sämtliche Schäden und gerichtlichen sowie außergerichtlichen Streitigkeiten, die infolge von Auseinandersetzungen mit anderen Benutzern entstehen. Der Benutzer erkennt ausdrücklich an, dass CamDiab unter keinen Umständen für Handlungen oder Unterlassungen anderer Benutzer und etwaige daraus resultierende Schäden verantwortlich ist.
                                </p>
                                <p>
                                    7.2.5 Sollten Dritte Ansprüche gegen CamDiab geltend machen, ist der Benutzer verpflichtet, alle ihm zur Verfügung stehenden Informationen, die zur Prüfung, Verteidigung und Verhinderung der Ansprüche Dritter nötig sind, unverzüglich wahrheitsgemäß und vollständig bereitzustellen. Der Benutzer trägt die Kosten einer eventuell erforderlichen Rechtsverteidigung von CamDiab, einschließlich sämtlicher Gerichts- und Anwaltskosten in der gesetzlich vorgesehenen Höhe.
                                </p>
                                <h2>
                                    8. SCHLUSSBESTIMMUNGEN
                                </h2>
                                <h3>
                                    8.1. Geltendes Recht
                                </h3>
                                <p>
                                    Diese AGB und das gesamte Rechtsverhältnis zwischen CamDiab und dem Benutzer unterliegen ausschließlich dem englischen Recht unter Ausschluss von dessen Verweisungsnormen und dem UN-Kaufrecht. Als Verbraucher genießt der Benutzer jedoch den Schutz durch die zwingenden Rechtsvorschriften des Landes, in dem er seinen Wohnsitz oder gewöhnlichen Aufenthalt hat.
                                </p>
                                <h3>
                                    8.2. Gerichtsstand
                                </h3>
                                <p>
                                    Als Erfüllungsort und alleiniger Gerichtsstand für sämtliche Streitigkeiten, die sich aus diesen AGB ergeben, wird der Firmensitz von CamDiab in Cambridge, Vereinigtes Königreich, vereinbart. Zwingende Rechtsvorschriften des internationalen Privatrechts zugunsten von Verbrauchern bleiben unberührt.
                                </p>
                                <h3>
                                    8.3. Sonstiges
                                </h3>
                                <p>
                                    8.3.1 Wenn gemäß diesen AGB eine Mitteilung oder die Schriftform vorgeschrieben ist (etwa für rechtlich bedeutsame Erklärungen), ist die Übertragung per E-Mail an die bei der Registrierung angegebene Adresse (CamDiab-ID) ausreichend.
                                </p>
                                <p>
                                    8.3.2 Benutzer sind nicht berechtigt, ihre CamDiab-ID oder Rechte und Pflichten, die sich im Zusammenhang mit der Nutzung unserer Produkte aus diesen AGB ergeben, an Dritte abzutreten. CamDiab darf seine Rechte und Pflichten aus diesen AGB an ein anderes Unternehmen oder einen Dritten abtreten, wenn eine solche Abtretung keine wesentlichen Auswirkungen auf die Rechte des Benutzers aus diesen AGB hat.
                                </p>
                                <p>
                                    8.3.3 Sollten einzelne Bestimmungen dieser AGB unwirksam sein oder werden, bleibt die Wirksamkeit und Anwendbarkeit der übrigen, nicht von der Unwirksamkeit betroffenen Bestimmungen dieser AGB davon unberührt.
                                </p>
                                <p>
                                    8.3.4 Diese AGB werden in verschiedenen Sprachen zur Verfügung gestellt. Die Version in der Sprache des Landes, in dem der Verbraucher seinen Wohnsitz oder gewöhnlichen Aufenthalt hat, ist entscheidend und maßgeblich. Falls und soweit keine landesspezifische Sprachversion zur Verfügung steht, ist die englische Version maßgeblich.
                                </p>

                            </div>
                        </div>
                    </div>
                </section>
                <Footer props={this.props}/>
            </div>
        );
    }
}

