/**
 * Texts for Reviews page
 */
export const reviewsPage = {
    title: 'Anmeldelser – CamAPS FX',
    titleMeta: 'Les anmeldelser fra CamAPS FX brukere',
    descriptionMeta: 'CamAPS FX forandrer livet til mange brukere ved å tilby uovertruffen glukosekontroll og redusere belastningen av å ha diabetes for brukere og familiene deres.',
    keywords:
        'camaps reviews, camaps fx reviews, ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'nb_no',

    shortReview1:
        '<h2>Vi ville bare fortelle hvor mye bedre tilværelsen har blitt for både oss og datteren vår etter at hun fikk CamAPS FX closed loop-systemet. </h2><p><span>Foreldre til en tenåringsjente</span></p>',

    shortReview2:
        '<p> Det går ikke an å overvurdere hvor mye det har påvirket livene våre. Som ved et trylleslag er nesten alle problemene borte, for eksempel trøttheten på grunn av urolige netter og konstante kontroller i løpet av dagen. Det er ingen overdrivelse å si at CamAPS FX har gitt oss livene våre tilbake. Ja, vi må fortsatt telle karbohydrater, og ja, vi gir bolus 10 minutter før måltider, og ja, vi må skifte pumpekanyle, men det er alt. CamAPS FX gir oss friheten til å slippe å tenke på diabetes det meste av dagen.</p> <p> <span>David, far til en 9 år gammel jente</span> </p>',
    shortReview3:
        '<h2>Takk. I natt sov jeg bedre enn jeg har gjort på lenge.</h2><p><span>Voksen bruker 60+</span></p>',

    shortReview4:
        '<p> Vi har merket en stor innvirkning på livet til sønnen vår. Han har blitt mye flinkere til å gå og å snakke. Det har utgjort en enorm forskjell på de siste månedene. Sønnen min har fått mye større selvtillit, er mindre sint og generelt gladere.</p> <p> <span>Moren til en liten gutt</span> </p>',


};
