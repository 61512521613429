/**
 * Texts for History page
 */
export const historyPageText = {
    title: 'CamDiab reise',
    titleMeta: 'Klinisk forskning på veien til CamAPS FX',
    descriptionMeta: 'En tidslinje som viser hvordan teamet bak CamAPS FX kom fram til å hjelpe mennesker med diabetes med Cambridge-algoritmen vår.',
    localeMeta: 'nb_no',

    keywords:
        'camaps history, camaps fx history, ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    journeyHeading: 'Vår CamDiab reise',
    timelineHeading: 'En tidslinje som viser hvordan teamet bak CamAPS FX kom fram til å hjelpe mennesker med diabetes med Cambridge-algoritmen vår.',
    year2006_2010dates: '2006–2010',
    year2006_2010heading: 'Tidlige forsøk på klinikk',
    year2006_2010text: 'Et prosjekt med kunstig bukspyttkjertel innledet i Cambridge med forsøk som omfattet barn med diabetes type 1. Ble med i JDRF Artificial Pancreas Consortium. Cambridge-algoritme stresstestet etter mosjon og alkoholforbruk hos voksne med diabetes type 1. Cambridge-algoritme evaluert hos gravide kvinner med diabetes type 1.',
    year2011_2014dates: '2011–2014',
    year2011_2014heading: 'Forsøk av utelukkende bruk om natten samt funksjonalitet ved en fri livsstil',
    year2011_2014text: 'Nattlig bruk av Cambridge-algoritmen evaluert av barn, voksne og gravide kvinner med diabetes type 1. Cambridge-algoritmen brukt dag og natt over sju dager av voksne med diabetes type 1.',
    year2015_2016dates: '2015–2016',
    year2015_2016heading: 'Korte forsøk av bruk døgnet rundt og funksjonalitet ved en fri livsstil',
    year2015_2016text: 'Cambridge-algoritmen brukt i fire uker av voksne med velkontrollert diabetes type 1. Cambridge-algoritmen brukes i tre uker av ungdommer med diabetes type 1.',
    year2017_2018dates: '2017–2018',
    year2017_2018heading: 'Lengre forsøk med bruk døgnet rundt og bruk hos barn',
    year2017_2018text: 'Cambridge-algoritmen brukt i tre måneder av barn og voksne med diabetes type 1. Cambridge-algoritmen brukt i to år av barn og ungdommer med debut av diabetes. Cambridge-algoritmen brukt av barn i alderen ett til sju år med diabetes type 1.',
    year2019_2019dates: '2019',
    year2019_2019heading: 'Overgang til CamAPS FX ',
    year2019_2019text: 'CamAPS FX appen brukt av eldre voksne med diabetes type 1. CamAPS FX appen brukt under hele graviditeten til gravide kvinner med diabetes type 1. CamAPS FX appen brukt av barn fra ett til sju år med diabetes type 1.',
    year2020_2020dates: '2020 og framover',
    year2020_2020heading: 'Myndighetsgodkjennelse, kommersialisering og kliniske forsøk',
    year2020_2020text: 'CamAPS FX appen CE-merket for bruk i EU og Storbritannia. Forskningen fortsetter for å vurdere fordelene og gi støtte til kostnadserstatning.',
};
