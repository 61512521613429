/**
 * Texts for footer
 */
export const footerText = {
    txtFAQs: 'Veelgestelde vragen',
    txtSupport: 'Ondersteuning',
    txtHistory: 'Geschiedenis',
    txtTrainingHtml: 'https://www.camdiabtraining.com/account-sign-in.html',
    txtTraining: 'Online-training',
    txtClinic: 'CamAPS FX-kliniek',
    terms: 'Algemene voorwaarden',
    privacy: 'Gegevensbeschermingsbeleid',
    txtFooterBottom: 'CamAPS is een geregistreerd handelsmerk van CamDiab Ltd. Andere handelsmerken zĳn eigendom van de respectievelĳke eigenaars. Het gebruik van de merken, handelsmerken of vormgeving van CamDiab van deze website is alleen toegestaan met voorafgaande toestemming van CamDiab, tenzij het gaat om de identificatie van de producten zelf of van de diensten die het bedrijf aanbiedt. De afbeeldingen van de producten dienen alleen ter illustratie.',
    rightsReserved: 'CamDiab Ltd. Alle rechten voorbehouden.',

    statusPageFooterText: 'Statuspagina',
    statusPageFooterLink: 'https://camdiabltd.statuspage.io/',
};
