const tab = '    ';
/**
 * Texts for Order page and modal popups
 */
export const orderPageText = {
    title: 'Commande - CamDiab',
    titleMeta: 'Obtenez CamAPS FX - CamDiab',
    descriptionMeta: '\n' +
        'Payez aussi peu que 70 £ par mois pour CamAPS FX. Obtenez un mois d’essai gratuit. ' +
        'Nécessite une pompe à insuline Dana compatible et un capteur de glucose Dexcom G6',
    localeMeta: 'fr_fr',

    keywords:
        'camaps coût, camaps fx coût, camaps ordre, camaps fx ordre, camaps fx essai gratuit' +
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ' +
        '',
    // discount code processing messages
    pleaseWait: 'Veuillez patienter',
    successResult: 'Réussite',
    errorResult: 'Erreur de traitement',
    discountApplied: 'Le code de réduction a bien été appliqué.',
    okButtonText: 'OK',

    // Discount line
    discountHeading: 'Réduction ',
    discountOver: ' pendant', // This reads: Discount: 30% over 3 months
    discountOneMonth: 'mois',
    discountTwoAndMoreMonths: 'mois',

    // interest in CamAPS FX related messages
    processingEnquiry: 'Votre demande est en cours de traitement',
    thankYouForSubmission: '<Merci d’avoir renseigné vos coordonnées',
    processingError: 'Erreur de traitement',
    responseAck: 'Nous allons examiner votre demande et nous reviendrons vers vous si CamAPS FX devient disponible dans votre pays',

    stage1: 'Etape 1',
    subscriptionOption: 'Options d’abonnement',
    stage2: 'Etape 2',
    equipment: 'Equipement',
    stage3: 'Etape 3',
    personalInfo: 'Informations personnelles',
    stage4: 'Etape 4',
    orderConfirmation: 'Confirmation de la commande',

    currencySubscription: '£',
    vatExempt: '(Exonération de la TVA)',


    costMonthly: 80,
    costSixMonthly: 450,
    costAnnual: 840,

    costMonthlyVAT: 96,
    costSixMonthlyVAT: 540,
    costAnnualVAT: 1008,


    subscriptionMonthly: 'Abonnement mensuel',
    subscriptionSixMonthly: 'Abonnement de six mois',
    subscriptionAnnual: 'Abonnement annuel',

    interested: 'Vous envisagez d’acheter CamAPS&nbsp;FX&nbsp;?',
    interestedPara: 'Utilisez le code de réduction <b>CamAPS30</b> pour obtenir ' +
        'une période d’essai de 30 jours entièrement gratuite et sans engagement.',

    subscriptionProcess: 'Notre méthode d’abonnement',
    choosePackage: 'Choisissez votre formule d’abonnement',
    answerQuestions: 'Fournissez quelques renseignements personnels',
    receiveTraining: 'Recevez une formation en ligne certifiée',

    // below are texts for API
    botCheckFailed: 'Echec de la vérification. Veuillez réessayer ultérieurement ou contactez nous à support@camdiab.com',
    wrongDiscountCode: 'Code de réduction erroné ou périmé',
    pumpAlreadyExists: 'Impossible de finaliser votre commande. Une pompe avec le numéro de série %s est déjà en cours d’utilisation. ' +
        'Veuillez vérifier le numéro de série de votre pompe ou contactez-nous à sales@camdiab.com.',
    costTextWithDiscount: '%s%s dans la première période (code de réduction %s), puis %s%s pour les périodes suivantes',
    emailOrderSubjectLine: 'Votre commande CamAPS FX',
    emailTrainingSubjectLine: 'Formation CamAPS FX',
    emailInterestedSubjectLine: 'Demande de renseignements CamAPS FX (%s)',

    emailVATexemptYes: 'Oui',
    emailVATexemptNo: 'Non',

    emailOrderBody: "Cher(e) %s," +
        "<p>" +
        "Nous sommes ravis de confirmer votre commande de l’application CamAPS FX. Les informations " +
        "de votre commande sont les suivantes :" +
        "<p>" +
        tab + "Nom : %s %s<br>" +
        tab + "E-mail de contact : %s<br>" +
        tab + "Numéro de série de la pompe : %s<br>" +
        tab + "Période d’abonnement : %s<br>" +
        tab + "%s<br>" +
        tab + "Exonération de TVA : %s<br>" +
        tab + "Service de formation en diabétologie : %s<br>" +
        "<p>" +
        "<strong>Etapes suivantes</strong><br>" +
        "Vous pouvez soit suivre la <a href=\"https://fr.camdiabtraining.com\">formation en ligne CamAPS FX</a>, " +
        "soit c’est en personne que votre formateur en diabétologie vous montrera comment utiliser l’application. " +
        "Vous pouvez télécharger l’application CamAPS FX depuis l’Appstore Amazon,  " +
        "mais vous ne pourrez pas l’utiliser tant que vous n’aurez pas suivi la formation et que votre formateur en diabétologie " +
        "ne l’aura pas débloquée en saisissant son code pour la formation certifiée CamAPS FX. " +
        " Après la formation, vous pourrez commencer à utiliser l’application CamAPS FX immédiatement." +
        "<p>" +
        "<strong>Paiement</strong><br>" +
        "Une fois que l’application est débloquée et que vous pouvez commencer à l’utiliser, nous vous enverrons une facture par e-mail. Nous préférons les règlements par prélèvement " +
        "automatique. Les paiements à l’échéance prévue sont nécessaires " +
        "pour continuer à utiliser l’application CamAPS FX. Vos droits statutaires " +
        "ne sont pas affectés. Vous pouvez annuler votre commande à tout moment avant la formation, ou " +
        "dans les 14 jours qui la suivent, pour obtenir le remboursement intégral de vos paiements." +
        "<p>" +
        "<strong>Période d’abonnement</strong><br>" +
        "Votre période d’abonnement démarre le jour de votre formation. Veuillez noter " +
        "qu’aucun paiement n’est effectué avant que la formation n’ait eu lieu. Après la première période " +
        "d’abonnement, les paiements seront requis pour chaque période " +
        "d’abonnement suivante, à moins que vous ayez annulé votre commande par écrit à %s." +
        "<p>" +
        "Merci pour votre achat. Nous espérons que l’application CamAPS FX vous satisfera " +
        "pleinement." +
        "<p>" +
        "L’équipe CamDiab<br>" +
        "%s",


    emailPotentialUser: "Cher(e) %s %s," +
        "<p>" +
        "Nous vous remercions de l’intérêt que vous portez à l’application CamAPS FX. Cependant, vous nous avez indiqué que " +
        "vous n’avez pas la possibilité d’obtenir une formation pour l’application CamAPS FX dans votre service de diabétologie. " +
        "Toutefois, nous pourrions organiser une formation à vos frais. Les renseignements que vous nous avez fournis sont les suivants :" +
        "<p>" +
        tab + "Nom : %s %s<br>" +
        tab + "E-mail de contact : %s<br>" +
        "<p>" +
        "<strong>Etapes suivantes</strong><br>" +
        "Nous allons transmettre votre demande aux formateurs pour CamAPS FX avec lesquels nous collaborons et qui sont susceptibles de vous aider. " +
        "Si tel est le cas, ils vous contacteront eux-mêmes par e-mail. Une fois que vous aurez convenu avec votre formateur des dispositions pour la formation, " +
        "vous devrez envoyer la commande de CamAPS FX sur <a href=\"https://camdiab.com/\">camdiab.com</a>." +
        "<p>" +
        "<strong>Paiement de la formation</strong><br>" +
        "Il vous faudra convenir avec votre formateur pour CamAPS FX du tarif de la formation et la lui payer " +
        "séparément. Ces services ne sont pas fournis par CamDiab. " +
        "<p>" +
        "<strong>Formateurs pour CamAPS</strong><br>" +
        "Nous accordons de l’importance à nos formateurs pour CamAPS FX et ils ont tous suivi une formation appropriée." +
        "<p>" +
        "Merci de l’intérêt que vous portez à l’application CamAPS FX." +
        "<p>" +
        "L’équipe CamDiab<br>" +
        "%s",

    emailInterestedUser: "Cher(e) %s %s," +
        "<p>" +
        "Merci de l’intérêt que vous portez à l’application CamAPS FX. Nous en avons pris connaissance et " +
        "nous vous préviendrons si CamAPS FX devient disponible dans votre pays (%s). " +
        "Vos coordonnées :" +
        "<p>" +
        tab + "Nom : %s %s<br>" +
        tab + "E-mail de contact : %s<br>" +
        tab + "Pays : %s<br>" +
        "<p>" +
        "Merci de l’intérêt que vous portez à l’application CamAPS FX." +
        "<p>" +
        "L’équipe CamDiab<br>" +
        "%s",

    emailOrderBody_1: 'Merci de vous être enregistré  pour l’utilisation de l’application CamAPS FX',
    emailOrderBody_2: 'Cher(e)  %s',
    emailOrderBody_3: 'Nous sommes ravis de confirmer votre commande de l’application CamAPS FX. ' +
        'Les détails de votre commande sont les suivants :',
    emailOrderBody_4: 'Nom :',
    emailOrderBody_5: 'E-mail de contact :',
    emailOrderBody_6: 'Numéro de série de la pompe :',
    emailOrderBody_7: 'Période d’abonnement :',
    emailOrderBody_8: 'Tarif :',
    emailOrderBody_9: 'Exonération de TVA :',
    emailOrderBody_10: 'Service de formation en diabétologie:',
    emailOrderBody_11: 'Etapes suivantes',
    emailOrderBody_12: 'Nous vous enverrons une facture <b>après</b> que vous ayez commencé à utiliser l’application CamAPS FX.',
    emailOrderBody_13: 'Nous vous recommandons de suivre la formation pour CamAPS FX en ligne.',
    emailOrderBody_14: 'Sinon, votre formateur en diabétologie vous montrera en personne comment utiliser l’application.',
    emailOrderBody_15: 'Vous pouvez télécharger l’application CamAPS FX depuis l’Appstore Amazon, mais vous ne pourrez pas ' +
        'l’utiliser tant que vous n’aurez pas suivi la formation et que votre formateur en diabétologie ou vous-même n’aurez pas débloqué l’application en saisissant ' +
        'son/votre code de formation certifiée CamAPS FX. ',
    emailOrderBody_16: 'Une fois l’application débloquée, vous pourrez commencer à l’utiliser immédiatement.',
    emailOrderBody_17: 'Paiement',
    emailOrderBody_18: 'Après la formation, nous vous enverrons une facture par e-mail. Les paiements à l’échéance prévue sont nécessaires ' +
        'pour continuer à utiliser l’application CamAPS FX. Vos droits statutaires ne sont pas affectés. ' +
        'Vous pouvez annuler votre commande à tout moment avant la formation, ou dans les 30 jours suivant celle-ci pour obtenir ' +
        'le remboursement intégral de vos paiements.',
    emailOrderBody_19: 'Période d’abonnement',
    emailOrderBody_20: 'Votre période d’abonnement démarre le jour de votre formation. Veuillez noter qu’aucun ' +
        'paiement n’est effectué avant que la formation n’ait eu lieu. Après la première période d’abonnement, ' +
        'les paiements seront requis pour chaque période d’abonnement suivante, à moins que vous ayez annulé votre ' +
        'commande par écrit à sales@camdiab.com.',
    emailOrderBody_21: 'Merci de votre abonnement à l’application CamAPS FX',
    emailOrderBody_22: 'Pour une assistance technique, veuillez consulter notre site web pour plus d’informations.',
    emailOrderBody_23: '© 2022 CamDiab Ltd. Tous droits réservés.',


    // Texts for step one form
    s1MonthlyHeading: 'Abonnement mensuel',
    s1MonthlyValue: '80 £*',
    s1Select: 'Sélectionner',
    s1HalfYearlyHeading: 'Abonnement de 6 mois',
    s1HalfYearlyText: '450 £*',
    s1HalfYearlyTextSaving: '(économie de 30 £)',
    s1YearlyHeading: 'Abonnement annuel',
    s1YearlyText: '840 £*',
    s1YearlyTextSaving: '(économie de 120 £)',
    s1VATtext: ' *Plus la TVA, sauf en cas d’exonération. Payable en totalité après la formation / l’activation de l’application.',

    // Texts for step two form
    previous: 'Précédent',
    next: 'Suivant',
    s2Yes: 'Oui',
    s2No: 'Non',
    s2HaveDanaPump: 'Possédez-vous une pompe à insuline mylife YpsoPump, DANA Diabecare RS ou DANA-i&nbsp;?',
    s2HaveCgm: 'Disposez-vous du système de mesure du glucose en continu Dexcom G6&nbsp;?',
    s2Phone1: 'Avez-vous un téléphone Android',
    s2PhoneHtml: 'https://www.dexcom.com/dexcom-international-compatibility',
    s2Phone2: 'compatible',
    s2Phone3: '&nbsp;?',
    s2NoPump: 'Afin d’utiliser l’application CamAPS FX, vous devez employer une pompe à insuline mylife YpsoPump, DANA Diabecare RS ou DANA-i. ' +
        'Veuillez consulter votre équipe de diabétologie locale et discuter avec elle de vos options, ou contacter votre distributeur Dana local.',
    s2NoCgm: 'Afin d’utiliser l’application CamAPS FX, vous devez disposer du système de mesure du glucose en continu Dexcom G6. Veuillez consulter ' +
        'votre équipe de diabétologie locale au sujet du système de mesure du glucose en continu Dexcom G6, ou contacter Dexcom.',
    s2NoPhone: 'Si vous n’utilisez pas un téléphone Android compatible, nous ne pouvons pas garantir la fiabilité de la connexion ' +
        'à votre capteur Dexcom G6. Veuillez envisager de vous procurer un téléphone Android compatible.',


    // Texts for step three form
    s3InfoSource: [
        'Mon service de diabétologie',
        'Moteur de recherche',
        'Réseaux sociaux',
        'Le message d’un ami sur les réseaux sociaux',
        'Radio',
        'Télévision',
        'Presse',
        'Bouche-à-oreille',
        'Autres'],

    // s3TeritoryQuestion: 'Are you based in the UK, the EU, or New Zealand?',
    s3TeritoryQuestion: 'Etes-vous au Royaume-Uni ou dans l’Union Européenne&nbsp;?',
    s3NotAvailable: 'Malheureusement, l’application CamAPS FX n’est disponible que dans ' +
        'certains pays. Veuillez nous indiquer vos coordonnées si vous ' +
        'souhaitez que nous vous contactions quand le système deviendra disponible dans votre pays.',
    s3SelectCountry: 'Sélectionnez votre pays',
    s3TC1: 'En nous soumettant votre demande, vous acceptez nos',
    s3TC2: 'Conditions générales',
    s3TC3: 'et notre',
    s3TC4: 'Politique de confidentialité',
    s3TC5: 'et vous nous autorisez également à vous contacter.',
    s3Training1: 'Vous devez suivre la formation d’un formateur certifié et/ou suivre une',
    s3Training2: 'formation en ligne',
    s3Training3: 'avant de pouvoir commencer à utiliser l’application CamAPS FX.',

    s3FirstName: 'Prénom',
    s3LastName: 'Nom de famille',
    s3Email: 'Adresse e-mail',
    s3EmailConfirm: 'Veuillez confirmer votre adresse e-mail',
    s3AddressLine1: 'Adresse (ligne 1)',
    s3AddressLine2: 'Adresse (ligne 2)',
    s3AddressLine3: 'Adresse (ligne 3}',
    s3PostCode: 'Code postal',
    s3SubmitCountry: 'Envoyer',
    s3DisabilityHeading: 'Je déclare présenter la maladie chronique ou l’invalidité suivante :',
    s3Disability: 'Maladie chronique ou invalidité',
    s3signature: 'Signature (saisissez votre nom)',

    s3TrainingCdep1: 'Sélectionnez la formation CamAPS en ligne pour continuer :',
    s3TrainingCdep2: 'Sélectionnez  la formation',
    s3TrainingCdep3: 'Formation CamAPS en ligne',

    s3contactHeading: 'Veuillez saisir vos coordonnées',
    s3whereHeard: 'Où avez-vous entendu parler de l’application CamAPS&nbsp;FX&nbsp;?',
    s3whereHeardSelect: 'Veuillez sélectionner un des choix suivants',
    s3pumpSerialNumber: 'Numéro de série de votre pompe Dana :',
    s3pumpSerialNumberConfirm: 'Veuillez confirmer le numéro de série de votre pompe Dana :',
    s3pumpSerialNumberMessage: 'Important :',
    s3pumpSerialNumberMessage1: 'Le numéro de série de la pompe est nécessaire pour autoriser l’application ' +
        'CamAPS FX à se connecter à votre pompe. Un numéro de série erroné peut entraîner l’impossibilité de connecter ' +
        'l’application CamAPS FX à votre pompe. Vous trouverez le numéro de série sur le côté de votre pompe. ' +
        'Pour exemple, le numéro de série se présente sous la forme THF00041FB.',
    s3vatExempt: 'Etes-vous exonéré(e) de la TVA&nbsp;?',
    s3vatHelp1: 'Vous pouvez trouver plus d’informations sur le site du Bulletin Officiel des Finances Publiques, ',
    s3vatHelpHtml: 'https://www.gov.uk/government/organisations/hm-revenue-customs/contact/vat-reliefs-for-disabled-and-older-people',
    s3vatHelpLine: 'Conseil exonéré de TVA',
    s3vatHelp2: 'le site GOV.UK',
    s3vatHelp3: 'ou en téléphonant à la ligne d’assistance TVA aux personnes handicapées au +44 300 123 1073 (en anglais uniquement).',
    s3vatHelp4: 'Le personnel du HMRC (fisc britannique) ne peut pas dire si une personne est ou non malade chronique ou handicapée.',
    s3vatHelp5: 'Important :',
    s3vatHelp6: 'Si vous commandez l’application CamAPS FX au nom d’une tierce personne, veuillez remplir le formulaire d’exonération de TVA en utilisant ses coordonnées.',
    s3PhoneWarning: 'Si vous n’utilisez pas un téléphone Android compatible, nous ne pouvons pas garantir la fiabilité de ' +
        'la connexion avec votre capteur Dexcom G6. Veuillez envisager de vous procurer un téléphone Android compatible',

    s3NameMissing: 'Le prénom doit être saisi',
    s3SurnameRequired: 'Le nom de famille doit être saisi',
    s3EmailRequired: 'L’adresse e-mail doit être saisie',
    s3CountryRequired: 'Le pays doit être indiqué',
    s3EmailConfimrRequired: 'La confirmation de l’adresse e-mail est nécessaire',
    s3EmailMatchRequired: 'Les adresses e-mail doivent être identiques',
    s3ClinicRequired: 'Le service de formation en diabétologie doit être indiqué',
    s3DiseaseStateRequired: 'La nature des besoins de l’utilisateur doit être indiquée',
    s3OnlyTextValueProvided: 'Ce champ ne doit contenir que du texte ',
    s3OnlyNumberValueProvided: 'Ce champ ne doit contenir que des chiffes',
    s3SerialNumbersMatch: 'Les numéros de série doivent être identiques',
    s3vatStatusRequired: 'Le statut de la TVA doit être indiqué',
    s3AddressLine1Required: 'La ligne 1 de l’adresse est nécessaire',
    s3AddressLine2Required: 'La ligne 2 de l’adresse est nécessaire',
    s3PostCodeRequired: 'Le code postal doit être indiqué',
    s3SickessRequired: 'Une maladie chronique ou une invalidité doit être indiquée',
    s3SignatureRequired: 'La signature doit être saisie',

    // Texts for step four form
    s4NextSteps: 'Etapes suivantes',
    s4DownloadApp: 'Vous pouvez télécharger l’application CamAPS FX depuis l’',
    s4DownloadGuideHtml: 'https://s3-eu-west-1.amazonaws.com/camdiab.user.manual/Downloading+CamAPS+FX+FR.pdf',
    s4DownloadAppGuide: 'Guide d’installation',
    s4Appstore: 'Appstore Amazon',
    s4DownloadApp1: 'mais vous ne pourrez pas l’utiliser tant que vous n’avez pas',
    s4TrainingTextLink: 'suivi la formation',
    s4TrainingHtml: 'https://camdiab.com/fr-ch/training',
    s4TrainingTitle: 'Formation',

    s4Payment: 'Les paiements sont effectués lors de la connexion de l’application CamAPS FX avec votre pompe.',

    s4TermsAccept: 'J’accepte les',
    s4TermsTermsHtml: 'https://camdiab.com/terms',
    s4TermsTermsText: 'Conditions générales',
    s4TermsPrivacyHtml: 'https://camdiab.com/privacy',
    s4TermsPrivacyText: 'Politique de confidentialité',

    s4DiscountCodeText: 'Code de réduction (optionnel)',
    s4DiscountApplyCode: 'Appliquer le code',

    s4Summary: 'Résumé de la commande',
    s4Name: 'Nom :',
    s4Email: 'E-mail :',
    s4PumpSN: 'N° de série de la pompe :',
    s4Training: 'Formation :',
    s4Total: 'Total',

    s4CompleteButtonText: 'Terminer',

    // duration or subscription period in an email
    emailOneMonth: '1 mois',
    emailSixMonths: '6 mois',
    emailTwelveMonths: '1 an',

    // Order complete
    oderProcessedHeading: 'Votre commande est en cours de traitement',
    oderProcessedSucess: 'Merci, votre commande est passée avec succès',
    oderProcessedError: 'Erreur de traitement',
    oderProcessedEmailToBeSent: 'Vous allez recevoir un e-mail de confirmation sous peu',
};
