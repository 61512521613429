/**
 * Texts for Reviews page
 */
export const reviewsPage = {
    title: 'Avis - CamAPS FX',
    titleMeta: 'Lire les avis des utilisateurs de CamAPS FX',
    descriptionMeta: 'CamAPS FX change la vie de nombreux utilisateurs, offrant un contrôle de la glycémie sans ' +
        'précédent et réduisant le fardeau du diabète pour les utilisateurs et leurs familles.',
    keywords:
        'camaps Avis, camaps fx Commentaires, ' +
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ' +
        '',
    localeMeta: 'fr_fr',

    shortReview1:
        '<h2>Nous voudrions juste vous dire à quel point la vie a changé pour notre fille et pour nous aussi, ' +
        'depuis qu’elle utilise le système en boucle fermée CamAPS FX. </h2>' +
        '<p>' +
        '    <span>Les parents d’une adolescente</span>' +
        '</p>',

    shortReview2:
        '<p> ' +
        '   On ne dira jamais assez à quel point ça nous a changé la vie. Comme par magie, ' +
        '   la fatigue due aux réveils en pleine nuit, les vérifications continuelles dans la journée, ' +
        '   tous ces problèmes ont quasiment disparu. Je n’exagère pas quand je dis ' +
        '   que CamAPS FX nous permet de revivre. Oui, nous sommes toujours obligés de compter les glucides, ' +
        '   oui, on fait un bolus 10 minutes avant les repas, et oui, on doit toujours changer la canule de la pompe, mais c’est tout. ' +
        '   Grâce à CamAPS FX, nous pouvons oublier le diabète toute la journée ou presque, en toute liberté.' +
        '</p> ' +
        '    <p> ' +
        '        <span>David, père d’une petit fille de 9 ans</span> ' +
        '    </p>',
    shortReview3:
        '<h2>Merci. La nuit dernière, ça a été la meilleure nuit de sommeil que j’ai passée depuis des lustres.</h2>' +
        '<p>' +
        '    <span>Un utilisateur de plus de 60 ans</span>' +
        '</p>',

    shortReview4:
        '<p> ' +
        'Globalement, c’est l’effet sur la vie de notre enfant que nous avons remarqué. Du point de vue de son développement, ' +
        'il s’est considérablement amélioré dans sa façon de parler, et aussi de marcher. Ces derniers mois, ' +
        'la différence est énorme. Mon fils a beaucoup plus confiance en lui, ' +
        'il est moins colérique et il est bien plus heureux.' +
        '    </p> ' +
        '    <p> ' +
        '        <span>La mère d’un enfant en bas âge</span> ' +
        '    </p>',

};
