const tab = '    ';
/**
 * Texts for Order page and modal popups
 */
export const orderPageText = {
    title: 'Bestilling – CamDiab',
    titleMeta: 'Køb CamAPS FX – CamDiab',
    descriptionMeta: 'Betal helt ned til £70 pr. måned for CamAPS FX. Få en gratis prøvemåned. Kræver kompatibel Dana-insulinpumpe og Dexcom G6-glukosesensor',
    localeMeta: 'da_dk',

    keywords:
        'camaps pris, camaps fx pris, camaps ordre, camaps fx ordre, camps fx gratis prøveperiode',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    // discount code processing messages
    pleaseWait: 'Vent venligst',
    successResult: 'Succes',
    errorResult: 'Behandlingsfejl',
    discountApplied: 'Rabatkoden er registreret.',
    okButtonText: 'OK',

    // Discount line
    discountHeading: 'Rabat',
    discountOver: ' over', // This reads: Discount: 30% over 3 months
    discountOneMonth: 'måned',
    discountTwoAndMoreMonths: 'måneder',

    // interest in CamAPS FX related messages
    processingEnquiry: 'Din forespørgsel er under behandling',
    thankYouForSubmission: 'Tak, fordi du opgav dine oplysninger',
    processingError: 'Behandlingsfejl',
    responseAck: 'Vi vil overveje din forespørgsel og kontakter dig, hvis CamAPS FX bliver tilgængelig i dit land',

    stage1: 'Stadie 1',
    subscriptionOption: 'Abonnementsmulighed',
    stage2: 'Stadie 2',
    equipment: 'Udstyr',
    stage3: 'Stadie 3',
    personalInfo: 'Personlige oplysninger',
    stage4: 'Stadie 4',
    orderConfirmation: 'Ordrebekræftelse',

    currencySubscription: '£',
    vatExempt: '(momsfritaget)',

    costMonthly: 80,
    costSixMonthly: 450,
    costAnnual: 840,

    costMonthlyVAT: 96,
    costSixMonthlyVAT: 540,
    costAnnualVAT: 1008,

    subscriptionMonthly: 'Månedsabonnement',
    subscriptionSixMonthly: 'Halvårligt abonnement',
    subscriptionAnnual: 'Årsabonnement',

    interested: 'Er du interesseret i at købe CamAPS FX?',
    interestedPara: 'Brug rabatkoden <b>CamAPS30</b> for at få en helt gratis 30-dages prøveperiode uden nogen form for binding.',

    subscriptionProcess: 'Vores abonnementsproces',
    choosePackage: 'Vælg din abonnementspakke',
    answerQuestions: 'Besvar nogle få spørgsmål om dig selv',
    receiveTraining: 'Få certificeret onlineoplæring',

    // below are texts for API
    botCheckFailed: 'Bot-tjek mislykkedes. Prøv igen om lidt, eller kontakt os på support@camdiab.com',
    wrongDiscountCode: 'Forkert rabatkode, eller rabatkoden er udløbet',
    pumpAlreadyExists: 'Kan ikke gennemføre ordren. Pumpe med serienummeret %s er allerede i brug. Tjek din pumpes serienummer, eller kontakt os på sales@camdiab.com.',
    costTextWithDiscount: '%s%s for den første periode (rabatkode %s) og derefter %s%s for de efterfølgende perioder',
    emailOrderSubjectLine: 'Din CamAPS FX-ordre',
    emailTrainingSubjectLine: 'CamAPS FX-oplæring',
    emailInterestedSubjectLine: 'CamAPS FX-forespørgsel (%s)',

    emailVATexemptYes: 'Ja',
    emailVATexemptNo: 'Nej',

    emailOrderBody: "Kære %s<p>Vi har hermed fornøjelsen at bekræfte din ordre på CamAPS FX-appen. Dine ordreoplysninger er som følger:<p>" +
        tab + "Navn: %s %s<br>" +
        tab + "Kontakt-e-mail: %s<br>" +
        tab + "Pumpens serienummer: %s<br>" +
        tab + "Abonnementsperiode: %s<br>" +
        tab + "%s<br>" +
        tab + "Momsfritaget: %s<br>" +
        tab + "Oplæringsklinik: %s<br><p><strong>Næste trin</strong><br>Du kan enten gennemføre <a href=\"https://www.camdiabtraining.com/account-sign-in.html\">CamAPS FX-oplæringen online</a>, eller din diabetesvejleder viser dig personligt, hvordan du bruger appen. Du kan downloade CamAPS FX-appen fra Amazon Appstore, men du kan ikke bruge den, før du er oplært, og din diabetesvejleder låser appen op ved at opgive sin certificerede CamAPS FX-oplæringskode. Efter oplæring kan du begynde at bruge CamAPS FX-appen med det samme.<p><strong>Betaling</strong><br>Når appen er låst op, og du kan begynde at bruge den, sender vi dig en faktura pr. e-mail. Vi foretrækker betaling med betalingsservice. Rettidig indbetaling er nødvendig for fortsat at kunne bruge CamAPS FX-appen. Dette har ingen indflydelse på dine lovfæstede rettigheder. Du kan til hver en tid annullere din ordre før oplæringen eller senest 14 dage efter oplæringen og få refunderet dine indbetalinger fuldt ud.<p><strong>Abonnementsperiode</strong><br>Din abonnementsperiode starter fra datoen for din oplæring. Bemærk, at der ikke opkræves nogen betalinger, før oplæringen har fundet sted. Efter den første abonnementsperiode opkræves der betaling for hver efterfølgende abonnementsperiode, medmindre du annullerer din ordre ved at skrive til %s.<p>Tak for din ordre. Vi håber, du vil have gavn af CamAPS FX-appen.<p>CamDiab-teamet<br>%s",

    emailPotentialUser: "Kære %s %s<p>Vi kan forstå, at du er interesseret i at bruge CamAPS FX-appen, men du fortalte, at du ikke kan få oplæring i CamAPS FX-appen på din diabetesklinik. Vi kan måske arrangere oplæring for dig til kostpris. Dine oplysninger er som følger:<p>" +
        tab + "Navn: %s %s<br>" +
        tab + "Kontakt-e-mail: %s<br><p><strong>Næste trin</strong><br>Vi giver din forespørgsel videre til vores CamAPS FX-vejleder(e), som måske kan hjælpe dig. Hvis de kan det, sender de dig en e-mail. Når du har aftalt oplæring med din vejleder, skal du indsende CamAPS FX-ordren via <a href=\"https://camdiab.com/\">camdiab.com</a>.<p><strong>Betaling for oplæring</strong><br>Du skal aftale prisen for din oplæring med din CamAPS FX-vejleder og betale den separat. Disse tjenester leveres ikke af CamDiab. <p><strong>CamAPS-vejledere</strong><br>Vi sætter pris på vores CamAPS FX-vejledere, som alle har gennemgået relevant uddannelse.<p>Tak for din interesse for CamAPS FX-appen.<p>CamDiab-teamet<br>%s",

    emailInterestedUser: "Kære %s %s<p>Tak for din interesse for CamAPS FX. Vi har noteret din interesse og kontakter dig, hvis CamAPS FX bliver tilgængelig i %s. Dine oplysninger:<p>" +
        tab + "Navn: %s %s<br>" +
        tab + "Kontakt-e-mail: %s<br>" +
        tab + "Land: %s<br><p>Tak for din interesse for CamAPS FX-appen.<p>CamDiab-teamet<br>%s",
    emailOrderBody_1: 'Tak, fordi du har registreret dig til at bruge CamAPS FX-appen',
    emailOrderBody_2: 'Kære %s',
    emailOrderBody_3: 'Vi har hermed fornøjelsen at bekræfte din ordre på CamAPS FX-appen. Dine ordreoplysninger er som følger:',
    emailOrderBody_4: 'Navn:',
    emailOrderBody_5: 'Kontakt-e-mail:',
    emailOrderBody_6: 'Pumpens serienummer:',
    emailOrderBody_7: 'Abonnementsperiode:',
    emailOrderBody_8: 'Pris:',
    emailOrderBody_9: 'Momsfritaget:',
    emailOrderBody_10: 'Oplæringsklinik:',
    emailOrderBody_11: 'Næste trin',
    emailOrderBody_12: 'Du får tilsendt en faktura, <b>efter</b> at du begynder at bruge CamAPS FX-appen.',
    emailOrderBody_13: 'Vi anbefaler, at du gennemfører CamAPS FX-oplæringen online.',
    emailOrderBody_14: 'Ellers viser din diabetesvejleder dig personligt, hvordan du bruger appen.',
    emailOrderBody_15: 'Du kan downloade CamAPS FX-appen fra Amazon Appstore, men du kan ikke bruge den, før du er oplært, og din diabetesvejleder eller du låser appen op ved at opgive sin/din certificerede CamAPS FX-oplæringskode. ',
    emailOrderBody_16: 'Når appen er låst op, kan du begynde at bruge den med det samme.',
    emailOrderBody_17: 'Betaling',
    emailOrderBody_18: 'Efter oplæringen sender vi dig en faktura pr. e-mail. Rettidig indbetaling er nødvendig for fortsat at kunne bruge CamAPS FX-appen. Dette har ingen indflydelse på dine lovfæstede rettigheder. Du kan til hver en tid annullere din ordre før oplæringen eller senest 30 dage efter oplæringen og få refunderet dine indbetalinger fuldt ud.',
    emailOrderBody_19: 'Abonnementsperiode',
    emailOrderBody_20: 'Din abonnementsperiode starter med datoen for din oplæring. Bemærk, at der ikke opkræves nogen betalinger, før oplæringen har fundet sted. Efter den første abonnementsperiode opkræves der betaling for hver efterfølgende abonnementsperiode, medmindre du annullerer din ordre ved at skrive til sales@camdiab.com.',
    emailOrderBody_21: 'Tak, fordi du har tegnet abonnement på at bruge CamAPS FX-appen',
    emailOrderBody_22: 'Du kan finde nærmere oplysninger om teknisk support på vores hjemmeside.',
    emailOrderBody_23: '© 2021 CamDiab Ltd. Alle rettigheder forbeholdes.',

    // Texts for step one form
    s1MonthlyHeading: 'Månedsabonnement',
    s1MonthlyValue: '£80*',
    s1Select: 'Vælg',
    s1HalfYearlyHeading: 'Halvårsabonnement',
    s1HalfYearlyText: '£450*',
    s1HalfYearlyTextSaving: '(du sparer £30)',
    s1YearlyHeading: 'Årsabonnement',
    s1YearlyText: '£840*',
    s1YearlyTextSaving: '(du sparer £120)',
    s1VATtext: ' *Plus moms, medmindre du er momsfritaget. Indbetales fuldt ud efter oplæring/aktivering af appen.',

    // Texts for step two form
    previous: 'Forrige',
    next: 'Næste',
    s2Yes: 'Ja',
    s2No: 'Nej',
    s2HaveDanaPump: 'Har du en mylife YpsoPump, Dana Diabecare RS- eller DANA-i-pumpe?',
    s2HaveCgm: 'Har du et Dexcom G6 CGM-system?',
    s2Phone1: 'Har du en kompatibel',
    s2PhoneHtml: 'https://www.dexcom.com/dexcom-international-compatibility',
    s2Phone2: 'Android-telefon',
    s2Phone3: '?',
    s2NoPump: 'For at kunne bruge CamAPS FX-appen skal du bruge en mylife YpsoPump, Dana Diabecare RS- eller DANA-i-insulinpumpe. Kontakt dit lokale diabetesteam, og tal med dem om, hvilke muligheder du har, eller kontakt din lokale Dana-forhandler.',
    s2NoCgm: 'For at kunne bruge CamAPS FX-appen skal du bruge Dexcom G6 CGM-systemet. Tal med dit lokale diabetesteam om Dexcom G6 CGM-systemet, eller kontakt Dexcom.',
    s2NoPhone: 'Vi kan ikke garantere en pålidelig forbindelse til din Dexcom G6-sensor, hvis du ikke bruger en kompatibel Android-telefon. Overvej at købe en kompatibel Android-telefon.',

    // Texts for step three form
    s3InfoSource: [
        'Klinik',
        'Søgemaskine',
        'Sociale medier',
        'Et opslag fra en ven på sociale medier',
        'JDRF',
        'Diabetes UK',
        'Radio',
        'TV',
        'Print',
        'Mund til mund',
        'Andet'],

    // s3TeritoryQuestion: 'Are you based in the UK, the EU, or New Zealand?',
    s3TeritoryQuestion: 'Bor du i Storbritannien eller EU?',
    s3NotAvailable: 'CamAPS FX-appen er desværre kun tilgængelig i disse lande. Udfyld dine oplysninger, hvis vi skal kontakte dig, når systemet bliver tilgængeligt i dit land.',
    s3SelectCountry: 'Vælg land',
    s3TC1: 'Når du indsender din anmodning, accepterer du vores',
    s3TC2: 'Vilkår og betingelser',
    s3TC3: 'og',
    s3TC4: 'Databeskyttelsespolitik',
    s3TC5: 'og giver os tilladelse til at kontakte dig.',
    s3Training1: 'Du skal være oplært af en certificeret vejleder og/eller gennemgå',
    s3Training2: 'onlineoplæring,',
    s3Training3: 'før du kan begynde at bruge CamAPS FX-appen.',

    s3FirstName: 'Fornavn',
    s3LastName: 'Efternavn',
    s3Email: 'E-mailadresse',
    s3EmailConfirm: 'Bekræft e-mailadresse',
    s3AddressLine1: 'Adresselinje 1',
    s3AddressLine2: 'Adresselinje 2',
    s3AddressLine3: 'Adresselinje 3',
    s3PostCode: 'Postnummer',
    s3SubmitCountry: 'Send',
    s3DisabilityHeading: 'Jeg erklærer, at jeg har følgende handicap eller kroniske sygdom:',
    s3Disability: 'Handicap eller kronisk sygdom',
    s3signature: 'Underskrift (indtast dit navn)',

    s3TrainingCdep1: 'Vælg CamAPS-onlineoplæring for at fortsætte:',
    s3TrainingCdep2: 'Vælg oplæring',
    s3TrainingCdep3: 'CamAPS-onlineoplæring',

    s3contactHeading: 'Indtast dine kontaktoplysninger',
    s3whereHeard: 'Hvor har du hørt om CamAPS FX-appen?',
    s3whereHeardSelect: 'Vælg et af følgende',
    s3pumpSerialNumber: 'Din Dana-pumpes serienummer:',
    s3pumpSerialNumberConfirm: 'Bekræft din Dana-pumpes serienummer:',
    s3pumpSerialNumberMessage: 'Vigtigt:',
    s3pumpSerialNumberMessage1: 'Pumpens serienummer er nødvendigt for at linke CamAPS FX-appen til din pumpe. Et forkert serienummer kan betyde, at det er umuligt at linke CamAPS FX-appen til din pumpe. Du finder serienummeret på siden af pumpen. Et serienummer kan f.eks. se sådan ud: THF00041FB.',
    s3vatExempt: 'Er du momsfritaget?',
    s3vatHelp1: 'Du kan læse mere i hjælpearkene på',
    s3vatHelpHtml: 'https://www.gov.uk/government/organisations/hm-revenue-customs/contact/vat-reliefs-for-disabled-and-older-people',
    s3vatHelpLine: 'Rådgivning om momsfritagelse',
    s3vatHelp2: 'GOV.UK-hjemmesiden',
    s3vatHelp3: 'eller ved at ringe til VAT Disabled Reliefs Helpline på 0300 123 1073.',
    s3vatHelp4: 'Skattemedarbejderne kan ikke rådgive om, hvorvidt en person er kronisk syg eller handicappet.',
    s3vatHelp5: 'Vigtigt:',
    s3vatHelp6: 'Hvis du bestiller CamAPS FX-appen på vegne af en anden, bedes du udfylde momsfritagelsesformularen med den anden persons oplysninger.',
    s3PhoneWarning: 'Vi kan ikke garantere en pålidelig forbindelse til din Dexcom G6-sensor, hvis du ikke bruger en kompatibel Android-telefon. Overvej at købe en kompatibel Android-telefon',

    s3NameMissing: 'Fornavn er obligatorisk',
    s3SurnameRequired: 'Efternavn er obligatorisk',
    s3EmailRequired: 'E-mail er obligatorisk',
    s3CountryRequired: 'Land er obligatorisk',
    s3EmailConfimrRequired: 'Bekræftelse af e-mail er obligatorisk',
    s3EmailMatchRequired: 'E-mail skal matche',
    s3ClinicRequired: 'Oplæringsklinik er obligatorisk',
    s3DiseaseStateRequired: 'Brugerens behov skal anføres',
    s3OnlyTextValueProvided: 'Indeholder kun tekstværdi',
    s3OnlyNumberValueProvided: 'Indeholder kun tal',
    s3SerialNumbersMatch: 'Serienummer skal matche',
    s3vatStatusRequired: 'Momsstatus skal angives',
    s3AddressLine1Required: 'Adresselinje 1 er obligatorisk',
    s3AddressLine2Required: 'Adresselinje 2 er obligatorisk',
    s3PostCodeRequired: 'Postnummer er obligatorisk',
    s3SickessRequired: 'Handicap eller kronisk sygdom er obligatorisk',
    s3SignatureRequired: 'Underskrift er obligatorisk',

    // Texts for step four form
    s4NextSteps:'Næste trin',
    s4DownloadApp:'Du kan downloade CamAPS FX-appen fra',
    s4DownloadGuideHtml:'https://s3-eu-west-1.amazonaws.com/camdiab.user.manual/Downloading+CamAPS+FX.pdf',
    s4DownloadAppGuide:'Installationsvejledning',
    s4Appstore:'Amazon Appstore',
    s4DownloadApp1:'men du kan ikke bruge den, før du er',
    s4TrainingTextLink:'oplært',
    s4TrainingHtml:'https://camdiab.com/training',
    s4TrainingTitle:'Oplæring',

    s4Payment:'Betalinger opkræves, når du linker CamAPS FX-appen til din pumpe.',

    s4TermsAccept:'Jeg accepterer',
    s4TermsTermsHtml:'https://camdiab.com/terms',
    s4TermsTermsText:'Vilkår og betingelser',
    s4TermsPrivacyHtml:'https://camdiab.com/privacy',
    s4TermsPrivacyText:'Databeskyttelsespolitik',

    s4DiscountCodeText:'Rabatkode (valgfri)',
    s4DiscountApplyCode:'Anvend kode',

    s4Summary:'Ordreoversigt',
    s4Name:'Navn:',
    s4Email:'E-mail:',
    s4PumpSN:'Pumpes SN:',
    s4Training:'Oplæring:',
    s4Total:'I alt',

    s4CompleteButtonText:'Udført',

    // duration or subscription period in an email
    emailOneMonth: '1 måned',
    emailSixMonths: '6 måneder',
    emailTwelveMonths: '1 år',

    // Order complete
    oderProcessedHeading: 'Din ordre er under behandling',
    oderProcessedSucess: 'Tak, din ordre er gennemført',
    oderProcessedError: 'Behandlingsfejl',
    oderProcessedEmailToBeSent: 'Du modtager en bekræftelses-e-mail om et øjeblik',
};
