import React from 'react';
import {Header} from './Header';
import {Footer} from './Footer';
import {NavLink} from 'react-router-dom';
import parse from 'html-react-parser';
import article_img01 from '../assets/images/article-sam-and-sofia-steve.jpg';
import {samReviewText as samReviewText_en} from "../libs/en/texts-review-messages-lib";
import {whatIsCamAPSFX as whatIsCamAPSFX_en, userReviewsIntro as userReviewsIntro_en} from "../libs/en/common-text-lib";
import {samReviewText as samReviewText_de} from "../libs/de/texts-review-messages-lib";
import {whatIsCamAPSFX as whatIsCamAPSFX_de, userReviewsIntro as userReviewsIntro_de} from "../libs/de/common-text-lib";
import {samReviewText as samReviewText_fr} from "../libs/fr/texts-review-messages-lib";
import {whatIsCamAPSFX as whatIsCamAPSFX_fr, userReviewsIntro as userReviewsIntro_fr} from "../libs/fr/common-text-lib";
import {samReviewText as samReviewText_nl} from "../libs/nl/texts-review-messages-lib";
import {whatIsCamAPSFX as whatIsCamAPSFX_nl, userReviewsIntro as userReviewsIntro_nl} from "../libs/nl/common-text-lib";
import {samReviewText as samReviewText_it} from "../libs/it/texts-review-messages-lib";
import {whatIsCamAPSFX as whatIsCamAPSFX_it, userReviewsIntro as userReviewsIntro_it} from "../libs/it/common-text-lib";
import {samReviewText as samReviewText_cz} from "../libs/cz/texts-review-messages-lib";
import {whatIsCamAPSFX as whatIsCamAPSFX_cz, userReviewsIntro as userReviewsIntro_cz} from "../libs/cz/common-text-lib";
import {samReviewText as samReviewText_fi} from "../libs/fi/texts-review-messages-lib";
import {whatIsCamAPSFX as whatIsCamAPSFX_fi, userReviewsIntro as userReviewsIntro_fi} from "../libs/fi/common-text-lib";
import {samReviewText as samReviewText_pl} from "../libs/pl/texts-review-messages-lib";
import {whatIsCamAPSFX as whatIsCamAPSFX_pl, userReviewsIntro as userReviewsIntro_pl} from "../libs/pl/common-text-lib";
import {samReviewText as samReviewText_se} from "../libs/se/texts-review-messages-lib";
import {samReviewText as samReviewText_no} from "../libs/no/texts-review-messages-lib";
import {whatIsCamAPSFX as whatIsCamAPSFX_se, userReviewsIntro as userReviewsIntro_se} from "../libs/se/common-text-lib";
import {whatIsCamAPSFX as whatIsCamAPSFX_no, userReviewsIntro as userReviewsIntro_no} from "../libs/no/common-text-lib";
import {samReviewText as samReviewText_dk} from "../libs/dk/texts-review-messages-lib";
import {whatIsCamAPSFX as whatIsCamAPSFX_dk, userReviewsIntro as userReviewsIntro_dk} from "../libs/dk/common-text-lib";
import {samReviewText as samReviewText_es} from "../libs/es/texts-review-messages-lib";
import {whatIsCamAPSFX as whatIsCamAPSFX_es, userReviewsIntro as userReviewsIntro_es} from "../libs/es/common-text-lib";


import {Helmet} from "react-helmet";

class ArticleSam extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            language: this.props.language,
            reviewText: samReviewText_en,
            whatIsCamAPSFX: whatIsCamAPSFX_en,
            userReviewsIntro: userReviewsIntro_en,
            prefix: ''
        };

        // set localisation text
        if (this.props.language === "DE") {
            this.state.reviewText = samReviewText_de;
            this.state.whatIsCamAPSFX = whatIsCamAPSFX_de;
            this.state.userReviewsIntro = userReviewsIntro_de;
            this.state.prefix = '/de';
        }
        if (this.props.language === "FR") {
            this.state.reviewText = samReviewText_fr;
            this.state.whatIsCamAPSFX = whatIsCamAPSFX_fr;
            this.state.userReviewsIntro = userReviewsIntro_fr;
            this.state.prefix = '/fr-ch';
        }
        if (this.props.language === "FR-FR") {
            this.state.reviewText = samReviewText_fr;
            this.state.whatIsCamAPSFX = whatIsCamAPSFX_fr;
            this.state.userReviewsIntro = userReviewsIntro_fr;
            this.state.prefix = '/fr';
        }
        if (this.props.language === "NL") {
            this.state.reviewText = samReviewText_nl;
            this.state.whatIsCamAPSFX = whatIsCamAPSFX_nl;
            this.state.userReviewsIntro = userReviewsIntro_nl;
            this.state.prefix = '/nl';
        }
        if (this.props.language === "IT") {
            this.state.reviewText = samReviewText_it;
            this.state.whatIsCamAPSFX = whatIsCamAPSFX_it;
            this.state.userReviewsIntro = userReviewsIntro_it;
            this.state.prefix = '/it';
        }
        if (this.props.language === "CZ") {
            this.state.reviewText = samReviewText_cz;
            this.state.whatIsCamAPSFX = whatIsCamAPSFX_cz;
            this.state.userReviewsIntro = userReviewsIntro_cz;
            this.state.prefix = '/cz';
        }
        if (this.props.language === "FI") {
            this.state.reviewText = samReviewText_fi;
            this.state.whatIsCamAPSFX = whatIsCamAPSFX_fi;
            this.state.userReviewsIntro = userReviewsIntro_fi;
            this.state.prefix = '/fi';
        }
        if (this.props.language === "PL") {
            this.state.reviewText = samReviewText_pl;
            this.state.whatIsCamAPSFX = whatIsCamAPSFX_pl;
            this.state.userReviewsIntro = userReviewsIntro_pl;
            this.state.prefix = '/pl';
        }
        if (this.props.language === "SE") {
            this.state.reviewText = samReviewText_se;
            this.state.whatIsCamAPSFX = whatIsCamAPSFX_se;
            this.state.userReviewsIntro = userReviewsIntro_se;
            this.state.prefix = '/se';
        }
        if (this.props.language === "NO") {
            this.state.reviewText = samReviewText_no;
            this.state.whatIsCamAPSFX = whatIsCamAPSFX_no;
            this.state.userReviewsIntro = userReviewsIntro_no;
            this.state.prefix = '/nb';
        }
        if (this.props.language === "DK") {
            this.state.reviewText = samReviewText_dk;
            this.state.whatIsCamAPSFX = whatIsCamAPSFX_dk;
            this.state.userReviewsIntro = userReviewsIntro_dk;
            this.state.prefix = '/dk';
        }
        if (this.props.language === "ES") {
            this.state.reviewText = samReviewText_es;
            this.state.whatIsCamAPSFX = whatIsCamAPSFX_es;
            this.state.userReviewsIntro = userReviewsIntro_es;
            this.state.prefix = '/es';
        }
    }

    /**
     * Sets localisation text
     * */
    setLanguageVars() {
        if (this.state.language !== this.props.language) {
            if (this.props.language === "EN") {
                this.setState({
                    language: this.props.language,
                    reviewText: samReviewText_en,
                    whatIsCamAPSFX: whatIsCamAPSFX_en,
                    userReviewsIntro: userReviewsIntro_en,
                    prefix: ''
                });
            }

            // set localisation text
            if (this.props.language === "DE") {
                this.setState({
                    language: this.props.language,
                    reviewText: samReviewText_de,
                    whatIsCamAPSFX: whatIsCamAPSFX_de,
                    userReviewsIntro: userReviewsIntro_de,
                    prefix: '/de'
                });
            }

            if (this.props.language === "FR") {
                this.setState({
                    language: this.props.language,
                    reviewText: samReviewText_fr,
                    whatIsCamAPSFX: whatIsCamAPSFX_fr,
                    userReviewsIntro: userReviewsIntro_fr,
                    prefix: '/fr-ch'
                });
            }

            if (this.props.language === "FR-FR") {
                this.setState({
                    language: this.props.language,
                    reviewText: samReviewText_fr,
                    whatIsCamAPSFX: whatIsCamAPSFX_fr,
                    userReviewsIntro: userReviewsIntro_fr,
                    prefix: '/fr'
                });
            }

            if (this.props.language === "NL") {
                this.setState({
                    language: this.props.language,
                    reviewText: samReviewText_nl,
                    whatIsCamAPSFX: whatIsCamAPSFX_nl,
                    userReviewsIntro: userReviewsIntro_nl,
                    prefix: '/nl'
                });
            }
            if (this.props.language === "IT") {
                this.setState({
                    language: this.props.language,
                    reviewText: samReviewText_it,
                    whatIsCamAPSFX: whatIsCamAPSFX_it,
                    userReviewsIntro: userReviewsIntro_it,
                    prefix: '/it'
                });
            }
            if (this.props.language === "CZ") {
                this.setState({
                    language: this.props.language,
                    reviewText: samReviewText_cz,
                    whatIsCamAPSFX: whatIsCamAPSFX_cz,
                    userReviewsIntro: userReviewsIntro_cz,
                    prefix: '/cz'
                });
            }
            if (this.props.language === "FI") {
                this.setState({
                    language: this.props.language,
                    reviewText: samReviewText_fi,
                    whatIsCamAPSFX: whatIsCamAPSFX_fi,
                    userReviewsIntro: userReviewsIntro_fi,
                    prefix: '/fi'
                });
            }
            if (this.props.language === "PL") {
                this.setState({
                    language: this.props.language,
                    reviewText: samReviewText_pl,
                    whatIsCamAPSFX: whatIsCamAPSFX_pl,
                    userReviewsIntro: userReviewsIntro_pl,
                    prefix: '/pl'
                });
            }
            if (this.props.language === "SE") {
                this.setState({
                    language: this.props.language,
                    reviewText: samReviewText_se,
                    whatIsCamAPSFX: whatIsCamAPSFX_se,
                    userReviewsIntro: userReviewsIntro_se,
                    prefix: '/se'
                });
            }
            if (this.props.language === "NO") {
                this.setState({
                    language: this.props.language,
                    reviewText: samReviewText_no,
                    whatIsCamAPSFX: whatIsCamAPSFX_no,
                    userReviewsIntro: userReviewsIntro_no,
                    prefix: '/nb'
                });
            }
            if (this.props.language === "DK") {
                this.setState({
                    language: this.props.language,
                    reviewText: samReviewText_dk,
                    whatIsCamAPSFX: whatIsCamAPSFX_dk,
                    userReviewsIntro: userReviewsIntro_dk,
                    prefix: '/dk'
                });
            }
            if (this.props.language === "ES") {
                this.setState({
                    language: this.props.language,
                    reviewText: samReviewText_es,
                    whatIsCamAPSFX: whatIsCamAPSFX_es,
                    userReviewsIntro: userReviewsIntro_es,
                    prefix: '/es'
                });
            }
        }
    }

    componentDidUpdate() {
        this.setLanguageVars();
    }

    componentDidMount() {
        this.setLanguageVars();
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>{parse(this.state.reviewText.title)}</title>
                    <meta name="title" content={parse(this.state.reviewText.titleMeta)}/>
                    <meta name="description"
                          content={parse(this.state.reviewText.descriptionMeta)}/>
                    <meta property="og:locale" content={this.state.reviewText.localeMeta}/>
                    <meta name="keywords" content={this.state.reviewText.keywords}/>
                </Helmet>
                <Header props={this.props}/>
                <section className='inner-banner'>
                    <div className='container'>
                        <div className='row justify-content-center'>
                            <div className='col-12 col-md-8 col-xl-9'>
                                <h1>{parse(this.state.userReviewsIntro.header)}</h1>
                                <p>{parse(this.state.userReviewsIntro.text)}</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='review-article-page'>
                    <div className='container'>
                        <div className='row justify-content-center'>
                            <div className='col-12 col-md-12 col-xl-12'>
                                <div className='review-article-main'>
                                    <div className='article-left'>
                                        {parse(this.state.reviewText.body)}
                                    </div>
                                    <div className='article-right'>
                                        <div className='article-right-inner'>
                                            <div className='article-img-col'>
												<span
                                                    className="review-img"
                                                    style={{backgroundImage: `url(${article_img01})`}}>
												</span>
                                                {/* <img src={article_img01} alt='image' /> */}
                                                <h5>
                                                    {parse(this.state.reviewText.imageTitle)}
                                                    <span>
													</span>
                                                </h5>
                                            </div>
                                        </div>
                                        <NavLink to={this.state.prefix + '/reviews'}
                                                 className='btn'>
                                            {parse(this.state.userReviewsIntro.linkMoreReviews)}
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='content-sec'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-sm-12'>
                                <div className='content-inner'>
                                    {parse(this.state.whatIsCamAPSFX.body)}
                                    <NavLink to={this.state.prefix + '/faq'}
                                             className='btn'>
                                        {parse(this.state.whatIsCamAPSFX.linkFindOutMore)}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer props={this.props}/>
            </div>
        );
    }
}

export default ArticleSam;
