const dev = {
    s3: {
        REGION: "eu-west-1",
        BUCKET: "camdiab.bug.report"
    },
    apiGateway: {
        REGION: "eu-west-1",
        URL: "https://sryw58ayy4.execute-api.eu-west-1.amazonaws.com/dev"
    },
    cognito: {
        REGION: "eu-west-1",
        IDENTITY_POOL_ID: "eu-west-1:d0b5185b-8f33-4bc1-8cdd-cf4dc594d1a1"
    },
    captchaSiteKey: "6LcBgcsUAAAAAD8gtKqb2Vw5A1ZsQyGtl6DeHYW8",
};

const prod = {
    s3: {
        REGION: "eu-west-1",
        BUCKET: "camdiab.bug.report"
    },
    apiGateway: {
        name: "ordering",
        REGION: "eu-west-1",
        URL: "https://xhxcamj2f2.execute-api.eu-west-1.amazonaws.com/prod"
    },
    cognito: {
        REGION: "eu-west-1",
        IDENTITY_POOL_ID: "eu-west-1:f56142d0-7d42-49c2-9e37-f38b6f4b42d1"
    },
    captchaSiteKey: "6LfIWssUAAAAAF10GieGXa2cI3XTUsyvd23HD79i",
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : dev;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    // Add common config values here
    ...config
};
