const tab = '    ';
/**
 * Texts for Order page and modal popups
 */
export const orderPageText = {
    title: 'Tilaukset − CamDiab',
    titleMeta: 'Hanki CamAPS FX − CamDiab',
    descriptionMeta: 'Maksat CamAPS FX -sovelluksesta vain £70 / kk. Kokeile sovellusta ilmaiseksi 1 kuukauden ajan. Vaatii yhteensopivan Dana-insuliinipumpun ja Dexcom G6 -glukoosisensorin.',
    localeMeta: 'fi_fi',

    keywords:
        'camaps hinta, camaps fx hinta, camaps tilaus, camaps fx tilaus, camaps fx ilmainen kokeilu',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    // discount code processing messages
    pleaseWait: 'Odota hetki',
    successResult: 'Onnistui',
    errorResult: 'Käsittelyvirhe',
    discountApplied: 'Alennuskoodi on käytetty.',
    okButtonText: 'OK',

    // Discount line
    discountHeading: 'Alennus',
    discountOver: ' ', // This reads: Discount: 30% over 3 months
    discountOneMonth: 'kk',
    discountTwoAndMoreMonths: 'kk',

    // interest in CamAPS FX related messages
    processingEnquiry: 'Kyselyäsi käsitellään',
    thankYouForSubmission: 'Kiitos tietojesi lähettämisestä',
    processingError: 'Käsittelyvirhe',
    responseAck: 'Käsittelemme kyselyäsi ja olemme sinuun yhteydessä, jos CamAPS FX tulee saataville maassasi',

    stage1: 'Vaihe 1',
    subscriptionOption: 'Tilausvaihtoehto',
    stage2: 'Vaihe 2',
    equipment: 'Laite',
    stage3: 'Vaihe 3',
    personalInfo: 'Henkilötiedot',
    stage4: 'Vaihe 4',
    orderConfirmation: 'Tilausvahvistus',

    currencySubscription: '€',
    vatExempt: '(ALV-vapautus)',

    costMonthly: 80,
    costSixMonthly: 450,
    costAnnual: 840,

    costMonthlyVAT: 96,
    costSixMonthlyVAT: 540,
    costAnnualVAT: 1008,

    subscriptionMonthly: 'Kuukausitilaus',
    subscriptionSixMonthly: 'Kuuden kuukauden tilaus',
    subscriptionAnnual: 'Vuositilaus',

    interested: 'Oletko kiinnostunut CamAPS FX -sovelluksesta?',
    interestedPara: 'Alennuskoodilla <b>CamAPS30</b> voit kokeilla sovellusta 30 päivää maksutta ja ilman sitoumuksia.',

    subscriptionProcess: 'Tilausmenettely',
    choosePackage: 'Valitse tilauspaketti',
    answerQuestions: 'Vastaa muutamiin sinua koskeviin kysymyksiin',
    receiveTraining: 'Osallistu sertifioituun verkkokoulutukseen',

    // below are texts for API
    botCheckFailed: 'Botin tarkastus epäonnistui. Yritä pian uudelleen tai ota meihin yhteyttä osoitteesta support@camdiab.com',
    wrongDiscountCode: 'Väärä tai umpeutunut alennuskoodi',
    pumpAlreadyExists: 'Tilausta ei voi päättää. Insuliinipumppu sarjanumerolla %s on jo käytössä. Tarkista insuliinipumpun sarjanumero tai ota meihin yhteyttä osoitteesta sales@camdiab.com.',
    costTextWithDiscount: '%s%s ensimmäiselle jaksolle (alennuskoodi %s) ja sitten %s%s seuraaville jaksoille',
    emailOrderSubjectLine: 'CamAPS FX -tilauksesi',
    emailTrainingSubjectLine: 'CamAPS FX -koulutus',
    emailInterestedSubjectLine: 'CamAPS FX -kysely (%s)',

    emailVATexemptYes: 'Kyllä',
    emailVATexemptNo: 'Ei',

    emailOrderBody: "Hyvä %s<p>Vahvistamme mielellämme CamAPS FX -sovelluksen tilauksesi. Tilauksesi tiedot ovat seuraavat:<p>" +
        tab + "Nimi: %s %s<br>" +
        tab + "Sähköpostiosoite: %s<br>" +
        tab + "Insuliinipumpun sarjanumero: %s<br>" +
        tab + "Tilausjakso: %s<br>" +
        tab + "%s<br>" +
        tab + "ALV-vapautus: %s<br>" +
        tab + "Kouluttava sairaala: %s<br><p><strong>Seuraavat vaiheet</strong><br>Voit joko osallistua <a href=\"https://www.camdiabtraining.com/account-sign-in.html\">CamAPS FX -verkkokoulutukseen</a> tai kouluttajasi näyttää sinulle henkilökohtaisesti, miten sovellusta käytetään. Voit ladata CamAPS FX -sovelluksen Google Play Store -sovelluskaupasta, mutta et voi käyttää sitä, ennen kuin olet saanut asianmukaisen koulutuksen ja kouluttajasi avaa sovelluksen lukituksen syöttämällä sertifioidun CamAPS FX -koulutuskoodinsa. Voit aloittaa CamAPS FX -sovelluksen käytön heti koulutuksen jälkeen.<p><strong>Maksu</strong><br>Kun sovelluksen lukitus on avattu ja voit aloittaa sen käytön, lähetämme sinulle laskun sähköpostitse. Käytämme mieluiten suoraveloitusta. Maksut eräpäivään mennessä ovat välttämättömiä, jotta voit jatkaa CamAPS FX -sovelluksen käyttöä. Tällä ei ole vaikutusta lakiin perustuviin oikeuksiisi. Voit peruuttaa tilauksen milloin tahansa ennen koulutusta tai 14 vuorokauden kuluessa koulutuksesta saadaksesi täyden palautuksen maksamastasi summasta.<p><strong>Tilausjakso</strong><br>Tilausjakso alkaa koulutuksen päivämäärästä. Huomioi, että emme veloita mitään maksuja ennen koulutusta. Ensimmäisen tilausjakson jälkeen jokainen seuraava tilausjakso on maksullinen, ellet peruuta tilaustasi kirjallisesti osoitteessa %s.<p>Kiitos tuestasi. Toivomme sinun hyötyvän CamAPS FX -sovelluksesta.<p>CamDiab-tiimi<br>%s",

    emailPotentialUser: "Hyvä %s %s<p>Kiitos mielenkiinnostasi CamAPS FX -sovelluksen käyttöä kohtaan. Olet kuitenkin ilmoittanut, ettet pystyisi suorittamaan CamAPS FX -sovellusta koskevaa koulutusta diabeteskeskuksessasi. Pystymme ehkä järjestämään koulutuksen kustannuksellasi. Tietosi ovat:<p>" +
        tab + "Nimi: %s %s<br>" +
        tab + "Sähköpostiosoite: %s<br><p><strong>Seuraavat vaiheet</strong><br>Välitämme kyselysi kanssamme yhteistyötä tekeville CamAPS FX -kouluttajille, jotka saattavat pystyä auttamaan sinua. Siinä tapauksessa he ottavat sinuun erikseen yhteyttä sähköpostitse. Sovittuasi kouluttajan kanssa koulutuksestasi, sinun on tilattava CamAPS FX -sovellus osoitteesta <a href=\"https://camdiab.com/\">camdiab.com</a>.<p><strong>Koulutuksen maksu</strong><br>Sovi CamAPS FX -kouluttajan kanssa koulutuksen maksamisesta ja maksa sovittu summa erikseen. CamDiab ei tarjoa tätä palvelua. <p><strong>CamAPS-kouluttajat</strong><br>Arvostamme CamAPS FX -kouluttajiamme, jotka ovat kaikki suorittaneet asianmukaisen koulutuksen.<p>Kiitos mielenkiinnostasi CamAPS FX -sovelluksen käyttöä kohtaan.<p>CamDiab-tiimi<br>%s",

    emailInterestedUser: "Hyvä %s %s<p>Kiitos mielenkiinnostasi CamAPS FX -sovelluksen käyttöä kohtaan. Olemme huomioineet kiinnostuksesi ja olemme sinuun yhteydessä, kun CamAPS FX tulee saataville %s. Yhteystietosi:<p>" +
        tab + "Nimi: %s %s<br>" +
        tab + "Sähköpostiosoite: %s<br>" +
        tab + "Maa: %s<br><p>Kiitos mielenkiinnostasi CamAPS FX -sovelluksen käyttöä kohtaan.<p>CamDiab-tiimi<br>%s",
    emailOrderBody_1: 'Kiitos rekisteröitymisestä käyttämään CamAPS FX -sovellusta',
    emailOrderBody_2: 'Hyvä %s',
    emailOrderBody_3: 'Vahvistamme mielellämme CamAPS FX -sovelluksen tilauksesi. Tilauksesi tiedot ovat seuraavat:',
    emailOrderBody_4: 'Nimi:',
    emailOrderBody_5: 'Sähköpostiosoite:',
    emailOrderBody_6: 'Insuliinipumpun sarjanumero:',
    emailOrderBody_7: 'Tilausjakso:',
    emailOrderBody_8: 'Maksu:',
    emailOrderBody_9: 'ALV-vapautus:',
    emailOrderBody_10: 'Kouluttava sairaala:',
    emailOrderBody_11: 'Seuraavat vaiheet',
    emailOrderBody_12: 'Lasku lähetetään sinulle <b>sen jälkeen</b>, kun olet aloittanut CamAPS FX -sovelluksen käytön.',
    emailOrderBody_13: 'Suosittelemme osallistumaan CamAPS FX -verkkokoulutukseen.',
    emailOrderBody_14: 'Muussa tapauksessa kouluttajasi näyttää sinulle henkilökohtaisesti, miten sovellusta käytetään.',
    emailOrderBody_15: 'Voit ladata CamAPS FX -sovelluksen Google Play Store -sovelluskaupasta, mutta et voi käyttää sitä, ennen kuin olet saanut asianmukaisen koulutuksen ja kouluttajasi avaa tai sinä avaat sovelluksen lukituksen syöttämällä hänen / sinun sertifioidun CamAPS FX -koulutuskoodin. ',
    emailOrderBody_16: 'Kun sovelluksen lukitus on avattu, voit heti aloittaa sen käytön.',
    emailOrderBody_17: 'Maksu',
    emailOrderBody_18: 'Lähetämme laskun sähköpostitse koulutuksen jälkeen. Maksut eräpäivään mennessä ovat välttämättömiä, jotta voit jatkaa CamAPS FX -sovelluksen käyttöä. Tällä ei ole vaikutusta lakiin perustuviin oikeuksiisi. Voit peruuttaa tilauksen milloin tahansa ennen koulutusta tai 30 vuorokauden kuluessa koulutuksesta saadaksesi täyden palautuksen maksamastasi summasta.',
    emailOrderBody_19: 'Tilausjakso',
    emailOrderBody_20: 'Tilausjakso alkaa koulutuksen päivämäärästä. Huomioi, että emme veloita mitään maksuja ennen koulutusta. Ensimmäisen tilausjakson jälkeen jokainen seuraava tilausjakso on maksullinen, ellet peruuta tilaustasi kirjallisesti osoitteessa sales@camdiab.com.',
    emailOrderBody_21: 'Kiitos CamAPS FX -sovelluksen tilaamisesta',
    emailOrderBody_22: 'Jos tarvitset teknistä tukea, katso verkkosivuiltamme lisätietoa.',
    emailOrderBody_23: '© 2021 CamDiab Ltd. Kaikki oikeudet pidätetään.',

    // Texts for step one form
    s1MonthlyHeading: 'Kuukausitilaus',
    s1MonthlyValue: '£80*',
    s1Select: 'Valitse',
    s1HalfYearlyHeading: '6 kk tilaus',
    s1HalfYearlyText: '£450*',
    s1HalfYearlyTextSaving: '(alennus £30)',
    s1YearlyHeading: 'Vuositilaus',
    s1YearlyText: '£840*',
    s1YearlyTextSaving: '(alennus £120)',
    s1VATtext: ' *Lisäksi ALV, ellei ALV-vapautusta Koko maksu on suoritettava koulutuksen / sovelluksen aktivoinnin jälkeen.',

    // Texts for step two form
    previous: 'Edellinen',
    next: 'Seuraava',
    s2Yes: 'Kyllä',
    s2No: 'Ei',
    s2HaveDanaPump: 'Onko sinulla mylife YpsoPump, Dana Diabecare RS- tai DANA-i-insuliinipumppu?',
    s2HaveCgm: 'Onko sinulla Dexcom G6 jatkuvan glukoosinseurannan järjestelmä?',
    s2Phone1: 'Onko sinulla yhteensopiva',
    s2PhoneHtml: 'https://www.dexcom.com/dexcom-international-compatibility',
    s2Phone2: 'Android-puhelin',
    s2Phone3: '?',
    s2NoPump: 'Jotta voit käyttää CamAPS FX -sovellusta, käytössäsi on oltava mylife YpsoPump, Dana Diabecare RS- tai DANA-i-insuliinipumppu. Ota yhteyttä paikalliseen diabetestiimiin ja keskustele eri vaihtoehdoista, tai ota yhteyttä paikalliseen Dana-jälleenmyyjään.',
    s2NoCgm: 'Jotta voit käyttää CamAPS FX -sovellusta, käytössäsi on oltava Dexcom G6 jatkuvan glukoosinseurannan järjestelmä. Keskustele paikallisen diabetestiimin kanssa Dexcom G6 jatkuvan glukoosinseurannan järjestelmästä tai ota yhteyttä Dexcomiin.',
    s2NoPhone: 'Jos et käytä yhteensopivaa Android-puhelinta, emme pysty takaamaan luotettavaa yhteyttä Dexcom G6 -sensoriisi. Harkitse yhteensopivan Android-puhelimen hankkimista.',

    // Texts for step three form
    s3InfoSource: [
        'Sairaala',
        'Hakukone',
        'Sosiaalinen media',
        'Viesti ystävältä sosiaalisen median kautta',
        'JDRF',
        'Diabetes UK',
        'Radio',
        'TV',
        'Lehdet',
        'Suullinen viestintä',
        'Muu'],

    // s3TeritoryQuestion: 'Are you based in the UK, the EU, or New Zealand?',
    s3TeritoryQuestion: 'Asutko Isossa-Britanniassa tai EU:ssa?',
    s3NotAvailable: 'Valitettavasti CamAPS FX -sovellus on käytettävissä vain tietyissä maissa. Syötä yhteystietosi, jos haluat meidän ottavan sinuun yhteyttä, kun järjestelmä tulee saataville maassasi.',
    s3SelectCountry: 'Valitse maa',
    s3TC1: 'Lähettämällä viestisi hyväksyt',
    s3TC2: 'käyttöehtomme',
    s3TC3: 'ja',
    s3TC4: 'tietosuojaehtomme',
    s3TC5: 'ja suostut yhteydenottoomme.',
    s3Training1: 'Sinun on saatava valtuutetun kouluttajan pitämä koulutus ja/tai suoritettava',
    s3Training2: 'verkkokoulutus',
    s3Training3: 'ennen kuin voit aloittaa CamAPS FX -sovelluksen käytön.',

    s3FirstName: 'Etunimi',
    s3LastName: 'Sukunimi',
    s3Email: 'Sähköpostiosoite',
    s3EmailConfirm: 'Vahvista sähköpostiosoite',
    s3AddressLine1: '1. osoiterivi',
    s3AddressLine2: '2. osoiterivi',
    s3AddressLine3: '3. osoiterivi',
    s3PostCode: 'Postinumero',
    s3SubmitCountry: 'Lähetä',
    s3DisabilityHeading: 'Ilmoitan, että minulla on seuraava vamma tai krooninen sairaus:',
    s3Disability: 'Vamma tai krooninen sairaus',
    s3signature: 'Allekirjoitus (kirjoita nimesi)',

    s3TrainingCdep1: 'Valitse CamAPS-verkkokoulutus jatkaaksesi:',
    s3TrainingCdep2: 'Valitse koulutus',
    s3TrainingCdep3: 'CamAPS-verkkokoulutus',

    s3contactHeading: 'Syötä yhteystietosi',
    s3whereHeard: 'Miten kuulit CamAPS FX -sovelluksesta?',
    s3whereHeardSelect: 'Valitse jokin seuraavista',
    s3pumpSerialNumber: 'Dana-insuliinipumpun sarjanumero:',
    s3pumpSerialNumberConfirm: 'Vahvista Dana-insuliinipumpun sarjanumero:',
    s3pumpSerialNumberMessage: 'Tärkeää:',
    s3pumpSerialNumberMessage1: 'Insuliinipumpun sarjanumero vaaditaan, jotta CamAPS FX -sovellus voi muodostaa yhteyden insuliinipumppuusi. Jos sarjanumero on väärä, CamAPS FX -sovellus ei mahdollisesti pysty muodostamaan yhteyttä insuliinipumppuusi. Sarjanumero on merkitty insuliinipumpun sivuun. Näytteen sarjanumero on THF00041FB.',
    s3vatExempt: 'Onko sinut vapautettu arvonlisäverosta?',
    s3vatHelp1: 'Löydät lisätietoa ohjeista sivulla',
    s3vatHelpHtml: 'https://www.gov.uk/government/organisations/hm-revenue-customs/contact/vat-reliefs-for-disabled-and-older-people',
    s3vatHelpLine: 'ALV-vapautusta koskeva ohje',
    s3vatHelp2: 'GOV.UK-verkkosivusto',
    s3vatHelp3: 'tai soittamalla ALV-vapautusta koskevaan puhelinpalveluun numerossa 0300 123 1073.',
    s3vatHelp4: 'HMRC:n henkilökunta ei pysty määrittelemään, onko henkilöllä krooninen sairaus tai vamma.',
    s3vatHelp5: 'Tärkeää:',
    s3vatHelp6: 'Jos tilaat CamAPS FX -sovelluksen toisen henkilön puolesta, täytä ALV-vapautuslomakkeeseen kyseisen henkilön tiedot.',
    s3PhoneWarning: 'Jos et käytä yhteensopivaa Android-puhelinta, emme pysty takaamaan luotettavaa yhteyttä Dexcom G6 -sensoriisi. Harkitse yhteensopivan Android-puhelimen hankkimista.',

    s3NameMissing: 'Etunimi on pakollinen',
    s3SurnameRequired: 'Sukunimi on pakollinen',
    s3EmailRequired: 'Sähköposti on pakollinen',
    s3CountryRequired: 'Maa on pakollinen',
    s3EmailConfimrRequired: 'Vahvistussähköposti on pakollinen',
    s3EmailMatchRequired: 'Sähköpostin pitää täsmätä',
    s3ClinicRequired: 'Kouluttava sairaala on pakollinen',
    s3DiseaseStateRequired: 'Käyttäjän ominaisuus on ilmoitettava',
    s3OnlyTextValueProvided: 'Sisältää vain tekstiarvon',
    s3OnlyNumberValueProvided: 'Sisältää vain numeroita',
    s3SerialNumbersMatch: 'Sarjanumeron pitää täsmätä',
    s3vatStatusRequired: 'ALV-tila on ilmoitettava',
    s3AddressLine1Required: '1. osoiterivi on pakollinen',
    s3AddressLine2Required: '2. osoiterivi on pakollinen',
    s3PostCodeRequired: 'Postinumero on pakollinen',
    s3SickessRequired: 'Vamma tai krooninen sairaus on ilmoitettava',
    s3SignatureRequired: 'Allekirjoitus on pakollinen',

    // Texts for step four form
    s4NextSteps:'Seuraavat vaiheet',
    s4DownloadApp:'Voit ladata CamAPS FX -sovelluksen',
    s4DownloadGuideHtml:'https://s3-eu-west-1.amazonaws.com/camdiab.user.manual/Downloading+CamAPS+FX.pdf',
    s4DownloadAppGuide:'Asennusohje',
    s4Appstore:'Google Play Storesta',
    s4DownloadApp1:'mutta et voi käyttää sitä, ennen kuin olet',
    s4TrainingTextLink:'saanut asianmukaisen koulutuksen',
    s4TrainingHtml:'https://camdiab.com/training',
    s4TrainingTitle:'Koulutus',

    s4Payment:'Maksut veloitetaan, kun CamAPS FX -sovellus ja insuliinipumppu yhdistetään toisiinsa.',

    s4TermsAccept:'Hyväksyn',
    s4TermsTermsHtml:'https://camdiab.com/terms',
    s4TermsTermsText:'Käyttöehdot',
    s4TermsPrivacyHtml:'https://camdiab.com/privacy',
    s4TermsPrivacyText:'Tietosuojaehdot',

    s4DiscountCodeText:'Alennuskoodi (valinnainen)',
    s4DiscountApplyCode:'Käytä koodia',

    s4Summary:'Tilauksen yhteenveto',
    s4Name:'Nimi:',
    s4Email:'Sähköposti:',
    s4PumpSN:'Insuliinipumpun sarjanumero:',
    s4Training:'Koulutus:',
    s4Total:'Yhteensä',

    s4CompleteButtonText:'Valmis',

    // duration or subscription period in an email
    emailOneMonth: '1 kk',
    emailSixMonths: '6 kk',
    emailTwelveMonths: 'Vuosi',

    // Order complete
    oderProcessedHeading: 'Tilaustasi käsitellään',
    oderProcessedSucess: 'Kiitos, tilauksesi onnistui',
    oderProcessedError: 'Käsittelyvirhe',
    oderProcessedEmailToBeSent: 'Saat pian vahvistuksen sähköpostitse',
};
