/**
 * Texts for FAQs page
 */
export const faqPageText = {
    title: 'UKK:t − CamAPS FX',
    titleMeta: 'Onko sinulla kysymyksiä CamAPS FX -sovelluksesta?',
    descriptionMeta: 'Seuraavassa on vastauksia usein kysytyihin kysymyksiin yhteensopivista laitteista, viranomaishyväksynnästä, saatavuudesta ja kliinisestä tutkimuksesta.',
    keywords:
        'camaps FAQs, camaps fx FAQ, ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'fi_fi',

    haveQuestion: 'Onko sinulla kysymyksiä CamAPS FX -sovelluksesta?',
    hereAnswers: 'Seuraavassa on vastauksia usein kysytyihin kysymyksiin.',
    whatIsCamAPS: 'Mikä on CamAPS FX?',
    whatIsCamAPSAnswer: 'CamAPS FX on edistynyt ja helppokäyttöinen Android-käyttöjärjestelmiin tarkoitettu sovellus, jonka avulla tyypin 1 diabetesta sairastavat vähintään 1-vuotiaat diabeetikot voivat hallita glukoositasojaan. Sovelluksessa käytetään pitkälle kehitettyä mukautuvaa suljettua hybridijärjestelmää.',
    isApproved: 'Onko CamAPS FX hyväksytty käyttöön?',
    isApprovedAnswer: 'Sovellukselle on suoritettu laajoja testejä kliinisissä tutkimuksissa, ja se on hyväksytty käyttöön mm. Isossa-Britanniassa ja EU:ssa.',
    isAvailable: 'Onko CamAPS FX saatavana alueellani?',
    isAvailableAnswer1: 'CamAPS on saatavana valikoiduissa diabeteskeskuksissa diabeetikoille, jotka ovat saaneet tuotteeseen liittyvän ohjauksen. Tarkista sairaalastasi tai valitse',
    isAvailableAnswer2: 'Tilaukset',
    isAvailableAnswer3: 'jos haluat tilata sovelluksen.',
    cost: 'Kuinka paljon CamAPS FX maksaa?',
    costAnswer1: 'Katso kustannuksia koskevat tiedot verkkosivujen',
    costAnswer2: '-osiossa.',
    compatibleCgm: 'Mitkä CGM-menetelmät ovat yhteensopivia CamAPS FX -sovelluksen kanssa?',
    compatibleCgmAnswer: 'CamAPS FX on yhteensopiva',
    compatibleCgmAnswer1: ':n ja',
    compatibleCgmAnswer2: ':n kanssa. Klikkaa linkkejä nähdäksesi yhteensopivat älypuhelimet ja käyttöjärjestelmät.',
    whatPumps: 'Mitkä insuliinipumput ovat yhteensopivia CamAPS FX -sovelluksen kanssa?',
    whatPumpsAnswer1: 'CamAPS FX on yhteensopiva',
    whatPumpsAnswer2: 'mylife YpsoPump-, Dana Diabecare RS-ja DANA-i-',
    whatPumpsAnswer3: 'insuliinipumppujen kanssa.',
    whatPumpsAnswerHtml: 'https://advancedtherapeutics.org.uk/shop/dana-diabecare-insulin-pump/dana-rs-insulin-pump-2018',
    whatPhone: 'Mitkä älypuhelimet ovat yhteensopivia CamAPS FX -sovelluksen kanssa?',
    whatPhoneAnswer: 'CamAPS FX toimii Android-puhelimessa ja on yhteensopiva CGM-valmistajan tukemien Android-älypuhelinmallien kanssa.',

    whatPhoneAnswer1: 'Napsauta tätä',
    whatPhoneAnswer2: 'linkkiä',
    whatPhoneAnswer3: 'nähdäksesi luettelon laitteista ja käyttöjärjestelmistä, jotka ovat yhteensopivia',
    whatPhoneAnswerDexcom: 'Dexcom G6 Sensorin ja Dexcom G6 -sovelluksen kanssa.',
    whatPhoneAnswerLibre3: 'FreeStyle Libre 3 Sensorin ja FreeStyle Libre 3 -sovelluksen kanssa.',
    whatPhoneAnswerDexcomHtml:'https://www.dexcom.com/compatibility',
    whatPhoneAnswerLibreHtml:'https://www.diabetescare.abbott/support/manuals.html',

    whereUpload: 'Minne voin ladata tietoni CamAPS FX -sovelluksesta?',
    whereUploadAnswer: 'Tällä hetkellä tiedot CamAPS FX -sovelluksesta voidaan ladata',
    whatLanguage: 'Mitkä kielet ovat käytettävissä CamAPS FX -sovelluksessa?',
    whatLanguageAnswer: 'CamAPS FX on saatavana englanniksi, tšekkiksi, tanskaksi, saksaksi, espanjaksi, ranskaksi, italiaksi, hollanniksi, puolaksi, suomeksi ja ruotsiksi.',
    whereResults: 'Mistä löydän kliinisten tutkimusten tuloksia?',
    whereResultsAnswer1: 'Cambridge-hallinta-algoritmin käyttöön liittyviä julkaisuja voidaan ladata',
    whereResultsAnswer2: 'tässä.',
    whereResultsAnswer3: 'Avainjulkaisut julkaistiin',
    whereResultsAnswer4: 'ja',
};
