const tab = '    ';
/**
 * Texts for Order page and modal popups
 */
export const orderPageText = {
    title: 'Zamawianie — CamDiab',
    titleMeta: 'Pobierz aplikację CamAPS FX — CamDiab',
    descriptionMeta: 'Płać zaledwie 70 GBP miesięcznie za aplikację CamAPS FX. Skorzystaj z jednomiesięcznego bezpłatnego okresu próbnego. Wymaga kompatybilnej pompy insulinowej Dana i czujnika Dexcom G6',
    localeMeta: 'pl_pl',

    keywords:
         'koszt camaps, koszt camaps fx, kolejność camaps, kolejność camaps fx, darmowa wersja próbna camaps fx',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    // discount code processing messages
    pleaseWait: 'Czekaj',
    successResult: 'Powodzenie',
    errorResult: 'Błąd przetwarzania',
    discountApplied: 'Kod rabatowy został zastosowany.',
    okButtonText: 'OK',

    // Discount line
    discountHeading: 'Rabat',
    discountOver: ' przez', // This reads: Discount: 30% over 3 months
    discountOneMonth: 'miesiąc',
    discountTwoAndMoreMonths: 'miesiące',

    // interest in CamAPS FX related messages
    processingEnquiry: 'Twoje zapytanie jest przetwarzane',
    thankYouForSubmission: 'Dziękujemy za przesłanie danych kontaktowych',
    processingError: 'Błąd przetwarzania',
    responseAck: 'Weźmiemy pod uwagę Twoje zapytanie i skontaktujemy się z Tobą, gdy aplikacja CamAPS FX będzie dostępna w Twoim kraju',

    stage1: 'Etap 1',
    subscriptionOption: 'Opcja subskrypcji',
    stage2: 'Etap 2',
    equipment: 'Sprzęt',
    stage3: 'Etap 3',
    personalInfo: 'Dane osobowe',
    stage4: 'Etap 4',
    orderConfirmation: 'Potwierdzenie zamówienia',

    currencySubscription: 'GBP',
    vatExempt: '(zwolnienie z podatku VAT)',

    costMonthly: 80,
    costSixMonthly: 450,
    costAnnual: 840,

    costMonthlyVAT: 96,
    costSixMonthlyVAT: 540,
    costAnnualVAT: 1008,

    subscriptionMonthly: 'Subskrypcja miesięczna',
    subscriptionSixMonthly: 'Subskrypcja sześciomiesięczna',
    subscriptionAnnual: 'Subskrypcja roczna',

    interested: 'Chcesz zakupić aplikację CamAPS FX?',
    interestedPara: 'Skorzystaj z kodu rabatowego <b>CamAPS30</b>, aby uzyskać dostęp do 30-dniowego bezpłatnego okresu próbnego bez żadnych zobowiązań.',

    subscriptionProcess: 'Nasz proces subskrypcji',
    choosePackage: 'Wybierz pakiet subskrypcji',
    answerQuestions: 'Odpowiedz na kilka pytań o sobie',
    receiveTraining: 'Weź udział w certyfikowanym szkoleniu online',

    // below are texts for API
    botCheckFailed: 'Niepowodzenie automatycznej kontroli (bot check). Spróbuj ponownie wkrótce lub skontaktuj się z nami pod adresem e-mail support@camdiab.com',
    wrongDiscountCode: 'Nieprawidłowy kod rabatowy lub kod rabatowy stracił ważność',
    pumpAlreadyExists: 'Nie można złożyć zamówienia. Pompa o numerze seryjnym %s jest już użytkowana. Sprawdź numer seryjny swojej pompy lub skontaktuj się z nami pod adresem e-mail sales@camdiab.com.',
    costTextWithDiscount: '%s%s za pierwszy okres (kod rabatowy %s), a następnie %s%s za kolejne okresy',
    emailOrderSubjectLine: 'Twoje zamówienie dotyczące aplikacji CamAPS FX',
    emailTrainingSubjectLine: 'Szkolenie dotyczące aplikacji CamAPS FX',
    emailInterestedSubjectLine: 'Zapytanie dotyczące aplikacji CamAPS FX (%s)',

    emailVATexemptYes: 'Tak',
    emailVATexemptNo: 'Nie',

    emailOrderBody: "Drogi(-a) %s,<p>Z przyjemnością potwierdzamy złożenie zamówienia dotyczącego aplikacji CamAPS FX. Poniżej przedstawiono szczegóły zamówienia:<p>" +
        tab + "Imię i nazwisko: %s %s<br>" +
        tab + "Adres e-mail: %s<br>" +
        tab + "Numer seryjny pompy: %s<br>" +
        tab + "Okres subskrypcji: %s<br>" +
        tab + "%s<br>" +
        tab + "Zwolnienie z podatku VAT: %s<br>" +
        tab + "Klinika organizująca szkolenie: %s<br><p><strong>Kolejne kroki</strong><br>Możesz odbyć <a href=\"https://www.camdiabtraining.com/account-sign-in.html\">szkolenie online dotyczące aplikacji CamAPS FX</a> albo Twój diabetolog może osobiście zaprezentować Ci, jak korzystać z aplikacji. Możesz pobrać aplikację CamAPS FX ze sklepu Amazon Appstore, lecz nie będziesz w stanie z niej korzystać, aż do momentu, w którym zakończysz szkolenie, a Twój diabetolog odblokuje Twoją aplikację, wprowadzając swój kod certyfikowanego szkolenia CamAPS FX. Po zakończeniu szkolenia będzie można natychmiast rozpocząć korzystanie z aplikacji CamAPS FX.<p><strong>Płatność</strong><br>Gdy aplikacja zostanie odblokowana i będziesz mieć do niej dostęp, prześlemy Ci fakturę w wiadomości e-mail. Preferujemy płatności poprzez polecenie zapłaty. Aby nieprzerwanie korzystać z aplikacji CamAPS FX, płatności należy dokonywać w terminie. Nie wpływa to na Twoje ustawowe prawa. Możesz anulować zamówienie w dowolnym momencie przed rozpoczęciem szkolenia lub w ciągu 14 dni od szkolenia, aby uzyskać zwrot wszystkich środków.<p><strong>Okres subskrypcji</strong><br>Twój okres subskrypcji rozpoczyna się w dniu rozpoczęcia szkolenia. Pamiętaj, że żadne płatności nie są pobierane przed rozpoczęciem szkolenia. Po upływie pierwszego okresu subskrypcji wymagane będą płatności za każdy kolejny okres subskrypcji, chyba że anulujesz zamówienie, przesyłając oświadczenie na piśmie pod adres e-mail %s.<p>Dziękujemy za Twoje zaufanie. Mamy nadzieję, że korzystanie z aplikacji CamAPS FX przyniesie Ci wiele korzyści.<p>Zespół CamDiab<br>%s",

    emailPotentialUser: "Drogi(-a) %s %s,<p>Dziękujemy za zainteresowanie aplikacją CamAPS FX. Niemniej otrzymaliśmy informację, że nie jesteś w stanie zapisać się na szkolenie dotyczące aplikacji CamAPS FX w swojej klinice diabetologicznej. Być może będziemy w stanie zorganizować dla Ciebie płatne szkolenie. Twoje dane kontaktowe:<p>" +
        tab + "Imię i nazwisko: %s %s<br>" +
        tab + "Adres e-mail: %s<br><p><strong>Kolejne kroki</strong><br>Przekażemy Twoje zapytanie do współpracujących z nami instruktorów ds. aplikacji CamAPS FX, którzy mogą być w stanie Ci pomóc. Jeśli instruktor potwierdzi taką możliwość, otrzymasz od niego oddzielną wiadomość e-mail. Po uzgodnieniu szczegółów dotyczących szkolenia z instruktorem konieczne będzie przesłanie zamówienia dotyczącego aplikacji CamAPS FX poprzez stronę internetową <a href=\"https://camdiab.com/\">camdiab.com</a>.<p><strong>Płatność za szkolenie</strong><br>Musisz ustalić z instruktorem ds. aplikacji CamAPS FX koszt szkolenia i oddzielnie zapłacić określoną kwotę. Usługi te nie są świadczone przez firmę CamDiab. <p><strong>Instruktorzy ds. aplikacji CamAPS</strong><br>Doceniamy naszych instruktorów ds. aplikacji CamAPS FX, którzy odbyli odpowiednie szkolenie.<p>Dziękujemy za zainteresowanie aplikacją CamAPS FX.<p>Zespół CamDiab<br>%s",

    emailInterestedUser: "Drogi(-a) %s %s,<p>Dziękujemy za zainteresowanie aplikacją CamAPS FX. Zarejestrowaliśmy Twoje zgłoszenie i skontaktujemy się z Tobą, gdy aplikacja CamAPS FX będzie dostępna w %s. Twoje dane kontaktowe:<p>" +
        tab + "Imię i nazwisko: %s %s<br>" +
        tab + "Adres e-mail: %s<br>" +
        tab + "Kraj: %s<br><p>Dziękujemy za zainteresowanie aplikacją CamAPS FX.<p>Zespół CamDiab<br>%s",
    emailOrderBody_1: 'Dziękujemy za zarejestrowanie się w celu rozpoczęcia użytkowania aplikacji CamAPS FX',
    emailOrderBody_2: 'Drogi(-a) %s,',
    emailOrderBody_3: 'Z przyjemnością potwierdzamy złożenie zamówienia dotyczącego aplikacji CamAPS FX. Poniżej przedstawiono szczegóły zamówienia:',
    emailOrderBody_4: 'Imię i nazwisko:',
    emailOrderBody_5: 'Adres e-mail:',
    emailOrderBody_6: 'Numer seryjny pompy:',
    emailOrderBody_7: 'Okres subskrypcji:',
    emailOrderBody_8: 'Koszt:',
    emailOrderBody_9: 'Zwolnienie z podatku VAT:',
    emailOrderBody_10: 'Klinika organizująca szkolenie:',
    emailOrderBody_11: 'Kolejne kroki',
    emailOrderBody_12: '<b>Po</b> rozpoczęciu korzystania z aplikacji CamAPS FX wyślemy Ci fakturę.',
    emailOrderBody_13: 'Zalecamy odbycie szkolenia online dotyczącego aplikacji CamAPS FX.',
    emailOrderBody_14: 'W przeciwnym razie Twój diabetolog osobiście zaprezentuje Ci, jak korzystać z aplikacji.',
    emailOrderBody_15: 'Możesz pobrać aplikację CamAPS FX ze sklepu Amazon Appstore, lecz nie będziesz w stanie z niej korzystać, aż do momentu, w którym zakończysz szkolenie, a Twój diabetolog odblokuje aplikację, wprowadzając swój kod certyfikowanego szkolenia CamAPS FX. Możesz również odblokować aplikację samodzielnie, wprowadzając swój kod certyfikowanego szkolenia CamAPS FX. ',
    emailOrderBody_16: 'Po odblokowaniu będzie można natychmiast rozpocząć korzystanie z aplikacji.',
    emailOrderBody_17: 'Płatność',
    emailOrderBody_18: 'Po zakończeniu szkolenia wyślemy Ci fakturę w wiadomości e-mail. Aby nieprzerwanie korzystać z aplikacji CamAPS FX, płatności należy dokonywać w terminie. Nie wpływa to na Twoje ustawowe prawa. Możesz anulować zamówienie w dowolnym momencie przed rozpoczęciem szkolenia lub w ciągu 30 dni od szkolenia, aby uzyskać zwrot wszystkich środków.',
    emailOrderBody_19: 'Okres subskrypcji',
    emailOrderBody_20: 'Twój okres subskrypcji rozpoczyna się w dniu rozpoczęcia szkolenia. Pamiętaj, że żadne płatności nie są pobierane przed rozpoczęciem szkolenia. Po upłynięciu pierwszego okresu subskrypcji wymagane będą płatności za każdy kolejny okres subskrypcji, chyba że anulujesz zamówienie, przesyłając oświadczenie na piśmie pod adres e-mail sales@camdiab.com.',
    emailOrderBody_21: 'Dziękujemy za subskrypcję aplikacji CamAPS FX',
    emailOrderBody_22: 'Aby uzyskać pomoc techniczną, odwiedź naszą stronę internetową w celu zapoznania się ze szczegółowymi informacjami.',
    emailOrderBody_23: '© 2021 CamDiab Ltd. Wszelkie prawa zastrzeżone.',

    // Texts for step one form
    s1MonthlyHeading: 'Subskrypcja miesięczna',
    s1MonthlyValue: '80 GBP*',
    s1Select: 'Wybierz',
    s1HalfYearlyHeading: 'Subskrypcja 6-miesięczna',
    s1HalfYearlyText: '450 GBP*',
    s1HalfYearlyTextSaving: '(oszczędzasz 30 GBP)',
    s1YearlyHeading: 'Subskrypcja roczna',
    s1YearlyText: '840 GBP*',
    s1YearlyTextSaving: '(oszczędzasz 120 GBP)',
    s1VATtext: ' *Plus podatek VAT, chyba że płatność jest zwolniona z podatku VAT. Pełna płatność musi zostać uiszczona po zakończeniu szkolenia / aktywacji aplikacji.',

    // Texts for step two form
    previous: 'Wstecz',
    next: 'Dalej',
    s2Yes: 'Tak',
    s2No: 'Nie',
    s2HaveDanaPump: 'Czy posiadasz pompę mylife YpsoPump, Dana Diabecare RS lub DANA-i?',
    s2HaveCgm: 'Czy posiadasz system CGM Dexcom G6?',
    s2Phone1: 'Czy posiadasz kompatybilny',
    s2PhoneHtml: 'https://www.dexcom.com/dexcom-international-compatibility',
    s2Phone2: 'smartfon z systemem operacyjnym Android',
    s2Phone3: '?',
    s2NoPump: 'Aby korzystać z aplikacji CamAPS FX, musisz używać pompy insulinowej mylife YpsoPump, Dana Diabecare RS lub DANA-i. Skontaktuj się z lokalnym zespołem ds. leczenia cukrzycy i omów dostępne opcje lub skontaktuj się z lokalnym dystrybutorem produktów Dana.',
    s2NoCgm: 'Aby korzystać z aplikacji CamAPS FX, musisz używać systemu CGM Dexcom G6. Skonsultuj się z lokalnym zespołem ds. leczenia cukrzycy w sprawie systemu CGM Dexcom G6 lub skontaktuj się z firmą Dexcom.',
    s2NoPhone: 'Jeśli nie korzystasz z kompatybilnego telefonu z systemem operacyjnym Android, nie będziemy w stanie zagwarantować niezawodnego połączenia z czujnikiem Dexcom G6. Rozważ zakup kompatybilnego telefonu z systemem operacyjnym Android.',

    // Texts for step three form
    s3InfoSource: [
        'Klinika',
        'Wyszukiwarka',
        'Media społecznościowe',
        'Post znajomego w mediach społecznościowych',
        'Organizacja JDRF',
        'Organizacja Diabetes UK',
        'Radio',
        'Telewizja',
        'Prasa',
        'Poczta pantoflowa',
        'Inne'],

    // s3TeritoryQuestion: 'Are you based in the UK, the EU, or New Zealand?',
    s3TeritoryQuestion: 'Czy mieszkasz w Wielkiej Brytanii lub na terytorium Unii Europejskiej?',
    s3NotAvailable: 'Niestety aplikacja CamAPS FX jest dostępna wyłącznie w określonych krajach. Wprowadź swoje dane, jeśli chcesz, żebyśmy skontaktowali się z Tobą, gdy system będzie dostępny w Twoim kraju.',
    s3SelectCountry: 'Wybierz kraj',
    s3TC1: 'Przesyłając wniosek, akceptujesz nasz',
    s3TC2: 'Regulamin',
    s3TC3: 'oraz',
    s3TC4: 'Politykę prywatności',
    s3TC5: 'i wyrażasz zgodę na kontakt.',
    s3Training1: 'Musisz odbyć szkolenie przeprowadzane przez certyfikowanego instruktora i/lub',
    s3Training2: 'szkolenie online,',
    s3Training3: 'zanim zaczniesz korzystać z aplikacji CamAPS FX.',

    s3FirstName: 'Imię',
    s3LastName: 'Nazwisko',
    s3Email: 'Adres e-mail',
    s3EmailConfirm: 'Potwierdź adres e-mail',
    s3AddressLine1: 'Pierwszy wiersz adresu',
    s3AddressLine2: 'Drugi wiersz adresu',
    s3AddressLine3: 'Trzeci wiersz adresu',
    s3PostCode: 'Kod pocztowy',
    s3SubmitCountry: 'Wyślij',
    s3DisabilityHeading: 'Potwierdzam, że cierpię na poniższe zaburzenie lub przewlekłą chorobę:',
    s3Disability: 'Zaburzenie lub przewlekła choroba',
    s3signature: 'Podpis (wpisz swoje imię i nazwisko)',

    s3TrainingCdep1: 'Wybierz szkolenie online dotyczące aplikacji CamAPS, aby kontynuować:',
    s3TrainingCdep2: 'Wybierz szkolenie',
    s3TrainingCdep3: 'Szkolenie online dotyczące aplikacji CamAPS',

    s3contactHeading: 'Wpisz swoje dane kontaktowe',
    s3whereHeard: 'Jak dowiedziałeś(-aś) się o aplikacji CamAPS FX?',
    s3whereHeardSelect: 'Wybierz jedną z poniższych odpowiedzi',
    s3pumpSerialNumber: 'Numer seryjny Twojej pompy Dana:',
    s3pumpSerialNumberConfirm: 'Potwierdź numer seryjny Twojej pompy Dana:',
    s3pumpSerialNumberMessage: 'Ważne:',
    s3pumpSerialNumberMessage1: 'Numer seryjny jest wymagany, aby zezwolić aplikacji CamAPS FX na nawiązanie połączenia z pompą. Nieprawidłowy numer seryjny może skutkować brakiem możliwości połączenia aplikacji CamAPS FX z pompą. Numer seryjny znajduje się z boku pompy. Przykładowy numer seryjny: THF00041FB.',
    s3vatExempt: 'Czy obejmuje Cię zwolnienie z podatku VAT?',
    s3vatHelp1: 'Aby uzyskać więcej informacji, zapoznaj się z arkuszami pomocy na',
    s3vatHelpHtml: 'https://www.gov.uk/government/organisations/hm-revenue-customs/contact/vat-reliefs-for-disabled-and-older-people',
    s3vatHelpLine: 'Porada dotycząca zwolnienia z podatku VAT',
    s3vatHelp2: 'stronie internetowej GOV.UK',
    s3vatHelp3: 'lub zadzwoń na infolinię dla osób niepełnosprawnych, którym przysługuje zwolnienie z podatku VAT, pod numerem telefonu 0300 123 1073.',
    s3vatHelp4: 'Personel HMRC nie może decydować, czy dana osoba jest przewlekle chora bądź niepełnosprawna.',
    s3vatHelp5: 'Ważne:',
    s3vatHelp6: 'Jeśli zamawiasz aplikację CamAPS FX w imieniu innej osoby, wpisz dane tej osoby w formularzu dotyczącym zwolnienia z podatku VAT.',
    s3PhoneWarning: 'Jeśli nie korzystasz z kompatybilnego telefonu z systemem operacyjnym Android, nie będziemy w stanie zagwarantować niezawodnego połączenia z czujnikiem Dexcom G6. Rozważ zakup kompatybilnego telefonu z systemem operacyjnym Android.',

    s3NameMissing: 'Pole Imię jest obowiązkowe',
    s3SurnameRequired: 'Pole Nazwisko jest obowiązkowe',
    s3EmailRequired: 'Pole Adres e-mail jest obowiązkowe',
    s3CountryRequired: 'Pole Kraj jest obowiązkowe',
    s3EmailConfimrRequired: 'Pole Potwierdź adres e-mail jest obowiązkowe',
    s3EmailMatchRequired: 'Adresy e-mail muszą być zgodne',
    s3ClinicRequired: 'Pole Klinika organizująca szkolenie jest wymagane',
    s3DiseaseStateRequired: 'Rodzaj użytkownika musi zostać podany',
    s3OnlyTextValueProvided: 'Zawiera tylko wartość tekstową',
    s3OnlyNumberValueProvided: 'Zawiera tylko liczby',
    s3SerialNumbersMatch: 'Numery seryjne muszą być zgodne',
    s3vatStatusRequired: 'Status podatku VAT musi zostać podany',
    s3AddressLine1Required: 'Pole Pierwszy wiersz adresu jest obowiązkowe',
    s3AddressLine2Required: 'Pole Drugi wiersz adresu jest obowiązkowe',
    s3PostCodeRequired: 'Pole Kod pocztowy jest obowiązkowe',
    s3SickessRequired: 'Pole Zaburzenie lub przewlekła choroba jest obowiązkowe',
    s3SignatureRequired: 'Pole Podpis jest obowiązkowe',

    // Texts for step four form
    s4NextSteps:'Kolejne kroki',
    s4DownloadApp:'Możesz pobrać aplikację CamAPS FX',
    s4DownloadGuideHtml:'https://s3-eu-west-1.amazonaws.com/camdiab.user.manual/Downloading+CamAPS+FX.pdf',
    s4DownloadAppGuide:'Instrukcja instalacji',
    s4Appstore:'ze sklepu Amazon Appstore,',
    s4DownloadApp1:'lecz nie będziesz w stanie z niej korzystać,',
    s4TrainingTextLink:'aż do momentu, w którym zakończysz szkolenie',
    s4TrainingHtml:'https://camdiab.com/training',
    s4TrainingTitle:'Szkolenie',

    s4Payment:'Płatności są pobierane po połączeniu aplikacji CamAPS FX z pompą.',

    s4TermsAccept:'Akceptuję',
    s4TermsTermsHtml:'https://camdiab.com/terms',
    s4TermsTermsText:'Regulamin',
    s4TermsPrivacyHtml:'https://camdiab.com/privacy',
    s4TermsPrivacyText:'Polityka prywatności',

    s4DiscountCodeText:'Kod rabatowy (opcjonalny)',
    s4DiscountApplyCode:'Zastosuj kod',

    s4Summary:'Podsumowanie zamówienia',
    s4Name:'Imię i nazwisko:',
    s4Email:'Adres e-mail:',
    s4PumpSN:'Numer seryjny pompy:',
    s4Training:'Szkolenie:',
    s4Total:'Łącznie',

    s4CompleteButtonText:'Zakończ',

    // duration or subscription period in an email
    emailOneMonth: '1 miesiąc',
    emailSixMonths: '6 miesięcy',
    emailTwelveMonths: 'Rok',

    // Order complete
    oderProcessedHeading: 'Twoje zamówienie jest przetwarzane',
    oderProcessedSucess: 'Dziękujemy, Twoje zamówienie zostało pomyślnie złożone',
    oderProcessedError: 'Błąd przetwarzania',
    oderProcessedEmailToBeSent: 'Wkrótce otrzymasz wiadomość e-mail z potwierdzeniem zamówienia',
};
