import React, {Component} from 'react';
import {Header} from '../Components/Header';
import {Footer} from '../Components/Footer';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

export class TermsES extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Condiciones generales - CamAPS FX</title>
                    <meta name="title" content='Condiciones generales'/>
                    <meta name="description"
                          content='Aquí puede encontrar los términos y condiciones de CamDiab'/>
                    <meta property="og:locale" content='es_es'/>
                    <meta name="keywords" content='condiciones generales'/>
                </Helmet>
                <Header props={this.props}/>
                <section className="inner-banner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-10">
                                <h1>Condiciones generales</h1>
                                <h3>Versión fechada el 24 de junio de 2024</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <h2>
                                    1. ALCANCE
                                </h2>
                                <h3>
                                    1.1. Nuestros productos
                                </h3>
                                <p>
                                    1.1.1. Nosotros,
                                </p>
                                <p>
                                    CamDiab Ltd <strong>
                                    <br/>
                                    Dirección de la oficina registrada<br/>
                                    20-22 Wenlock Road<br/>
                                    London, N1 7GU, UK
                                    <br/>
                                </strong>
                                    support@camdiab.com
                                    <br/>
                                    número de empresa 11659211 Inglaterra y Gales
                                    <br/>
                                    <br/>
                                </p>
                                <p>
                                    (“<strong>CamDiab</strong>”), desarrollamos y gestionamos aplicaciones móviles (aplicaciones) para la administración de insulina en Loop cerrado (automático) y una infraestructura de datos enlazados para personas con diabetes y profesionales sanitarios. CamDiab es una empresa sanitaria digital, cuyo propósito es ofrecer a las personas con diabetes un mejor control de la glucosa y reducir la carga del autotratamiento de la diabetes.
                                </p>
                                <p>
                                    1.1.2 Nuestros productos orientan y asisten la administración de insulina en Loop cerrado (automático) según un sistema basado en todo nuestro saber y hacer. Nuestros productos y servicios no pueden sustituir la consulta y el diagnóstico de un profesional sanitario. En caso de dudas sobre la enfermedad y el tratamiento, recomendamos que se dirija a un profesional sanitario. Los datos proporcionados solo los deberán analizar usted y su profesional sanitario. Ningún contenido ─ independientemente de que lo proporcionen CamDiab, nuestros colaboradores, distribuidores, contratistas o usuarios─ se considerará complementario o sustitutivo de la información proporcionada por un profesional sanitario o farmacéutico.
                                </p>
                                <p>
                                    1.1.3 CamDiab puede ofrecer una gama de productos (aplicaciones) y servicios, así como permitir el intercambio de datos para asistir el tratamiento de la diabetes. En lo sucesivo, todas las aplicaciones y servicios actuales y futuros (incluidas las nuevas versiones, actualizaciones y otras mejoras) que proporcione CamDiab en cualquier modalidad se denominan conjuntamente “<strong>productos</strong>” o individualmente “<strong>producto</strong>”. Toda persona que adquiera y utilice uno o varios de nuestros productos se denomina conjuntamente “<strong>usuarios</strong>” o individualmente “<strong>usuario</strong>”. Si estas Condiciones generales se refieren a personas físicas utilizando el género masculino, se aplican igualmente a todas las personas.
                                </p>
                                <p>
                                    1.1.4 En relación con estas Condiciones generales, se aplican a los productos individuales las siguientes definiciones terminológicas:
                                </p>
                                <p>
                                    <strong>CamAPS FX </strong> es una aplicación (app) móvil independiente para su smartphone que ofrece la administración de insulina en Loop híbrido cerrado a personas con diabetes tipo 1. La aplicación se enlaza con un sensor de glucosa y una bomba de insulina de otros fabricantes. Puede decidir compartir los datos recogidos por la aplicación con CamDiab o con portales de diabetes de otros fabricantes para consultar los datos. Es posible que se apliquen restricciones en el modelo de smartphone apto en función de la bomba y el sensor de glucosa del fabricante en cuestión.
                                </p>
                                <p>
                                    <strong>CamAPS HX </strong> es una aplicación similar a CamAPS FX pero que ofrece un circuito completamente cerrado en diabetes no tipo 1.
                                </p>
                                <p>
                                    1.1.5 Nuestros manuales de usuario (accesibles a través de nuestras aplicaciones o de nuestro sitio web) y las disposiciones especiales pertinentes hacen hincapié en cualquier peculiaridad de los productos individuales.
                                </p>
                                <h3>
                                    1.2. Alcance de nuestras condiciones generales
                                </h3>
                                <p>
                                    1.2.1 Ofrecemos nuestros productos exclusivamente sobre la base de la versión actual de estas Condiciones generales (CG), que se aplican internacionalmente. CamDiab se opone explícitamente a los términos comerciales contradictorios o a otros usos.
                                </p>
                                <p>
                                    1.2.2 Tenga en cuenta que antes de utilizar nuestros productos por primera vez debe dar su consentimiento a todas las declaraciones y acuerdos mencionados, así como aceptarlos en cualquier uso posterior. Quedan prohibidos el registro y el uso de nuestros productos sin su consentimiento de nuestras CG.
                                </p>
                                <p>
                                    1.2.3 Nos reservamos explícitamente el derecho a realizar futuros cambios y modificaciones justificadas de nuestras CG. Los cambios pueden ser necesarios para cumplir con los requisitos legales, satisfacer los requisitos técnicos y económicos o responder a los intereses de nuestros usuarios. Estos cambios son posibles en cualquier momento y se publicarán debidamente. Si no se impugnan al cabo de un mes, el uso continuado de nuestros productos estará sujeto a las nuevas CG correspondientes.
                                </p>
                                <h3>
                                    1.3. Proveedores externos
                                </h3>
                                <p>
                                    1.3.1 Encontrará nuestros productos en las app stores. En general, se aplican las condiciones comerciales del proveedor externo en cuestión (en las que no ejercemos influencia alguna); estas no forman parte de nuestras CG ni de nuestra relación contractual con usted como usuario de nuestros productos. Esto también se aplica a las condiciones comerciales de su dispositivo móvil o proveedor de servicios.
                                </p>
                                <p>
                                    1.3.2 Colaboramos con diferentes socios para garantizar el uso óptimo de nuestros productos. Entre ellos hay socios colaboradores que suministran determinados productos directamente a nuestros usuarios (p. ej., bomba de insulina, monitor continuo de glucosa). En estos casos, subrayamos las condiciones comerciales aplicables de los proveedores ajenos, ya que usted deberá aceptar estas condiciones comerciales antes de poder empezar a utilizar nuestros productos.
                                </p>
                                <h2>
                                    2. PROTECCIÓN DE DATOS
                                </h2>
                                <p>
                                    <strong> Somos conscientes de la gran responsabilidad que conlleva el uso de nuestros productos. No solo nos está proporcionando datos personales generales, sino también datos sobre su salud. En nuestra </strong>
                                    <Link to="/es/privacy">
                                        Política de privacidad </Link> <strong> le informamos sobre el uso y la protección de sus datos en relación con el uso de nuestros productos y sobre su consentimiento necesario para ello. </strong>
                                </p>
                                <p>
                                    <strong> SIN SU CONSENTIMIENTO DE PRIVACIDAD PARA EL TRATAMIENTO NECESARIO DE LOS DATOS, NO SE PODRÁN UTILIZAR LOS PRODUCTOS EN TÉRMINOS LEGALES O REALES. </strong>
                                </p>
                                <p>
                                    <strong>Nuestra </strong>
                                    <Link to="/es/privacy"> Política de privacidad </Link> <strong> no forma parte de estas CG, sino que solo se utiliza para proporcionarle información en virtud del RGPD según el que usted da su consentimiento para el tratamiento de datos necesario. </strong>
                                </p>
                                <h2>
                                    3. CONCLUSIÓN DEL CONTRATO Y REGISTRO
                                </h2>
                                <h3>
                                    3.1. Compra y pago de nuestros productos
                                </h3>
                                <p>
                                    3.1.1 Nuestros productos pueden adquirirse por diferentes medios:
                                </p>
                                <h3>
                                    Apps
                                </h3>
                                <p>
                                    Las <strong>app stores</strong> (como Google Play y Amazon Appstore) tienen nuestros productos a la venta. Tenga en cuenta que para descargar (no utilizar) nuestros productos, se aplican las condiciones comerciales de la app store, que pueden requerir una cuenta de usuario.
                                </p>
                                <p>
                                    <strong>Nuestro sitio web</strong> puede facilitar el acceso directo a nuestros productos y permite encargarlos.
                                </p>
                                <p>
                                    Pueden haber en circulación <strong>códigos de descuento</strong> para nuestros productos, canjeables en nuestro sitio web.
                                </p>
                                <p>
                                    3.1.2 Puede encargar los productos no gratuitos en nuestro sitio web o a través de nuestros distribuidores. Se pagan a través del proveedor de servicios de pago, nuestros distribuidores u otras opciones de pago especificadas. El distribuidor ejerce como agente y proveedor de servicios de pago para CamDiab o como vendedor directo. Si tiene dificultades con la descarga o el pago, diríjase en cualquier momento a <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <p>
                                    3.1.3 Las tarifas por uso o los precios de compra (incluido el impuesto sobre el valor añadido vigente) se deben abonar ─según la oferta y la selección─ en una o más veces (p. ej., mensual, semestral o anualmente). Las tarifas actuales constan en el sitio web, clasificadas por distribuidor, o se muestran en la aplicación o en las app stores, y se deben pagar por adelantado. Si el pago no se procesa directamente a través de la app store, el proveedor de pagos o el distribuidor puede enviarle facturas y recordatorios de pago por correo electrónico. En caso de pago doloso, tardío o incompleto, tenemos derecho a bloquearle el acceso a nuestros productos. Ello no afecta su obligación de pago de la tarifa por uso.
                                </p>
                                <p>
                                    3.1.4 CamDiab también puede presentar ofertas especiales y gratuitas, sujetas a otras condiciones o restricciones. A este respecto, nos referimos explícitamente a cualquier variación de estas CG. Por lo demás, el contenido completo de estas CG sigue siendo aplicable. Las ofertas especiales o gratuitas no se pueden transferir a otros usuarios.
                                </p>
                                <h3>
                                    3.2. Registro y activación
                                </h3>
                                <p>
                                    3.2.1 Para usar nuestros productos es preciso registrarse en CamDiab, en nuestras aplicaciones. Una vez haya activado nuestro producto por primera vez, deberá registrarse con una dirección de correo electrónico y una contraseña (ID CamDiab). Cada usuario solo podrá generar un ID CamDiab que solo puede utilizar un usuario.
                                </p>
                                <p>
                                    3.2.2 Al registrarse, el usuario acepta su oferta vinculante de suscribir un contrato con CamDiab para el uso de nuestros productos de acuerdo con la versión aplicable de estas CG. Aceptamos explícitamente dicha oferta, a más tardar al activar su ID CamDiab.
                                </p>
                                <p>
                                    3.2.3 La activación se confirmará una vez se hayan aceptados sus datos de acceso y haya recibido la formación adecuada. Nos reservamos el derecho a rechazar, sin indicar el motivo, a usuarios individuales o su propuesta de suscribir un contrato. En ese caso, se reembolsarán los pagos ya abonados y se eliminarán inmediatamente los datos del usuario facilitados.
                                </p>
                                <p>
                                    3.2.4 Al registrarse, el usuario confirma que tiene al menos dieciséis años y que la legislación aplicable le permite suscribir contratos. Solo las personas con capacidad jurídica están autorizadas a suscribirse. Los menores de edad solo se aceptarán con el acuerdo explícito de un progenitor/tutor. Con el registro, el progenitor/tutor declara su autoridad para presentar declaraciones jurídicamente vinculantes en nombre del menor.
                                </p>
                                <p>
                                    3.2.5 Al registrarse, el usuario confirma que conoce y acepta sin reservas el contenido de estas CG generales y que sus datos son verdaderos, correctos, actuales y completos. Nos reservamos el derecho a dirigirnos a los usuarios en cualquier momento para verificar los datos de registro y la información de uso.
                                </p>
                                <p>
                                    3.2.6 Si el usuario proporciona información falsa, incorrecta, obsoleta o incompleta, o tenemos razones sólidas para creer que la información es falsa, incorrecta, obsoleta o incompleta, CamDiab tendrá derecho a bloquear el correspondiente ID CamDiab con efecto inmediato y sin previo aviso. Asimismo, tendrá derecho a prohibir el uso de nuestros productos, sin la obligación de reembolsar al usuario los costes en que haya incurrido.
                                </p>
                                <p>
                                    3.2.7 El usuario debe proteger sus datos de registro del acceso no autorizado de terceros, del uso indebido o del uso con intención fraudulenta. Si existe la más mínima sospecha de que el ID CamDiab ha estado expuesto a un riesgo de este tipo, deberá notificarse sin demora a <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>. Tenemos derecho a bloquear el ID CamDiab de cualquier usuario si se utiliza con fines ilícitos o fraudulentos.
                                </p>
                                <h2>
                                    4. DERECHO DE CANCELACIÓN Y REVOCACIÓN
                                </h2>
                                <h3>
                                    4.1. Información sobre el ejercicio del derecho de revocación
                                </h3>
                                <p>
                                    4.1.1 Si no utiliza nuestros productos con fines comerciales o profesionales, como consumidor tiene derecho a rescindir el contrato suscrito con CamDiab en las siguientes condiciones:
                                </p>
                                <p>
                                    <strong>DERECHO DE RESCISIÓN</strong>
                                </p>
                                <p>
                                    <strong><u>Derecho de rescisión</u></strong>
                                </p>
                                <p>
                                    <strong> Tiene derecho a rescindir el presente contrato en un plazo de catorce días sin indicar el motivo. </strong>
                                </p>
                                <p>
                                    <strong> El plazo de rescisión es de catorce días a partir de la fecha de suscripción del contrato (activación del contenido digital). </strong>
                                </p>
                                <p>
                                    <strong> Para ejercer su derecho de rescisión, debe informarnos de su decisión de rescindir el contrato mediante una declaración explícita dirigida al distribuidor o a CamDiab, correo electrónico </strong> <strong> <a href="mailto:support@camdiab.com">support@camdiab.com</a> </strong> <strong> (p. ej., por correo electrónico o postal). </strong>
                                </p>
                                <p>
                                    <strong> Para mantener el plazo de rescisión, basta con que envíe la notificación de ejercicio del derecho de rescisión antes de que finalice el plazo de rescisión. </strong>
                                </p>
                                <p>
                                    <strong><u>Consecuencias de la rescisión</u></strong>
                                </p>
                                <p>
                                    <strong> Si rescinde este contrato, el distribuidor o nosotros deberemos reembolsar todos los pagos que hayamos recibido de usted, sin demora y como máximo en un plazo de catorce días a contar a partir de la fecha en que hayamos recibido la notificación de su rescisión de este contrato. Siempre que sea posible, llevaremos a cabo este reembolso mediante el mismo método de pago que usted utilizó para la transacción original, a menos que hayamos acordado explícitamente lo contrario; en ningún caso se incurrirá en gastos derivados de este reembolso. </strong>
                                </p>
                                <h2>
                                    5. USO DE NUESTROS PRODUCTOS
                                </h2>
                                <h3>
                                    5.1. Plazo de uso y cese
                                </h3>
                                <p>
                                    5.1.1 Su derecho a utilizar nuestros productos comienza con el registro y la activación por parte de CamDiab.
                                </p>
                                <p>
                                    5.1.2 Nuestros productos no gratuitos se pueden adquirir en forma de suscripciones mensuales, semestrales o anuales, y según la oferta actual también están disponibles para un tiempo de uso inferior o mayor (en lo sucesivo, “<strong>tiempo de suscripción</strong>”).
                                </p>
                                <p>
                                    5.1.3 Tanto el usuario como CamDiab tienen derecho a rescindir el contrato de forma ordinaria en cualquier momento mediante notificación a tal efecto un mes antes. La rescisión será efectiva al final del mes. Puede hacerlo dirigiéndose a su distribuidor o notificándonoslo por escrito a <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>. Tenga en cuenta que las tarifas ya abonadas por un periodo de suscripción no disfrutado solo se reembolsan en caso de rescisión ordinaria por parte de CamDiab o de cese automático (apartado 5.1.5 siguiente).
                                </p>
                                <p>
                                    5.1.4 Si no hay cese o este es tardío, el tiempo de suscripción se prolongará automáticamente por otro tiempo correspondiente al tiempo de suscripción anteriormente seleccionado. Se aplica el precio estándar correspondiente en el momento de la prórroga (no los precios de las ofertas especiales o gratuitas).
                                </p>
                                <p>
                                    5.1.5 En caso de defunción del usuario o de revocación del consentimiento de privacidad, la relación de uso finaliza automáticamente con efecto inmediato. CamDiab no está autorizado a tratar sus datos sanitarios sin su consentimiento.
                                </p>
                                <p>
                                    5.1.6 Si tiene dudas sobre el tiempo de uso o cuándo concluye, diríjase en cualquier momento a <a href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h3>
                                    5.2. Bloqueo y exclusión
                                </h3>
                                <p>
                                    5.2.1 Nos reservamos el derecho a bloquear temporalmente a los usuarios en cualquier momento por cualquier razón importante (a nuestra discreción) o a excluirlos permanentemente del uso de nuestros productos. En caso de finalización del contrato sin plazo de preaviso, queda excluido el reembolso de las tasas de uso.
                                </p>
                                <p>
                                    5.2.2 Un motivo importante se da, en particular, en el caso de infracciones graves por parte de un usuario de lo dispuesto en estas CG, p. ej., infracciones del apartado 3.1 (Pago) después de establecer un período de gracia de dos semanas, del apartado 3.2 (Datos de registro), del apartado 5.3 (Licencia) o del apartado 6 ("Su comportamiento").
                                </p>
                                <h3>
                                    5.3. Licencia y concesión de derechos
                                </h3>
                                <p>
                                    5.3.1 Con su registro y activación, usted adquiere el derecho no exclusivo e intransferible, pero geográficamente ilimitado, a guardar y utilizar una copia de nuestra aplicación para sus propios fines en el contexto de estas CG. En el caso de los productos no gratuitos, esta licencia está limitada a la duración del periodo de suscripción contractual.
                                </p>
                                <p>
                                    5.3.2 El software, el código, los métodos y los sistemas, así como el contenido de nuestros productos, están protegidos por la ley de derechos de autor y competencia, y los utilizará exclusivamente CamDiab. Nuestros productos o partes de los mismos no se copiarán, modificarán, reproducirán, republicarán, publicarán transferirán, venderán, pondrán a la venta, revenderán ni utilizarán de ningún otro modo, ni total ni parcialmente, sin nuestro consentimiento previo por escrito. Los usuarios no están autorizados a utilizar marcas, logotipos, otros derechos de propiedad comercial o derechos de marca de CamDiab. Salvo que se especifique lo contrario en estas CG, todos los derechos de uso y explotación son propiedad exclusiva de CamDiab y no hay ningún tipo de licencia para nuestros productos.
                                </p>
                                <p>
                                    5.3.3 Si fuera necesario para la prestación técnica de nuestros productos, todo usuario concede a CamDiab un derecho de explotación y uso no exclusivo, revocable pero gratuito y transferible, sin restricciones de tiempo y lugar, sobre los contenidos que genere, transfiera, almacene o publique en el marco de nuestros productos. Sin embargo, se excluye el uso o la explotación si afecta desproporcionadamente los intereses legítimos del usuario (p. ej., derechos de privacidad). En caso de utilización fuera del marco de nuestros productos, indicaremos, si procede, que el contenido procede del usuario. CamDiab no reclama la propiedad de los contenidos creados ni asumirá función alguna de supervisión con respecto a los contenidos generados por los usuarios.
                                </p>
                                <h3>
                                    5.4. Disponibilidad de nuestros productos
                                </h3>
                                <p>
                                    5.4.1 Suministramos nuestros productos en cada caso de acuerdo con las posibilidades técnicas, económicas, operativas y organizativas existentes. CamDiab no puede excluir las interrupciones, alteraciones, retrasos, eliminaciones, transmisiones incorrectas o fallos de almacenamiento en relación con el uso de nuestros productos o la comunicación con los usuarios. En parte, ofrecemos nuestros productos en colaboración con distribuidores y proveedores externos, por lo que también dependemos de la prestación técnica de servicios de terceros. Por lo tanto, CamDiab no acepta ninguna responsabilidad, garantía, obligación ni compromiso de suministrar nuestros productos en línea en todo momento sin interrupciones.
                                </p>
                                <p>
                                    5.4.2 Lo mismo se aplica a las restricciones del uso de nuestros productos por causas de fuerza mayor, huelgas, cierres patronales e instrucciones oficiales, o debido a modificaciones técnicas o trabajos de mantenimiento en los sistemas CamDiab. En caso de interrupciones o fallos de nuestros productos, diríjase en cualquier momento a <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h2>
                                    6. SU COMPORTAMIENTO
                                </h2>
                                <h3>
                                    6.1. Información general
                                </h3>
                                <p>
                                    6.1.1 Cada usuario tiene el derecho y la obligación de utilizar nuestros productos por su cuenta y riesgo, mientras cuente con equipos técnicos adecuados. Los dispositivos y sistemas operativos compatibles con los productos se pueden encargar en cualquier momento a <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <p>
                                    6.1.2 Asimismo, está obligado a utilizar nuestros productos solo si cumple estas CG y de las disposiciones legales, así como a evitar todo uso indebido. Le rogamos que nos informe inmediatamente si detecta cualquier incumplimiento de los deberes indicados en este apartado, escribiéndonos a <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h3>
                                    6.2. Uso indebido
                                </h3>
                                <p>
                                    6.2.1 Todo usuario está obligado a declarar sus datos de registro de forma veraz, a mantenerlos actualizados y completos, así como a no cederlos a terceros. Los datos personales se deben tratar confidencialmente, administrarse cuidadosamente y salvaguardarse bajo la propia responsabilidad. CamDiab no acepta responsabilidad alguna ni otras garantías por la pérdida o el daño de los datos o contenidos si no es por fallo nuestro.
                                </p>
                                <p>
                                    6.2.2 Los contenidos como fotos, imágenes, textos, vídeos u otras representaciones solo se pueden guardar, publicar, transferir o distribuir en relación con nuestros productos si el usuario tiene derecho a transferirlos o utilizarlos. En todos los casos queda prohibido el uso de contenidos racistas, ofensivos, discriminatorios, difamatorios, sexuales, pornográficos, violentos o ilegales de cualquier tipo.
                                </p>
                                <p>
                                    6.2.3 Además, queda prohibido descifrar, realizar ingeniería inversa, descompilar o desensamblar nuestros productos o utilizarlos en smartphones "rooteados". Todo usuario tiene prohibido realizar interferencias perjudiciales por medios técnicos o electrónicos en nuestros productos (en particular, intentos de piratería informática, ataques directos; introducción de virus, gusanos, troyanos, otro software malicioso) ni ningún tipo de intento de alteración que pueda afectar el software o hardware de los productos y sistemas de CamDiab. Cualquier manipulación de nuestros productos puede impedir que funcionen de acuerdo con el uso previsto.
                                </p>
                                <h2>
                                    7. NUESTRA RESPONSABILIDAD
                                </h2>
                                <h3>
                                    7.1. Garantía y responsabilidad
                                </h3>
                                <p>
                                    7.1.1 Salvo que se disponga lo contrario en las presentes CG, CamDiab se responsabiliza y ofrece las garantías previstas en las disposiciones legales.
                                </p>
                                <p>
                                    7.1.2 Con respecto a los usuarios que no son consumidores, se excluye la responsabilidad por pérdidas económicas, daños consecuenciales, lucro cesante y daños derivados de reclamaciones de terceros. Si nuestra responsabilidad está limitada o excluida en estas CG, lo mismo se aplica a los agentes indirectos y a las empresas afiliadas de CamDiab.
                                </p>
                                <p>
                                    7.1.3 Fuera del ámbito de aplicación de la legislación sobre responsabilidad de productos, la responsabilidad de CamDiab se limita a los actos deliberados y a la negligencia grave, con excepción de los daños personales. La responsabilidad por negligencia leve queda excluida ante las justificaciones objetivas consecuentes.
                                </p>
                                <p>
                                    7.1.4 CamDiab no garantiza que nuestros productos estén disponibles en su totalidad sin interrupción y sin errores, ni que el software y el hardware necesarios funcionen sin errores. Tampoco podemos excluir la posibilidad de que terceros rastreen, registren o falsifiquen los datos durante la transferencia de estos a través de sistemas de terceros, en particular Internet y otras redes de telecomunicaciones.
                                </p>
                                <p>
                                    7.1.5 CamDiab no asume garantía alguna por los contenidos o materiales descargados que los usuarios hayan recibido por utilizar nuestros productos. El usuario es el único responsable de todos los daños que se puedan producir en su sistema o dispositivos informáticos, o de la pérdida de datos, por la descarga de materiales asociados a nuestros productos.
                                </p>
                                <p>
                                    7.1.6 Asimismo, CamDiab no se responsabiliza de los contenidos de terceros, como enlaces externos, banners, otra información u ofertas publicitarias de terceros que se puedan integrar como parte de nuestros productos. Si permitimos el acceso a las ofertas de terceros a través de notificaciones o enlaces, CamDiab no es responsable de la información que estos contengan. Las transacciones legales resultantes con terceros dan lugar exclusivamente a relaciones contractuales entre el usuario y los terceros correspondientes. No asumimos ninguna garantía u otra responsabilidad por los servicios de terceros.
                                </p>
                                <h3>
                                    7.2. Aviso legal e indemnización
                                </h3>
                                <p>
                                    7.2.1 El uso de nuestros productos no sustituye la consulta con un profesional sanitario ni otro tipo de asesoramiento médico, y se realiza exclusivamente por cuenta y riesgo del usuario. Esta cláusula es de aplicación a cualquier uso que haga el usuario de los datos suministrados como parte de nuestros productos, incluidos los resultados de la glucosa en sangre y del sensor, los cálculos y cualquier recomendación. El usuario reconoce explícitamente que dichos datos pueden ser defectuosos y CamDiab no se responsabiliza de que sean correctos.
                                </p>
                                <p>
                                    7.2.2 En la medida en que lo exija la legislación aplicable sobre productos sanitarios que rige el uso de los productos, estos solo se podrán manejar o utilizar de acuerdo con la finalidad, las especificaciones y los ámbitos de aplicación especificados en la oferta y las condiciones de uso.
                                </p>
                                <p>
                                    7.2.3 El usuario indemnizará a CamDiab por todas las reclamaciones de terceros presentadas contra CamDiab a raíz de la vulneración de sus derechos por parte del usuario en relación con el uso de nuestros productos. El resto de reclamaciones por daños y perjuicios de CamDiab contra el usuario permanecen inalteradas.
                                </p>
                                <p>
                                    7.2.4 El usuario acepta la plena responsabilidad por todos los daños y litigios judiciales y extrajudiciales derivados de los conflictos con otros usuarios. El usuario reconoce explícitamente que CamDiab no es en ningún caso responsable de las acciones u omisiones de otros usuarios ni de los daños resultantes.
                                </p>
                                <p>
                                    7.2.5 En caso de que terceros presenten reclamaciones contra CamDiab, el usuario está obligado a facilitar inmediatamente de forma veraz y completa toda la información de la que disponga y que sea necesaria para revisar, defender y evitar las reclamaciones de terceros. El usuario asume los costes de cualquier defensa legal necesaria por parte de CamDiab, incluidos todos los gastos de defensa y judiciales en el importe legal.
                                </p>
                                <h2>
                                    8. DISPOSICIONES FINALES
                                </h2>
                                <h3>
                                    8.1. Elección de la legislación aplicable
                                </h3>
                                <p>
                                    Estas condiciones generales y la totalidad de la relación jurídica entre CamDiab y el usuario están sujetas exclusivamente a la legislación inglesa, a excepción de sus disposiciones de referencia y de la Convención de las Naciones Unidas sobre la Compraventa. Sin embargo, como consumidor, el usuario goza de la protección de las disposiciones obligatorias del país donde se encuentra o reside habitualmente.
                                </p>
                                <h3>
                                    8.2. Lugar de jurisdicción
                                </h3>
                                <p>
                                    Se pacta que la sede de CamDiab en Cambridge, Reino Unido, sea el lugar de cumplimiento y el único tribunal competente para todo conflicto derivado de estas CG. Los conflictos entre legislaciones de obligado cumplimiento no se ven afectados.
                                </p>
                                <h3>
                                    8.3. Otros
                                </h3>
                                <p>
                                    8.3.1 Si las presentes CG exigen la notificación o la forma escrita (también en caso de declaraciones jurídicamente importantes), basta con enviarla por correo electrónico a la dirección indicada en el registro (ID CamDiab).
                                </p>
                                <p>
                                    8.3.2 Los usuarios no tienen derecho a transferir a terceros su ID CamDiab, ni los derechos y obligaciones de estas CG relacionados con el uso de nuestros productos. CamDiab puede transferir sus derechos y obligaciones de estas CG a otra empresa o a un tercero, si dicha transferencia no tiene efectos significativos sobre los derechos del usuario de estas CG.
                                </p>
                                <p>
                                    8.3.3 Si alguna de las disposiciones de estas CG no tuviese vigencia o la perdiese, las demás disposiciones de estas CG que no se vean afectadas por la invalidez seguirán siendo válidas y aplicables.
                                </p>
                                <p>
                                    8.3.4 Estas CG se entregan en diferentes idiomas. La versión en el idioma del país donde se encuentra o reside habitualmente el consumidor es clave y definitiva. En la medida en que no exista una versión en el idioma nacional correspondiente, si es ese el caso, la versión en inglés es la definitiva.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer props={this.props}/>
            </div>
        );
    }
}

