import React, {Component} from 'react';
import {Header} from '../Components/Header';
import {Footer} from '../Components/Footer';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

export class TermsDK extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Vilkår og betingelser - CamAPS FX</title>
                    <meta name="title" content='Vilkår og betingelser'/>
                    <meta name="description"
                          content='Her kan du finde CamDiabs vilkår og betingelser'/>
                    <meta property="og:locale" content='da_dk'/>
                    <meta name="keywords" content='vilkår og betingelser'/>
                </Helmet>
                <Header props={this.props}/>
                <section className="inner-banner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-10">
                                <h1>Vilkår og betingelser</h1>
                                <h3>Version dateret 24. juni 2024</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <h2>
                                    1. ANVENDELSESOMRÅDE
                                </h2>
                                <h3>
                                    1.1. Vores produkter
                                </h3>
                                <p>
                                    1.1.1. Vi
                                </p>
                                <p>
                                    CamDiab Ltd <strong>
                                    <br/>
                                    Registreret kontoradresse<br/>
                                    20-22 Wenlock Road<br/>
                                    London, N1 7GU, UK
                                    <br/>
                                </strong>
                                    support@camdiab.com
                                    <br/>
                                    virksomhedsnummer 11659211 England og Wales
                                    <br/>
                                    <br/>
                                </p>
                                <p>
                                    (“<strong>CamDiab</strong>”), udvikler og driver mobilapplikationer (apps) til insulinindgivelse i et lukket (loop) system og den dertil hørende datainfrastruktur for mennesker med diabetes og behandlere. CamDiab er et digitalt sundhedsselskab og ønsker at tilbyde mennesker med diabetes bedre glukosekontrol og lette byrden ved selvbehandling af diabetes.
                                </p>
                                <p>
                                    1.1.2 Vores produkter guider og understøtter efter vores bedste overbevisning insulinindgivelse i et lukket (loop) system. Vores produkter og tjenester kan ikke erstatte konsultation og diagnose hos en behandler. Hvis du har spørgsmål til sygdommen og behandlingen, anbefaler vi, at du kontakter en behandler. De opgivne data må kun analyseres af dig og din behandler. Intet indhold – uanset om det leveres af CamDiab selv, vores samarbejdspartnere, forhandlere, kontraktleverandører eller brugere – må forstås som et supplement til eller erstatning for information fra en behandler eller fra apotekspersonale.
                                </p>
                                <p>
                                    1.1.3 CamDiab kan derudover tilbyde en række produkter (apps) og tjenester, som tillader datadeling til støtte for din diabetesbehandling. Alle nuværende og fremtidige apps og tjenester (inklusive nye versioner, opdateringer og andre forbedringer), som CamDiab leverer på en hvilken som helst måde, kaldes efterfølgende tilsammen “<strong>produkter</strong>” eller hver for sig et “<strong>produkt</strong>”. Personer, som køber eller bruger et eller flere af vores produkter, kaldes tilsammen “<strong>brugere</strong>” eller hver for sig “<strong>bruger</strong>”. Hvis disse vilkår og betingelser omtaler fysiske personer i hankønsform, gælder de tilsvarende for alle fysiske personer.
                                </p>
                                <p>
                                    1.1.4 I forbindelse med disse vilkår og betingelser gælder følgende termdefinitioner for de enkelte produkter:
                                </p>
                                <p>
                                    <strong>CamAPS FX </strong> er en uafhængig mobilapplikation (app) til din smartphone, som tilbyder insulinindgivelse i et lukket (loop) system til mennesker med type 1-diabetes. Appen linker til en tredjepartsglukosesensor og en tredjepartsinsulinpumpe. Du kan beslutte at dele data, som indsamles af appen, med CamDiab eller tredjepartsdiabetesportaler til datavisning. Der kan være begrænsninger i forhold til, hvilken Android-smartphonemodel der kan anvendes, alt efter hvilken tredjepartspumpe og -glukosesensor du bruger.
                                </p>
                                <p>
                                    <strong>CamAPS HX </strong> er en app svarende til CamAPS FX, men tilbyder fuldt lukket sløjfe til ikke-type 1-diabetes.
                                </p>
                                <p>
                                    1.1.5 Vores brugervejledninger (som kan ses via vores apps eller vores hjemmeside) og de relevante særlige bestemmelser gør opmærksom på eventuelle særlige egenskaber ved de enkelte produkter.
                                </p>
                                <h3>
                                    1.2. Anvendelsesområde for vores vilkår og betingelser
                                </h3>
                                <p>
                                    1.2.1 Vi tilbyder udelukkende vores produkter på grundlag af den aktuelle version af disse generelle vilkår og betingelser, og dette gælder globalt. CamDiab modsætter sig udtrykkeligt modstridende forretningsbetingelser eller andre brugsbetingelser.
                                </p>
                                <p>
                                    1.2.2 Bemærk, at før du tager vores produkter i brug for første gang, skal du give samtykke til alle de erklæringer og aftaler, der henvises til, og acceptere dem under yderligere brug. Det er forbudt at registrere dig til og bruge vores produkter, uden at du har accepteret vores vilkår og betingelser.
                                </p>
                                <p>
                                    1.2.3 Vi forbeholder os udtrykkeligt ret til at foretage fremtidige ændringer og faktuelt berettigede redigeringer af vores vilkår og betingelser. Ændringer kan være nødvendige for at opfylde lovgivningsmæssige krav, reagere på tekniske og økonomiske krav eller imødekomme vores brugeres interesser. Disse ændringer kan foretages når som helst og bliver offentliggjort på passende vis. Hvis der ikke gøres indsigelse mod dem inden for en måned, er fortsat brug af vores produkter underlagt de relevante nye vilkår og betingelser.
                                </p>
                                <h3>
                                    1.3. Tredjepartsleverandører
                                </h3>
                                <p>
                                    1.3.1 Du kan finde vores produkter i app-stores. Generelt gælder den pågældende tredjeparts forretningsbetingelser der (og vi har ingen indflydelse på dem); de er ikke en del af vores vilkår og betingelser eller af vores aftaleretlige forhold til dig som bruger af vores produkter. Det gælder også for din mobilenheds- eller tjenesteudbyders forretningsbetingelser.
                                </p>
                                <p>
                                    1.3.2 Vi samarbejder med forskellige partnere for at sikre optimal brug af vores produkter. Det omfatter samarbejdspartnere, som leverer visse produkter (f.eks. insulinpumpe, CGM-system) direkte til vores brugere. I disse tilfælde gør vi opmærksom på en tredjepartsleverandørs gæIdende forretningsbetingelser, da du skal acceptere dem, før du kan begynde at bruge vores produkter.
                                </p>
                                <h2>
                                    2. DATABESKYTTELSE
                                </h2>
                                <p>
                                    <strong> Vi er klar over det store ansvar, der følger med, at du bruger vores produkter. Du giver os ikke bare generelle persondata, men også helbredsdata. I vores </strong>
                                    <Link to="/dk/privacy">
                                        databeskyttelsespolitik </Link> <strong> informerer vi dig om, hvordan dine data bliver anvendt og beskyttet i forbindelsen med brugen af vores produkter, og om, at du skal give samtykke til det. </strong>
                                </p>
                                <p>
                                    <strong> HVIS DU IKKE GIVER SAMTYKKE TIL DEN NØDVENDIGE DATABEHANDLING, ER DET HVERKEN LOVLIGT ELLER PRAKTISK MULIGT AT BRUGE VORES PRODUKTER. </strong>
                                </p>
                                <p>
                                    <strong>Vores </strong>
                                    <Link to="/dk/privacy"> databeskyttelsespolitik </Link> <strong> er ikke en del af disse vilkår og betingelser, men bruges kun til at give dig information i henhold til GDPR, som er det grundlag, du giver samtykke til den nødvendige databehandling på. </strong>
                                </p>
                                <h2>
                                    3. INDGÅELSE AF AFTALE OG REGISTRERING
                                </h2>
                                <h3>
                                    3.1. Køb af og betaling for vores produkter
                                </h3>
                                <p>
                                    3.1.1 Vores produkter kan købes på forskellige måder:
                                </p>
                                <h3>
                                    Apps
                                </h3>
                                <p>
                                    <strong>App-stores</strong> (f.eks. Google Play og Amazon Appstore) tilbyder vores produkter. Bemærk, at der gælder særlige forretningsbetingelser for app-storen, og at de kan kræve, at du har en brugerkonto for at kunne downloade (ikke bruge) vores produkter.
                                </p>
                                <p>
                                    <strong>Vores hjemmeside</strong> kan give direkte adgang til vores produkter og til at bestille dem.
                                </p>
                                <p>
                                    <strong>Voucherkoder</strong> til vores produkter kan være uddelt og kan indløses på vores hjemmeside.
                                </p>
                                <p>
                                    3.1.2 Du kan bestille vores betalingsprodukter på vores hjemmeside eller gennem vores distributører. De betales via en betalingstjenesteudbyder, vores forhandlere eller andre angivne betalingsmuligheder. Forhandleren fungerer enten som agent eller betalingstjenesteudbyder for CamDiab eller som direkte sælger. Hvis du har problemer med at downloade eller betale, kan du altid kontakte <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <p>
                                    3.1.3 Brugsgebyrer eller købspriser (inklusive lovpligtig moms) skal betales – afhængigt af tilbud og valg – én gang eller flere gange (f.eks. en gang om måneden, hvert halve år eller en gang om året). De aktuelle gebyrer er angivet på vores hjemmeside efter forhandler eller vist i vores app eller i app-stores og skal betales forud. Hvis betalingen ikke behandles direkte via app-store, kan betalingsudbyderen eller forhandleren sende dig fakturaer og betalingspåmindelser pr. e-mail. I tilfælde af culpøs, for sen eller ufuldstændig betaling har vi ret til at blokere din adgang til vores produkter. Din forpligtelse til at betale brugsgebyr berøres ikke af dette.
                                </p>
                                <p>
                                    3.1.4 CamDiab kan også fremsætte særtilbud eller gratis tilbud, som er underlagt yderligere betingelser eller begrænsninger. I den forbindelse henviser vi udtrykkeligt til eventuelle afvigelser fra disse vilkår og betingelser, men derudover gælder disse vilkår og betingelser fuldt ud. Særtilbud eller gratis tilbud kan ikke overføres til andre brugere.
                                </p>
                                <h3>
                                    3.2. Registrering og aktivering
                                </h3>
                                <p>
                                    3.2.1 Du skal registrere dig hos CamDiab i vores apps for at kunne bruge vores produkter. Så snart du har aktiveret vores produkt første gang, skal du registrere dig med en e-mailadresse og en adgangskode (CamDiab-id). Hver bruger må kun oprette ét CamDiab-id, og hvert CamDiab-id må kun anvendes af én bruger.
                                </p>
                                <p>
                                    3.2.2 Med sin registrering accepterer brugeren sit bindende tilbud om at indgå en aftale med CamDiab om brug af vores produkter på grundlag af den gældende version af disse vilkår og betingelser. Vi accepterer udtrykkeligt dette tilbud, senest ved aktivering af dit CamDiab-id.
                                </p>
                                <p>
                                    3.2.3 Aktiveringen bekræftes, når dine loginoplysninger accepteres, og du har gennemgået passende oplæring. Vi forbeholder os ret til at afvise enkelte brugere eller deres tilbud om at indgå en aftale uden at skulle begrunde det. I disse tilfælde vil allerede gennemførte betalinger blive refunderet, og brugerdataene vil straks blive slettet.
                                </p>
                                <p>
                                    3.2.4 Efter registrering bekræfter brugeren, at denne er fyldt seksten år, og at gældende lov tillader, at han eller hun indgår aftaler. Kun personer, som er myndige, må registrere sig. Mindreårige må kun gøre dette med udtrykkelig tilladelse fra en af sine forældre eller en værge. Med registreringen erklærer forælderen/værgen, at denne er berettiget til at afgive juridisk bindende erklæringer på den mindreåriges vegne.
                                </p>
                                <p>
                                    3.2.5 Efter registreringen bekræfter brugeren, at denne anerkender og uden begrænsninger accepterer indholdet af disse vilkår og betingelser, og at dennes oplysninger er sande, korrekte, up to date og fuldstændige. Vi forbeholder os ret til til hver en tid at kontakte brugerne og kontrollere registreringsdataene og brugsoplysningerne.
                                </p>
                                <p>
                                    3.2.6 Hvis brugeren opgiver falske, forkerte, forældede eller ufuldstændige oplysninger, eller vi har begrundet formodning om, at oplysningerne er falske, forkerte, forældede eller ufuldstændige, har CamDiab ret til uden forudgående varsel og med omgående virkning at blokere det pågældende CamDiab-id og forbyde brug af vores produkter uden at være forpligtet til at tilbagebetale brugerens udgifter i den forbindelse.
                                </p>
                                <p>
                                    3.2.7 Brugeren skal beskytte sine registreringsdata mod uautoriseret adgang fra tredjeparter, misbrug eller brug med henblik på svindel. Hvis der er den mindste mistanke om, at CamDiab-id'et har været udsat for en sådan risiko, skal det uden ophold meddeles til <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>. Vi har ret til at blokere en brugers CamDiab-id, hvis det anvendes med ulovlig eller svigagtig hensigt.
                                </p>
                                <h2>
                                    4. FORTRYDELSESRET
                                </h2>
                                <h3>
                                    4.1. Information om udøvelse af fortrydelsesret
                                </h3>
                                <p>
                                    4.1.1 Hvis du ikke anvender vores produkter til kommercielle eller erhvervsmæssige formål, er du forbruger og har dermed ret til at fortryde den aftale, du har indgået med CamDiab, på følgende betingelser:
                                </p>
                                <p>
                                    <strong>FORTRYDELSESRET</strong>
                                </p>
                                <p>
                                    <strong><u>Fortrydelsesret</u></strong>
                                </p>
                                <p>
                                    <strong> Du har ret til at fortryde denne aftale inden for fjorten dage uden at begrunde det. </strong>
                                </p>
                                <p>
                                    <strong> Fortrydelsesretten gælder i fjorten dage fra den dato, aftalen er indgået (aktivering af digitalt indhold). </strong>
                                </p>
                                <p>
                                    <strong> Hvis du vil benytte dig af din fortrydelsesret, skal du informere os om din beslutning om at fortryde aftalen med en tydelig erklæring til forhandleren eller CamDiab ved at sende en e-mail til </strong> <strong> <a href="mailto:support@camdiab.com">support@camdiab.com</a> </strong> <strong> (f.eks. pr. e-mail eller post). </strong>
                                </p>
                                <p>
                                    <strong> Det er tilstrækkeligt, at du sender meddelelsen om, at du vil benytte dig af din fortrydelsesret, før fortrydelsesfristens udløb. </strong>
                                </p>
                                <p>
                                    <strong><u>Konsekvenser af fortrydelse</u></strong>
                                </p>
                                <p>
                                    <strong> Hvis du benytter dig af din fortrydelsesret i forhold til denne aftale, skal forhandleren eller vi tilbagebetale alle de betalinger, vi har modtaget fra dig, uden ophold og senest fjorten dage efter, at vi har modtaget din meddelelse om, at du ønsker at hæve aftalen. Når det er muligt, benytter vi samme betalingsmetode til denne tilbagebetaling, som du benyttede til den oprindelige transaktion, medmindre vi udtrykkeligt har aftalt andet; der bliver under ingen omstændigheder opkrævet gebyrer på grund af denne tilbagebetaling. </strong>
                                </p>
                                <h2>
                                    5. BRUG AF VORES PRODUKTER
                                </h2>
                                <h3>
                                    5.1. Brugsperiode og ophør
                                </h3>
                                <p>
                                    5.1.1 Din ret til at bruge vores produkter starter med registrering og aktivering hos CamDiab.
                                </p>
                                <p>
                                    5.1.2 Vores betalingsprodukter kan købes i form af månedlige, halvårlige eller årlige abonnementer, og afhængigt af det aktuelle tilbud kan de også være tilgængelige i kortere eller længere brugsperioder (herefter kaldet “ <strong>abonnementsperioden</strong>”).
                                </p>
                                <p>
                                    5.1.3 Både brugeren og CamDiab har til hver en tid ret til at opsige aftalen på ordinær vis med en måneds varsel til udgangen af en måned. Du kan enten gøre det ved at kontakte din forhandler eller ved at meddele os det skriftligt på <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>. Bemærk, at gebyrer, der allerede er betalt for en ubrugt abonnementsperiode, kun refunderes i tilfælde af ordinær opsigelse fra CamDiabs side eller ved automatisk ophør (paragraf 5.1.5 nedenfor).
                                </p>
                                <p>
                                    5.1.4 Hvis der ikke er nogen opsigelse, eller opsigelsen kommer for sent, forlænges abonnementsperioden automatisk med en ny periode svarende til den tidligere valgte abonnementsperiode. Den relevante standardpris på forlængelsestidspunktet (ikke priserne på særtilbud eller gratis tilbud) gælder.
                                </p>
                                <p>
                                    5.1.5 I tilfælde af brugerens død eller tilbagekaldelse af samtykke til databehandling, ophører brugsforholdet automatisk med omgående virkning. CamDiab må ikke behandle dine sundhedsdata uden dit samtykke.
                                </p>
                                <p>
                                    5.1.6 Hvis du har spørgsmål til din brugsperiode eller dens ophør, kan du altid kontakte <a href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h3>
                                    5.2. Lås ude og udelukkelse
                                </h3>
                                <p>
                                    5.2.1 Vi forbeholder os ret til at låse brugere ude midlertidigt, hvis der (efter vores skøn) foreligger en væsentlig begrundelse, eller til permanent at udelukke dem fra at bruge vores produkter. Hvis en sådan aftale slutter uden varslingsperiode, er refundering af brugsgebyrer udelukket.
                                </p>
                                <p>
                                    5.2.2 Der foreligger en væsentlig begrundelse især ved alvorlige overtrædelser af bestemmelserne i disse vilkår og betingelser fra brugerens side, f.eks. overtrædelse af paragraf 3.1 (Betaling) efter to ugers henstand, paragraf 3.2 (Registreringsdata), paragraf 5.3 (Licens) eller paragraf 6 (“Din adfærd”).
                                </p>
                                <h3>
                                    5.3. Licens og overdragelse af rettigheder
                                </h3>
                                <p>
                                    5.3.1 Med din registrering og aktivering erhverver du en ikke-eksklusiv, ikke-overdragelig, men geografisk ubegrænset, ret til at gemme og anvende en kopi af vores app til dine egne formål inden for rammerne af disse vilkår og betingelser. For betalingsprodukter er denne licens begrænset til din abonnementsperiode ifølge aftalen.
                                </p>
                                <p>
                                    5.3.2 Softwaren, koden, metoderne og systemerne samt indholdet af vores produkter er beskyttet af ophavsrets- og konkurrencelovgivningen og må udelukkende anvendes af CamDiab. Vores produkter eller dele heraf må ikke kopieres, modificeres, gengives, genudgives, postes, overføres, sælges, udbydes til salg, videresælges eller anvendes på anden vis uden forudgående skriftlig tilladelse fra os. Brugerne må ikke anvende brands, logoer, andre kommercielle ejendomsrettigheder eller varemærkerettigheder, som tilhører CamDiab. Medmindre andet er bestemt i disse vilkår og betingelser, tilhører alle brugs- og udnyttelsesrettigheder udelukkende CamDiab, og der er ingen licens af nogen art til vores produkter.
                                </p>
                                <p>
                                    5.3.3 Hvis det kræves til den tekniske levering af vores produkter, giver hver bruger CamDiab en ikke-eksklusiv, genkaldelig, men gratis, overdragelig udnyttelses- og brugsret, ubegrænset i forhold til tid og sted, til det indhold, de genererer, overfører, opbevarer eller offentliggør i vores produkter. Brug eller udnyttelse er imidlertid udelukket, hvis det har en uforholdsmæssigt negativ indvirkning på brugerens legitime interesser (f.eks. databeskyttelse). I tilfælde af brug uden for vores produkter vil vi, hvis det er relevant, angive, at indholdet kommer fra brugeren. CamDiab påberåber sig ikke ejerskab til oprettet indhold og påtager sig ikke nogen overvågningsfunktion i forhold til indhold, som er oprettet af brugeren.
                                </p>
                                <h3>
                                    5.4. Tilgængelighed af vores produkter
                                </h3>
                                <p>
                                    5.4.1 Vi leverer vores produkter i hvert tilfælde i overensstemmelse med de eksisterende tekniske, økonomiske, driftsmæssige og organisatoriske muligheder. CamDiab kan ikke udelukke afbrydelser, forstyrrelser, forsinkelser, sletninger, ukorrekte transmissioner eller opbevaringssvigt i forbindelse med brugen af vores produkter eller kommunikation med brugere. Vi tilbyder delvist vores produkter i samarbejde med tredjepartsforhandlere og leverandører og er derfor også afhængige af den tekniske levering af tredjepartstjenester. Derfor påtager CamDiab sig ikke noget ansvar for, giver ingen garantier for, hæfter ikke for og forpligter sig ikke til at levere vores produkter online hele tiden uden afbrydelser.
                                </p>
                                <p>
                                    5.4.2 Dette gælder også begrænsninger i brugen af vores produkter på grund af force majeure, strejke, lockout og officielle påbud eller på grund af tekniske modifikationer eller vedligeholdelsesarbejder på CamDiabs systemer. I tilfælde af afbrydelser eller svigt af vores produkter kan du altid kontakte <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h2>
                                    6. DIN ADFÆRD
                                </h2>
                                <h3>
                                    6.1. Generelle oplysninger
                                </h3>
                                <p>
                                    6.1.1 Den enkelte bruger er berettiget og forpligtet til at bruge vores produkter for egen regning og risiko via passende teknisk udstyr. Du kan til hver en tid få oplyst, hvilke enheder og operativsystemer der er kompatible med produkterne, på <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <p>
                                    6.1.2 Du er også forpligtet til udelukkende at anvende vores produkter i overensstemmelse med disse vilkår og betingelser samt lovgivningen og undgå al uretmæssig brug. Du bedes straks informere os, hvis du opdager nogen brud på de forpligtelser, som er anført i denne paragraf, på <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h3>
                                    6.2. Misbrug
                                </h3>
                                <p>
                                    6.2.1 Alle brugere har pligt til at opgive deres registreringsdata sandfærdigt, at holde dem up to date og fuldstændige og ikke videregive dem til tredjepart. Persondata skal behandles fortroligt, administreres omhyggeligt og sikkerhedskopieres på eget ansvar. CamDiab påtager sig intet ansvar for og garanterer ikke for mistede eller beskadigede data, hvis det ikke er vores fejl.
                                </p>
                                <p>
                                    6.2.2 Indhold som fotos, billeder, tekster, videoer eller andre afbildninger må kun opbevares, offentliggøres, overføres eller distribueres i forbindelse med vores produkter, hvis brugeren har ret til at overføre eller bruge dem. I alle tilfælde er det altid forbudt at anvende racistisk, stødende, diskriminerende, ærekrænkende, seksuelt, pornografisk, voldeligt eller andet ulovligt indhold af enhver art.
                                </p>
                                <p>
                                    6.2.3 Det er desuden forbudt at dekryptere, foretage reverse engineering, dekompilere eller disassemblere vores produkter eller bruge vores produkter på rootede smartphones. Alle brugere har pligt til ikke at udføre forstyrrende tiltag af teknisk eller elektronisk art i vores produkter (især forsøg på hacking, forsøg på at bruge magt, introduktion af virus, orme, trojanske heste eller ondsindet software) eller nogen form for forsøg på forstyrrelse, som kan påvirke softwaren eller hardwaren i CamDiabs produkter og systemer. Enhver manipulation af vores produkter kan resultere i, at vores produkter ikke fungerer efter hensigten.
                                </p>
                                <h2>
                                    7. VORES ANSVAR
                                </h2>
                                <h3>
                                    7.1. Garanti og hæftelse
                                </h3>
                                <p>
                                    7.1.1 Medmindre andet er bestemt i disse vilkår og betingelser, hæfter CamDiab og yder garantier i henhold til lovgivningen.
                                </p>
                                <p>
                                    7.1.2 Med hensyn til brugere, som ikke er forbrugere, udelukkes hæftelse for økonomiske tab, følgeskader, tab af avance og skadeserstatning som følge af krav fra tredjepart. Hvis vores hæftelse er begrænset eller udelukket i disse vilkår og betingelser, gælder dette også CamDiabs stedfortrædere og tilknyttede selskaber.
                                </p>
                                <p>
                                    7.1.3 Ud over produktansvarslovgivningens anvendelsesområde er CamDiabs hæftelse begrænset til forsæt og grov uagtsomhed – med undtagelse af personskade. Hæftelse for simpel uagtsomhed udelukkes af hensyn til de efterfølgende saglige begrundelser.
                                </p>
                                <p>
                                    7.1.4 CamDiab yder ingen garanti for, at vores produkter er fuldt tilgængelige uden afbrydelser og uden fejl, og at den nødvendige software og hardware fungerer uden fejl. Vi kan heller ikke udelukke muligheden for, at data kan spores, registreres eller forfalskes af tredjeparter under dataoverførsel via tredjepartssystemer, især internettet og andre telekommunikationsnetværk.
                                </p>
                                <p>
                                    7.1.5 CamDiab giver ingen garantier for downloadet indhold eller materiale, som brugerne har fået som følge af brug af vores produkter. Det er alene brugeren, som hæfter for alle skader, der kan opstå på dennes it-systemer eller enheder, eller for tab af data som følge af download af materialer i forbindelse med vores produkter.
                                </p>
                                <p>
                                    7.1.6 Desuden hæfter CamDiab ikke for tredjepartsindhold som eksterne links, bannere eller andre oplysninger eller reklametilbud fra tredjeparter, der kan placeres som led i vores produkter. Hvis vi giver adgang til tilbud fra tredjeparter via notifikationer eller links, er CamDiab ikke ansvarlig for oplysningerne i dem. Efterfølgende juridiske transaktioner med tredjeparter fører udelukkende til aftaleforhold mellem brugeren og den pågældende tredjepart. Vi giver ingen garantier og hæfter ikke for tredjeparters tjenester.
                                </p>
                                <h3>
                                    7.2. Ansvarsfraskrivelse og skadesløsholdelse
                                </h3>
                                <p>
                                    7.2.1 Anvendelse af vores produkter kan ikke erstatte en konsultation med en behandler eller anden medicinsk rådgivning og sker alene på brugerens egen risiko. Dette gælder for enhver brug af de data, der leveres som led i vores produkter, herunder blod- og sensorglukoseresultater, beregninger og eventuelle anbefalinger. Brugeren anerkender udtrykkeligt, at sådanne data kan være mangelfulde, og CamDiab påtager sig intet ansvar for, at de er korrekte.
                                </p>
                                <p>
                                    7.2.2 I det omfang, det kræves i gældende lovgivning vedrørende medicinsk udstyr med hensyn til brug af produkterne, må produkterne kun drives eller anvendes i henhold til det formål, de specifikationer og de anvendelsesområder, som er anført i tilbuddet og brugsbetingelserne.
                                </p>
                                <p>
                                    7.2.3 Brugeren holder CamDiab skadesløs for alle krav fra tredjeparter mod CamDiab som følge af brugerens uretmæssige krænkelse af deres rettigheder med hensyn til brug af vores produkter. Alle andre krav om skadeserstatning fra CamDiab mod brugeren er ikke berørt heraf.
                                </p>
                                <p>
                                    7.2.4 Brugeren påtager sig det fulde ansvar for alle skader og tvister i og uden for domstolssystemet som følger af konflikter med andre brugere. Brugeren anerkender udtrykkeligt, at CamDiab under ingen omstændigheder er ansvarlig for andre brugeres handlinger eller udeladelser og deraf følgende skader.
                                </p>
                                <p>
                                    7.2.5 I tilfælde af, at der rejses krav mod CamDiab af tredjeparter, er brugeren forpligtet til straks at give alle oplysninger, denne har til rådighed, sandfærdigt og fuldt ud som krævet for at gennemgå, forsvare og forebygge krav fra tredjeparter. Brugeren bærer omkostningerne til ethvert nødvendigt juridisk forsvar af CamDiab, herunder alle domstols- og andre juridiske omkostninger med det lovbestemte beløb.
                                </p>
                                <h2>
                                    8. AFSLUTTENDE BESTEMMELSER
                                </h2>
                                <h3>
                                    8.1. Lovvalg
                                </h3>
                                <p>
                                    Disse vilkår og betingelser og hele forholdet mellem CamDiab og brugeren er alene underlagt engelsk lovgivning med undtagelse af referencebestemmelserne og FN's konvention om internationale køb. Som forbruger nyder brugeren beskyttelse af præceptive bestemmelser i det land, hvor denne opholder sig eller normalt er bosat.
                                </p>
                                <h3>
                                    8.2. Værneting
                                </h3>
                                <p>
                                    Opfyldelsesstedet og eneste værneting for alle tvister i forbindelse med disse vilkår og betingelser aftales som CamDiabs hovedkontor i Cambridge i Storbritannien. Tvingende kollisionsnormer til forbrugerens fordel er uberørte deraf.
                                </p>
                                <h3>
                                    8.3. Andet
                                </h3>
                                <p>
                                    8.3.1 Hvis underretning eller skriftlighed kræves i disse vilkår og betingelser (herunder juridisk vigtige erklæringer), er transmission via e-mail til den adresse, der er anført ved registrering (CamDiab-id), tilstrækkelig.
                                </p>
                                <p>
                                    8.3.2 Brugerne er ikke berettiget til at overføre deres CamDiab-id eller rettigheder og forpligtelser i henhold til disse vilkår og betingelser i forbindelse med brug af vores produkter til tredjeparter. CamDiab kan overføre deres rettigheder og forpligtelser i henhold til disse vilkår og betingelser til et andet selskab eller tredjepart, hvis en sådan overførsel ikke har væsentlig indvirkning på brugerens rettigheder i henhold til disse vilkår og betingelser.
                                </p>
                                <p>
                                    8.3.3 Hvis enkelte bestemmelser i disse vilkår og betingelser er eller bliver ugyldige, forbliver de øvrige bestemmelser i disse vilkår og betingelser, som ikke er berørt af ugyldigheden, gyldige og anvendelige.
                                </p>
                                <p>
                                    8.3.4 Disse vilkår og betingelser leveres på forskellige sprog. Versionen på det sprog i det land, hvor forbrugeren opholder sig eller normalt er bosat, er væsentlige og endelige. Hvis og i det omfang, der ikke er noget landespecifikt sprog tilgængeligt, gælder den engelske version.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer props={this.props}/>
            </div>
        );
    }
}

