/**
 * Texts for Support page
 */
export const supportPageText = {
    title: 'Assistenza - CamAPS FX',
    titleMeta: 'Offriamo un’ampia assistenza per l’utilizzo di CamAPS FX',
    descriptionMeta: 'Offriamo materiale di supporto, tra cui manuali d’uso, linee guida scaricabili, webinar e assistenza ai clienti sia telefonicamente che tramite posta elettronica.',
    keywords:
        'camaps sostegno, camaps fx sostegno, ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'it',

    needHelp: 'Serve aiuto?',
    needHelpResponse: 'Speriamo che il materiale di supporto disponibile qui possa aiutarti a chiarire eventuali dubbi. Se non riesci a trovare la risposta al tuo problema, contattaci.',
    clinicalSupportHeader: 'Assistenza clinica',
    clinicalSupportText: 'Per qualsiasi domanda inerente la tua assistenza sanitaria, ti invitiamo a contattare il tuo team diabetologico.',
    techSupportHeader: 'Assistenza a livello tecnico e didattico',
    techSupportHeaderText: 'Qui sono disponibili i materiali di supporto e i dati di contatto necessari per contattare il nostro team di assistenza.',
    techMaterialHeader: 'Materiali tecnici:',
    userManual: 'CamAPS FX manuale d’uso',
    userManualEnglish_mmol_l: 'English',
    userManualCzech_mmol_l: 'Česky',
    userManualDanish_mmol_l: 'Dansk',
    userManualGerman_mg_dl: 'Deutsch',
    userManualGerman_mmol_l: 'Deutsch',
    userManualSpanish_mg_dl: 'Español',
    userManualFrench_mmol_l: 'Français',
    userManualFrench_mg_dl: 'Français',
    userManualItalian_mg_dl: 'Italiano',
    userManualItalian_mmol_l: 'Italiano',
    userManualDutch_mg_dl: 'Nederland',
    userManualDutch_mmol_l: 'Nederland',
    userManualNorsk_mmol_l: 'Norsk',
    userManualPolish_mg_dl: 'Polski',
    userManualFinish_mmol_l: 'Suomi',
    userManualSwedish_mmol_l: 'Svenska',

    previousManualVersions: 'Versione precedente',
    installationManualHeader: 'Installazione di CamAPS FX (DANA)',
    installationManualEnglish: 'Guida all’installazione',
    installationManualGerman: 'Installationsanleitung',
    installationManualFrench: 'Guide d’installation',
    educationalMaterial: 'Materiale didattico:',
    onlineTraining: 'Addestramento online:',
    onlineTrainingText: 'Offerto dal Cambridge Diabetes Education Programme (CDEP)',
    onlineTrainingLink: 'https://www.camdiabtraining.com/account-sign-in.html',
    onlineTrainingText_1: 'Corso di addestramento online GRATUITO certificato',
    webinarsHeading: 'Webinar',
    webinar_1: 'Presentazione del sistema CamAPS FX',
    webinar_2: 'Iniziare con il sistema CamAPS FX',
    webinar_3: 'Ottimizzare le impostazioni di CamAPS FX',
    webinar_4: 'Addestramento per insegnanti e personale di assistenza didattica',
    webinar_5: 'Aggiustamenti per migliorare il tempo nell’intervallo',
    webinar_6: 'Utilizzo di CamAPS FX durante la gravidanza',
    webinar_7: 'Utilizzo di CamAPS FX negli infanti, nei bambini piccoli e in età prescolare',
    webinar_8: 'Utilizzo di CamAPS FX nei giovani adulti e negli adulti',
    webinar_9: 'Gestione dell’attività fisica, dei pasti e di altri eventi della vita',
    webinar_10: 'Terapia insulinica a circuito chiuso e qualità di vita',
    webinar_11: 'Terapia a circuito chiuso e diabete di tipo 1 di lunga data',
    webinar_footnote: '',

    contactDetailsHeader: 'Dettagli di contatto:',
    contactDetailsText: 'Il nostro team di assistenza è disponibile negli orari d’ufficio dal lunedì al venerdì, ore 10:00 - 18:00.',
    contactDetailsEmailTitle: 'Scrivici per posta elettronica',
    contactDetailsEmail: 'support@camdiab.com',
    contactDetailsEmailFull: 'mailto:support@camdiab.com',
    contactDetailsPhoneTitle: 'Contattaci',
    contactDetailsPhone: '+44 20 3695 3780 (Soltanto inglese)',
    contactDetailsPhoneFull: 'tel.:+442036953780',

    eIfuAccess: 'Copie stampate dei manuali utente possono essere richieste all’assistenza clienti. ' +
        'Le copie stampate sono gratuite e arriveranno in circa 3-7 giorni. Per ottenere i migliori ' +
        'risultati, utilizzare Adobe® Reader® per visualizzare i manuali utente di CamDiab.',

    statusPageText: 'Pagina di stato',
    statusPageLink: 'https://camdiabltd.statuspage.io/',

    installationYpsoPumpHeader: 'Installazione di CamAPS FX (YpsoPump)',
    installationYpsoPumpText: 'Google Play',
    installationYpsoPumpLink: 'https://play.google.com/store/search?q=camaps%20fx&c=apps',

    danaPumpSupportText: 'Utenti della pompa Dana',
    danaPumpSupportTextUK: 'Dana pump users in the UK',
    danaPumpSupportLinkUK: 'https://advancedtherapeutics.org.uk/contact-us/',
    ypsoPumpSupportText: 'Utenti della pompa YpsoPump',
    ypsoPumpSupportLink: 'https://www.mylife-diabetescare.com/en/services/customer-care-contact.html',
};
