/**
 * Texts for header
 */
export const headerText = {
    cookiesIntroText: 'Używamy plików cookie wyłącznie w celu śledzenia odwiedzin na naszej stronie internetowej, nie przechowujemy żadnych danych osobowych.',
    cookiesAccept: 'Akceptuj pliki cookie',
    cookiesDeny: 'Odrzuć pliki cookie',
    tabFAQs: 'Często zadawane pytania',
    tabSupport: 'Wsparcie',
    tabHistory: 'Historia',
    tabReviews: 'Opinie',
    tabTraining: 'Szkolenie',
    // tabOrders: 'Zamówienia',
    tabOrders: '',
    tabNotifications: 'Powiadomienia',
};
