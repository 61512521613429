/**
 * Texts for History page
 */
export const historyPageText = {
    title: 'Unsere CamDiab Reise',
    titleMeta: 'Klinische Forschung auf dem Weg zu CamAPS FX',
    descriptionMeta: 'Zeitleiste, wie das Team hinter CamAPS FX dazu kam, Menschen mit Diabetes mit unserem Cambridge Algorithmus zu helfen.',
    keywords:
        'camaps geschichte, camaps fx geschichte, ' +
        // 'closed loop, closed loop system, loop deutsch, eine autoimmunerkrankung kommt selten allein, glukose im blut, closed de, open loop closed loop, loop diabetes, was ist ein loop, hba1c zu niedrig, blutglukose, closed loop diabetes, dexcom app, insulin berechnen app, closed loop insulinpumpe, diabetes zimt therapie, glukose zu hoch, stopp diabetes, dexcom g6 closed loop, diabetes kohlenhydrate app, hybrid closed loop diabetes, loop dexcom g6, welche insulinpumpen gibt es, diabetes loop system, kohlenhydrate app android, kur diabetes typ 1, was heißt loop, mutter kind kur für diabetiker,  ' +
        '',
    localeMeta: 'de_de',

    journeyHeading: 'Unsere CamDiab Reise',
    timelineHeading: 'Zeitleiste, wie das Team hinter CamAPS FX dazu kam, Menschen mit Diabetes mit unserem Cambridge Algorithmus zu helfen.',
    year2006_2010dates: '2006–2010',
    year2006_2010heading: 'Frühe Studien in der Klinik',
    year2006_2010text: 'Start des Künstlicher-Pankreas-Projektes in Cambridge mit Studien bei Kindern mit Typ-1-Diabetes. Teilnahme am JDRF Artificial Pancreas Consortium. Der Cambridge Algorithmus wird dem Stresstest nach Sport und Alkoholkonsum bei Erwachsenen mit Typ-1-Diabetes unterzogen. Der Cambridge Algorithmus wird bei schwangeren Frauen mit Typ-1-Diabetes evaluiert.',
    year2011_2014dates: '2011–2014',
    year2011_2014heading: 'Nur-Nacht und Selbstbestimmt-Zuhause Machbarkeitsstudien',
    year2011_2014text: 'Nächtlicher Einsatz des Cambridge Algorithmus bei Kindern, Erwachsenen und schwangeren Frauen mit Typ-1-Diabetes evaluiert. Einsatz des Cambridge Algorithmus bei Tag-und-Nacht-Einsatz über sieben Tage bei Erwachsenen mit Typ-1-Diabetes.',
    year2015_2016dates: '2015–2016',
    year2015_2016heading: 'Kurze Tag-und-Nacht-selbstbestimmt-zuhause Studien',
    year2015_2016text: 'Einsatz des Cambridge Algorithmus bei sorgfältig überwachten Erwachsenen mit Typ-1-Diabetes über vier Wochen. Einsatz des Cambridge Algorithmus bei Heranwachsenden mit Typ-1-Diabetes über drei Wochen.',
    year2017_2018dates: '2017–2018',
    year2017_2018heading: 'Längere Tag-und-Nacht und Junge-Kinder Studien',
    year2017_2018text: 'Einsatz des Cambridge Algorithmus über drei Monate bei Kindern und Erwachsenen mit Typ-1-Diabetes. Einsatz des Cambridge Algorithmus über zwei Jahre bei Kindern und Heranwachsenden von Beginn des Diabetes an. Einsatz des Cambridge Algorithmus bei jungen Kindern von 1 bis 7 Jahren mit Typ-1-Diabetes.',
    year2019_2019dates: '2019',
    year2019_2019heading: 'Umstellung auf CamAPS FX ',
    year2019_2019text: 'Einsatz der CamAPS FX App bei älteren Erwachsenen mit Typ-1-Diabetes. Einsatz der CamAPS FX App während der Schwangerschaft bei schwangeren Frauen mit Typ-1-Diabetes. Einsatz der CamAPS FX App bei Kindern von 1 bis 7 Jahren mit Typ-1-Diabetes.',
    year2020_2020dates: 'ab 2020',
    year2020_2020heading: 'Behördliche Genehmigung, Vermarktung, klinische Erprobung',
    year2020_2020text: 'CamAPS FX App CE-zertifiziert für den Einsatz in der EU und in UK. Weitere Forschung zur Bewertung des Nutzens und Unterstützung der Erstattung durch Krankenkassen.',
};
