/**
 * Texts for Support page
 */
export const supportPageText = {
    title: 'Tuki − CamAPS FX',
    titleMeta: 'Annamme laajaa tukea CamAPS FX -järjestelmän käyttöön',
    descriptionMeta: 'Tarjoamme tukimateriaalia, kuten käyttöohjeita, ladattavia ohjeita, webinaareja sekä muuta asiakastukea puhelimitse ja sähköpostitse.',
    keywords:
        'camaps-tuki, camaps fx-tuki,',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'fi_fi',

    needHelp: 'Tarvitsetko apua?',
    needHelpResponse: 'Toivomme tässä tarjoamamme tukimateriaalin antavan vastauksen kaikkiin sinulla mahdollisesti oleviin kysymyksiin. Jos et löydä vastausta ongelmaasi, ota meihin yhteyttä.',
    clinicalSupportHeader: 'Kliininen tuki',
    clinicalSupportText: 'Jos sinulla on terveydenhoitoasi koskevia kysymyksiä, ota yhteyttä diabeteshoitotiimiisi.',
    techSupportHeader: 'Tekninen ja koulutustuki',
    techSupportHeaderText: 'Tästä löydät tukimateriaalia ja yhteystietoja, jos haluat olla yhteydessä tukitiimimme kanssa.',
    techMaterialHeader: 'Tekniset materiaalit:',
    userManual: 'CamAPS FX käyttöohje',
    userManualEnglish_mmol_l: 'English',
    userManualCzech_mmol_l: 'Česky',
    userManualDanish_mmol_l: 'Dansk',
    userManualGerman_mg_dl: 'Deutsch',
    userManualGerman_mmol_l: 'Deutsch',
    userManualSpanish_mg_dl: 'Español',
    userManualFrench_mmol_l: 'Français',
    userManualFrench_mg_dl: 'Français',
    userManualItalian_mg_dl: 'Italiano',
    userManualItalian_mmol_l: 'Italiano',
    userManualDutch_mg_dl: 'Nederland',
    userManualDutch_mmol_l: 'Nederland',
    userManualNorsk_mmol_l: 'Norsk',
    userManualPolish_mg_dl: 'Polski',
    userManualFinish_mmol_l: 'Suomi',
    userManualSwedish_mmol_l: 'Svenska',

    previousManualVersions: 'Aiemmat versiot',
    installationManualHeader: 'CamAPS FX -järjestelmän asennus (DANA)',
    installationManualEnglish: 'Asennusohje',
    installationManualGerman: 'Installationsanleitung',
    installationManualFrench: 'Guide d\'installation',
    educationalMaterial: 'Koulutusmateriaali:',
    onlineTraining: 'Verkkokoulutus:',
    onlineTrainingText: 'Tarjoaja: Cambridge Diabetes Education Programme (CDEP)',
    onlineTrainingLink: 'https://www.camdiabtraining.com/account-sign-in.html',
    onlineTrainingText_1: 'MAKSUTON sertifioitu verkkokoulutus',
    webinarsHeading: 'Webinaarit',
    webinar_1: 'CamAPS FX -järjestelmän esittely',
    webinar_2: 'CamAPS FX -järjestelmän käynnistäminen',
    webinar_3: 'CamAPS FX -asetusten optimointi',
    webinar_4: 'Koulutusta opettajille ja koulutuksen tukihenkilöille',
    webinar_5: 'Hienosäädöllä lisätään aikaa glukoositavoitteessa ("Time in range")',
    webinar_6: 'CamAPS FX -järjestelmän käyttäminen raskauden aikana',
    webinar_7: 'CamAPS FX -järjestelmän käyttäminen imeväisillä, taaperoilla ja nuorilla lapsilla',
    webinar_8: 'CamAPS FX -järjestelmän käyttäminen nuorilla aikuisilla ja aikuisilla',
    webinar_9: 'Fyysisten aktiviteettien, aterioiden ja muiden elämäntapahtumien hallinta',
    webinar_10: 'Insuliinihoito suljetulla järjestelmällä ja elämänlaatu',
    webinar_11: 'Hoito suljetulla järjestelmällä ja pitkäkestoinen tyypin 1 diabetes',
    webinar_footnote: '',

    contactDetailsHeader: 'Yhteystiedot:',
    contactDetailsText: 'Tukitiimimme on käytettävissä maanantaista perjantaihin toimistoaikana kello 10.00−18.00.',
    contactDetailsEmailTitle: 'Lähetä sähköpostia',
    contactDetailsEmail: 'support@camdiab.com',
    contactDetailsEmailFull: 'mailto:support@camdiab.com',
    contactDetailsPhoneTitle: 'Yhteystiedot',
    contactDetailsPhone: '+44 20 3695 3780 (vain englanniksi)',
    contactDetailsPhoneFull: 'tel.: +442036953780',

    eIfuAccess: 'Painettuja kopioita käyttöoppaista voi pyytää asiakastuesta. Painetut kopiot ovat ilmaisia, ja ne saapuvat noin 3–7 päivässä. Saat parhaat tulokset käyttämällä Adobe® Reader® -ohjelmaa CamDiab-käyttöoppaiden katseluun.',

    statusPageText: 'Tilasivu',
    statusPageLink: 'https://camdiabltd.statuspage.io/',

    installationYpsoPumpHeader: 'CamAPS FX -järjestelmän asennus (YpsoPump)',
    installationYpsoPumpText: 'Google Play',
    installationYpsoPumpLink: 'https://play.google.com/store/search?q=camaps%20fx&c=apps',

    danaPumpSupportText: 'Dana pumpun käyttäjät',
    danaPumpSupportTextUK: 'Dana pump users in the UK',
    danaPumpSupportLinkUK: 'https://advancedtherapeutics.org.uk/contact-us/',
    ypsoPumpSupportText: 'YpsoPump pumpun käyttäjät',
    ypsoPumpSupportLink: 'https://www.mylife-diabetescare.com/fi-FI/palvelut/asiakaspalvelu-ja-yhteydenotto.html',
};
