import React, {Component} from 'react';
import {Header} from '../Components/Header';
import {Footer} from '../Components/Footer';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

export class TermsIT extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Termini e condizioni - CamAPS FX</title>
                    <meta name="title" content='Termini e condizioni'/>
                    <meta name="description"
                          content='Qui puoi trovare i termini e le condizioni di CamDiab'/>
                    <meta property="og:locale" content='it'/>
                    <meta name="keywords" content='termini e condizioni'/>
                </Helmet>
                <Header props={this.props}/>
                <section className="inner-banner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-10">
                                <h1>Termini e condizioni</h1>
                                <h3>Versione datata 24 giugno 2024</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12">

                                <h2>
                                    1. AMBITO DI VALIDITÀ
                                </h2>
                                <h3>
                                    1.1. I nostri prodotti
                                </h3>
                                <p>
                                    1.1.1. Noi sottoscritti
                                </p>
                                <p>
                                    CamDiab Ltd <strong>
                                    <br/>
                                    Indirizzo della sede legale<br/>
                                    20-22 Wenlock Road<br/>
                                    London, N1 7GU, Regno Unito
                                    <br/>
                                </strong>
                                    support@camdiab.com
                                    <br/>
                                    numero della società 11659211 in Inghilterra e in Galles
                                    <br/>
                                    <br/>
                                </p>
                                <p>
                                    (“<strong>CamDiab</strong>”), sviluppiamo e gestiamo applicazioni mobile (app) per sistemi di erogazione di insulina a circuito chiuso o “closed loop” e connesse infrastrutture di dati per persone con diabete e operatori sanitari. CamDiab è un’azienda attiva nel settore sanitario digitale, impegnata ad offrire alle persone con diabete un migliore controllo glicemico e un minore onere associato all’autogestione del diabete.
                                </p>
                                <p>
                                    1.1.2 I nostri Prodotti controllano e supportano l’erogazione di insulina a circuito chiuso al meglio delle nostre conoscenze. I nostri Prodotti e servizi non possono sostituire la consulenza e la diagnosi da parte di un operatore sanitario. Per domande riguardanti la malattia e la terapia, si consiglia di rivolgersi a un operatore sanitario. I dati forniti devono essere analizzati esclusivamente da parte Sua e del Suo operatore sanitario. Nessun contenuto, non importa che sia fornito da CamDiab stessa, dai suoi collaboratori, distributori, appaltatori o utilizzatori, può essere inteso come integrativo o sostitutivo delle informazioni fornite da un operatore sanitario o da un farmacista.
                                </p>
                                <p>
                                    1.1.3 CamDiab può offrire una gamma di Prodotti (app) e servizi e consentire la condivisione dei dati per migliorare l’autogestione del diabete. Tutte le app e i servizi attuali e futuri (comprendenti nuove versioni, aggiornamenti e altre migliorie) forniti da CamDiab in qualsiasi modo, saranno chiamati in seguito congiuntamente “<strong>Prodotti</strong>” o singolarmente “<strong>Prodotto</strong>”. Tutti coloro che acquisiscono o utilizzano uno o più dei nostri Prodotti saranno chiamati congiuntamente “<strong>Utilizzatori</strong>” o singolarmente “<strong>Utilizzatore</strong>”. Qualora i presenti T&amp;C facciano riferimento a persone fisiche utilizzando il genere maschile, saranno intese ugualmente le persone di tutti i generi.
                                </p>
                                <p>
                                    1.1.4 In relazione ai presenti T&amp;C, si applicano le seguenti definizioni terminologiche ai corrispondenti Prodotti:
                                </p>
                                <p>
                                    <strong>CamAPS FX </strong> è un’applicazione (app) mobile indipendente per smartphone che consente un sistema ibrido a circuito chiuso di erogazione dell’insulina per persone con diabete di tipo 1. L’app si collega a un sensore della glicemia di terzi e a un microinfusore di terzi. L’Utilizzatore può decidere di condividere i dati acquisiti dall’app con CamDiab o con uno o più portali sul diabete di terzi per analizzare i dati. Possono esserci delle restrizioni riguardo ai modelli di smartphone utilizzabili in base al microinfusore e al sensore della glicemia di terzi.
                                </p>
                                <p>
                                    <strong>CamAPS HX </strong> è un’app simile a CamAPS FX, ma offre un circuito completamente chiuso nel diabete non di tipo 1.
                                </p>
                                <p>
                                    1.1.5 I nostri manuali d’uso (accessibili tramite le nostre app o il nostro sito web) e le pertinenti disposizioni speciali evidenziano le caratteristiche specifiche dei singoli Prodotti.
                                </p>
                                <h3>
                                    1.2. Ambito di validità dei nostri T&amp;C
                                </h3>
                                <p>
                                    1.2.1 Offriamo i nostri Prodotti esclusivamente sulla base dell’attuale versione dei presenti Termini e Condizioni Generali (T&amp;C), un prerequisito valido a livello globale. CamDiab si oppone espressamente a transazioni contraddittorie o ad altri termini di utilizzo.
                                </p>
                                <p>
                                    1.2.2 Si noti che prima di utilizzare i nostri Prodotti per la prima volta, l’Utilizzatore deve acconsentire a tutte le dichiarazioni e a tutti gli accordi citati e accettarli per continuare l’uso dei Prodotti. Registrarsi e utilizzare i nostri Prodotti è vietato senza aver acconsentito ai nostri T&amp;C.
                                </p>
                                <p>
                                    1.2.3 Ci riserviamo espressamente il diritto di apportare future modifiche ed emendamenti giustificati di fatto ai nostri T&amp;C. Eventuali modifiche possono rendersi necessarie per ottemperare ai requisiti di legge, rispondere a requisiti tecnici ed economici oppure soddisfare gli interessi dei nostri Utilizzatori. Tali modifiche sono possibili in qualsiasi momento e saranno pubblicate in modo appropriato. Salvo obiezione da sollevare entro un mese, il proseguimento dell’utilizzo dei nostri Prodotti sarà soggetto ai corrispondenti nuovi T&amp;C.
                                </p>
                                <h3>
                                    1.3. Fornitori terzi
                                </h3>
                                <p>
                                    1.3.1 Può trovare i nostri Prodotti in uno o più app store. In generale, si applicano in questo caso i termini commerciali del rispettivo fornitore terzo (sul quale non esercitiamo alcuna influenza); questi termini non sono parte integrante dei nostri T&amp;C né del nostro rapporto contrattuale con Lei in qualità di Utilizzatore dei nostri Prodotti. Questo principio si applica ugualmente ai termini e condizioni commerciali del fornitore del Suo dispositivo mobile o del Suo provider di servizi.
                                </p>
                                <p>
                                    1.3.2 Collaboriamo con vari partner per garantire l’utilizzo ottimale dei nostri Prodotti. Ne fanno parte aziende collaboratrici che forniscono direttamente ai nostri Utilizzatori certi Prodotti (ad es. microinfusore insulinico, sistema di monitoraggio glicemico continuo). In questi casi, mettiamo in evidenza i termini commerciali vigenti del fornitore terzo, poiché l’Utilizzatore deve accettare tali termini prima di poter cominciare a utilizzare i nostri Prodotti.
                                </p>
                                <h2>
                                    2. PROTEZIONE DEI DATI
                                </h2>
                                <p>
                                    <strong> Siamo consapevoli della grande responsabilità associata all’uso dei nostri Prodotti. L’Utilizzatore non solo ci fornisce i propri dati personali generali, ma anche quelli sanitari. Nella nostra </strong>
                                    <Link to="/it/privacy">
                                        Informativa sulla Privacy </Link> <strong> informiamo l’Utilizzatore in merito al trattamento e alla protezione dei suoi dati in associazione con l’utilizzo dei nostri Prodotti e alla necessità di ottenere il suo consenso a tale riguardo. </strong>
                                </p>
                                <p>
                                    <strong> IN MANCANZA DEL SUO CONSENSO ALLA PRIVACY PER IL NECESSARIO TRATTAMENTO DEI DATI NON È CONSENTITO L’UTILIZZO DEI NOSTRI PRODOTTI IN TERMINI LEGALI O REALI. </strong>
                                </p>
                                <p>
                                    <strong>La nostra </strong>
                                    <Link to="/it/privacy"> Informativa sulla Privacy </Link> <strong> non fa parte dei presenti T&amp;C, ma ha piuttosto unicamente lo scopo di informarla sulla protezione dei dati secondo il GDPR, ai sensi del quale Lei rilascia il Suo consenso al necessario trattamento dei dati. </strong>
                                </p>
                                <h2>
                                    3. CONCLUSIONE DEL CONTRATTO E REGISTRAZIONE
                                </h2>
                                <h3>
                                    3.1. Acquisto e pagamento dei nostri Prodotti
                                </h3>
                                <p>
                                    3.1.1 I nostri Prodotti possono essere acquistati tramite vari canali:
                                </p>
                                <h3>
                                    App
                                </h3>
                                <p>
                                    Gli <strong>App store</strong> (come Google Play e Amazon Appstore) offrono i nostri Prodotti. Si noti che per scaricare (non utilizzare) i nostri Prodotti si applicano i termini commerciali separati dell’app store e che questo può richiedere un account utente.
                                </p>
                                <p>
                                    <strong>Il nostro sito web</strong> può offrire un accesso diretto ai nostri Prodotti e consente l’ordine dei nostri Prodotti.
                                </p>
                                <p>
                                    Possono esserci in circolazione <strong>codici voucher</strong> per i nostri Prodotti, riscattabili nel nostro sito web.
                                </p>
                                <p>
                                    3.1.2 Può ordinare i nostri Prodotti a pagamento nel nostro sito web o tramite i nostri distributori. I Prodotti vengono pagati tramite il fornitore di servizi di pagamento, i nostri distributori o altre opzioni di pagamento concordate. Il distributore funge da agente e fornitore di servizi di pagamento per conto di CamDiab oppure come venditore diretto. Per qualsiasi problema connesso al download o al pagamento dei Prodotti, può rivolgersi in qualsiasi momento all’indirizzo <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <p>
                                    3.1.3 I canoni di utilizzo o i prezzi d’acquisto (compresa l’imposta sul valore aggiunto di legge) devono essere pagati, in base al tipo di offerta e di scelta effettuata, una tantum oppure a cadenza temporale (ad es. su base mensile, semestrale o annuale). I canoni attuali sono indicati sul nostro sito web, comunicati da parte del distributore oppure visualizzati nella nostra app o negli app store e sono dovuti in anticipo. Se il pagamento non viene effettuato direttamente tramite l’app store, il fornitore di servizi di pagamento o il distributore può inviare le fatture e i solleciti di pagamento tramite posta elettronica. In caso di pagamento colposo, tardivo o incompleto, siamo autorizzati a bloccare l’accesso ai nostri Prodotti. Ciò non pregiudica comunque il Suo obbligo di pagamento del canone di utilizzo.
                                </p>
                                <p>
                                    3.1.4 CamDiab può inoltre concedere offerte gratuite e speciali, che sono soggette ad ulteriori condizioni o restrizioni. A tale riguardo evidenziamo espressamente eventuali deviazioni dai presenti T&amp;C, mentre per il resto rimangono in vigore i presenti T&amp;C nella loro integralità. Eventuali offerte gratuite o speciali non possono essere trasferite ad altri Utilizzatori.
                                </p>
                                <h3>
                                    3.2. Registrazione e attivazione
                                </h3>
                                <p>
                                    3.2.1 L’uso dei nostri Prodotti presuppone la registrazione a CamDiab nelle nostre app. Alla prima attivazione del nostro Prodotto occorre registrarsi utilizzando un indirizzo e-mail e una password (ID CamDiab). Ogni Utilizzatore può creare un unico ID CamDiab ed ogni ID CamDiab può essere utilizzato da un unico Utilizzatore.
                                </p>
                                <p>
                                    3.2.2 Registrandosi, l’Utilizzatore accetta la propria offerta vincolante di concludere un contratto con CamDiab per l’uso dei nostri Prodotti sulla base della versione vigente dei presenti T&amp;C. Noi accettiamo espressamente questa offerta, al più tardi attivando il Suo ID CamDiab.
                                </p>
                                <p>
                                    3.2.3 L’attivazione è confermata quando i Suoi dati di login sono stati accettati e Lei ha superato un adeguato addestramento. Ci riserviamo il diritto di rifiutare singoli utilizzatori o la loro offerta di concludere un contratto senza fornire giustificazioni. In questi casi, eventuali pagamenti già effettuati saranno rimborsati e i dati dell’Utilizzatore forniti saranno cancellati immediatamente.
                                </p>
                                <p>
                                    3.2.4 Alla registrazione, l’Utilizzatore conferma di avere compiuto almeno sedici anni e conferma altresì che la legge vigente gli consente di concludere contratti. Solo i soggetti con capacità legale hanno il permesso di registrarsi. I minori possono registrarsi esclusivamente con l’esplicito consenso di un genitore/tutore. Con la registrazione, il genitore/tutore dichiara di essere autorizzato a presentare dichiarazioni legalmente vincolanti a nome del minore.
                                </p>
                                <p>
                                    3.2.5 Alla registrazione, l’Utilizzatore conferma di riconoscere e accettare incondizionatamente il contenuto dei presenti T&amp;C e conferma altresì la veridicità, la correttezza, l’aggiornamento e la completezza delle sue informazioni. Ci riserviamo il diritto di contattare gli Utilizzatori in qualsiasi momento per verificare i dati di registrazione e le informazioni di utilizzo.
                                </p>
                                <p>
                                    3.2.6 Se l’Utilizzatore fornisce informazioni false, errate, non aggiornate o incomplete oppure se solo abbiamo motivo di credere che le informazioni siano false, errate, non aggiornate o incomplete, CamDiab è autorizzata a bloccare il corrispondente ID CamDiab con effetto immediato senza alcun preavviso e a vietare l’utilizzo dei nostri Prodotti senza avere l’obbligo di rimborsare all’Utilizzatore i costi sostenuti.
                                </p>
                                <p>
                                    3.2.7 L’Utilizzatore è tenuto a proteggere i propri dati di registrazione dall’accesso non autorizzato di terzi, da abusi o dall’uso fraudolento. Se esiste anche solo il sospetto che l’ID CamDiab sia stato esposto a un tale rischio, è necessario notificarlo immediatamente all’indirizzo <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>. Abbiamo il diritto di bloccare l’ID CamDiab di qualsiasi Utilizzatore se è utilizzato per finalità illegittime o fraudolente.
                                </p>
                                <h2>
                                    4. DIRITTO DI RITIRO E RECESSO
                                </h2>
                                <h3>
                                    4.1. Informazioni sull’esercizio del diritto di recesso
                                </h3>
                                <p>
                                    4.1.1 Se Lei non utilizza i nostri Prodotti per finalità commerciali o professionali, in qualità di consumatore ha il diritto di recedere dal contratto concluso con CamDiab alle seguenti condizioni:
                                </p>
                                <p>
                                    <strong>DIRITTO DI RECESSO</strong>
                                </p>
                                <p>
                                    <strong><u>Diritto di recesso</u></strong>
                                </p>
                                <p>
                                    <strong> Lei ha il diritto di recedere da questo contratto entro quattordici giorni senza fornire giustificazioni. </strong>
                                </p>
                                <p>
                                    <strong> Il periodo di recesso è di quattordici giorni dalla data di conclusione del contratto (attivazione del contenuto digitale). </strong>
                                </p>
                                <p>
                                    <strong> Per esercitare il Suo diritto di recesso, deve notificarci la Sua decisione di recedere dal contratto inviando una chiara dichiarazione al distributore o a CamDiab, all’indirizzo e-mail </strong> <strong> <a href="mailto:support@camdiab.com">support@camdiab.com</a> </strong> <strong> (cioè per posta elettronica o posta normale). </strong>
                                </p>
                                <p>
                                    <strong> Per rispettare il periodo di recesso, è sufficiente inviare la notifica di esercizio del diritto di recesso entro il termine di detto periodo. </strong>
                                </p>
                                <p>
                                    <strong><u>Conseguenze del recesso</u></strong>
                                </p>
                                <p>
                                    <strong> Se Lei recede da questo contratto, il distributore o noi siamo costretti a rimborsare tutti i pagamenti ricevuti da parte Sua, senza indugio e al più tardi entro quattordici giorni dalla data di ricevimento da parte nostra della notifica del Suo recesso da questo contratto. Se possibile, per questo rimborso utilizzeremo lo stesso metodo di pagamento impiegato per la transazione originaria, a meno che non sia stato concordato espressamente un metodo alternativo; in nessun caso saranno addebitati eventuali oneri sostenuti per questo rimborso. </strong>
                                </p>
                                <h2>
                                    5. UTILIZZO DEI NOSTRI PRODOTTI
                                </h2>
                                <h3>
                                    5.1. Durata e cessazione dell’utilizzo
                                </h3>
                                <p>
                                    5.1.1 Il Suo diritto di utilizzo dei nostri Prodotti comincia con la registrazione e l’attivazione da parte di CamDiab.
                                </p>
                                <p>
                                    5.1.2 I nostri prodotti a pagamento possono essere acquistati con la formula di abbonamento mensile, semestrale o annuale; in funzione dell’offerta corrente sono disponibili anche per un periodo di utilizzo più breve o più lungo (in seguito “<strong>periodo di abbonamento</strong>”).
                                </p>
                                <p>
                                    5.1.3 Sia l’Utilizzatore che CamDiab sono autorizzati a cessare ordinariamente il contratto in qualsiasi momento mediante preavviso di un mese con effetto alla fine del mese. La cessazione del contratto può essere perfezionata contattando il distributore oppure mediante notifica scritta all’indirizzo <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>. Si noti che i canoni già pagati per un periodo di abbonamento non utilizzato saranno rimborsati unicamente in caso di cessazione ordinaria da parte di CamDiab o cessazione automatica (vedere la sezione 5.1.5 di seguito).
                                </p>
                                <p>
                                    5.1.4 In mancanza di una cessazione oppure in caso di ritardata cessazione, il periodo di abbonamento viene prorogato automaticamente di un altro periodo corrispondente al periodo di abbonamento precedentemente selezionato. Si applica il corrispondente prezzo standard valido al momento della proroga (non i prezzi di eventuali offerte gratuite o speciali).
                                </p>
                                <p>
                                    5.1.5 In caso di decesso dell’Utilizzatore oppure di revoca del consenso alla privacy, il rapporto di utilizzo cessa automaticamente con effetto immediato. CamDiab non è autorizzata a trattare i Suoi dati sanitari senza il Suo consenso.
                                </p>
                                <p>
                                    5.1.6 Per eventuali domande riguardanti la durata o la cessazione dell’utilizzo può rivolgersi in qualsiasi momento all’indirizzo <a href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h3>
                                    5.2. Blocco ed esclusione
                                </h3>
                                <p>
                                    5.2.1 Ci riserviamo la facoltà di bloccare temporaneamente gli Utilizzatori in qualsiasi momento per motivi importanti (a nostra discrezione) oppure di escluderli temporaneamente dall’utilizzo dei nostri Prodotti. In caso di tale cessazione del contratto senza preavviso, è escluso il rimborso dei canoni di utilizzo.
                                </p>
                                <p>
                                    5.2.2 Sussiste un motivo importante in particolare in caso di gravi violazioni da parte dell’Utilizzatore delle disposizioni dei presenti T&amp;C, ad es. violazioni della sezione 3.1 (Pagamento) dopo la concessione di un periodo di grazia di due settimane, della sezione 3.2 (Dati di registrazione), della sezione 5.3 (Licenza) o della sezione 6 (“Comportamento dell’Utilizzatore”).
                                </p>
                                <h3>
                                    5.3. Licenza e concessione di diritti
                                </h3>
                                <p>
                                    5.3.1 Con la registrazione e l’attivazione, Lei acquisisce il diritto non esclusivo, non trasferibile, ma geograficamente illimitato di salvare e utilizzare una copia della nostra app per le Sue finalità nell’ambito dei presenti T&amp;C. Per i Prodotti a pagamento, questa licenza è limitata alla durata del Suo periodo di abbonamento contrattuale.
                                </p>
                                <p>
                                    5.3.2 Il software, il codice, i metodi e i sistemi, nonché il contenuto dei nostri Prodotti sono protetti dal diritto d’autore e dal diritto in materia di concorrenza e possono essere utilizzati esclusivamente da CamDiab. I nostri Prodotti o parti degli stessi non possono essere copiati, modificati, riprodotti, ripubblicati, postati, trasferiti, venduti, offerti in vendita, rivenduti né utilizzati in nessun altro modo senza previo consenso scritto da parte nostra. Gli utilizzatori non sono autorizzati ad utilizzare marchi, loghi o altri diritti di proprietà commerciale o diritti sui marchi di CamDiab. Salvo quanto diversamente disposto dai presenti T&amp;C, tutti i diritti di utilizzo e di sfruttamento sono di esclusiva proprietà di CamDiab e non è previsto alcun tipo di concessione di licenza per i nostri Prodotti.
                                </p>
                                <p>
                                    5.3.3 Qualora fosse necessario per la messa a disposizione tecnica dei nostri Prodotti, ogni utilizzatore concede a CamDiab un diritto di utilizzo e di sfruttamento non esclusivo, revocabile, ma liberamente trasferibile, illimitato nel tempo e nello spazio, del contenuto che ha generato, trasferito, salvato o pubblicato all’interno dei nostri Prodotti. L’utilizzo o lo sfruttamento è tuttavia escluso nel caso in cui ciò pregiudichi sproporzionatamente gli interessi legittimi dell’Utilizzatore (ad es. i diritti di privacy). In caso di utilizzo al di fuori dei nostri Prodotti, se appropriato segnaleremo che il contenuto proviene dall’Utilizzatore. CamDiab non rivendica la proprietà del contenuto creato e non pretende di svolgere alcuna funzione di supervisione del contenuto creato dagli Utilizzatori.
                                </p>
                                <h3>
                                    5.4. Disponibilità dei nostri Prodotti
                                </h3>
                                <p>
                                    5.4.1 Forniamo i nostri Prodotti in tutti i casi in conformità alle possibilità tecniche, economiche, operative e organizzative esistenti. CamDiab non può escludere interruzioni, disturbi, ritardi, cancellazioni, trasmissioni errate o errori di salvataggio connessi all’utilizzo dei nostri Prodotti o alla comunicazione con gli Utilizzatori. Offriamo parzialmente i nostri Prodotti in collaborazione con distributori e fornitori terzi e dipendiamo quindi anche dalla fornitura tecnica di servizi di terzi. CamDiab non accetta quindi alcuna responsabilità, non rilascia alcuna garanzia e non si assume alcun obbligo di fornire i propri Prodotti online sempre senza interruzioni.
                                </p>
                                <p>
                                    5.4.2 Questo requisito riguarda anche le limitazioni di utilizzo dei nostri Prodotti a causa di forza maggiore, scioperi, serrate e disposizioni ufficiali oppure a causa di modifiche tecniche o lavori di manutenzione ai sistemi CamDiab. In caso di interruzioni o guasti ai nostri Prodotti, contattare in qualsiasi momento <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h2>
                                    6. COMPORTAMENTO DELL’UTILIZZATORE
                                </h2>
                                <h3>
                                    6.1. Generalità
                                </h3>
                                <p>
                                    6.1.1 Ogni Utilizzatore è autorizzato e obbligato ad utilizzare i nostri Prodotti a proprio rischio e a proprie spese facendo uso di idonei dispositivi tecnici. Per conoscere i dispositivi e i sistemi operativi compatibili con i Prodotti si può scrivere in qualsiasi momento all’indirizzo <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <p>
                                    6.1.2 L’Utilizzatore è inoltre tenuto ad utilizzatore i nostri Prodotti esclusivamente in conformità ai presenti T&amp;C e alle disposizioni legali, e ad evitare qualsiasi tipo di uso illegale. La invitiamo a informarci immediatamente qualora venisse a conoscenza di violazioni degli obblighi specificati in questa sezione, scrivendo all’indirizzo <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h3>
                                    6.2. Abuso
                                </h3>
                                <p>
                                    6.2.1 Ogni Utilizzatore è tenuto a dichiarare in modo veritiero i propri dati di registrazione, a mantenerli aggiornati e ad integrarli, come pure a non divulgarli a terzi. I dati personali devono essere trattati con riservatezza, gestiti con cura e salvati in backup sotto la propria responsabilità. CamDiab non accetta alcuna responsabilità e non rilascia alcuna garanzia per la perdita o il danneggiamento di dati o contenuti qualora ciò non sia imputabile a CamDiab stessa.
                                </p>
                                <p>
                                    6.2.2 Contenuti come foto, immagini, testi, video o altri elementi figurativi possono essere salvati, pubblicati, trasferiti o distribuiti in associazione ai nostri Prodotti esclusivamente se l’Utilizzatore ha il diritto di trasferirli o utilizzarli. In tutti i casi è sempre vietato l’utilizzo di contenuti razzisti, offensivi, discriminatori, diffamatori, sessuali, pornografici, violenti o altri contenuti illegali di qualsiasi genere.
                                </p>
                                <p>
                                    6.2.3 Inoltre, è vietato decriptare, decodificare, decompilare o disassemblare i nostri Prodotti o utilizzare i nostri Prodotti su smartphone rootati. Ogni Utilizzatore è tenuto ad astenersi da qualsiasi tipo di alterazione dei nostri Prodotti mediante mezzi tecnici o elettronici (in particolare tentativi di hacking, attacchi di forza bruta, introduzione di virus, worm, cavalli di Troia o altri tipi di malware) e da qualsiasi tentativo di alterazione che potrebbe influenzare il software o l’hardware dei Prodotti e dei sistemi di CamDiab. Qualsiasi manomissione dei nostri Prodotti può portare al mancato funzionamento degli stessi secondo la rispettiva destinazione d’uso.
                                </p>
                                <h2>
                                    7. AMBITO DI NOSTRA RESPONSABILITÀ
                                </h2>
                                <h3>
                                    7.1. Garanzia e responsabilità
                                </h3>
                                <p>
                                    7.1.1 Salvo quanto diversamente disposto dai presenti T&amp;C, CamDiab è responsabile e tenuta a rilasciare garanzie secondo i termini di legge.
                                </p>
                                <p>
                                    7.1.2 Per quanto concerne gli Utilizzatori che non sono consumatori, è esclusa la responsabilità per perdite finanziarie, danni consequenziali, mancato guadagno e danni derivanti da rivendicazioni di terzi. Se la nostra responsabilità è limitata o esclusa nei presenti T&amp;C, ciò riguarda ugualmente agenti vicari o società affiliate di CamDiab.
                                </p>
                                <p>
                                    7.1.3 Ferma restando l’applicazione delle leggi sulla responsabilità per il prodotto, la responsabilità di CamDiab è limitata ai casi di dolo e grave negligenza, fatta eccezione per le lesioni personali. È esclusa la responsabilità per lieve negligenza a fronte di connesse giustificazioni oggettive.
                                </p>
                                <p>
                                    7.1.4 CamDiab non rilascia alcuna garanzia che i propri Prodotti siano completamente disponibili senza interruzioni e senza errori o che il software e l’hardware necessari funzionino senza errori. Non possiamo nemmeno escludere la possibilità che i dati possano essere tracciati, registrati o falsificati da terzi durante il relativo trasferimento per mezzo di sistemi di terzi, in particolare tramite Internet e altre reti di telecomunicazione.
                                </p>
                                <p>
                                    7.1.5 CamDiab non rilascia alcuna garanzia per i contenuti o i materiali scaricati e ricevuti dagli Utilizzatori come conseguenza dell’utilizzo dei nostri Prodotti. L’Utilizzatore è l’unico responsabile per tutti i danni che potrebbero verificarsi nel suo sistema IT o nei suoi dispositivi oppure per la perdita di dati come conseguenza dei materiali scaricati in associazione con i nostri Prodotti.
                                </p>
                                <p>
                                    7.1.6 Inoltre, CamDiab non è responsabile per i contenuti di terzi, quali link esterni, banner, altre informazioni o offerte pubblicitarie di terzi eventualmente inseriti nei nostri Prodotti. Pur consentendo l’accesso a offerte di terzi tramite notifiche o link, CamDiab non è responsabile per le informazioni contenute negli stessi. Eventuali conseguenti transazioni legali con terzi creano rapporti contrattuali esclusivamente tra l’Utilizzatore la rispettiva terza parte. Non rilasciamo alcuna garanzia e decliniamo qualsiasi responsabilità per i servizi di terzi.
                                </p>
                                <h3>
                                    7.2. Esclusione di responsabilità e indennizzo
                                </h3>
                                <p>
                                    7.2.1 L’utilizzo dei nostri Prodotti non sostituisce la consultazione di un operatore sanitario o altri consulti medici ed avviene esclusivamente a rischio dell’Utilizzatore. Ciò riguarda qualsiasi utilizzo da parte dell’Utilizzatore dei dati ottenuti contestualmente all’utilizzo dei nostri Prodotti, compresi risultati della glicemia del sensore e della glicemia, calcoli ed eventuali raccomandazioni. L’Utilizzatore accetta espressamente che questi dati possono essere errati e CamDiab declina qualsiasi responsabilità per la relativa correttezza.
                                </p>
                                <p>
                                    7.2.2 Ai sensi delle leggi sui dispositivi medici che disciplinano l’utilizzo dei Prodotti, i Prodotti possono essere utilizzati o gestiti esclusivamente secondo la finalità, le specifiche e gli ambiti di applicazione definiti nell’offerta e nei termini d’uso.
                                </p>
                                <p>
                                    7.2.3 L’Utilizzatore indennizzerà CamDiab contro tutte le rivendicazioni di terzi nei confronti di CamDiab come conseguenza della violazione illegittima dei suoi diritti da parte dell’Utilizzatore in relazione all’utilizzo dei nostri Prodotti. Sono fatte salve tutte le altre rivendicazioni di risarcimento danni da parte di CamDiab nei confronti dell’Utilizzatore.
                                </p>
                                <p>
                                    7.2.4 L’Utilizzatore si assume la piena responsabilità per tutti i danni e le controversie giudiziali ed extragiudiziali derivanti da conflitti con altri Utilizzatori. L’Utilizzatore riconosce espressamente che CamDiab non è in alcun caso responsabile per azioni o omissioni ad opera di altri Utilizzatori né per eventuali danni conseguenti.
                                </p>
                                <p>
                                    7.2.5 In caso di rivendicazioni sollevate da terzi nei confronti di CamDiab, l’Utilizzatore è tenuto a fornire immediatamente tutte le informazioni a sua disposizione, in modo veritiero e completo, nei termini richiesti al fine di consentire l’esame, la difesa e la prevenzione di rivendicazioni di terzi. L’Utilizzatore si accolla i costi dell’eventuale difesa legale richiesta da CamDiab, compresi tutti i costi legali e giudiziari negli importi stabiliti dalla legge.
                                </p>
                                <h2>
                                    8. DISPOSIZIONI CONCLUSIVE
                                </h2>
                                <h3>
                                    8.1. Scelta del diritto
                                </h3>
                                <p>
                                    I presenti T&amp;C e l’intero rapporto legale tra CamDiab e l’Utilizzatore sono soggetti esclusivamente al diritto inglese, fatta eccezione per le disposizioni di riferimento e la Convenzione delle Nazioni Unite sui contratti di vendita internazionale di merci. Tuttavia, in quanto consumatore l’Utilizzatore gode della protezione delle disposizioni obbligatorie nel Paese in cui si trova o risiede abitualmente.
                                </p>
                                <h3>
                                    8.2. Foro competente
                                </h3>
                                <p>
                                    Come luogo di adempimento e foro competente esclusivo per tutte le controversie derivanti dai presenti T&amp;C è stata convenuta la sede principale di CamDiab a Cambridge nel Regno Unito. Sono fatte salve le norme obbligatorie sui conflitti di leggi a favore dei consumatori.
                                </p>
                                <h3>
                                    8.3. Varie
                                </h3>
                                <p>
                                    8.3.1 Se i presenti T&amp;C richiedono la notifica o la forma scritta (anche per dichiarazioni legalmente importanti), è sufficiente la trasmissione tramite posta elettronica all’indirizzo indicato al momento della registrazione (ID CamDiab).
                                </p>
                                <p>
                                    8.3.2 Gli Utilizzatori non sono autorizzati a cedere a terzi il loro ID CamDiab o i loro diritti e obblighi ai sensi dei presenti T&amp;C in associazione con l’utilizzo dei nostri Prodotti. CamDiab può cedere i propri diritti e obblighi ai sensi dei presenti T&amp;C ad un’altra azienda o a terzi qualora detta cessione non abbia effetti significativi sui diritti dell’Utilizzatore ai sensi dei presenti T&amp;C.
                                </p>
                                <p>
                                    8.3.3 Qualora singole disposizioni dei presenti T&amp;C fossero o diventassero inefficaci, le restanti disposizioni dei T&amp;C non interessate dalla perdita di validità continueranno a rimanere valide ed efficaci.
                                </p>
                                <p>
                                    8.3.4 I presenti T&amp;C vengono messi a disposizione in varie lingue. Fa fede ed è vincolante la versione nella lingua del Paese in cui il consumatore si trova o risiede abitualmente. Se e nella misura in cui non ci sia alcuna versione nella rispettiva lingua nazionale, fa fede la versione inglese.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer props={this.props}/>
            </div>
        );
    }
}

