import React from 'react';
import {Header} from './Header';
import {Footer} from './Footer';
import {Link, NavLink} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import article_img01 from '../assets/images/article-sam-and-sofia-steve.jpg';
import article_img02 from '../assets/images/article-roger.jpg';
import {userReviewsIntro as userReviewsIntro_en, whatIsCamAPSFX as whatIsCamAPSFX_en} from "../libs/en/common-text-lib";
import {userReviewsIntro as userReviewsIntro_de, whatIsCamAPSFX as whatIsCamAPSFX_de} from "../libs/de/common-text-lib";
import {userReviewsIntro as userReviewsIntro_fr, whatIsCamAPSFX as whatIsCamAPSFX_fr} from "../libs/fr/common-text-lib";
import {userReviewsIntro as userReviewsIntro_nl, whatIsCamAPSFX as whatIsCamAPSFX_nl} from "../libs/nl/common-text-lib";
import {userReviewsIntro as userReviewsIntro_it, whatIsCamAPSFX as whatIsCamAPSFX_it} from "../libs/it/common-text-lib";
import {userReviewsIntro as userReviewsIntro_cz, whatIsCamAPSFX as whatIsCamAPSFX_cz} from "../libs/cz/common-text-lib";
import {userReviewsIntro as userReviewsIntro_fi, whatIsCamAPSFX as whatIsCamAPSFX_fi} from "../libs/fi/common-text-lib";
import {userReviewsIntro as userReviewsIntro_pl, whatIsCamAPSFX as whatIsCamAPSFX_pl} from "../libs/pl/common-text-lib";
import {userReviewsIntro as userReviewsIntro_se, whatIsCamAPSFX as whatIsCamAPSFX_se} from "../libs/se/common-text-lib";
import {userReviewsIntro as userReviewsIntro_no, whatIsCamAPSFX as whatIsCamAPSFX_no} from "../libs/no/common-text-lib";
import {userReviewsIntro as userReviewsIntro_dk, whatIsCamAPSFX as whatIsCamAPSFX_dk} from "../libs/dk/common-text-lib";
import {userReviewsIntro as userReviewsIntro_es, whatIsCamAPSFX as whatIsCamAPSFX_es} from "../libs/es/common-text-lib";

import {samReviewText as samReviewText_en} from "../libs/en/texts-review-messages-lib";
import {samReviewText as samReviewText_de} from "../libs/de/texts-review-messages-lib";
import {samReviewText as samReviewText_fr} from "../libs/fr/texts-review-messages-lib";
import {samReviewText as samReviewText_nl} from "../libs/nl/texts-review-messages-lib";
import {samReviewText as samReviewText_it} from "../libs/it/texts-review-messages-lib";
import {samReviewText as samReviewText_cz} from "../libs/cz/texts-review-messages-lib";
import {samReviewText as samReviewText_fi} from "../libs/fi/texts-review-messages-lib";
import {samReviewText as samReviewText_pl} from "../libs/pl/texts-review-messages-lib";
import {samReviewText as samReviewText_se} from "../libs/se/texts-review-messages-lib";
import {samReviewText as samReviewText_no} from "../libs/no/texts-review-messages-lib";
import {samReviewText as samReviewText_dk} from "../libs/dk/texts-review-messages-lib";
import {samReviewText as samReviewText_es} from "../libs/es/texts-review-messages-lib";
import {rogerReviewText as rogerReviewText_en} from "../libs/en/texts-review-messages-lib";
import {rogerReviewText as rogerReviewText_de} from "../libs/de/texts-review-messages-lib";
import {rogerReviewText as rogerReviewText_fr} from "../libs/fr/texts-review-messages-lib";
import {rogerReviewText as rogerReviewText_nl} from "../libs/nl/texts-review-messages-lib";
import {rogerReviewText as rogerReviewText_it} from "../libs/it/texts-review-messages-lib";
import {rogerReviewText as rogerReviewText_cz} from "../libs/cz/texts-review-messages-lib";
import {rogerReviewText as rogerReviewText_fi} from "../libs/fi/texts-review-messages-lib";
import {rogerReviewText as rogerReviewText_pl} from "../libs/pl/texts-review-messages-lib";
import {rogerReviewText as rogerReviewText_se} from "../libs/se/texts-review-messages-lib";
import {rogerReviewText as rogerReviewText_no} from "../libs/no/texts-review-messages-lib";
import {rogerReviewText as rogerReviewText_dk} from "../libs/dk/texts-review-messages-lib";
import {rogerReviewText as rogerReviewText_es} from "../libs/es/texts-review-messages-lib";
import {reviewsPage as reviewsPage_en} from "../libs/en/reviews-page-texts-lib";
import {reviewsPage as reviewsPage_de} from "../libs/de/reviews-page-texts-lib";
import {reviewsPage as reviewsPage_fr} from "../libs/fr/reviews-page-texts-lib";
import {reviewsPage as reviewsPage_nl} from "../libs/nl/reviews-page-texts-lib";
import {reviewsPage as reviewsPage_it} from "../libs/it/reviews-page-texts-lib";
import {reviewsPage as reviewsPage_cz} from "../libs/cz/reviews-page-texts-lib";
import {reviewsPage as reviewsPage_fi} from "../libs/fi/reviews-page-texts-lib";
import {reviewsPage as reviewsPage_pl} from "../libs/pl/reviews-page-texts-lib";
import {reviewsPage as reviewsPage_se} from "../libs/se/reviews-page-texts-lib";
import {reviewsPage as reviewsPage_no} from "../libs/no/reviews-page-texts-lib";
import {reviewsPage as reviewsPage_dk} from "../libs/dk/reviews-page-texts-lib";
import {reviewsPage as reviewsPage_es} from "../libs/es/reviews-page-texts-lib";

import parse from "html-react-parser";

class Reviews extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            language: this.props.language,
            samReviewText: samReviewText_en,
            rogerReviewText: rogerReviewText_en,
            whatIsCamAPSFX: whatIsCamAPSFX_en,
            userReviewsIntro: userReviewsIntro_en,
            reviewsPage: reviewsPage_en,
            prefix: ''
        };

        // set localisation text
        if (this.props.language === "DE") {
            this.state.samReviewText = samReviewText_de;
            this.state.rogerReviewText = rogerReviewText_de;
            this.state.whatIsCamAPSFX = whatIsCamAPSFX_de;
            this.state.userReviewsIntro = userReviewsIntro_de;
            this.state.reviewsPage = reviewsPage_de;
            this.state.prefix = '/de';
        }

        if (this.props.language === "FR") {
            this.state.samReviewText = samReviewText_fr;
            this.state.rogerReviewText = rogerReviewText_fr;
            this.state.whatIsCamAPSFX = whatIsCamAPSFX_fr;
            this.state.userReviewsIntro = userReviewsIntro_fr;
            this.state.reviewsPage = reviewsPage_fr;
            this.state.prefix = '/fr-ch';
        }

        if (this.props.language === "FR-FR") {
            this.state.samReviewText = samReviewText_fr;
            this.state.rogerReviewText = rogerReviewText_fr;
            this.state.whatIsCamAPSFX = whatIsCamAPSFX_fr;
            this.state.userReviewsIntro = userReviewsIntro_fr;
            this.state.reviewsPage = reviewsPage_fr;
            this.state.prefix = '/fr';
        }

        if (this.props.language === "NL") {
            this.state.samReviewText = samReviewText_nl;
            this.state.rogerReviewText = rogerReviewText_nl;
            this.state.whatIsCamAPSFX = whatIsCamAPSFX_nl;
            this.state.userReviewsIntro = userReviewsIntro_nl;
            this.state.reviewsPage = reviewsPage_nl;
            this.state.prefix = '/nl';
        }
        if (this.props.language === "IT") {
            this.state.samReviewText = samReviewText_it;
            this.state.rogerReviewText = rogerReviewText_it;
            this.state.whatIsCamAPSFX = whatIsCamAPSFX_it;
            this.state.userReviewsIntro = userReviewsIntro_it;
            this.state.reviewsPage = reviewsPage_it;
            this.state.prefix = '/it';
        }
        if (this.props.language === "CZ") {
            this.state.samReviewText = samReviewText_cz;
            this.state.rogerReviewText = rogerReviewText_cz;
            this.state.whatIsCamAPSFX = whatIsCamAPSFX_cz;
            this.state.userReviewsIntro = userReviewsIntro_cz;
            this.state.reviewsPage = reviewsPage_cz;
            this.state.prefix = '/cz';
        }
        if (this.props.language === "FI") {
            this.state.samReviewText = samReviewText_fi;
            this.state.rogerReviewText = rogerReviewText_fi;
            this.state.whatIsCamAPSFX = whatIsCamAPSFX_fi;
            this.state.userReviewsIntro = userReviewsIntro_fi;
            this.state.reviewsPage = reviewsPage_fi;
            this.state.prefix = '/fi';
        }
        if (this.props.language === "PL") {
            this.state.samReviewText = samReviewText_pl;
            this.state.rogerReviewText = rogerReviewText_pl;
            this.state.whatIsCamAPSFX = whatIsCamAPSFX_pl;
            this.state.userReviewsIntro = userReviewsIntro_pl;
            this.state.reviewsPage = reviewsPage_pl;
            this.state.prefix = '/pl';
        }
        if (this.props.language === "SE") {
            this.state.samReviewText = samReviewText_se;
            this.state.rogerReviewText = rogerReviewText_se;
            this.state.whatIsCamAPSFX = whatIsCamAPSFX_se;
            this.state.userReviewsIntro = userReviewsIntro_se;
            this.state.reviewsPage = reviewsPage_se;
            this.state.prefix = '/se';
        }
        if (this.props.language === "NO") {
            this.state.samReviewText = samReviewText_no;
            this.state.rogerReviewText = rogerReviewText_no;
            this.state.whatIsCamAPSFX = whatIsCamAPSFX_no;
            this.state.userReviewsIntro = userReviewsIntro_no;
            this.state.reviewsPage = reviewsPage_no;
            this.state.prefix = '/nb';
        }
        if (this.props.language === "DK") {
            this.state.samReviewText = samReviewText_dk;
            this.state.rogerReviewText = rogerReviewText_dk;
            this.state.whatIsCamAPSFX = whatIsCamAPSFX_dk;
            this.state.userReviewsIntro = userReviewsIntro_dk;
            this.state.reviewsPage = reviewsPage_dk;
            this.state.prefix = '/dk';
        }
        if (this.props.language === "ES") {
            this.state.samReviewText = samReviewText_es;
            this.state.rogerReviewText = rogerReviewText_es;
            this.state.whatIsCamAPSFX = whatIsCamAPSFX_es;
            this.state.userReviewsIntro = userReviewsIntro_es;
            this.state.reviewsPage = reviewsPage_es;
            this.state.prefix = '/es';
        }
    }

    /**
     * Sets localisation text
     * */
    setLanguageVars() {
        if (this.state.language !== this.props.language) {
            if (this.props.language === "EN") {
                this.setState({
                    language: this.props.language,
                    samReviewText: samReviewText_en,
                    rogerReviewText: rogerReviewText_en,
                    whatIsCamAPSFX: whatIsCamAPSFX_en,
                    userReviewsIntro: userReviewsIntro_en,
                    reviewsPage: reviewsPage_en,
                    prefix: ''
                });
            }

            // set localisation text
            if (this.props.language === "DE") {
                this.setState({
                    language: this.props.language,
                    samReviewText: samReviewText_de,
                    rogerReviewText: rogerReviewText_de,
                    whatIsCamAPSFX: whatIsCamAPSFX_de,
                    userReviewsIntro: userReviewsIntro_de,
                    reviewsPage: reviewsPage_de,
                    prefix: '/de'
                });
            }

            if (this.props.language === "FR") {
                this.setState({
                    language: this.props.language,
                    samReviewText: samReviewText_fr,
                    rogerReviewText: rogerReviewText_fr,
                    whatIsCamAPSFX: whatIsCamAPSFX_fr,
                    userReviewsIntro: userReviewsIntro_fr,
                    reviewsPage: reviewsPage_fr,
                    prefix: '/fr-ch'
                });
            }

            if (this.props.language === "FR-FR") {
                this.setState({
                    language: this.props.language,
                    samReviewText: samReviewText_fr,
                    rogerReviewText: rogerReviewText_fr,
                    whatIsCamAPSFX: whatIsCamAPSFX_fr,
                    userReviewsIntro: userReviewsIntro_fr,
                    reviewsPage: reviewsPage_fr,
                    prefix: '/fr'
                });
            }

            if (this.props.language === "NL") {
                this.setState({
                    language: this.props.language,
                    samReviewText: samReviewText_nl,
                    rogerReviewText: rogerReviewText_nl,
                    whatIsCamAPSFX: whatIsCamAPSFX_nl,
                    userReviewsIntro: userReviewsIntro_nl,
                    reviewsPage: reviewsPage_nl,
                    prefix: '/nl'
                });
            }
            if (this.props.language === "IT") {
                this.setState({
                    language: this.props.language,
                    samReviewText: samReviewText_it,
                    rogerReviewText: rogerReviewText_it,
                    whatIsCamAPSFX: whatIsCamAPSFX_it,
                    userReviewsIntro: userReviewsIntro_it,
                    reviewsPage: reviewsPage_it,
                    prefix: '/it'
                });
            }
            if (this.props.language === "CZ") {
                this.setState({
                    language: this.props.language,
                    samReviewText: samReviewText_cz,
                    rogerReviewText: rogerReviewText_cz,
                    whatIsCamAPSFX: whatIsCamAPSFX_cz,
                    userReviewsIntro: userReviewsIntro_cz,
                    reviewsPage: reviewsPage_cz,
                    prefix: '/cz'
                });
            }
            if (this.props.language === "FI") {
                this.setState({
                    language: this.props.language,
                    samReviewText: samReviewText_fi,
                    rogerReviewText: rogerReviewText_fi,
                    whatIsCamAPSFX: whatIsCamAPSFX_fi,
                    userReviewsIntro: userReviewsIntro_fi,
                    reviewsPage: reviewsPage_fi,
                    prefix: '/fi'
                });
            }
            if (this.props.language === "PL") {
                this.setState({
                    language: this.props.language,
                    samReviewText: samReviewText_pl,
                    rogerReviewText: rogerReviewText_pl,
                    whatIsCamAPSFX: whatIsCamAPSFX_pl,
                    userReviewsIntro: userReviewsIntro_pl,
                    reviewsPage: reviewsPage_pl,
                    prefix: '/pl'
                });
            }
            if (this.props.language === "SE") {
                this.setState({
                    language: this.props.language,
                    samReviewText: samReviewText_se,
                    rogerReviewText: rogerReviewText_se,
                    whatIsCamAPSFX: whatIsCamAPSFX_se,
                    userReviewsIntro: userReviewsIntro_se,
                    reviewsPage: reviewsPage_se,
                    prefix: '/se'
                });
            }
            if (this.props.language === "NO") {
                this.setState({
                    language: this.props.language,
                    samReviewText: samReviewText_no,
                    rogerReviewText: rogerReviewText_no,
                    whatIsCamAPSFX: whatIsCamAPSFX_no,
                    userReviewsIntro: userReviewsIntro_no,
                    reviewsPage: reviewsPage_no,
                    prefix: '/nb'
                });
            }
            if (this.props.language === "DK") {
                this.setState({
                    language: this.props.language,
                    samReviewText: samReviewText_dk,
                    rogerReviewText: rogerReviewText_dk,
                    whatIsCamAPSFX: whatIsCamAPSFX_dk,
                    userReviewsIntro: userReviewsIntro_dk,
                    reviewsPage: reviewsPage_dk,
                    prefix: '/dk'
                });
            }
            if (this.props.language === "ES") {
                this.setState({
                    language: this.props.language,
                    samReviewText: samReviewText_es,
                    rogerReviewText: rogerReviewText_es,
                    whatIsCamAPSFX: whatIsCamAPSFX_es,
                    userReviewsIntro: userReviewsIntro_es,
                    reviewsPage: reviewsPage_es,
                    prefix: '/es'
                });
            }
        }
    }

    componentDidUpdate() {
        this.setLanguageVars();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>{parse(this.state.reviewsPage.title)}</title>
                    <meta name="title" content={parse(this.state.reviewsPage.titleMeta)}/>
                    <meta name="description"
                          content={parse(this.state.reviewsPage.descriptionMeta)}/>
                    <meta property="og:locale" content={this.state.reviewsPage.localeMeta}/>
                    <meta name="keywords" content={this.state.reviewsPage.keywords}/>
                </Helmet>
                <Header props={this.props}/>
                <section className='inner-banner'>
                    <div className='container'>
                        <div className='row justify-content-center'>
                            <div className='col-12 col-md-8 col-xl-9'>
                                <h1>{parse(this.state.userReviewsIntro.header)}</h1>
                                <p>{parse(this.state.userReviewsIntro.text)}</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='review-page'>
                    <div className='container'>
                        <div className='review-page-content'>
                            <div className='review-content-small'>
                                {/* <img src={article_img01} alt='image' /> */}
                                <span
                                    className='review-img'
                                    style={{backgroundImage: `url(${article_img01})`}}>
								</span>
                                {parse(this.state.samReviewText.shortIntroAndBody)}
                                <NavLink
                                    to={this.state.prefix + '/reviews/sam-and-sofia-stevie'}
                                    title={parse(this.state.userReviewsIntro.readMore)}
                                    className='border-link'>
                                    {parse(this.state.userReviewsIntro.readMore)}
                                </NavLink>
                            </div>
                            <div className='review-content-large'>
                                <div className='review-content-head'>
                                    {parse(this.state.reviewsPage.shortReview1)}
                                </div>
                                <div className='review-content-info'>
                                    {parse(this.state.reviewsPage.shortReview2)}
                                </div>
                            </div>
                        </div>
                        <div className='review-page-content'>
                            <div className='review-content-small'>
                                {/* <img src={article_img02} alt='image' /> */}
                                <span
                                    className='review-img'
                                    style={{backgroundImage: `url(${article_img02})`}}>
								</span>
                                {parse(this.state.rogerReviewText.shortIntroAndBody)}
                                <NavLink
                                    to={this.state.prefix + '/reviews/roger'}
                                    title={parse(this.state.userReviewsIntro.readMore)}
                                    className='border-link'>
                                    {parse(this.state.userReviewsIntro.readMore)}
                                </NavLink>
                            </div>
                            <div className='review-content-large'>
                                <div className='review-content-info'>
                                    {parse(this.state.reviewsPage.shortReview4)}
                                </div>
                                <div className='review-content-head'>
                                    {parse(this.state.reviewsPage.shortReview3)}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='content-sec'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-sm-12'>
                                <div className='content-inner'>
                                    {parse(this.state.whatIsCamAPSFX.body)}
                                    <Link to={this.state.prefix + '/faq'}
                                          className='btn'>
                                        {parse(this.state.whatIsCamAPSFX.linkFindOutMore)}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer props={this.props}/>
            </div>
        );
    }
}

export default Reviews;
