/**
 * Texts for Sam's review article
 */
export const samReviewText = {
    title: 'Sam and Sofia-Stevie - CamAPS FX',
    titleMeta: 'Rezension zu CamAPS FX von Sam und Sofia-Stevie lesen',
    descriptionMeta: 'CamAPS FX hat das Leben von Sam und Sofia-Stevie verändert, ' +
        'so dass sich Sam zum ersten Mal seit der Diagnose entspannen kann.',
    keywords:
        'camaps Rezension Sam and Sofia-Stevie, camaps fx Rezension Sam and Sofia-Stevie, ' +
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ' +
        '',
    localeMeta: 'de_de',

    imageTitle: 'Sam und Sofia-Stevie',
    shortIntroAndBody:
        '<h2>Ich kann mich zum ersten Mal seit der Diagnose entspannen</h2>' +
        '<p>' +
        'Ich habe vollstes Vertrauen in die CamAPS FX App und kann mich zum ersten Mal seit der Diagnose entspannen. Ohne jeden Zweifel hat sich die Zeit, die sich Sofia-Stevie im Blutzuckerzielbereich befindet, verbessert. ' +
        '</p>',
    body:
        '<h2>Ich kann mich zum ersten Mal seit der Diagnose entspannen</h2> ' +
        '<p> ' +
        '<strong> ' +
        'In den letzten zwei Jahren hat Sam, die Mutter von Sofia-Stevie (5 Jahre alt) die extrem steile Lernkurve ausgehalten, die alle Eltern von Kindern mit Typ-1-Diabetes durchmachen. Einen Sturm von Fingerstichtests, Injektionen und Sensoren später, hat sie nun die CamAPS FX App entdeckt und das hybride Closed-Loop System und möchte nicht mehr ohne sein. ' +
        '</strong> ' +
        '</p> ' +
        '<p> ' +
        'In einem heißen Sommer ist großer Durst nichts Ungewöhnliches für ein kleines Kind. Nichtsdestotrotz folgten Sam und ihre Mutter ihrer Intuition, dass etwas anderes dahinter stecken könnte und forschten nach, ob es Grund zur Besorgnis gibt, was schließlich zu Sofia-Stevies Diagnose von Typ-1-Diabetes führte. ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '“Die Diagnose veränderte alles für immer; ich kann mich an die ersten Wochen erinnern, als sei es gestern gewesen. Ich hatte ich das Gefühl, als müsse ich quasi über Nacht zum Diabetesexperten werden, um mich richtig um meine kleine Tochter kümmern zu können. Sie hat absolute Priorität, so dass ich es einfach gemacht habe, und ich bin wirklich stolz auf mich und meine Mutter – sie war auf dieser Reise von Anfang an dabei.” ' +
        '    </i> ' +
        '</p> ' +
        '<h3> ' +
        '    Eine steile Lernkurve ' +
        '</h3> ' +
        '<p> ' +
        'Wie die meisten Eltern von neu diagnostizierten Kindern mit Typ-1-Diabetes, wurde Sam schnell Experte in Fingerstichtests, Dosierung von Basal- und Bolusinsulin und Maßnahmen, wenn die Tochter hypoglykämisch wurde. Seinem Kind Spritzen zu setzen, ist für alle Eltern eine Herausforderung, aber für Sam ganz besonders, weil sie Angst vor Nadeln überwinden musste. ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '“Das klinische Team unterstützte mich hervorragend dabei, einige große Herausforderungen in diesen ersten Tagen zu meistern. Ich glaube nicht, dass wird dort wären, wo wir heute sind, wenn wir nicht die Unterstützung des klinischen Teams gehabt hätten.” ' +
        '    </i> ' +
        '</p> ' +
        '<p> ' +
        'In den ersten Monaten nach Sofia-Stevies Diagnose waren Fingerstichtests ein regelmäßiges Ereignis, um die Blutzuckerwerte über den Tag zu verstehen. Sam musste sich auch nachts den Wecker stellen, um die Blutzuckerwerte ihrer Tochter zu messen. Wenn Sofia-Stevies Werte hoch oder niedrig waren, musste Sam eine Korrekturdosis verabreichen und dann warten, bis sich die Werte in die richtige Richtung bewegten, bevor sie sich selbst wieder schlafen legen konnte – ein strapaziöser Prozess für beide, jede einzelne Nacht. ' +
        '</p> ' +
        '<p> ' +
        'Eines Tages erhielt Sofia-Stevie einen kontinuierlichen Glukosemesser (CGM), um die Behandlung zu unterstützen. Ein CGM ist ein kleines Gerät mit einem Sensor, der unter der Haut getragen wird, um die Blutzuckerwerte kontinuierlich zu messen. Sam betrachtete den CGM als Schritt in die richtige Richtung, insbesondere, weil sie mit dem Gerät nachts keinen Wecker mehr stellen musste – das Gerät würde sie automatisch alarmieren, wenn Sofia-Stevies Blutzuckerwerte außerhalb des Zielbereichs wären. Der CGM synchronisiert sich mit Sams Smartphone, so dass sie Sofia-Stevies Blutzuckerwerte in der Schule überprüfen kann, was auch den Lehrern bei der Betreuung tagsüber hilft. ' +
        '</p> ' +
        '<p> ' +
        '    Aber im Januar 2020 lernte Sam die CamAPS FX App kennen und hat seitdem nicht mehr zurückgeschaut. ' +
        '</p> ' +
        '<h3> ' +
        '    Freiheit finden ' +
        '</h3> ' +
        '<p> ' +
        ' Die CamAPS FX App wurde entwickelt, um zentrale Funktionen eines gesunden Pankreas nachzuahmen, ein sogenannter Hybrid-Closed-Loop Behandlungsansatz. Die App kommuniziert autonom mit dem CGM und der Insulinpumpe des Patienten und setzt einen komplexen Algorithmus ein, um die optimale Insulindosis alle 8-12 Minuten automatisch zu berechnen und zu verabreichen. ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '“Ich hatte noch nie vom hybriden Closed-Loop System gehört oder der CamAPS FX App. Ich wusste nicht, wie das funktioniert, aber als das klinische Team nahelegte, dass es von Nutzen für uns wäre, sind wir mit zwei Füßen hineingesprungen. Bis dahin hatte ich so viel über Diabetes gelernt, dass sich das Lernen über hybriden Closed-Loop wie ein Spaziergang im Park anfühlte.” ' +
        '    </i> ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '“Ich habe vollstes Vertrauen in die CamAPS FX App und ich kann mich zum ersten Mal seit der Diagnose entspannen. Ohne jeden Zweifel hat sich die Zeit, die sich Sofia-Stevie im Blutzuckerzielbereich befindet, verbessert. Es ist so viel einfacher geworden, ihre Werte zu kontrollieren und es ist eine schwere Last von meinen Schultern genommen worden. Es bedeutet auch weniger Belastung für ihre Lehrer und sehr viel weniger Sorge für mich. Ich kann aus der Ferne auf sie aufpassen, wenn ich möchte, aber ich habe zusätzlich die Sicherheit, dass ich automatisch Textnachrichten erhalte, wenn ihr Blutzucker hoch oder runter geht.” ' +
        '    </i> ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '“Man kann keinen Unterschied zu ihren Klassenkameraden sehen, und das ist dank der CamAPS FX App möglich.” ' +
        '    </i> ' +
        '</p> ' +
        '<p> ' +
        'Sam ist mit ihrer Beobachtung über die Veränderungen durch die CamAPS FX App für sie und ihre Tochter nicht allein. Studien zeigen, dass die App den Schlaf von Heranwachsenden verbessert und ihnen das Gefühl gibt, normal aufzuwachen, was die Diabeteskontrolle am Tag verbessert sowie Konzentration und Wohlbefinden in der Schule. Zusätzlich waren Eltern, welche die App einsetzen, weniger ängstlich, was den Diabetes ihrer Kinder betrifft, und in der Folge konnten sie ihren Kindern größere Freiheit gewähren, wie beispielsweise mit Freunden spielen gehen, an Klassenausflügen teilnehmen und übernachten. ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '“Es ist so sinnvoll für Kinder von Sofia-Stevies Alter ein Closed-Loop zu verwenden, weil das Ansprechen ihres Körper auf Insulin sich ständig verändert. Es ist eine sehr schwere Aufgabe für Eltern, die Situation ohne signifikante Hochs und Tiefs stets unter Kontrolle zu haben. Die App hingegen lernt und passt sich sofort an. Sie ist nützlich für das Langzeitmanagement der Erkrankung der Kinder und die Freiheit der Eltern, so dass sie nachts schlafen können.” ' +
        '    </i> ' +
        '</p> ' +
        '<h3> ' +
        '    Ich würde nie ohne die App sein wollen ' +
        '</h3> ' +
        '<p> ' +
        '    <i> ' +
        '“Ich würde nie ohne die App sein wollen. Sie passt auf einen auf und man muss sich keine Sorgen machen. Für jeden, der das in Erwägung zieht: Tu es. Sie ist ein Gamechanger und man schaut nicht mehr zurück oder will nicht ohne sein, wenn man sie einmal ausprobiert hat. Ich bin vollkommen überzeugt, dass das so nützlich für Eltern wie Kinder ist.” ' +
        '    </i> ' +
        '</p>'

};

/**
 * Texts for Roger's review article
 */
export const rogerReviewText = {
    title: 'Roger - CamAPS FX',
    titleMeta: 'Lesen Sie die Rezension zu CamAPS FX von Roger',
    descriptionMeta: 'Roger bemerkt, dass CamAPS FX von jedem und in jedem Alter verwendet werden kann.',
    keywords:
        'camaps Rezension Roger, camaps fx Rezension Roger, ' +
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ' +
        '',
    localeMeta: 'de_de',

    imageTitle: 'Roger',
    shortIntroAndBody:
        '<h2>Wenn ich es kann, kann es jeder</h2>' +
        '<p>' +
        'Es ist erstaunlich, wie sehr sich die Behandlung seit meiner Diagnose verändert hat, ich habe wirklich den Eindruck, dass ich von der Wüste in die digitale Welt gekommen bin. Für jeden, der den Einsatz der CamAPS FX App in Erwägung zieht, ist meine Empfehlung sehr einfach: Tu es! Es ist wirklich umwälzend, und wenn ich es kann, kann es jeder.' +
        '</p>',
    body:
        '<h2>Wenn ich es kann, kann es jeder</h2> ' +
        '<p> ' +
        '<strong> ' +
        'Als Erwachsener mit Typ-1-Diabetes diagnostiziert, hat der pensionierte Schulleiter Roger, 69 Jahre, die letzten 39 Jahre damit verbracht, seine Erkrankung besser zu verstehen. Er hat in dieser Zeit aus erster Hand die Veränderung in der Diabetestherapie erfahren, von Spritzen mit Glaskolben und dicken Nadeln, die in kochendem Wasser sterilisiert werden, bis zur CamAPS FX App und dem hybriden Closed-Loop System, ohne das er jetzt nicht mehr sein will.   ' +
        '</strong> ' +
        '</p> ' +
        '<p> ' +
        'Heutzutage wird Typ-1-Diabetes oft in der Kindheit diagnostiziert, aber bei Roger traten die Symptome erst mit 30 auf. Rogers Onkel und Schwiegermutter hatten schon Typ-1-Diabetes, so dass er mit dem Symptomen bereits vertraut war. ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '“Ich erinnere mich lebhaft, dass ich gerade gefrühstückt hatte und einige Arbeiten im Garten erledigte. Ich ging zurück ins Haus und war sehr durstig. Ich stürzte einen halben Liter Fruchtsaft hinunter und meine Frau und ich sahen uns an und sagten gleichzeitig \'Diabetes\'. Mein Hausarzt brauchte etwas Überredung, aber ich wurde getestet und bekam die Bestätigung, dass ich Diabetiker bin.” ' +
        '    </i> ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '“Zu dieser Zeit war das Wissen um Diabetes und seine Behandlung im Vergleich zu heute sehr begrenzt. Mir wurden einfach ein paar Spritzen aus Glas gegeben und mir wurde gesagt, wie ich die Kanülen in einem Topf mit kochendem Wasser sterilisiere und was meine Zielmenge an Insulin ist. Zum Glück hat mir meine Schwiegermutter ihr Diabeteshandbuch geliehen, und ich erinnere mich, dass ich mit meiner Frau in der Küche sitze, die das Buch aufhält, während ich mir selbst beibringe, wie man die Injektionen vornimmt.” ' +
        '    </i> ' +
        '</p> ' +
        '<h3> ' +
        'Nach besserer Kontrolle streben  ' +
        '</h3> ' +
        '<p> ' +
        'Während dieser ersten Jahre hat Roger sehr von den nationalen Diabetes-Patientenorganisationen profitiert, wo er zum Beispiel lernte, dass er mehr Insulin verabreichen muss, wenn er krank ist, was er vorher nicht wusste. Roger managte seine Erkrankung gut, aber dann wurden seine Blutzuckerwerte herausfordernder, weshalb er einen Kurs besuchte, in dem er lernte, die Insulinzufuhr seiner Diät und seinem Lebensstil anzupassen. Trotzdem erlebte Roger weiterhin schwere hypoglykämische Ereignisse.  ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '“Ich passte auf meinen Enkelsohn auf und spielte mit ihm im Garten Fußball. Mein Schwiegersohn holte ihn ab und sagte plötzlich: \'Roger, sieh zu, dass Du etwas Saft trinkst.\' Natürlich habe ich einen Fingerstichtest durchgeführt und mein Blutzucker war sehr niedrig, ziemlich unterhalb dessen, wo er sein sollte. Ich sollte am folgenden Tag auf meinen Enkelsohn aufpassen, aber sie trafen andere Vereinbarungen. Sie dachten, mir gehe es nicht gut genug, um auf ihn aufzupassen, und das hat mich am Boden zerstört. Ich wusste, genug ist genug.” ' +
        '    </i> ' +
        '</p> ' +
        '<p> ' +
        'Nachdem Roger diese Geschichte seinem Berater erzählte, wurde er an ein Studienprojekt vermittelt, welches ungeklärte hypoglykämische Ereignisse untersucht. Er begann mit einer Insulinpumpentherapie. Die Pumpe verbesserte Rogers Blutzuckerwerte sofort. ' +
        '</p> ' +
        '<p> ' +
        'Da Roger so sehr vom ersten Studienprojekt profitierte, ergriff er sofort die Chance und startete mit der CamAPS FX App im Oktober 2019, als ihm die Möglichkeit angeboten wurde, an einer weiteren Studie teilzunehmen. ' +
        '</p> ' +
        '<p> ' +
        ' Die CamAPS FX App wurde entwickelt, um zentrale Funktionen eines gesunden Pankreas nachzuahmen, ein sogenannter hybrider Closed-Loop Behandlungsansatz. Die App kommuniziert autonom mit dem kontinuierlichen Glukosemonitor und der Insulinpumpe des Patienten und setzt einen komplexen Algorithmus ein, um die optimale Insulindosis alle 8-12 Minuten automatisch zu berechnen und zu verabreichen.' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '“Zugegebenermaßen hatte ich vom hybriden Closed-Loop System vorher noch nichts gehört, aber als meine beiden Kinder davon erfuhren, dass ich meinen Diabetes über mein Smartphone manage, haben sie sich kaputtgelacht! Vor der Studie erschöpften sich meine Telefonkenntnisse darin, es anzuschalten, einen Anruf zu tätigen und es auszuschalten. Aber tatsächlich konnte ich mit Unterstützung des sehr kompetenten klinischen Teams in meinem Krankenhaus ganz gut mit dem Smartphone und der CamAPS FX App umgehen. Deren Fähigkeit, einfach den Diabetes zu kontrollieren und zu behandeln, ist enorm und befreiend.” ' +
        '    </i> ' +
        '</p> ' +
        '<h3> ' +
        '    Völlig diskret ' +
        '</h3> ' +
        '<p> ' +
        'Seit Einsatz der CamAPS FX App hat Roger sein Bolusinsulin reduziert und eine Reduktion der erforderlichen Insulinmenge pro Tag erlebt. Aber Roger hat auch anderen Nutzen gesehen.  ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '“Wenn wir jetzt Essen gehen, ist das einzige, was ich tun muss, mein Smartphone herauszunehmen, die Kohlenhydratmenge einzugeben und los geht es. Ich kann meine Werte sofort sehen und ich kann alles, was zu tun ist, über das Smartphone erledigen. Bevor ich die App hatte, musste ich Nadeln und Insulin mitnehmen und zur Toilette gehen, um einen Fingerstichtest durchzuführen und Injektionen, falls nötig. Um das alles wird sich nun völlig diskret gekümmert.” ' +
        '    </i> ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '“Eine andere Zeit, in der wir von der App profitierten, war der Beginn der COVID-19 Pandemie, als sich die Dinge zum Schlechten wendeten. Ich fürchtete das Schlimmste, aber die App las meine Blutzuckerwerte kontinuierlich aus, was eine große Beruhigung war. Einmal schlief ich ein und meine Frau hatte Sorge, ich könnte in ein diabetisches Koma fallen, aber sie hat in aller Stille während ich schlief meine Blutzuckerwerte auf meinem Phone geprüft, und sie konnte sehen, dass alles in Ordnung war. Die App gibt innere Ruhe, was ein Nutzen ist, der sowohl meine Lebensqualität als auch die meiner Frau erhöht.”' +
        '    </i> ' +
        '</p> ' +
        '<p> ' +
        'Das Gefühl von Sicherheit und Diskretion haben nicht nur Roger und seine Frau erlebt. Studien zeigen, dass die CamAPS FX App Menschen hilft, sich vom Diabetes weniger belastet zu fühlen und ein flexibleres und spontaneres Leben zu führen. ' +
        '</p> ' +
        '<p> ' +
        'Nutzer rechneten der App ebenfalls an, dass sie als Sicherheitsnetz fungiert und einen Level an Reaktivität hat, die über ihre eigenen Fähigkeiten hinausgeht. Jedoch können Nutzer sich über die App hinwegsetzen, wenn sie die Therapie verringern oder verstärken möchten, weil physiologische Notwendigkeiten wie beispielsweise Sport oder Krankheit es erfordern.' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '“Es ist erstaunlich, wie sehr sich die Behandlung seit meiner Diagnose verändert hat, ich habe wirklich den Eindruck, dass ich von der Wüste in die digitale Welt gekommen bin. Für jeden, der den Einsatz der CamAPS FX App in Erwägung zieht, ist meine Empfehlung sehr einfach: Tu es! Es ist wirklich umwälzend, und wenn ich es kann, kann es jeder.” ' +
        '    </i> ' +
        '</p>'

};
