import React from 'react';
import load_ic from '../../assets/images/load-ic.svg';
import check_ic from '../../assets/images/check-ic.svg';
import error_ic from '../../assets/images/red-close.svg';
import parse from "html-react-parser";

export const DiscountCodeModal = (props) => {

    return (
        <div className="popup-msg-wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-12">
                        <div
                            className={`popup-msg ${props.response ?
                                'popup-msg' : props.load ? 'popup-msg' : 'popup-msg-error'}`}>
                            {
                                props.load && (
                                    <>
                                        <h5>{parse(props.orderPageText.pleaseWait)}</h5>
                                        <img src={load_ic} alt="icon" className="load-ic"/>
                                    </>)
                            }
                            {
                                !props.load && (
                                    <>
                                        <h5>{props.response ?
                                            props.orderPageText.successResult :
                                            props.orderPageText.errorResult
                                        } </h5>
                                        <img src={props.response ? check_ic : error_ic} alt="icon"/>
                                        <p>{props.response ?
                                            props.orderPageText.discountApplied :
                                            (props.errorMessage.response &&
                                            props.errorMessage.response.data &&
                                            props.errorMessage.response.data.messageUser ?
                                                props.errorMessage.response.data.messageUser :
                                                props.errorMessage.toString())}  </p>
                                        <button className="btn" onClick={(e) => {
                                            e.preventDefault();
                                            props.closeModal()
                                        }}>{parse(props.orderPageText.okButtonText)}
                                        </button>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default DiscountCodeModal;