import React, {Component} from 'react';
import {Header} from './Header';
import {Footer} from './Footer';
import {notificationsPageText as notificationsPageText_en} from "../libs/en/texts-notifications-page-lib";
import {notificationsPageText as notificationsPageText_de} from "../libs/de/texts-notifications-page-lib";
import {notificationsPageText as notificationsPageText_fr} from "../libs/fr/texts-notifications-page-lib";
import {notificationsPageText as notificationsPageText_nl} from "../libs/nl/texts-notifications-page-lib";
import {notificationsPageText as notificationsPageText_it} from "../libs/it/texts-notifications-page-lib";
import {notificationsPageText as notificationsPageText_cz} from "../libs/cz/texts-notifications-page-lib";
import {notificationsPageText as notificationsPageText_fi} from "../libs/fi/texts-notifications-page-lib";
import {notificationsPageText as notificationsPageText_pl} from "../libs/pl/texts-notifications-page-lib";
import {notificationsPageText as notificationsPageText_se} from "../libs/se/texts-notifications-page-lib";
import {notificationsPageText as notificationsPageText_no} from "../libs/no/texts-notifications-page-lib";
import {notificationsPageText as notificationsPageText_dk} from "../libs/dk/texts-notifications-page-lib";
import {notificationsPageText as notificationsPageText_es} from "../libs/es/texts-notifications-page-lib";
import {headerText as headerText_en} from "../libs/en/texts-header-lib";
import {headerText as headerText_de} from "../libs/de/texts-header-lib";
import {headerText as headerText_fr} from "../libs/fr/texts-header-lib";
import {headerText as headerText_nl} from "../libs/nl/texts-header-lib";
import {headerText as headerText_it} from "../libs/it/texts-header-lib";
import {headerText as headerText_cz} from "../libs/cz/texts-header-lib";
import {headerText as headerText_fi} from "../libs/fi/texts-header-lib";
import {headerText as headerText_pl} from "../libs/pl/texts-header-lib";
import {headerText as headerText_se} from "../libs/se/texts-header-lib";
import {headerText as headerText_no} from "../libs/no/texts-header-lib";
import {headerText as headerText_dk} from "../libs/dk/texts-header-lib";
import {headerText as headerText_es} from "../libs/es/texts-header-lib";
import parse from "html-react-parser";
import {Helmet} from 'react-helmet';

class Accordion extends Component {
    constructor() {
        super();
        this._handleClick = this._handleClick.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        window.addEventListener('resize', this.resize);
        this._handleClick();

    }

    _handleClick() {
        let acc = this._acc.getElementsByClassName('accordian-title');
        for (let i = 0; i < acc.length; i++) {
            let a = acc[i];
            a.onclick = () => a.classList.toggle("active");
        }
    }

    render() {
        return (
            <div
                ref={a => this._acc = a}
                onClick={this._handleClick}>
                {this.props.children}
            </div>
        )
    }
}

export class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block1: true,
            block2: false,
            block3: false,
            language: this.props.language,
            notificationsPageText: notificationsPageText_en,
            headerText: headerText_en,
        };
        if (this.state.language === "DE") {
            this.state.notificationsPageText = notificationsPageText_de;
            this.state.headerText = headerText_de;
        } else if ((this.state.language === "FR") || (this.state.language === "FR-FR")) {
            this.state.notificationsPageText = notificationsPageText_fr;
            this.state.headerText = headerText_fr;
        } else if (this.state.language === "NL") {
            this.state.notificationsPageText = notificationsPageText_nl;
            this.state.headerText = headerText_nl;
        } else if (this.state.language === "IT") {
            this.state.notificationsPageText = notificationsPageText_it;
            this.state.headerText = headerText_it;
        } else if (this.state.language === "CZ") {
            this.state.notificationsPageText = notificationsPageText_cz;
            this.state.headerText = headerText_cz;
        } else if (this.state.language === "FI") {
            this.state.notificationsPageText = notificationsPageText_fi;
            this.state.headerText = headerText_fi;
        } else if (this.state.language === "PL") {
            this.state.notificationsPageText = notificationsPageText_pl;
            this.state.headerText = headerText_pl;
        } else if (this.state.language === "SE") {
            this.state.notificationsPageText = notificationsPageText_se;
            this.state.headerText = headerText_se;
        } else if (this.state.language === "NO") {
            this.state.notificationsPageText = notificationsPageText_no;
            this.state.headerText = headerText_no;
        } else if (this.state.language === "DK") {
            this.state.notificationsPageText = notificationsPageText_dk;
            this.state.headerText = headerText_dk;
        } else if (this.state.language === "ES") {
            this.state.notificationsPageText = notificationsPageText_es;
            this.state.headerText = headerText_es;
        }
    }

    setLanguageVars() {
        if (this.state.language !== this.props.language) {
            if (this.props.language === "EN") {
                this.setState({
                    language: this.props.language,
                    notificationsPageText: notificationsPageText_en,
                    headerText: headerText_en,
                });
            } else if (this.props.language === "DE") {
                this.setState({
                    language: this.props.language,
                    notificationsPageText: notificationsPageText_de,
                    headerText: headerText_de,
                });
            } else if ((this.props.language === "FR") || (this.props.language === "FR-FR")) {
                this.setState({
                    language: this.props.language,
                    notificationsPageText: notificationsPageText_fr,
                    headerText: headerText_fr,
                });
            } else if (this.props.language === "NL") {
                this.setState({
                    language: this.props.language,
                    notificationsPageText: notificationsPageText_nl,
                    headerText: headerText_nl,
                });
            } else if (this.props.language === "IT") {
                this.setState({
                    language: this.props.language,
                    notificationsPageText: notificationsPageText_it,
                    headerText: headerText_it,
                });
            } else if (this.props.language === "CZ") {
                this.setState({
                    language: this.props.language,
                    notificationsPageText: notificationsPageText_cz,
                    headerText: headerText_cz,
                });
            } else if (this.props.language === "FI") {
                this.setState({
                    language: this.props.language,
                    notificationsPageText: notificationsPageText_fi,
                    headerText: headerText_fi,
                });
            } else if (this.props.language === "PL") {
                this.setState({
                    language: this.props.language,
                    notificationsPageText: notificationsPageText_pl,
                    headerText: headerText_pl,
                });
            } else if (this.props.language === "SE") {
                this.setState({
                    language: this.props.language,
                    notificationsPageText: notificationsPageText_se,
                    headerText: headerText_se,
                });
            } else if (this.props.language === "NO") {
                this.setState({
                    language: this.props.language,
                    notificationsPageText: notificationsPageText_no,
                    headerText: headerText_no,
                });
            } else if (this.props.language === "DK") {
                this.setState({
                    language: this.props.language,
                    notificationsPageText: notificationsPageText_dk,
                    headerText: headerText_dk,
                });
            } else if (this.props.language === "ES") {
                this.setState({
                    language: this.props.language,
                    notificationsPageText: notificationsPageText_es,
                    headerText: headerText_es,
                });
            }
        }
    }

    componentDidUpdate() {
        this.setLanguageVars();
    }

    toggle = (index) => () => {
        this.setState({[`block${index}`]: !this.state[`block${index}`]});
    };

    toggleExpand = (expand = false) => () => {
        this.setState({
            block1: expand,
            block2: expand,
            block3: expand,
        });
    };


    render() {
        return (
            <div>
                <Helmet>
                    <title>{parse(this.state.notificationsPageText.title)}</title>
                    <meta name="title" content={parse(this.state.notificationsPageText.titleMeta)}/>
                    <meta name="description"
                          content={parse(this.state.notificationsPageText.descriptionMeta)}/>
                    <meta name="keywords" content={this.state.notificationsPageText.keywords}/>
                    <meta property="og:locale" content={this.state.notificationsPageText.localeMeta}/>
                </Helmet>
                <Header props={this.props}/>
                <section className="inner-banner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-8 col-xl-9">
                                <h1>{parse(this.state.notificationsPageText.heading)}</h1>
                                {/*<p>{parse(this.state.notificationsPageText.subheading)}</p>*/}
                            </div>
                        </div>
                    </div>
                </section>
                <section className="faq-main">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <Accordion>

                                    {this.state.notificationsPageText.header_27 &&
                                        <div className="accordian-col">
                                            <div className="accordian-title">
                                                <h4>{parse(this.state.notificationsPageText.header_27)}</h4></div>
                                            <div className="accordian-content">
                                                <p>{parse(this.state.notificationsPageText.body_27)}</p>
                                            </div>
                                        </div>}
                                    {this.state.notificationsPageText.header_26 &&
                                        <div className="accordian-col">
                                            <div className="accordian-title">
                                                <h4>{parse(this.state.notificationsPageText.header_26)}</h4></div>
                                            <div className="accordian-content">
                                                <p>{parse(this.state.notificationsPageText.body_26)}</p>
                                            </div>
                                        </div>}
                                    {this.state.notificationsPageText.header_25 &&
                                        <div className="accordian-col">
                                            <div className="accordian-title">
                                                <h4>{parse(this.state.notificationsPageText.header_25)}</h4></div>
                                            <div className="accordian-content">
                                                <p>{parse(this.state.notificationsPageText.body_25)}</p>
                                            </div>
                                        </div>}
                                    {this.state.notificationsPageText.header_24 &&
                                        <div className="accordian-col">
                                            <div className="accordian-title">
                                                <h4>{parse(this.state.notificationsPageText.header_24)}</h4></div>
                                            <div className="accordian-content">
                                                <p>{parse(this.state.notificationsPageText.body_24)}</p>
                                            </div>
                                        </div>}
                                    {this.state.notificationsPageText.header_23 &&
                                        <div className="accordian-col">
                                            <div className="accordian-title">
                                                <h4>{parse(this.state.notificationsPageText.header_23)}</h4></div>
                                            <div className="accordian-content">
                                                <p>{parse(this.state.notificationsPageText.body_23)}</p>
                                            </div>
                                        </div>}
                                    {this.state.notificationsPageText.header_22 &&
                                        <div className="accordian-col">
                                            <div className="accordian-title">
                                                <h4>{parse(this.state.notificationsPageText.header_22)}</h4></div>
                                            <div className="accordian-content">
                                                <p>{parse(this.state.notificationsPageText.body_22)}</p>
                                            </div>
                                        </div>}
                                    {this.state.notificationsPageText.header_21 &&
                                        <div className="accordian-col">
                                            <div className="accordian-title">
                                                <h4>{parse(this.state.notificationsPageText.header_21)}</h4></div>
                                            <div className="accordian-content">
                                                <p>{parse(this.state.notificationsPageText.body_21)}</p>
                                            </div>
                                        </div>}
                                    {this.state.notificationsPageText.header_20 &&
                                        <div className="accordian-col">
                                            <div className="accordian-title">
                                                <h4>{parse(this.state.notificationsPageText.header_20)}</h4></div>
                                            <div className="accordian-content">
                                                <p>{parse(this.state.notificationsPageText.body_20)}</p>
                                            </div>
                                        </div>}
                                    {this.state.notificationsPageText.header_19 &&
                                        <div className="accordian-col">
                                            <div className="accordian-title">
                                                <h4>{parse(this.state.notificationsPageText.header_19)}</h4></div>
                                            <div className="accordian-content">
                                                <p>{parse(this.state.notificationsPageText.body_19)}</p>
                                            </div>
                                        </div>}
                                    {this.state.notificationsPageText.header_18 &&
                                        <div className="accordian-col">
                                            <div className="accordian-title">
                                                <h4>{parse(this.state.notificationsPageText.header_18)}</h4></div>
                                            <div className="accordian-content">
                                                <p>{parse(this.state.notificationsPageText.body_18)}</p>
                                            </div>
                                        </div>}
                                    {this.state.notificationsPageText.header_17 &&
                                        <div className="accordian-col">
                                            <div className="accordian-title">
                                                <h4>{parse(this.state.notificationsPageText.header_17)}</h4></div>
                                            <div className="accordian-content">
                                                <p>{parse(this.state.notificationsPageText.body_17)}</p>
                                            </div>
                                        </div>}
                                    {this.state.notificationsPageText.header_16 &&
                                        <div className="accordian-col">
                                            <div className="accordian-title">
                                                <h4>{parse(this.state.notificationsPageText.header_16)}</h4></div>
                                            <div className="accordian-content">
                                                <p>{parse(this.state.notificationsPageText.body_16)}</p>
                                            </div>
                                        </div>}
                                    {this.state.notificationsPageText.header_15 &&
                                        <div className="accordian-col">
                                            <div className="accordian-title">
                                                <h4>{parse(this.state.notificationsPageText.header_15)}</h4></div>
                                            <div className="accordian-content">
                                                <p>{parse(this.state.notificationsPageText.body_15)}</p>
                                            </div>
                                        </div>}
                                    {/*{this.state.notificationsPageText.header_14 &&*/}
                                    {/*<div className="accordian-col">*/}
                                    {/*    <div className="accordian-title">*/}
                                    {/*        <h4>{parse(this.state.notificationsPageText.header_14)}</h4></div>*/}
                                    {/*    <div className="accordian-content">*/}
                                    {/*        <p>{parse(this.state.notificationsPageText.body_14)}</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>}*/}
                                    {/*{this.state.notificationsPageText.header_13 &&*/}
                                    {/*<div className="accordian-col">*/}
                                    {/*    <div className="accordian-title">*/}
                                    {/*        <h4>{parse(this.state.notificationsPageText.header_13)}</h4></div>*/}
                                    {/*    <div className="accordian-content">*/}
                                    {/*        <p>*/}
                                    {/*            {parse(this.state.notificationsPageText.body_13)}*/}
                                    {/*            &nbsp;*/}
                                    {/*            <a href={parse(this.state.notificationsPageText.body_13_link)}*/}
                                    {/*               title={parse(this.state.notificationsPageText.body_13_link_text)}*/}
                                    {/*               target="_blank"*/}
                                    {/*               rel="noopener noreferrer">*/}
                                    {/*                {parse(this.state.notificationsPageText.body_13_link_text)}</a>*/}
                                    {/*            &nbsp;*/}
                                    {/*            {parse(this.state.notificationsPageText.body_13_end)}*/}
                                    {/*            &nbsp;*/}
                                    {/*        </p>*/}
                                    {/*    </div>*/}
                                    {/*</div>}*/}
                                    {/*{this.state.notificationsPageText.header_12 &&*/}
                                    {/*<div className="accordian-col">*/}
                                    {/*    <div className="accordian-title">*/}
                                    {/*        <h4>{parse(this.state.notificationsPageText.header_12)}</h4></div>*/}
                                    {/*    <div className="accordian-content">*/}
                                    {/*        <p>{parse(this.state.notificationsPageText.body_12)}</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>}*/}
                                    {/*{this.state.notificationsPageText.header_11 &&*/}
                                    {/*<div className="accordian-col">*/}
                                    {/*    <div className="accordian-title">*/}
                                    {/*        <h4>{parse(this.state.notificationsPageText.header_11)}</h4></div>*/}
                                    {/*    <div className="accordian-content">*/}
                                    {/*        <p>{parse(this.state.notificationsPageText.body_11)}</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>}*/}
                                    {/*{this.state.notificationsPageText.header_10 &&*/}
                                    {/*<div className="accordian-col">*/}
                                    {/*    <div className="accordian-title">*/}
                                    {/*        <h4>{parse(this.state.notificationsPageText.header_10)}</h4></div>*/}
                                    {/*    <div className="accordian-content">*/}
                                    {/*        <p>{parse(this.state.notificationsPageText.body_10)}</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>}*/}
                                    {/*{this.state.notificationsPageText.header_9 &&*/}
                                    {/*<div className="accordian-col">*/}
                                    {/*    <div className="accordian-title">*/}
                                    {/*        <h4>{parse(this.state.notificationsPageText.header_9)}</h4></div>*/}
                                    {/*    <div className="accordian-content">*/}
                                    {/*        <p>{parse(this.state.notificationsPageText.body_9)}</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>}*/}
                                    {/*{this.state.notificationsPageText.header_8 &&*/}
                                    {/*<div className="accordian-col">*/}
                                    {/*    <div className="accordian-title">*/}
                                    {/*        <h4>{parse(this.state.notificationsPageText.header_8)}</h4></div>*/}
                                    {/*    <div className="accordian-content">*/}
                                    {/*        <p>{parse(this.state.notificationsPageText.body_8)}</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>}*/}
                                    {/*{this.state.notificationsPageText.header_7 &&*/}
                                    {/*<div className="accordian-col">*/}
                                    {/*    <div className="accordian-title">*/}
                                    {/*        <h4>{parse(this.state.notificationsPageText.header_7)}</h4></div>*/}
                                    {/*    <div className="accordian-content">*/}
                                    {/*        <p>{parse(this.state.notificationsPageText.body_7)}</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>}*/}
                                    {/*{this.state.notificationsPageText.header_6 &&*/}
                                    {/*<div className="accordian-col">*/}
                                    {/*    <div className="accordian-title">*/}
                                    {/*        <h4>{parse(this.state.notificationsPageText.header_6)}</h4></div>*/}
                                    {/*    <div className="accordian-content">*/}
                                    {/*        <p>{parse(this.state.notificationsPageText.body_6)}</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>}*/}
                                    {/*{this.state.notificationsPageText.header_5 &&*/}
                                    {/*<div className="accordian-col">*/}
                                    {/*    <div className="accordian-title">*/}
                                    {/*        <h4>{parse(this.state.notificationsPageText.header_5)}</h4></div>*/}
                                    {/*    <div className="accordian-content">*/}
                                    {/*        <p>{parse(this.state.notificationsPageText.body_5)}</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>}*/}
                                    {/*{this.state.notificationsPageText.header_4 &&*/}
                                    {/*<div className="accordian-col">*/}
                                    {/*    <div className="accordian-title">*/}
                                    {/*        <h4>{parse(this.state.notificationsPageText.header_4)}</h4></div>*/}
                                    {/*    <div className="accordian-content">*/}
                                    {/*        <p>{parse(this.state.notificationsPageText.body_4)}</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>}*/}
                                    {/*{this.state.notificationsPageText.header_3 &&*/}
                                    {/*<div className="accordian-col">*/}
                                    {/*    <div className="accordian-title">*/}
                                    {/*        <h4>{parse(this.state.notificationsPageText.header_3)}</h4></div>*/}
                                    {/*    <div className="accordian-content">*/}
                                    {/*        <p>{parse(this.state.notificationsPageText.body_3)}</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>}*/}
                                    {/*{this.state.notificationsPageText.header_2 &&*/}
                                    {/*<div className="accordian-col">*/}
                                    {/*    <div className="accordian-title">*/}
                                    {/*        <h4>{parse(this.state.notificationsPageText.header_2)}</h4></div>*/}
                                    {/*    <div className="accordian-content">*/}
                                    {/*        <p>{parse(this.state.notificationsPageText.body_2)}</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>}*/}
                                    {/*{this.state.notificationsPageText.header_1 &&*/}
                                    {/*<div className="accordian-col">*/}
                                    {/*    <div className="accordian-title">*/}
                                    {/*        <h4>{parse(this.state.notificationsPageText.header_1)}</h4></div>*/}
                                    {/*    <div className="accordian-content">*/}
                                    {/*        <p>{parse(this.state.notificationsPageText.body_1)}</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>}*/}
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer props={this.props}/>
            </div>
        );
    }
}

