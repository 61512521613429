/**
 * Texts for FAQs page
 */
export const faqPageText = {
    title: 'Časté dotazy – CamAPS FX',
    titleMeta: 'Chcete se zeptat na něco k aplikaci CamAPS FX?',
    descriptionMeta: 'Zde najdete odpovědi na některé z nejčastějších otázek týkajících se kompatibilních zařízení, schválení kontrolními úřady, dostupnosti nebo klinického výzkumu.',
    keywords:
        'časté otázky týkající se camaps, časté otázky týkající se camaps fx, ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'cs_cz',

    haveQuestion: 'Chcete se zeptat na něco k aplikaci CamAPS FX?',
    hereAnswers: 'Zde najdete odpovědi na některé nejčastější dotazy.',
    whatIsCamAPS: 'Co je CamAPS FX?',
    whatIsCamAPSAnswer: 'CamAPS FX je propracovaná, snadno ovladatelná aplikace pro Android k řízení hladiny glukózy u lidí s diabetem 1. typu od jednoho roku věku pomocí pokročilé adaptivní hybridní uzavřené smyčky.',
    isApproved: 'Je aplikace CamAPS FX schválená pro používání?',
    isApprovedAnswer: 'Aplikace prošla rozsáhlými klinickými zkouškami a byla schválena k používání např. v EU nebo ve Spojeném království.',
    isAvailable: 'Je aplikace CamAPS FX dostupná v České republice?',
    isAvailableAnswer1: 'Aplikace CamAPS FX je dostupná ve vybraných zdravotnických zařízeních, která léčí diabetes, a také pro každého, kdo si ji zaplatí a projde online školením. Zeptejte se svého lékaře nebo přejděte na',
    isAvailableAnswer2: 'Objednávky',
    isAvailableAnswer3: 'a aplikaci si objednejte.',
    cost: 'Kolik aplikace CamAPS FX stojí?',
    costAnswer1: 'Informace o ceně najdete v sekci',
    costAnswer2: 'našeho webu.',
    compatibleCgm: 'Které systémy CGM jsou s aplikací CamAPS FX kompatibilní?',
    compatibleCgmAnswer: 'CamAPS FX je kompatibilní se systémy',
    compatibleCgmAnswer1: 'a',
    compatibleCgmAnswer2: '. Pro zobrazení kompatibilních smartphonů a operačních systémů klikněte na odkazy.',
    whatPumps: 'Které inzulinové pumpy jsou s aplikací CamAPS FX kompatibilní?',
    whatPumpsAnswer1: 'Aplikace CamAPS FX je kompatibilní s inzulinovými pumpami mylife YpsoPump, Dana Diabecare RS a DANA-i.',
    whatPumpsAnswer2: '',
    whatPumpsAnswer3: '',
    whatPumpsAnswerHtml: 'https://advancedtherapeutics.org.uk/shop/dana-diabecare-insulin-pump/dana-rs-insulin-pump-2018',
    whatPhone: 'Které chytré telefony jsou s aplikací CamAPS FX kompatibilní?',
    whatPhoneAnswer: 'CamAPS FX běží na telefonu se systémem Android a je kompatibilní s modely smartphonů se systémem Android podporovanými výrobcem CGM.',
    whatPhoneAnswer1: '',
    whatPhoneAnswer2: 'Kliknutím',
    whatPhoneAnswer3: 'na tento odkaz zobrazíte seznam zařízení a operačních systémů kompatibilních se',
    whatPhoneAnswerDexcom: 'senzorem Dexcom G6 a aplikací Dexcom G6.',
    whatPhoneAnswerLibre3: 'senzorem FreeStyle Libre 3 a aplikací FreeStyle Libre 3.',
    whatPhoneAnswerDexcomHtml:'https://www.dexcom.com/compatibility',
    whatPhoneAnswerLibreHtml:'https://www.diabetescare.abbott/support/manuals.html',

    whereUpload: 'Kam lze nahrávat data z aplikace CamAPS FX?',
    whereUploadAnswer: 'Data z aplikace CamAPS FX lze v současné době nahrávat do',
    whatLanguage: 'Jaké jazyky nabízí aplikace CamAPS FX?',
    whatLanguageAnswer: 'Aplikace CamAPS FX je dostupná v angličtině, češtině, dánštině, němčině, španělštině, francouzštině, italštině, holandštině, polštině, finštině a švédštině.',
    whereResults: 'Kde najdu výsledky klinických zkoušek?',
    whereResultsAnswer1: 'Publikace o používání kontrolního cambridgeského algoritmu si můžete stáhnout',
    whereResultsAnswer2: 'zde.',
    whereResultsAnswer3: 'Hlavní publikace byly zveřejněny v',
    whereResultsAnswer4: 'a',
};
