import React from 'react';
import * as Yup from "yup";
import {Formik, ErrorMessage} from "formik";
import {
    errorMessage,
} from "../inputTypes";
import parse from "html-react-parser";

const StepTwoForm = (props) => {
    const stepTwoData = props.stepTwoData;
    return (
        <Formik
            initialValues={{
                question1: false,
                question2: false,
                question3: false,
                // question1: props.stepTwoData.question1,
                // question2: props.stepTwoData.question2,
                // question3: props.stepTwoData.question3,
            }}
            onSubmit={props.handleSubmit}
            isInitialValid={false}
            // isInitialValid={props.stepTwoData.isValid}
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={stepOneValidationSchema(props)}
        >{({handleSubmit, setFieldTouched, setFieldValue, isValid}) => (

            <section className="step-chart-content">
                <div className="container">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <div className="step-content-row">
                                    <div className="step-content-left">
                                        <p>{parse(props.orderPageText.s2HaveDanaPump)}</p>
                                        <div className="div-content">
                                            <ErrorMessage name="question1" component={errorMessage}/>
                                        </div>
                                    </div>
                                    <div className="step-content-right">
                                        <div className="custom-radio">
                                            <input type="radio" name="question1" id="stage_btn1" value="true"
                                                // checked={stepTwoData.question1}
                                                   onChange={(e) => {
                                                       const selectedValue = e.target.value === "true";
                                                       stepTwoData.question1 = selectedValue;
                                                       stepTwoData.isValid = true;
                                                       props.self.setState({stepTwoData: stepTwoData});
                                                       setTimeout(async () => {
                                                           await setFieldValue("question1", selectedValue);
                                                           await setFieldTouched("question1", true)
                                                       })
                                                   }}/>
                                            <label htmlFor="stage_btn1">
                                                {parse(props.orderPageText.s2Yes)}
                                            </label>
                                        </div>
                                        <div className="custom-radio">
                                            <input type="radio" name="question1" id="stage_btn2" value="false"
                                                   onChange={(e) => {
                                                       const selectedValue = e.target.value === "true";
                                                       stepTwoData.question1 = selectedValue;
                                                       props.self.setState({stepTwoData: stepTwoData});
                                                       setTimeout(async () => {
                                                           await setFieldValue("question1", selectedValue);
                                                           await setFieldTouched("question1", true);
                                                       })
                                                   }}/>
                                            <label htmlFor="stage_btn2" className="red-border">
                                                {parse(props.orderPageText.s2No)}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="step-content-row">
                                    <div className="step-content-left">
                                        <p>{parse(props.orderPageText.s2HaveCgm)}</p>
                                        <div className="div-content">
                                            <ErrorMessage name="question2" component={errorMessage}/>
                                        </div>
                                    </div>
                                    <div className="step-content-right">
                                        <div className="custom-radio">
                                            <input type="radio" name="question2" id="stage_btn3" value="true"
                                                // checked={stepTwoData.question2}
                                                   onChange={(e) => {
                                                       const selectedValue = e.target.value === "true";
                                                       stepTwoData.question2 = selectedValue;
                                                       stepTwoData.isValid = true;
                                                       props.self.setState({stepTwoData: stepTwoData});
                                                       setTimeout(async () => {
                                                           await setFieldValue("question2", selectedValue);
                                                           await setFieldTouched("question2", true)
                                                       })
                                                   }}/>
                                            <label htmlFor="stage_btn3">
                                                {parse(props.orderPageText.s2Yes)}
                                            </label>
                                        </div>
                                        <div className="custom-radio">
                                            <input type="radio" name="question2" id="stage_btn4" value="false"
                                                   onChange={(e) => {
                                                       const selectedValue = e.target.value === "true";
                                                       stepTwoData.question2 = selectedValue;
                                                       props.self.setState({stepTwoData: stepTwoData});
                                                       setTimeout(async () => {
                                                           await setFieldValue("question2", selectedValue);
                                                           await setFieldTouched("question2", true)
                                                       })
                                                   }}/>
                                            <label htmlFor="stage_btn4" className="red-border">
                                                {parse(props.orderPageText.s2No)}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="step-content-row">
                                    <div className="step-content-left">
                                        <p>{parse(props.orderPageText.s2Phone1)}{' '}<a
                                            href={parse(props.orderPageText.s2PhoneHtml)}
                                            title={parse(props.orderPageText.s2Phone2)} target="_blank"
                                            rel="noopener noreferrer">
                                            {parse(props.orderPageText.s2Phone2)}</a>
                                            {parse(props.orderPageText.s2Phone3)}
                                        </p>
                                        <div className="div-content">
                                            <ErrorMessage name="question3" component={errorMessage}/>
                                        </div>
                                    </div>
                                    <div className="step-content-right">
                                        <div className="custom-radio">
                                            <input type="radio" name="question3" id="stage_btn5" value="true"
                                                // checked={stepTwoData.question3}
                                                   onChange={(e) => {
                                                       const selectedValue = e.target.value === "true";
                                                       stepTwoData.question3 = selectedValue;
                                                       stepTwoData.isValid = true;
                                                       props.self.setState({stepTwoData: stepTwoData});
                                                       setTimeout(async () => {
                                                           await setFieldValue("question3", selectedValue);
                                                           await setFieldTouched("question3", true)
                                                       })
                                                   }}/>
                                            <label htmlFor="stage_btn5">
                                                {parse(props.orderPageText.s2Yes)}
                                            </label>
                                        </div>
                                        <div className="custom-radio">
                                            <input type="radio" name="question3" id="stage_btn6" value="false"
                                                   onChange={(e) => {
                                                       const selectedValue = e.target.value === "true";
                                                       stepTwoData.question3 = selectedValue;
                                                       props.self.setState({stepTwoData: stepTwoData});
                                                       setTimeout(async () => {
                                                           await setFieldValue("question3", selectedValue);
                                                           await setFieldTouched("question3", true);
                                                       })
                                                   }}/>
                                            <label htmlFor="stage_btn6" className="red-border">
                                                {parse(props.orderPageText.s2No)}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <div className="next-step">
                                    <button className="btn arrow-btn prev-btn"
                                            onClick={props.handlePrevious}>
                                        {parse(props.orderPageText.previous)}
                                    </button>
                                    <button className={`btn arrow-btn ${!isValid ? 'disable-btn' : ''}`}
                                            type="button"
                                            onClick={props.handleNext} disabled={!isValid}>
                                        {parse(props.orderPageText.next)}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        )}
        </Formik>
    );
};

const stepOneValidationSchema = (props) => Yup.object().shape({
        question1: Yup.boolean()
            .required()
            .oneOf([true],
                props.orderPageText.s2NoPump),
        question2: Yup.boolean()
            .required()
            .oneOf([true],
                props.orderPageText.s2NoCgm),
        question3: Yup.boolean
        ()
            .required()
            .oneOf([true],
                props.orderPageText.s2NoPhone),
    })
;

export default StepTwoForm;