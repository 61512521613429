/**
 * Common texts - what is CamAPS FX
 */
export const whatIsCamAPSFX = {

    linkFindOutMore: 'Dowiedz się więcej',
    body:
        '<h2>Czym jest aplikacja CamAPS FX?</h2> <p> CamAPS FX to aplikacja na urządzenia z systemem operacyjnym Android, która służy do kontrolowania stężenia glukozy we krwi u osób chorujących na cukrzycę typu 1 za pomocą zaawansowanego, adaptacyjnego rozwiązania wykorzystującego hybrydowy system pętli zamkniętej. </p>'
};

/**
 * Common texts - what is CamAPS FX
 */
export const userReviewsIntro = {
    linkMoreReviews: 'Więcej opinii',
    readMore: 'Dowiedz się więcej',
    header:
        'Opinie użytkowników aplikacji CamAPS FX',
    text:
        'Przeczytaj historie o osobach korzystających z aplikacji CamAPS FX'
};