import React, {Component} from 'react';
import {Header} from './Header';
import {Footer} from './Footer';
import {historyPageText as historyPageText_en} from "../libs/en/texts-history-page-lib";
import {historyPageText as historyPageText_de} from "../libs/de/texts-history-page-lib";
import {historyPageText as historyPageText_fr} from "../libs/fr/texts-history-page-lib";
import {historyPageText as historyPageText_nl} from "../libs/nl/texts-history-page-lib";
import {historyPageText as historyPageText_it} from "../libs/it/texts-history-page-lib";
import {historyPageText as historyPageText_cz} from "../libs/cz/texts-history-page-lib";
import {historyPageText as historyPageText_fi} from "../libs/fi/texts-history-page-lib";
import {historyPageText as historyPageText_pl} from "../libs/pl/texts-history-page-lib";
import {historyPageText as historyPageText_se} from "../libs/se/texts-history-page-lib";
import {historyPageText as historyPageText_no} from "../libs/no/texts-history-page-lib";
import {historyPageText as historyPageText_dk} from "../libs/dk/texts-history-page-lib";
import {historyPageText as historyPageText_es} from "../libs/es/texts-history-page-lib";

import parse from "html-react-parser";
import {Helmet} from 'react-helmet';

export class History extends Component {

    constructor(props) {
        super(props);
        this.state = {
            language: this.props.language,
            historyPageText: historyPageText_en,
        };
        if (this.state.language === "DE") {
            this.state.historyPageText = historyPageText_de;
        } else if ((this.state.language === "FR") || (this.state.language === "FR-FR")){
            this.state.historyPageText = historyPageText_fr;
        } else if (this.state.language === "NL") {
            this.state.historyPageText = historyPageText_nl;
        } else if (this.state.language === "IT") {
            this.state.historyPageText = historyPageText_it;
        } else if (this.state.language === "CZ") {
            this.state.historyPageText = historyPageText_cz;
        } else if (this.state.language === "FI") {
            this.state.historyPageText = historyPageText_fi;
        } else if (this.state.language === "PL") {
            this.state.historyPageText = historyPageText_pl;
        } else if (this.state.language === "SE") {
            this.state.historyPageText = historyPageText_se;
        } else if (this.state.language === "NO") {
            this.state.historyPageText = historyPageText_no;
        } else if (this.state.language === "DK") {
            this.state.historyPageText = historyPageText_dk;
        } else if (this.state.language === "ES") {
            this.state.historyPageText = historyPageText_es;
        }
    }

    setLanguageVars() {
        if (this.state.language !== this.props.language) {
            if (this.props.language === "EN") {
                this.setState({
                    language: this.props.language,
                    historyPageText: historyPageText_en,
                });
            }
            if (this.props.language === "DE") {
                this.setState({
                    language: this.props.language,
                    historyPageText: historyPageText_de,
                });
            }
            if ((this.props.language === "FR") || (this.props.language === "FR-FR")){
                this.setState({
                    language: this.props.language,
                    historyPageText: historyPageText_fr,
                });
            }
            if (this.props.language === "NL") {
                this.setState({
                    language: this.props.language,
                    historyPageText: historyPageText_nl,
                });
            }
            if (this.props.language === "IT") {
                this.setState({
                    language: this.props.language,
                    historyPageText: historyPageText_it,
                });
            }
            if (this.props.language === "CZ") {
                this.setState({
                    language: this.props.language,
                    historyPageText: historyPageText_cz,
                });
            }
            if (this.props.language === "FI") {
                this.setState({
                    language: this.props.language,
                    historyPageText: historyPageText_fi,
                });
            }
            if (this.props.language === "PL") {
                this.setState({
                    language: this.props.language,
                    historyPageText: historyPageText_pl,
                });
            }
            if (this.props.language === "SE") {
                this.setState({
                    language: this.props.language,
                    historyPageText: historyPageText_se,
                });
            }
            if (this.props.language === "NO") {
                this.setState({
                    language: this.props.language,
                    historyPageText: historyPageText_no,
                });
            }
            if (this.props.language === "DK") {
                this.setState({
                    language: this.props.language,
                    historyPageText: historyPageText_dk,
                });
            }
            if (this.props.language === "ES") {
                this.setState({
                    language: this.props.language,
                    historyPageText: historyPageText_es,
                });
            }
        }
    }

    componentDidUpdate() {
        this.setLanguageVars();
    }

    checkVisible = (elm) => {
        var rect = elm.getBoundingClientRect();
        var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
        return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        let self = this;
        document.querySelectorAll(".pattern-div").forEach((item, index) => {
            if (self.checkVisible(document.querySelectorAll(".pattern-div")[index])) {
                setTimeout(function () {
                    document.querySelectorAll(".pattern-div")[index].classList.add("show-section");
                }, 500)
            }
        });
        document.querySelectorAll(".history-col").forEach((item, index) => {
            if (self.checkVisible(document.querySelectorAll(".history-col")[index])) {
                setTimeout(function () {
                    document.querySelectorAll(".history-col")[index].classList.add("show-text");
                }, 500)
            }
        });
        document.addEventListener("scroll", function (e) {
            document.querySelectorAll(".pattern-div").forEach((item, index) => {
                if (self.checkVisible(document.querySelectorAll(".pattern-div")[index])) {
                    setTimeout(function () {
                        document.querySelectorAll(".pattern-div")[index].classList.add("show-section");
                    }, 500)
                }
            });
            document.querySelectorAll(".history-col").forEach((item, index) => {
                if (self.checkVisible(document.querySelectorAll(".history-col")[index])) {
                    setTimeout(function () {
                        document.querySelectorAll(".history-col")[index].classList.add("show-text");
                    }, 500)
                }
            })
        })
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>{parse(this.state.historyPageText.title)}</title>
                    <meta name="title" content={parse(this.state.historyPageText.titleMeta)}/>
                    <meta name="description"
                          content={parse(this.state.historyPageText.descriptionMeta)}/>
                    <meta property="og:locale" content={this.state.historyPageText.localeMeta}/>
                    <meta name="keywords" content={this.state.historyPageText.keywords}/>
                </Helmet>

                <Header props={this.props}/>
                <section className="history-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <div className="history-head">
                                    <h1>{parse(this.state.historyPageText.journeyHeading)}</h1>
                                    <p>{parse(this.state.historyPageText.timelineHeading)}</p>
                                </div>
                                <div className="history-content">

                                    <div className="history-col">
                                        <h2>{parse(this.state.historyPageText.year2006_2010dates)}</h2>
                                        <h4>{parse(this.state.historyPageText.year2006_2010heading)}</h4>
                                        <p>{parse(this.state.historyPageText.year2006_2010text)}</p>
                                        <span className="pattern-div">
                                            <span className="circle-div"></span>
                                            <span className="vertical-line-div"></span>
                                            <span className="horizontal-line-div"></span>
                                        </span>
                                    </div>

                                    <div className="history-col">
                                        <h2>{parse(this.state.historyPageText.year2011_2014dates)}</h2>
                                        <h4>{parse(this.state.historyPageText.year2011_2014heading)}</h4>
                                        <p>{parse(this.state.historyPageText.year2011_2014text)}</p>
                                        <span className="pattern-div">
                                            <span className="horizontal-line-div"></span>
                                            <span className="vertical-line-div"></span>
                                            <span className="horizontal-line-div"></span>
                                        </span>
                                    </div>
                                    <div className="history-col">
                                        <h2>{parse(this.state.historyPageText.year2015_2016dates)}</h2>
                                        <h4>{parse(this.state.historyPageText.year2015_2016heading)}</h4>
                                        <p>{parse(this.state.historyPageText.year2015_2016text)}</p>
                                        <span className="pattern-div">
                                            <span className="horizontal-line-div"></span>
                                            <span className="vertical-line-div"></span>
                                            <span className="horizontal-line-div"></span>
                                        </span>
                                    </div>
                                    <div className="history-col">
                                        <h2>{parse(this.state.historyPageText.year2017_2018dates)}</h2>
                                        <h4>{parse(this.state.historyPageText.year2017_2018heading)}</h4>
                                        <p>{parse(this.state.historyPageText.year2017_2018text)}</p>
                                        <span className="pattern-div">
                                            <span className="horizontal-line-div"></span>
                                            <span className="vertical-line-div"></span>
                                            <span className="horizontal-line-div"></span>
                                        </span>
                                    </div>
                                    <div className="history-col">
                                        <h2>{parse(this.state.historyPageText.year2019_2019dates)}</h2>
                                        <h4>{parse(this.state.historyPageText.year2019_2019heading)} </h4>
                                        <p>{parse(this.state.historyPageText.year2019_2019text)}</p>
                                        <span className="pattern-div">
                                            <span className="horizontal-line-div"></span>
                                            <span className="vertical-line-div"></span>
                                            <span className="circle-div"></span>
                                        </span>
                                    </div>
                                    <div className="history-col">
                                        <h2>{parse(this.state.historyPageText.year2020_2020dates)}</h2>
                                        <h4>{parse(this.state.historyPageText.year2020_2020heading)}</h4>
                                        <p>{parse(this.state.historyPageText.year2020_2020text)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer props={this.props}/>
            </div>
        );
    }
}

