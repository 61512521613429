/**
 * Texts for footer
 */
export const footerText = {
    txtFAQs: 'FAQ',
    txtSupport: 'Assistenza',
    txtHistory: 'Storia',
    txtTrainingHtml: 'https://www.camdiabtraining.com/account-sign-in.html',
    txtTraining: 'Addestramento online',
    txtClinic: 'Centro clinico CamAPS FX',
    terms: 'Termini e condizioni',
    privacy: 'Informativa sulla Privacy',
    txtFooterBottom: 'CamAPS è un marchio registrato di CamDiab Ltd. Altri marchi di fabbrica appartengono ai rispettivi proprietari. È evitato qualsiasi utilizzo di nomi, marchi di fabbrica o immagini commerciali di CamDiab in questo sito web senza previa autorizzazione scritta di CamDiab, tranne che per identificare il prodotto o i servizi della società. Le immagini del prodotto hanno esclusivamente scopo illustrativo.',
    rightsReserved: 'CamDiab Ltd. Tutti i diritti riservati.',

    statusPageFooterText: 'Pagina di stato',
    statusPageFooterLink: 'https://camdiabltd.statuspage.io/',
};
