/**
 * Texts for History page
 */
export const historyPageText = {
    title: 'CamDiab-rejsen',
    titleMeta: 'Klinisk forskning på vejen til CamAPS FX',
    descriptionMeta: 'En tidslinje, som viser, hvordan teamet bag CamAPS FX kom frem til at hjælpe mennesker med diabetes med vores Cambridge-algoritme.',
    localeMeta: 'da_dk',

    keywords:
        'camps historie, camps fx historie,',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    journeyHeading: 'Vores CamDiab-rejse',
    timelineHeading: 'En tidslinje, som viser, hvordan teamet bag CamAPS FX kom frem til at hjælpe mennesker med diabetes med vores Cambridge-algoritme.',
    year2006_2010dates: '2006–2010',
    year2006_2010heading: 'Tidlige forsøg på klinik',
    year2006_2010text: 'Start på Artificial Pancreas-projektet på Cambridge med forsøg hos børn med type 1-diabetes. Trådte ind i JDRF Artificial Pancreas Consortium. Cambridge-algoritme stresstestet efter motion og alkoholforbrug hos voksne med type 1-diabetes. Cambridge-algoritme evalueret hos gravide kvinder med type 1-diabetes.',
    year2011_2014dates: '2011–2014',
    year2011_2014heading: 'Forsøg med brugbarhed til natbrug alene og free-living',
    year2011_2014text: 'Natlig brug af Cambridge-algoritmen evalueret af børn, voksne og gravide kvinder med type 1-diabetes. Cambridge-algoritme brugt dag og nat over syv dage af voksne med type 1-diabetes.',
    year2015_2016dates: '2015–2016',
    year2015_2016heading: 'Korte free-living-forsøg dag og nat',
    year2015_2016text: 'Cambridge-algoritme brugt af velkontrollerede voksne med type 1-diabetes over fire uger. Cambridge-brugt af unge med type 1-diabetes over tre uger.',
    year2017_2018dates: '2017–2018',
    year2017_2018heading: 'Længere dag og nat- og unge-børn-forsøg',
    year2017_2018text: 'Cambridge-algoritme brugt over tre måneder af børn og unge med type 1-diabetes. Cambridge-algoritme brugt over 2 år af børn og unge fra diabetesdebut. Cambridge-algoritme brugt af små børn fra 1 til 7 år med type 1-diabetes.',
    year2019_2019dates: '2019',
    year2019_2019heading: 'Overgang til CamAPS FX ',
    year2019_2019text: 'CamAPS FX-app brugt af ældre voksne med type 1-diabetes. CamAPS FX-app brugt under hele graviditeten af gravide kvinder med type 1-diabetes. CamAPS FX-app brugt hos børn fra 1 til 7 år med type 1-diabetes.',
    year2020_2020dates: '2020 og fremefter',
    year2020_2020heading: 'Myndighedsgodkendelse, kommercialisering, klinisk afprøvning',
    year2020_2020text: 'CamAPS FX-app CE-mærket til brug i EU og Storbritannien. Forskningen fortsætter for at vurdere fordele og understøtte tilskud.',
};
