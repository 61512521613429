/**
 * Texts for History page
 */
export const historyPageText = {
    title: 'Historia firmy CamDiab',
    titleMeta: 'Badanie kliniczne prowadzące do opracowania aplikacji CamAPS FX',
    descriptionMeta: 'Oś czasu objaśniająca drogę zespołu odpowiadającego za aplikację CamAPS FX do zapewnienia pomocy osobom chorującym na cukrzycę przy użyciu naszego algorytmu Cambridge.',
    localeMeta: 'pl_pl',

    keywords:
        'historia camaps, historia camaps fx, ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    journeyHeading: 'Historia firmy CamDiab',
    timelineHeading: 'Oś czasu objaśniająca drogę zespołu odpowiadającego za aplikację CamAPS FX do zapewnienia pomocy osobom chorującym na cukrzycę przy użyciu naszego algorytmu Cambridge.',
    year2006_2010dates: '2006–2010',
    year2006_2010heading: 'Wczesne badania w klinice',
    year2006_2010text: 'Rozpoczęcie projektu dotyczącego sztucznej trzustki na uniwersytecie University of Cambridge i przeprowadzanie badań u dzieci z cukrzycą typu 1. Dołączenie do organizacji JDRF Artificial Pancreas Consortium. Algorytm Cambridge został przetestowany w skrajnych warunkach u osób dorosłych z cukrzycą typu 1 po wysiłku fizycznym oraz po spożyciu alkoholu. Stosowanie algorytmu Cambridge zostało ocenione u kobiet w ciąży chorujących na cukrzycę typu 1.',
    year2011_2014dates: '2011–2014',
    year2011_2014heading: 'Badania wykonalności przeprowadzane tylko w nocy i u osób samodzielnych',
    year2011_2014text: 'Użytkowanie algorytmu Cambridge w nocy zostało ocenione przez dzieci, osoby dorosłe i kobiety w ciąży chorujące na cukrzycę typu 1. Algorytm Cambridge używany w dzień i w nocy przez siedem dni przez osoby dorosłe chorujące na cukrzycę typu 1.',
    year2015_2016dates: '2015–2016',
    year2015_2016heading: 'Krótkie badania przeprowadzane w dzień i w nocy u osób samodzielnych',
    year2015_2016text: 'Algorytm Cambridge używany przez osoby dorosłe z prawidłowo leczoną cukrzycą typu 1 w okresie czterech tygodni. Algorytm Cambridge używany przez nastolatków z cukrzycą typu 1 w okresie trzech tygodni.',
    year2017_2018dates: '2017–2018',
    year2017_2018heading: 'Dłuższe badania przeprowadzane w dzień i w nocy u małych dzieci',
    year2017_2018text: 'Algorytm Cambridge używany w dzień i w nocy przez trzy miesiące przez dzieci i osoby dorosłe chorujące na cukrzycę typu 1. Algorytm Cambridge używany przez 2 lata przez dzieci i nastolatków od momentu zachorowania na cukrzycę. Algorytm Cambridge stosowany u małych dzieci w wieku 1–7 lat, które chorują na cukrzycę typu 1.',
    year2019_2019dates: '2019',
    year2019_2019heading: 'Rozpoczęcie korzystania z aplikacji CamAPS FX ',
    year2019_2019text: 'Aplikacja CamAPS FX używana przez starszych dorosłych z cukrzycą typu 1. Aplikacja CamAPS FX używana przez kobiety z cukrzycą typu 1 w czasie ciąży. Aplikacja CamAPS FX stosowana u dzieci w wieku 1–7 lat, które chorują na cukrzycę typu 1.',
    year2020_2020dates: '2020 i kolejne lata',
    year2020_2020heading: 'Zatwierdzenie przez urząd nadzorujący, komercjalizacja, badania kliniczne',
    year2020_2020text: 'Aplikacja CamAPS FX uzyskała certyfikat CE umożliwiający jej użytkowanie w Unii Europejskiej i Wielkiej Brytanii. Badania są kontynuowane, aby ocenić korzyści i umożliwić refundację.',
};
