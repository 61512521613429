/**
 * Common texts - what is CamAPS FX
 */
export const whatIsCamAPSFX = {

    linkFindOutMore: 'Läs mer',
    body:
        '<h2>Vad är CamAPS FX?</h2> <p> CamAPS FX är en Android-app som hjälper till att hantera glukosvärden hos personer med diabetes typ 1 genom en avancerad, adaptiv closed-loop-hybridmetod. </p>'
};

/**
 * Common texts - what is CamAPS FX
 */
export const userReviewsIntro = {
    linkMoreReviews: 'Fler recensioner',
    readMore: 'Läs mer',
    header:
        'Användarnas recensioner av CamAPS FX',
    text:
        'Berättelser ur verkligheten om verkliga personer som använder CamAPS FX-appen'
};