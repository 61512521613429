/**
 * Common texts - what is CamAPS FX
 */
export const whatIsCamAPSFX = {

    linkFindOutMore: 'Finn ut mer',
    body:
        '<h2>Hva er CamAPS FX?</h2> <p> CamAPS FX er en Android-app for styring av glukoseverdier hos personer med diabetes type 1 ved hjelp av en avansert hybrid closed loop-løsning. </p>'
};

/**
 * Common texts - what is CamAPS FX
 */
export const userReviewsIntro = {
    linkMoreReviews: 'Flere anmeldelser',
    readMore: 'Les mer',
    header:
        'Anmeldelser fra brukere av CamAPS FX',
    text:
        'Historier fra virkeligheten om ekte mennesker som bruker CamAPS FX appen'
};