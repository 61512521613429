import React, {Component} from 'react';
import {Header} from '../Components/Header';
import {Footer} from '../Components/Footer';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

export class TermsFR extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Conditions générales - CamAPS FX</title>
                    <meta name="title" content='Conditions générales'/>
                    <meta name="description"
                          content='Vous trouverez ici les conditions générales de CamDiab'/>
                    <meta property="og:locale" content='fr'/>
                    <meta name="keywords" content='conditions générales'/>
                </Helmet>
                <Header props={this.props}/>
                <section className="inner-banner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-10">
                                <h1>Conditions générales</h1>
                                <h3>Version datée du 24 juin 2024</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <h2>
                                    1. OBJET
                                </h2>
                                <h3>
                                    1.1. Nos produits
                                </h3>
                                <p>
                                    1.1.1. Nous
                                </p>
                                <p>
                                    CamDiab Ltd <strong>
                                    <br/>
                                    Adresse du siège social<br/>
                                    20-22 Wenlock Road<br/>
                                    London, N1 7GU, R.-U.
                                    <br/>
                                </strong>
                                    support@camdiab.com
                                    <br/>
                                    société immatriculée sous le n°11659211 en Angleterre et au Pays de Galles
                                    <br/>
                                    <br/>
                                </p>
                                <p>
                                    (« <strong>CamDiab</strong> »), développons et faisons fonctionner une/des application(s) mobile(s) pour l'administration d'insuline en boucle fermée ainsi qu'une infrastructure de données liée pour les personnes diabétiques et les professionnels de la santé. CamDiab est une entreprise spécialisée dans le domaine de la santé numérique et veut offrir aux personnes ayant un diabète un meilleur contrôle du glucose et réduire le fardeau d'une autogestion du diabète.
                                </p>
                                <p>
                                    1.1.2 Autant que nous sachions, nos Produits guident et facilitent l'administration de l'insuline en boucle fermée. Nos Produits et services ne peuvent pas remplacer une consultation avec ni un diagnostic par un professionnel de santé. Pour des questions sur la maladie et le traitement, nous recommandons de prendre contact avec un professionnel de santé. Les données fournies doivent impérativement être analysées par vous et votre professionnel de santé. Aucun contenu – qu'il soit fourni par CamDiab, par nos collaborateurs, par nos distributeurs, par nos sous-traitants ou par nos utilisateurs – ne peut être compris comme complétant ou remplaçant les informations d'un professionnel de santé ou d'un pharmacien.
                                </p>
                                <p>
                                    1.1.3 CamDiab peut proposer une gamme de Produits (applications) et de services ainsi que la possibilité de partager des données pour vous aider à prendre en charge votre diabète. Toutes les applications et tous les services actuels et à venir (y compris les nouvelles versions, les mises à jour et les autres améliorations) que CamDiab propose d'une manière ou d'une autre sont ci-après désignés collectivement par le terme « <strong>Produits</strong> » ou individuellement par le terme « <strong>Produit</strong> ». Chaque personne qui acquiert et utilise un ou plusieurs de nos Produits est désignée collectivement par le terme « <strong>Utilisateurs</strong> » ou individuellement par le terme « <strong>Utilisateur</strong> ». Si ces CGU se réfèrent à des personnes physiques en utilisant la forme masculine, elles s'appliquent également à tous les genres.
                                </p>
                                <p>
                                    1.1.4 Pour ces CGU, les définitions suivantes s'appliquent à chaque Produit :
                                </p>
                                <p>
                                    <strong>CamAPS FX </strong> est une application mobile indépendante pour les smartphones sous permettant l'administration d'insuline en boucle fermée hybride pour les personnes ayant un diabète de type 1. L'application fait le lien avec un capteur de glucose tiers et une pompe à insuline tierce. Pour visualiser les données, vous pouvez décider de partager les données collectées par l'application avec CamDiab ou avec le(s) portail(s) sur le diabète de tiers. Des restrictions peuvent s'appliquer quant au modèle de smartphone sous à utiliser en fonction de la pompe et du capteur de glucose de tiers.
                                </p>
                                <p>
                                    <strong>CamAPS HX </strong> est une application similaire à CamAPS FX mais offrant une boucle entièrement fermée dans le diabète non de type 1.
                                </p>
                                <p>
                                    1.1.5 Notre/nos manuel(s) d'utilisation (accessible(s) via nos applications ou notre site Web) et les dispositions spéciales applicables soulignent les éventuelles particularités de chaque Produit.
                                </p>
                                <h3>
                                    1.2. Champ d'application de nos CGU
                                </h3>
                                <p>
                                    1.2.1 Nous proposons nos Produits exclusivement sur la base de la version actuelle de ces Conditions générales d'utilisation (CGU) qui s'appliquent globalement. CamDiab s'oppose explicitement aux conditions commerciales contradictoires ou à d'autres conditions d'utilisation.
                                </p>
                                <p>
                                    1.2.2 Veuillez noter qu'avant d'utiliser nos Produits pour la première fois, vous devez impérativement consentir à toutes les déclarations et tous les accords mentionnés et les accepter lors de tout usage ultérieur. L'inscription et l'utilisation de nos Produits est interdite sans votre consentement à nos CGU.
                                </p>
                                <p>
                                    1.2.3 Nous nous réservons explicitement le droit de faire des changements futurs et d'apporter des modifications justifiées dans les faits à nos CGU. Les changements peuvent être nécessaires pour répondre à des exigences légales, à des exigences techniques et économiques ou aux intérêts de nos Utilisateurs. Ces changements sont possibles à tout moment et seront publiés comme il se doit. S'ils ne font pas l'objet d'une opposition dans un délai d'un mois, la poursuite de l'utilisation de nos Produits est soumise aux nouvelles CGU applicables.
                                </p>
                                <h3>
                                    1.3. Fournisseurs tiers
                                </h3>
                                <p>
                                    1.3.1 Vous pouvez trouver nos Produits dans la/les boutique(s) d'applications. En général, les conditions commerciales du tiers fournisseur concerné (sur lequel nous n'avons aucune influence) s'y appliquent. Ces conditions ne font pas partie de nos CGU ni de notre relation contractuelle avec vous en tant qu'utilisateur de nos Produits. Cela s'applique également aux conditions d'utilisation commerciales de votre appareil mobile ou de votre prestataire de services.
                                </p>
                                <p>
                                    1.3.2 Nous travaillons avec différents partenaires pour garantir l'utilisation optimale de nos Produits. Parmi eux figurent des partenaires de coopération qui fournissent directement à nos Utilisateurs certains Produits (p. ex. pompe à insuline, système de surveillance continue du glucose). Dans ces cas, nous soulignons les conditions commerciales d'un tiers fournisseur car vous devez impérativement accepter lesdites conditions avant de pouvoir commencer à utiliser nos Produits.
                                </p>
                                <h2>
                                    2. PROTECTION DES DONNÉES
                                </h2>
                                <p>
                                    <strong> Nous sommes conscients de l'importante responsabilité associée à votre utilisation de nos Produits. Vous nous communiquez non seulement des données personnelles d'ordre général mais également des données sur votre santé. Dans notre </strong>
                                    <Link to="/fr-ch/privacy">
                                        Politique sur la protection des données personnelles</Link> <strong>, nous vous informons sur l'utilisation et la protection de vos données en lien avec l'utilisation de nos Produits ainsi que sur votre consentement nécessaire pour cela. </strong>
                                </p>
                                <p>
                                    <strong> SANS VOTRE CONSENTEMENT RELATIF AUX DONNÉES PERSONNELLES PERMETTANT LE TRAITEMENT NÉCESSAIRE DES DONNÉES, L'UTILISATION DES PRODUITS N'EST POSSIBLE NI LÉGALEMENT NI DANS LES FAITS. </strong>
                                </p>
                                <p>
                                    <strong>Notre </strong>
                                    <Link to="/fr-ch/privacy"> Politique en matière de protection des données personnelles </Link> <strong> ne fait pas partie de ces CGU ; elle est plutôt utilisée uniquement pour vous fournir des informations dans le cadre du RGPD sur la base duquel vous donnez votre consentement pour le traitement nécessaire des données. </strong>
                                </p>
                                <h2>
                                    3. CONCLUSION DU CONTRAT ET INSCRIPTION
                                </h2>
                                <h3>
                                    3.1. Achat et paiement de nos produits
                                </h3>
                                <p>
                                    3.1.1 Nos produits peuvent être achetés de différentes manières :
                                </p>
                                <h3>
                                    Applications
                                </h3>
                                <p>
                                    Les <strong>boutiques d'applications</strong> (p. ex. Google Play et Amazon Appstore) proposent nos produits. Veuillez noter que pour télécharger (et non utiliser) nos produits, les conditions commerciales distinctes de la boutique d'applications s'appliquent et qu'elles peuvent nécessiter un compte utilisateur.
                                </p>
                                <p>
                                    <strong>Notre site Web</strong> peut donner un accès direct à nos produits et permet de les commander.
                                </p>
                                <p>
                                    Des <strong>codes promotionnels</strong> pour nos produits peuvent être en circulation et utilisés sur notre site Web.
                                </p>
                                <p>
                                    3.1.2 Vous pouvez commander nos Produits facturables sur notre site Web ou via nos distributeurs. Ils sont à payer via un prestataire de services de paiement, nos distributeurs ou les autres options de paiement indiquées. Le distributeur agit soit comme agent et prestataire de services de paiement pour CamDiab soit comme vendeur direct. Si vous rencontrez des problèmes lors du téléchargement ou du paiement, veuillez contacter <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a> à tout moment.
                                </p>
                                <p>
                                    3.1.3 Des redevances d'utilisation ou des prix d'achat (taxe sur la valeur ajoutée légale incluse) doivent impérativement être acquitté(e)s – en fonction de l'offre et de la sélection – en une seule fois ou régulièrement (p. ex. sur une base mensuelle, semestrielle ou annuelle). Les redevances actuelles sont indiquées sur notre site Web, par le distributeur ou affichées dans notre application ou dans les boutiques d'applications, et sont payables à l'avance. Si le paiement n'est pas traité directement via la boutique d'applications, le prestataire de services de paiement ou le distributeur peut vous envoyer des factures et des relances pour le paiement par courriel. En cas de paiement frauduleux, en retard ou incomplet, nous sommes en droit de bloquer votre accès à nos Produits. Votre obligation de paiement de la redevance d'utilisation demeure inchangée.
                                </p>
                                <p>
                                    3.1.4 CamDiab peut également faire des offres spéciales ou gratuites qui sont soumises à des conditions ou restrictions supplémentaires. À cet effet, nous nous référons explicitement aux éventuelles différences par rapport à ces CGU, sans quoi l'intégralité du contenu de ces CGU reste applicable. Les offres spéciales ou gratuites ne peuvent pas être transférées à d'autres Utilisateurs.
                                </p>
                                <h3>
                                    3.2. Inscription et activation
                                </h3>
                                <p>
                                    3.2.1 L'utilisation de nos Produits nécessite votre inscription auprès de CamDiab dans nos applications. Dès que vous aurez activé notre Produit pour la première fois, vous devrez impérativement vous inscrire en utilisant une adresse de courrier électronique et un mot de passe (ID CamDiab). Chaque Utilisateur ne peut créer qu'un seul identifiant CamDiab et chaque identifiant CamDiab ne peut être utilisé que par un Utilisateur.
                                </p>
                                <p>
                                    3.2.2 En s'inscrivant, l'Utilisateur accepte sa proposition contraignante de conclure un contrat avec CamDiab pour l'utilisation de nos Produits sur la base de la version applicable de ces CGU. Nous acceptons explicitement une telle proposition, au moins en activant votre identifiant CamDiab.
                                </p>
                                <p>
                                    3.2.3 L'activation est confirmée une fois que vos données de connexion sont acceptées et que vous avez suivi la formation appropriée. Nous nous réservons le droit de rejeter les Utilisateurs individuels ou leur proposition de conclure un contrat sans donner de motif. Dans ce cas, tous les paiements déjà effectués seront remboursés et les données communiquées par l'Utilisateur immédiatement supprimées.
                                </p>
                                <p>
                                    3.2.4 À l'inscription, l'Utilisateur confirme qu'il a au moins seize ans et que la loi applicable l'autorise à conclure des contrats. Seules les personnes ayant la capacité juridique peuvent s'inscrire. Les mineurs ne sont admis qu'avec l'accord explicite d'un parent/tuteur. À l'inscription, le parent/tuteur déclare être autorisé à faire des déclarations juridiquement contraignantes au nom du mineur.
                                </p>
                                <p>
                                    3.2.5 À l'inscription, l'Utilisateur confirme avoir pris connaissance et accepter sans réserve le contenu de ces CGU et que les informations qu'il communique sont vraies, correctes, à jour et complètes. Nous nous réservons le droit de contacter les Utilisateurs à tout moment pour vérifier les données d'inscription et les informations sur l'usage.
                                </p>
                                <p>
                                    3.2.6 Si l'Utilisateur communique des informations fausses, incorrectes, périmées ou incomplètes ou si nous avons tout simplement des raisons de croire que les informations sont fausses, incorrectes, périmées ou incomplètes, CamDiab est en droit de bloquer l'ID CamDiab correspondant avec effet immédiat et sans préavis, et d'interdire l'utilisation de nos Produits sans être obligé de rembourser à l'Utilisateur les coûts supportés.
                                </p>
                                <p>
                                    3.2.7 L'Utilisateur doit impérativement protéger ses données d'inscription contre l'accès non autorisé par des tiers, l'utilisation abusive ou l'utilisation avec une intention frauduleuse. S'il y a le moindre soupçon que l'ID CamDiab a été exposé à un tel risque, cela doit impérativement être signalé sans délai à <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>. Nous sommes en droit de bloquer l'ID CamDiab de tout Utilisateur s'il est utilisé avec des intentions injustifiées ou frauduleuses.
                                </p>
                                <h2>
                                    4. DROIT DE RETRAIT ET DE RÉTRACTATION
                                </h2>
                                <h3>
                                    4.1. Informations sur l'exercice du droit de rétractation
                                </h3>
                                <p>
                                    4.1.1 Si vous n'utilisez pas nos Produits à des fins commerciales ou professionnelles, vous êtes en droit, en tant que consommateur, d'annuler le contrat conclu avec CamDiab dans les conditions suivantes :
                                </p>
                                <p>
                                    <strong>DROIT DE RÉTRACTATION</strong>
                                </p>
                                <p>
                                    <strong><u>Droit de rétractation</u></strong>
                                </p>
                                <p>
                                    <strong> Vous avez le droit d'annuler ce contrat dans un délai de quatorze jours sans justification. </strong>
                                </p>
                                <p>
                                    <strong> Le délai de rétractation est de quatorze jours à partir de la date de conclusion du contrat (activation du contenu numérique). </strong>
                                </p>
                                <p>
                                    <strong> Pour exercer votre droit de rétractation, vous devez impérativement nous informer de votre décision d'annuler le contrat au moyen d'une déclaration claire au distributeur ou à CamDiab, courriel </strong> <strong> <a href="mailto:support@camdiab.com">support@camdiab.com</a> </strong> <strong>, (p. ex. par courrier électronique ou postal). </strong>
                                </p>
                                <p>
                                    <strong> Pour respecter le délai de rétractation, il vous suffit d'envoyer la notification d'exercice du droit de rétractation avant la fin du délai correspondant. </strong>
                                </p>
                                <p>
                                    <strong><u>Conséquences de la rétractation</u></strong>
                                </p>
                                <p>
                                    <strong> Si vous annulez ce contrat, le distributeur doit ou nous devons impérativement vous rembourser tous les paiements que vous avez effectués, sans délai et au plus tard dans les quatorze jours suivant la date de réception par nous de votre notification d'annulation de ce contrat. Lorsque ceci est possible, nous utilisons le même moyen de paiement pour le remboursement que celui que vous avez utilisé pour la transaction initiale sauf si nous avons explicitement convenu d'un moyen différent. En aucun cas ce remboursement occasionne des frais. </strong>
                                </p>
                                <h2>
                                    5. UTILISATION DE NOS PRODUITS
                                </h2>
                                <h3>
                                    5.1. Échéance et résiliation de l'usage
                                </h3>
                                <p>
                                    5.1.1 Votre droit à utiliser nos Produits commence au moment de l'inscription et de l'activation par CamDiab.
                                </p>
                                <p>
                                    5.1.2 Nos Produits facturables peuvent être achetés sous la forme d'abonnements mensuels, semestriels ou annuels ; selon l'offre en cours, ils sont également disponibles pour une période d'usage plus courte ou plus longue (ci-après « <strong>période d'abonnement</strong> »).
                                </p>
                                <p>
                                    5.1.3 L'Utilisateur et CamDiab sont tous deux en droit de résilier le contrat normalement et à tout moment, en donnant un préavis d'un mois avec prise d'effet à la fin du mois. Vous pouvez faire cela en contactant votre distributeur ou en nous envoyant une notification par écrit à l'adresse <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>. Veuillez noter que les sommes déjà payées pour une période d'abonnement non utilisée ne sont remboursées qu'en cas de résiliation normale par CamDiab ou de fin automatique (section 5.1.5 ci-dessous).
                                </p>
                                <p>
                                    5.1.4 En l'absence de résiliation ou de résiliation tardive, l'abonnement est automatiquement prolongé pour la même durée que celle initialement sélectionnée. Le prix standard correspondant au moment de la prolongation (et non pas le prix d'une offre spéciale ou gratuite) s'appliquera.
                                </p>
                                <p>
                                    5.1.5 En cas de décès de l'Utilisateur ou de révocation du consentement concernant la protection des données personnelles, la relation d'usage prend fin automatiquement et avec effet immédiat. CamDiab n'est pas autorisé à traiter vos données de santé sans votre consentement.
                                </p>
                                <p>
                                    5.1.6 Si vous avez des questions sur l'échéance de votre usage ou sur sa fin, veuillez contacter <a href="mailto:support@camdiab.com">support@camdiab.com</a> à tout moment.
                                </p>
                                <h3>
                                    5.2. Verrouillage et exclusion
                                </h3>
                                <p>
                                    5.2.1 Nous nous réservons le droit de verrouiller à tout moment et temporairement l'accès des Utilisateurs pour un motif important (à notre discrétion) ou de les exclure définitivement de l'utilisation de nos Produits. Si un contrat prend fin sans délai de préavis, le remboursement des redevances d'utilisation est exclu.
                                </p>
                                <p>
                                    5.2.2 Il existe un motif important, en particulier, en cas de violations graves par un Utilisateur des dispositions de ces CGU, p. ex. violations de la section 3.1 (Paiement) après un délai de grâce de deux semaines, de la section 3.2 (Données d'inscription), de la section 5.3 (Licence) ou de la section 6 (« Votre comportement »).
                                </p>
                                <h3>
                                    5.3. Licence et octroi des droits
                                </h3>
                                <p>
                                    5.3.1 Lors de votre inscription et de l'activation, vous acquérez le droit non exclusif, non transférable mais géographiquement illimité de conserver et d'utiliser une copie de notre application à vos propres fins dans le cadre de ces CGU. Pour les Produits facturables, cette licence est limitée à la durée de votre période d'abonnement contractuelle.
                                </p>
                                <p>
                                    5.3.2 Le logiciel, le code, les méthodes et les systèmes ainsi que le contenu de nos Produits sont protégés par le droit d'auteur et la loi sur la concurrence, et peuvent être exclusivement utilisés par CamDiab. Nos Produits ou des parties de ces derniers ne peuvent être copié(e)s, modifié(e)s, reproduit(e)s, republié(e)s, posté(e)s, transféré(e)s, vendu(e)s, proposé(e)s à la vente, revendu(e)s ni utilisé(e)s d'une quelconque autre manière sans notre autorisation préalable par écrit. Les Utilisateurs ne sont pas autorisés à utiliser les marques, les logos, les autres droits de propriété commerciale ou les droits sur des marques commerciales de CamDiab. Sauf disposition contraire dans ces CGU, l'ensemble des droits d'usage et d'exploitation sont la propriété exclusive de CamDiab et il n'existe aucune licence d'aucune nature pour nos Produits.
                                </p>
                                <p>
                                    5.3.3 Si la disposition technique de nos Produits le requiert, chaque Utilisateur octroie à CamDiab un droit d'usage et d'exploitation non exclusif, révocable mais gratuit et transférable, illimité en termes de durée et de localisation, sur le contenu qu'il génère, transfère, enregistre ou publie au sein de nos Produits. L'usage ou l'exploitation est toutefois exclu(e) si cela a un effet négatif disproportionné sur les intérêts légitimes de l'Utilisateur (p. ex. droits à la vie privée). En cas d'utilisation en dehors de nos Produits, nous préciserons que le contenu vient de l'utilisateur si cela est approprié. CamDiab ne revendique aucun droit de propriété sur le contenu créé et n'assumera aucun rôle de supervision quant au contenu créé par les Utilisateurs.
                                </p>
                                <h3>
                                    5.4. Disponibilité de nos Produits
                                </h3>
                                <p>
                                    5.4.1 Dans tous les cas, nous fournissons nos Produits en fonction des possibilités techniques, économiques, opérationnelles et organisationnelles existantes. CamDiab ne peut exclure d'éventuel(le)s interruptions, perturbations, retards, suppressions, transmissions incorrectes ou échecs d'enregistrement en lien avec l'utilisation de nos Produits ou la communication avec les Utilisateurs. Nous proposons en partie nos Produits en coopération avec des tiers distributeurs et prestataires et sommes par conséquent aussi dépendants de la disposition technique des services de ces tiers. CamDiab rejette par conséquent toute responsabilité, garantie ou obligation de fournir nos Produits en ligne à tout moment et sans interruption.
                                </p>
                                <p>
                                    5.4.2 Cela s'applique également aux restrictions d'utilisation de nos Produits pour cause de force majeure, de grèves, de lockouts et d'instructions officielles ou encore en raison de modifications techniques ou de travaux de maintenance sur les systèmes de CamDiab. En cas d'interruptions ou de défaillances de nos Produits, merci de nous contacter à tout moment à l'adresse <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h2>
                                    6. VOTRE COMPORTEMENT
                                </h2>
                                <h3>
                                    6.1. Informations générales
                                </h3>
                                <p>
                                    6.1.1 Chaque Utilisateur est en droit et a l'obligation d'utiliser nos Produits à ses seuls risques et frais tout en déployant les moyens techniques appropriés. Les dispositifs et les systèmes d'exploitation compatibles avec les Produits peuvent être demandés à tout moment auprès de <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <p>
                                    6.1.2 Vous êtes également obligé d'utiliser nos Produits exclusivement en conformité avec ces CGU et avec les dispositions légales ainsi que d'éviter toute utilisation abusive. Veuillez nous informer immédiatement si vous découvrez des manquements aux obligations énoncées dans cette section à l'adresse <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h3>
                                    6.2. Abus
                                </h3>
                                <p>
                                    6.2.1 Chaque Utilisateur est obligé d'indiquer honnêtement ses données d'inscription, de les maintenir à jour et complètes ainsi que de s'abstenir de les passer à un tiers. Les données personnelles doivent impérativement être traitées confidentiellement, gérées avec précaution et sauvegardées sous la responsabilité de chacun. CamDiab rejette toute responsabilité ou autres garanties en cas de perte ou de détérioration des données ou du contenu si cela ne lui est pas imputable.
                                </p>
                                <p>
                                    6.2.2 Le contenu tel que les photos, images, textes, vidéos ou autres représentations peut uniquement être enregistré, publié, transféré ou distribué en lien avec nos Produits si l'Utilisateur a le droit de les transférer ou les utiliser. Dans tous les cas, l'utilisation de tout contenu raciste, injurieux, discriminatoire, diffamatoire, sexuel, pornographique, violent ou autrement illégal, de quelque nature que ce soit, est toujours interdite.
                                </p>
                                <p>
                                    6.2.3 Il est en outre interdit de décrypter, faire de la rétro-ingénierie, décompiler ou démonter nos Produits ou d'utiliser nos Produits sur des smartphones « rootés ». Chaque Utilisateur a l'obligation de s'abstenir de toute interférence perturbatrice par des moyens techniques ou électroniques (en particulier par des tentatives de piratage, des attaques de force, l'introduction de virus, de vers informatiques, de chevaux de Troie ou d'autres logiciels malveillants) ou de tout type de tentative de perturbation pouvant affecter le logiciel ou le matériel des Produits et des systèmes de CamDiab. Toute altération de nos Produits peut se traduire par des performances différentes de l'usage prévu.
                                </p>
                                <h2>
                                    7. NOTRE RESPONSABILITÉ
                                </h2>
                                <h3>
                                    7.1. Garantie et responsabilité
                                </h3>
                                <p>
                                    7.1.1 Sauf disposition contraire dans ces CGU, CamDiab a les responsabilités et fournit les garanties prévues par les dispositions légales.
                                </p>
                                <p>
                                    7.1.2 Concernant les Utilisateurs qui ne sont pas des consommateurs, la responsabilité en matière de pertes financières, dommages indirects, manque à gagner et dommages résultant des réclamations de tiers est exclue. Si notre responsabilité est limitée ou exclue dans ces CGU, cela s'applique également aux agents d'exécution et sociétés affiliées de CamDiab.
                                </p>
                                <p>
                                    7.1.3 En dehors du champ d'application de la législation sur la responsabilité des produits, la responsabilité de CamDiab est limitée aux actes délibérés et à la négligence caractérisée – à l'exception des blessures aux personnes. La responsabilité pour négligence mineure est exclue lorsqu'elle s'accompagne de justifications objectives conséquentes.
                                </p>
                                <p>
                                    7.1.4 CamDiab ne garantit aucunement une disponibilité de nos Produits totale, sans interruption et sans erreurs ni que le logiciel et le matériel nécessaires fonctionnent sans erreurs. Nous ne pouvons non plus exclure la possibilité que les données soient suivies, enregistrées ou falsifiées par des tiers pendant le transfert via les systèmes de tiers, notamment Internet et d'autres réseaux de télécommunication.
                                </p>
                                <p>
                                    7.1.5 CamDiab n'accepte aucune garantie pour le contenu ou le matériel téléchargé que les Utilisateurs ont reçu suite à l'utilisation de nos Produits. L'Utilisateur est l'unique responsable pour tous les dommages pouvant intervenir sur son système informatique ou ses dispositifs ainsi que pour la perte des données résultant du téléchargement de matériels associés à nos Produits.
                                </p>
                                <p>
                                    7.1.6 CamDiab n'est en outre pas responsable du contenu de tiers, tel que les liens externes, les bannières, les autres informations ou autres offres publicitaires de tiers, qui peut être placé dans le cadre de nos Produits. Si nous permettons l'accès aux offres de tiers via des notifications ou des liens, CamDiab n'est pas responsable des informations qu'elles contiennent. Les transactions légales qui en résultent avec des tiers entraînent des relations contractuelles entre l'Utilisateur et le tiers concerné exclusivement. Nous n'acceptons aucune garantie ni aucune autre responsabilité pour les services des tiers.
                                </p>
                                <h3>
                                    7.2. Clause de non-responsabilité et indemnisation
                                </h3>
                                <p>
                                    7.2.1 L'utilisation de nos Produits ne remplace pas la consultation d'un professionnel de santé ni aucun autre avis médical et se fait exclusivement aux seuls risques de l'Utilisateur. Cela s'applique à toute utilisation des données fournies dans le cadre de nos Produits par l'Utilisateur y compris les résultats de la glycémie et du capteur de glucose, les calculs et les éventuelles recommandations. L'Utilisateur reconnaît explicitement que ces données peuvent être approximatives et CamDiab n'accepte aucune responsabilité quant à leur caractère correct.
                                </p>
                                <p>
                                    7.2.2 Dans la mesure exigée par la loi sur les dispositifs médicaux applicable qui régit l'utilisation des Produits, ces derniers peuvent exclusivement être exploités ou utilisés conformément à l'objectif, aux spécifications et dans les domaines d'application indiqués dans l'offre et les conditions d'utilisation.
                                </p>
                                <p>
                                    7.2.3 L'Utilisateur indemnisera CamDiab pour toutes les réclamations de tiers à l'encontre de CamDiab résultant d'une violation injustifiée de ses droits par l'Utilisateur en lien avec l'utilisation de nos Produits. Toutes les autres demandes de dédommagement par CamDiab à l'encontre de l'Utilisateur restent inchangées.
                                </p>
                                <p>
                                    7.2.4 L'Utilisateur accepte l'entière responsabilité pour tous les dommages et litiges devant et hors des tribunaux découlant de conflits avec d'autres Utilisateurs. L'Utilisateur reconnaît explicitement que CamDiab n'est en aucun cas responsable des actions ou omissions d'autres Utilisateurs ni des dommages qui en découlent.
                                </p>
                                <p>
                                    7.2.5 En cas de réclamations de tiers à l'encontre de CamDiab, l'Utilisateur a l'obligation de lui fournir immédiatement toutes les informations disponibles, complètes et conformes à la vérité, qui sont nécessaires pour étudier, se défendre contre et prévenir les réclamations faites par des tiers. L'Utilisateur assume les coûts de toute défense juridique nécessaire de CamDiab, y compris les frais de justice et d'avocats au taux légal.
                                </p>
                                <h2>
                                    8. DISPOSITIONS FINALES
                                </h2>
                                <h3>
                                    8.1. Choix de la législation
                                </h3>
                                <p>
                                    Ces CGU et l'ensemble de la relation légale entre CamDiab et l'Utilisateur sont exclusivement régis par la législation anglaise, à l'exception de ses dispositions de référence et de la convention des Nations unies sur les contrats de vente. En tant que consommateur, toutefois, l'Utilisateur bénéficie de la protection des dispositions obligatoires dans le pays où il se trouve ou réside habituellement.
                                </p>
                                <h3>
                                    8.2. Juridiction compétente
                                </h3>
                                <p>
                                    Il est convenu que le lieu d'exécution et la seule juridiction compétente pour tous les litiges découlant de ces CGU sont ceux du siège social de CamDiab à Cambridge, R.-U. Les dispositions obligatoires en matière de conflits de lois en faveur des consommateurs restent inchangées.
                                </p>
                                <h3>
                                    8.3. Autre
                                </h3>
                                <p>
                                    8.3.1 Si une notification ou la forme écrite est exigée par ces CGU (y compris pour les déclarations légalement importantes), la transmission par courriel à l'adresse indiquée à l'inscription (ID CamDiab) suffit.
                                </p>
                                <p>
                                    8.3.2 Les Utilisateurs n'ont pas le droit de transférer à des tiers leur ID CamDiab ni leurs droits et obligations découlant de ces CGU en lien avec l'utilisation de nos Produits. CamDiab peut transférer ses droits et obligations découlant de ces CGU à une autre société ou à un tiers si un tel transfert n'a pas d'effets significatifs sur les droits de l'Utilisateur aux termes de ces CGU.
                                </p>
                                <p>
                                    8.3.3 Si des dispositions individuelles de ces CGU sont ou deviennent inexécutables, les autres dispositions de ces CGU non concernées par ce défaut de validité restent valides et applicables.
                                </p>
                                <p>
                                    8.3.4 Ces CGU sont disponibles en plusieurs langues. La version dans la langue du pays où se trouve ou réside habituellement le consommateur est la version clé et fait autorité. Si et dans la mesure où il n'y a pas de version dans la langue spécifique au pays, la version anglaise fait autorité.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer props={this.props}/>
            </div>
        );
    }
}

