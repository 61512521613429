import React, {Component} from 'react';
import {Header} from './Header';
import {Footer} from './Footer';
import {Helmet} from "react-helmet";

export class PrivacyCZ extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Zásady ochrany osobních údajů - CamAPS FX</title>
                    <meta name="title" content='Zásady ochrany osobních údajů'/>
                    <meta name="description"
                          content='Zde najdete zásady ochrany osobních údajů CamDiab'/>
                    <meta property="og:locale" content='cs_cz'/>
                    <meta name="keywords" content='zásady ochrany osobních údajů'/>
                </Helmet>
                <Header props={this.props}/>
                <section className="inner-banner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-10">
                                <h1>Zásady ochrany osobních údajů</h1>
                                <h3>Verze ze dne 24. června 2024</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <h2>
                                    1. ÚVOD
                                </h2>
                                <h3>
                                    1.1 Odpovědný subjekt
                                </h3>
                                <p>
                                    Uvedeným odpovědným subjektem podle předpisů o ochraně osobních údajů je společnost CamDiab Limited, se sídlem 20-22 Wenlock Road, London, N1 7GU, Spojené království, založená a registrovaná v Anglii a Walesu pod číslem společnosti 11659211 (dále „společnost CamDiab“). Společnost CamDiab rozhoduje o účelu a způsobech zpracování osobních údajů svých uživatelů (dále „<strong>uživatelské údaje</strong>“), a proto odpovídá za jejich bezpečnost a za dodržování platných zákonů.
                                </p>
                                <p>
                                    1.1.2 Jako odpovědný subjekt máme například informační povinnost, kterou plníme tímto sdělením o ochraně osobních údajů.
                                </p>
                                <h3>
                                    1.2 Struktura a podstata souhlasu
                                </h3>
                                <p>
                                    1.2.1 V tomto sdělení o ochraně osobních údajů vás informujeme o účelech a rozsahu zpracování vašich uživatelských údajů a jejich předávání a o vašich rozsáhlých právech. Naše nabídka je určena výhradně osobám s diabetem, a tak už tím, že ji využijete, sdělujete určité údaje o vašem zdravotním stavu. Uživatelské údaje proto zpracováváme jako zdravotní údaje pouze s vaším souhlasem. Rozlišujeme:
                                </p>
                                <p>
                                    1.2.1.1 V části „Nezbytné zpracování“ popisujeme zpracování uživatelských údajů nezbytných k plnění smlouvy. Bez tohoto souhlasu není používání našich produktů z právního hlediska ani fakticky možné, protože naše služby závisejí na zpracování uvedených údajů.
                                </p>
                                <p>
                                    1.2.1.2 V části „Zpracování za účelem zdokonalování produktu“ je vysvětleno, jak nám a ostatním uživatelům můžete s vaším souhlasem pomoci tím, že nám umožníte používat vaše údaje zejména ke zdokonalení algoritmů pro řízení léčby, vylepšení produktu apod., aniž bychom vás kontaktovali.
                                </p>
                                <p>
                                    1.2.1.3 V části „Zpracování pro marketingové účely“ je popsáno, jak vás s vaším souhlasem budeme kontaktovat pro marketingové účely, např. prostřednictvím e-mailu, notifikací atd.
                                </p>
                                <p>
                                    1.2.1.4 V části „Obecné informace“ najdete souhrnně informace, které se týkají všech výše uvedených souhlasů, abychom je nemuseli opakovat.
                                </p>
                                <p>
                                    Relevantní kategorie jsou podrobněji popsány v následující části. Příslušný souhlas musíte poskytnout při registraci nebo při objednávce produktu.
                                </p>
                                <p>
                                    1.2.2 V některých případech může zpracování probíhat nezávisle na souhlasu na základě zákonných principů (např. nařízení o zdravotnických prostředcích).
                                </p>
                                <h2>
                                    2. NEZBYTNÉ ZPRACOVÁNÍ
                                </h2>
                                <p>
                                    Pokud nám udělíte souhlas, budeme níže uvedené uživatelské údaje zpracovávat, abychom mohli poskytovat naše služby. Pokud s tímto nezbytným zpracováním nesouhlasíte, nemůžete služby společnosti CamDiab využívat. Souhlasy můžete poskytnout během procesu registrace nebo při objednávání produktu.
                                </p>
                                <h3>
                                    2.1 Nezbytné uživatelské údaje
                                </h3>
                                <p>
                                    2.1.1 Kvůli ochraně vašich uživatelských údajů můžete naše služby používat pouze ve spojení s uživatelským účtem. K vytvoření uživatelského účtu potřebujeme a zpracováváme následující uživatelské údaje:
                                </p>
                                <ul type="disc">
                                    <li>
                                        E-mailová adresa
                                    </li>
                                    <li>
                                        Heslo (ukládáme pouze kontrolní součet)
                                    </li>
                                    <li>
                                        Jméno
                                    </li>
                                    <li>
                                        Prohlášení o osvobození od DPH (jméno, adresa, datum; pro samoplátce)
                                    </li>
                                    <li>
                                        Rok narození
                                    </li>
                                    <li>
                                        Datum registrace
                                    </li>
                                    <li>
                                        Poskytnutí nebo neposkytnutí souhlasu
                                    </li>
                                    <li>
                                        Identifikační číslo zařízení, výrobce, typ zařízení, verze operačního systému, verze aplikace
                                    </li>
                                    <li>
                                        Jazyk, země, časové pásmo
                                    </li>
                                </ul>
                                <p>
                                    2.1.2 Nad rámec registrace uživatele pak shromažďujeme tyto další údaje:
                                </p>
                                <p>
                                    <strong>Základní lékařské údaje </strong>
                                    <br/>
                                    Hmotnost, cílové rozmezí glykémie, nastavení bazálního inzulinu v pumpě, faktory citlivosti na inzulin, sacharidový poměr, doba působení inzulínu, cílová glykémie, nastavení upozornění, krok bolusu, sacharidová jednotka, stav těhotenství, předpokládané datum porodu.
                                </p>
                                <p>
                                    <strong>Údaje o používání a propojení</strong>
                                    <br/>
                                    Sériové číslo pumpy a vysílače, sled úkonů pumpy, snímače a uzavřené/otevřené smyčky, sled příkazů aplikace, sled událostí, informace o sledujících osobách zadaných do aplikace (telefonní číslo, přihlašovací údaje k účtům na datových portálech pro diabetes), dotazy na podporu, údaje o školiteli, ID diabetologické kliniky.
                                </p>
                                <p>
                                    <strong>Zdravotní údaje</strong>
                                    <br/>
                                    Záznamy v aplikaci, např. datum/čas/časová zóna/místo, příjem potravy/jídla, hodnoty glykémie a hodnoty glukózy ze senzoru, rychlost změny hladiny glukózy ze senzoru, celková denní dávka inzulínu, podávání inzulínu (bolusy a bazální inzulin), dočasná bazální rychlost, stav uzavřené smyčky, plnění pumpy a kanyly, upozornění, události boost a ease-off, nastavení aplikace, např. možnosti zobrazení, statistické přehledy údajů o glukóze a inzulinu.
                                </p>
                                <p>
                                    2.1.3 Pokud chcete, můžete zadat jakoukoli e-mailovou adresu, kterou si zvolíte speciálně pro naši společnost, musí být ale funkční, abychom vám na ni mohli zasílat důležitá upozornění.
                                </p>
                                Zpracováváme pouze uživatelské údaje, které společnosti CamDiab sami dobrovolně sdělíte nebo které jsou získávány z kontinuálního senzoru a z inzulinové pumpy, k nimž se dobrovolně připojíte. Zadání nezbytných uživatelských údajů je nicméně podmínkou, abyste mohli naše produkty používat v plném rozsahu.
                                <h3>
                                    2.2 Nezbytné účely
                                </h3>
                                <p>
                                    2.2.1 Všechny nezbytné účely zpracování jsou spojeny s poskytováním našich služeb:
                                </p>
                                <p>
                                    Pokud má <strong>zákazník nějaký dotaz</strong>, jsou zaznamenány zákazníkovy kontaktní údaje.
                                </p>
                                <p>
                                    <strong>Objednávky</strong> našich aplikací od samoplátců vedou k zaznamenání kontaktních a technických údajů, např. identifikačního čísla zařízení.
                                </p>
                                <p>
                                    <strong>Instalace</strong> našich aplikací vede k zaznamenání technických údajů a údajů o zařízením.
                                </p>
                                <p>
                                    <strong>Registrace</strong> vede k vytvoření účtu CamDiab zadáním e-mailové adresy a hesla.
                                </p>
                                <p>
                                    <strong>Poskytování našich služeb</strong> vyžaduje, abyste dobrovolně a aktivně zadávali nebo poskytovali údaje, přičemž u každé funkce je popsán účel, k němuž jsou údaje potřebné.
                                </p>
                                <p>
                                    Komunikace mezi společností CamDiab nebo distributorem a vámi v našich aplikacích nebo prostřednictvím jiných služeb elektronických zpráv (např. e-mailu, messengeru nebo telefonu), pokud je nezbytná k poskytnutí podpory nebo k řešení problémů s našimi produkty. Tímto způsobem zpracováváme veškeré připomínky a dotazy, které můžete zadávat prostřednictvím různých komunikačních kanálů při používání produktů a služeb společnosti CamDiab. Nejdůležitějším příkladem je naše služba podpory, kterou můžete kontaktovat e-mailem na <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a> Proto si vždy rozmyslete, jaké informace a údaje nám při aktivní komunikaci s námi sdělíte. Je to pouze vaše rozhodnutí. Z naší strany může být nezbytná komunikace s uživateli např. prostřednictvím e-mailu nebo podobným způsobem. Tímto způsobem vás informujeme o aktualizacích našich produktů, poskytujeme vám důležité bezpečnostní rady a pomoc při používání produktů a služeb. Takové zprávy v rámci podpory jsou uživatelům posílány jako nezbytná součást našich produktů.
                                </p>
                                <p>
                                    <strong>Léčebná zařízení</strong> (např. kontinuální senzor nebo inzulinová pumpa) jsou spárována s vaším zařízením, což umožňuje přenos dat do našich aplikací.
                                </p>
                                <p>
                                    <strong>Portály pro data o diabetu a pro sdílení dat</strong>, např. portály Glooko, umožňují výměnu dat s našimi aplikacemi. Synchronizace ale probíhá pouze tehdy, pokud ji aktivujete v nastaveních našich aplikací, a navíc pouze pokud používáte příslušnou funkci. Sdělení o ochraně osobních údajů výrobců portálů pro data o diabetu se vztahuje na údaje sdílené s těmito spolupracujícími třetími stranami.
                                </p>

                                2.2.2 <strong>Používání</strong> našich aplikací vyžaduje, abyste dobrovolně a aktivně zadávali nebo poskytovali údaje K vyřešení chyby v aplikaci potřebujeme například hlášení o pádu aplikace, které můžeme použít k vyřešení problému a určení okolností, za nichž k problému došlo. Jsou také zaznamenávány důležité údaje o vašem zařízení a o vašem chování při používání, protože plnění našich smluvních závazků spočívá zejména v přizpůsobování našich produktů, tj. zpracování individuálních informací o uživateli, mj. v závislosti na vaší poloze (což je důležité i pro funkci vyhledávání). Automatizovaná analýza chování uživatelů se provádí výhradně za účelem přizpůsobení vašeho používání při plnění smlouvy a nemá pro vás žádné právní účinky.

                                <h2>
                                    3. ZPRACOVÁNÍ ZA ÚČELEM ZDOKONALOVÁNÍ PRODUKTU
                                </h2>
                                <p>
                                    Vaše uživatelské údaje zpracováváme také nad rámec nezbytného použití popsaného v bodě 2 výše, abychom mohli naše produkty a služby zdokonalovat, jak je podrobněji popsáno níže.
                                </p>
                                <h3>
                                    3.1 Další údaje
                                </h3>
                                <p>
                                    Obecně používáme ke zdokonalování našich produktů tytéž uživatelské údaje, které jsou uvedené v bodě 2. Společnost CamDiab může navíc zaznamenávat ještě tyto uživatelské údaje:
                                </p>
                                <p>
                                    <strong>Údaje o používání</strong>
                                    <br/>
                                    Události týkající se aktivity, podle nichž si můžeme udělat představu o tom, jak naše produkty používáte. Můžeme podle nich zjišťovat, jak jsou naše produkty používány a kde lze například optimalizovat uspořádání nabídky.
                                </p>
                                <h3>
                                    3.2 Účel zdokonalování produktu
                                </h3>
                                <p>
                                    V důsledku rychlého technologického pokroku musíme neustále analyzovat, vyvíjet, testovat a zdokonalovat naše produkty a jejich působení, aby naši uživatelé mohli z našeho obsahu těžit co nejvíce. Za tímto účelem můžeme vyhodnocovat používání, proveditelnost, předvídatelnost, ovládání nebo zabezpečení našich produktů a získané poznatky zahrnovat do zdokonalených nových verzí produktů, např. aplikace. Tato zdokonalení jsou vám poskytována také prostřednictvím pravidelných aktualizací.
                                </p>
                                <p>
                                    Společnost CamDiab se věnuje vědeckému výzkumu všech aspektů diabetu. Proto mohou být anonymní uživatelské údaje použity také pro účely výzkumu a statistiky (vždy při dodržení uznávaných etických vědeckých norem) a interních analýz. To může zahrnovat sdílení anonymizovaných údajů se spolupracujícími třetími stranami.
                                </p>
                                <h2>
                                    4. ZPRACOVÁNÍ PRO MARKETINGOVÉ ÚČELY
                                </h2>
                                <h3>
                                    4.1 Zpravodaj
                                </h3>
                                <p>
                                    4.1.1 Nad rámec smlouvy vám můžeme zasílat informace o produktech a službách (včetně informací od pečlivě vybraných partnerů) a pozvánky k účasti v průzkumech a na jiných prodejních akcích a marketingových aktivitách (dále „<strong>zpravodaj</strong>“).
                                </p>
                                <p>
                                    4.1.2 Můžete si vybrat, zda se chcete přihlásit k odběru našeho zpravodaje. Souhlas můžete kdykoli odvolat v nastaveních účtu.
                                </p>
                                <h3>
                                    4.2 Další typy marketingu
                                </h3>
                                <p>
                                    4.2.1 Další souhlasy, např. s průzkumy, notifikacemi nebo nabídkami na míru, jsou získávány podle potřeby. Vždy vám vysvětlíme, proč určité údaje potřebujeme, a také jak můžete souhlas odvolat.
                                </p>
                                <p>
                                    4.2.2 Upozorňujeme, že vám v aplikaci můžeme zobrazovat nabídky, aniž bychom zpracovávali vaše osobní údaje. Takové obecné, nepřizpůsobené reklamy se vám budou zobrazovat i v případě, že k tomu nedáte souhlas.
                                </p>
                                <h2>
                                    5. POUŽITÍ PRO ZÁKONNÉ ÚČELY
                                </h2>
                                <h3>
                                    5.1 Vědecký výzkum a statistiky
                                </h3>
                                <p>
                                    Společnost CamDiab se věnuje vědeckému výzkumu všech aspektů diabetu. Proto mohou být anonymní uživatelské údaje použity také pro účely výzkumu a statistik (vždy při dodržení uznávaných etických vědeckých norem) a interních analýz. Používají se především k určování a zvyšování účinnosti postupů ke zvládání a léčbě diabetu. Právním základem takového použití je čl. 9 odst. 2 písm. j) nařízení General Data Protection Regulation (GDPR).
                                </p>
                                <h3>
                                    5.2 Uplatňování práv
                                </h3>
                                <p>
                                    Použití osobních údajů může být rovněž nezbytné k zabránění zneužití ze strany uživatelů nebo k uplatnění, výkonu nebo obhajobě právních nároků. Ke sdělení osobních údajů nás mohou nutit závazné právní předpisy, soudní nebo úřední rozhodnutí a pokyny, vyšetřování trestných činů nebo veřejný zájem. V takových případech je uchovávání a zpracovávání vašich údajů povoleno ze zákona bez vašeho souhlasu. Právním základem takového použití je čl. 9 odst. 2 písm. f) nařízení GDPR.
                                </p>
                                <h3>
                                    5.3 V souladu s právními předpisy o zdravotnických prostředcích
                                </h3>
                                <p>
                                    Jako výrobce nebo distributor zdravotnického prostředku musí naše společnost splňovat přísnější požadavky na monitorování funkčnosti našeho produktu. Tento systém tzv. vigilance (bdělosti) je vyžadován kontrolními úřady a může zahrnovat také zpracování osobních údajů. Právním základem takového použití je čl. 9 odst. 2 písm. i) nařízení GDPR.
                                </p>
                                <h2>
                                    6. OBECNÉ INFORMACE
                                </h2>
                                <h3>
                                    6.1 Omezení účelu a zabezpečení
                                </h3>
                                <p>
                                    6.1.1 Společnost CamDiab používá vaše osobní údaje výhradně pro účely stanovené v tomto sdělení o ochraně osobních údajů a v příslušných souhlasech. Dbáme na to, aby zpracování bylo vždy omezeno pouze na rozsah nezbytný pro jeho účel.
                                </p>
                                <p>
                                    6.1.2 Při zpracování je vždy zaručena důvěrnost vašich osobních údajů a jejich zabezpečení. To zahrnuje ochranu před neoprávněným a nezákonným zpracováním, neúmyslnou ztrátou, neúmyslným zničením nebo poškozením pomocí vhodných technických a organizačních opatření. Používáme přísné interní procesy, bezpečnostní prvky a metody šifrování.
                                </p>
                                <h3>
                                    6.2 Zpracovatelé
                                </h3>
                                <p>
                                    6.2.1 Naše produkty podléhají složitým procesům, které musíme řídit a aktualizovat. Zajišťováním technické podpory můžeme pověřit externí dodavatele (dále „<strong>zpracovatele</strong>“), abychom vám mohli nabízet komplexní a optimální používání našich produktů.
                                </p>
                                <p>
                                    6.2.2 Společnost CamDiab předává uživatelské údaje zpracovatelům výhradně v souladu s tímto sdělením o ochraně osobních údajů a pouze pro účely v něm uvedené. Zpracovatelé pracují podle našich specifikací a pokynů a nesmějí osobní údaje našich uživatelů používat pro své vlastní nebo jiné účely.
                                </p>
                                <p>
                                    6.2.3 Využíváme zpracovatele, kteří poskytují dostatečné záruky, že jsou přijata vhodná technická a organizační opatření tak, aby zpracování osobních údajů bylo v souladu se zákonnými požadavky a s naším sdělením o ochraně osobních údajů. Ochrana práv našich uživatelů je zajištěna uzavíráním závazných smluv, které splňují přísné požadavky nařízení GDPR.
                                </p>
                                <h3>
                                    6.3 Šifrování, pseudonymizace a anonymizace
                                </h3>
                                <p>
                                    6.3.1 Každý přenos dat je bez výjimky a už ve výchozím nastavení během přenosu šifrován. Pomocí protokolu HTTPS (Hypertext Transfer Protocol Secure, zabezpečený protokol pro přenos hypertextových dokumentů) nebo podobného protokolu chráníme vaše údaje před zachycením neoprávněnými třetími stranami.
                                </p>
                                <p>
                                    Pro účely zabezpečení a minimalizace údajů používáme také další procesy šifrování a pseudonymizace uživatelských údajů. To samozřejmě závisí na typu, rozsahu a účelu zpracování příslušných údajů. Zpracovateli například sdělujeme pouze takové uživatelské údaje, které potřebuje k plnění svých povinností.
                                </p>
                                <p>
                                    6.3.2 Po ukončení smluvního vztahu se zpracovatelem musí zpracovatel podle uvážení společnosti CamDiab vrátit všechny údaje našich uživatelů, nebo je vymazat, pokud ze zákona nemá povinnost uchovávat je.
                                </p>
                                <p>
                                    6.3.3 Údaje, k jejichž zpracování nejsou nutné osobní údaje (např. pro výzkum a analýzu), musejí být anonymizovány. Tím se ve všech případech zabrání propojení takových údajů s konkrétním uživatelem.
                                </p>
                                <h3>
                                    6.4 EU a další země
                                </h3>
                                <p>
                                    6.4.1 Snažíme se vybírat si takové partnery pro spolupráci, kteří sídlí nebo jejichž servery se nacházejí v Evropské unii (EU) nebo v Evropském hospodářském prostoru (EHP). Proti předávání údajů v rámci EU a EHP nelze nic namítat, protože nařízení GDPR platí ve všech členských státech.
                                </p>
                                <p>
                                    6.4.2 Ve výjimečných případech pověřujeme externí dodavatele, kteří se nacházejí nebo mají servery mimo EU. I v takových případech ale vaše osobní údaje podléhají vysoké úrovni ochrany v souladu s nařízením GDPR, a to buď na základě rozhodnutí EU o odpovídající ochraně, podle něhož je ochrana údajů v některých třetích zemích považována za přiměřenou (např. Švýcarsko, Izrael nebo Nový Zéland), nebo na základě určitých standardních smluvních doložek schválených EU, na nichž jsou založeny smluvní vztahy s našimi smluvními zpracovateli údajů, případně na základě srovnatelných právních nástrojů povolených nařízením GDPR.
                                </p>
                                <p>
                                    6.4.3 Dbáme také na to, aby naši partneři uplatňovali další bezpečnostní opatření, např. individuální bezpečnostní opatření a ustanovení o ochraně údajů nebo certifikaci podle GDPR. Pokud tedy například externí dodavatelé sídlí v USA, musejí se řídit rámcem štítu na ochranu soukromí schváleným EU nebo srovnatelnými mezinárodně uznávanými bezpečnostními standardy.
                                </p>
                                <h3>
                                    6.5 Soubory cookie
                                </h3>
                                <p>
                                    Společnost CamDiab může ukládat takzvané soubory cookie a díky nim nabízet širokou škálu funkcí a usnadnit vám používání našich webových stránek. Soubory cookie jsou malé textové soubory, které prohlížeč ukládá do vašeho zařízení. S výjimkou souborů cookie pro údaje o používání uvedených v bodě 6.6 mohou naše soubory cookie sloužit k provozu webových stránek. Pokud nechcete soubory cookie používat, můžete jejich ukládání zakázat pomocí příslušného nastavení v prohlížeči. Většina našich souborů cookie se vymaže buď na konci vaší návštěvy, nebo po zavření prohlížeče (soubory cookie relace). Pokud ne, můžete se v prohlížeči podívat, kdy se vymažou, nebo je vymazat ručně.
                                </p>
                                <h3>
                                    6.6 Údaje o používání
                                </h3>
                                <p>
                                    V aplikaci používáme službu Google Firebase Analytics, což je služba analýzy aplikací a webových stránek poskytovaná společností Google Inc. (dále „Google“). Společnost Google je certifikována v rámci štítu EU-USA na ochranu soukromí.
                                </p>
                                <h3>
                                    6.7 Uchovávání a vymazání
                                </h3>
                                <p>
                                    6.7.1 Vaše uživatelská data jsou uchovávána ve vašem zařízení. Stejné údaje jsou uchovávány také na serverech. Používáme pouze systémy, které splňují požadavky nařízení GDPR.
                                </p>
                                <p>
                                    6.7.2 Vaše údaje jsou uchovávány na serverech v Evropské unii (EU).
                                </p>
                                <p>
                                    6.7.3 Společnost CamDiab uchovává vaše osobní údaje zpravidla pouze po dobu trvání smlouvy. Ve výjimečných případech může být za účelem splnění určitých povinností po skončení smlouvy nebo zákonných povinností uchovávání nebo sdělení nebo za účelem uplatnění, výkonu nebo obhajoby právních nároků nezbytné uchovávat údaje po delší dobu (s ohledem na promlčecí lhůty).
                                </p>
                                <h3>
                                    6.8 Nezletilé osoby
                                </h3>
                                <p>
                                    Nezletilým osobám mladším šestnácti let je povoleno používat naše produkty pouze se souhlasem rodiče/opatrovníka (viz <a href="/cz/terms">všeobecné podmínky</a>). To platí i pro zpracování jejich osobních údajů, které je zákonné pouze tehdy a pouze v takovém rozsahu, v jakém byl získán souhlas rodiče/opatrovníka. Jinak naše produkty používat nesmějí.
                                </p>
                                <h3>
                                    6.9 Pověřenec pro ochranu osobních údajů
                                </h3>
                                <p>
                                    6.9.1 Našeho pověřenece pro ochranu osobních údajů, který je připravený odpovídat na všechny otázky týkajících se ochrany osobních údajů, můžete kontaktovat e-mailem na <a href="mailto:dpo@evalian.co.uk">dpo@evalian.co.uk</a>.
                                </p>
                                <p>
                                    6.9.2 Pověřenec pro ochranu osobních údajů je široce zapojen do všech záležitostí spojených s ochranou osobních údajů našich uživatelů. Jako vyškolený odborník průběžně sleduje naše zpracování, aby zajistil co nejlepší ochranu vašich uživatelských údajů.
                                </p>
                                <h2>
                                    7. VAŠE PRÁVA
                                </h2>
                                <h3>
                                    7.1 Odvolání souhlasů
                                </h3>
                                <p>
                                    Pokud zpracováváme vaše uživatelské údaje na základě vašeho souhlasu, můžete souhlas kdykoli odvolat. Tím však není dotčena zákonnost zpracování před odvoláním souhlasu. Naše služby budeme poskytovat i nadále, pokud nebudou záviset na odvolaném souhlasu.
                                </p>
                                <h3>
                                    7.2 <strong>Informace, opravy a omezení</strong>
                                </h3>
                                <p>
                                    7.2.1 Uživatel má právo požadovat informace o zpracování jeho osobních údajů. Za tím účelem nás kontaktujte kdykoli e-mailem na <a
                                    href="mailto:privacy@camdiab.com">privacy@camdiab.com</a>.
                                </p>
                                <p>
                                    7.2.2 Vaše právo na informace zahrnuje informace o účelech zpracování, kategoriích údajů a příjemců, době uchovávání, původu vašich údajů a vašich právech podle právních předpisů o ochraně osobních údajů. Všechny tyto informace najdete v tomto sdělení o ochraně osobních údajů a rádi vám je poskytneme v elektronické podobě.
                                </p>
                                <p>
                                    7.2.3 Pokud jsou některé vaše osobní údaje nesprávné, můžete kdykoli požádat o jejich opravu nebo doplnění. Máte právo omezit zpracování údajů po dobu, po kterou bude probíhat vámi požadované šetření.
                                </p>
                                <h3>
                                    7.3 Vymazání („právo být zapomenuti“)
                                </h3>
                                <p>
                                    Uživatel má právo požádat o vymazání svých osobních údajů. Za tím účelem nás kontaktujte kdykoli e-mailem na <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a>.
                                </p>
                                <h3>
                                    7.4 Stížnosti
                                </h3>
                                <p>
                                    7.4.1 Pokud se budete domnívat, že nedostatečně chráníme vaše práva na ochranu osobních údajů, obraťte se na nás kdykoli e-mailem na <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a> nebo kontaktujte pověřence pro ochranu osobních údajů na <a
                                    href="mailto:dpo@evalian.co.uk">dpo@evalian.co.uk</a>. Vaši žádost náležitě vyřídíme.
                                </p>
                                <p>
                                    7.4.2 Každý uživatel má právo podat stížnost příslušnému úřadu pro ochranu osobních údajů, pod něhož společnost CamDiab spadá, jestliže se domnívá, že zpracování jeho osobních údajů není v souladu s právními předpisy o ochraně osobních údajů. Uživatel má také právo podat stížnost u dozorového úřadu v členském státě EU, v němž má bydliště, v němž se nachází jeho pracoviště nebo v němž se nachází místo, kde došlo k možnému porušení předpisů.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer props={this.props}/>
            </div>
        );
    }
}

