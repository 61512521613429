import React, {Component} from 'react';
import {Header} from './Header';
import {Footer} from './Footer';
import {trainingPageText as trainingPageText_en} from "../libs/en/texts-training-page-lib";
import {trainingPageText as trainingPageText_de} from "../libs/de/texts-training-page-lib";
import {trainingPageText as trainingPageText_fr} from "../libs/fr/texts-training-page-lib";
import {trainingPageText as trainingPageText_nl} from "../libs/nl/texts-training-page-lib";
import {trainingPageText as trainingPageText_it} from "../libs/it/texts-training-page-lib";
import {trainingPageText as trainingPageText_cz} from "../libs/cz/texts-training-page-lib";
import {trainingPageText as trainingPageText_fi} from "../libs/fi/texts-training-page-lib";
import {trainingPageText as trainingPageText_pl} from "../libs/pl/texts-training-page-lib";
import {trainingPageText as trainingPageText_se} from "../libs/se/texts-training-page-lib";
import {trainingPageText as trainingPageText_no} from "../libs/no/texts-training-page-lib";
import {trainingPageText as trainingPageText_dk} from "../libs/dk/texts-training-page-lib";
import {trainingPageText as trainingPageText_es} from "../libs/es/texts-training-page-lib";
import parse from "html-react-parser";
import {Helmet} from 'react-helmet';

export class Training extends Component {

    constructor(props) {
        super(props);
        this.state = {
            language: this.props.language,
            trainingPageText: trainingPageText_en,
        };
        if (this.state.language === "DE") {
            this.state.trainingPageText = trainingPageText_de;
        }
        if ((this.state.language === "FR") || (this.state.language === "FR-FR")) {
            this.state.trainingPageText = trainingPageText_fr;
        }
        if (this.state.language === "NL") {
            this.state.trainingPageText = trainingPageText_nl;
        }
        if (this.state.language === "IT") {
            this.state.trainingPageText = trainingPageText_it;
        }
        if (this.state.language === "CZ") {
            this.state.trainingPageText = trainingPageText_cz;
        }
        if (this.state.language === "FI") {
            this.state.trainingPageText = trainingPageText_fi;
        }
        if (this.state.language === "PL") {
            this.state.trainingPageText = trainingPageText_pl;
        }
        if (this.state.language === "SE") {
            this.state.trainingPageText = trainingPageText_se;
        }
        if (this.state.language === "NO") {
            this.state.trainingPageText = trainingPageText_no;
        }
        if (this.state.language === "DK") {
            this.state.trainingPageText = trainingPageText_dk;
        }
        if (this.state.language === "ES") {
            this.state.trainingPageText = trainingPageText_es;
        }
    }

    setLanguageVars() {
        if (this.state.language !== this.props.language) {
            if (this.props.language === "EN") {
                this.setState({
                    language: this.props.language,
                    trainingPageText: trainingPageText_en,
                });
            }
            if (this.props.language === "DE") {
                this.setState({
                    language: this.props.language,
                    trainingPageText: trainingPageText_de,
                });
            }
            if ((this.props.language === "FR") || (this.props.language === "FR-FR")) {
                this.setState({
                    language: this.props.language,
                    trainingPageText: trainingPageText_fr,
                });
            }
            if (this.props.language === "NL") {
                this.setState({
                    language: this.props.language,
                    trainingPageText: trainingPageText_nl,
                });
            }
            if (this.props.language === "IT") {
                this.setState({
                    language: this.props.language,
                    trainingPageText: trainingPageText_it,
                });
            }
            if (this.props.language === "CZ") {
                this.setState({
                    language: this.props.language,
                    trainingPageText: trainingPageText_cz,
                });
            }
            if (this.props.language === "FI") {
                this.setState({
                    language: this.props.language,
                    trainingPageText: trainingPageText_fi,
                });
            }
            if (this.props.language === "PL") {
                this.setState({
                    language: this.props.language,
                    trainingPageText: trainingPageText_pl,
                });
            }
            if (this.props.language === "SE") {
                this.setState({
                    language: this.props.language,
                    trainingPageText: trainingPageText_se,
                });
            }
            if (this.props.language === "NO") {
                this.setState({
                    language: this.props.language,
                    trainingPageText: trainingPageText_no,
                });
            }
            if (this.props.language === "DK") {
                this.setState({
                    language: this.props.language,
                    trainingPageText: trainingPageText_dk,
                });
            }
            if (this.props.language === "ES") {
                this.setState({
                    language: this.props.language,
                    trainingPageText: trainingPageText_es,
                });
            }
        }
    }

    componentDidUpdate() {
        this.setLanguageVars();
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>{parse(this.state.trainingPageText.title)}</title>
                    <meta name="title" content={parse(this.state.trainingPageText.titleMeta)}/>
                    <meta name="description"
                          content={parse(this.state.trainingPageText.descriptionMeta)}/>
                    <meta property="og:locale" content={this.state.trainingPageText.localeMeta}/>
                    <meta name="keywords" content={this.state.trainingPageText.keywords}/>
                </Helmet>
                <Header props={this.props}/>
                <section className="inner-banner hcp-page">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-12 hcp-col-main content-wrap">
                                <h1>{parse(this.state.trainingPageText.trainingHeader)}</h1>
                                <h2>{parse(this.state.trainingPageText.trainingHeader_2)}</h2>
                                <div className="hcp-col">
                                    <p>{parse(this.state.trainingPageText.trainingHeaderText)}</p>
                                </div>
                                <a href={parse(this.state.trainingPageText.websiteLink)}
                                   title={parse(this.state.trainingPageText.websiteLinkHeading)}
                                   className="btn">
                                    {parse(this.state.trainingPageText.getTrained)}</a>
                            </div>
                        </div>

                    </div>
                </section>
                {/*<section className="inner-banner">*/}
                {/*    <div className="container">*/}
                {/*        <div className="row justify-content-center">*/}
                {/*            <div className="col-12 col-sm-12">*/}
                {/*                <h1>CamAPS FX Clinic</h1>*/}
                {/*                <h2>Access to CamAPS FX performance data</h2>*/}
                {/*                <div className="hcp-col">*/}
                {/*                    <p>CamAPS FX Clinic website allows statistics performance data to be viewed by*/}
                {/*                        authorised healthcare professionals.*/}
                {/*                        Note that only data from users electing to share data with your clinic*/}
                {/*                        can be viewed.*/}
                {/*                    </p>*/}
                {/*                </div>*/}
                {/*                <a href="https://camaps-fx.com" title="CamAPS FX Clinic"*/}
                {/*                   className="btn btn-extra-space">CamAPS FX*/}
                {/*                    Clinic</a>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}
                <Footer props={this.props}/>
            </div>
        );
    }
}

