/**
 * Texts for Training page
 */
export const trainingPageText = {
    title: 'Training - CamAPS FX',
    titleMeta: 'Wij bieden gratis een online-training aan voor CamAPS FX-gebruikers',
    descriptionMeta: 'Cambridge Diabetes Education Programme biedt een gratis gecertificeerde online-training aan voor personen met diabetes type 1, ouders en voogden, schoolpersoneel en diabetesbehandelaren.',
    keywords:
        'camaps training, camaps fx training,  ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'nl_NL',

    trainingHeader: 'Gecertificeerde training',
    trainingHeader_2: 'Voor gebruikers, diabetesbehandelaren en schoolpersoneel',
    trainingHeaderText: 'GRATIS online CamAPS FX-training, beschikbaar gesteld door het Cambridge Diabetes Education Programme (CDEP, het opleidingsprogramma van Cambridge op het gebied van diabetes).',
    websiteLink: 'https://www.camdiabtraining.com/account-sign-in.html',
    websiteLinkHeading: 'CDEP-training',
    getTrained: 'Volg nu een training!',
};
