/**
 * Texts for Training page
 */
export const trainingPageText = {
    title: 'Szkolenie — aplikacja CamAPS FX',
    titleMeta: 'Zapewniamy bezpłatne szkolenie online dla użytkowników aplikacji CamAPS FX',
    descriptionMeta: 'W ramach programu Cambridge Diabetes Education Programme odbywają się bezpłatne certyfikowane szkolenia online przeznaczone dla osób z cukrzycą typu 1, ich rodziców i opiekunów, personelu szkolnego i personelu medycznego.',
    keywords:
        'trening camaps, trening camaps fx,  ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    localeMeta: 'pl_pl',

    trainingHeader: 'Certyfikowane szkolenie',
    trainingHeader_2: 'Dla użytkowników, personelu medycznego i personelu szkolnego',
    trainingHeaderText: 'BEZPŁATNE szkolenie online dotyczące aplikacji CamAPS FX organizowane w ramach programu Cambridge Diabetes Education Programme (CDEP).',
    websiteLink: 'https://www.camdiabtraining.com/account-sign-in.html',
    websiteLinkHeading: 'Szkolenie CDEP',
    getTrained: 'Weź udział w szkoleniu',
};
