const tab = '    ';
/**
 * Texts for Order page and modal popups
 */
export const orderPageText = {
    title: 'Pedidos - CamDiab',
    titleMeta: 'Obtener CamAPS FX - CamDiab',
    descriptionMeta: 'Pague tan solo 70 libras esterlinas al mes por CamAPS FX. Obtenga un mes de prueba gratis. Requiere una bomba de insulina Dana compatible y un sensor de glucosa Dexcom G6',
    localeMeta: 'es_es',

    keywords:
        'costo de camaps, costo de camaps fx, pedido de camaps, pedido de camaps fx, prueba gratuita de camaps fx',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    // discount code processing messages
    pleaseWait: 'Espere',
    successResult: 'Correcto',
    errorResult: 'Error de procesamiento',
    discountApplied: 'Se ha aplicado el código de descuento.',
    okButtonText: 'Aceptar',

    // Discount line
    discountHeading: 'Descuento',
    discountOver: ' de', // This reads: Discount: 30% over 3 months
    discountOneMonth: 'mes',
    discountTwoAndMoreMonths: 'meses',

    // interest in CamAPS FX related messages
    processingEnquiry: 'Su solicitud se está procesando',
    thankYouForSubmission: 'Gracias por enviar sus datos',
    processingError: 'Error de procesamiento',
    responseAck: 'Tendremos en cuenta su consulta y nos pondremos en contacto con usted si CamAPS FX está disponible en su país',

    stage1: 'Fase 1',
    subscriptionOption: 'Opción de suscripción',
    stage2: 'Fase 2',
    equipment: 'Equipo',
    stage3: 'Fase 3',
    personalInfo: 'Datos personales',
    stage4: 'Fase 4',
    orderConfirmation: 'Confirmación de pedido',

    currencySubscription: '£',
    vatExempt: '(exento de IVA)',

    costMonthly: 80,
    costSixMonthly: 450,
    costAnnual: 840,

    costMonthlyVAT: 96,
    costSixMonthlyVAT: 540,
    costAnnualVAT: 1008,

    subscriptionMonthly: 'Suscripción mensual',
    subscriptionSixMonthly: 'Suscripción semestral',
    subscriptionAnnual: 'Suscripción anual',

    interested: '¿Le interesa adquirir CamAPS FX?',
    interestedPara: 'Utilice el código de descuento <b>CamAPS30</b> para una prueba de 30 días totalmente gratuita y sin compromiso.',

    subscriptionProcess: 'Nuestro proceso de suscripción',
    choosePackage: 'Elija su paquete de suscripción',
    answerQuestions: 'Responda a algunas preguntas sobre su persona',
    receiveTraining: 'Recibir formación en línea certificada',

    // below are texts for API
    botCheckFailed: 'Error de comprobación de bots. Vuelva a intentarlo en breve o escríbanos a support@camdiab.com',
    wrongDiscountCode: 'Código de descuento incorrecto o vencido',
    pumpAlreadyExists: 'No se puede finalizar el pedido. La bomba con el número de serie %s ya está en uso. Compruebe el número de serie de su bomba o escríbanos a sales@camdiab.com.',
    costTextWithDiscount: '%s%s para el primer período (código de descuento %s) y luego %s%s para los períodos siguientes',
    emailOrderSubjectLine: 'Su pedido de CamAPS FX',
    emailTrainingSubjectLine: 'Formación CamAPS FX',
    emailInterestedSubjectLine: 'Consulta CamAPS FX (%s)',

    emailVATexemptYes: 'Sí',
    emailVATexemptNo: 'No',

    emailOrderBody: "Estimada/o %s:<p>Nos complace confirmar su pedido de la app CamAPS FX. Los datos de su pedido son los siguientes:<p>" +
        tab + "Nombre: %s %s<br>" +
        tab + "Correo electrónico de contacto: %s<br>" +
        tab + "Número de serie bomba: %s<br>" +
        tab + "Período de suscripción: %s<br>" +
        tab + "%s<br>" +
        tab + "Exento de IVA: %s<br>" +
        tab + "Hospital de formación: %s<br><p><strong>Siguientes pasos</strong><br>Puede realizar la <a href=\"https://www.camdiabtraining.com/account-sign-in.html\">formación CamAPS FX en línea</a> o su instructor de diabetes le mostrará en persona cómo utilizar la aplicación. Puede descargarse la app CamAPS FX desde Amazon Appstore, pero no podrá utilizarla hasta que reciba formación y el instructor de diabetes desbloquee la aplicación proporcionando el código de formación CamAPS FX certificado. Tras la formación, podrá empezar a utilizar inmediatamente la app CamAPS FX.<p><strong>Pago</strong><br>Una vez se haya desbloqueado la aplicación y pueda empezar a utilizarla, le enviaremos una factura por correo electrónico. Preferimos los pagos por domiciliación bancaria. Para poder seguir utilizando la app CamAPS FX hay que abonar los pagos puntualmente. Esta obligación no va en detrimento de sus derechos legales. Para obtener el reembolso total de sus pagos, puede cancelar su pedido en cualquier momento antes de la formación o en los 14 días posteriores.<p><strong>Período de suscripción</strong><br>Su período de suscripción comienza a partir de la fecha de su formación. Tenga en cuenta que no se aceptan pagos antes de que tenga lugar la formación. Después del primer período de suscripción, se requerirán pagos por todos los períodos de suscripción posteriores, a menos que cancele su pedido escribiendo a %s.<p>Gracias por su compra. Le deseamos que disfrute del uso de la app CamAPS FX.<p>El equipo de CamDiab<br>%s",

    emailPotentialUser: "Estimada/o %s %s:<p>Entendemos que está interesada/o en utilizar la app CamAPS FX. Sin embargo, ha indicado que no puede obtener formación sobre la app CamAPS FX en su clínica de diabetes. Es posible que podamos organizar una formación para usted sujeta a costes. Sus datos son los siguientes:<p>" +
        tab + "Nombre: %s %s<br>" +
        tab + "Correo electrónico de contacto: %s<br><p><strong>Siguientes pasos</strong><br>Trasladaremos su consulta a nuestros formadores colaboradores de CamAPS FX. Quizás puedan ayudarle. En caso afirmativo, le enviarán un correo electrónico aparte. Una vez haya acordado con su formador las modalidades de formación, deberá presentar el pedido de CamAPS FX a través de <a href=\"https://camdiab.com/\">camdiab.com</a>.<p><strong>Pago de la formación</strong><br>Deberá acordar con su formador de CamAPS FX el coste de su formación y pagarlo aparte. Estos servicios no los proporciona CamDiab. <p><strong>Formadores de CamAPS</strong><br>Valoramos a nuestros formadores de CamAPS FX, todos los cuales han recibido una formación adecuada.<p>Gracias por su interés en la app CamAPS FX.<p>El equipo de CamDiab<br>%s",

    emailInterestedUser: "Estimada/o %s %s:<p>Gracias por su interés en usar la app CamAPS FX. Tomamos nota de su interés y nos pondremos en contacto con usted si la CamAPS FX está disponible en %s. Sus datos:<p>" +
        tab + "Nombre: %s %s<br>" +
        tab + "Correo electrónico de contacto: %s<br>" +
        tab + "País: %s<br><p>Gracias por su interés en usar la app CamAPS FX.<p>El equipo de CamDiab<br>%s",
    emailOrderBody_1: 'Gracias por registrarse para utilizar la app CamAPS FX',
    emailOrderBody_2: 'Estimada/o %s',
    emailOrderBody_3: 'Nos complace confirmar su pedido de la app CamAPS FX. Los datos de su pedido son los siguientes:',
    emailOrderBody_4: 'Nombre:',
    emailOrderBody_5: 'Correo electrónico de contacto:',
    emailOrderBody_6: 'Número de serie bomba:',
    emailOrderBody_7: 'Período de suscripción:',
    emailOrderBody_8: 'Precio:',
    emailOrderBody_9: 'Exento de IVA:',
    emailOrderBody_10: 'Hospital de formación:',
    emailOrderBody_11: 'Siguientes pasos',
    emailOrderBody_12: 'Se le enviará una factura <b>después</b> de que empiece a utilizar la app CamAPS FX.',
    emailOrderBody_13: 'Le recomendamos que realice la formación CamAPS FX en línea.',
    emailOrderBody_14: 'De lo contrario, su instructor de diabetes le mostrará en persona cómo utilizar la aplicación.',
    emailOrderBody_15: 'Puede descargarse la app CamAPS FX desde Amazon Appstore, pero no podrá utilizarla hasta que reciba formación y usted o el instructor de diabetes desbloquee la aplicación proporcionando el código de formación CamAPS FX certificado. ',
    emailOrderBody_16: 'Una vez desbloqueada la aplicación, podrá empezar a utilizarla inmediatamente.',
    emailOrderBody_17: 'Pago',
    emailOrderBody_18: 'Tras la formación, le enviaremos una factura por correo electrónico. Para poder seguir utilizando la app CamAPS FX hay que abonar los pagos puntualmente. Esta obligación no va en detrimento de sus derechos legales. Para obtener el reembolso total de sus pagos, puede cancelar su pedido en cualquier momento antes de la formación o en los 30 días posteriores.',
    emailOrderBody_19: 'Período de suscripción',
    emailOrderBody_20: 'Su período de suscripción comienza con la fecha de su formación. Tenga en cuenta que no se aceptan pagos antes de que tenga lugar la formación. Después del primer período de suscripción, se requerirán pagos por todos los períodos de suscripción posteriores, a menos que cancele su pedido escribiendo a sales@camdiab.com.',
    emailOrderBody_21: 'Gracias por suscribirse para utilizar la app CamAPS FX',
    emailOrderBody_22: 'Si precisa asistencia técnica, encontrará más información en nuestro sitio web.',
    emailOrderBody_23: '© 2021 CamDiab Ltd. Reservados todos los derechos.',

    // Texts for step one form
    s1MonthlyHeading: 'Suscripción mensual',
    s1MonthlyValue: '£80*',
    s1Select: 'Seleccionar',
    s1HalfYearlyHeading: 'suscripción de 6 meses',
    s1HalfYearlyText: '£450*',
    s1HalfYearlyTextSaving: '(ahorro de 30 libras esterlinas)',
    s1YearlyHeading: 'Suscripción anual',
    s1YearlyText: '£840*',
    s1YearlyTextSaving: '(ahorro de 120 libras esterlinas)',
    s1VATtext: ' *Más IVA, a menos que esté exento del mismo. Pago del importe total tras la formación/activación de la aplicación.',

    // Texts for step two form
    previous: 'Anterior',
    next: 'Siguiente',
    s2Yes: 'Sí',
    s2No: 'No',
    s2HaveDanaPump: '¿Tiene una bomba mylife YpsoPump, Dana Diabecare RS o DANA-i?',
    s2HaveCgm: '¿Tiene un sistema de MCG Dexcom G6?',
    s2Phone1: '¿Tiene un',
    s2PhoneHtml: 'https://www.dexcom.com/dexcom-international-compatibility',
    s2Phone2: 'teléfono Android compatible',
    s2Phone3: '?',
    s2NoPump: 'Para poder utilizar la app CamAPS FX debe ser usuario de una bomba de insulina mylife YpsoPump, Dana Diabecare RS o DANA-i. Diríjase a su equipo de diabetes para analizar sus posibilidades o diríjase a su distribuidor local de Dana.',
    s2NoCgm: 'Para poder usar la app CamAPS FX debe ser usuario del sistema de MCG Dexcom G6. Consulte a su equipo local de diabetes sobre el sistema de MCG Dexcom G6 o diríjase a Dexcom.',
    s2NoPhone: 'Si no utiliza un teléfono Android compatible, no podemos garantizar una conexión fiable con su sensor Dexcom G6. Plantéese adquirir un teléfono Android compatible.',

    // Texts for step three form
    s3InfoSource: [
        'Consulta',
        'Motor de búsqueda',
        'Redes sociales',
        'Un post de un amigo en las redes sociales',
        'JDRF',
        'Diabetes UK',
        'Radio',
        'TV',
        'Prensa',
        'Recomendación personal',
        'Otros'],

    // s3TeritoryQuestion: 'Are you based in the UK, the EU, or New Zealand?',
    s3TeritoryQuestion: '¿Reside en el Reino Unido o en la UE?',
    s3NotAvailable: 'Lamentablemente, la app CamAPS FX solo está disponible en los países indicados. Rellene sus datos si desea que nos pongamos en contacto con usted cuando el sistema esté disponible en su país.',
    s3SelectCountry: 'Seleccionar país',
    s3TC1: 'Al enviar su solicitud, acepta nuestras',
    s3TC2: 'Condiciones generales',
    s3TC3: 'y',
    s3TC4: 'Política de privacidad',
    s3TC5: 'y nos permite ponernos en contacto con usted.',
    s3Training1: 'Debe recibir la formación de un formador certificado o realizar una',
    s3Training2: 'formación en línea',
    s3Training3: 'antes de poder comenzar a usar la app CamAPS FX.',

    s3FirstName: 'Nombre',
    s3LastName: 'Apellido',
    s3Email: 'Dirección de correo electrónico',
    s3EmailConfirm: 'Confirmar la dirección de correo electrónico',
    s3AddressLine1: 'Línea de dirección uno',
    s3AddressLine2: 'Línea de dirección dos',
    s3AddressLine3: 'Línea de dirección tres',
    s3PostCode: 'Código postal',
    s3SubmitCountry: 'Enviar',
    s3DisabilityHeading: 'Declaro que tengo la siguiente discapacidad o enfermedad crónica:',
    s3Disability: 'Discapacidad o enfermedad crónica',
    s3signature: 'Firma (en letras de imprenta)',

    s3TrainingCdep1: 'Seleccione la formación CamAPS en línea para continuar:',
    s3TrainingCdep2: 'Seleccionar formación',
    s3TrainingCdep3: 'Formación en línea CamAPS',

    s3contactHeading: 'Introduzca sus datos de contacto',
    s3whereHeard: '¿Dónde ha oído hablar de la app CamAPS FX?',
    s3whereHeardSelect: 'Seleccione uno de los siguientes',
    s3pumpSerialNumber: 'El número de serie de su bomba Dana:',
    s3pumpSerialNumberConfirm: 'Confirme el número de serie de su bomba Dana:',
    s3pumpSerialNumberMessage: 'Importante:',
    s3pumpSerialNumberMessage1: 'El número de serie de la bomba es necesario para permitir que la app CamAPS FX se enlace con su bomba. Un número de serie incorrecto de la bomba puede impedir enlazar la app CamAPS FX con su bomba. Encontrará el número de serie en el lateral de la bomba. Ejemplo de número de serie: THF00041FB.',
    s3vatExempt: '¿Está usted exento de pagar el IVA?',
    s3vatHelp1: 'Encontrará más información en los folletos de asistencia de',
    s3vatHelpHtml: 'https://www.gov.uk/government/organisations/hm-revenue-customs/contact/vat-reliefs-for-disabled-and-older-people',
    s3vatHelpLine: 'Información sobre exención del IVA',
    s3vatHelp2: 'el sitio web GOV.UK',
    s3vatHelp3: 'o llamando a la línea de ayuda sobre el IVA para discapacitados, 0300 123 1073.',
    s3vatHelp4: 'El personal de HMRC no puede valorar si alguien está enfermo o discapacitado de forma crónica.',
    s3vatHelp5: 'Importante:',
    s3vatHelp6: 'Si encarga la app CamAPS FX en nombre de otra persona, rellene el formulario de exención del IVA con sus datos.',
    s3PhoneWarning: 'Si no utiliza un teléfono Android compatible, no podemos garantizar una conexión fiable con su sensor Dexcom G6. Plantéese adquirir un teléfono Android compatible',

    s3NameMissing: 'El nombre es obligatorio',
    s3SurnameRequired: 'El apellido es obligatorio',
    s3EmailRequired: 'La dirección de correo electrónico es obligatoria',
    s3CountryRequired: 'El país es obligatorio',
    s3EmailConfimrRequired: 'Es necesario confirmar el correo electrónico',
    s3EmailMatchRequired: 'La dirección de correo electrónico debe coincidir',
    s3ClinicRequired: 'El hospital de formación es obligatorio',
    s3DiseaseStateRequired: 'Se debe indicar el tipo de necesidades del usuario',
    s3OnlyTextValueProvided: 'Solo contiene valor de texto',
    s3OnlyNumberValueProvided: 'Solo contiene dígitos',
    s3SerialNumbersMatch: 'El número de serie debe coincidir',
    s3vatStatusRequired: 'Es obligatorio facilitar el régimen del IVA',
    s3AddressLine1Required: 'La línea de dirección uno es obligatoria',
    s3AddressLine2Required: 'La línea de dirección dos es obligatoria',
    s3PostCodeRequired: 'EL código postal es obligatorio',
    s3SickessRequired: 'La discapacidad o enfermedad crónica es obligatoria',
    s3SignatureRequired: 'La firma es obligatoria',

    // Texts for step four form
    s4NextSteps:'Siguientes pasos',
    s4DownloadApp:'Puede descargarse la app CamAPS FX en',
    s4DownloadGuideHtml:'https://s3-eu-west-1.amazonaws.com/camdiab.user.manual/Downloading+CamAPS+FX.pdf',
    s4DownloadAppGuide:'Guía de instalación',
    s4Appstore:'Amazon Appstore',
    s4DownloadApp1:'pero no podrá usarla hasta haberse',
    s4TrainingTextLink:'formado',
    s4TrainingHtml:'https://camdiab.com/training',
    s4TrainingTitle:'Formación',

    s4Payment:'Los pagos se cobrarán cuando enlace la app CamAPS FX con la bomba.',

    s4TermsAccept:'Acepto',
    s4TermsTermsHtml:'https://camdiab.com/terms',
    s4TermsTermsText:'Condiciones generales',
    s4TermsPrivacyHtml:'https://camdiab.com/privacy',
    s4TermsPrivacyText:'Política de privacidad',

    s4DiscountCodeText:'Código de descuento (opcional)',
    s4DiscountApplyCode:'Aplicar código',

    s4Summary:'Resumen del pedido',
    s4Name:'Nombre:',
    s4Email:'Correo electrónico:',
    s4PumpSN:'NS bomba:',
    s4Training:'Formación:',
    s4Total:'Total',

    s4CompleteButtonText:'Completo',

    // duration or subscription period in an email
    emailOneMonth: '1 mes',
    emailSixMonths: '6 meses',
    emailTwelveMonths: 'anual',

    // Order complete
    oderProcessedHeading: 'Su pedido se está procesando',
    oderProcessedSucess: 'Gracias, su pedido es correcto',
    oderProcessedError: 'Error de procesamiento',
    oderProcessedEmailToBeSent: 'Recibirá en breve un correo electrónico de confirmación',
};
