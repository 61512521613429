/**
 * Texts for Notifications page
 */
export const notificationsPageText = {
    title: 'Notifications récentes dans l\'application',
    titleMeta: 'Affichage des notifications récentes dans l\'application CamAPS FX',
    descriptionMeta: 'Voici le texte des récentes notifications intégrées à l\'application concernant les mises à jour de l\'application, les avis de sécurité, les mises à jour du manuel d\'utilisation',
    keywords: 'Notifications' ,
    localeMeta: 'fr_fr',

    heading: 'Notifications récentes dans l\'application',
    subheading: '',
	
	header_27:'',
    body_27:'',
	
	header_26:'Mise à jour du manuel de l\'utilisateur 11/05/2024',
    body_26:'Une version actualisée du manuel d\'utilisation est disponible.',
	
	header_25:'une nouvelle version de l\'application 05/05/2024',
    body_25:'Cher utilisateur de mylife CamAPS FX, une nouvelle version de l\'application sera disponible dans les prochaines 24 heures. Si vous souhaitez choisir le moment qui vous convient pour effectuer la mise à jour, vous pouvez désactiver la mise à jour automatique de mylife CamAPS FX dans le Google Play Store. Si vous avez des questions, veuillez contacter le service client d\'Ypsomed. ',
	
	header_24:'',
    body_24:'',
	
	header_23:'une nouvelle version de l\'application 24/04/2024',
    body_23:'Cher utilisateur de mylife CamAPS FX, une nouvelle version de l\'application sera disponible dans les prochaines 24 heures. Si vous souhaitez choisir le moment qui vous convient pour effectuer la mise à jour, vous pouvez désactiver la mise à jour automatique de mylife CamAPS FX dans le Google Play Store. Si vous avez des questions, veuillez contacter le service client d\'Ypsomed. ',

	header_22:'Mise à jour du manuel de l\'utilisateur 21/04/2024',
    body_22:'Une version actualisée du manuel d\'utilisation est disponible.',
	
	header_21:'',
    body_21:'',
	
	header_20:'',
    body_20:'',
	
	header_19:'',
    body_19:'',

    header_18:'',
    body_18:'',

    header_17:'',
    body_17:'',

	header_16:'Mise à jour du manuel de l\'utilisateur 04/03/2024',
    body_16:'Une version actualisée du manuel d\'utilisation est disponible.',
	
	header_15:'Mise à jour du manuel de l\'utilisateur 04/01/2024',
    body_15:'Une version actualisée du manuel d\'utilisation est disponible.',
	
	header_14:'Android OS 14. 22/11/2023',
    body_14:'Rappel: Pour garantir une utilisation ininterrompue de l\'application mylife CamAPS FX, veuillez consulter le site Web du fabricant du capteur afin de vérifier la compatibilité avant de procéder à la mise à jour vers Android OS 14. Note: Les mises à jour automatiques pourraient être activées par défaut dans les paramètres de votre téléphone. mylife CamAPS FX avant de procéder à la mise à jour vers Android OS ',
	
	header_13:'INFORMATION DE SÉCURITÉ IMPORTANTE 14/11/2023',
    body_13:'INFORMATION DE SÉCURITÉ IMPORTANTE.',
    body_13_link_text:'Veuillez lire et confirmer que vous avez pris connaissance de l\'avis important',
    body_13_end: '. Accédez au menu de l\'application, sélectionnez Sécurité et lisez l\'avis.',
    body_13_link:'https://s3.eu-west-1.amazonaws.com/camaps.safety.notices/Field+Safety+Notice+ET5604+Users+-+FR.pdf',

	header_12:'Mise à jour du manuel de l\'utilisateur 10/11/2023',
    body_12:'Une version actualisée du manuel d\'utilisation est disponible.',
	
	header_11:'Android OS 14. 12/09/2023',
    body_11:'Pour garantir une utilisation ininterrompue de l\'application mylife CamAPS FX, veuillez consulter le site Web du fabricant du capteur afin de vérifier la compatibilité avant de procéder à la mise à jour vers Android OS 14. Note: Les mises à jour automatiques pourraient être activées par défaut dans les paramètres de votre téléphone. Veuillez-vous assurer que vous utilisez la version 1.4(173) de l\'application mylife CamAPS FX avant de procéder à la mise à jour vers Android OS 14.',

	header_10:'INFORMATION DE SÉCURITÉ IMPORTANTE 15/08/2023',
    body_10:'INFORMATION DE SÉCURITÉ IMPORTANTE. Veuillez lire et confirmer que vous avez pris connaissance de l\'avis important. Accédez au menu de l\'application, sélectionnez Sécurité et lisez l\'avis.',
	
	header_9:'Mise à jour du manuel de l\'utilisateur 27/07/2023',
    body_9:'Une version actualisée du manuel d\'utilisation est disponible.',
	
	header_8:'',
    body_8:'',
	
	header_7:'Samsung de la série A 14/07/2023 ',
    body_7:'Les utilisateurs des modèles Samsung de la série A (c\'est-à-dire A53, A33) ont peut-être connu une perte de signal avec leur capteur CGM suite à la dernière mise à jour Samsung One UI 5.1. Samsung a identifié le problème et une solution est en cours de développement. Samsung prévoit de publier une mise à jour de One UI le 24 juillet (selon votre pays), qui résoudra le problème de perte de signal. Il est recommandé d\'installer la mise à jour dès qu\'elle sera disponible. Si vous n\'avez pas encore mis à jour vers One UI 5.1, nous vous recommandons d\'attendre la prochaine mise à jour Samsung One UI.',

    header_6:'',
    body_6:'',

    header_5:'',
    body_5:'',

    header_4:'Mise à jour du manuel de l\'utilisateur 27/05/2023',
    body_4:'Une version actualisée du manuel d\'utilisation est disponible.',

    header_3:'Alertes par SMS (Ypsopump uniquement) 11/05/2023',
    body_3:'Avec la prochaine mise à jour 1.4(171) mi-mai, vous pourrez activer jusqu\'à 5 followers SMS ET utiliser simultanément le mode Companion. Pour utiliser le mode Companion, le téléphone de l\'utilisateur doit avoir une connexion internet pour partager les données. Les utilisateurs doivent avoir une carte SIM pour permettre l\'envoi de SMS depuis leur téléphone. Si vous utilisez l\'une de ces options, nous vous conseillons de vérifier que votre forfait mobile inclut les SMS et/ou la couverture de données en fonction de l\'option que vous utilisez.',

    header_2:'Mise à jour sur la cybersécurité (Ypsopump uniquement) 24/04/2023',
    body_2:'Ne s\'applique pas aux utilisateurs de France. La sécurité de l\'application mylife CamAPS FX est importante pour nous. À partir de mi-mai, une mise à jour de cybersécurité imposée par Google Play Store sera en vigueur. À la suite de cette mise à jour, certains smartphones utilisés pourraient ne pas valider le test d\'intégrité de cybersécurité. Vous pouvez tester votre téléphone en amont, en accédant au menu d\'aide de l\'application et en effectuant une "vérification d\'intégrité". Si votre téléphone échoue à ce test, Essayez de désinstaller et réinstaller l\'application.',

    header_1:'Compatibilité avec Glooko 14/04/2023',
    body_1:'mylife CamAPS FX est maintenant compatible avec Glooko. Veuillez mettre à jour votre application mylife CamAPS FX. Connectez-vous à votre compte Diasend sur un navigateur Web et suivez le processus de mise à niveau car Diasend fusionne avec Glooko. Après avoir terminé avec succès, veuillez redémarrer votre téléphone pour envoyer automatiquement vos données à Glooko. Diasend sera disponible pour consulter les données historiques pour le reste de l\'année. Veuillez passer à Glooko avant le 14 juin 2023. Remarque : Si vous changez votre mot de passe pendant le processus de mise à niveau, vous devrez également mettre à jour le mot de passe dans le menu Partager de mylife CamAPS FX. Si vous habitez en France, la solution GlookoXT sera prête dans les prochaines semaines.',
};
