/**
 * Texts for Notifications page
 */
export const notificationsPageText = {
    title: 'Nya meddelanden i appen',
    titleMeta: 'Visar senaste aviseringar i appen CamAPS FX',
    descriptionMeta: 'Här är texten för de senaste aviseringarna i appen om appuppdateringar, säkerhetsmeddelanden, uppdateringar av användarmanualen',
    keywords: 'meddelanden' ,
    localeMeta: 'sv_se',

    heading: 'Nya meddelanden i appen',
    subheading: '',
	
	header_27:'En ny appversion 12/05/2024',
    body_27:'Kära mylife CamAPS FX-användare, En ny appversion kommer att vara klar inom de närmaste 24 timmarna. För att välja din föredragna uppdateringstid, inaktivera automatisk uppdatering för CamAPS FX i Google Play Butik. Om du har några frågor, vänligen kontakta Ypsomeds kundservice.',
	
	header_26:'Uppdatering av användarhandboken 11/05/2024',
    body_26:'En uppdaterad version av användarhandboken finns tillgänglig.',
	
	header_25:'',
    body_25:'',
	
	header_24:'',
    body_24:'',
	
	header_23:'En ny appversion 24/04/2024',
    body_23:'Kära mylife CamAPS FX-användare, En ny appversion kommer att vara klar inom de närmaste 24 timmarna. För att välja din föredragna uppdateringstid, inaktivera automatisk uppdatering för CamAPS FX i Google Play Butik. Om du har några frågor, vänligen kontakta Ypsomeds kundservice.',
	
	header_22:'Uppdatering av användarhandboken 21/04/2024',
    body_22:'En uppdaterad version av användarhandboken finns tillgänglig.',
	
	header_21:'',
    body_21:'',
	
	header_20:'',
    body_20:'',
	
	header_19:'',
    body_19:'',

    header_18:'',
    body_18:'',

    header_17:'',
    body_17:'',

	header_16:'Uppdatering av användarhandboken 04/03/2024',
    body_16:'En uppdaterad version av användarhandboken finns tillgänglig.',
	
	header_15:'Uppdatering av användarhandboken 04/01/2024',
    body_15:'En uppdaterad version av användarhandboken finns tillgänglig.',
	
	header_14:'Android OS 14. 22/11/2023',
    body_14:'Påminnelse: För att säkerställa en oavbruten användning av mylife CamAPS FX-applikationen, kontrollera sensortillverkarens webbplats för att verifiera kompatibiliteten innan du uppdaterar till Android OS 14. Observera: Automatiska uppdateringar kan vara aktiverade som standard i din telefons inställningar.',
	
	header_13:'SÄKERHETSMEDDELANDE TILL MARKNADEN 14/11/2023',
    body_13:'SÄKERHETSMEDDELANDE TILL MARKNADEN ',
    body_13_link_text:'Vänligen läs av viktigt rådgivande meddelande',
    body_13_end: '. Gå till appmeny -> Säkerhetsmeddelanden.',
    body_13_link:'https://s3.eu-west-1.amazonaws.com/camaps.safety.notices/Field+Safety+Notice+ET5604+Mylife+Users+-+SE+v2.pdf',

	header_12:'Uppdatering av användarhandboken 10/11/2023',
    body_12:'En uppdaterad version av användarhandboken finns tillgänglig.',
	
	header_11:'Android OS 14. 12/09/2023',
    body_11:'För att säkerställa en oavbruten användning av mylife CamAPS FX-applikationen, kontrollera sensortillverkarens webbplats för att verifiera kompatibiliteten innan du uppdaterar till Android OS 14. Observera: Automatiska uppdateringar kan vara aktiverade som standard i din telefons inställningar.Kontrollera att du använder mylife CamAPS FX app version 1.4(173) innan du uppdaterar till Android OS 14.',
	
	header_10:'SÄKERHETSMEDDELANDE TILL MARKNADEN 15/08/2023',
    body_10:'SÄKERHETSMEDDELANDE TILL MARKNADEN Vänligen läs av viktigt rådgivande meddelande. Gå till appmeny -> Säkerhetsmeddelanden.',
	
	header_9:'Uppdatering av användarhandboken 27/07/2023',
    body_9:'En uppdaterad version av användarhandboken finns tillgänglig.',
	
	header_8:'Glooko Kompatibilitet 21/07/2023',
    body_8:'Som tidigare meddelats kommer diasend att avbrytas den 28 juli. Om du vill fortsätta dela data med ditt diabetesteam, uppgradera din diasend-konto till Glooko eller skapa ett nytt Glooko-konto. Vänligen uppdatera till den senaste versionen av mylife CamAPS FX, ange ditt nya Glooko-användarnamn och lösenord i delningsmenyn och starta om din smartphone. Diasend kommer att vara tillgängligt för att visa historisk data under resten av året.',
	
	header_7:'Samsung A-seriens modeller 14/07/2023',
    body_7:'Användare av Samsung A-seriens modeller (t.ex. A53, A33) kan ha upplevt signalbortfall till sin CGM-sensor efter den senaste Samsung One UI 5.1-uppdateringen. Samsung har identifierat problemet och en lösning är under utveckling. Samsung planerar att släppa en uppdatering till One UI den 24 juli (beroende på din region), vilket kommer att lösa signalbortfallsproblemet. Det rekommenderas att installera uppdateringen så snart den blir tillgänglig. Om du ännu inte har uppdaterat till One UI 5.1 rekommenderar vi att vänta på nästa Samsung One UI-uppdateringen.',

    header_6:'Glooko Kompatibilitet (Endast Ypsopump) 14/06/2023',
    body_6:'mylife CamAPS FX är kompatibel med Glooko. Vänligen uppdatera din mittliv CamAPS FX-app till version 1.4(172). Om du delar data med en klinik kommer du att få meddelande inom ditt befintliga diasend-konto när din klinik uppgraderar till Glooko. När uppgraderingen är klar, ange ditt nya Glooko-användarnamn och lösenord i delningsmenyn och starta om din smartphone. Vänligen uppgradera till Glooko före den 28 juli 2023. Diasend kommer att vara tillgängligt för att visa historisk data under resten av året.',

    header_5:'',
    body_5:'',

    header_4:'Uppdatering av användarhandboken 27/05/2023 ',
    body_4:'En uppdaterad version av användarhandboken finns tillgänglig.',

    header_3:'SMS-notifikationer (Endast Ypsopump) 11/05/2023',
    body_3:'Med den kommande uppdateringen 1.4(171) i mitten av maj kommer du kunna aktivera upp till 5 SMS-följare OCH använda Companion-läge samtidigt. För att använda Companion-läget måste användarens telefon ha en internetanslutning för att dela data. Användare måste ha ett SIM-kort för att tillåta att SMS skickas från deras telefon. Om du använder någon av dessa alternativ rekommenderar vi att du kontrollerar att ditt abonnemang inkluderar SMS och/eller data täckning beroende på vilket alternativ du använder.',

    header_2:'Uppdatering om cybersäkerhet (Endast Ypsopump) 24/04/2023',
    body_2:'Meddelande:Säkerheten i mylife CamAPS FX-appen är av yttersta vikt. Från och med mitten av maj kommer det att finnas en uppdatering av cybersäkerheten som är påtvingad av Google Play Store. Efter denna uppdatering kan en liten andel av telefonerna misslyckas med integritetskontrollen av cybersäkerheten. Du kan testa telefonen i förväg genom att gå till hjälpmenyn och fylla i kontrollen. Om telefonen inte klarar av denna kontroll kan du försöka avinstallera och installera appen på nytt.',

    header_1:'',
    body_1:'',
};
