const tab = '    ';
/**
 * Texts for Order page and modal popups
 */
export const orderPageText = {
    title: 'Ordering - CamDiab',
    titleMeta: 'Get CamAPS FX - CamDiab',
    descriptionMeta: 'Pay as little as £70 per month for CamAPS FX. Get one month free trial. Requires compatible insulin Dana pump and Dexcom G6 glucose sensor',
    localeMeta: 'en_gb',

    keywords:
        'camaps cost, camaps fx cost, camaps order, camaps fx order, camaps fx free trial' +
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ' +
        '',
    // discount code processing messages
    pleaseWait: 'Please wait',
    successResult: 'Success',
    errorResult: 'Processing error',
    discountApplied: 'The discount code has been applied.',
    okButtonText: 'OK',

    // Discount line
    discountHeading: 'Discount',
    discountOver: ' over', // This reads: Discount: 30% over 3 months
    discountOneMonth: 'month',
    discountTwoAndMoreMonths: 'months',

    // interest in CamAPS FX related messages
    processingEnquiry: 'Your enquiry is being processed',
    thankYouForSubmission: 'Thank you for submitting your details',
    processingError: 'Processing error',
    responseAck: 'We will consider your enquiry and will be in touch if CamAPS FX becomes available in your country',

    stage1: 'Stage 1',
    subscriptionOption: 'Subscription option',
    stage2: 'Stage 2',
    equipment: 'Equipment',
    stage3: 'Stage 3',
    personalInfo: 'Personal information',
    stage4: 'Stage 4',
    orderConfirmation: 'Order confirmation',

    currencySubscription: '£',
    vatExempt: '(VAT exempt)',

    costMonthly: 80,
    costSixMonthly: 450,
    costAnnual: 840,

    costMonthlyVAT: 96,
    costSixMonthlyVAT: 540,
    costAnnualVAT: 1008,

    subscriptionMonthly: 'Monthly subscription',
    subscriptionSixMonthly: 'Six monthly subscription',
    subscriptionAnnual: 'Annual subscription',

    interested: 'Interested in purchasing CamAPS FX?',
    interestedPara: 'Use discount code <b>CamAPS30</b> for a completely free 30-day trial with no strings attached.',

    subscriptionProcess: 'Our subscription process',
    choosePackage: 'Choose your subscription package',
    answerQuestions: 'Answer a few questions about yourself',
    receiveTraining: 'Receive certified online training',

    // below are texts for API
    botCheckFailed: 'Bot check failed. Please try again shortly or contact us at support@camdiab.com',
    wrongDiscountCode: 'Wrong discount code or the discount code has expired',
    pumpAlreadyExists: 'Unable to complete the order. Pump with serial number %s is already in use. ' +
        'Please check your pump serial number or contact us at sales@camdiab.com.',
    costTextWithDiscount: '%s%s for the first period (discount code %s) and then %s%s for the following periods',
    emailOrderSubjectLine: 'Your CamAPS FX order',
    emailTrainingSubjectLine: 'CamAPS FX training',
    emailInterestedSubjectLine: 'CamAPS FX enquiry (%s)',

    emailVATexemptYes: 'Yes',
    emailVATexemptNo: 'No',

    emailOrderBody: "Dear %s," +
        "<p>" +
        "We are pleased to confirm your order of the CamAPS FX app. Your order " +
        "details are as follows:" +
        "<p>" +
        tab + "Name: %s %s<br>" +
        tab + "Contact email: %s<br>" +
        tab + "Pump serial number: %s<br>" +
        tab + "Subscription period: %s<br>" +
        tab + "%s<br>" +
        tab + "VAT exempt: %s<br>" +
        tab + "Training clinic: %s<br>" +
        "<p>" +
        "<strong>Next steps</strong><br>" +
        "You can either undertake the <a href=\"https://www.camdiabtraining.com/account-sign-in.html\">CamAPS FX training online</a> " +
        "or your diabetes educator will show you how to use the app in person. " +
        "You can download the CamAPS FX app from Amazon Appstore " +
        "but you will not be able to use it until you are trained and your diabetes educator " +
        "unlocks your app by providing their certified CamAPS FX training code. " +
        " Following training, you will be able to start using the CamAPS FX app immediately." +
        "<p>" +
        "<strong>Payment</strong><br>" +
        "Once the app is unlocked and you can start using it, we will email you an invoice. We prefer payments by Direct " +
        "Debit. Timely payments are required to " +
        "be able to continue using the CamAPS FX app. This does not affect your " +
        "statutory rights. You can cancel your order any time prior to training or " +
        "within 14 days of training to obtain a full refund of your payments." +
        "<p>" +
        "<strong>Subscription period</strong><br>" +
        "Your subscription period starts from the date of your training. Please note " +
        "that no payments are taken prior to training taking place. After the first subscription " +
        "period, payments will be required for each subsequent " +
        "subscription period unless you cancel your order in writing to %s." +
        "<p>" +
        "Thank you for your custom. We hope you will benefit from using the CamAPS " +
        "FX app." +
        "<p>" +
        "CamDiab team<br>" +
        "%s",

    emailPotentialUser: "Dear %s %s," +
        "<p>" +
        "We understand that you are interested in using the CamAPS FX app. However, you indicated that " +
        "you are unable to secure training on the CamAPS FX app at your diabetes clinic. " +
        "We may be able to arrange training for you at cost. Your details are as follows:" +
        "<p>" +
        tab + "Name: %s %s<br>" +
        tab + "Contact email: %s<br>" +
        "<p>" +
        "<strong>Next steps</strong><br>" +
        "We will pass your enquiry to our collaborating CamAPS FX trainer(s) who may be able to help you. " +
        "If so, they will email you separately. Once you agreed with your trainer your training arrangements, " +
        "you will need to submit the CamAPS FX order through <a href=\"https://camdiab.com/\">camdiab.com</a>." +
        "<p>" +
        "<strong>Payment for training</strong><br>" +
        "You will need to agree with your CamAPS FX trainer the cost of your training and pay the cost  " +
        "separately. These services are not provided by CamDiab. " +
        "<p>" +
        "<strong>CamAPS trainers</strong><br>" +
        "We value our CamAPS FX trainers who all undergone appropriate training." +
        "<p>" +
        "Thank you for your interest in the CamAPS FX app." +
        "<p>" +
        "CamDiab team<br>" +
        "%s",

    emailInterestedUser: "Dear %s %s," +
        "<p>" +
        "Thank you for your interest in using the CamAPS FX. " +
        "We registered your interest and will be in touch should the CamAPS FX become available in %s. " +
        "Your details:" +
        "<p>" +
        tab + "Name: %s %s<br>" +
        tab + "Contact email: %s<br>" +
        tab + "Country: %s<br>" +
        "<p>" +
        "Thank you for your interest in using the CamAPS FX app." +
        "<p>" +
        "CamDiab team<br>" +
        "%s",
    emailOrderBody_1: 'Thank you for registering to use the CamAPS FX app',
    emailOrderBody_2: 'Dear %s',
    emailOrderBody_3: 'We are pleased to confirm your order of the CamAPS FX app. Your order details are as follows:',
    emailOrderBody_4: 'Name:',
    emailOrderBody_5: 'Contact email:',
    emailOrderBody_6: 'Pump serial number:',
    emailOrderBody_7: 'Subscription period:',
    emailOrderBody_8: 'Cost:',
    emailOrderBody_9: 'VAT exempt:',
    emailOrderBody_10: 'Training clinic:',
    emailOrderBody_11: 'Next steps',
    emailOrderBody_12: 'An invoice will be sent to you <b>after</b> you start using the CamAPS FX app.',
    emailOrderBody_13: 'We recommend that you undertake the CamAPS FX training online.',
    emailOrderBody_14: 'Otherwise your diabetes educator will show you how to use the app in person.',
    emailOrderBody_15: 'You can download the CamAPS FX app from Amazon Appstore but you will not be able ' +
        'to use it until you are trained and your diabetes educator or you unlock your app by providing ' +
        'their/your certified CamAPS FX training code. ',
    emailOrderBody_16: 'Once the app is unlocked, you will be able to start using it immediately.',
    emailOrderBody_17: 'Payment',
    emailOrderBody_18: 'Following training, we will email you an invoice. Timely payments are required to be ' +
        'able to continue using the CamAPS FX app. This does not affect your statutory rights. ' +
        'You can cancel your order any time prior to training or within 30 days of training to obtain ' +
        'a full refund of your payments.',
    emailOrderBody_19: 'Subscription period',
    emailOrderBody_20: 'Your subscription period starts with the date of your training. Please note that ' +
        'no payments are taken prior to training taking place. After the first subscription period, ' +
        'payments are required for each subsequent subscription period unless you cancel your ' +
        'order in writing to sales@camdiab.com.',
    emailOrderBody_21: 'Thank you for subscribing to use the CamAPS FX app',
    emailOrderBody_22: 'For technical support, visit our website for details.',
    emailOrderBody_23: '© 2023 CamDiab Ltd. All rights reserved.',

    // Texts for step one form
    s1MonthlyHeading: 'Monthly subscription',
    s1MonthlyValue: '£80*',
    s1Select: 'Select',
    s1HalfYearlyHeading: '6 month subscription',
    s1HalfYearlyText: '£450*',
    s1HalfYearlyTextSaving: '(£30 savings)',
    s1YearlyHeading: 'Annual subscription',
    s1YearlyText: '£840*',
    s1YearlyTextSaving: '(£120 savings)',
    s1VATtext: ' *Plus VAT unless VAT exempt. Payable in full following training / activation of the app.',

    // Texts for step two form
    previous: 'Previous',
    next: 'Next',
    s2Yes: 'Yes',
    s2No: 'No',
    s2HaveDanaPump: 'Do you have a mylife YpsoPump, DANA Diabecare RS or DANA-i pump?',
    s2HaveCgm: 'Do you have a Dexcom G6 CGM system?',
    s2Phone1: 'Do you have a compatible',
    s2PhoneHtml: 'https://www.dexcom.com/dexcom-international-compatibility',
    s2Phone2: 'Android phone',
    s2Phone3: '?',
    s2NoPump: 'In order to use the CamAPS FX app you need to be using mylife YpsoPump, DANA Diabecare RS or DANA-i insulin pump. ' +
        'Please contact your local diabetes team and discuss your options or contact your local Dana distributor.',
    s2NoCgm: 'In order to use the CamAPS FX app you need to be using Dexcom G6 CGM system. Please consult ' +
        'your local diabetes team about the Dexcom G6 CGM system or contact Dexcom.',
    s2NoPhone: 'Without using a compatible Android phone, we are unable to guarantee reliable connection ' +
        'to your Dexcom G6 sensor. Consider purchasing a compatible Android phone.',

    // Texts for step three form
    s3InfoSource: [
        'Clinic',
        'Search engine',
        'Social media',
        'A post from a friend on social media',
        'JDRF',
        'Diabetes UK',
        'Radio',
        'TV',
        'Print',
        'Word of mouth',
        'Other'],

    // s3TeritoryQuestion: 'Are you based in the UK, the EU, or New Zealand?',
    // s3TeritoryQuestion: 'Are you based in the UK or the EU?',
    s3TeritoryQuestion: 'Are you based in the UK?',
    s3NotAvailable: 'Unfortunately the CamAPS FX app is only available in the ' +
        'designated countries. Please fill in your details if you would ' +
        'like us to contact you when the system becomes available in your country.',
    s3SelectCountry: 'Select country',
    s3TC1: 'By submitting your request you agree to our',
    s3TC2: 'Terms and Conditions',
    s3TC3: 'and',
    s3TC4: 'Privacy',
    s3TC5: 'and allow us to contact you.',
    s3Training1: 'You need to be trained by a certified trainer and/or undergo',
    s3Training2: 'online training',
    s3Training3: 'before you can start using the CamAPS FX app.',

    s3FirstName: 'First name',
    s3LastName: 'Last name',
    s3Email: 'Email address',
    s3EmailConfirm: 'Confirm email address',
    s3AddressLine1: 'Address line one',
    s3AddressLine2: 'Address line two',
    s3AddressLine3: 'Address line three',
    s3PostCode: 'Post code',
    s3SubmitCountry: 'Submit',
    s3DisabilityHeading: 'I declare that I have the following disability or chronic sickness:',
    s3Disability: 'Disability or chronic sickness',
    s3signature: 'Signature (type your name)',

    s3TrainingCdep1: 'Select CamAPS online training to continue:',
    s3TrainingCdep2: 'Select training',
    s3TrainingCdep3: 'CamAPS online training',

    s3contactHeading: 'Please enter your contact details',
    s3whereHeard: 'Where did you hear of the CamAPS FX app?',
    s3whereHeardSelect: 'Select one of the following',
    s3pumpSerialNumber: 'Your Dana pump serial number:',
    s3pumpSerialNumberConfirm: 'Confirm your Dana pump serial number:',
    s3pumpSerialNumberMessage: 'Important:',
    s3pumpSerialNumberMessage1: 'Pump serial number is required to allow the CamAPS FX ' +
        'app to link to your pump. An incorrect serial number may result in inability to link the ' +
        'CamAPS FX app to your pump. You will find serial number on the side of your pump. ' +
        'A sample serial number reads THF00041FB.',
    s3vatExempt: 'Are you exempt from VAT?',
    s3vatHelp1: 'You can find out more from the Helpsheets on',
    s3vatHelpHtml: 'https://www.gov.uk/government/organisations/hm-revenue-customs/contact/vat-reliefs-for-disabled-and-older-people',
    s3vatHelpLine: 'VAT Exempt Advise',
    s3vatHelp2: 'the GOV.UK website',
    s3vatHelp3: 'or by telephoning the VAT Disabled Reliefs Helpline on 0300 123 1073.',
    s3vatHelp4: 'HMRC staff cannot advise whether or not an individual is chronically sick or disabled.',
    s3vatHelp5: 'Important:',
    s3vatHelp6: 'If you are ordering the CamAPS FX app on behalf of somebody else, ' +
        'please fill in the VAT exemption form using their details.',
    s3PhoneWarning: 'Without using a compatible Android phone, we are unable to guarantee reliable ' +
        'connection to your Dexcom G6 sensor. Consider purchasing compatible Android phone',

    s3NameMissing: 'First name is required',
    s3SurnameRequired: 'Last name is required',
    s3EmailRequired: 'Email is required',
    s3CountryRequired: 'Country is required',
    s3EmailConfimrRequired: 'Confirm email is required',
    s3EmailMatchRequired: 'Email must match',
    s3ClinicRequired: 'Training clinic is required',
    s3DiseaseStateRequired: 'Nature of the user needs to be stated',
    s3OnlyTextValueProvided: 'Only contains text value',
    s3OnlyNumberValueProvided: 'Only contains number',
    s3SerialNumbersMatch: 'Serial number must match',
    s3vatStatusRequired: 'VAT status must be provided',
    s3AddressLine1Required: 'Address line one is required',
    s3AddressLine2Required: 'Address line two is required',
    s3PostCodeRequired: 'Post code is required',
    s3SickessRequired: 'Disability or chronic sickness is required',
    s3SignatureRequired: 'Signature is required',

    // Texts for step four form
    s4NextSteps:'Next steps',
    s4DownloadApp:'You can download the CamAPS FX app from',
    s4DownloadGuideHtml:'https://s3-eu-west-1.amazonaws.com/camdiab.user.manual/Downloading+CamAPS+FX.pdf',
    s4DownloadAppGuide:'Installation guide',
    s4Appstore:'Amazon Appstore',
    s4DownloadApp1:'but you will not be able to use it until you are',
    s4TrainingTextLink:'trained',
    s4TrainingHtml:'https://camdiab.com/training',
    s4TrainingTitle:'Training',

    s4Payment:'Payments are collected on linking the CamAPS FX app with your pump.',

    s4TermsAccept:'I accept',
    s4TermsTermsHtml:'https://camdiab.com/terms',
    s4TermsTermsText:'Terms and conditions',
    s4TermsPrivacyHtml:'https://camdiab.com/privacy',
    s4TermsPrivacyText:'Privacy policy',

    s4DiscountCodeText:'Discount code (optional)',
    s4DiscountApplyCode:'Apply code',

    s4Summary:'Order summary',
    s4Name:'Name:',
    s4Email:'Email:',
    s4PumpSN:'Pump SN:',
    s4Training:'Training:',
    s4Total:'Total',

    s4CompleteButtonText:'Complete',

    // duration or subscription period in an email
    emailOneMonth: '1 month',
    emailSixMonths: '6 months',
    emailTwelveMonths: 'annual',

    // Order complete
    oderProcessedHeading: 'Your order is being processed',
    oderProcessedSucess: 'Thank you, your order was successful',
    oderProcessedError: 'Processing error',
    oderProcessedEmailToBeSent: 'You will receive a confirmation email shortly',
};
