import React from 'react';
import * as Yup from "yup";
import {Formik, Field} from "formik";
import parse from "html-react-parser";

const StepFourForm = props => {
    return (
        <Formik
            initialValues={{discountCode: "", radio1: "", radio2: ""}}
            onSubmit={() => console.log()}
            validateOnChange={true}
            isInitialValid={false}
            validateOnBlur={true}
            validationSchema={orderSummaryFormValidation}
        >
            {({values, setFieldTouched, setFieldValue, isValid}) => (
                <form>
                    <section className="order-confirmation min-height-200">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="order-info-left">
                                        <p><b>{parse(props.orderPageText.s4NextSteps)}</b></p>
                                        <p> {parse(props.orderPageText.s4DownloadApp)}
                                            &nbsp;
                                            <a
                                                href={props.orderPageText.s4DownloadGuideHtml}
                                                title={props.orderPageText.s4DownloadAppGuide}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                {parse(props.orderPageText.s4Appstore)}</a>
                                            &nbsp;
                                            {parse(props.orderPageText.s4DownloadApp1)}
                                            &nbsp;
                                            <a href={props.orderPageText.s4TrainingHtml}
                                               title={props.orderPageText.s4TrainingTitle}
                                               target="_blank"
                                               rel="noopener noreferrer">
                                                {parse(props.orderPageText.s4TrainingTextLink)}</a>.</p>
                                        {/*<p>You will need to contact your nominated*/}
                                        {/*    certified trainer to be trained. Immediately following training,*/}
                                        {/*    you will be able to use the CamAPS FX app. </p>*/}
                                        <p>{parse(props.orderPageText.s4Payment)}</p>
                                        <div className="term-agree">
                                            <p>{parse(props.orderPageText.s4TermsAccept)}</p>
                                            <p>
                                                <Field type="checkbox" name="radio1" id="terms-condition"
                                                       value="true"
                                                       onChange={(e) => {
                                                           if (e.target.checked) {
                                                               const selectedValue = e.target.value;
                                                               setTimeout(async () => {
                                                                   await setFieldValue(
                                                                       "radio1",
                                                                       selectedValue
                                                                   );
                                                                   await setFieldTouched("radio1", false);
                                                               });
                                                           } else {
                                                               setTimeout(async () => {
                                                                   await setFieldValue(
                                                                       "radio1",
                                                                       ""
                                                                   );
                                                                   await setFieldTouched("radio1", false);
                                                               });
                                                           }
                                                       }}
                                                       checked={values.radio1 === "true"}
                                                />
                                                <label htmlFor="terms-condition">
                                                    <a href={props.orderPageText.s4TermsTermsHtml}
                                                       title={props.orderPageText.s4TermsTermsText}
                                                       target="_blank"
                                                       rel="noopener noreferrer">
                                                        {parse(props.orderPageText.s4TermsTermsText)}</a>
                                                </label>
                                            </p>
                                            <p>
                                                <Field type="checkbox" name="radio2" id="privacy-policy"
                                                       value="true"
                                                       onChange={(e) => {
                                                           if (e.target.checked) {
                                                               const selectedValue = e.target.value;
                                                               setTimeout(async () => {
                                                                   await setFieldValue(
                                                                       "radio2",
                                                                       selectedValue
                                                                   );
                                                                   await setFieldTouched("radio2", false);
                                                               });
                                                           } else {
                                                               setTimeout(async () => {
                                                                   await setFieldValue(
                                                                       "radio2",
                                                                       ""
                                                                   );
                                                                   await setFieldTouched("radio2", false);
                                                               });
                                                           }
                                                       }}
                                                       checked={values.radio2 === "true"}
                                                />
                                                <label htmlFor="privacy-policy">
                                                    <a href={props.orderPageText.s4TermsPrivacyHtml}
                                                       title={props.orderPageText.s4TermsPrivacyText}
                                                       target="_blank"
                                                       rel="noopener noreferrer">
                                                        {parse(props.orderPageText.s4TermsPrivacyText)}</a>
                                                </label>
                                            </p>
                                        </div>
                                        <div>
                                            <p></p>
                                        </div>
                                        <div className="discount-code">
                                            <Field type="text" className="txt"
                                                   placeholder={props.orderPageText.s4DiscountCodeText}
                                                   name="discountCode"
                                                   value={values.discountCode}/>

                                            <input type="button" className="btn"
                                                   value={props.orderPageText.s4DiscountApplyCode}
                                                   disabled={!(values.discountCode && values.discountCode.length >= 3)}
                                                   onClick={() => props.handleDiscount(values)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="order-info-right">
                                        <p>{parse(props.orderPageText.s4Summary)}</p>
                                        <p>{parse(props.orderPageText.s4Name)} {props.orderSummary.camAPSUserFormFirstName || 'Not Available'}&nbsp;
                                            {props.orderSummary.camAPSUserFormLastName || 'Not Available'}</p>
                                        <p>{parse(props.orderPageText.s4Email)} {props.orderSummary.camAPSUserFormEmail || 'Not Available'}</p>
                                        <p>{parse(props.orderPageText.s4PumpSN)} {props.orderSummary.serialNumber || 'Not Available'}</p>
                                        <p>{parse(props.orderPageText.s4Training)} {props.orderSummary.training || 'Not Available'}</p>
                                        <p>{props.isDiscount ? props.getDiscountAsText() : ''} </p>
                                        <p>{props.getSubscriptionPeriodAsText()}</p>
                                        <p>{parse(props.orderPageText.s4Total)}
                                            <span>{props.getSubscriptionCost(props.orderSummary.exemptVat)}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-12">
                                    <div className="next-step">
                                        <button className="btn arrow-btn prev-btn"
                                                onClick={props.handlePrevious}>
                                            {parse(props.orderPageText.previous)}
                                        </button>
                                        {
                                            // console.log(isValid)
                                        }
                                        <button
                                            className={`btn arrow-btn arrow-complete ${!isValid ? 'disable-btn' : ''}`}
                                            disabled={!isValid}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                props.handleComplete(values)
                                            }}>
                                            {parse(props.orderPageText.s4CompleteButtonText)}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </form>
            )}
        </Formik>
    )
};

const orderSummaryFormValidation = Yup.object().shape({
    discountCode: Yup.string().nullable(true),
    radio1: Yup.string().trim().required(),
    radio2: Yup.string().trim().required()
});

export default StepFourForm;