const tab = '    ';
/**
 * Texts for Order page and modal popups
 */
export const orderPageText = {
    title: 'Bestellen - CamDiab',
    titleMeta: 'Bestel nu CamAPS FX - CamDiab',
    descriptionMeta: 'U betaalt nu per maand maar £70 voor CamAPS FX. Nu een maand gratis op proef. Hiervoor zijn een compatibele Dana-insulinepomp en een Dexcom G6-glucosesensor nodig.',
    localeMeta: 'nl_NL',

    keywords:
        'camaps cost, camaps fx cost, camaps order, camaps fx order, camaps fx free trial',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    // discount code processing messages
    pleaseWait: 'Even wachten a.u.b.',
    successResult: 'Gelukt',
    errorResult: 'Fout bij het verwerken',
    discountApplied: 'De kortingscode is toegepast.',
    okButtonText: 'OK',

    // Discount line
    discountHeading: 'Korting',
    discountOver: ' over', // This reads: Discount: 30% over 3 months
    discountOneMonth: 'maand',
    discountTwoAndMoreMonths: 'maanden',

    // interest in CamAPS FX related messages
    processingEnquiry: 'Uw aanvraag wordt verwerkt.',
    thankYouForSubmission: 'Dank voor het invullen van uw gegevens.',
    processingError: 'Fout bij het verwerken',
    responseAck: 'Wij bekijken uw verzoek en nemen contact met u op als CamAPS FX in uw land beschikbaar komt.',

    stage1: 'Stap 1',
    subscriptionOption: 'Abonnementsoptie',
    stage2: 'Stap 2',
    equipment: 'Apparatuur',
    stage3: 'Stap 3',
    personalInfo: 'Persoonlijke informatie',
    stage4: 'Stap 4',
    orderConfirmation: 'Bestelbevestiging',

    currencySubscription: '£',
    vatExempt: '(vrijgesteld van BTW)',

    costMonthly: 80,
    costSixMonthly: 450,
    costAnnual: 840,

    costMonthlyVAT: 96,
    costSixMonthlyVAT: 540,
    costAnnualVAT: 1008,

    subscriptionMonthly: 'Maandabonnement',
    subscriptionSixMonthly: 'Abonnement van zes maanden',
    subscriptionAnnual: 'Jaarabonnement',

    interested: 'Overweegt u om CamAPS FX aan te schaffen?',
    interestedPara: 'Gebruik de kortingscode <b>CamAPS30</b> voor een volledig vrijblijvende gratis proefperiode van 30 dagen.',

    subscriptionProcess: 'Onze abonnementsprocedure',
    choosePackage: 'Kies uw abonnementspakket.',
    answerQuestions: 'Beantwoord een paar vragen over uzelf.',
    receiveTraining: 'U krijgt online een training van een gecertificeerde trainer.',

    // below are texts for API
    botCheckFailed: 'De botcheck is mislukt. Probeer het opnieuw of neem contact met ons op via support@camdiab.com.',
    wrongDiscountCode: 'Verkeerde kortingscode of kortingscode is verlopen',
    pumpAlreadyExists: 'De bestelling kan niet worden afgerond. De pomp met het serienummer %s is reeds in gebruik. Controleer het serienummer van uw pomp of neem contact met ons op via sales@camdiab.com.',
    costTextWithDiscount: '%s%s voor de eerste periode (kortingscode %s) en daarna %s%s voor de volgende periodes',
    emailOrderSubjectLine: 'Uw CamAPS FX-bestelling',
    emailTrainingSubjectLine: 'CamAPS FX-training',
    emailInterestedSubjectLine: 'Aanvraag CamAPS FX (%s)',

    emailVATexemptYes: 'Ja',
    emailVATexemptNo: 'Nee',

    emailOrderBody: "Beste %s,<p>Hierbij bevestigen wij graag uw bestelling voor de CamAPS FX-app. Hieronder vindt u de details van uw bestelling:<p>" +
        tab + "Naam: %s %s<br>" +
        tab + "Contact e-mail: %s<br>" +
        tab + "Serienummer pomp: %s<br>" +
        tab + "Abonnementsperiode: %s<br>" +
        tab + "%s<br>" +
        tab + "Vrijgesteld van BTW: %s<br>" +
        tab + "Trainingsziekenhuis: %s<br><p><strong>Volgende stappen</strong><br>U kunt de <a href=\"https://www.camdiabtraining.com/account-sign-in.html\">CamAPS FX-training online</a> volgen, of uw diabetestrainer kan u persoonlijk laten zien hoe u de app moet gebruiken. U kunt de CamAPS FX-app downloaden uit de Amazon Appstore, maar u kunt de app nog niet gebruiken tot u de training heeft gedaan en uw diabetestrainer de app voor u ontgrendelt door het invoeren van de code van de gecertificeerde CamAPS FX-training. Nadat u de training heeft gevolgd, kunt u direct beginnen met het gebruiken van de CamAPS FX-app.<p><strong>Betaling</strong><br>Als de app ontgrendeld is en u hem kunt gebruiken, sturen wij u een e-mail met een factuur. Wij hebben een voorkeur voor betaling via automatische overschrijving. Om de CamAPS FX-app te kunnen blijven gebruiken is tijdige betaling noodzakelijk. Uw wettelijke rechten blijven onverminderd van kracht. U kunt uw bestelling op ieder moment voor de training of binnen 14 dagen na de training opzeggen om uw betalingen volledig terug te ontvangen.<p><strong>Abonnementsperiode</strong><br>Uw abonnementsperiode begint vanaf de datum van uw training. Houd er rekening mee dat er geen betalingen worden geaccepteerd voordat de training heeft plaatsgevonden. Na de eerste abonnementsperiode moet er iedere keer voor de volgende abonnementsperiode worden betaald, tenzij u uw bestelling schriftelijk annuleert via %s.<p>Dank voor uw klandizie. Wij hopen dat u zult kunnen profiteren van uw gebruik van de CamAPS FX-app.<p>CamDiab-team<br>%s",

    emailPotentialUser: "Beste %s %s,<p>Wij hebben begrepen dat u belangstelling heeft om de CamAPS FX-app te gaan gebruiken. U heeft echter aangegeven dat het u via uw eigen diabeteskliniek niet is gelukt om een training te krijgen in het gebruik van de CamAPS FX-app. We kunnen voor u misschien een training regelen tegen een kostenvergoeding. U heeft de volgende gegevens ingevuld:<p>" +
        tab + "Naam: %s %s<br>" +
        tab + "Contact e-mail: %s<br><p><strong>Volgende stappen</strong><br>Wij geven uw verzoek door aan de CamAPS FX-trainer(s) waar wij mee samenwerken en die u mogelijk kan/kunnen helpen. Zo ja, dan sturen zij u een afzonderlijke e-mail. Als u eenmaal met uw trainer afspraken heeft gemaakt over de training, dan moet u de bestelling voor CamAPS FX insturen via <a href=\"https://camdiab.com/\">camdiab.com</a>.<p><strong>Betaling voor training</strong><br>U maakt met uw CamAPS FX-trainer zelf afspraken over de kosten van uw training en betaalt die kosten afzonderlijk. Die diensten worden niet verleend door CamDiab. <p><strong>CamAPS-trainers</strong><br>Wij hebben veel waardering voor onze CamAPS FX-trainers, die allen op de juiste manier zijn geschoold.<p>Bedankt voor uw belangstelling voor de CamAPS FX-app.<p>CamDiab-team<br>%s",

    emailInterestedUser: "Beste %s %s,<p>Bedankt voor uw belangstelling voor het gebruik van de CamAPS FX-app. Wij hebben vastgelegd dat u geïnteresseerd bent en nemen contact met u op als CamAPS FX in %s beschikbaar komt. Uw contactgegevens:<p>" +
        tab + "Naam: %s %s<br>" +
        tab + "Contact e-mail: %s<br>" +
        tab + "Land: %s<br><p>Bedankt voor uw belangstelling voor het gebruik van de CamAPS FX-app.<p>CamDiab-team<br>%s",
    emailOrderBody_1: 'Bedankt voor uw aanmelding voor het gebruiken van de CamAPS FX-app.',
    emailOrderBody_2: 'Beste %s,',
    emailOrderBody_3: 'Hierbij bevestigen wij graag uw bestelling voor de CamAPS FX-app. Hieronder vindt u de details van uw bestelling:',
    emailOrderBody_4: 'Naam:',
    emailOrderBody_5: 'Contact e-mail:',
    emailOrderBody_6: 'Serienummer pomp:',
    emailOrderBody_7: 'Abonnementsperiode:',
    emailOrderBody_8: 'Kosten:',
    emailOrderBody_9: 'Vrijgesteld van BTW:',
    emailOrderBody_10: 'Trainingsziekenhuis:',
    emailOrderBody_11: 'Volgende stappen',
    emailOrderBody_12: 'Pas <b>nadat</b> u bent begonnen met het gebruiken van de CamAPS FX-app, sturen wij u een factuur.',
    emailOrderBody_13: 'Wij raden aan om de CamAPS FX-training online te doen.',
    emailOrderBody_14: 'Anders laat uw diabetestrainer u persoonlijk zien hoe u de app moet gebruiken.',
    emailOrderBody_15: 'U kunt de CamAPS FX-app downloaden uit de Amazon Appstore, maar u kunt de app nog niet gebruiken tot u de training heeft gedaan en uw diabetestrainer of u de app ontgrendelt door het invoeren van de code van de gecertificeerde CamAPS FX-training. ',
    emailOrderBody_16: 'Als de app is ontgrendeld, kunt u direct beginnen hem te gebruiken.',
    emailOrderBody_17: 'Betaling',
    emailOrderBody_18: 'Na de training sturen wij u een factuur per e-mail. Om de CamAPS FX-app te kunnen blijven gebruiken is tijdige betaling noodzakelijk. Uw wettelijke rechten blijven onverminderd van kracht. U kunt uw bestelling op ieder moment voor de training of binnen 30 dagen na de training opzeggen om uw betalingen volledig terug te ontvangen.',
    emailOrderBody_19: 'Abonnementsperiode',
    emailOrderBody_20: 'Uw abonnementsperiode begint vanaf de datum van uw training. Houd er rekening mee dat er geen betalingen worden geaccepteerd voordat de training heeft plaatsgevonden. Na de eerste abonnementsperiode moet er iedere keer voor de volgende abonnementsperiode worden betaald, tenzij u uw bestelling schriftelijk annuleert via sales@camdiab.com.',
    emailOrderBody_21: 'Bedankt dat u zich heeft geabonneerd op de CamAPS FX-app.',
    emailOrderBody_22: 'Ga voor technische ondersteuning naar onze website voor meer informatie.',
    emailOrderBody_23: '© 2022 CamDiab Ltd. Alle rechten voorbehouden.',

    // Texts for step one form
    s1MonthlyHeading: 'Maandabonnement',
    s1MonthlyValue: '£80*',
    s1Select: 'Selecteer',
    s1HalfYearlyHeading: 'Abonnement van 6 maanden',
    s1HalfYearlyText: '£450*',
    s1HalfYearlyTextSaving: '(besparing van £30)',
    s1YearlyHeading: 'Jaarabonnement',
    s1YearlyText: '£840*',
    s1YearlyTextSaving: '(besparing van £120)',
    s1VATtext: ' *Exclusief BTW, tenzij vrijgesteld van BTW. Volledig te betalen na het volgen van de training/activering van de app.',

    // Texts for step two form
    previous: 'Vorige',
    next: 'Volgende',
    s2Yes: 'Ja',
    s2No: 'Nee',
    s2HaveDanaPump: 'Heeft u een mylife YpsoPump, Dana Diabecare RS- of DANA-i-pomp?',
    s2HaveCgm: 'Heeft u een Dexcom G6 CGM-systeem?',
    s2Phone1: 'Heeft u een compatibele',
    s2PhoneHtml: 'https://www.dexcom.com/dexcom-international-compatibility',
    s2Phone2: 'Android-telefoon',
    s2Phone3: '?',
    s2NoPump: 'Om de CamAPS FX-app te kunnen gebruiken, moet u een mylife YpsoPump, Dana Diabecare RS- of DANA-i-insulinepomp hebben. Neem alstublieft contact op met uw plaatselijke diabetesteam voor de mogelijkheden, of neem contact op met uw plaatselijke Dana-distributeur.',
    s2NoCgm: 'Om de CamAPS FX-app te kunnen gebruiken, moet u een Dexcom G6 CGM-systeem gebruiken. Raadpleeg uw plaatselijke diabetesteam over het Dexcom G6 CGM-systeem of neem contact op met Dexcom.',
    s2NoPhone: 'Als u geen gebruik maakt van een compatibele Android-telefoon, kunnen wij niet garanderen dat u een betrouwbare verbinding heeft met uw Dexcom G6-sensor. Overweeg om een compatibele Android-telefoon aan te schaffen.',

    // Texts for step three form
    s3InfoSource: [
        'Ziekenhuis',
        'Zoekmachine',
        'Sociale media',
        'Een post van een vriend op sociale media',
        'JDRF',
        'Diabetes UK',
        'Radio',
        'TV',
        'Krant',
        'Mond-tot-mondreclame',
        'Overige'],

    // s3TeritoryQuestion: 'Are you based in the UK, the EU, or New Zealand?',
    s3TeritoryQuestion: 'Bent u gevestigd in het Verenigd Koninkrijk of de EU?',
    s3NotAvailable: 'Helaas is de CamAPS FX-app uitsluitend verkrijgbaar in de genoemde landen. Vul alstublieft uw gegevens in als u wilt dat wij contact met u opnemen als het systeem in uw land beschikbaar komt.',
    s3SelectCountry: 'Kies een land',
    s3TC1: 'Door het versturen stemt u in met onze',
    s3TC2: 'Algemene voorwaarden',
    s3TC3: 'en',
    s3TC4: 'Gegevensbeschermingsbeleid',
    s3TC5: 'en geeft u ons toestemming om contact met u op te nemen.',
    s3Training1: 'U moet zijn getraind door een gecertificeerde trainer en/of een',
    s3Training2: 'online training',
    s3Training3: 'volgen voordat u kunt beginnen met het gebruiken van de CamAPS FX-app.',

    s3FirstName: 'Voornaam',
    s3LastName: 'Achternaam',
    s3Email: 'E-mailadres',
    s3EmailConfirm: 'Bevestig e-mailadres',
    s3AddressLine1: 'Adresregel 1',
    s3AddressLine2: 'Adresregel 2',
    s3AddressLine3: 'Adresregel 3',
    s3PostCode: 'Postcode',
    s3SubmitCountry: 'Verzenden',
    s3DisabilityHeading: 'Ik verklaar hierbij dat ik de volgende beperking of chronische aandoening heb:',
    s3Disability: 'Beperking of chronische aandoening',
    s3signature: 'Ondertekening (naam in blokletters)',

    s3TrainingCdep1: 'Selecteer de online CamAPS-training om door te gaan:',
    s3TrainingCdep2: 'Selecteer training',
    s3TrainingCdep3: 'Online CamAPS-training',

    s3contactHeading: 'Voer hier uw contactgegevens in.',
    s3whereHeard: 'Hoe heeft u gehoord over de CamAPS FX-app?',
    s3whereHeardSelect: 'Selecteer een van de volgende mogelijkheden.',
    s3pumpSerialNumber: 'Serienummer van uw Dana-pomp:',
    s3pumpSerialNumberConfirm: 'Bevestig het serienummer van uw Dana-pomp:',
    s3pumpSerialNumberMessage: 'Belangrijk:',
    s3pumpSerialNumberMessage1: 'Het serienummer van uw pomp is nodig om de CamAPS FX-app te koppelen aan uw pomp. Een onjuist serienummer kan ertoe leiden dat u de CamAPS FX-app niet aan uw pomp kunt koppelen. U vindt het serienummer aan de zijkant van uw pomp. Een voorbeeld van een serienummer is THF00041FB.',
    s3vatExempt: 'Geldt voor u een vrijstelling van BTW?',
    s3vatHelp1: 'U leest meer op de hulpbladen op',
    s3vatHelpHtml: 'https://www.gov.uk/government/organisations/hm-revenue-customs/contact/vat-reliefs-for-disabled-and-older-people',
    s3vatHelpLine: 'Advies vrijstelling van BTW',
    s3vatHelp2: 'de website GOV.UK',
    s3vatHelp3: 'of door telefonisch contact op te nemen met de hulplijn voor BTW-vrijstelling voor lichamelijk beperkten (VAT Disabled Reliefs Helpline): 0031 44 (0)300 123 1073.',
    s3vatHelp4: 'Het personeel van HMRC kan geen advies geven of iemand chronisch ziek is of een beperking heeft.',
    s3vatHelp5: 'Belangrijk:',
    s3vatHelp6: 'Als u de CamAPS FX-app namens iemand anders besteld, vul dan het formulier voor BTW-vrijstelling in met de persoonsgegevens van die persoon.',
    s3PhoneWarning: 'Als u geen gebruik maakt van een compatibele Android-telefoon, kunnen wij niet garanderen dat u een betrouwbare verbinding heeft met uw Dexcom G6-sensor. Overweeg om een compatibele Android-telefoon aan te schaffen.',

    s3NameMissing: 'Voornaam is een vereist veld.',
    s3SurnameRequired: 'Achternaam is een vereist veld.',
    s3EmailRequired: 'E-mail is een vereist veld.',
    s3CountryRequired: 'Land is een vereist veld.',
    s3EmailConfimrRequired: 'Bevestiging e-mail is een vereist veld.',
    s3EmailMatchRequired: 'E-mail-adressen moeten overeenkomen.',
    s3ClinicRequired: 'Trainingsziekenhuis is een vereist veld.',
    s3DiseaseStateRequired: 'Er moet worden aangegeven om wat voor soort gebruiker het gaat.',
    s3OnlyTextValueProvided: 'Bevat alleen tekst.',
    s3OnlyNumberValueProvided: 'Bevat alleen cijfers.',
    s3SerialNumbersMatch: 'Serienummer moet overeenkomen.',
    s3vatStatusRequired: 'BTW-status moet vermeld worden.',
    s3AddressLine1Required: 'Adresregel 1 is een vereist veld.',
    s3AddressLine2Required: 'Adresregel 2 is een vereist veld.',
    s3PostCodeRequired: 'Postcode is een vereist veld.',
    s3SickessRequired: 'Beperking of chronische aandoening is een vereist veld.',
    s3SignatureRequired: 'Ondertekening is een vereist veld.',

    // Texts for step four form
    s4NextSteps:'Volgende stappen',
    s4DownloadApp:'U kunt de CamAPS FX-app downloaden via',
    s4DownloadGuideHtml:'https://s3-eu-west-1.amazonaws.com/camdiab.user.manual/Downloading+CamAPS+FX.pdf',
    s4DownloadAppGuide:'Installatiehandleiding',
    s4Appstore:'Amazon Appstore',
    s4DownloadApp1:'maar u kunt hem niet gebruiken tot u',
    s4TrainingTextLink:'een training hebt gevolgd.',
    s4TrainingHtml:'https://camdiab.com/nl/training',
    s4TrainingTitle:'Training',

    s4Payment:'De betalingen worden geïnd als uw CamAPS FX-app gekoppeld wordt aan uw pomp.',

    s4TermsAccept:'Ik stem in met',
    s4TermsTermsHtml:'https://camdiab.com/terms',
    s4TermsTermsText:'Algemene voorwaarden',
    s4TermsPrivacyHtml:'https://camdiab.com/privacy',
    s4TermsPrivacyText:'Gegevensbeschermingsbeleid',

    s4DiscountCodeText:'Kortingscode (optioneel)',
    s4DiscountApplyCode:'Code toepassen',

    s4Summary:'Samenvatting bestelling',
    s4Name:'Naam:',
    s4Email:'E-mail:',
    s4PumpSN:'Serienummer pomp:',
    s4Training:'Training:',
    s4Total:'Totaal',

    s4CompleteButtonText:'Voltooid',

    // duration or subscription period in an email
    emailOneMonth: '1 maand',
    emailSixMonths: '6 maanden',
    emailTwelveMonths: 'jaarlijks',

    // Order complete
    oderProcessedHeading: 'Uw aanvraag wordt verwerkt.',
    oderProcessedSucess: 'Dank, uw bestelling is gelukt.',
    oderProcessedError: 'Fout bij het verwerken',
    oderProcessedEmailToBeSent: 'U ontvangt binnenkort een e-mail als bevestiging.',
};
