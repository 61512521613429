const tab = '    ';
/**
 * Texts for Order page and modal popups
 */
export const orderPageText = {
    title: 'Bestellungen - CamDiab',
    titleMeta: 'Erhalten CamAPS FX - CamDiab',
    descriptionMeta: 'Zahlen Sie für CamAPS FX nur £70 pro Monat. Holen Sie sich einen Monat kostenlose Testversion. Erfordert eine kompatible Insulin-Dana-Pumpe und einen Dexcom G6-Glukosesensor. Geschäftsbedingungen gelten',
    localeMeta: 'de_de',

    keywords:
        'camaps bestellungen, camaps fx bestellungen, camaps kosten, camaps fx kosten, ' +
        // 'closed loop, closed loop system, loop deutsch, eine autoimmunerkrankung kommt selten allein, glukose im blut, closed de, open loop closed loop, loop diabetes, was ist ein loop, hba1c zu niedrig, blutglukose, closed loop diabetes, dexcom app, insulin berechnen app, closed loop insulinpumpe, diabetes zimt therapie, glukose zu hoch, stopp diabetes, dexcom g6 closed loop, diabetes kohlenhydrate app, hybrid closed loop diabetes, loop dexcom g6, welche insulinpumpen gibt es, diabetes loop system, kohlenhydrate app android, kur diabetes typ 1, was heißt loop, mutter kind kur für diabetiker,  ' +
        '',

    // discount code processing messages
    pleaseWait: 'Bitte warten',
    successResult: 'Erfolg',
    errorResult: 'Verarbeitungsfehler',
    discountApplied: 'Der Discount-Code wurde angewendet.',
    okButtonText: 'OK',

    // Discount line
    discountHeading: 'Discount',
    discountOver: ' über', // This reads: Discount: 30% over 3 months
    discountOneMonth: 'Monat',
    discountTwoAndMoreMonths: 'Monate',

    // interest in CamAPS FX related messages
    processingEnquiry: 'Ihre Anfrage wurde verarbeitet',
    thankYouForSubmission: 'Vielen Dank für die Übermittlung Ihrer Kontaktdaten',
    processingError: 'Verarbeitungsfehler',
    responseAck: 'Wir werden Ihre Anfrage prüfen und Kontakt aufnehmen, wenn CamAPS FX in Ihrem Land zur Verfügung steht.',

    stage1: 'Phase 1',
    subscriptionOption: 'Abonnement Option',
    stage2: 'Phase 2',
    equipment: 'Equipment',
    stage3: 'Phase 3',
    personalInfo: 'Persönliche Information',
    stage4: 'Phase 4',
    orderConfirmation: 'Bestellbestätigung',

    currencySubscription: '£',
    vatExempt: '(Mehrwertsteuerbefreiung)',

    costMonthly: 80,
    costSixMonthly: 450,
    costAnnual: 840,

    costMonthlyVAT: 96,
    costSixMonthlyVAT: 540,
    costAnnualVAT: 1008,

    subscriptionMonthly: 'Monatliches Abonnement',
    subscriptionSixMonthly: 'Halbjährliches Abonnement',
    subscriptionAnnual: 'Jahresabonnement',

    interested: 'Möchten Sie CamAPS FX verwenden?</span>',
    interestedPara: 'Nutzen Sie den Discount Code <b>CamAPS30</b> für einen kostenlosen 30&#8209;Tage&#8209;Testlauf ohne Bedingungen.',

    subscriptionProcess: 'Unser Abonnement-Prozess',
    choosePackage: 'Wählen Sie Ihr Abonnement-Paket',
    answerQuestions: 'Beantworten Sie einige Fragen über sich',
    receiveTraining: 'Erhalten Sie Ihr zertifiziertes Online-Training',

    // below are texts for API
    botCheckFailed: 'Bot Check fehlgeschlagen. Bitte versuchen Sie es in Kürze erneut oder kontaktieren Sie uns über support@camdiab.com',
    wrongDiscountCode: 'Falscher Discount-Code oder abgelaufener Discount-Code',
    pumpAlreadyExists: 'Die Bestellung konnte nicht abgeschlossen werden. Die Pumpe mit der Seriennummer %s ist bereits in Gebrauch. Bitte prüfen Sie die Seriennummer Ihrer Pumpe oder kontaktieren Sie uns über sales@camdiab.com.',
    costTextWithDiscount: '%s%s für den ersten Zeitraum (Discount-Code %s) und %s%s für die folgenden Zeiträume',
    emailOrderSubjectLine: 'Ihre CamAPS FX Bestellung',
    emailTrainingSubjectLine: 'CamAPS FX Training',
    emailInterestedSubjectLine: 'CamAPS FX Anfrage (%s)',

    emailVATexemptYes: 'Ja',
    emailVATexemptNo: 'Nein',

    emailOrderBody: "Liebe(r) %s," +
        "<p>" +
        "Wir freuen uns, Ihnen die Bestellung der CamAPS FX App zu bestätigen. Ihre Bestellung " +
        "Details wie folgt:" +
        "<p>" +
        tab + "Name: %s %s<br>" +
        tab + "Kontakt E-Mail: %s<br>" +
        tab + "Pumpen-Seriennummer: %s<br>" +
        tab + "Abonnementzeitraum: %s<br>" +
        tab + "%s<br>" +
        tab + "Mehrwertsteuerbefreiung: %s<br>" +
        tab + "Training Klinik: %s<br>" +
        "<p>" +
        "<strong>Nächste Schritte</strong><br>" +
        "Sie können das<a href=\"https://de.camdiabtraining.com/\">CamAPS FX Training online durchführen</a> " +
        "oder Ihr zertifizierter Diabetesberater zeigt Ihnen persönlich, wie Sie die App verwenden. Sie können die CamAPS FX App vom Amazon Appstore herunterladen, aber Sie können sie erst verwenden, wenn Sie trainiert sind und Ihr Diabetesberater Ihre App freischaltet, indem er Ihnen den CamAPS FX Training-Code zur Verfügung stellt. Nach dem Training können Sie die CamAPS FX App sofort einsetzen." +
        "<p>" +
        "<strong>Bezahlung</strong><br>" +
        "Sobald die App freigeschaltet ist und Sie sie einsetzen können, werden wir Ihnen Ihre Rechnung per E-Mail zusenden. Fristgerechte Bezahlungen sind notwendig, um die CamAPS FX App weiter nutzen zu können. Das betrifft nicht Ihre gesetzlichen Rechte. Sie können Ihre Bestellung jederzeit vor dem Training stornieren oder innerhalb von 14 Tagen des Trainings, um Ihre Bezahlung erstattet zu bekommen." +
        "<p>" +
        "<strong>Abonnement-Zeitraum</strong><br>" +
        "Ihr Abonnement-Zeitraum startet mit dem Datum Ihres Trainings. Bitte beachten Sie, dass keine Zahlungen vor Beginn des Trainings vorgenommen werden. Nach dem ersten Abonnement-Zeitraum werden Zahlungen für jeden folgenden Abonnement-Zeitraum erforderlich, außer Sie kündigen Ihre Bestellung, indem Sie schreiben an %s." +
        "<p>" +
        "Vielen Dank für Ihre Bestellung. Wir hoffen, Sie profitieren vom Einsatz der CamAPS FX App." +
        "<p>" +
        "CamDiab Team<br>" +
        "%s",

    emailPotentialUser: '',

    emailInterestedUser:
        "Liebe(r) %s %s," +
        "<p>" +
        "Vielen Dank für Ihr Interesse am Einsatz der CamAPS FX. Wir haben Ihr Interesse registriert und werden in Kontakt treten, wenn CamAPS FX verfügbar wird in %s. Ihre Kontaktdaten:" +
        "<p>" +
        tab + "Name: %s %s<br>" +
        tab + "Kontakt E-Mail: %s<br>" +
        tab + "Land: %s<br>" +
        "<p>" +
        "Vielen Dank für Ihr Interesse am Einsatz der CamAPS FX App." +
        "<p>" +
        "CamDiab Team<br>" +
        "%s",
    emailOrderBody_1: 'Vielen Dank, dass Sie sich für den Einsatz der CamAPS FX App registriert haben',
    emailOrderBody_2: 'Liebe(r) %s',
    emailOrderBody_3: 'Wir freuen uns, Ihre Bestellung der CamAPS FX App zu bestätigen. Ihre Bestelldetails:',
    emailOrderBody_4: 'Name:',
    emailOrderBody_5: 'Kontakt E-Mail:',
    emailOrderBody_6: 'Pumpen-Seriennummer:',
    emailOrderBody_7: 'Abonnement-Zeitraum:',
    emailOrderBody_8: 'Kosten:',
    emailOrderBody_9: 'Mehrwertsteuerbefreiung:',
    emailOrderBody_10: 'Training Klinik:',
    emailOrderBody_11: 'Nächste Schritte',
    emailOrderBody_12: 'Sie erhalten eine Rechnung <b>nachdem</b> Sie mit dem Einsatz der CamAPS FX App begonnen haben.',
    emailOrderBody_13: 'Wir empfehlen Ihnen, das CamAPS FX Training online durchzuführen.',
    emailOrderBody_14: 'Alternativ wird Ihnen Ihr Diabetesberater persönlich zeigen, wie Sie die App einsetzen.',
    emailOrderBody_15: 'Sie können die CamAPS FX App vom Amazon Appstore herunterladen, aber Sie werden sie erst ' +
        'einsetzen können, wenn Sie trainiert sind und Ihr Diabetesberater oder Sie selbst die App freischalten, indem Sie ' +
        'seinen/Ihren zertifizierten CamAPS FX Training Code bereitstellen. ',
    emailOrderBody_16: 'Wenn die App freigeschaltet ist, können Sie sie sofort einsetzen.',
    emailOrderBody_17: 'Bezahlung',
    emailOrderBody_18: 'Nach dem Training werden wir Ihnen Ihre Rechnung per E-Mail zusenden. Fristgerechte Bezahlungen sind notwendig, um die CamAPS FX App weiter nutzen zu können. Das betrifft nicht Ihre gesetzlichen Rechte. Sie können Ihre Bestellung jederzeit vor dem Training stornieren oder innerhalb von 30 Tagen des Trainings, um Ihre Bezahlung erstattet zu bekommen.',
    emailOrderBody_19: 'Abonnement-Zeitraum',
    emailOrderBody_20: 'Ihr Abonnement-Zeitraum startet mit dem Datum Ihres Trainings. Bitte beachten Sie, dass keine Zahlungen vor Beginn des Trainings vorgenommen werden. Nach dem ersten Abonnement-Zeitraum werden Zahlungen für jeden folgenden Abonnement-Zeitraum erforderlich, außer Sie kündigen Ihre Bestellung, indem Sie schreiben an sales@camdiab.com.',
    emailOrderBody_21: 'Vielen Dank, dass Sie sich für den Einsatz der CamAPS FX App registriert haben',
    emailOrderBody_22: 'Für technischen Support, besuchen Sie unsere Webseite für Details.',
    emailOrderBody_23: '© 2022 CamDiab Ltd. Alle Rechte vorbehalten.',

    // Texts for step one form
    s1MonthlyHeading: 'Monatliches Abonnement',
    s1MonthlyValue: '£80*',
    s1Select: 'Auswählen',
    s1HalfYearlyHeading: 'Halbjährliches Abonnement',
    s1HalfYearlyText: '£450*',
    s1HalfYearlyTextSaving: '(£30 Einsparung)',
    s1YearlyHeading: 'Jahresabonnement',
    s1YearlyText: '£840*',
    s1YearlyTextSaving: '(£120 Einsparung)',
    s1VATtext: ' *Zuzüglich MwSt. außer bei Mehrwertsteuerbefreiung. Vollständig zu bezahlen nach dem Training / nach der Aktivierung der App.',

    // Texts for step two form
    previous: 'Zurück',
    next: 'Weiter',
    s2Yes: 'Ja',
    s2No: 'Nein',
    s2HaveDanaPump: 'Haben Sie eine mylife YpsoPump, DANA Diabecare RS oder DANA-i Pumpe?',
    s2HaveCgm: 'Haben Sie ein Dexcom G6 CGM System?',
    s2Phone1: 'Haben Sie ein kompatibles',
    s2PhoneHtml: 'https://www.dexcom.com/dexcom-international-compatibility',
    s2Phone2: 'Android Phone',
    s2Phone3: '?',
    s2NoPump: 'Um die CamAPS FX App nutzen zu können, benötigen Sie eine mylife YpsoPump, DANA Diabecare RS or DANA-i Insulinpumpe. Bitte kontaktieren Sie Ihr lokales Diabetes-Team und besprechen Sie Ihre Optionen oder kontaktieren Sie Ihren lokalen Dana-Händler.',
    s2NoCgm: 'Um die CamAPS FX App nutzen zu können, benötigen Sie ein Dexcom G6 CGM System. Bitte kontaktieren Sie Ihr lokales Diabetes-Team zum Dexcom G6 CGM System oder kontaktieren Sie Dexcom.',
    s2NoPhone: 'Ohne Einsatz eines kompatiblen Android Phones können wir nicht gewährleisten, dass eine zuverlässige Verbindung zum Dexcom G6 Sensor besteht. Erwägen Sie den Kauf eines kompatiblen Android Phones.',

    // Texts for step three form
    s3InfoSource: [
        'Klinik',
        'Suchmaschine',
        'Social media',
        'Eine Nachricht von einem Freund auf social media',
        'DDG',
        'Radio',
        'TV',
        'Presse',
        'Mündliche Empfehlung',
        'Andere'],

    // s3TeritoryQuestion: 'Wohnen Sie in UK, der EU oder Neuseeland?',
    // s3TeritoryQuestion: 'Wohnen Sie in der EU?',
    s3TeritoryQuestion: 'Wohnen Sie in UK?',
    s3NotAvailable: 'Leider ist die CamAPS FX App nur in den genannten Ländern verfügbar. Bitte geben Sie Ihre Kontaktdaten an, wenn Sie möchten, dass wir Sie kontaktieren, wenn das System in Ihrem Land verfügbar wird.',
    s3SelectCountry: 'Land auswählen',
    s3TC1: 'Mit der Einreichung Ihrer Anfrage stimmen Sie unseren',
    s3TC2: 'Allgemeinen Geschäftsbedingungen zu',
    s3TC3: 'und',
    s3TC4: 'den Datenschutzbestimmungen',
    s3TC5: 'und erlauben uns, Sie zu kontaktieren.',
    s3Training1: 'Sie müssen von einem zertifizierten Trainer trainiert werden oder das',
    s3Training2: 'Online-Training durchführen',
    s3Training3: 'bevor Sie die CamAPS FX App einsetzen können.',

    s3FirstName: 'Vorname',
    s3LastName: 'Nachname',
    s3Email: 'E-Mail-Adresse',
    s3EmailConfirm: 'E-Mail-Adresse bestätigen',
    s3AddressLine1: 'Adresse Zeile eins',
    s3AddressLine2: 'Adresse Zeile zwei',
    s3AddressLine3: 'Adresse Zeile drei',
    s3PostCode: 'Postleitzahl',
    s3SubmitCountry: 'Einreichen',
    s3DisabilityHeading: 'Ich erkläre, dass ich die folgende Behinderung oder chronische Erkrankung habe:',
    s3Disability: 'Behinderung oder chronische Erkrankung',
    s3signature: 'Unterschrift (geben Sie Ihren Namen ein)',

    s3TrainingCdep1: 'Wählen Sie CamAPS Online-Training, um fortzufahren:',
    s3TrainingCdep2: 'Training auswählen',
    s3TrainingCdep3: 'CamAPS Online-Training',

    s3contactHeading: 'Bitte geben Sie Ihre Kontakdaten ein',
    s3whereHeard: 'Woher kennen Sie die CamAPS FX App?',
    s3whereHeardSelect: 'Wählen Sie eines der folgenden aus',
    s3pumpSerialNumber: 'Seriennummer Ihrer Dana Pumpe:',
    s3pumpSerialNumberConfirm: 'Bestätigen Sie die Seriennummer Ihrer Dana Pumpe:',
    s3pumpSerialNumberMessage: 'Wichtig:',
    s3pumpSerialNumberMessage1: 'Die Seriennummer der Pumpe wird benötigt, damit die CamAPS FX App sich mit Ihrer Pumpe verbinden kann. Eine falsche Seriennummer kann dazu führen, dass die CamAPS FX App sich nicht mit Ihrer Pumpe verbinden kann. Sie finden die Seriennummer an der Seite Ihrer Pumpe. Eine Beispielnummer wäre THF00041FB.',
    s3vatExempt: 'Sind Sie von der Mehrwertsteuer befreit?',
    s3vatHelp1: 'Sie können mehr erfahren auf der',
    s3vatHelpHtml: 'https://www.gov.uk/government/organisations/hm-revenue-customs/contact/vat-reliefs-for-disabled-and-older-people',
    s3vatHelpLine: 'Hinweis MwSt-Befreiung',
    s3vatHelp2: 'GOV.UK Website',
    s3vatHelp3: 'oder rufen Sie die VAT Disabled Reliefs Beratungsstelle an unter +44 300 123 1073.',
    s3vatHelp4: 'HMRC Mitarbeiter können keine Auskunft geben, ob eine Person chronisch krank oder behindert ist oder ob nicht.',
    s3vatHelp5: 'Wichtig:',
    s3vatHelp6: 'Wenn Sie die CamAPS FX App für jemand anderen bestellen, füllen Sie bitte die MwSt-Befreiung mit dessen Kontaktdaten aus.',
    s3PhoneWarning: 'Ohne Einsatz eines kompatiblen Android Phones können wir nicht gewährleisten, dass eine zuverlässige Verbindung zum Dexcom G6 Sensor besteht. Erwägen Sie den Kauf eines kompatiblen Android Phones',

    s3NameMissing: 'Vorname wird benötigt',
    s3SurnameRequired: 'Nachname wird benötigt',
    s3EmailRequired: 'E-Mail wird benötigt',
    s3CountryRequired: 'Land wird benötigt',
    s3EmailConfimrRequired: 'Bestätigung der E-Mail wird benötigt',
    s3EmailMatchRequired: 'E-Mail muss übereinstimmen',
    s3ClinicRequired: 'Schulende Klinik wird benötigt',
    s3DiseaseStateRequired: 'Art des Nutzers muss genannt werden',
    s3OnlyTextValueProvided: 'Enthält nur Textwert',
    s3OnlyNumberValueProvided: 'Enthält nur Zahlen',
    s3SerialNumbersMatch: 'Seriennummer muss übereinstimmen',
    s3vatStatusRequired: 'MwSt.-Status muss bereitgestellt werden',
    s3AddressLine1Required: 'Adresszeile eins wird benötigt',
    s3AddressLine2Required: 'Adresszeile zwei wird benötigt',
    s3PostCodeRequired: 'Postleitzahl wird benötigt',
    s3SickessRequired: 'Behinderung oder chronische Erkrankung wird benötigt',
    s3SignatureRequired: 'Unterschrift wird benötigt',

    // Texts for step four form
    s4NextSteps: 'Nächste Schritte',
    s4DownloadApp: 'Sie können die CamAPS FX App herunterladen von',
    s4DownloadGuideHtml: 'https://s3-eu-west-1.amazonaws.com/camdiab.user.manual/Downloading+CamAPS+FX+DE.pdf ',
    s4DownloadAppGuide: 'Installationsanleitung',
    s4Appstore: 'Amazon Appstore',
    s4DownloadApp1: 'aber Sie werden sie nicht einsetzen können, bevor Sie nicht',
    s4TrainingTextLink: 'trainiert sind',
    s4TrainingHtml: 'https://camdiab.com/de/training',
    s4TrainingTitle: 'Training',

    s4Payment: 'Zahlungen erfolgen bei Verbindung der CamAPS FX App mit Ihrer Pumpe.',

    s4TermsAccept: 'Ich akzeptiere',
    s4TermsTermsHtml: 'https://camdiab.com/de/terms',
    s4TermsTermsText: 'Allgemein Geschäftsbedingungen',
    s4TermsPrivacyHtml: 'https://camdiab.com/de/privacy',
    s4TermsPrivacyText: 'Datenschutzbestimmungen',

    s4DiscountCodeText: 'Discount-Code (optional)',
    s4DiscountApplyCode: 'Anwendungs-Code',

    s4Summary: 'Zusammenfassung der Bestellung',
    s4Name: 'Name:',
    s4Email: 'E-Mail:',
    s4PumpSN: 'Pumpen-SN:',
    s4Training: 'Training:',
    s4Total: 'Gesamt',

    s4CompleteButtonText: 'Abschließen',

    // email content
    emailOneMonth: '1 Monat',
    emailSixMonths: '6 Monate',
    emailTwelveMonths: 'jährlich',

    // Order complete
    oderProcessedHeading: 'Ihre Bestellung wird bearbeitet',
    oderProcessedSucess: 'Vielen Dank, Ihre Bestellung war erfolgreich',
    oderProcessedError: 'Bearbeitungsfehler',
    oderProcessedEmailToBeSent: 'Sie werden in Kürze eine Bestätigungs-E-Mail erhalten',

};

