/**
 * Texts for History page
 */
export const historyPageText = {
    title: 'Viaje CamDiab',
    titleMeta: 'La investigación clínica en la ruta hacia CamAPS FX',
    descriptionMeta: 'Un cronograma del camino que siguió el equipo de CamAPS FX para asistir a los diabéticos con nuestro algoritmo Cambridge.',
    localeMeta: 'es_es',

    keywords:
        'historia de camaps, historia de camaps fx, ',
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ',
    journeyHeading: 'Nuestro viaje CamDiab',
    timelineHeading: 'Un cronograma del camino que siguió el equipo de CamAPS FX para asistir a los diabéticos con nuestro algoritmo Cambridge.',
    year2006_2010dates: '2006–2010',
    year2006_2010heading: 'Pruebas intrahospitalarias tempranas',
    year2006_2010text: 'Inicio del proyecto de páncreas artificial en Cambridge con ensayos en niños con diabetes de tipo 1. Asociación con el Consorcio de Páncreas Artificial de la JDRF. Algoritmo Cambridge sometido a ensayos en condiciones extremas tras el ejercicio y el consumo de alcohol en adultos con diabetes de tipo 1. Evaluación del algoritmo Cambridge en embarazadas con diabetes de tipo 1.',
    year2011_2014dates: '2011–2014',
    year2011_2014heading: 'Pruebas de viabilidad solo nocturnas y sin limitaciones',
    year2011_2014text: 'Uso nocturno del algoritmo Cambridge evaluado por niños, adultos y embarazadas con diabetes de tipo 1. Algoritmo Cambridge utilizado día y noche durante siete días por adultos con diabetes de tipo 1.',
    year2015_2016dates: '2015–2016',
    year2015_2016heading: 'Pruebas breves sin limitaciones nocturnas y diurnas',
    year2015_2016text: 'Algoritmo Cambridge utilizado por adultos bien controlados con diabetes de tipo 1 durante cuatro semanas. Algoritmo Cambridge utilizado por adolescentes con diabetes de tipo 1 durante tres semanas.',
    year2017_2018dates: '2017–2018',
    year2017_2018heading: 'Pruebas diurnas y nocturnas más prolongadas y para niños pequeños',
    year2017_2018text: 'Algoritmo Cambridge utilizado durante tres meses por niños y adultos con diabetes de tipo 1. Algoritmo Cambridge utilizado durante 2 años por niños y adolescentes a partir de la aparición de la diabetes. Algoritmo Cambridge utilizado en niños pequeños de uno a siete años con diabetes de tipo 1.',
    year2019_2019dates: '2019',
    year2019_2019heading: 'Transición a CamAPS FX ',
    year2019_2019text: 'App CamAPS FX utilizada por adultos mayores con diabetes de tipo 1. App CamAPS FX utilizada durante el embarazo por embarazadas con diabetes de tipo 1. App CamAPS FX utilizada en niños de edades comprendidas entre uno y siete años con diabetes de tipo 1.',
    year2020_2020dates: 'A partir de 2020',
    year2020_2020heading: 'Aprobación reglamentaria, comercialización, ensayos clínicos',
    year2020_2020text: 'La app CamAPS FX recibe la marca CE para su uso en la UE y en el Reino Unido. La investigación sigue evaluando los beneficios y abogando por el reembolso.',
};
