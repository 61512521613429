/**
 * Texts for Sam's review article
 */
export const samReviewText = {
    title: 'Sam et Sofia-Stevie - CamAPS FX',
    titleMeta: 'Lire la critique de CamAPS FX par Sam et Sofia-Stevie',
    descriptionMeta: 'CamAPS FX a changé la vie de Sam et Sofia-Stevie, permettant à ' +
        'Sam de se détendre pour la première fois depuis le diagnostic.',
    keywords:
        'camaps review Sam et Sofia-Stevie, camaps fx review Sam et Sofia-Stevie, ' +
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ' +
        '',
    localeMeta: 'fr_fr',

    imageTitle: 'Sam and Sofia-Stevie',
    shortIntroAndBody:
        '<h2>Pour la première fois depuis le diagnostic, j’ai l’impression que je peux enfin souffler</h2>' +
        '<p>' +
        'Je fais entièrement confiance à l’application CamAPS FX et pour la première fois depuis le diagnostic, j’ai l’impression que je peux enfin souffler. Sans aucun doute, Sofia-Stevie passe plus de temps dans sa cible glycémique. ' +
        '</p>',
    body:
        '<h2> Pour la première fois depuis le diagnostic, j’ai l’impression que je peux enfin souffler </h2> ' +
        '<p> ' +
        '<strong> ' +
        'Ces deux dernières années, Sam, la maman de Sofia-Stevie (5 ans), a dû gravir cette si raide montagne de nouvelles choses à apprendre que tous les parents d’enfants diabétiques de type 1 doivent affronter. Après une avalanche de piqûres au bout du doigt, d’injections et de capteurs, elle a découvert l’application CamAPS FX et le système en boucle fermée hybride, et elle ne pourrait plus s’en passer. ' +
        '</strong> ' +
        '</p> ' +
        '<p> ' +
        'Avec les fortes chaleurs estivales, une soif excessive n’a rien d’extraordinaire chez un jeune enfant. ' +
        'Cependant, Sam et sa maman ont suivi leur intuition : et si c’était autre chose&nbsp;? ' +
        'Après avoir commencé à chercher s’il y avait des raisons de s’inquiéter, c’est finalement le diabète de type 1 de Sofia-Stevie qui a été diagnostiqué. ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '«&nbsp;Le diagnostic a tout changé, à tout jamais. Je me souviens des premières semaines comme si c’était hier. Du jour au lendemain, ou presque, j’ai senti que je devais devenir une experte du diabète pour m’occuper au mieux de ma fille. Elle est ma priorité absolue, alors je l’ai fait, et je suis vraiment fière de moi et de ma mère pour ce qu’elle a fait. Dès le début, elle nous a rejoint dans cette aventure.&nbsp;» ' +
        '    </i> ' +
        '</p> ' +
        '<h3> ' +
        '    Une montagne de nouvelles choses à apprendre ' +
        '</h3> ' +
        '<p> ' +
        'Comme la plupart des parents avec un enfant diabétique de type 1 nouvellement diagnostiqué, Sam est rapidement devenue une experte des piqûres au bout du doigt, des dosages d’insuline pour les bolus et les débits de base, ou de la marche à suivre quand sa fille était en état d’hypoglycémie. Faire des injections à son enfant, c’est un défi pour tous les parents, mais encore plus pour Sam, qui a dû vaincre sa peur des aiguilles. ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '«&nbsp;L’équipe du service de diabétologie a été extraordinaire pour m’aider à surmonter pas mal de gros problèmes, dans les premiers temps. Je ne crois pas qu’on serait là où on en est aujourd’hui sans le soutien de l’équipe clinique.&nbsp;» ' +
        '    </i> ' +
        '</p> ' +
        '<p> ' +
        'Dans les premiers mois qui ont suivi le diagnostic de la maladie chez Sofia-Stevie, les tests capillaires par piqûre au bout du doigt étaient régulièrement l’occasion de mieux comprendre les taux de glycémie au cours d’une journée entière. La nuit, Sam devait aussi faire déclencher des alarmes pour pouvoir vérifier la glycémie de sa fille. Si la glycémie de Sofia-Stevie était élevée ou basse, Sam devait administrer une dose de correction et attendre jusqu’au moment où la glycémie évoluait favorablement pour pouvoir redormir, un processus usant pour toutes les deux, répété chaque nuit. ' +
        '</p> ' +
        '<p> ' +
        'Finalement, Sofia-Stevie a reçu un système de mesure du glucose en continu (MGC) dans le cadre de sa prise en charge. Un système de MGC est un appareil compact équipé d’un capteur placé juste sous la peau, destiné à mesurer en continu e taux de glucose. Pour Sam, la MGC était un pas dans la bonne direction, en particulier parce que cet appareil signifiait qu’elle n’avait plus besoin des alarmes nocturnes : l’appareil l’alerte automatiquement si la glycémie de Sofia-Stevie n’est plus dans les valeurs cibles. Le système de MGC se synchronisant également avec le smartphone de Sam, elle peut également vérifier la glycémie de Sofia-Stevie quand elle est à l’école, ce qui contribue aussi à aider les enseignants dans la prise en charge au quotidien. ' +
        '</p> ' +
        '<p> ' +
        '    Mais en janvier 2020, Sam a découvert l’application CamAPS FX et aujourd’hui elle ne voudrait plus revenir en arrière. ' +
        '</p> ' +
        '<h3> ' +
        '    Retrouver la liberté ' +
        '</h3> ' +
        '<p> ' +
        ' L’application CamAPS FX a été créée pour simuler les fonctions clés d’un pancréas en bonne santé, ce qu’on appelle une approche en boucle fermée hybride dans le traitement du diabète. L’application communique de façon autonome avec le système de MGC et la pompe à insuline du patient, en utilisant un algorithme complexe pour calculer automatiquement et administrer la dose optimale d’insuline toutes les 8 à 12 minutes. ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '«&nbsp;Je n’avais jamais entendu parler de système en boucle fermée hybride auparavant, ni de l’application CamAPS FX. Je ne savais pas comment ça fonctionnait, mais quand notre équipe clinique a suggéré que cela pourrait nous être bénéfique, nous n’avons pas hésité. A ce stade, j’avais déjà appris tellement de choses sur le diabète que découvrir la boucle fermée hybride, ça a été simplissime !”&nbsp;» ' +
        '    </i> ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '«&nbsp;Je fais entièrement confiance à l’application CamAPS FX et pour la première fois depuis le diagnostic, j’ai l’impression que je peux enfin souffler. Sans aucun doute, Sofia-Stevie passe plus de temps dans sa cible glycémique. C’est tellement plus facile de contrôler sa glycémie, maintenant. Ca m’enlève vraiment un poids des épaules. C’est aussi moins de charges pour ses instituteurs et beaucoup moins d’inquiétudes pour moi. Si je veux, je peux vérifier à distance, mais je suis aussi rassurée de savoir que je reçois automatiquement des messages si elle part en hyper ou en hypo.&nbsp;» ' +
        '    </i> ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '«&nbsp;On ne devinerait jamais qu’elle est différente de ses camarades de classe et c’est grâce à l’application CamAPS FX.&nbsp;» ' +
        '    </i> ' +
        '</p> ' +
        '<p> ' +
        'Sam n’est pas la seule à observer les changements que l’application CamAPS FX produit sur les patients et leurs proches. La recherche a montré que l’application améliore le sommeil des adolescents et procure la sensation d’un réveil normal, ce qui facilite le contrôle du diabète au cours de la journée et contribue à la concentration et au bien-être à l’école. De plus, il a été démontré que les parents utilisant l’application sont moins anxieux au sujet du diabète de leur enfant, et par conséquent plus enclins à donner plus de liberté à leur enfant, comme par exemple les laisser s’amuser avec leurs amis, les laisser partir en voyage scolaire ou faire des soirées pyjama. ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '«&nbsp;C’est si logique pour les enfants de l’âge de Sofia-Stevie d’avoir une boucle fermée, parce que leur corps est constamment en train de changer la façon dont ils réagissent à l’insuline. Pour les parents, c’est extrêmement difficile de rester en contrôle sans qu’il y ait des écarts significatifs, alors que l’application s’informe en permanence et s’adapte instantanément. C’est un bienfait dans la prise en charge à long terme de la maladie de l’enfant, et une liberté pour les parents, quand tout le monde peut dormir la nuit.&nbsp;» ' +
        '    </i> ' +
        '</p> ' +
        '<h3> ' +
        '    Je ne pourrais plus me passer de l’application ' +
        '</h3> ' +
        '<p> ' +
        '    <i> ' +
        '«&nbsp;Je ne pourrais plus me passer de l’application. C’est quelque chose qui veille sur vous et qui fait que vous n’avez plus à vous inquiéter. A tous ceux qui s’interrogent, je dis “allez-y” ! Ca change la donne : une fois que vous l’avez, vous ne pouvez plus faire marche arrière ou vous en passer. Je suis pleinement convaincue que ça apporte tellement, aussi bien aux parents qu’aux enfants.&nbsp;» ' +
        '    </i> ' +
        '</p>'

};

/**
 * Texts for Roger's review article
 */
export const rogerReviewText = {
    title: 'Roger - CamAPS FX',
    titleMeta: 'Lire la critique de CamAPS FX par Roger',
    descriptionMeta: 'Roger commente que CamAPS FX peut être utilisé par n’importe qui et à n’importe quel âge.',
    keywords:
        'camaps avis Roger, camaps fx avis Roger, ' +
        // 'type 1 diabetes, autoimmune disease, hyperglycemia, dexcom g6, type 1 diabetes symptoms, blood sugar monitor, diasend, insulin pump, diabetes type 1, blood glucose monitor, blood sugar, cgm, high blood sugar, type 1 diabetes causes, what is type 1 diabetes, diabetes in children, type 1, diabetes treatment, type 1 diabetes treatment, what is hypoglycemia, hypoglycemia treatment, jdrf, blood glucose meters, glucose monitor, type one diabetes, continuous glucose monitoring, type 1 diabetes in children, diabetes pump, t1d, closed loop system, diabetes machine, diabetic gastroparesis, diabetic hypoglycemia, diasend uk, diabetes 1, blood glucose monitor boots,  ' +
        '',
    localeMeta: 'fr_fr',

    imageTitle: 'Roger',
    shortIntroAndBody:
        '<h2>Si je peux le faire, tout le monde peut le faire !</h2>' +
        '<p>' +
        'C’est impressionnant à quel point le traitement a changé depuis mon diagnostic. C’est comme si j’étais passé du désert au monde digital ! A tous ceux qui se posent des questions sur l’application CamAPS FX, mon conseil est simple : allez-y ! Ca change la vie et si je peux le faire, tout le monde peut le faire !' +
        '</p>',
    body:
        '<h2>Si je peux le faire, tout le monde peut le faire</h2> ' +
        '<p> ' +
        '<strong> ' +
        'Avec un diabète de type 1 diagnostiqué à l’âge adulte, Roger (69 ans), directeur d’école à la retraite, a passé les 39 dernières années à la recherche d’une meilleure compréhension de sa maladie. Dans son expérience personnelle, il a connu la transformation progressive de la prise en charge du diabète au fil du temps, des seringues en verre aux grosses aiguilles stérilisées dans une casserole d’eau bouillante, jusqu’à l’application CamAPS FX et au système en boucle fermé hybride, desquels il ne pourrait plus se passer.   ' +
        '</strong> ' +
        '</p> ' +
        '<p> ' +
        'Aujourd’hui, le diabète de type 1 est souvent diagnostiqué dans l’enfance, mais pour Roger, les symptômes ne sont devenus apparents qu’à l’âge de 30 ans. Un oncle et la belle-mère de Roger ayant déjà été diagnostiqués avec un diabète de type 1 auparavant, il en avait déjà une certaine connaissance des symptômes. ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '«&nbsp;Je me souviens très bien : je venais de prendre mon petit déjeuner et je faisais des ' +
        'bricoles dans le jardin. Je suis rentré à l’intérieur et j’avais une soif phénoménale. J’ai ' +
        'descendu plus d’un demi-litre de jus d’une traite. Ma femme et moi nous sommes regardés et en ' +
        'même temps, on a dit “diabète”. Il a fallu un peu convaincre mon généraliste, mais il m’a envoyé faire des tests qui ont confirmé que j’étais diabétique.&nbsp;»' +
        '    </i> ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '«&nbsp;A l’époque, les connaissances et le traitement du diabète étaient très limités par rapport à aujourd’hui. On m’a juste donné des seringues en verre, expliqué comment stériliser les aiguilles dans de l’eau bouillante dans une casserole et je suis reparti avec une quantité cible d’insuline. Heureusement, ma belle-mère m’avait prêté son manuel sur le diabète et je nous revois, ma femme et moi. Elle, tenant les pages du livre ouvertes, alors que je m’apprenais moi-même à faire les injections.&nbsp;»' +
        '    </i> ' +
        '</p> ' +
        '<h3> ' +
        'A la recherche d’un meilleur contrôle  ' +
        '</h3> ' +
        '<p> ' +
        'Pendant ces premières années, Roger a beaucoup bénéficié des ressources mises à disposition par les associations de patients diabétiques, comme par exemple lorsqu’il a appris qu’il devait s’administrer plus d’insuline quand il était malade, chose qu’il ignorait auparavant. ' +
        'Roger s’occupait correctement de sa maladie, mais quand sa glycémie est devenue plus difficile à maîtriser, il fut envoyé suivre une formation pour apprendre à faire correspondre ses doses d’insuline à son alimentation et à son style de vie. Néanmoins, Roger continuait à présenter des épisodes d’hypoglycémie sévères.  ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '«&nbsp;Je gardais mon petit-fils et je jouais au football avec lui dans le jardin. Mon gendre vient le chercher et dit d’un coup : “Roger, il faut que tu boives un verre de jus”. En effet, je me pique le doigt et ma glycémie était très basse, bien plus basse que là où elle aurait dû être. Je devais garder mon petit-fils le lendemain, mais ils ont pris d’autres dispositions. Ils ont pensé que je n’étais pas en état de m’occuper de lui. Ca m’a dévasté et j’ai décidé que ça ne pouvait pas continuer comme ça.&nbsp;»' +
        '    </i> ' +
        '</p> ' +
        '<p> ' +
        'Après avoir rapporté cette histoire à son médecin, Roger a été enrôlé dans un projet de recherche portant sur les événements hypoglycémiques non expliqués et a alors commencé à utiliser une pompe à insuline. Immédiatement, la pompe a contribué à améliorer la glycémie de Roger. ' +
        '</p> ' +
        '<p> ' +
        'Puisqu’il avait bénéficié de ce premier projet de recherche, lorsque Roger s’est vu offrir une chance de participer à un autre, il a sauté sur l’occasion et a commencé à utiliser l’application CamAPS FX en octobre 2019. ' +
        '</p> ' +
        '<p> ' +
        'L’application CamAPS FX a été créée pour simuler les fonctions clés d’un pancréas en bonne santé, ce qu’on appelle une approche en boucle fermée hybride dans le traitement du diabète. L’application communique de façon autonome avec le système de mesure du glucose en continu et la pompe à insuline du patient, en utilisant un algorithme complexe pour calculer automatiquement et administrer la dose optimale d’insuline toutes les 8 à 12 minutes.' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '«&nbsp;Bien sûr, je n’avais jamais entendu parler d’un système en boucle fermée hybride auparavant, mais quand mes deux enfants ont entendu que j’allais gérer mon diabète depuis un smartphone, ils ont éclaté de rire ! Avant le programme de recherche, tout ce que je savais faire avec mon téléphone, c’était l’allumer, passer un coup de fil, puis l’éteindre. En réalité, avec le soutien de l’équipe de mon hôpital, toujours très compétente, j’ai pu facilement m’habituer au smartphone et à l’application CamAPS FX. Sa faculté à simplement contrôler et traiter le diabète est tout bonnement formidable. C’est une vraie libération.&nbsp;» ' +
        '    </i> ' +
        '</p> ' +
        '<h3> ' +
        '    Discrétion totale ' +
        '</h3> ' +
        '<p> ' +
        'Dès qu’il a commencé à utiliser l’application CamAPS FX, Roger a diminué la quantité d’insuline de ses bolus et il a noté une réduction des quantités d’insuline utilisées chaque jour. Mais Roger a aussi constaté d’autres bénéfices.  ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '«&nbsp;Maintenant, quand on va manger dehors, tout ce que je dois faire, c’est prendre mon smartphone, saisir la quantité de glucides et ça marche tout seul. Je peux voir ma glycémie instantanément et tout ce que j’ai à faire, je peux le faire depuis mon téléphone. Avant d’avoir l’application, j’avais besoin d’emporter avec moi des aiguilles et de l’insuline, d’aller aux toilettes pour me piquer le doigt et faire mes injections, si nécessaire. Maintenant, tout ça fonctionne tout seul, dans une discrétion totale.&nbsp;» ' +
        '    </i> ' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '«&nbsp;Une autre fois où l’application nous a bien aidés, c’est au début de la pandémie de COVID-19, quand j’avais commencé à aller vraiment mal. J’imaginais le pire, mais l’application fournissait des mesures en permanence de ma glycémie, ce qui était vraiment rassurant. Un jour, je me suis endormi et mon épouse craignait que je tombe dans un coma diabétique. Elle a discrètement vérifié ma glycémie sur mon téléphone pendant que je dormais et elle a pu voir que tout allait bien. Grâce à l’application, on peut avoir l’esprit tranquille, ce qui est un avantage pour ma qualité de vie, bien sûr, mais aussi celle de ma femme.&nbsp;»' +
        '    </i> ' +
        '</p> ' +
        '<p> ' +
        'Ce sentiment d’être rassuré et cette discrétion ne sont pas des choses que seuls Roger et sa femme connaissent. Des recherches ont démontré que l’application CamAPS FX aide les patients à se sentir moins accablés par leur diabète et renforce leur capacité à mener leur vie de façon plus flexible et spontanée. ' +
        '</p> ' +
        '<p> ' +
        'Les utilisateurs mettent également en avant l’application pour son rôle de filet de sécurité, avec une réactivité qui dépasse leurs propres capacités. Les utilisateurs ont toutefois la possibilité de prendre le contrôle de l’application, si jamais ils souhaitaient alléger ou intensifier le traitement en raison de demandes physiologiques particulières, comme en cas d’exercice ou de maladie.' +
        '</p> ' +
        '<p> ' +
        '    <i> ' +
        '«&nbsp;C’est impressionnant à quel point le traitement a changé depuis mon diagnostic. C’est comme si j’étais passé du désert au monde digital ! A tous ceux qui se posent des questions sur l’application CamAPS FX, mon conseil est simple : allez-y ! Ca change la vie et si je peux le faire, tout le monde peut le faire !&nbsp;» ' +
        '    </i> ' +
        '</p>'


};