import React, {Component} from 'react';
import {Header} from './Header';
import {Footer} from './Footer';
import {Helmet} from "react-helmet";

export class PrivacyDE extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Datenschutz - CamAPS FX</title>
                    <meta name="title" content='Datenschutz-Bestimmungen'/>
                    <meta name="description"
                          content='Hier finden Sie die Datenschutzrichtlinie von CamDiab'/>
                    <meta property="og:locale" content='de'/>
                    <meta name="keywords" content='Datenschutz-Bestimmungen'/>
                </Helmet>
                <Header props={this.props}/>
                <section className="inner-banner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-10">
                                <h1>Datenschutzbestimmungen</h1>
                                <h3>Version vom 24. Juni 2024</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner-page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <h2>
                                    1. EINLEITUNG
                                </h2>
                                <h3>
                                    1.1. Verantwortliche Stelle
                                </h3>
                                <p>
                                    CamDiab Limited, eingetragener Firmensitz 20-22 Wenlock Road, London, N1 7GU, Vereinigtes Königreich, gegründet und eingetragen in England und Wales unter der Unternehmensnummer 11659211 („CamDiab“), ist die verantwortliche Stelle gemäß den Datenschutzgesetzen. CamDiab entscheidet über Zweck und Mittel der Verarbeitung der personenbezogenen Daten unserer Benutzer („<strong>Benutzerdaten</strong>“) und ist somit für deren Schutz und die Einhaltung der geltenden Gesetze verantwortlich.
                                </p>
                                <p>
                                    1.1.2 Als verantwortliche Stelle unterliegen wir beispielsweise einer Informationspflicht, der wir mit dieser Datenschutzerklärung nachkommen möchten.
                                </p>
                                <h3>
                                    1.2. Struktur und Konzept der Einwilligung
                                </h3>
                                <p>
                                    1.2.1 Diese Datenschutzerklärung informiert Sie über die Zwecke und den Umfang der Verarbeitung Ihrer Benutzerdaten sowie über Datenübermittlungen und Ihre umfassenden Rechte. Da sich unser Angebot ausschließlich an Personen mit Diabetes richtet, stellen Sie typischerweise bereits durch Ihre Nutzung Informationen über Ihren Gesundheitszustand zur Verfügung. Aus diesem Grund verarbeiten wir Benutzerdaten ausschließlich als Gesundheitsdaten und mit Ihrer Einwilligung. Wir unterscheiden wie folgt:
                                </p>
                                <p>
                                    1.2.1.1 „Erforderliche Verarbeitung“ beschreibt, wie wir die Benutzerdaten verarbeiten, die erforderlich sind, um den Vertrag zu erfüllen. Ohne die Einwilligung in diese Verarbeitung ist die Nutzung unserer Produkte in rechtlicher und faktischer Hinsicht nicht möglich, da wir zur Erbringung unserer Leistungen auf diese Verarbeitung angewiesen sind.
                                </p>
                                <p>
                                    1.2.1.2 „Verarbeitung zur Produktverbesserung“ beschreibt, wie Sie uns und anderen Benutzern – mit Ihrer Einwilligung – helfen können, indem Sie uns die Erlaubnis erteilen, Ihre Daten insbesondere zur Verbesserung der Therapiealgorithmen, zur Verbesserung des Produkts usw. zu nutzen, ohne dass wir Sie dabei kontaktieren.
                                </p>
                                <p>
                                    1.2.1.3 „Verarbeitung zu Marketingzwecken“ beschreibt, wie wir Sie mit Ihrer Einwilligung zu Marketingzwecken kontaktieren, z. B. per E-Mail, über Benachrichtigungen usw.
                                </p>
                                <p>
                                    1.2.1.4 Um Wiederholungen zu vermeiden, haben wir unter „Allgemeine Informationen“ die Informationen zusammengetragen, die für alle oben genannten Arten der Verarbeitung gelten.
                                </p>
                                <p>
                                    Die einzelnen Kategorien sind nachfolgend ausführlicher beschrieben. Die entsprechende Einwilligung müssen Sie erteilen, wenn Sie sich registrieren oder Ihr Produkt bestellen.
                                </p>
                                <p>
                                    1.2.2 In manchen Fällen kann die Verarbeitung unabhängig von Ihrer Einwilligung auf Grundlage gesetzlicher Regelungen (z. B. Medizinproduktegesetz) stattfinden.
                                </p>
                                <h2>
                                    2. ERFORDERLICHE VERARBEITUNG
                                </h2>
                                <p>
                                    Wenn Sie Ihre Einwilligung erteilen, verarbeiten wir die nachfolgend aufgeführten Benutzerdaten, um unsere Leistungen erbringen zu können. Wenn Sie nicht in diese erforderliche Verarbeitung einwilligen, können Sie die Leistungen von CamDiab nicht nutzen. Ihre Einwilligungen können Sie während des Registrierungsprozesses oder bei der Bestellung Ihres Produkts erteilen.
                                </p>
                                <h3>
                                    2.1. Erforderliche Benutzerdaten
                                </h3>
                                <p>
                                    2.1.1 Zum Schutz Ihrer Benutzerdaten können unsere Leistungen nur in Verbindung mit einem Benutzerkonto in Anspruch genommen werden. Zur Erstellung eines Benutzerkontos benötigen und verarbeiten wir die folgenden Benutzerdaten:
                                </p>
                                <ul type="disc">
                                    <li>
                                        E-Mail-Adresse
                                    </li>
                                    <li>
                                        Passwort (wir speichern lediglich eine Prüfsumme)
                                    </li>
                                    <li>
                                        Name
                                    </li>
                                    <li>
                                        Erklärung zur Mehrwertsteuerbefreiung (Name, Adresse, Datum; für Selbstzahler im Vereinigten Königreich)
                                    </li>
                                    <li>
                                        Geburtsjahr
                                    </li>
                                    <li>
                                        Datum der Registrierung
                                    </li>
                                    <li>
                                        Einwilligungsstatus
                                    </li>
                                    <li>
                                        Produktkennung, Hersteller, Produkttyp, Version des Betriebssystems, App-Version
                                    </li>
                                    <li>
                                        Sprache, Land, Zeitzone.
                                    </li>
                                </ul>
                                <p>
                                    2.1.2 Weitere Informationen, die auf anderem Wege als über die Benutzerregistrierung erhoben werden, sind:
                                </p>
                                <p>
                                    <strong>Medizinische Stammdaten </strong>
                                    <br/>
                                    Gewicht, Blutzucker-Zielbereich, Basal-Einstellungen der Pumpe, Korrekturfaktoren, Kohlenhydrate/Insulin-Verhältnis, Insulin-Wirkdauer, Zielglukose, Alarmeinstellungen, Bolusschrittweite, Kohlenhydrate-Einheit, Schwangerschaftsstatus, voraussichtliches Geburtsdatum.
                                </p>
                                <p>
                                    <strong>Nutzungs- und Verknüpfungsdaten</strong>
                                    <br/>
                                    Seriennummer von Pumpe und Sender, Pumpen-, Sensor- und Closed-Loop-/Open-Loop-Aktionsprotokoll, Flussprotokoll der App-Bedienung, Ereignisprotokoll, in die App eingegebene Follower-Informationen (Telefonnummer, Anmeldedaten für Konten bei Diabetes-Datenportalen), Supportanfragen, Trainer-Informationen, ID der Diabetesklinik.
                                </p>
                                <p>
                                    <strong>Medizinische Daten</strong>
                                    <br/>
                                    App-Eingaben wie Datum/Uhrzeit/Zeitzone/Ort, Nahrungsaufnahme/Mahlzeit, Blutzucker- und Sensorglukosemessungen, Änderungsrate der Sensorglukose, tägliche Gesamtinsulindosis, Insulinzufuhr (Bolus und Basal), temporäre Basalrate, Closed-Loop-Status, Vorbereitung von Pumpe und Kanüle, Alarme, Boost- und Ease-off-Ereignisse, App-Einstellungen wie Anzeigeoptionen, statistische Zusammenfassungen von Glukose- und Insulindaten.
                                </p>
                                <p>
                                    2.1.3 Wenn Sie möchten, können Sie eine beliebige E-Mail-Adresse angeben, die Sie speziell für uns einrichten – diese muss jedoch funktionieren, damit wir Ihnen wichtige Warnmeldungen zusenden können.
                                </p>
                                Wir verarbeiten nur Benutzerdaten, die Sie CamDiab aktiv und freiwillig bereitstellen oder die über den Glukosesensor und die Insulinpumpe, mit denen Sie sich freiwillig verbinden, erhoben werden. Die Eingabe der erforderlichen Benutzerdaten ist jedoch eine Voraussetzung dafür, dass Sie unsere Produkte vollumfänglich nutzen können.
                                <h3>
                                    2.2. Erforderliche Zwecke
                                </h3>
                                <p>
                                    2.2.1 Alle erforderlichen Zwecke unserer Verarbeitung stehen mit der Erbringung unserer Leistungen in Zusammenhang:
                                </p>
                                <p>
                                    <strong>Kundenanfragen</strong> führen zur Erfassung von Kontaktdaten.
                                </p>
                                <p>
                                    <strong>Bestellungen</strong> unserer Apps von Selbstzahlern führen zur Erfassung von Kontaktdaten und technischen Daten wie der Produktkennung.
                                </p>
                                <p>
                                    <strong>Installationen</strong> unserer Apps führen zur Erfassung technischer und produktbezogener Daten.
                                </p>
                                <p>
                                    <strong>Registrierungen</strong> führen zur Erstellung von CamDiab-Konten unter Verwendung von E-Mail-Adresse und Passwort.
                                </p>
                                <p>
                                    Die <strong>Erbringung unserer Leistungen</strong> setzt voraus, dass Sie freiwillig und aktiv Daten eingeben oder bereitstellen, wobei für jede Funktion der Zweck beschrieben ist, zu dem die Daten benötigt werden.
                                </p>
                                <p>
                                    Kommunikation von CamDiab oder dem Händler mit Ihnen in unseren Apps oder über andere elektronische Messaging-Dienste (z. B. E-Mail, Messenger, Telefon), wenn dies zu Zwecken des Supports oder der Fehlerbehebung für unsere Produkte nötig ist. So verarbeiten wir etwaige Kommentare und Anfragen, die Sie bei der Nutzung von CamDiab über verschiedene Kommunikationskanäle übermitteln können. Das wichtigste Beispiel dafür ist unser Support-Dienst, den Sie unter <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a> kontaktieren können. Überlegen Sie sich deshalb bitte, welche Informationen und Daten Sie in Ihrer aktiven Kommunikation mit uns herausgeben möchten – dies ist allein Ihre Entscheidung. Unsererseits kann die Kommunikation mit Benutzern per E-Mail oder über ähnliche Kanäle erforderlich sein. Auf diesem Wege informieren wir Sie über Produktupdates und stellen wichtige Sicherheitshinweise sowie Unterstützung im Zusammenhang mit Ihrer Nutzung unserer Produkte bereit. Entsprechende Support-Nachrichten werden – als wesentlicher Teil unserer Produkte – an die Benutzer gesendet.
                                </p>
                                <p>
                                    <strong>Therapiegeräte</strong> (z. B. Glukosesensor und Insulinpumpen) werden mit Ihrem Gerät verbunden, sodass Daten an unsere Apps übermittelt werden können.
                                </p>
                                <p>
                                    <strong>Diabetes-Datenportale und ‑Datenaustauschfunktionen</strong>, wie etwa von mylife Cloud/Glooko, ermöglichen den Austausch von Daten mit unseren Apps. Die Synchronisierung erfolgt jedoch nur, wenn Sie dies in den Einstellungen unserer Apps aktivieren, d. h. wenn Sie die entsprechende Funktion nutzen. Für Daten, die an solche externen Partner weitergegeben werden, gilt die Datenschutzerklärung der Hersteller der Diabetes-Datenportale.
                                </p>

                                2.2.2 Die <strong>Nutzung</strong> unserer Apps setzt voraus, dass Sie aktiv und freiwillig Daten eingeben oder bereitstellen. Um einen Fehler in der App zu beheben, benötigen wir beispielsweise Absturzberichte, die wir zu Zwecken der Fehlerbehebung nutzen können, um so die Umstände des Problems zu ermitteln. Darüber hinaus werden die zentralen Daten Ihres Geräts und Ihres Nutzungsverhaltens erfasst, da die Erfüllung unseres Vertrages vor allem die Personalisierung unserer Produkte erfordert, d. h. die Verarbeitung individueller Benutzerinformationen, etwa in Abhängigkeit Ihres Standorts (auch für die Suchfunktion relevant). Eine automatisierte Analyse des Nutzungsverhaltens findet ausschließlich zum Zweck der Personalisierung Ihrer Nutzung im Rahmen der Erfüllung des Vertrages statt und hat keine rechtlichen Auswirkungen auf Sie.

                                <h2>
                                    3. VERARBEITUNG ZUR PRODUKTVERBESSERUNG
                                </h2>
                                <p>
                                    Wir verarbeiten Ihre Benutzerdaten auch über den in Abschnitt 2 oben beschriebenen erforderlichen Umfang hinaus, um unsere Produkte und Leistungen, wie nachfolgend ausführlicher beschrieben, zu verbessern.
                                </p>
                                <h3>
                                    3.1. Zusätzliche Daten
                                </h3>
                                <p>
                                    Generell nutzen wir die in Abschnitt 2 aufgeführten Benutzerdaten auch, um unsere Produkte zu verbessern. Zusätzlich kann CamDiab auch die folgenden Benutzerdaten erfassen:
                                </p>
                                <p>
                                    <strong>Nutzungsdaten</strong>
                                    <br/>
                                    Aktivitätsereignisse, anhand derer wir erkennen können, wie Sie unsere Produkte nutzen. So können wir sehen, wie unsere Produkte genutzt werden und wo zum Beispiel die Menügestaltung noch optimiert werden kann.
                                </p>
                                <h3>
                                    3.2. Zweck der Produktverbesserung
                                </h3>
                                <p>
                                    Angesichts des schnellen technischen Fortschritts müssen wir unsere Produkte und die Interaktion mit ihnen kontinuierlich analysieren, weiterentwickeln, testen und verbessern, um zu gewährleisten, dass die Benutzer auf möglichst effektive Weise von unseren Inhalten profitieren können. Zu diesem Zweck führen wir eventuell Nutzungs-, Machbarkeits-, Kontroll- und Sicherheitsbeurteilungen sowie Vorhersagen durch und die dabei gewonnenen Erkenntnisse fließen in neue, verbesserte Versionen unserer Produkte wie beispielsweise der App ein. Diese Verbesserungen werden im Rahmen regelmäßiger Updates auch Ihnen zur Verfügung gestellt.
                                </p>
                                <p>
                                    CamDiab engagiert sich für die wissenschaftliche Erforschung aller Aspekte von Diabetes. Daher können anonyme Nutzerdaten auch für Forschungs- und Statistikzwecke (immer unter Einhaltung der anerkannten ethischen wissenschaftlichen Standards) und interne Analysen verwendet werden. Dies kann auch die Weitergabe anonymisierter Daten an Dritte beinhalten.
                                </p>

                                <h2>
                                    4. VERARBEITUNG ZU MARKETINGZWECKEN
                                </h2>
                                <h3>
                                    4.1. Newsletter
                                </h3>
                                <p>
                                    4.1.1 Wir senden Ihnen möglicherweise Informationen zu Produkten und Leistungen, die über den vertraglichen Umfang hinausgehen (darunter auch Informationen von sorgfältig ausgewählten Partnern), sowie Einladungen zur Teilnahme an Umfragen oder sonstigen Verkaufsaktionen und Marketingaktivitäten zu („<strong>Newsletter</strong>“).
                                </p>
                                <p>
                                    4.1.2 Sie können auswählen, ob Sie unseren Newsletter abonnieren möchten (Opt-in). Ihre Einwilligung können Sie jederzeit in Ihren Kontoeinstellungen widerrufen.
                                </p>
                                <h3>
                                    4.2. Sonstige Arten von Marketing
                                </h3>
                                <p>
                                    4.2.1 Weitere Einwilligungen, z. B. für Umfragen, Benachrichtigungen oder personalisierte Angebote, werden nach Bedarf eingeholt. Wir erläutern Ihnen immer, wozu wir bestimmte Daten benötigen, und auch, wie Sie Ihre Einwilligung widerrufen können.
                                </p>
                                <p>
                                    4.2.2 Bitte beachten Sie, dass wir Ihnen in der App auch Angebote anzeigen können, ohne Ihre personenbezogenen Daten zu verarbeiten. Diese nicht personalisierte Werbung sehen Sie auch, wenn Sie Ihre Einwilligung nicht erteilt haben.
                                </p>
                                <h2>
                                    5. VERARBEITUNG ZU GESETZLICH VORGESEHENEN ZWECKEN
                                </h2>
                                <h3>
                                    5.1. Wissenschaftliche Forschung und Statistik
                                </h3>
                                <p>
                                    CamDiab engagiert sich für die Erforschung aller Aspekte von Diabetes. Deshalb können anonyme Benutzerdaten auch zu wissenschaftlichen und statistischen Zwecken (stets unter Beachtung der anerkannten ethischen Forschungsstandards) sowie für interne Analysen verwendet werden. Diese dienen vor allem dazu, die Wirksamkeit von Verfahren zur Kontrolle und Behandlung von Diabetes zu ermitteln und zu erhöhen. Die Rechtsgrundlage dafür bildet Artikel 9 Absatz 2 Buchstabe j DSGVO.
                                </p>
                                <h3>
                                    5.2. Ausübung von Rechten
                                </h3>
                                <p>
                                    Die Verwendung personenbezogener Daten kann auch erforderlich sein, um Missbrauch durch Benutzer zu verhindern oder um Rechtsansprüche geltend zu machen, auszuüben oder zu verteidigen. Möglicherweise sind wir durch geltende Gesetze, gerichtliche oder amtliche Entscheidungen und Weisungen, Ermittlungsverfahren oder aus Gründen des öffentlichen Interesses gezwungen, Informationen offenzulegen. In solchen Fällen ist die Aufbewahrung und Verarbeitung Ihrer Daten auch ohne Ihre Einwilligung rechtlich zulässig. Die Rechtsgrundlage dafür bildet Artikel 9 Absatz 2 Buchstabe f DSGVO.
                                </p>
                                <h3>
                                    5.3. Gemäß Medizinproduktegesetz
                                </h3>
                                <p>
                                    Für uns als Hersteller oder Händler eines Medizinprodukts gelten erhöhte Anforderungen an die Überwachung der Funktion unseres Produkts. Im Rahmen des Überwachungssystems, das zu regulatorischen Zwecken erforderlich ist, können auch personenbezogene Daten verarbeitet werden. Die Rechtsgrundlage dafür bildet Artikel 9 Absatz 2 Buchstabe i DSGVO.
                                </p>
                                <h2>
                                    6. ALLGEMEINE INFORMATIONEN
                                </h2>
                                <h3>
                                    6.1. Zweckbindung und Sicherheit
                                </h3>
                                <p>
                                    6.1.1 CamDiab verwendet Ihre personenbezogenen Daten ausschließlich zu den Zwecken, die in dieser Datenschutzerklärung und den entsprechenden Einwilligungserklärungen angegeben sind. Wir stellen sicher, dass jede Verarbeitung auf das für den jeweiligen Zweck erforderliche Maß beschränkt bleibt.
                                </p>
                                <p>
                                    6.1.2 Bei jeder Verarbeitung ist angemessene Sicherheit und Vertraulichkeit Ihrer personenbezogenen Daten stets gewährleistet. Dies umfasst den Schutz vor unbefugter und unrechtmäßiger Verarbeitung, unbeabsichtigtem Verlust, unbeabsichtigter Vernichtung oder unbeabsichtigter Schädigung durch geeignete technische und organisatorische Maßnahmen. Wir haben strenge interne Prozesse, Sicherheitsmerkmale und Verschlüsselungsmethoden implementiert.
                                </p>
                                <h3>
                                    6.2. Auftragsverarbeiter
                                </h3>
                                <p>
                                    6.2.1 Unsere Produkte unterliegen komplexen Prozessen, die wir verwalten und auf dem aktuellen Stand halten müssen. Zur technischen Unterstützung nehmen wir möglicherweise die Dienste von Drittanbietern („<strong>Auftragsverarbeitern</strong>“) in Anspruch, um Ihnen die vollumfängliche und optimale Nutzung unserer Produkte anbieten zu können.
                                </p>
                                <p>
                                    6.2.2 CamDiab übermittelt Benutzerdaten ausschließlich im Rahmen dieser Datenschutzerklärung und zur Erfüllung der darin beschriebenen Zwecke an Auftragsverarbeiter. Die Auftragsverarbeiter werden nach unseren Vorgaben und Weisungen tätig; es ist ihnen nicht gestattet, die personenbezogenen Daten unserer Benutzer zu ihren eigenen oder zu anderen Zwecken zu verwenden.
                                </p>
                                <p>
                                    6.2.3 Wir arbeiten mit Auftragsverarbeitern, die hinreichend Garantien dafür bieten, dass geeignete technische und organisatorische Maßnahmen so durchgeführt werden, dass die Verarbeitung der personenbezogenen Daten im Einklang mit den gesetzlichen Vorschriften und unserer Datenschutzerklärung erfolgt. Der Schutz der Rechte unserer Benutzer wird gewährleistet, indem wir verbindliche Verträge schließen, die die strengen Vorgaben der DSGVO erfüllen.
                                </p>
                                <h3>
                                    6.3. Verschlüsselung, Pseudonymisierung und Anonymisierung
                                </h3>
                                <p>
                                    6.3.1 Jede Datenübermittlung erfolgt ausnahmslos und standardmäßig verschlüsselt. Durch die Nutzung von HTTPS (Hypertext Transfer Protocol Secure) oder Ähnlichem stellen wir sicher, dass Ihre Daten nicht von unbefugten Dritten abgefangen werden.
                                </p>
                                <p>
                                    Zu Zwecken der Datensicherheit und ‑minimierung wenden wir zusätzlich noch weitere Prozesse zur Verschlüsselung und Pseudonymisierung von Benutzerdaten an. Dies richtet sich natürlich nach Art, Umfang und Zweck der jeweiligen Datenverarbeitung. So legen wir beispielsweise nur die Benutzerdaten offen, die der Auftragsverarbeiter benötigt, um seine Aufgaben zu erfüllen.
                                </p>
                                <p>
                                    6.3.2 Wenn das Vertragsverhältnis mit einem Auftragsverarbeiter endet, muss der entsprechende Auftragsverarbeiter nach Wahl von CamDiab entweder alle Daten unserer Benutzer zurückgeben oder diese löschen, sofern keine gesetzliche Pflicht zur Aufbewahrung besteht.
                                </p>
                                <p>
                                    6.3.3 Daten, für deren Verarbeitung kein persönlicher Bezug notwendig ist (z. B. für Forschung und Analyse), werden anonymisiert. Dadurch wird in allen Fällen verhindert, dass eine Verbindung zu einem bestimmten Benutzer hergestellt werden kann.
                                </p>
                                <h3>
                                    6.4. EU und andere Länder
                                </h3>
                                <p>
                                    6.4.1 Wir bemühen uns um die Wahl von Kooperationspartnern, die in der Europäischen Union (EU) oder dem Europäischen Wirtschaftsraum (EWR) ansässig sind oder deren Server sich dort befinden. Datenübermittlungen innerhalb der EU und des EWR geben keinen Anlass für Beanstandungen, da die DSGVO in allen Mitgliedstaaten gilt.
                                </p>
                                <p>
                                    6.4.2 In Ausnahmefällen nehmen wir die Dienste von Drittanbietern in Anspruch, die außerhalb der EU ansässig sind oder deren Server sich außerhalb der EU befinden. Doch auch in diesen Fällen ist für Ihre personenbezogenen Daten ein hohes Schutzniveau im Einklang mit der DSGVO gewährleistet – entweder durch einen Angemessenheitsbeschluss der EU, wonach der Datenschutz in bestimmten Drittländern (z. B. Schweiz, Israel und Neuseeland) als angemessen eingestuft wird, oder durch bestimmte von der EU genehmigte Standardvertragsklauseln, auf denen die Vertragsverhältnisse mit unseren Auftragsverarbeitern beruhen, oder durch vergleichbare Rechtsinstrumente, die gemäß DSGVO zulässig sind.
                                </p>
                                <p>
                                    6.4.3 Darüber hinaus stellen wir sicher, dass unsere Partner über zusätzliche Sicherheitsstandards verfügen, zum Beispiel über individuelle Sicherheitsmaßnahmen und Datenschutzvorkehrungen oder Zertifizierungen gemäß DSGVO. Wenn Drittanbieter also beispielsweise in den USA ansässig sind, sollten der von der EU genehmigte Privacy Shield oder vergleichbare international anerkannte Sicherheitsstandards für sie gelten.
                                </p>
                                <h3>
                                    6.5. Cookies
                                </h3>
                                <p>
                                    CamDiab speichert möglicherweise sogenannte „Cookies“, um Ihnen umfassende Funktionen anbieten und die Nutzung unserer Website benutzerfreundlicher gestalten zu können. Cookies sind kleine Textdateien, die von Ihrem Browser auf Ihrem Gerät gespeichert werden. Mit Ausnahme der in Abschnitt 6.6. erwähnten Cookies für Nutzungsdaten können unsere Cookies zum Betrieb der Website eingesetzt werden. Wenn Sie keine Cookies verwenden möchten, können Sie ihre Speicherung verhindern, indem Sie die entsprechenden Einstellungen in Ihrem Browser nutzen. Die meisten unserer Cookies werden entweder beim Verlassen der Website oder beim Schließen des Browsers gelöscht (sogenannte Session-Cookies). Ist dies nicht der Fall, können Sie die Speicherdauer in Ihrem Browser einsehen oder die Cookies manuell löschen.
                                </p>
                                <h3>
                                    6.6. Nutzungsdaten
                                </h3>
                                <p>
                                    In unserer App nutzen wir Google Firebase Analytics, einen App- und Webanalysedienst von Google Inc. („Google“). Google ist gemäß dem EU-US Privacy Shield zertifiziert.
                                </p>
                                <h3>
                                    6.7. Speicherung und Löschung
                                </h3>
                                <p>
                                    6.7.1 Ihre Benutzerdaten werden auf Ihrem Gerät gespeichert. Außerdem werden die Daten auf Servern gespeichert. Wir nutzen ausschließlich Systeme, die die Anforderungen der DSGVO erfüllen.
                                </p>
                                <p>
                                    6.7.2 Ihre Daten werden auf Servern in der Europäischen Union (EU) gespeichert.
                                </p>
                                <p>
                                    6.7.3 In der Regel speichert CamDiab Ihre personenbezogenen Daten nur für die Dauer des Vertrages. In Ausnahmefällen kann eine längere Speicherung erforderlich sein, um nachvertragliche Pflichten zu erfüllen, um gesetzlichen Pflichten zur Aufbewahrung oder Offenlegung nachzukommen oder um Rechtansprüche geltend zu machen, auszuüben oder zu verteidigen (Verjährungsfristen).
                                </p>
                                <h3>
                                    6.8. Minderjährige
                                </h3>
                                <p>
                                    Minderjährige unter sechzehn Jahren dürfen unsere Produkte nur mit Erlaubnis eines Elternteils/Vormunds nutzen (<a href="/de/terms">Allgemeine Geschäftsbedingungen – AGB</a>). Dies gilt auch für die Verarbeitung ihrer personenbezogenen Daten, die nur dann rechtmäßig ist, wenn und soweit die Einwilligung des Elternteils/Vormunds eingeholt wurde. Ansonsten ist die Nutzung unserer Produkte untersagt.
                                </p>
                                <h3>
                                    6.9. Datenschutzbeauftragte/r
                                </h3>
                                <p>
                                    6.9.1 Unser/e Datenschutzbeauftragte/r steht unter <a href="mailto:dpo@evalian.co.uk">dpo@evalian.co.uk</a> gern zur Verfügung, um Fragen zum Thema Datenschutz zu beantworten.
                                </p>
                                <p>
                                    6.9.2 Der/die Datenschutzbeauftragte wird in allen Fragen im Zusammenhang mit dem Schutz der personenbezogenen Daten unserer Benutzer umfassend einbezogen. Als ausgebildete Fachperson überwacht er/sie unsere Verarbeitung fortlaufend, um den bestmöglichen Schutz Ihrer Benutzerdaten sicherzustellen.
                                </p>
                                <h2>
                                    7. IHRE RECHTE
                                </h2>
                                <h3>
                                    7.1. Widerruf von Einwilligungen
                                </h3>
                                <p>
                                    Wenn wir Ihre Benutzerdaten auf Grundlage Ihrer Einwilligung verarbeiten, haben Sie jederzeit das Recht, die Einwilligung zu widerrufen. Dies hat jedoch keinen Einfluss auf die Rechtmäßigkeit der Verarbeitung vor dem Widerruf. Wir werden unsere Leistungen weiter erbringen, wenn dazu nicht die Einwilligung erforderlich ist, die widerrufen wurde.
                                </p>
                                <h3>
                                    7.2. <strong>Auskunft, Berichtigung und Einschränkung</strong>
                                </h3>
                                <p>
                                    7.2.1 Alle Benutzer haben das Recht, Auskunft über die Verarbeitung ihrer personenbezogenen Daten zu verlangen. Zu diesem Zweck können Sie sich jederzeit unter <a
                                    href="mailto:privacy@camdiab.com">privacy@camdiab.com</a> an uns wenden.
                                </p>
                                <p>
                                    7.2.2 Ihr Auskunftsrecht umfasst Informationen über die Verarbeitungszwecke, die Kategorien von Daten und Empfängern, die Aufbewahrungsdauer, die Herkunft Ihrer Daten und Ihre Rechte gemäß den Datenschutzgesetzen. All diese Informationen können Sie in dieser Datenschutzerklärung finden und wir stellen sie Ihnen gern in elektronischer Form bereit.
                                </p>
                                <p>
                                    7.2.3 Sollten personenbezogene Daten über Sie nicht korrekt sein, können Sie jederzeit die Berichtigung oder Vervollständigung Ihrer Daten verlangen. Falls Sie eine Überprüfung der Verarbeitung verlangen, haben Sie das Recht, die Datenverarbeitung für deren Dauer einzuschränken.
                                </p>
                                <h3>
                                    7.3. Löschung („Recht auf Vergessenwerden“)
                                </h3>
                                <p>
                                    Alle Benutzer haben das Recht, die Löschung ihrer personenbezogenen Daten zu verlangen. Zu diesem Zweck können Sie sich jederzeit unter <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a> an uns wenden.
                                </p>
                                <h3>
                                    7.4. Beschwerden
                                </h3>
                                <p>
                                    7.4.1 Wenn Sie der Meinung sind, dass wir Ihre Datenschutzrechte nicht angemessen wahren, können Sie sich jederzeit unter <a
                                    href="mailto:support@camdiab.com">support@camdiab.com</a> an uns oder unter <a
                                    href="mailto:dpo@evalian.co.uk">dpo@evalian.co.uk</a> direkt an unsere/n Datenschutzbeauftragte/n wenden. Wir werden uns angemessen um Ihr Anliegen kümmern.
                                </p>
                                <p>
                                    7.4.2 Alle Benutzer haben das Recht, Beschwerde bei der entsprechenden Datenschutzbehörde einzulegen, die für CamDiab zuständig ist, wenn sie der Meinung sind, dass die Verarbeitung ihrer personenbezogenen Daten nicht den Datenschutzgesetzen entspricht. Außerdem haben Benutzer ein Recht auf Beschwerde bei einer Aufsichtsbehörde in dem EU-Mitgliedstaat, in dem sie wohnen, in dem sich ihr Arbeitsplatz befindet oder in dem der mutmaßliche Verstoß stattgefunden hat.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer props={this.props}/>
            </div>
        );
    }
}

